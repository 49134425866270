@import "../base/base.gd.scss";
@import "../popups/tooltip.gd.scss";

$rating-li-font-size: 20px !default;
$rating-li-padding: 4px !default;
$rating-li-line-height: 13px !default;
$rating-li-min-width-height: 28px !default;
$rating-focus-border-radius: 0 !default;
$rating-label-bottom-margin: 4px !default;
$rating-label-right-margin: 8px !default;
$rating-label-top-margin: 4px !default;
$rating-label-left-margin: 4px !default;
$rating-label-font-size: 12px !default;
$rating-label-line-height: 18px !default;
$rating-reset-font-size : 16px !default;
$rating-tooltip-tip-bottom-height: 8px !default;
$rating-border-width: 1px !default;

$rating-small-li-font-size: 16px !default;
$rating-small-li-line-height: 9px !default;
$rating-small-li-min-width-height: 24px !default;
$rating-small-label-font-size: 10px !default;
$rating-small-label-line-height: 16px !default;
$rating-small-reset-font-size : 12px !default;

$rating-bigger-li-font-size: 32px !default;
$rating-bigger-li-line-height: 21px !default;
$rating-bigger-li-min-width-height: 40px !default;
$rating-bigger-label-font-size: 14px !default;
$rating-bigger-label-line-height: 22px !default;
$rating-bigger-reset-font-size : 28px !default;

$rating-bigger-small-li-font-size: 24px !default;
$rating-bigger-small-li-line-height: 17px !default;
$rating-bigger-small-li-min-width-height: 32px !default;
$rating-bigger-small-label-font-size: 12px !default;
$rating-bigger-small-label-line-height: 18px !default;
$rating-bigger-small-reset-font-size : 20px !default;

$rating-selected-bg-color: $grey-900 !default;
$rating-unrated-bg-color: $grey-500 !default;
$rating-unrated-icon-bg-color: none !default;
$rating-hover-bg-color: $primary !default;
$rating-pressed-bg-color: $primary-300 !default;
$rating-selected-disabled-bg-color: $grey-500 !default;
$rating-unrated-disabled-bg-color: $grey-500 !default;
$rating-li-focus: $primary !default;
$rating-reset-icon-color: $grey-800 !default;
$rating-reset-hover-icon-color: $grey-900 !default;
$rating-reset-disabled-icon-color: $grey-500 !default;

@mixin rating-container-properties($svg-font-size, $svg-line-height, $label-font-size, $label-line-height, $reset-icon-font-size) {
  .e-rating-item-container {
    &:has(svg) {
      line-height: $svg-line-height;
    }
    font-size: $svg-font-size;
  }

  .e-rating-label {
    font-size: $label-font-size;
    line-height: $label-line-height;
  }

  .e-reset {
    font-size: $reset-icon-font-size;
  }
}

@include export-module('rating-layout') {
  .e-rating-container {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    min-width: max-content;

    .e-rating {
      display: none;
    }

    .e-rating-item-list {
      display: inline-flex;
      padding: 0;
      margin: 4px;

      &:focus-visible:not(:hover) .e-rating-focus {
        &:not(:has(svg)),
        .e-rating-item:has(svg) {
          outline: 1px solid;
        }
      }

      &:focus-visible {
        outline: none;
      }
    }

    &.e-rating-readonly {
      cursor: default;
      pointer-events: none;
    }

    .e-rating-item-container {
      &:has(svg) {
        line-height: $rating-li-line-height;
      }
      cursor: pointer;
      font-size: $rating-li-font-size;
      padding: $rating-li-padding;
      display: block;
      min-width: calc(1em + (2 * $rating-li-padding)); //calc(font-size + (2 * padding));
      min-height: calc(1em + (2 * $rating-li-padding)); //calc(font-size + (2 * padding));
      //For Blazor Tooltip
      position: relative;

      .e-rating-item {
        display: block;
      }
    }

    &.e-rating-animation.e-touch-select:not(.e-disabled) .e-rating-item-container.e-rating-focus .e-rating-item,
    &.e-rating-animation:not(.e-disabled, .e-rating-touch) .e-rating-item-container:hover .e-rating-item {
      transition: transform 150ms cubic-bezier(.4, 0, .2, 1) 0ms;
      transform: scale(1.4);
      pointer-events: none;
    }

    &.e-disabled {
      cursor: default;
      pointer-events: none;
    }

    &.e-rating-hidden {
      display: none;
    }

    .e-reset {
      cursor: pointer;
      margin: auto 2px 6px;
      font-size: $rating-reset-font-size;
      padding: 2px;

      &.e-disabled {
        cursor: default;
      }

      &:focus-visible {
        outline: 1px solid;
        border-radius: $rating-focus-border-radius;
      }
    }

    .e-rating-label {
      font-size: $rating-label-font-size;
      line-height: $rating-label-line-height;

      &.e-label-right,
      &.e-label-left {
        min-width: 50px;
      }

      &.e-label-bottom,
      &.e-label-top {
        flex-basis: 100%;
        width: 0;
        text-align: center;
      }

      &.e-label-right {
        text-align: left;
        margin: auto $rating-label-right-margin;
      }

      &.e-label-left {
        text-align: right;
        margin: auto $rating-label-left-margin;
      }

      &.e-label-top {
        margin: $rating-label-top-margin 0;
      }

      &.e-label-bottom {
        margin: $rating-label-bottom-margin 0;
      }
    }

    &.e-rtl {
      .e-rating-label {
        &.e-label-right {
          text-align: right;
        }

        &.e-label-left {
          text-align: left;
        }
      }
    }
    //Blazor Rating Tooltip
    .e-tooltip-wrap.e-rating-tooltip {
      display: none;
      justify-content: center;
      left: 50%;
      top: -$rating-tooltip-tip-bottom-height;
      transform: translate(-50%, -100%);

      &.e-show-tooltip {
        display: inline-flex;
      }

      .e-tip-content {
        width: max-content;
      }

      .e-arrow-tip.e-tip-bottom {
        left: 50%;
        top: 100%;
        transform: translate(-50%, 0);
      }

      .e-arrow-tip-inner.e-tip-bottom {
        top: -6px;
      }
    }
  }

  .e-rating-tooltip .e-tip-content {
    text-align: center;
  }

  .e-small.e-rating-container,
  .e-small .e-rating-container {
    @include rating-container-properties($rating-small-li-font-size, $rating-small-li-line-height, $rating-small-label-font-size, $rating-small-label-line-height, $rating-small-reset-font-size);
  }
}


@include export-module('rating-theme') {
  .e-rating-container {
    .e-rating-item-list {
      &:focus-visible:not(:hover) .e-rating-focus {
        &:not(:has(svg)),
        .e-rating-item:has(svg) {
          outline-color: $rating-li-focus;
          border-radius: $rating-focus-border-radius;
        }
      }
    }

    .e-reset {
      color: $rating-reset-icon-color;

      &:hover {
        color: $rating-reset-hover-icon-color;
      }

      &.e-disabled {
        color: $rating-reset-disabled-icon-color;
        opacity: 1;
      }
    }

    .e-rating-item-container {
      .e-rating-icon {
        -webkit-text-fill-color: transparent;
        @if ($skin-name == 'Material3') {
          -webkit-text-stroke: $rating-border-width transparent;
        }
        @else {
          -webkit-text-stroke: $rating-border-width $rating-unrated-bg-color;
        }
        background: $rating-unrated-icon-bg-color;
        @if ($skin-name == 'Material3' or $skin-name =='tailwind') {
          background-clip: text;
          /* stylelint-disable property-no-vendor-prefix */
          -webkit-background-clip: text;
          /* stylelint-enable property-no-vendor-prefix */
        }
      }

      &.e-rating-selected .e-rating-icon,
      &.e-rating-intermediate .e-rating-icon {
        background: linear-gradient(to right, $rating-selected-bg-color var(--rating-value), transparent var(--rating-value));
        background-clip: text;
        /* stylelint-disable property-no-vendor-prefix */
        -webkit-background-clip: text;
        /* stylelint-enable property-no-vendor-prefix */
        -webkit-text-stroke: $rating-border-width $rating-selected-bg-color;
      }

      &.e-selected-value .e-rating-icon {
        -webkit-text-stroke: $rating-border-width $rating-hover-bg-color;
        @if ($skin-name =='bootstrap5') {
          -webkit-text-stroke: $rating-border-width $rating-selected-bg-color;
        }
      }
    }

    .e-rating-item-list {
      &:hover .e-rating-item-container {
        &.e-rating-selected .e-rating-icon,
        &.e-rating-intermediate .e-rating-icon {
          background: linear-gradient(to right, $rating-hover-bg-color var(--rating-value), transparent var(--rating-value));
          background-clip: text;
          /* stylelint-disable property-no-vendor-prefix */
          -webkit-background-clip: text;
          /* stylelint-enable property-no-vendor-prefix */
          @if ($skin-name == 'Material3') {
            -webkit-text-stroke: $rating-border-width $rating-selected-bg-color;
          }
          @else {
            -webkit-text-stroke: $rating-border-width $rating-hover-bg-color;
          }
        }
      }

      .e-rating-item-container {
        &.e-rating-selected:active .e-rating-icon,
        &.e-rating-intermediate:active .e-rating-icon {
          background: linear-gradient(to right, $rating-pressed-bg-color var(--rating-value), transparent var(--rating-value));
          background-clip: text;
          /* stylelint-disable property-no-vendor-prefix */
          -webkit-background-clip: text;
          /* stylelint-enable property-no-vendor-prefix */
          @if ($skin-name == 'bootstrap4') {
            -webkit-text-stroke: $rating-border-width $rating-hover-bg-color;
          }
          @else if ($skin-name == 'Material3') {
            -webkit-text-stroke: $rating-border-width $rating-selected-bg-color;
          }
          @else {
            -webkit-text-stroke: $rating-border-width $rating-pressed-bg-color;
          }
        }
      }
    }

    &.e-rtl {
      .e-rating-item-container {
        &.e-rating-selected .e-rating-icon,
        &.e-rating-intermediate .e-rating-icon {
          background: linear-gradient(to left, $rating-selected-bg-color var(--rating-value), transparent var(--rating-value));
          background-clip: text;
          /* stylelint-disable property-no-vendor-prefix */
          -webkit-background-clip: text;
          /* stylelint-enable property-no-vendor-prefix */
        }
      }

      .e-rating-item-list {
        &:hover .e-rating-item-container {
          &.e-rating-selected .e-rating-icon,
          &.e-rating-intermediate .e-rating-icon {
            background: linear-gradient(to left, $rating-hover-bg-color var(--rating-value), transparent var(--rating-value));
            background-clip: text;
            /* stylelint-disable property-no-vendor-prefix */
            -webkit-background-clip: text;
            /* stylelint-enable property-no-vendor-prefix */
          }
        }

        .e-rating-item-container {
          &.e-rating-selected:active .e-rating-icon,
          &.e-rating-intermediate:active .e-rating-icon {
            background: linear-gradient(to left, $rating-pressed-bg-color var(--rating-value), transparent var(--rating-value));
            background-clip: text;
            /* stylelint-disable property-no-vendor-prefix */
            -webkit-background-clip: text;
            /* stylelint-enable property-no-vendor-prefix */
          }
        }
      }
    }

    &.e-disabled {
      opacity: 1;
      .e-rating-item-container {
        .e-rating-icon {
          @if ($skin-name == 'Material3') {
            -webkit-text-stroke: $rating-border-width transparent;
            background: $rating-unrated-disabled-bg-color;
            background-clip: text;
            /* stylelint-disable property-no-vendor-prefix */
            -webkit-background-clip: text;
            /* stylelint-enable property-no-vendor-prefix */
          }
          @else{
            -webkit-text-stroke: $rating-border-width $rating-unrated-disabled-bg-color;
          }
        }

        &.e-rating-selected .e-rating-icon,
        &.e-rating-intermediate .e-rating-icon {
          background: linear-gradient(to right, $rating-selected-disabled-bg-color var(--rating-value), transparent var(--rating-value));
          background-clip: text;
          /* stylelint-disable property-no-vendor-prefix */
          -webkit-background-clip: text;
          /* stylelint-enable property-no-vendor-prefix */
          @if ($skin-name == 'Material3') {
            -webkit-text-stroke: $rating-border-width transparent;
          }
          @else{
            -webkit-text-stroke: $rating-border-width $rating-selected-disabled-bg-color;
          }
        }
      }

      &.e-rtl {
        .e-rating-item-container {
          .e-rating-icon {
            @if ($skin-name == 'Material3') {
              background: $rating-unrated-disabled-bg-color;
            }
            @else{
              background: linear-gradient(to left, $rating-unrated-disabled-bg-color var(--rating-value), transparent var(--rating-value));
            }
            background-clip: text;
            /* stylelint-disable property-no-vendor-prefix */
            -webkit-background-clip: text;
            /* stylelint-enable property-no-vendor-prefix */
          }

          &.e-rating-selected .e-rating-icon,
          &.e-rating-intermediate .e-rating-icon {
            background: linear-gradient(to left, $rating-selected-disabled-bg-color var(--rating-value), transparent var(--rating-value));
            background-clip: text;
            /* stylelint-disable property-no-vendor-prefix */
            -webkit-background-clip: text;
            /* stylelint-enable property-no-vendor-prefix */
          }
        }
      }
    }
  }
}

@include export-module('rating-bigger') {

  .e-bigger.e-rating-container,
  .e-bigger .e-rating-container {
    @include rating-container-properties($rating-bigger-li-font-size, $rating-bigger-li-line-height, $rating-bigger-label-font-size, $rating-bigger-label-line-height, $rating-bigger-reset-font-size);
  }

  .e-bigger.e-small.e-rating-container,
  .e-bigger.e-small .e-rating-container,
  .e-bigger .e-small.e-rating-container,
  .e-small .e-bigger.e-rating-container {
    @include rating-container-properties($rating-bigger-small-li-font-size, $rating-bigger-small-li-line-height, $rating-bigger-small-label-font-size, $rating-bigger-small-label-line-height, $rating-bigger-small-reset-font-size);
  }
}

@import "../base/base.gd.scss";
@import "../popups/popup.gd.scss";
@import "../navigations/context-menu.gd.scss";
@import "../navigations/h-scroll.gd.scss";
@import "../navigations/v-scroll.gd.scss";

$menu-bigger-caret-font-size: $cmenu-bigger-caret-font-size !default;
$menu-bigger-caret-li-padding: 36px !default;
$menu-bigger-caret-right: 16px !default;
$menu-bigger-font-size: 14px !default;
$menu-bigger-li-right-padding: 16px !default;
$menu-bigger-li-height: 56px !default;
$menu-bigger-li-padding: 0 16px !default;
$menu-bigger-li-text-indent: 16px !default;
$menu-bigger-icon-margin-right: 10px !default;
$menu-color: $cmenu-color !default;
$menu-blank-icon: 44px !default;
$menu-blank-icon-bigger: 50px !default;
$menu-caret-font-size: 10px !default;
$menu-caret-icon: '\e94d' !default;
$menu-caret-icon-rtl: '\e937' !default;
$menu-caret-right: 12px !default;
$menu-caret-li-padding: 30px !default;
$menu-ul-padding: 0 !default;
$menu-separator-padding: 0 !default;
$menu-ul-header-padding: $menu-ul-padding !default;
$menu-caret-padding-left: 10px !default;
$menu-disable-opacity: .38 !default;
$menu-font-size: 13px !default;
$menu-icon-margin-right: 8px !default;
$menu-li-border-style: solid !default;
$menu-li-border-width: 1px !default;
$menu-li-selection-font-color: $cmenu-li-selection-font-color !default;
$menu-li-height: 42px !default;
$menu-li-padding: 0 12px !default;
$menu-li-text-indent: 12px !default;
$menu-li-right-padding: 12px !default;
$menu-li-bgcolor: $cmenu-li-bgcolor !default;
$menu-li-selection-bgcolor: $grey-300 !default;
$menu-li-selected-outline: 0 solid $menu-li-selection-bgcolor !default;
$menu-icon-font-size: 14px !default;
$menu-icon-bigger-font-size: 16px !default;
$menu-icon-width: 24px !default;
$menu-separator-bigger-padding: 0 !default;
$menu-seperator-border-color: rgba($grey-light-font, .12) !default;
$menu-sub-ul-bgcolor: $grey-white !default;
$menu-sub-li-border-color: rgba($grey-light-font, .12) !default;
$menu-ul-bgcolor: $grey-50 !default;
$menu-ul-border: none !default;
$menu-ul-bigger-header-padding: 0 !default;
$menu-li-hover-outline-offset: 0 !default;
$menu-li-selected-outline-offset: 0 !default;
$menu-selected-color: $cmenu-selected-color !default;
$menu-li-border-color: $cmenu-li-border-color !default;
$menu-li-hover-outline: 0 solid $menu-li-border-color !default;
$menu-hscroll-nav-size: 30px !default;
$menu-vscroll-nav-size: 16px !default;
$menu-scroll-nav-icon-size: 10px !default;
$menu-bigger-hscroll-nav-size: 36px !default;
$menu-bigger-vscroll-nav-size: 24px !default;
$menu-bigger-scroll-nav-icon-size: 12px !default;
$menu-srollbar-ul-border: none !default;
$submenu-caret-font-size: $cmenu-caret-font-size !default;
$submenu-bigger-caret-font-size: $cmenu-bigger-caret-font-size !default;
$submenu-color: $cmenu-color !default;
$menu-icon-color: $cmenu-caret-color !default;
$menu-icon-hover-color: initial !default;
$menu-icon-focussed-color: $cmenu-caret-color !default;
$menu-caret-color: $cmenu-caret-color !default;
$menu-header-icon-padding: 0 16px !default;


@include export-module('menu-layout') {
  .e-menu-wrapper,
  .e-menu-container {
    border: $menu-ul-border;
    border-radius: $cmenu-ul-border-radius;
    display: inline-block;
    line-height: 0;

    & .e-menu-vscroll {
      height: inherit;
    }

    @at-root {
      & ul {
        @include ul-layout;
        @if $skin-name == 'tailwind3' {
          font-weight: $font-weight-medium;
        }
        font-size: $menu-font-size;
        padding: $menu-ul-padding;
        text-align: left;
        white-space: nowrap;

        & .e-separator {
          @if $skin-name == 'fluent2' {
            padding: 0 !important; /* stylelint-disable-line declaration-no-important */
          }
        }

        &.e-vertical {
          min-width: 120px;

          & .e-menu-item {
            display: list-item;
          }

          & .e-separator {
            @include separator-size;
          }
        }

        &.e-menu {
          display: inline-block;

          &:not(.e-vertical) {
            padding: $menu-ul-header-padding;

            & .e-separator {
              border-right-style: $menu-li-border-style;
              border-right-width: $menu-li-border-width;
              padding: $menu-separator-padding;
            }
          }

          & .e-menu-item .e-menu-icon {
            width: $menu-icon-width;
          }
        }

        & .e-menu-item  {
          @include li-layout;
          display: inline-flex;
          height: $menu-li-height;
          line-height: $menu-li-height;
          padding: $menu-li-padding;
          vertical-align: top;
          white-space: nowrap;

          & .e-menu-icon {
            font-size: $menu-icon-font-size;
            height: auto;
            line-height: $menu-li-height;
            margin-right: $menu-icon-margin-right;
            text-align: center;
            @if $skin-name == 'fluent2' {
              margin-bottom: 2px;
            }
            @else {
              margin-bottom: 4px;
            }
          }

          & .e-caret {
            font-size: $menu-caret-font-size;
            height: auto;
            @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'FluentUI' or $skin-name == 'Material3' or $skin-name == 'fluent2' {
              line-height: $menu-icon-li-height;
            }
            @else {
              line-height: $menu-li-height;
            }
            position: absolute;
            right: $menu-caret-right;
            top: 0;
            width: auto;
          }

          &.e-menu-caret-icon {
            padding-right: $menu-caret-li-padding;
          }

          &.e-disabled {
            cursor: auto;
            opacity: $menu-disable-opacity;
            pointer-events: none;
            @if $skin-name == 'tailwind3' {
              color: $flyout-text-color-disabled;
            }
          }
        }
      }

      & .e-ul {
        @include ul-size;
        min-width: $cmenu-min-width;
        @if $skin-name == 'tailwind3' {
          font-weight: $font-weight-normal;
        }

        & .e-menu-item {
          @include li-size;
          display: list-item;

          & .e-caret {
            font-size: $submenu-caret-font-size;
            @if $skin-name == 'fluent2' {
              padding: 9px 0;
            }
          }
        }
      }
    }

    &.e-menu-icon-right {
      & .e-menu-header .e-menu-icon {
        float: right;
      }

      & .e-menu-header .e-menu-title {
        padding: $menu-header-icon-padding;
      }
    }

    & .e-menu-header {
      display: none;
      font-family: $font-family;
      font-size: $menu-icon-font-size;
      font-weight: $font-weight;
      height: $menu-li-height;
      line-height: $menu-li-height;
      text-align: left;
      white-space: nowrap;
      @if $skin-name == 'tailwind3' {
        font-weight: $font-weight-medium;
      }

      & .e-menu-title,
      & .e-menu-icon {
        display: inline-block;
        line-height: inherit;
      }

      & .e-menu-icon {
        cursor: pointer;
        float: left;
        outline: none;
        padding: $menu-header-icon-padding;
      }
    }

    & .e-menu-hscroll.e-hscroll:not(.e-scroll-device) {
      @if $skin-name == 'bootstrap4' {
        padding: 4px $menu-hscroll-nav-size;
      }
      @else {
        padding: 0 $menu-hscroll-nav-size;
      }

      & .e-scroll-nav {
        width: $menu-hscroll-nav-size;
        @if $skin-name == 'tailwind3' {
          min-height: 38px;
        }

        &:focus,
        &:hover {
          border: 0;
        }

        &.e-scroll-right-nav {
          border-left: $menu-srollbar-ul-border;
        }

        &.e-scroll-left-nav {
          border-right: $menu-srollbar-ul-border;
        }
      }

      & .e-hscroll-bar {
        overflow-y: hidden;
      }
    }

    & .e-scroll-nav .e-nav-arrow {
      font-size: $menu-scroll-nav-icon-size;

      &.e-nav-left-arrow,
      &.e-nav-up-arrow {
        transform: rotate(180deg);
      }
    }

    &.e-rtl .e-scroll-nav .e-nav-arrow {
      &.e-nav-left-arrow {
        transform: rotate(0deg);
      }

      &.e-nav-right-arrow {
        transform: rotate(180deg);
      }
    }

    &.e-popup.e-menu-popup {
      border: $cmenu-ul-border;
      box-shadow: $cmenu-sub-ul-box-shadow;
      overflow: hidden;
      position: absolute;
    }

    & .e-menu-vscroll.e-vscroll {
      &:not(.e-scroll-device) {
        padding: $menu-vscroll-nav-size 0;
      }

      &.e-scroll-device {
        & .e-scroll-nav.e-scroll-down-nav {
          transform: none;
          width: 100%;

          & .e-nav-down-arrow {
            transform: none;
          }
        }
      }

      & .e-vscroll-bar {
        height: 100%;
        width: inherit;

        & .e-vscroll-content {
          width: inherit;
        }
      }

      & .e-scroll-nav {
        height: $menu-vscroll-nav-size;
      }
    }

    &.e-scrollable {
      display: block;

      & .e-menu {
        display: block;
        overflow: auto;
      }
    }

    &.e-contextmenu-container {
      height: 1px;

      .e-menu-vscroll ul.e-ul {
        width: inherit;
      }

      .e-menu-vscroll {
        border: $cmenu-ul-border;
        box-shadow: $cmenu-sub-ul-box-shadow;
      }
    }
  }

  .e-menu-container:not(.e-hamburger):not(.e-rtl) .e-menu {
    &.e-vertical {
      & .e-blankicon {
        padding-left: $menu-blank-icon;
      }
    }
  }

  .e-rtl.e-menu-wrapper ul,
  .e-rtl.e-menu-container ul {
    & .e-menu-item {
      @include rtl-li-layout;
      text-align: right;

      & .e-menu-icon {
        margin-left: $menu-icon-margin-right;
      }

      & .e-caret {
        left: $menu-caret-right;
      }

      &.e-menu-caret-icon {
        padding-left: $menu-caret-li-padding;
        padding-right: $menu-li-right-padding;
      }
    }

    &.e-ul {
      & .e-menu-item {
        @include rtl-li-size;
      }
    }
  }

  .e-rtl.e-menu-container:not(.e-hamburger) .e-menu {
    &.e-vertical {
      & .e-blankicon {
        padding-right: $menu-blank-icon;
      }
    }
  }

  .e-menu-wrapper.e-hamburger,
  .e-menu-container.e-hamburger {
    border: 0;
    display: block;
    position: relative;

    & .e-menu-header:not(.e-vertical) {
      border: $menu-ul-border;
      display: block;
    }

    & .e-popup.e-menu-popup {
      border: 0;
      border-radius: 0;
      box-shadow: none;
      display: block;
      position: relative;
      width: 100%;
    }

    & ul {
      &.e-menu {
        border: $menu-ul-border;
        overflow-y: auto;
        width: 100%;

        &.e-menu-parent.e-hide-menu {
          display: none;
        }

        & .e-menu-item {
          text-indent: $menu-li-text-indent;
          @if $skin-name == 'Material3' {
            border-radius: 0;
          }

          &.e-blankicon {
            text-indent: $menu-li-text-indent + $menu-icon-font-size + $menu-icon-margin-right;
          }

          & .e-menu-icon {
            display: inline;
            text-indent: 0;
          }

          &.e-menu-hide {
            display: none;
          }
        }

        /* stylelint-disable */
		    & .e-menu-item[aria-expanded = "true"] .e-caret {
			    -webkit-transition: -webkit-transform .3s ease-in-out;
          transition: -webkit-transform .3s ease-in-out;
          transition: transform .3s ease-in-out;
			    -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
          text-indent: 0;
		    }

		    & .e-menu-item[aria-expanded = "false"] .e-caret {
			    -webkit-transition: -webkit-transform .3s ease-in-out;
          transition: -webkit-transform .3s ease-in-out;
          transition: transform .3s ease-in-out;
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
          text-indent: 0;
        }
        /* stylelint-enable */
      }

      &.e-menu:not(.e-vertical) {
        border-top: 0;
        display: block;
        padding: 0;
        position: absolute;
      }

      &.e-ul {
        font-size: $menu-font-size;
        padding: 0;

        & .e-menu-item {
          line-height: $menu-li-height;
          text-indent: inherit;

          &.e-blankicon {
            padding: 0;
            text-indent: inherit;
          }

          & .e-caret {
            font-size: $menu-caret-font-size;
            right: $menu-caret-right;
          }

          &.e-selected {
            & .e-menu-icon,
            & .e-caret {
              @if $skin-name == 'bootstrap5.3' {
                color: $flyout-text-color-pressed;
              }
            }
          }
        }
      }

      & .e-menu-item {
        display: list-item;
        height: auto;
        padding: 0;

        &.e-menu-caret-icon,
        &.e-blankicon {
          padding: 0;
        }

        & .e-menu-url {
          display: inline-block;
          min-width: 120px;
          text-indent: 0;
          width: 100%;
        }

        &.e-separator {
          @include separator-size;
        }
      }
    }
    @if $skin-name == 'tailwind3' {
      .e-menu-header {
        background: $content-bg-color-alt1;
        .e-menu-icon {
          font-size: $menu-bigger-scroll-nav-icon-size;
        }
      }
      ul {
        background: $content-bg-color;
        .e-menu-item {
          & .e-menu-icon,
          & .e-caret {
            font-size: $menu-bigger-scroll-nav-icon-size;
          }
        }
      }
      .e-ul {
        font-weight: $font-weight-medium;
      }
    }
  }

  .e-rtl.e-menu-wrapper.e-hamburger ul,
  .e-rtl.e-menu-container.e-hamburger ul {
    & .e-menu-item {
      & .e-menu-caret-icon {
        padding-left: 0;
        padding-right: 0;
      }

      & .e-menu-icon {
        margin-left: 0;
        text-indent: inherit;
      }

      & .e-caret {
        left: $menu-caret-right;
        right: auto;
      }
    }
  }

  .e-menu-container .e-vscroll ul {
    position: relative;
  }
}



@include export-module('menu-theme') {
  .e-menu-wrapper,
  .e-menu-container {
    background: $menu-ul-bgcolor;

    @at-root {
      & .e-menu {
        @if $skin-name == 'bootstrap4' {
          background-color: $cmenu-ul-bgcolor;
        }
        @else {
          background: inherit;
        }
        color: $menu-color;

        &.e-menu {
          &:not(.e-vertical) {
            & .e-separator {
              border-right-color: $menu-seperator-border-color;
            }
          }
        }

        & .e-menu-item  {
          @if $skin-name == 'bootstrap4' {
            & .e-caret {
              color: $menu-caret-color;
            }

            & .e-menu-icon {
              color: $menu-icon-color;
            }
          }
          @else {
            @include icon-color;
            @if $skin-name == 'fluent2' {
              & .e-menu-icon,
              & .e-caret {
                color: $icon-color;
              }
            }
          }

          &.e-menu-header {
            border-bottom-color: $cmenu-li-border-color;
          }

          & .e-menu-url {
            color: $menu-color;
          }

          &.e-focused {
            color: $menu-icon-hover-color;
            outline: $menu-li-hover-outline;
            outline-offset: $menu-li-hover-outline-offset;
            @if $skin-name == 'bootstrap4' {
              background: $menu-ul-bgcolor;

              & .e-caret {
                color: $menu-caret-color;
              }

              & .e-menu-icon {
                color: $menu-icon-hover-color;
              }
            }
            @else {
              @include focused-icon-color;
              background: $menu-li-bgcolor;
              @if $skin-name == 'Material3' {
                border-radius: 2px;
              }
              @if $skin-name == 'fluent2' {
                border-radius: 4px;
              }
            }
          }

          &.e-selected,
          &.e-active-item {
            color: $menu-selected-color;
            outline: $menu-li-selected-outline;
            outline-offset: $menu-li-selected-outline-offset;
            @if $skin-name == 'bootstrap4' {
              background: $menu-ul-bgcolor;
            }
            @if $skin-name == 'FluentUI' {
              background: $cmenu-li-bgcolor;
            }
            @else {
              background: $menu-li-selection-bgcolor;
            }
            @if $skin-name == 'Material3' {
              border-radius: 2px;
            }
            @if $skin-name == 'fluent2' {
              border-radius: 4px;
            }

            & .e-caret {
              @if $skin-name == 'Material3' {
                color: $menu-icon-focussed-color;
              }
              @else {
                color: $menu-caret-color;
              }
            }

            & .e-menu-icon {
              @if $skin-name == 'Material3' {
                color: $menu-icon-focussed-color;
              }
              @else {
                color: $menu-caret-color;
              }
            }
          }

          &.e-separator {
            border-bottom-color: $menu-seperator-border-color;
          }

          &:focus-visible {
            @if $skin-name == 'fluent2' {
              border-radius: 4px;
              background-color: $content-bg-color;
              box-shadow: $shadow-focus-ring1;
            }
            @if $skin-name == 'tailwind3' {
              box-shadow: $shadow-focus-ring2;
              background: transparent;
              border-radius: 4px;
            }
          }
        }

        @include disabled-color;
      }

      & .e-ul {
        background: inherit;
        color: $submenu-color;

        & .e-menu-item {
          @if $skin-name == 'bootstrap4' {
            & .e-caret,
            & .e-menu-icon {
              color: $submenu-color;
            }
          }
          @else if $skin-name == 'FluentUI' or $skin-name == 'tailwind3' {
            & .e-caret,
            & .e-menu-icon {
              color: $menu-icon-color;
            }
          }

          &.e-focused {
            @if $skin-name == 'bootstrap4' {
              & .e-caret {
                color: $menu-caret-color;
              }

              & .e-menu-icon {
                color: $submenu-color;
              }
            }
            @if $skin-name == 'fluent2' {
              background-color: $flyout-bg-color-hover;
              border-radius: 4px;
            }
            @if $skin-name == 'tailwind3' {
              & .e-caret,
              & .e-menu-icon {
                color: $icon-color-hover;
              }
            }
          }

          &.e-selected,
          &.e-active-item {
            @if $skin-name == 'bootstrap4' {
              & .e-caret,
              & .e-menu-icon {
                color: $menu-icon-focussed-color;
              }
            }
            @if $skin-name == 'fluent2' {
              background-color: $flyout-bg-color-selected;
            }
            @if $skin-name == 'tailwind3' {
              & .e-caret,
              & .e-menu-icon {
                color: $menu-icon-color;
              }
            }
          }

          & .e-menu-url {
            color: $submenu-color;
          }

          &.e-focused {
            @include focused-color;
          }

          &.e-selected,
          &.e-active-item {
            @include selected-color;
          }

          &.e-separator {
            border-bottom-color: $cmenu-li-border-color;
          }

          &:focus-visible {
            @if $skin-name == 'fluent2' {
              background-color: $content-bg-color;
              box-shadow: $shadow-focus-ring1;
              border-radius: 4px;
            }
            @if $skin-name == 'tailwind3' {
              box-shadow: $shadow-focus-ring2;
              background: $flyout-bg-color;
            }
          }

          &:active {
            @if $skin-name == 'bootstrap5.3' {
              background-color: $cmenu-li-selection-bgcolor;
              color: $cmenu-selected-color;
            }
          }
        }
      }
    }

    &.e-menu-popup {
      background: $cmenu-ul-bgcolor;

      & .e-ul .e-menu-item.e-disabled,
      & .e-ul .e-menu-item.e-disabled .e-menu-icon {
        @if $skin-name == 'fluent2' {
          color: $flyout-text-color-disabled;
          opacity: 1;
        }
      }

      &.e-sfcontextmenu .e-menu-vscroll {
        background: inherit;
      }
    }

    & .e-menu-hscroll.e-hscroll .e-scroll-nav {
      background: $menu-ul-bgcolor;

      & .e-nav-arrow.e-icons {
        @if $skin-name == 'fluent2' {
          color: $icon-color;
        }
        @else if $skin-name == 'tailwind3' {
          color: $secondary-text-color-focus;
        }
        @else {
          color: $cmenu-caret-color;
        }
      }

      &:hover {
        @if $skin-name == 'bootstrap4' {
          background: darken($gray-600, 7.5%);
          border: 1px inset darken($gray-600, 10%);

          & .e-icons {
            color: $menu-icon-focussed-color;
          }
        }
        @else if $skin-name == 'Material3' {
          border-radius: 2px;
        }
        @else {
          background: $menu-li-bgcolor;
        }

        @if $skin-name == 'fluent2' {
          & .e-nav-arrow.e-icons {
            color: $icon-color-pressed;
          }
        }
      }

      &:focus {
        @if $skin-name == 'bootstrap4' {
          background: darken($gray-600, 7.5%);
          border-color: darken($gray-600, 10%);

          & .e-icons {
            color: $menu-icon-focussed-color;
          }
        }
        @else {
          background: $menu-li-bgcolor;
        }

        @if $skin-name == 'fluent2' {
          & .e-nav-arrow.e-icons {
            color: $icon-color-hover;
          }
        }
      }

      &:active {
        @if $skin-name == 'bootstrap4' {
          background: darken($gray-600, 7.5%);
          border: 1px inset darken($gray-600, 10%);
        }
        @else if $skin-name == 'tailwind3' {
          background: $secondary-bg-color-pressed;
        }
        @else {
          background: $menu-li-bgcolor;
        }
        @if $skin-name == 'fluent2' {
          & .e-nav-arrow.e-icons {
            color: $icon-color-pressed;
          }
        }
      }
    }

    &.e-menu-popup .e-menu-vscroll.e-vscroll .e-scroll-nav {
      background: $cmenu-ul-bgcolor;
      border-color: $menu-seperator-border-color;

      & .e-icons {
        color: $cmenu-color;
      }

      &:hover,
      &:focus {
        background: $cmenu-li-bgcolor;
        @if $skin-name == 'fluent2' {
          & .e-nav-arrow.e-icons {
            color: $flyout-text-color-selected;
          }
        }
      }

      &.e-overlay {
        @if $skin-name == 'fluent2' {
          & .e-nav-arrow.e-icons {
            color: $icon-color-disabled !important; /* stylelint-disable-line declaration-no-important */
          }
        }
      }

      &:active {
        background: $cmenu-li-selection-bgcolor;
      }
    }

    &.e-inherit {
      border: 0;

      & .e-menu,
      & .e-menu .e-menu-item .e-menu-icon,
      & .e-menu .e-menu-item .e-caret {
        color: inherit;
      }

      & .e-menu {
        background: inherit;
      }

      & .e-menu .e-menu-item.e-selected,
      & .e-menu .e-menu-item.e-active-item,
      & .e-menu .e-menu-item.e-focused {
        background: rgba(transparent, .056);
        color: inherit;
      }
    }
  }

  .e-menu-wrapper.e-hamburger,
  .e-menu-container.e-hamburger {
    & .e-menu-header {
      color: $submenu-color;

      & .e-menu-icon {
        color: $submenu-color;
      }
    }

    & ul {
      color: $submenu-color;

      @if $skin-name == 'bootstrap4' {
        & .e-selected .e-caret,
        & .e-active-item .e-caret,
        & .e-caret {
          color: $submenu-color;
        }
      }
    }
  }
}

@if $skin-name == 'fluent2' {
  .e-menu-wrapper.e-hamburger .e-menu-popup .e-menu-item.e-menu-caret-icon:not(.e-selected):not(.e-focused) .e-icons {
    color: $icon-color !important; /* stylelint-disable-line declaration-no-important */
  }
}
@if $skin-name == 'tailwind3' {
  .e-menu-wrapper.e-hamburger .e-menu .e-menu-item.e-selected {
    color: $primary;
  }
}


@include export-module('menu-material-icons') {
  .e-menu-wrapper,
  .e-menu-container {
    @at-root {
      & ul {
        &.e-vertical .e-menu-item {
          & .e-caret {
            &::before {
              content: '\e956';
            }
          }
        }

        & .e-menu-item  {
          & .e-caret {
            &::before {
              content: '\e94d';
            }
          }
        }
      }

      & .e-ul .e-menu-item {
        & .e-caret {
          &::before {
            content: '\e956';
          }
        }
      }
    }

    &.e-hamburger {
      & .e-menu-header {
        & .e-menu-icon::before {
          content: '\e99a';
        }
      }

      &.e-close-icon {
        & .e-menu-header {
          & .e-menu-icon::before {
            content: '\eb36';
          }
        }
      }

      & .e-vertical .e-menu-item .e-caret,
      & .e-menu-item .e-caret {
        &::before {
          content: '\e94d';
        }
      }
    }

    &.e-rtl.e-hamburger ul {
      &.e-ul {
        & .e-caret {
          &::before {
            content: '\e94d';
          }
        }
      }
    }

    & .e-menu-hscroll.e-hscroll .e-nav-left-arrow::before,
    & .e-menu-hscroll.e-hscroll .e-nav-right-arrow::before {
      content: '\e956';
    }

    & .e-menu-vscroll.e-vscroll .e-nav-up-arrow::before,
    & .e-menu-vscroll.e-vscroll .e-nav-down-arrow::before {
      content: '\e94d';
    }
  }

  .e-rtl.e-menu-wrapper ul,
  .e-rtl.e-menu-container ul,
  .e-bigger .e-rtl.e-menu-wrapper ul,
  .e-bigger.e-rtl.e-menu-wrapper ul,
  .e-bigger .e-rtl.e-menu-container ul,
  .e-bigger.e-rtl.e-menu-container ul {
    &.e-vertical,
    &.e-ul {
      & .e-caret {
        &::before {
          content: '\e937';
        }
      }
    }
  }

  .e-bigger .e-rtl.e-menu-wrapper.e-hamburger ul,
  .e-bigger.e-rtl.e-menu-wrapper.e-hamburger ul,
  .e-bigger .e-rtl.e-menu-container.e-hamburger ul,
  .e-bigger.e-rtl.e-menu-container.e-hamburger ul {
    &.e-ul {
      & .e-caret {
        &::before {
          content: '\e94d';
        }
      }
    }
  }
}

@mixin bigger-rtl-blank-icon {
  padding-left: $cmenu-caret-blank-icon;

  &.e-menu-caret-icon {
    padding-left: $cmenu-caret-li-padding;
  }
}

@mixin bigger-ul-size {
  font-size: $cmenu-bigger-font-size;
  padding: $cmenu-ul-bigger-padding;
  white-space: nowrap;
}

@mixin bigger-li-size {
  height: $cmenu-bigger-li-height;
  line-height: $cmenu-bigger-li-height;
  @if $skin-name == 'fluent2' {
    height: 40px;
    line-height: 21px;
    padding: 9px;
  }

  &.e-menu-caret-icon {
    @if $skin-name == 'fluent2' {
      padding: 9px 36px 9px 9px;
    }
  }

  & .e-menu-icon {
    font-size: $cmenu-icon-bigger-font-size;
    line-height: $cmenu-bigger-li-height;
    @if $skin-name == 'tailwind' {
      margin-right: $cmenu-icon-bigger-margin-right;
    }
  }

  & .e-caret {
    line-height: $cmenu-bigger-li-height;
    @if $skin-name == 'fluent2' {
      margin: 3px 0;
    }
  }

  &.e-separator {
    height: auto;
    line-height: normal;
  }
}

@include export-module('menu-bigger') {
  .e-bigger .e-menu-wrapper ul,
  .e-bigger.e-menu-wrapper ul,
  .e-bigger .e-menu-container ul,
  .e-bigger.e-menu-container ul {
    font-size: $menu-bigger-font-size;

    &.e-menu .e-menu-item {
      line-height: $menu-bigger-li-height;
      padding: $menu-bigger-li-padding;
      height: $menu-bigger-li-height;

      & .e-menu-icon {
        font-size: $menu-icon-bigger-font-size;
        line-height: $menu-bigger-li-height;
        margin-right: $menu-bigger-icon-margin-right;
        @if $skin-name == 'fluent2' {
          margin-bottom: 3px;
        }
      }

      & .e-caret {
        font-size: $menu-bigger-caret-font-size;
        @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'fluent2' {
          line-height: $menu-bigger-li-icon-height;
        }
        @else {
          line-height: $menu-bigger-li-height;
        }
        right: $menu-bigger-caret-right;
      }

      &.e-separator {
        padding: $menu-separator-bigger-padding;
      }
    }

    &.e-menu {
      &:not(.e-vertical) {
        padding: $menu-ul-bigger-header-padding;
      }

      &.e-vertical {
        & .e-menu-item {
          &.e-separator {
            height: auto;
            line-height: normal;
          }
        }
      }
    }

    &.e-ul {
      @include bigger-ul-size;
      min-width: $cmenu-bigger-min-width;
      @if $skin-name == 'tailwind3' {
        font-weight: $font-weight-normal;
      }

      & .e-menu-item  {
        @include bigger-li-size;
        @if $skin-name != 'fluent2' {
          padding: $cmenu-li-padding;
        }
        @if $skin-name == 'tailwind' or $skin-name == 'tailwind-dark' {
          padding: $menu-bigger-li-padding;
        }

        & .e-caret {
          font-size: $submenu-bigger-caret-font-size;
          right: $cmenu-caret-right;
        }

        &.e-menu-caret-icon {
          padding-right: $cmenu-caret-li-padding;
        }

        & .e-menu-icon {
          @if $skin-name == 'tailwind' {
            margin-right: $menu-icon-bigger-margin-right;
          }
          @else {
            margin-right: $cmenu-icon-margin-right;
          }
        }
      }
    }
  }

  .e-bigger .e-menu-container:not(.e-hamburger):not(.e-rtl) .e-menu,
  .e-bigger.e-menu-container:not(.e-hamburger):not(.e-rtl) .e-menu {
    &.e-vertical {
      & .e-blankicon {
        padding-left: $menu-blank-icon-bigger;
      }
    }
  }

  .e-bigger .e-menu-wrapper,
  .e-bigger.e-menu-wrapper,
  .e-bigger .e-menu-container,
  .e-bigger.e-menu-container {
    & .e-menu-hscroll.e-hscroll:not(.e-scroll-device) {
      padding: 0 $menu-bigger-hscroll-nav-size;

      & .e-scroll-nav {
        width: $menu-bigger-hscroll-nav-size;
        @if $skin-name == 'tailwind3' {
          min-height: 48px;
        }
      }
    }

    & .e-menu-vscroll.e-vscroll {
      &:not(.e-scroll-device) {
        padding: $menu-bigger-vscroll-nav-size 0;
      }

      & .e-scroll-nav {
        height: $menu-bigger-vscroll-nav-size;
      }
    }

    &.e-menu-popup {
      box-shadow: $cmenu-parent-ul-box-shadow;
    }

    & .e-scroll-nav .e-icons {
      font-size: $menu-bigger-scroll-nav-icon-size;
    }
  }

  .e-bigger .e-rtl.e-menu-wrapper ul,
  .e-bigger.e-rtl.e-menu-wrapper ul,
  .e-bigger .e-rtl.e-menu-container ul,
  .e-bigger.e-rtl.e-menu-container ul {
    & .e-menu-item {
      text-align: right;

      & .e-menu-icon {
        margin-left: $menu-bigger-icon-margin-right;
        margin-right: 0;
      }

      & .e-caret {
        left: $menu-bigger-caret-right;
        margin-left: 0;
        right: auto;
      }

      &.e-menu-caret-icon {
        padding-left: $menu-bigger-caret-li-padding;
        padding-right: $menu-bigger-li-right-padding;
      }
    }

    &.e-ul {
      & .e-menu-item {
        & .e-menu-icon {
          margin-left: $cmenu-icon-margin-right;
        }

        & .e-caret {
          left: $cmenu-caret-right;
        }

        &.e-menu-caret-icon {
          padding-left: $cmenu-caret-li-padding;
          @if $skin-name == 'fluent2' {
            padding-right: 9px;
          }
          @else {
            padding-right: $cmenu-li-right-padding;
          }
        }

        &.e-blankicon {
          @include bigger-rtl-blank-icon;
        }
      }
    }
  }

  .e-bigger .e-rtl.e-menu-container:not(.e-hamburger) .e-menu,
  .e-bigger.e-rtl.e-menu-container:not(.e-hamburger) .e-menu {
    &.e-vertical {
      & .e-blankicon {
        padding-right: $menu-blank-icon-bigger;
      }
    }
  }

  .e-bigger .e-menu-wrapper.e-hamburger ul,
  .e-bigger.e-menu-wrapper.e-hamburger ul,
  .e-bigger .e-menu-container.e-hamburger ul,
  .e-bigger.e-menu-container.e-hamburger ul {
    & .e-ul {
      max-width: 100%;

      & .e-menu-item {
        height: auto;
        line-height: $menu-bigger-li-height;
        padding: 0;
      }
    }
  }

  .e-bigger .e-menu-wrapper.e-hamburger,
  .e-bigger.e-menu-wrapper.e-hamburger,
  .e-bigger .e-menu-container.e-hamburger,
  .e-bigger.e-menu-container.e-hamburger {
    & .e-menu-header {
      font-size: $menu-icon-bigger-font-size;
      height: $menu-bigger-li-height;
      line-height: $menu-bigger-li-height;
    }

    & ul.e-menu {
      & .e-menu-item {
        text-indent: $menu-bigger-li-text-indent;

        &.e-blankicon {
          text-indent: $menu-bigger-li-text-indent + $menu-icon-bigger-font-size + $menu-bigger-icon-margin-right;
        }
      }

      & .e-ul {
        font-size: $menu-bigger-font-size;

        & .e-menu-item {
          text-indent: inherit;

          &.e-menu-caret-icon {
            padding-right: 0 !important; /* stylelint-disable-line declaration-no-important */
          }

          & .e-caret {
            font-size: $menu-bigger-caret-font-size;
            right: $menu-bigger-caret-right;
          }
        }
      }
    }
    @if $skin-name == 'tailwind3' {
      .e-menu-header {
        background: $content-bg-color-alt1;
        .e-menu-icon {
          font-size: $menu-bigger-scroll-nav-icon-size;
        }
      }
      ul {
        background: $content-bg-color;
        .e-menu-item {
          & .e-menu-icon,
          & .e-caret {
            font-size: $menu-bigger-scroll-nav-icon-size;
          }
        }
      }
      .e-ul {
        font-weight: $font-weight-medium;
      }
    }
  }

  .e-bigger .e-rtl.e-menu-wrapper.e-hamburger,
  .e-bigger.e-rtl.e-menu-wrapper.e-hamburger,
  .e-bigger .e-rtl.e-menu-container.e-hamburger,
  .e-bigger.e-rtl.e-menu-container.e-hamburger {
    & ul.e-menu {
      & .e-menu-item {
        padding: 0;
        text-indent: $menu-bigger-li-text-indent;

        &.e-blankicon {
          text-indent: $menu-bigger-li-text-indent + $menu-icon-bigger-font-size + $menu-bigger-icon-margin-right;
        }

        & .e-menu-icon {
          margin-left: 0;
          text-indent: inherit;
        }

        &.e-menu-caret-icon {
          padding-left: 0;
          padding-right: 0;
        }

        & .e-caret {
          left: $menu-caret-right;
          right: auto;
        }
      }

      & .e-ul {
        & .e-menu-item {
          text-indent: inherit;
        }
      }
    }
  }

  .e-bigger .e-menu-container .e-ul .e-menu-item .e-caret {
    @if $skin-name == 'fluent2' {
      padding: 9px 0;
    }
  }

  .e-bigger .e-menu-wrapper:not(.e-hamburger) ul.e-ul .e-menu-item:not(.e-separator),
  .e-bigger .e-menu-container:not(.e-hamburger) ul.e-ul .e-menu-item:not(.e-separator) {
    @if $skin-name == 'fluent2' {
      height: 40px;
    }
    @else {
      height: $cmenu-bigger-li-height;
    }
  }

  .e-bigger .e-menu-wrapper:not(.e-hamburger) ul .e-menu-item,
  .e-bigger .e-menu-container:not(.e-hamburger) ul .e-menu-item {
    &.e-menu-caret-icon {
      padding-right: $menu-bigger-caret-li-padding;
    }
  }

  .e-bigger .e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item,
  .e-bigger .e-menu-container.e-hamburger ul.e-menu .e-menu-item {
    padding: 0 !important; /* stylelint-disable-line declaration-no-important */
    height: auto !important; /* stylelint-disable-line declaration-no-important */
  }
}

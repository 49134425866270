@import "../base/base.gd.scss";
@import "../inputs/input.gd.scss";
@import "../buttons/check-box.gd.scss";

/*! TreeView's material theme wise override definitions and variables */
/* stylelint-disable */
$treeview-font-size: 13px !default;
$treeview-icon-font-size: 10px !default;
$treeview-image-font-size: 18px !default;

$treeview-big-font-size: 14px !default;
$treeview-big-icon-font-size: 12px !default;

$treeview-icon-color: rgba($grey-light-font, .54) !default;
$treeview-text-color: rgba($grey-light-font, .87) !default;
$treeview-item-border-color: transparent !default;
$treeview-item-active-bg: $grey-200 !default;
$treeview-icon-active-color: rgba($grey-light-font, .54) !default;
$treeview-text-active-color: $accent !default;
$treeview-item-active-border-color: $grey-200 !default;
$treeview-item-hover-bg: $grey-100 !default;
$treeview-icon-hover-color: rgba($grey-light-font, .54) !default;
$treeview-text-hover-color: rgba($grey-light-font, .87) !default;
$treeview-item-hover-border-color: $grey-100 !default;
$treeview-item-active-hover-bg: $grey-300 !default;
$treeview-icon-active-hover-color: rgba($grey-light-font, .54) !default;
$treeview-text-active-hover-color: $accent !default;
$treeview-item-active-hover-border-color: $grey-300 !default;
$treeview-text-disable-color: rgba($grey-light-font, .54) !default;
$treeview-icon-disable-color: rgba($grey-light-font, .87) !default;
$treeview-drag-line-bg: $accent !default;
$treeview-drag-line-color: rgba($grey-light-font, .54) !default;
$treeview-popup-bg-color: $grey-white !default;
$treeview-popup-border-color: $grey-200 !default;
$treeview-drop-count-bg: $accent !default;
$treeview-drop-count-border: $accent-font !default;
$treeview-drop-count-color: $accent-font !default;
$treeview-drag-item-bg: $grey-200 !default;
$treeview-drag-item-color: rgba($grey-light-font, .54) !default;
$treeview-drag-icon-color: rgba($grey-light-font, .54) !default;

$treeview-item-height: 32px !default;
$treeview-text-height: 30px !default;
$treeview-input-height: 30px !default;
$treeview-root-ul-padding: 0 0 0 24px !default;
$treeview-rtl-root-ul-padding: 0 24px 0 0 !default;
$treeview-child-ul-padding: 0 0 0 24px !default;
$treeview-rtl-child-ul-padding: 0 24px 0 0 !default;
$treeview-text-wrap-padding: 0 0 0 24px !default;
$treeview-rtl-text-wrap-padding: 0 24px 0 0 !default;
$treeview-icon-size: 24px !default;
$treeview-icon-margin: 0 0 0 -24px !default;
$treeview-rtl-icon-margin: 0 -24px 0 0 !default;
$treeview-icon-padding: 7px !default;
$treeview-text-padding: 0 5px !default;
$treeview-text-margin: 0 !default;
$treeview-image-size: 18px !default;
$treeview-image-margin: 0 0 0 5px !default;
$treeview-navigable-image-icon-margin: 0 10px 0 12px !default;
$treeview-navigable-uncheck-image-margin: 0 10px 0 0 !default;
$treeview-navigable-image-icon-rtl-margin: 0 12px 0 10px !default;
$treeview-navigable-uncheck-image-rtl-margin: 0 0 0 10px !default;
$treeview-navigable-icon-image-margin: 0 8px 0 0 !default;
$treeview-navigable-icon-image-margin-reverse: 0 0 0 8px !default;
$treeview-navigable-check-margin-bigger: 0 0 0 12px !default;
$treeview-navigable-icon-image-anchor-margin-bigger: 0 10px 0 6px !default;
$treeview-navigable-icon-image-anchor-margin-reverse-bigger: 0 6px 0 10px !default;
$treeview-navigable-icon-image-anchor-margin: 0 10px 0 2px !default;
$treeview-navigable-icon-image-anchor-margin-reverse: 0 2px 0 10px !default;
$treeview-navigable-rtl-margin-reverse: 0 12px 0 0 !default;
$treeview-rtl-image-margin: 0 5px 0 0 !default;
$treeview-input-padding: 0 7px !default;
$treeview-image-text-padding: 0 10px !default;
$treeview-icon-image-margin: 0 0 0 10px !default;
$treeview-rtl-icon-image-margin: 0 10px 0 0 !default;
$treeview-check-margin: 0 0 0 5px !default;
$treeview-rtl-check-margin: 0 5px 0 0 !default;
$treeview-check-text-padding: 0 10px !default;
$treeview-check-image-margin: 0 0 0 12px !default;
$treeview-rtl-check-image-margin: 0 12px 0 0 !default;
$treeview-drop-count-border-size: 1px !default;
$treeview-drop-count-font-size: 13px !default;
$treeview-edit-wrap-width: calc(100% - 2px) !default;
$treeview-check-wrap-width: calc(100% - 22px) !default;
$treeview-check-icon-wrap-width: calc(100% - 59px) !default;
$treeview-check-icon-img-wrap-width: calc(100% - 87px) !default;
$treeview-icon-wrap-width: calc(100% - 29px) !default;
$treeview-icon-img-wrap-width: calc(100% - 57px) !default;

$treeview-big-item-height: 40px !default;
$treeview-big-text-height: 38px !default;
$treeview-big-input-height: 38px !default;
$treeview-big-text-padding: 0 10px !default;
$treeview-big-input-padding: 0 9px !default;
$treeview-big-icon-padding: 6px !default;
$treeview-big-image-margin: 0 0 0 10px !default;
$treeview-big-rtl-image-margin: 0 10px 0 0 !default;
$treeview-big-icon-image-margin: 0 0 0 10px !default;
$treeview-big-rtl-icon-image-margin: 0 10px 0 0 !default;
$treeview-big-check-margin: 0 0 0 10px !default;
$treeview-big-rtl-check-margin: 0 10px 0 0 !default;
$treeview-big-check-image-margin: 0 0 0 16px !default;
$treeview-big-rtl-check-image-margin: 0 16px 0 0 !default;
$treeview-big-check-wrap-width: calc(100% - 29px) !default;
$treeview-big-check-icon-wrap-width: calc(100% - 70px) !default;
$treeview-big-check-icon-img-wrap-width: calc(100% - 98px) !default;
$treeview-big-icon-wrap-width: calc(100% - 34px) !default;
$treeview-big-icon-img-wrap-width: calc(100% - 62px) !default;

$treeview-font-family: $font-family !default;
$treeview-drag-icon-font-size: 12px !default;
$treeview-drag-icon-padding: 6px !default;

$ripple-size: -7px !default;
$ripple-height: 32px !default;
$ripple-width: 32px !default;

/* stylelint-disable property-no-vendor-prefix */
@mixin user-select {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

@mixin sibling-style {
  @if $skin-name == 'material' {
    border-radius: 10px;
  }
  @else {
    border: 4px solid transparent;
  }
}

@mixin sibling-before($position) {
  @if $skin-name == 'material' {
    @if $position == 'rtl' {
      right: 6px;
      top: 3px;
    }
    @else {
      left: 6px;
      top: 3px;
    }
  }
  @else {
    @if $position == 'rtl' {
      right: 0;
    }
    @else {
      left: 0;
    }
  }
}

@mixin big-icon-style($size) {
  height: $size;
  width: $size;
}

@include export-module('treeview-layout') {
  @keyframes rotation {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }

  .e-treeview {
    display: block;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    @if $skin-name == 'fluent2' or $skin-name == 'tailwind3' {
      border: 1px solid;
      border-color: $treeview-border-color;
      border-radius: $treeview-radius;
    }
    @if $skin-name == 'tailwind3' {
      background: $treeview-bg-color;
    }

    &.e-virtualization {
      overflow: auto;

      & .e-virtual-mask {
        display: block;
        margin-bottom: 20px;
      }

      & .e-ul {
        overflow: unset;
      }
    }

    & > .e-ul {
      -webkit-overflow-scrolling: touch;
      overflow: auto;
    }

    &.e-text-wrap {
      .e-list-text {
        white-space: normal;
        word-break: break-word;
      }

      &.e-ie-wrap {
        .e-list-text {
          word-break: break-all;
        }
      }

      .e-editing {
        .e-list-text,
        .e-list-text .e-input-group {
          max-width: $treeview-edit-wrap-width;
        }
      }

      .e-checkbox-wrapper {
        & + .e-list-text {
          max-width: $treeview-check-wrap-width;
        }

        & + .e-list-icon,
        & + .e-list-img {
          & + .e-list-text {
            max-width: $treeview-check-icon-wrap-width;
          }
        }

        & + .e-list-icon + .e-list-img {
          & + .e-list-text {
            max-width: $treeview-check-icon-img-wrap-width;
          }
        }
      }

      .e-list-icon,
      .e-list-img {
        & + .e-list-text {
          max-width: $treeview-icon-wrap-width;
        }
      }

      .e-list-icon + .e-list-img {
        & + .e-list-text {
          max-width: $treeview-icon-img-wrap-width;
        }
      }
    }

    @if ($skin-name == 'fluent2') {
      .e-checkbox-wrapper .e-frame {
        margin-right: 0;
      }
    }

    .e-ul {
      margin: 0;
      padding: $treeview-root-ul-padding;
    }

    .e-node-collapsed .e-list-item .e-fullrow,
    .e-display-none {
      display: none;
    }

    .e-list-item {
      list-style: none;
      @if $skin-name != 'tailwind3' and $skin-name != 'tailwind' and $skin-name != 'Material3' and $skin-name != 'fluent2' {
        padding: 2px 0;
      }
      @if $skin-name == 'fluent2' {
        padding: 4px 0;
      }

      .e-ul {
        margin: 2px 0 -2px;
        padding: $treeview-child-ul-padding;
        @if $skin-name == 'tailwind' {
          margin: 2px 0 0;
        }
        @else if $skin-name == 'tailwind3' or $skin-name == 'Material3' {
          margin: 0;
        }
      }

      &.e-disable {

        > .e-text-content,
        > .e-fullrow {
          -ms-touch-action: none;
          opacity: .5;
          pointer-events: none;
          touch-action: none;
        }
      }

      &.e-active {
        @if $skin-name == 'tailwind' {
          font-weight: $treeview-active-font-weight;
        }
      }

      div.e-icons.interaction {
        -webkit-transition: -webkit-transform .3s ease-in-out;
        border-radius: 15px;
        transition: -webkit-transform .3s ease-in-out;
        transition: transform .3s ease-in-out;
        transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
      }

      .e-icons.e-icon-collapsible {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
      }

      .e-icons.e-icons-spinner::before {
        content: none;
      }
    }

    .e-icons {
      .e-spinner-pane {
        position: relative;
      }
      .e-treeview-spinner {
        position: absolute;
      }
    }

    .e-icons-spinner {
      position: relative;
    }

    .e-text-content {
      @include user-select;
      border: 1px solid;
      cursor: pointer;
      margin: 0;
      padding: $treeview-text-wrap-padding;

      @if $skin-name == 'Material3' or $skin-name == 'tailwind3' {
        border: none;
      }

      + .e-sibling {
        @if ($skin-name == 'tailwind') {
          margin-top: -2px;
        }
        @else if ($skin-name == 'bootstrap5' or $skin-name == 'FluentUI') {
          margin-top: 1px;
        }
        @else {
          margin-top: -1px;
        }
      }
    }

    .e-fullrow {
      @include user-select;
      border: 1px solid;
      box-sizing: border-box;
      cursor: pointer;
      height: $treeview-item-height;
      left: 0;
      overflow: hidden;
      position: absolute;
      width: 100%;
      @if $skin-name == 'fluent2' {
        border-radius: $treeview-radius;
        width: calc(100% - 8px);
        margin-left: 4px;
      }
    }

    .e-checkbox-wrapper {
      margin: $treeview-check-margin;
      pointer-events: all;
      position: relative;

      & + .e-list-icon,
      & + .e-list-img {
        margin: $treeview-check-image-margin;
      }

      & + .e-list-text {
        padding: $treeview-check-text-padding;
      }

      .e-ripple-container {
        bottom: $ripple-size;
        height: $ripple-height;
        left: $ripple-size;
        right: $ripple-size;
        top: $ripple-size;
        width: $ripple-width;
      }
    }

    .e-list-text {
      box-sizing: border-box;
      display: inline-block;
      line-height: $treeview-text-height;
      margin: $treeview-text-margin;
      @if $skin-name == 'Material3' {
        min-height: $treeview-item-height;
      }
      @else if $skin-name != 'bootstrap4' {
        min-height: $treeview-text-height;
      }
      @else if $skin-name == 'bootstrap4' {
        min-height: 30px;
      }
      @if $skin-name == 'tailwind3' {
        font-weight: $treeview-font-weight;
      }
      padding: $treeview-text-padding;
      text-decoration: none;
      vertical-align: middle;

      .e-input-group {
        height: $treeview-input-height;
        @if ($skin-name == 'FluentUI') {
          margin-top: 3px;
        }
        @else if($skin-name == 'tailwind') {
          margin-bottom: 3px;
        }
        @else if($skin-name == 'bootstrap5') {
          margin-bottom: 2px;
        }
        @else {
          margin-bottom: 0;
        }
        min-width: 150px;
        vertical-align: bottom;
        @if ($skin-name == 'fluent2') {
          border-color: $treeview-input-border-color;
        }
        @if $skin-name != 'material' {
          .e-input {
            height: 28px;
          }
        }
      }
      @if ($skin-name == 'fluent2') {
        .e-input-group:hover {
          border-color: $treeview-input-border-color;
          border-bottom-color: $treeview-input-border-color;
        }
      }
    }

    .e-navigable-text {
      @if ($skin-name != 'Material3') {
        padding: $treeview-text-padding;
      }
      @else {
        padding: 0;
      }
    }

    .e-list-icon,
    .e-list-img {
      display: inline-block;
      height: $treeview-image-size;
      margin: $treeview-image-margin;
      vertical-align: middle;
      width: $treeview-image-size;

      & + .e-list-icon,
      & + .e-list-img {
        margin: $treeview-icon-image-margin;
      }

      & + .e-list-text {
        padding: $treeview-image-text-padding;
      }

      & + .e-navigable-text {
        padding: $treeview-image-text-padding;
      }
    }

    .e-icon-collapsible,
    .e-icon-expandable {
      display: inline-block;
      height: $treeview-icon-size;
      margin: $treeview-icon-margin;
      vertical-align: middle;
      width: $treeview-icon-size;

      &::before {
        display: inline-block;
        padding: $treeview-icon-padding;
      }
    }

    .e-load {
      -webkit-animation: rotation .5s infinite linear;
      animation: rotation .5s infinite linear;
    }

    .e-sibling {
      @if ($skin-name == 'tailwind' or $skin-name == 'bootstrap5' or $skin-name == 'FluentUI') {
        width: 144px;
      }
      @else {
        @include sibling-style;
        height: 6px;
        margin-top: -5px;
        width: 6px;
      }

      &::before {
        @include sibling-before(ltr);
        @if ($skin-name == 'tailwind' or $skin-name == 'bootstrap5' or $skin-name == 'FluentUI') {
          height: 2px;
          top: -1.5px;
        }
        @else {
          height: 1px;
          width: 144px;
        }
      }
    }

    .e-sibling,
    .e-sibling::before {
      position: absolute;
      z-index: 2;
    }

    .e-popup {
      @include user-select;
      font-weight: normal;
      position: absolute;
      z-index: 99999;

      .e-content {
        border-radius: 4px;
        border-style: solid;
        border-width: 1px;
        font-size: 14px;
        padding: 4px;
      }

      .e-icons {
        border: 1px solid transparent;
        cursor: pointer;
        display: inline-block;
        height: 26px;
        line-height: 18px;
        padding: 4px;
        width: 26px;
      }

      .e-downtail {
        &::before,
        &::after {
          border: 10px solid transparent;
          content: '';
          height: 0;
          left: 8px;
          position: absolute;
          width: 0;
        }

        &::after {
          bottom: -18px;
        }
      }
    }

    &.e-fullrow-wrap {

      .e-text-content {
        pointer-events: none;
        position: relative;
      }

      .e-icon-collapsible,
      .e-icon-expandable,
      .e-input,
      .e-list-url {
        pointer-events: auto;
      }
    }

    & .e-navigable {

      .e-text-content {
        align-items: center;
        display: flex;
      }

      .e-list-url {
        @if ($skin-name != 'Material3' and $skin-name != 'tailwind3') {
          padding: 0;
        }
        width: 100%;
      }

      .e-checkbox-wrapper + .e-list-url .e-anchor-wrap {
        @if $skin-name == 'bootstrap4' {
          padding: $treeview-navigable-icon-image-margin-reverse;
        }
        @else {
          padding: $treeview-icon-image-margin;
        }

        .e-list-icon,
        .e-list-img {
          margin: $treeview-navigable-icon-image-anchor-margin;
        }

        .e-list-icon + .e-list-img {
          margin: $treeview-rtl-icon-image-margin;
        }
      }

      .e-anchor-wrap {
        padding: $treeview-check-margin;
      }

      .e-nav-wrapper {
        padding: 0;
      }

      .e-checkbox-wrapper + .e-list-text .e-nav-wrapper:not(:has(.e-list-icon)) {
        padding: 0;
      }

      .e-list-icon,
      .e-list-img {
        @if $skin-name == 'bootstrap4' {
          margin: $treeview-navigable-icon-image-margin;
        }
        @else {
          margin: $treeview-rtl-icon-image-margin;
        }
      }
    }

    &.e-drag-item {
      overflow: visible;
      z-index: 10000;

      .e-text-content {
        float: left;

        @if ($skin-name == 'Material3') {
          .e-list-img {
            margin: $treeview-drag-icon-img-margin;
          }
        }

        .e-list-text {
          @if ($skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'FluentUI') {
            padding: 0 $treeview-drag-icon-padding;
          }
          @if $skin-name == 'fluent2' {
            line-height: 20px;
            padding: 5px 12px 7px 8px;
          }
        }
      }

      @if ($skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'FluentUI' or $skin-name == 'fluent2') {
        .e-icon-collapsible,
        .e-icon-expandable {
          @if ($skin-name != 'bootstrap5' and $skin-name != 'bootstrap5.3' and $skin-name != 'FluentUI') {
            @if ($skin-name == 'fluent2') {
              margin: 0;
            }
            @else {
              margin: $treeview-drag-icon-margin;
            }
          }
          @if ($skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'FluentUI') {
            box-sizing: content-box;
            padding-left: $treeview-drag-icon-padding;
            @if ($skin-name == 'FluentUI') {
              padding-top: 8px;
            }
          }
        }
      }

      .e-icon-collapsible::before,
      .e-icon-expandable::before {
        font-size: $treeview-drag-icon-font-size;
        @if ($skin-name != 'bootstrap5' or $skin-name == 'bootstrap5.3' and $skin-name != 'FluentUI') {
          padding: $treeview-drag-icon-padding;
        }
        @if ($skin-name == 'bootstrap4' or $skin-name == 'tailwind') {
          padding-right: $treeview-drag-icon-padding-right;
        }
      }

      .e-drop-count {
        border: $treeview-drop-count-border-size solid;
        border-radius: 15px;
        box-sizing: content-box;
        font-size: $treeview-drop-count-font-size;
        @if $skin-name == 'bootstrap4' {
          line-height: 1.5;
          min-width: 10px;
          padding: 0 5px;
        }
        @else {
          line-height: normal;
          min-width: 12px;
          @if ($skin-name == 'tailwind' or $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'FluentUI') {
            padding: 1px 3px 2px;
          }
          @else {
            padding: 3px 5px 4px;
          }
        }
        margin-left: -12px;
        position: absolute;
        text-align: center;
        top: -10px;
      }
    }

    &.e-dragging {

      .e-text-content,
      .e-fullrow {
        cursor: default;
      }
    }

    &.e-rtl {

      & .e-navigable {

        .e-checkbox-wrapper + .e-list-url .e-anchor-wrap {

          @if $skin-name == 'bootstrap4' {
            padding: $treeview-navigable-icon-image-margin;
          }
          @else {
            padding: $treeview-rtl-icon-image-margin;
          }

          .e-list-icon,
          .e-list-img {
            margin: $treeview-navigable-icon-image-anchor-margin-reverse;
          }

          .e-list-icon + .e-list-img {
            margin: $treeview-icon-image-margin;
          }
        }

        .e-anchor-wrap {
          padding: $treeview-rtl-check-margin;
        }

        .e-nav-wrapper {
          padding: 0;
        }

        .e-list-icon,
        .e-list-img,
        .e-list-icon + .e-list-img {
          @if $skin-name == 'bootstrap4' {
            margin: $treeview-navigable-icon-image-margin-reverse;
          }
          @else {
            margin: $treeview-icon-image-margin;
          }
        }
      }

      .e-ul {
        padding: $treeview-rtl-root-ul-padding;
      }

      .e-list-item {

        .e-ul {
          padding: $treeview-rtl-child-ul-padding;
        }
      }

      .e-text-content {
        padding: $treeview-rtl-text-wrap-padding;
      }

      .e-checkbox-wrapper {
        margin: $treeview-rtl-check-margin;

        & + .e-list-icon,
        & + .e-list-img {
          margin: $treeview-rtl-check-image-margin;
        }
      }

      .e-list-icon,
      .e-list-img {
        margin: $treeview-rtl-image-margin;

        & + .e-list-icon,
        & + .e-list-img {
          margin: $treeview-rtl-icon-image-margin;
        }
      }

      .e-icon-collapsible,
      .e-icon-expandable {
        margin: $treeview-rtl-icon-margin;
      }

      .e-sibling::before {
        @include sibling-before(rtl);
      }

      &.e-drag-item {

        .e-icons.e-drop-next {
          transform: rotate(180deg);
        }

        .e-text-content {
          float: right;
        }

        .e-icon-collapsible,
        .e-icon-expandable {
          @if ($skin-name == 'bootstrap4' or $skin-name == 'tailwind') {
            margin: $treeview-rtl-drag-margin;
          }
        }

        .e-drop-count {
          margin-right: -12px;
        }
      }

      div.e-icons {
        transform: rotate(180deg);
      }
    }

    &.e-disabled {
      .e-fullrow,
      .e-icons,
      .e-text-content,
      .e-list-img,
      .e-list-icon {
        cursor: auto;
      }

      .e-list-url {
        cursor: default;
        pointer-events: none;
      }
    }

    &.e-interaction.e-fullrow-wrap {
      .e-text-content {
        pointer-events: auto;
      }
    }
  }
  /* stylelint-enable property-no-vendor-prefix */
}


/* stylelint-disable property-no-vendor-prefix */
@mixin sibling-theme($left, $right) {
  @if $skin-name == 'material' {
    background: $treeview-drag-line-bg;
  }
  @else {
    border-#{$left}-color: $treeview-drag-line-bg;
    border-#{$right}-color: transparent;
  }
}

@mixin bg-border($background, $border) {
  background-color: $background;
  border-color: $border;
}

@mixin active-hover-theme {
  @if $theme-name =='fluentui-dark' {
    background-color: lighten($treeview-item-active-bg, 5%);
  }
  @else {
    @if ($skin-name == 'bootstrap5.3' or $skin-name == 'tailwind3') {
      background-color: $treeview-item-active-bg;
    }

    @else {
      background-color: $treeview-item-active-hover-bg;
    }
  }
  border-color: $treeview-item-active-hover-border-color;
  @if $skin-name == 'bootstrap' or $skin-name == 'highcontrast' {
    -webkit-box-shadow: $treeview-border-shadow;
    box-shadow: $treeview-border-shadow;
  }
}

@mixin active-editing-theme {
  @include bg-border($treeview-item-border-color, $treeview-item-border-color);
  @if $skin-name == 'bootstrap' {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

@mixin active-font-weight {
  @if $skin-name == 'highcontrast' {
    font-weight: $treeview-active-font-weight;
  }
}

@mixin text-content($text-color, $icon-color) {
  color: $text-color;

  .e-list-text {
    color: $text-color;
    .e-input {
      @include active-font-weight;
    }
  }

  .e-icon-collapsible,
  .e-icon-expandable {
    color: $icon-color;
  }
}

@include export-module('treeview-theme') {
  .e-treeview {
    -webkit-tap-highlight-color: transparent;

    @if $skin-name == 'bootstrap5' {
      &.e-disabled {
        opacity: .5;
      }
    }

    .e-text-content,
    .e-fullrow {
      border-color: $treeview-item-border-color;
    }

    .e-list-text {
      color: $treeview-text-color;
      font-size: $treeview-font-size;

      .e-input {
        @include active-font-weight;
      }
    }

    .e-list-icon,
    .e-list-img {
      font-size: $treeview-image-font-size;
    }

    .e-icon-collapsible,
    .e-icon-expandable {
      color: $treeview-icon-color;

      &::before {
        font-size: $treeview-icon-font-size;
      }
    }

    .e-list-item {
      &.e-hover,
      &.e-node-focus {
        background: transparent;

        > .e-fullrow {
          @include bg-border($treeview-item-hover-bg, $treeview-item-hover-border-color);
        }

        > .e-text-content {
          @include text-content($treeview-text-hover-color, $treeview-icon-hover-color);
        }
      }

      &.e-active {
        background: transparent;

        > .e-fullrow {
          background-color: $treeview-item-active-bg;
          @if $skin-name != 'fluent2' {
            border-color: $treeview-item-active-border-color;
          }
        }

        &.e-animation-active {
          > .e-fullrow {
            @include bg-border(transparent, transparent);
          }

          > .e-text-content {
            color: $treeview-text-color;

            .e-list-text {
              color: $treeview-text-color;
            }
          }
        }

        > .e-text-content {
          @include text-content($treeview-text-active-color, $treeview-icon-active-color);

          .e-list-text {
            @include active-font-weight;
          }

          .e-check {
            @if $skin-name == 'highcontrast' {
              @include bg-border($treeview-checkmark-bgcolor, $treeview-checkmark-border-color);
              color: $treeview-checkmark-color;
            }
            @else if $skin-name == 'bootstrap4' {
              @include bg-border($treeview-item-active-check-bg, $treeview-item-active-check-border-color);
              color: $treeview-item-active-check-color;
            }
            @else if $skin-name == 'bootstrap5' or $skin-name == 'FluentUI' {
              border-color: $content-text-color-selected;
            }
          }

          .e-stop {
            @if $skin-name == 'bootstrap5' or $skin-name == 'FluentUI' {
              border-color: $content-text-color-selected;
            }
          }
        }

        &.e-hover,
        &.e-node-focus {

          > .e-fullrow {
            @include active-hover-theme;
          }

          > .e-text-content {
            @include text-content($treeview-text-active-hover-color, $treeview-icon-active-hover-color);
          }
        }
      }

      &.e-editing {
        &.e-active,
        &.e-hover,
        &.e-node-focus {
          > .e-fullrow {
            @include active-editing-theme;
          }

          > .e-text-content {
            @include text-content ($treeview-text-color, $treeview-icon-color);
          }
        }
      }

      &.e-disable {
        > .e-text-content,
        > .e-fullrow {
          color: $treeview-text-disable-color;
          @if $skin-name == 'bootstrap5' or $skin-name == 'FluentUI' or $skin-name == 'fluent2' {
            .e-list-text {
              color: $treeview-text-disable-color;
            }
          }
        }

        > .e-text-content {
          > .e-icon-collapsible,
          > .e-icon-expandable {
            color: $treeview-icon-disable-color;
          }
        }
      }
    }

    .e-sibling {
      @if ($skin-name != 'tailwind' and $skin-name != 'bootstrap5' and $skin-name != 'FluentUI') {
        @include sibling-theme(left, right);
      }
      @else {
        border-top: 2px solid $treeview-drag-line-bg;
      }

      &::before {
        background: $treeview-drag-line-color;
        @if $skin-name == 'bootstrap4' {
          border: 1px;
        }
        @if ($skin-name == 'fluent2') {
          border: 1px solid $treeview-sibling-border-color;
        }
      }
    }

    .e-popup {
      .e-content {
        @include bg-border($treeview-popup-bg-color, $treeview-popup-border-color);
      }

      &.e-select {
        .e-icons {
          border-color: $treeview-popup-border-color;
        }
      }

      .e-downtail {
        &::before {
          border-top-color: $treeview-popup-border-color;
        }
        &::after {
          border-top-color: $treeview-popup-bg-color;
        }
      }
    }

    &:not(.e-fullrow-wrap) {
      .e-list-item {
        &.e-hover,
        &.e-node-focus {
          > .e-text-content {
            @include bg-border($treeview-item-hover-bg, $treeview-item-hover-border-color);
          }
        }

        &.e-active {
          > .e-text-content {
            @include bg-border($treeview-item-active-bg, $treeview-item-active-border-color);
          }

          &.e-hover,
          &.e-node-focus {
            > .e-text-content {
              @include active-hover-theme;
            }
          }
        }

        &.e-editing {
          &.e-active,
          &.e-hover,
          &.e-node-focus {
            > .e-text-content {
              @include active-editing-theme;
            }
          }
        }
      }
    }

    &.e-fullrow-wrap {
      .e-text-content {
        border-color: transparent;
      }
    }

    &.e-drag-item {
      background-color: $treeview-drag-item-bg;
      font-family: $treeview-font-family;
      @if ($skin-name == 'Material3') {
        background: $treeview-drag-item-bg;
      }

      .e-icon-collapsible,
      .e-icon-expandable {
        &::before {
          font-size: $treeview-drag-icon-font-size;
        }
      }

      .e-list-text {
        @include active-font-weight;
        color: $treeview-drag-item-color;
        @if $skin-name == 'bootstrap4' {
          padding: $treeview-drag-text-padding;
        }
      }

      .e-text-content {
        @if $skin-name == 'bootstrap4' {
          -webkit-box-shadow: $treeview-drag-item-box-shadow;
          border-radius: .5em;
          box-shadow: $treeview-drag-item-box-shadow;
        }
      }

      .e-icons {
        color: $treeview-drag-icon-color;
      }

      .e-drop-count {
        @include bg-border($treeview-drop-count-bg, $treeview-drop-count-border);
        color: $treeview-drop-count-color;
      }

      &.e-rtl {
        .e-sibling {
          @if ($skin-name != 'tailwind' and $skin-name != 'bootstrap5' and $skin-name != 'FluentUI') {
            @include sibling-theme(right, left);
          }
          @else {
            border: 1px solid $treeview-drag-line-bg;
          }
        }
      }
    }
    /* stylelint-enable property-no-vendor-prefix */
  }
}


@include export-module('treeview-material-icons') {

  /*! TreeView icons */
  .e-treeview {

    .e-list-item {

      div.e-icons:not(.e-icons-spinner).e-icon-expandable::before,
      div.e-icons:not(.e-icons-spinner).e-icon-collapsible::before {
        content: '\e22f';
      }
    }

    .e-sibling::before {
      content: '';
    }

    .e-popup {

      .e-icons::before {
        content: '\e930';
      }
    }

    &.e-drag-item {

      .e-icons.e-drop-in::before {
        content: '\e22c';
      }

      .e-icons.e-drop-out::before {
        content: '\e22b';
      }

      .e-icons.e-drop-next::before {
        content: '\e22d';
      }

      .e-icons.e-no-drop::before {
        content: '\e22a';
      }
    }
  }
}

@mixin big-icon-style($size) {
  height: $size;
  width: $size;
}

@include export-module('treeview-bigger') {
  .e-bigger .e-treeview,
  .e-treeview.e-bigger {

    @if $skin-name == 'tailwind3' {
      border-radius: $treeview-bigger-radius;
    }

    @if $skin-name == 'bootstrap4' {
      .e-ul,
      .e-list-item .e-ul {
        padding-left: 16px;
      }
    }

    @if $skin-name == 'tailwind' or $skin-name == 'bootstrap5' or $skin-name == 'FluentUI' or $skin-name == 'Material3' or $skin-name == 'fluent2' {
      .e-ul {
        padding: $treeview-big-root-ul-padding;
      }

      .e-list-item {
        .e-ul {
          padding: $treeview-big-child-ul-padding;
        }

        .e-text-content {
          padding: $treeview-big-text-wrap-padding;
        }

        .e-list-icon,
        .e-list-img {
          @if $skin-name == 'fluent2' {
            @include big-icon-style($treeview-big-img-size);
          }
          @else {
            @include big-icon-style($treeview-big-icon-font-size);
          }
        }

        .e-small.e-css.e-checkbox-wrapper {
          & .e-frame {
            height: 20px;
            line-height: 17px;
            width: 20px;
          }

          & .e-check,
          & .e-stop {
            font-size: 12px;
          }

          & .e-stop {
            line-height: 17px;
          }
        }

        .e-checkbox-wrapper + .e-list-text {
          padding: $treeview-big-check-text-padding;
        }
      }

      .e-icon-collapsible,
      .e-icon-expandable {
        @include big-icon-style($treeview-big-icon-font-size);
        margin: $treeview-big-icon-margin;
      }

      &.e-drag-item {

        @if $skin-name == 'FluentUI' {
          .e-icon-expandable,
          .e-icon-collapsible {
            padding-left: 24px;
            padding-top: 10px;
          }
        }

        .e-drop-count {
          padding: 3px 5px 4px;
        }
      }
    }

    .e-navigable {

      .e-list-text {
        @if ($skin-name != 'Material3' and $skin-name != 'tailwind3') {
          padding: 0;
        }
        width: 100%;
      }

      .e-checkbox-wrapper {
        + .e-list-text {
          padding: 0;
        }

        + .e-list-url .e-anchor-wrap {
          @if $skin-name == 'bootstrap4' {
            padding: $treeview-check-image-margin;
          }

          .e-list-icon,
          .e-list-img {
            margin: $treeview-navigable-icon-image-anchor-margin-bigger;
          }
        }
      }

      .e-anchor-wrap {
        @if $skin-name == 'bootstrap4' {
          padding: $treeview-navigable-check-margin-bigger;
        }
        @else {
          padding: $treeview-icon-image-margin;
        }
      }

      .e-list-icon,
      .e-list-img,
      .e-list-icon + .e-list-img {

        @if $skin-name == 'bootstrap4' {
          margin: $treeview-rtl-check-image-margin;
        }
        @else {
          margin: $treeview-rtl-icon-image-margin;
        }
      }
    }

    .e-fullrow {
      height: $treeview-big-item-height;
    }

    &.e-text-wrap {
      .e-checkbox-wrapper {
        & + .e-list-text {
          max-width: $treeview-big-check-wrap-width;
        }

        & + .e-list-icon,
        & + .e-list-img {
          & + .e-list-text {
            max-width: $treeview-big-check-icon-wrap-width;
          }
        }

        & + .e-list-icon + .e-list-img {
          & + .e-list-text {
            max-width: $treeview-big-check-icon-img-wrap-width;
          }
        }
      }

      .e-list-icon,
      .e-list-img {
        & + .e-list-text {
          max-width: $treeview-big-icon-wrap-width;
        }
      }

      .e-list-icon + .e-list-img {
        & + .e-list-text {
          max-width: $treeview-big-icon-img-wrap-width;
        }
      }
    }

    .e-list-text {
      line-height: $treeview-big-text-height;
      @if $skin-name != 'bootstrap4' {
        min-height: $treeview-big-text-height;
      }
      @else if $skin-name == 'bootstrap4' {
        min-height: 38px;
      }
      @if $skin-name == 'tailwind3' {
        padding: $treeview-big-text-padding;
      }

      .e-input-group {
        height: $treeview-big-input-height;

        @if $skin-name != 'material' {
          .e-input {
            height: 36px;
          }
        }
      }
    }

    .e-checkbox-wrapper {
      margin: $treeview-big-check-margin;
      @if ($skin-name == 'bootstrap4' or $skin-name == 'tailwind') {
        & + .e-list-text {
          padding: $treeview-big-check-text-padding;
        }
      }

      & + .e-list-icon,
      & + .e-list-img {
        margin: $treeview-big-check-image-margin;
      }
    }

    .e-list-icon,
    .e-list-img {
      margin: $treeview-big-image-margin;
      @if $skin-name == 'bootstrap4' {
        font-size: $treeview-big-image-font-size;
      }
      @if ($skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'bootstrap5' or $skin-name == 'FluentUI' or $skin-name == 'Material3' or $skin-name == 'fluent2') {
        & + .e-list-text {
          padding: $treeview-big-image-text-padding;
        }
      }

      & + .e-list-icon,
      & + .e-list-img {
        margin: $treeview-big-icon-image-margin;
      }
    }

    .e-icon-collapsible,
    .e-icon-expandable {
      @if $skin-name == 'bootstrap4' {
        height: $treeview-big-icon-size;
        margin: $treeview-big-icon-margin;
        width: $treeview-big-icon-size;
      }

      @if $skin-name == 'tailwind3' {
        height: $treeview-big-icon-size;
        width: $treeview-big-icon-size;
      }

      &::before {
        @if $skin-name != 'tailwind3' {
          padding: $treeview-big-icon-padding;
        }
      }
    }

    &.e-drag-item {

      @if ($skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'bootstrap5' or $skin-name == 'FluentUI') {

        .e-text-content {
          padding-left: $treeview-big-drag-item-text-padding-left;
        }

        .e-icon-collapsible,
        .e-icon-expandable {
          font-size: $treeview-big-drag-icon-font-size;
          @if ($skin-name != 'bootstrap5' and $skin-name != 'FluentUI') {
            margin: $treeview-big-drag-icon-margin;
          }

          &::before {
            @if ($skin-name != 'bootstrap5' and $skin-name != 'FluentUI') {
              padding: $treeview-big-drag-before-icon-padding;
            }
            @if ($skin-name == 'bootstrap5' or $skin-name == 'FluentUI') {
              font-size: $treeview-big-drag-icon-font-size;
            }
          }
        }

        @if $skin-name == 'bootstrap4' {
          .e-drop-count {
            border: $treeview-big-drop-count-border-size solid;
          }
        }
      }
    }

    &.e-rtl {

      @if $skin-name == 'tailwind' or $skin-name == 'bootstrap5' or $skin-name == 'FluentUI' or $skin-name == 'Material3' or $skin-name == 'fluent2' {
        .e-ul {
          padding: $treeview-big-rtl-root-ul-padding;
        }

        .e-list-item {
          .e-ul {
            padding: $treeview-big-rtl-child-ul-padding;
          }

          .e-text-content {
            padding: $treeview-big-rtl-text-wrap-padding;
          }
        }
      }

      @if $skin-name == 'tailwind' or $skin-name == 'bootstrap5' or $skin-name == 'FluentUI' or $skin-name == 'Material3' or $skin-name == 'bootstrap4' {
        .e-icon-collapsible,
        .e-icon-expandable {
          margin: $treeview-big-rtl-icon-margin;
        }
      }

      &.e-drag-item {

        @if ($skin-name == 'bootstrap5' or $skin-name == 'FluentUI') {

          .e-text-content {
            padding-left: 0;
            padding-right: $treeview-big-drag-item-text-padding-left;
          }
        }
      }

      .e-navigable {

        .e-checkbox-wrapper + .e-list-url .e-anchor-wrap {

          @if $skin-name == 'bootstrap4' {
            padding: $treeview-navigable-rtl-margin-reverse;
          }

          .e-list-icon,
          .e-list-img {
            margin: $treeview-navigable-icon-image-anchor-margin-reverse-bigger;
          }
        }

        .e-anchor-wrap {
          @if $skin-name == 'bootstrap4' {
            padding: $treeview-navigable-check-margin-bigger-reverse;
          }
          @else {
            padding: $treeview-rtl-icon-image-margin;
          }
        }

        .e-list-icon,
        .e-list-img,
        .e-list-icon + .e-list-img {
          @if $skin-name == 'bootstrap4' {
            margin: $treeview-check-image-margin;
          }
          @else {
            margin: $treeview-icon-image-margin;
          }
        }
      }

      .e-checkbox-wrapper {
        margin: $treeview-big-rtl-check-margin;

        & + .e-list-icon,
        & + .e-list-img {
          margin: $treeview-big-rtl-check-image-margin;
        }
      }

      .e-list-icon,
      .e-list-img {
        margin: $treeview-big-rtl-image-margin;

        & + .e-list-icon,
        & + .e-list-img {
          margin: $treeview-big-rtl-icon-image-margin;
        }
      }
    }
  }

  .e-bigger .e-treeview,
  .e-treeview.e-bigger {
    .e-list-text {
      font-size: $treeview-big-font-size;
      @if $skin-name == 'bootstrap4' {
        color: $treeview-big-text-color;
      }
    }

    .e-icon-collapsible,
    .e-icon-expandable {
      &::before {
        font-size: $treeview-big-icon-font-size;
      }
    }

    &.e-drag-item {
      .e-icon-collapsible,
      .e-icon-expandable {
        @if $skin-name == 'bootstrap4' {
          padding: $treeview-big-drag-icon-padding;
        }
      }

      .e-list-text {
        @if $skin-name == 'bootstrap4' {
          padding: $treeview-big-drag-text-padding;
        }
      }
    }
  }
}

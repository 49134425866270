@import "../base/base.gd.scss";
@import "../buttons/button.gd.scss";

$btn-grp-margin: -1px !default;
$btn-grp-margin-left: 0 !default;
$btn-grp-wrapper-border: 2px !default;
$btn-grp-round-radius: 20px !default;
$btn-grp-icon-font-size: 14px !default;
$btn-grp-icon-font-size-bigger: 16px !default;

//Normal Outline GroupButton
$btn-grp-outline-focus-border-color: inherit !default;
$btn-grp-outline-focus-color: inherit !default;

//Outline Primary
$btn-grp-outline-primary-focus-border-color: inherit !default;
$btn-grp-outline-focus-primary-color: inherit !default;

//Outline Success
$btn-grp-outline-success-focus-border-color: inherit !default;
$btn-grp-outline-focus-success-color: inherit !default;

//Outline Info
$btn-grp-outline-info-focus-border-color: inherit !default;
$btn-grp-outline-focus-info-color: inherit !default;

//Outline warning
$btn-grp-outline-warning-focus-border-color: inherit !default;
$btn-grp-outline-focus-warning-color: inherit !default;

//Outline danger
$btn-grp-outline-danger-focus-border-color: inherit !default;
$btn-grp-outline-focus-danger-color: inherit !default;

@mixin apply-border {
  &:not(:first-of-type):not(:last-of-type) {
    border-left: transparent;
    border-right: transparent;
  }

  &:first-of-type {
    border-right: transparent;
  }

  &:last-of-type {
    border-left: transparent;
  }
}

@mixin apply-border-vertical {
  &:not(:first-of-type):not(:last-of-type) {
    border-bottom: transparent;
    border-top: transparent;
  }

  &:first-of-type {
    border-bottom: transparent;
  }

  &:last-of-type {
    border-top: transparent;
  }
}

@mixin different-states {
  // Outline focus
  .e-btn:focus,
  input:focus + label.e-btn {
    &.e-outline {
      border-color: $btn-grp-outline-focus-border-color;
      outline-color: $btn-grp-outline-focus-color;

      &.e-primary {
        border-color: $btn-grp-outline-primary-focus-border-color;
        outline-color: $btn-grp-outline-focus-primary-color;
      }

      &.e-success {
        border-color: $btn-grp-outline-success-focus-border-color;
        outline-color: $btn-grp-outline-focus-success-color;
      }

      &.e-info {
        border-color: $btn-grp-outline-info-focus-border-color;
        outline-color: $btn-grp-outline-focus-info-color;
      }

      &.e-warning {
        border-color: $btn-grp-outline-warning-focus-border-color;
        outline-color: $btn-grp-outline-focus-warning-color;
      }

      &.e-danger {
        border-color: $btn-grp-outline-danger-focus-border-color;
        outline-color: $btn-grp-outline-focus-danger-color;
      }
    }
  }
}

@mixin disabled {
  background-color: $btn-disabled-bgcolor;
  border-color: $btn-disabled-border-color;
  box-shadow: $btn-flat-box-shadow;
  color: $btn-disabled-color;
}

@mixin box-shadow-theme {
  @if $skin-name != 'bootstrap5' and $skin-name != 'bootstrap4' and $skin-name != 'tailwind' and $skin-name != 'tailwind3' {
    box-shadow: none;
  }
  @if $skin-name == 'fabric' {
    border-color: transparent;
  }
}

@mixin outline-box-shadow-theme {
  @if $skin-name != 'bootstrap5' and $skin-name != 'bootstrap4' and $skin-name != 'tailwind' and $skin-name != 'tailwind3' {
    box-shadow: none;
  }
}

@mixin Mat3-box-shadow-none {
  @if $skin-name == 'Material3' {
    box-shadow: none;
  }
}


@include export-module('button-group-layout') {
/* stylelint-disable */
  #{if(&, '&', '*')}.e-btn-group,
  #{if(&, '&', '*')}.e-css.e-btn-group {
    display: -webkit-inline-flex;
    display: inline-flex;
    border-radius: $btn-grp-wrapper-border;
    -webkit-flex-direction: row;
    flex-direction: row;
    position: relative;
    @if $skin-name == 'Material3' {
      border-radius: $btn-border-radius;
      box-shadow: $btn-active-box-shadow;
    }

    input:focus+label.e-btn,
    .e-btn:focus,
    .e-btn:hover {
      @if $skin-name == 'tailwind' or $skin-name == 'tailwind3' or $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'bootstrap4' {
        z-index: 2;
      }
    }

    input + label.e-btn {
      margin-bottom: 0;
    }

    input {
      -moz-appearance: none;
      height: 1px;
      margin: 0 0 0 $btn-grp-margin;
      opacity: 0;
      position: absolute;
      width: 1px;
    }

    // Disabled
    input:disabled+label.e-btn,
    :disabled {
      cursor: default;
      pointer-events: none;
    }

    .e-btn-icon {
      font-size: $btn-grp-icon-font-size;
    }

    // Sharp corner for sides individual Button and SplitButton
    &:not(.e-rtl):not(.e-vertical) {
      .e-btn {
        @if $skin-name != 'Material3' and $skin-name != 'fluent2' {
          margin-left: $btn-grp-margin-left;
        }

        &:not(:first-of-type):not(:last-of-type) {
          border-radius: 0;
        }

        &:first-of-type,
        &:last-of-type:not(:last-child) {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }

        &:last-of-type {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }
      }

      .e-split-btn-wrapper {
        +.e-btn {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }

        &:not(:first-child):not(:last-child) {
          .e-btn:last-child {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }

          .e-btn:first-child {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
          }
        }

        &:first-child {
          .e-btn:last-child {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }
        }

        &:last-child {
          .e-btn:first-child {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
          }
        }
      }
    }

    // RTL with Sharp corner combinations
    &.e-rtl {
      .e-btn {
        margin-left: $btn-grp-margin-left;

        &:not(:first-of-type):not(:last-of-type) {
          border-radius: 0;
        }

        &:first-of-type,
        &:last-of-type:not(:last-child) {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }

        &:last-of-type {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }
      }

      .e-split-btn-wrapper {
        +.e-btn {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }

        &:not(:first-child):not(:last-child) {
          .e-btn:last-child {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
          }

          .e-btn:first-child {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }
        }

        &:first-child {
          .e-btn:last-child {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
          }
        }

        &:last-child {
          .e-btn:first-child {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }
        }
      }
    }

    // Vertical Orientation
    &.e-vertical {
      -webkit-flex-direction: column;
      flex-direction: column;

      input {
        margin: $btn-grp-margin 0 0;
      }

      // Round corner
      .e-btn {
        &:not(.e-outline) {
          margin-top: $btn-grp-margin;
        }

        &:not(:first-of-type):not(:last-of-type) {
          border-radius: 0;
        }

        &:first-of-type {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border-top-left-radius: 2px;
          border-top-right-radius: 2px;
        }

        &:last-of-type {
          border-bottom-left-radius: 2px;
          border-bottom-right-radius: 2px;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
      }
    }

    // Rounded Corner
    &.e-round-corner:not(.e-vertical) {
      &:not(.e-rtl) {
        border-radius: $btn-grp-round-radius;

        .e-btn {
          &:first-of-type {
            border-bottom-left-radius: $btn-grp-round-radius;
            border-top-left-radius: $btn-grp-round-radius;
          }

          &:last-of-type {
            border-bottom-right-radius: $btn-grp-round-radius;
            border-top-right-radius: $btn-grp-round-radius;
          }

          &:last-of-type:not(:last-child) {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }
        }

        .e-split-btn-wrapper {
          +.e-btn {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
          }

          &:first-child {
            .e-btn:first-child {
              border-bottom-left-radius: $btn-grp-round-radius;
              border-top-left-radius: $btn-grp-round-radius;
            }

            .e-btn:last-child {
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
            }
          }

          &:last-child {
             .e-btn:first-child {
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
            }

            .e-btn:last-child {
              border-bottom-right-radius: $btn-grp-round-radius;
              border-top-right-radius: $btn-grp-round-radius;
            }
          }
        }
      }

      // Rounded corner with RTL
      &.e-rtl {
        border-radius: $btn-grp-round-radius;

        .e-btn {
          &:first-of-type {
            border-bottom-right-radius: $btn-grp-round-radius;
            border-top-right-radius: $btn-grp-round-radius;
          }

          &:last-of-type {
            border-bottom-left-radius: $btn-grp-round-radius;
            border-top-left-radius: $btn-grp-round-radius;
          }

          &:last-of-type:not(:last-child) {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
          }

          &:first-of-type:not(:first-child) {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }
        }
      }
    }
  }
}




@include export-module('button-group-theme') {
/* stylelint-disable */
  .e-btn-group,
  .e-css.e-btn-group {
    &:not(.e-outline) {
      @if $skin-name != 'Material3' {
        box-shadow: $btn-box-shadow;
      }
    }

    @if $skin-name == 'material' or $skin-name == 'Material3' {
      .e-btn {
        box-shadow: none;
      }
    }

    .e-btn:hover {
      box-shadow: none;
    }

    @if $skin-name == 'Material3' {
      .e-btn:focus-visible,
      input:focus-visible+label.e-btn { // only for keybord
        box-shadow: $btn-keyboard-focus-box-shadow !important;
        z-index: 1;

        &.e-primary,
        &.e-success,
        &.e-info,
        &.e-warning,
        &.e-danger {
          box-shadow: $btn-keyboard-focus-box-shadow !important;
          z-index: 1;
        }

        &.e-outline {
          box-shadow: $btn-keyboard-focus-box-shadow !important;
          z-index: 1;
          &.e-primary,
          &.e-success,
          &.e-info,
          &.e-warning,
          &.e-danger {
            box-shadow: $btn-keyboard-focus-box-shadow !important;
            z-index: 1;
          }
        }
    }
  }

  @if $skin-name == 'fluent2' {
    .e-btn:focus-visible,
    input:focus-visible+label.e-btn { // only for keybord
      box-shadow: $btn-keyboard-focus-box-shadow !important;
    }

    .e-outline.e-btn:active,
    .e-outline.e-btn:focus,
    input:focus+label.e-btn.e-outline {
      background: transparent !important;
    }
  }

    //Focus state for Normal and Outline GroupButton
    .e-btn:focus,
    input:focus-visible+label.e-btn,
    input:focus+label.e-btn {
      @include button-focus;
      @if $skin-name != 'bootstrap5' and $skin-name != 'bootstrap5.3' and $skin-name != 'bootstrap4' and $skin-name != 'tailwind' and $skin-name != 'tailwind3' {
        box-shadow: none;
      }
      @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
        border-color: $btn-focus-bgcolor;
      }
      outline-color: $btn-grp-outline-focus-color;
      @if $skin-name == 'fabric' {
        outline-offset: -2px;
      }

      @if $skin-name == 'highcontrast' {
        outline-offset: -4px;
      }

      @if $skin-name == 'tailwind3' {
        background: $btn-bgcolor;
        color: $btn-color;
        box-shadow: $btn-focus-box-shadow;
      }

      &.e-primary {
        @include primary-focus;
        @include box-shadow-theme;
        @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
          border-color: $btn-primary-focus-bgcolor;
        }
        outline-color: $btn-grp-outline-focus-primary-color;
        @if $skin-name == 'tailwind3' {
          background: $btn-primary-bgcolor;
          color: $btn-primary-color;
          box-shadow: $btn-focus-box-shadow;
        }
      }

      &.e-success {
        @include success-focus;
        @include box-shadow-theme;
        @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
          border-color: $btn-success-focus-bgcolor;
        }
        outline-color: $btn-grp-outline-focus-success-color;
        @if $skin-name == 'tailwind3' {
          background: $btn-success-bgcolor;
          color: $btn-success-color;
          box-shadow: $btn-focus-box-shadow;
        }
      }

      &.e-info {
        @include info-focus;
        @include box-shadow-theme;
        @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
          border-color: $btn-info-focus-bgcolor;
        }
        outline-color: $btn-grp-outline-focus-info-color;
        @if $skin-name == 'tailwind3' {
          background: $btn-info-bgcolor;
          color: $btn-info-color;
          box-shadow: $btn-focus-box-shadow;
        }
      }

      &.e-warning {
        @include warning-focus;
        @include box-shadow-theme;
        @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
          border-color: $btn-warning-focus-bgcolor;
        }
        outline-color: $btn-grp-outline-focus-warning-color;
        @if $skin-name == 'tailwind3' {
          background: $btn-warning-bgcolor;
          color: $btn-warning-color;
          box-shadow: $btn-focus-box-shadow;
        }
      }

      &.e-danger {
        @include danger-focus;
        @include box-shadow-theme;
        @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
          border-color: $btn-danger-focus-bgcolor;
        }
        outline-color: $btn-grp-outline-focus-danger-color;
        @if $skin-name == 'tailwind3' {
          background: $btn-danger-bgcolor;
          color: $btn-danger-color;
          box-shadow: $btn-focus-box-shadow;
        }
      }

      &.e-link {
        @include link-focus;
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
        outline: none;
      }

      &.e-outline {
        @include outline-focus;
        @include outline-box-shadow-theme;
        @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
          border-color: $btn-outline-focus-bgcolor;
        }
        @if $skin-name == 'tailwind3' {
          background: $transparent;
          color: $btn-color;
          box-shadow: $btn-focus-box-shadow;
        }

        &.e-primary {
          @include outline-primary-focus;
          @include outline-box-shadow-theme;
          @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
            border-color: $btn-outline-primary-focus-bgcolor;
          }
          @if $skin-name == 'tailwind3' {
            background: $transparent;
            color: $btn-primary-bgcolor;
            box-shadow: $btn-focus-box-shadow;
          }
        }

        &.e-success {
          @include outline-success-focus;
          @include outline-box-shadow-theme;
          @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
            border-color: $btn-success-focus-bgcolor;
          }
          @if $skin-name == 'tailwind3' {
            background: $transparent;
            color: $btn-success-bgcolor;
            box-shadow: $btn-focus-box-shadow;
          }
        }

        &.e-info {
          @include outline-info-focus;
          @include outline-box-shadow-theme;
          @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
            border-color: $btn-info-hover-bgcolor;
          }
          @if $skin-name == 'tailwind3' {
            background: $transparent;
            color: $btn-info-bgcolor;
            box-shadow: $btn-focus-box-shadow;
          }
        }

        &.e-warning {
          @include outline-warning-focus;
          @include outline-box-shadow-theme;
          @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
            border-color: $btn-warning-hover-bgcolor;
          }
          @if $skin-name == 'tailwind3' {
            background: $transparent;
            color: $btn-warning-bgcolor;
            box-shadow: $btn-focus-box-shadow;
          }
        }

        &.e-danger {
          @include outline-danger-focus;
          @include outline-box-shadow-theme;
          @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
            border-color: $btn-danger-hover-bgcolor;
          }
          @if $skin-name == 'tailwind3' {
            background: $transparent;
            color: $btn-danger-bgcolor;
            box-shadow: $btn-focus-box-shadow;
          }
        }
      }
    }

    //Active/Checked state for Normal and Outline GroupButton
    .e-btn:active,
    input:active+label.e-btn,
    input:checked+label.e-btn {
      @include button-active;
      @include Mat3-box-shadow-none;

      &.e-primary {
        @include primary-active;
        @include Mat3-box-shadow-none;
      }

      &.e-success {
        @include success-active;
        @include Mat3-box-shadow-none;
      }

      &.e-info {
        @include info-active;
        @include Mat3-box-shadow-none;
      }

      &.e-warning {
        @include warning-active;
        @include Mat3-box-shadow-none;
      }

      &.e-danger {
        @include danger-active;
        @include Mat3-box-shadow-none;
      }

      &.e-outline {
        @include outline-active;
        @include Mat3-box-shadow-none;

        &.e-primary {
          @include outline-primary-active;
          @include Mat3-box-shadow-none;
        }

        &.e-success {
          @include outline-success-active;
          @include Mat3-box-shadow-none;
        }

        &.e-info {
          @include outline-info-active;
          @include Mat3-box-shadow-none;
        }

        &.e-warning {
          @include outline-warning-active;
          @include Mat3-box-shadow-none;
        }

        &.e-danger {
          @include outline-danger-active;
          @include Mat3-box-shadow-none;
        }
      }
    }

    //Disabled state for Normal and Outline GroupButton
    .e-btn:disabled,
    input:disabled+label.e-btn {
      @include disabled;
      @if $skin-name != 'bootstrap4' {
        border-color: $btn-border-color;
      }

      &.e-primary {
        @include primary-disabled;
        @if $skin-name != 'bootstrap4' and $skin-name != 'tailwind3' {
          border-color: $btn-primary-border-color;
        }
      }

      &.e-success {
        @include success-disabled;
        @if $skin-name != 'bootstrap4' and $skin-name != 'tailwind3' {
          border-color: $btn-success-border-color;
        }
      }

      &.e-info {
        @include info-disabled;
        @if $skin-name != 'bootstrap4' and $skin-name != 'tailwind3' {
          border-color: $btn-info-border-color;
        }
      }

      &.e-warning {
        @include warning-disabled;
        @if $skin-name != 'bootstrap4' and $skin-name != 'tailwind3' {
          border-color: $btn-warning-border-color;
        }
      }

      &.e-danger {
        @include danger-disabled;
        @if $skin-name != 'bootstrap4' and $skin-name != 'tailwind3' {
          border-color: $btn-danger-border-color;
        }
      }

      &.e-link {
        @include link-disabled;
        @if $skin-name != 'bootstrap4' and $skin-name != 'tailwind3' {
          border-color: $btn-link-border-color;
        }
      }

      &.e-outline {
        @include outline-disabled;
        border-color: $btn-outline-border-color;

        &.e-primary {
          @include outline-primary-disabled;
          @if $skin-name != 'tailwind3' {
            border-color: $btn-primary-bgcolor;
          }
        }

        &.e-success {
          @include outline-success-disabled;
          @if $skin-name != 'tailwind3' {
            border-color: $btn-success-bgcolor;
          }
        }

        &.e-info {
          @include outline-info-disabled;
          @if $skin-name != 'tailwind3' {
            border-color: $btn-info-bgcolor;
          }
        }

        &.e-warning {
          @include outline-warning-disabled;
          @if $skin-name != 'tailwind3' {
            border-color: $btn-warning-bgcolor;
          }
        }

        &.e-danger {
          @include outline-danger-disabled;
          @if $skin-name != 'tailwind3' {
            border-color: $btn-danger-bgcolor;
          }
        }
      }
    }

    //Normal ButtonGroup layout for highcontrast theme
    @if $skin-name == 'highcontrast' {
      &:not(.e-vertical):not(.e-rtl) {
        .e-btn {
          @include apply-border;
        }
      }

      &.e-vertical:not(.e-rtl) {
        .e-btn {
          @include apply-border-vertical;
        }
      }

      .e-btn:hover {
        border-color: $btn-border-color;

        &.e-primary {
          border-color: $btn-primary-bgcolor;
        }

        &.e-success {
          border-color: $btn-success-bgcolor;
        }

        &.e-info {
          border-color: $btn-info-bgcolor;
        }

        &.e-warning {
          border-color: $btn-warning-bgcolor;
        }

        &.e-danger {
          border-color: $btn-danger-bgcolor;
        }
      }
    }

    &:not(.e-vertical):not(.e-rtl) {// Outline GroupButton - Horizontal orientation
      .e-outline {
        @if $skin-name != 'bootstrap' {
          @include apply-border;
        }
      }
      @include different-states;
    }

    &.e-vertical:not(.e-rtl) {// Outline GroupButton - Vertical Orientation
      .e-outline {
        @include apply-border-vertical;
      }
      @include different-states;
    }

    // RTL
    &.e-rtl {
      .e-btn {
        @if $skin-name != 'bootstrap' and $skin-name != 'tailwind' and $skin-name !='tailwind-dark' {
          &:not(:first-of-type):not(:last-of-type) {
            border-left: transparent;
            border-right: transparent;
          }

          &:first-of-type {
            border-left: transparent;
          }

          &:last-of-type {
            border-right: transparent;
          }
        }
      }
    }
  }
}

@include export-module('button-group-bigger') {
  .e-bigger .e-btn-group,
  .e-bigger.e-btn-group,
  .e-bigger .e-css.e-btn-group,
  .e-bigger.e-css.e-btn-group  {
    .e-btn-icon {
      font-size: $btn-grp-icon-font-size-bigger;
    }
  }
}

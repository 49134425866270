@import "../base/base.gd.scss";
@import "../buttons/button.gd.scss";
@import "../buttons/floating-action-button.gd.scss";

$sd-offset: 16px !default;
$sd-ul-margin: 0 !default;
$sd-li-text-padding: 0 5px !default;

//Distance between vertical menu and button => Ul bottom padding + li bottom margin
//Distance between vertical menu item =>  li bottom margin + li top margin
//Horizontal padding for vertical menu => ((fab wdith - li icon width)/2) - 1 px (for icon border)
$sd-horz-ul-padding: 5px 5px !default;
$sd-vert-ul-padding: 5px 5px !default;
$sd-horz-li-margin: 0 5px !default;
$sd-vert-li-margin: 5px 0 !default;
$sd-li-text-margin: 0 8px !default;
$sd-li-text-height: 26px !default;
$sd-li-icon-height: 28px !default;
$sd-li-icon-width: 28px !default;
$sd-li-icon-border-radius: 99999px !default;
$sd-li-icon-font-size: 12px !default;

$sd-small-horz-ul-padding: 3px 5px !default;
$sd-small-vert-ul-padding: 5px 3px !default;
$sd-small-horz-li-margin: 0 5px !default;
$sd-small-vert-li-margin: 5px 0 !default;
$sd-small-li-text-margin: 0 8px !default;
$sd-small-li-text-height: 22px !default;
$sd-small-li-icon-height: 24px !default;
$sd-small-li-icon-width: 24px !default;
$sd-small-li-icon-font-size: 12px !default;

$sd-bigger-horz-ul-padding: 7px 6px !default;
$sd-bigger-vert-ul-padding: 6px 7px !default;
$sd-bigger-horz-li-margin: 0 6px !default;
$sd-bigger-vert-li-margin: 6px 0 !default;
$sd-bigger-li-text-margin: 0 12px !default;
$sd-bigger-li-text-height: 32px !default;
$sd-bigger-li-icon-height: 36px !default;
$sd-bigger-li-icon-width: 36px !default;
$sd-bigger-li-icon-font-size: 14px !default;

$sd-bigger-small-horz-ul-padding: 5px 5px !default;
$sd-bigger-small-vert-ul-padding: 5px 5px !default;
$sd-bigger-small-horz-li-margin: 0 5px !default;
$sd-bigger-small-vert-li-margin: 5px 0 !default;
$sd-bigger-small-li-text-margin: 0 10px !default;
$sd-bigger-small-li-text-height: 30px !default;
$sd-bigger-small-li-icon-height: 34px !default;
$sd-bigger-small-li-icon-width: 34px !default;
$sd-bigger-small-li-icon-font-size: 14px !default;

$sd-overlay-bg: rgba(107, 114, 128, .5) !default;
$sd-li-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px rgba(0, 0, 0, .14), 0 1px 18px rgba(0, 0, 0, .12) !default;
$sd-li-bg: $grey-white !default;
$sd-li-border-color: $grey-white !default;
$sd-font-color: $base-font !default;
$sd-disabled-li-bg: $grey-50 !default;
$sd-disabled-li-border-color: $grey-50 !default;
$sd-disabled-font-color: $sd-font-color !default;
$sd-focus-li-bg: $grey-300 !default;
$sd-focus-li-border-color: $grey-300 !default;
$sd-focus-font-color: $sd-font-color !default;
$sd-hover-li-bg: $grey-300 !default;
$sd-hover-li-border-color: $grey-300 !default;
$sd-hover-font-color: $sd-font-color !default;
$sd-active-li-bg: $grey-400 !default;
$sd-active-li-border-color: $grey-400 !default;
$sd-active-font-color: $base-font !default;

@mixin speeddial-popup-styles($padding, $li-margin, $li-text-margin, $speeddial-li-text-height, $li-icon-height, $li-icon-width, $li-icon-font-size, $ul-padding, $sd-li-margin, $speeddial-text-li-padding) {
  .e-speeddial-ul {
    padding: $padding;
  }

  &.e-speeddial-linear .e-speeddial-li {
    margin: $li-margin;
  }

  .e-speeddial-li:not(.e-speeddial-text-li) .e-speeddial-li-text {
    margin: $li-text-margin;
  }

  .e-speeddial-li.e-speeddial-text-li .e-speeddial-li-text {
    @if ($skin-name == 'fluent2' or $skin-name == 'tailwind3') {
      font-size: $li-icon-font-size;
      line-height: $speeddial-li-text-height;
      padding: $speeddial-text-li-padding;
    }
    @if ($skin-name == 'tailwind3') {
      height: 12px;
      line-height: 12px;
    }
  }

  .e-speeddial-li-text {
    height: $speeddial-li-text-height;
    line-height: $speeddial-li-text-height;
    @if ($skin-name == 'tailwind3') {
      font-size: $li-icon-font-size;
    }
  }

  .e-speeddial-li-icon {
    height: $li-icon-height;
    width: $li-icon-width;
    font-size: $li-icon-font-size;
  }

  &.e-speeddial-horz-right,
  &.e-speeddial-horz-left {
    .e-speeddial-ul {
      padding: $ul-padding;
    }

    .e-speeddial-li {
      margin: $sd-li-margin;
    }
  }
}

@mixin speeddial-top-bottom-position($direction) {
  #{$direction}: $sd-offset;

  &.e-speeddial-radial {
    .e-speeddial-li {
      #{$direction}: 0;
    }

    &.e-speeddial-middle .e-speeddial-li {
      #{$direction}: calc(.5 * var(--speeddialRadialMinWidth) + var(--speeddialRadialOffset));
    }
  }

  &.e-speeddial-middle,
  &.e-speeddial-linear {
    #{$direction}: var(--speeddialVertDist);
  }
}

@mixin speeddial-left-right-position($direction) {
  #{$direction}: var(--speeddialRadialHorzDist);

  &.e-speeddial-radial {
    .e-speeddial-li {
      #{$direction}: 0;
    }

    &.e-speeddial-center .e-speeddial-li {
      #{$direction}: calc(.5 * var(--speeddialRadialMinWidth) + var(--speeddialRadialOffset));
    }
  }

  &.e-speeddial-radial.e-speeddial-center,
  &.e-speeddial-center {
    #{$direction}: var(--speeddialRadialHorzDist);
  }

  &.e-speeddial-linear {
    #{$direction}: var(--speeddialHorzDist);
  }
}

@mixin speeddial-styles($sd-height, $sd-width, $sd-position) {
  height: $sd-height;
  width: $sd-width;
  position: $sd-position;
}

@include export-module('speed-dial-layout') {
  .e-speeddial-popup {
    position: absolute;
    z-index: 100000;
    pointer-events: none;

    &.e-speeddial-top {
      @include speeddial-top-bottom-position(top);
    }

    &.e-speeddial-bottom {
      @include speeddial-top-bottom-position(bottom);
    }

    &.e-speeddial-left {
      @include speeddial-left-right-position(left);
    }

    &.e-speeddial-right {
      @include speeddial-left-right-position(right);
    }

    &.e-speeddial-template {
      outline: none;
      pointer-events: auto;
      z-index: 100001;
    }

    &.e-speeddial-linear .e-speeddial-li {
      margin: $sd-vert-li-margin;
    }

    .e-speeddial-ul {
      box-sizing: border-box;
      display: inline-flex;
      flex-direction: column;
      list-style-type: none;
      margin: $sd-ul-margin;
      padding: $sd-vert-ul-padding;
    }

    .e-speeddial-li {
      align-items: center;
      cursor: pointer;
      display: inline-flex;
      pointer-events: auto;
      position: relative;
      z-index: 100001;

      &:not(.e-speeddial-text-li) .e-speeddial-li-text {
        margin: $sd-li-text-margin;
      }

      &.e-speeddial-text-li .e-speeddial-li-text {
        @if ($skin-name == 'fluent2') {
          border: 1px solid;
          border-radius: 20px;
          font-size: 12px;
          line-height: 16px;
          padding: 8px 16px;
          box-sizing: content-box;
        }
        @if ($skin-name == 'tailwind3') {
          height: 26px;
          line-height: 26px;
          border: 1px solid;
          border-radius: 20px;
          padding: 0 12px;
        }
      }
    }

    .e-speeddial-li-text {
      border-radius: 2px;
      padding: $sd-li-text-padding;
      white-space: nowrap;
      height: $sd-li-text-height;
      line-height: $sd-li-text-height;
      @if ($skin-name == 'fluent2' or $skin-name == 'tailwind3') {
        border: none;
      }
      @else {
        border: 1px solid;
      }
      @if ($skin-name == 'tailwind3') {
        font-weight: $font-weight-medium;
      }
    }

    .e-speeddial-li-icon {
      border-radius: $sd-li-icon-border-radius;
      border: 1px solid;
      font-size: $sd-li-icon-font-size;
      height: $sd-li-icon-height;
      width: $sd-li-icon-width;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    &.e-speeddial-fixed {
      position: fixed;
    }

    &.e-speeddial-vert-right .e-speeddial-li,
    &.e-speeddial-horz-right .e-speeddial-ul,
    &.e-speeddial-horz-left .e-speeddial-ul.e-speeddial-horz-top {
      flex-direction: row-reverse;
    }

    &.e-speeddial-vert-bottom .e-speeddial-ul {
      flex-direction: column-reverse;
    }

    &.e-speeddial-horz-right .e-speeddial-ul.e-speeddial-horz-top,
    &.e-speeddial-horz-left .e-speeddial-ul {
      flex-direction: row;
    }

    &.e-speeddial-horz-right,
    &.e-speeddial-horz-left {
      .e-speeddial-ul {
        padding: $sd-horz-ul-padding;
      }

      .e-speeddial-li {
        margin: $sd-horz-li-margin;
      }
    }

    /* OVERFLOW CSS */
    &.e-speeddial-overflow {
      .e-speeddial-ul {
        overflow: auto;
        scroll-behavior: smooth;

        /* Hide scrollbar for IE and Edge */
        -ms-overflow-style: none;

        /* Hide scrollbar for Firefox */
        scrollbar-width: none;

        &::-webkit-scrollbar {

          /* Hide scrollbar for Chrome, Safari and Opera */
          display: none;
        }
      }

      &.e-speeddial-vert-overflow .e-speeddial-ul {
        max-height: var(--speeddialOverflowLimit);
      }

      &.e-speeddial-horz-overflow .e-speeddial-ul {
        max-width: var(--speeddialOverflowLimit);
      }
    }

    &.e-speeddial-radial {
      height: calc(var(--speeddialRadialOffset) + 2 * var(--speeddialRadialMinHeight));
      width: calc(var(--speeddialRadialOffset) + 2 * var(--speeddialRadialMinWidth));

      &.e-speeddial-middle {
        height: calc(2 * var(--speeddialRadialOffset) + 2 * var(--speeddialRadialMinHeight));
      }

      &.e-speeddial-center {
        width: calc(2 * var(--speeddialRadialOffset) + 2 * var(--speeddialRadialMinWidth));
      }

      &.e-speeddial-top-left {
        border-bottom-right-radius: 100%;
      }

      &.e-speeddial-top-right {
        border-bottom-left-radius: 100%;
      }

      &.e-speeddial-bottom-left {
        border-top-right-radius: 100%;
      }

      &.e-speeddial-bottom-right {
        border-top-left-radius: 100%;
      }

      .e-speeddial-ul {
        @include speeddial-styles(inherit, inherit, relative);
        border-radius: inherit;
        padding: 0;
      }

      .e-speeddial-li {
        position: absolute;
        transform: rotate(var(--speeddialRadialAngle)) translate(var(--speeddialRadialOffset)) rotate(calc(-1 * var(--speeddialRadialAngle)));
      }
    }

    &.e-speeddial-radial,
    &.e-speeddial-horz-right,
    &.e-speeddial-horz-left {
      .e-speeddial-li-text {
        position: absolute;
        visibility: hidden;
      }
    }
  }

  .e-small.e-speeddial-popup,
  .e-small .e-speeddial-popup {
    @include speeddial-popup-styles($sd-small-vert-ul-padding, $sd-small-vert-li-margin, $sd-small-li-text-margin, $sd-small-li-text-height, $sd-small-li-icon-height, $sd-small-li-icon-width, $sd-small-li-icon-font-size, $sd-small-horz-ul-padding, $sd-small-horz-li-margin, 5px 16px);
  }

  .e-speeddial-overlay {
    @include speeddial-styles(100%, 100%, absolute);
    left: 0;
    top: 0;
    z-index: 99998;

    &.e-speeddial-fixed {
      @include speeddial-styles(100vh, 100vw, fixed);
    }
  }

  .e-speeddial-hidden {
    visibility: hidden;
  }
}


@mixin speeddial-text-icon-colors($sd-color, $sd-bg-color, $sd-border-color) {
  color: $sd-color;
  .e-speeddial-li-text,
  .e-speeddial-li-icon {
    background: $sd-bg-color;
    border-color: $sd-border-color;
  }
}

@include export-module('speed-dial-theme') {
  .e-speeddial-overlay {
    background-color: $sd-overlay-bg;
  }

  .e-speeddial-popup {
    .e-speeddial-li {
      color: $sd-font-color;

      .e-speeddial-li-text {
        @if ($skin-name == 'fluent2' or $skin-name == 'tailwind3') {
          background: transparent;
          box-shadow: none;
        }
        @else {
          background: $sd-li-bg;
          box-shadow: $sd-li-shadow;
        }
        @if ($skin-name == 'Material3' or $skin-name == 'fluent2' or $skin-name == 'tailwind3') {
          border-color: transparent;
        }
        @else {
          border-color: $sd-li-border-color;
        }
      }
      .e-speeddial-li-icon {
        box-shadow: $sd-li-shadow;
        background: $sd-li-bg;
        @if ($skin-name == 'Material3') {
          border-color: transparent;
        }
        @else {
          border-color: $sd-li-border-color;
        }
        @if ($skin-name == 'tailwind3') {
          box-shadow: none;
        }
      }
      &.e-speeddial-text-li .e-speeddial-li-text {
        @if ($skin-name == 'fluent2') {
          background: $secondary-bg-color;
          box-shadow: $shadow-lg;
          border-color: $secondary-border-color;
        }
        @if ($skin-name == 'tailwind3') {
          background: $secondary-bg-color;
          border-color: $secondary-border-color;
        }
      }
    }
    .e-speeddial-li.e-disabled {
      @include speeddial-text-icon-colors($sd-disabled-font-color, $sd-disabled-li-bg, $sd-disabled-li-border-color);
    }

    .e-speeddial-li.e-speeddial-li-active:not(.e-disabled),
    .e-speeddial-li:focus:not(.e-disabled) {
      color: $sd-focus-font-color;

      .e-speeddial-li-text,
      .e-speeddial-li-icon {
        background: $sd-focus-li-bg;
        border-color: $sd-focus-li-border-color;
        @if ($skin-name == 'tailwind' or $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'highcontrast' or $skin-name == 'highcontrast-light' or $skin-name == 'FluentUI' or $skin-name == 'Material3') {
          box-shadow: $sd-focus-box-shadow;
        }
      }
    }

    .e-speeddial-li:not(.e-disabled):hover {
      @include speeddial-text-icon-colors($sd-hover-font-color, $sd-hover-li-bg, $sd-hover-li-border-color);
    }

    .e-speeddial-li:not(.e-disabled):active {
      @include speeddial-text-icon-colors($sd-active-font-color, $sd-active-li-bg, $sd-active-li-border-color);
    }
  }
}

@mixin speeddial-popup-styles($padding, $li-margin, $li-text-margin, $speeddial-li-text-height, $li-icon-height, $li-icon-width, $li-icon-font-size, $ul-padding, $sd-li-margin, $speeddial-text-li-padding) {
  .e-speeddial-ul {
    padding: $padding;
  }

  &.e-speeddial-linear .e-speeddial-li {
    margin: $li-margin;
  }

  .e-speeddial-li:not(.e-speeddial-text-li) .e-speeddial-li-text {
    margin: $li-text-margin;
  }

  .e-speeddial-li.e-speeddial-text-li .e-speeddial-li-text {
    @if ($skin-name == 'fluent2' or $skin-name == 'tailwind3') {
      font-size: $li-icon-font-size;
      line-height: $speeddial-li-text-height;
      padding: $speeddial-text-li-padding;
    }
    @if ($skin-name == 'tailwind3') {
      height: 18px;
      line-height: 18px;
    }
  }

  .e-speeddial-li-text {
    height: $speeddial-li-text-height;
    line-height: $speeddial-li-text-height;
    @if ($skin-name == 'tailwind3') {
      font-size: $li-icon-font-size;
    }
  }

  .e-speeddial-li-icon {
    height: $li-icon-height;
    width: $li-icon-width;
    font-size: $li-icon-font-size;
  }

  &.e-speeddial-horz-right,
  &.e-speeddial-horz-left {
    .e-speeddial-ul {
      padding: $ul-padding;
    }

    .e-speeddial-li {
      margin: $sd-li-margin;
    }
  }
}

@include export-module('speed-dial-bigger') {
  .e-bigger.e-speeddial-popup,
  .e-bigger .e-speeddial-popup {
    @include speeddial-popup-styles($sd-bigger-vert-ul-padding, $sd-bigger-vert-li-margin, $sd-bigger-li-text-margin, $sd-bigger-li-text-height, $sd-bigger-li-icon-height, $sd-bigger-li-icon-width, $sd-bigger-li-icon-font-size, $sd-bigger-horz-ul-padding, $sd-bigger-horz-li-margin, 10px 28px);
  }

  .e-bigger.e-small.e-speeddial-popup,
  .e-bigger.e-small .e-speeddial-popup,
  .e-bigger .e-small.e-speeddial-popup,
  .e-small .e-bigger.e-speeddial-popup {
    @include speeddial-popup-styles($sd-bigger-small-vert-ul-padding, $sd-bigger-small-vert-li-margin, $sd-bigger-li-text-margin, $sd-bigger-small-li-text-height, $sd-bigger-small-li-icon-height, $sd-bigger-small-li-icon-width, $sd-bigger-small-li-icon-font-size, $sd-bigger-small-horz-ul-padding, $sd-bigger-small-horz-li-margin, 8px 24px);
  }
}

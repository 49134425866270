@import "../base/base.gd.scss";

// Dimension variables
$msg-font-family: $font-family !default;
$msg-font-weight: 400 !default;
$msg-font-style: normal !default;
$msg-padding: 9px 7px !default;
$msg-border-radius: 4px !default;
$msg-icon-size: 16px !default;
$msg-icon-margin: 3px 4px !default;
$msg-close-icon-margin: 0 0 0 auto !default;
$msg-close-icon-size: 14px !default;
$msg-close-icon-padding: 3px 4px !default;
$msg-close-icon-height: 22px !default;
$msg-content-font-size: 14px !default;
$msg-content-line-height: 22px !default;
$msg-content-padding: 0 4px !default;

$msg-rtl-close-icon-margin: 0 auto 0 0 !default;

$msg-bigger-padding: 11px 9px !default;
$msg-bigger-icon-size: 18px !default;
$msg-bigger-icon-margin: 3px 6px !default;
$msg-bigger-close-icon-margin: 0 2px 0 auto !default;
$msg-bigger-close-icon-padding: 3px 4px !default;
$msg-bigger-close-icon-height: 24px !default;
$msg-bigger-close-icon-size: 16px !default;
$msg-bigger-content-font-size: 16px !default;
$msg-bigger-content-line-height: 24px !default;
$msg-bigger-content-padding: 0 6px !default;

$msg-bigger-rtl-close-icon-margin: 0 auto 0 2px !default;

// Color variables
// Text
$msg-color: $msg-color !default;
$msg-bg-color: $msg-bg-color !default;
$msg-border-color: $msg-border-color !default;
$msg-success-color: $msg-success-color !default;
$msg-success-bg-color: $msg-success-bg-color !default;
$msg-success-border-color: $msg-success-border-color !default;
$msg-warning-color: $msg-warning-color !default;
$msg-warning-bg-color: $msg-warning-bg-color !default;
$msg-warning-border-color: $msg-success-border-color !default;
$msg-info-color: $msg-info-color !default;
$msg-info-bg-color: $msg-info-bg-color !default;
$msg-info-border-color: $msg-info-border-color !default;
$msg-error-color: $msg-danger-color !default;
$msg-error-bg-color: $msg-danger-bg-color !default;
$msg-error-border-color: $msg-danger-border-color !default;

$msg-icon-color: $msg-icon-color !default;
$msg-success-icon-color: $msg-success-icon-color !default;
$msg-warning-icon-color: $msg-warning-icon-color !default;
$msg-info-icon-color: $msg-info-icon-color !default;
$msg-error-icon-color: $msg-danger-icon-color !default;

$msg-close-icon-color: $msg-close-icon-color !default;
$msg-close-icon-hover-bg-color: rgba($msg-close-icon-color, .12) !default;
$msg-success-close-icon-color: $msg-success-close-icon-color !default;
$msg-success-close-icon-hover-bg-color: rgba($msg-success-close-icon-color, .12) !default;
$msg-warning-close-icon-color: $msg-warning-close-icon-color !default;
$msg-warning-close-icon-hover-bg-color: rgba($msg-warning-close-icon-color, .12) !default;
$msg-info-close-icon-color: $msg-info-close-icon-color !default;
$msg-info-close-icon-hover-bg-color: rgba($msg-info-close-icon-color, .12) !default;
$msg-error-close-icon-color: $msg-danger-close-icon-color !default;
$msg-error-close-icon-hover-bg-color: rgba($msg-error-close-icon-color, .12) !default;

// Outlined
$msg-outline-color: $msg-color-alt1 !default;
$msg-outline-bg-color: $msg-bg-color-alt1 !default;
$msg-outline-border-color: $msg-border-color-alt1 !default;
$msg-outline-success-color: $msg-success-color-alt1 !default;
$msg-outline-success-border-color: $msg-success-border-color-alt1 !default;
$msg-outline-warning-color: $msg-warning-color-alt1 !default;
$msg-outline-warning-border-color: $msg-warning-border-color-alt1 !default;
$msg-outline-info-color: $msg-info-color-alt1 !default;
$msg-outline-info-border-color: $msg-info-border-color-alt1 !default;
$msg-outline-error-color: $msg-danger-color-alt1 !default;
$msg-outline-error-border-color: $msg-danger-border-color-alt1 !default;

$msg-outline-icon-color: $msg-icon-color-alt1 !default;
$msg-outline-success-icon-color: $msg-success-icon-color-alt1 !default;
$msg-outline-warning-icon-color: $msg-warning-icon-color-alt1 !default;
$msg-outline-info-icon-color: $msg-info-icon-color-alt1 !default;
$msg-outline-error-icon-color: $msg-danger-icon-color-alt1 !default;

$msg-outline-close-icon-color: $msg-close-icon-color-alt1 !default;
$msg-outline-close-icon-hover-bg-color: rgba($msg-outline-close-icon-color, .12) !default;
$msg-outline-success-close-icon-color: $msg-success-close-icon-color-alt1 !default;
$msg-outline-success-close-icon-hover-bg-color: rgba($msg-outline-success-close-icon-color, .12) !default;
$msg-outline-warning-close-icon-color: $msg-warning-close-icon-color-alt1 !default;
$msg-outline-warning-close-icon-hover-bg-color: rgba($msg-outline-warning-close-icon-color, .12) !default;
$msg-outline-info-close-icon-color: $msg-info-close-icon-color-alt1 !default;
$msg-outline-info-close-icon-hover-bg-color: rgba($msg-outline-info-close-icon-color, .12) !default;
$msg-outline-error-close-icon-color: $msg-danger-close-icon-color-alt1 !default;
$msg-outline-error-close-icon-hover-bg-color: rgba($msg-outline-error-close-icon-color, .12) !default;

// Filled
$msg-filled-color: $msg-color-alt2 !default;
$msg-filled-bg-color: $msg-bg-color-alt2 !default;
$msg-filled-border-color: $msg-border-color-alt2 !default;
$msg-filled-success-color: $msg-success-color-alt2 !default;
$msg-filled-success-bg-color: $msg-success-bg-color-alt2 !default;
$msg-filled-success-border-color: $msg-success-border-color-alt2 !default;
$msg-filled-warning-color: $msg-warning-color-alt2 !default;
$msg-filled-warning-bg-color:$msg-warning-bg-color-alt2 !default;
$msg-filled-warning-border-color: $msg-warning-border-color-alt2 !default;
$msg-filled-info-color: $msg-info-color-alt2 !default;
$msg-filled-info-bg-color: $msg-info-bg-color-alt2 !default;
$msg-filled-info-border-color: $msg-info-border-color-alt2 !default;
$msg-filled-error-color: $msg-danger-color-alt2 !default;
$msg-filled-error-bg-color: $msg-danger-bg-color-alt2 !default;
$msg-filled-error-border-color: $msg-danger-border-color-alt2 !default;

$msg-filled-icon-color: $msg-icon-color-alt2 !default;
$msg-filled-success-icon-color: $msg-success-icon-color-alt2 !default;
$msg-filled-warning-icon-color: $msg-warning-icon-color-alt2 !default;
$msg-filled-info-icon-color: $msg-info-icon-color-alt2 !default;
$msg-filled-error-icon-color: $msg-danger-icon-color-alt2 !default;

$msg-filled-close-icon-color: $msg-close-icon-color-alt2 !default;
$msg-filled-close-icon-hover-bg-color: rgba($msg-filled-close-icon-color, .12) !default;
$msg-filled-success-close-icon-color: $msg-success-close-icon-color-alt2 !default;
$msg-filled-success-close-icon-hover-bg-color: rgba($msg-filled-success-close-icon-color, .12) !default;
$msg-filled-warning-close-icon-color: $msg-warning-close-icon-color-alt2 !default;
$msg-filled-warning-close-icon-hover-bg-color: rgba($msg-filled-warning-close-icon-color, .12) !default;
$msg-filled-info-close-icon-color: $msg-info-close-icon-color-alt2 !default;
$msg-filled-info-close-icon-hover-bg-color: rgba($msg-filled-info-close-icon-color, .12) !default;
$msg-filled-error-close-icon-color: $msg-danger-close-icon-color-alt2 !default;
$msg-filled-error-close-icon-hover-bg-color: rgba($msg-filled-error-close-icon-color, .12) !default;

@include export-module('message-layout') {

  .e-message {
    border: 1px solid;
    border-radius: $msg-border-radius;
    box-sizing: border-box;
    font-family: $msg-font-family;
    font-weight: $msg-font-weight;
    font-style: $msg-font-style;
    padding: $msg-padding;
    display: flex;

    &.e-hidden {
      display: none;
    }

    .e-msg-icon,
    .e-msg-close-icon {
      box-sizing: border-box;
      display: flex;
      line-height: $msg-icon-size;
    }

    .e-msg-icon {
      font-size: $msg-icon-size;
      height: $msg-icon-size;
      margin: $msg-icon-margin;
      @if $skin-name == 'fluent2' {
        padding: 0 4px 0 2px;
      }
    }

    .e-msg-close-icon {
      border-width: 0;
      cursor: pointer;
      font-size: $msg-close-icon-size;
      margin: $msg-close-icon-margin;
      padding: $msg-close-icon-padding;
      height: $msg-close-icon-height;
      @if $skin-name == 'fluent2' {
        line-height: 12px;
      }
    }

    .e-msg-content {
      box-sizing: border-box;
      font-size: $msg-content-font-size;
      line-height: $msg-content-line-height;
      padding: $msg-content-padding;
      vertical-align: top;
    }

    &.e-content-right {
      justify-content: flex-end;
    }

    &.e-content-center .e-msg-content-wrap {
      display: flex;
      flex: 1;
      justify-content: center;
    }

    &.e-content-right .e-msg-close-icon,
    &.e-content-center .e-msg-close-icon {
      margin: $msg-icon-margin;
    }

    &.e-rtl {
      .e-msg-close-icon {
        margin: $msg-rtl-close-icon-margin;
      }
    }
  }
}


@include export-module('message-theme') {
  .e-message {
    background: $msg-bg-color;
    @if ($skin-name == 'Material3') {
      border-image: $msg-border-color;
    }
    @else {
      border-color: $msg-border-color;
    }
    color: $msg-color;

    .e-msg-icon {
      color: $msg-icon-color;
    }

    .e-msg-close-icon {
      background: transparent;
      color: $msg-close-icon-color;

      &:hover,
      &:focus {
        background-color: $msg-close-icon-hover-bg-color;
        box-shadow: 0 0 0 2px $msg-close-icon-hover-bg-color;
        border-radius: 50%;
      }
    }

    &.e-success {
      background-color: $msg-success-bg-color;
      border-color: $msg-success-border-color;
      color: $msg-success-color;

      .e-msg-icon {
        color: $msg-success-icon-color;
      }

      .e-msg-close-icon {
        color: $msg-success-close-icon-color;

        &:hover,
        &:focus {
          background-color: $msg-success-close-icon-hover-bg-color;
          box-shadow: 0 0 0 2px $msg-success-close-icon-hover-bg-color;
        }
      }
    }

    &.e-warning {
      background-color: $msg-warning-bg-color;
      border-color: $msg-warning-border-color;
      color: $msg-warning-color;

      .e-msg-icon {
        color: $msg-warning-icon-color;
      }

      .e-msg-close-icon {
        color: $msg-warning-close-icon-color;

        &:hover,
        &:focus {
          background-color: $msg-warning-close-icon-hover-bg-color;
          box-shadow: 0 0 0 2px $msg-warning-close-icon-hover-bg-color;
        }
      }
    }

    &.e-info {
      background-color: $msg-info-bg-color;
      border-color: $msg-info-border-color;
      color: $msg-info-color;

      .e-msg-icon {
        color: $msg-info-icon-color;
      }

      .e-msg-close-icon {
        color: $msg-info-close-icon-color;

        &:hover,
        &:focus {
          background-color: $msg-info-close-icon-hover-bg-color;
          box-shadow: 0 0 0 2px $msg-info-close-icon-hover-bg-color;
        }
      }
    }

    &.e-error {
      background-color: $msg-error-bg-color;
      border-color: $msg-error-border-color;
      color: $msg-error-color;

      .e-msg-icon {
        color: $msg-error-icon-color !important; /* stylelint-disable-line declaration-no-important */
      }

      .e-msg-close-icon {
        color: $msg-error-close-icon-color;

        &:hover,
        &:focus {
          background-color: $msg-error-close-icon-hover-bg-color;
          box-shadow: 0 0 0 2px $msg-error-close-icon-hover-bg-color;
        }
      }
    }

    &.e-outlined {
      background: $msg-outline-bg-color;
      @if ($skin-name == 'Material3') {
        border-image: none;
      }
      border-color: $msg-outline-border-color;
      color: $msg-outline-color;

      .e-msg-icon {
        color: $msg-outline-icon-color;
      }

      .e-msg-close-icon {
        color: $msg-outline-close-icon-color;

        &:hover,
        &:focus {
          background-color: $msg-outline-close-icon-hover-bg-color;
          box-shadow: 0 0 0 2px $msg-outline-close-icon-hover-bg-color;
        }
      }

      &.e-success {
        border-color: $msg-outline-success-border-color;
        color: $msg-outline-success-color;

        .e-msg-icon {
          color: $msg-outline-success-icon-color;
        }

        .e-msg-close-icon {
          color: $msg-outline-success-close-icon-color;

          &:hover,
          &:focus {
            background-color: $msg-outline-success-close-icon-hover-bg-color;
            box-shadow: 0 0 0 2px $msg-outline-success-close-icon-hover-bg-color;
          }
        }
      }

      &.e-warning {
        border-color: $msg-outline-warning-border-color;
        color: $msg-outline-warning-color;

        .e-msg-icon {
          color: $msg-outline-warning-icon-color;
        }

        .e-msg-close-icon {
          color: $msg-outline-warning-close-icon-color;

          &:hover,
          &:focus {
            background-color: $msg-outline-warning-close-icon-hover-bg-color;
            box-shadow: 0 0 0 2px $msg-outline-warning-close-icon-hover-bg-color;
          }
        }
      }

      &.e-info {
        border-color: $msg-outline-info-border-color;
        color: $msg-outline-info-color;

        .e-msg-icon {
          color: $msg-outline-info-icon-color;
        }

        .e-msg-close-icon {
          color: $msg-outline-info-close-icon-color;

          &:hover,
          &:focus {
            background-color: $msg-outline-info-close-icon-hover-bg-color;
            box-shadow: 0 0 0 2px $msg-outline-info-close-icon-hover-bg-color;
          }
        }
      }

      &.e-error {
        border-color: $msg-outline-error-border-color;
        color: $msg-outline-error-color;

        .e-msg-icon {
          color: $msg-outline-error-icon-color;
        }

        .e-msg-close-icon {
          color: $msg-outline-error-close-icon-color;

          &:hover,
          &:focus {
            background-color: $msg-outline-error-close-icon-hover-bg-color;
            box-shadow: 0 0 0 2px $msg-outline-error-close-icon-hover-bg-color;
          }
        }
      }
    }

    &.e-filled {
      background: $msg-filled-bg-color;
      @if ($skin-name == 'Material3') {
        border-image: none;
      }
      border-color: $msg-filled-border-color;
      color: $msg-filled-color;

      .e-msg-icon {
        color: $msg-filled-icon-color;
      }

      .e-msg-close-icon {
        color: $msg-filled-close-icon-color;

        &:hover,
        &:focus {
          background-color: $msg-filled-close-icon-hover-bg-color;
          box-shadow: 0 0 0 2px $msg-filled-close-icon-hover-bg-color;
        }
      }

      &.e-success {
        background-color: $msg-filled-success-bg-color;
        border-color: $msg-filled-success-border-color;
        color: $msg-filled-success-color;

        .e-msg-icon {
          color: $msg-filled-success-icon-color;
        }

        .e-msg-close-icon {
          color: $msg-filled-success-close-icon-color;

          &:hover,
          &:focus {
            background-color: $msg-filled-success-close-icon-hover-bg-color;
            box-shadow: 0 0 0 2px $msg-filled-success-close-icon-hover-bg-color;
          }
        }
      }

      &.e-warning {
        background-color: $msg-filled-warning-bg-color;
        border-color: $msg-filled-warning-border-color;
        color: $msg-filled-warning-color;

        .e-msg-icon {
          color: $msg-filled-warning-icon-color;
        }

        .e-msg-close-icon {
          color: $msg-filled-warning-close-icon-color;

          &:hover,
          &:focus {
            background-color: $msg-filled-warning-close-icon-hover-bg-color;
            box-shadow: 0 0 0 2px $msg-filled-warning-close-icon-hover-bg-color;
          }
        }
      }

      &.e-info {
        background-color: $msg-filled-info-bg-color;
        border-color: $msg-filled-info-border-color;
        color: $msg-filled-info-color;

        .e-msg-icon {
          color: $msg-filled-info-icon-color;
        }

        .e-msg-close-icon {
          color: $msg-filled-info-close-icon-color;

          &:hover,
          &:focus {
            background-color: $msg-filled-info-close-icon-hover-bg-color;
            box-shadow: 0 0 0 2px $msg-filled-info-close-icon-hover-bg-color;
          }
        }
      }

      &.e-error {
        background-color: $msg-filled-error-bg-color;
        border-color: $msg-filled-error-border-color;
        color: $msg-filled-error-color;

        .e-msg-icon {
          color: $msg-filled-error-icon-color !important; /* stylelint-disable-line declaration-no-important */
        }

        .e-msg-close-icon {
          color: $msg-filled-error-close-icon-color;

          &:hover,
          &:focus {
            background-color: $msg-filled-error-close-icon-hover-bg-color;
            box-shadow: 0 0 0 2px $msg-filled-error-close-icon-hover-bg-color;
          }
        }
      }
    }
  }
}


@include export-module('message-material-icons') {
  .e-message {
    .e-msg-icon::before,
    .e-msg-close-icon::before {
      font-family: 'e-icons';
    }

    .e-msg-icon::before,
    &.e-info .e-msg-icon::before {
      content: '\e607';
    }

    .e-msg-close-icon::before {
      content: '\e7fc';
    }

    &.e-success .e-msg-icon::before {
      content: '\ea84';
    }

    &.e-error .e-msg-icon::before {
      content: '\e208';
    }

    &.e-warning .e-msg-icon::before {
      content: '\e92f';
    }
  }
}

@include export-module('message-bigger') {
  .e-bigger .e-message,
  .e-message.e-bigger {
    padding: $msg-bigger-padding;

    .e-msg-icon,
    .e-msg-close-icon {
      line-height: $msg-bigger-icon-size;
    }

    .e-msg-icon {
      font-size: $msg-bigger-icon-size;
      height: $msg-bigger-icon-size;
      margin: $msg-bigger-icon-margin;
      @if $skin-name == 'fluent2' {
        padding: 2px 5px 0 6px;
      }
    }

    .e-msg-close-icon {
      font-size: $msg-bigger-close-icon-size;
      margin: $msg-bigger-close-icon-margin;
      padding: $msg-bigger-close-icon-padding;
      height: $msg-bigger-close-icon-height;
      @if $skin-name == 'fluent2' {
        line-height: 14px;
      }
    }

    .e-msg-content {
      line-height: $msg-bigger-content-line-height;
      font-size: $msg-bigger-content-font-size;
      padding: $msg-bigger-content-padding;
    }

    &.e-content-right .e-msg-close-icon,
    &.e-content-center .e-msg-close-icon {
      margin: $msg-bigger-icon-margin;
    }

    &.e-rtl {
      .e-msg-close-icon {
        margin: $msg-bigger-rtl-close-icon-margin;
      }
    }
  }

  .e-bigger .e-message,
  .e-message.e-bigger {
    .e-msg-close-icon {
      &:hover,
      &:focus {
        box-shadow: 0 0 0 4px $msg-close-icon-hover-bg-color;
      }
    }

    &.e-success {
      .e-msg-close-icon {
        &:hover,
        &:focus {
          box-shadow: 0 0 0 4px $msg-success-close-icon-hover-bg-color;
        }
      }
    }

    &.e-warning {
      .e-msg-close-icon {
        &:hover,
        &:focus {
          box-shadow: 0 0 0 4px $msg-warning-close-icon-hover-bg-color;
        }
      }
    }

    &.e-info {
      .e-msg-close-icon {
        &:hover,
        &:focus {
          box-shadow: 0 0 0 4px $msg-info-close-icon-hover-bg-color;
        }
      }
    }

    &.e-error {
      .e-msg-close-icon {
        &:hover,
        &:focus {
          box-shadow: 0 0 0 4px $msg-error-close-icon-hover-bg-color;
        }
      }
    }

    &.e-outlined {
      .e-msg-close-icon {
        &:hover,
        &:focus {
          box-shadow: 0 0 0 4px $msg-outline-close-icon-hover-bg-color;
        }
      }

      &.e-success {
        .e-msg-close-icon {
          &:hover,
          &:focus {
            box-shadow: 0 0 0 4px $msg-outline-success-close-icon-hover-bg-color;
          }
        }
      }

      &.e-warning {
        .e-msg-close-icon {
          &:hover,
          &:focus {
            box-shadow: 0 0 0 4px $msg-outline-warning-close-icon-hover-bg-color;
          }
        }
      }

      &.e-info {
        .e-msg-close-icon {
          &:hover,
          &:focus {
            box-shadow: 0 0 0 4px $msg-outline-info-close-icon-hover-bg-color;
          }
        }
      }

      &.e-error {
        .e-msg-close-icon {
          &:hover,
          &:focus {
            box-shadow: 0 0 0 4px $msg-outline-error-close-icon-hover-bg-color;
          }
        }
      }
    }

    &.e-filled {
      .e-msg-close-icon {
        &:hover,
        &:focus {
          box-shadow: 0 0 0 4px $msg-filled-close-icon-hover-bg-color;
        }
      }

      &.e-success {
        .e-msg-close-icon {
          &:hover,
          &:focus {
            box-shadow: 0 0 0 4px $msg-filled-success-close-icon-hover-bg-color;
          }
        }
      }

      &.e-warning {
        .e-msg-close-icon {
          &:hover,
          &:focus {
            box-shadow: 0 0 0 4px $msg-filled-warning-close-icon-hover-bg-color;
          }
        }
      }

      &.e-info {
        .e-msg-close-icon {
          &:hover,
          &:focus {
            box-shadow: 0 0 0 4px $msg-filled-info-close-icon-hover-bg-color;
          }
        }
      }

      &.e-error {
        .e-msg-close-icon {
          &:hover,
          &:focus {
            box-shadow: 0 0 0 4px $msg-filled-error-close-icon-hover-bg-color;
          }
        }
      }
    }
  }
}

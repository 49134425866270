@import "../base/base.gd.scss";
@import "../popups/popup.gd.scss";
@import "../buttons/button.gd.scss";
@import "../navigations/h-scroll.gd.scss";
@import "../navigations/v-scroll.gd.scss";
@import "../navigations/toolbar.gd.scss";

$tab-skin: 'material' !default;
$tab-big-vertical-scroll-nav-top-bottom: 23px !default;
$tab-big-vertical-nav-arrow-size: 24px !default;
$tab-big-vertical-nav-arrow-font-size: 12px !default;
$tab-font-family: $font-family !default;
$tab-nrml-vertical-scroll-nav-top-bottom: 15px !default;
$tab-nrml-vertical-nav-arrow-size: 16px !default;
$tab-nrml-vertical-nav-arrow-font-size: 10px !default;
$tab-text-transform: uppercase !default;
$tab-big-height: 48px !default;
$tab-nrml-height: 36px !default;
$tab-focus-big-height: 48px !default;
$tab-focus-nrml-height: 36px !default;
$tab-active-big-height: 48px !default;
$tab-active-nrml-height: 36px !default;
$tab-active-focus-big-height: 48px !default;
$tab-active-focus-nrml-height: 36px !default;
$tab-big-fill-height: 46px !default;
$tab-nrml-fill-height: 34px !default;
$tab-mob-fill-height: 46px !default;
$tab-focus-big-fill-height: 46px !default;
$tab-focus-nrml-fill-height: 34px !default;
$tab-disable-cnt-opacity: .38 !default;
$tab-big-tb-icon-height: 72px !default;
$tab-nrml-tb-icon-height: 62px !default;
$tab-content-font-color: $base-font !default;
$tab-border-width: 2px !default;
$tab-border-style: $tab-border-width Solid !default;
$tab-focus-outline: 0 !default;
$tab-active-focus-outline: 0 !default;
$tab-active-text-item-border: 0 !default;
$tab-disable-active-text-item-border: 0 !default;
$tab-alt-active-item-border: $tab-border-style $primary-font !default;
$tab-accent-active-item-border: $tab-border-style $accent !default;
$tab-fill-hdr-border: $tab-border-style $accent !default;
$tab-fill-disable-hdr-border: $tab-border-style rgba($accent, .38) !default;
$tab-anim-border-display: block !default;
$tab-border-color: $accent !default;
$tab-disable-border-color: rgba($accent, .38) !default;
$tab-alt-border-color: $primary-font !default;
$tab-alt-disable-border-color: rgba($primary-font, .38) !default;
$tab-bg-color: transparent !default;
$tab-border: none !default;
$tab-alt-hdr-bg-color: $primary !default;
$tab-focus-hscroll-content-padding: 0 !default;
$tab-hdr-bdr-color: inherit !default;
$tab-hdr-border: 0 !default;
$tab-hdr-border-width: 0 !default;
$tab-alt-hdr-border: 0 !default;
$tab-items-border: 0 !default;
$tab-big-item-margin: 0 !default;
$tab-nrml-item-margin: 0 !default;
$tab-big-item-padding: 0 !default;
$tab-nrml-item-padding: 0 !default;
$tab-big-icon-top-bottom-item-height: 72px !default;
$tab-nrml-icon-top-bottom-item-height: 62px !default;
$tab-pop-big-icon-top-bottom-item-height: 48px !default;
$tab-pop-nrml-icon-top-bottom-item-height: 36px !default;
$tab-big-focus-icon-top-bottom-item-height: 72px !default;
$tab-nrml-focus-icon-top-bottom-item-height: 62px !default;
$tab-big-fill-icon-top-bottom-item-height: 70px !default;
$tab-nrml-fill-icon-top-bottom-item-height: 60px !default;
$tab-big-focus-fill-icon-top-bottom-item-height: 70px !default;
$tab-nrml-focus-fill-icon-top-bottom-item-height: 60px !default;
$tab-big-first-item-margin: 0 !default;
$tab-nrml-first-item-margin: 0 !default;
$tab-big-last-item-margin: 0 !default;
$tab-nrml-last-item-margin: 0 !default;
$tab-big-active-last-item-margin: 0 !default;
$tab-nrml-active-last-item-margin: 0 !default;
$tab-big-scroll-last-item-margin: 0 !default;
$tab-nrml-scroll-last-item-margin: 0 !default;
$tab-nrml-active-scroll-last-item-margin: 0 !default;
$tab-mob-last-item-margin: 0 !default;
$tab-big-even-last-item-margin: 0 !default;
$tab-nrml-even-last-item-margin: 0 !default;
$tab-mob-even-last-item-margin: 0 !default;
$tab-big-alt-first-item-margin: 0 !default;
$tab-nrml-alt-first-item-margin: 0 !default;
$tab-pop-item-margin: 0 !default;
$tab-big-pop-item-height: $tab-big-height !default;
$tab-nrml-pop-item-height: $tab-nrml-height !default;
$tab-alt-item-margin: 0 !default;
$tab-big-rtl-item-margin: 0 !default;
$tab-nrml-rtl-item-margin: 0 !default;
$tab-big-rtl-alt-item-margin: 0 !default;
$tab-nrml-rtl-alt-item-margin: 0 !default;
$tab-mob-pop-item-height: 48px !default;
$tab-ripple-bg-color: rgba($accent, .12) !default;
$tab-fill-ripple-bg-color: rgba($grey-black, .05) !default;
$tab-alt-ripple-bg-color: rgba($grey-black, .1) !default;
$tab-item-border: none !default;
$tab-active-item-border: none !default;
$tab-active-item-hide-border: initial !default;
$tab-active-item-border-color: inherit !default;
$tab-big-boot-item-margin: 0 !default;
$tab-nrml-boot-item-margin: 0 !default;
$tab-mob-boot-item-margin: 0 !default;
$tab-big-even-boot-item-margin: 0 !default;
$tab-nrml-even-boot-item-margin: 0 !default;
$tab-mob-even-boot-item-margin: 0 !default;
$tab-big-boot-active-item-padding-bottom: 0 !default;
$tab-nrml-boot-active-item-padding-bottom: 0 !default;
$tab-big-even-boot-active-item-padding-bottom: 0 !default;
$tab-nrml-even-boot-active-item-padding-bottom: 0 !default;
$tab-active-item-border-radius: 0 !default;
$tab-top-active-item-border-radius: 0 !default;
$tab-bottom-active-item-border-radius: 0 !default;
$tab-left-active-item-border-radius: 0 !default;
$tab-right-active-item-border-radius: 0 !default;
$tab-item-wrap-border-radius: 0 !default;
$tab-hscroll-item-padding: 0 !default;
$tab-big-item-last-child-padding-bottom: 0 !default;
$tab-nrml-item-last-child-padding-bottom: 0 !default;
$tab-big-wrap-padding: 0 24px !default;
$tab-nrml-wrap-padding: 0 18px !default;
$tab-mob-wrap-padding: 0 12px !default;
$tab-pop-padding: 0 !default;
$tab-big-pop-item-wrap-padding: 0 16px !default;
$tab-nrml-pop-item-wrap-padding: 0 16px !default;
$tab-mob-pop-item-wrap-padding: 0 16px !default;
$tab-big-io-text-wrap-width: auto !default;
$tab-nrml-io-text-wrap-width: auto !default;
$tab-big-io-wrap-padding: 0 24px !default;
$tab-nrml-io-wrap-padding: 0 18px !default;
$tab-wrap-focus-margin-right: 0 !default;
$tab-wrap-focus-margin-left: 0 !default;
$tab-big-even-ileft-active-text-wrap-margin: 0 !default;
$tab-nrml-even-ileft-active-text-wrap-margin: 0 !default;
$tab-big-fill-tb-wrap-height: 50px !default;
$tab-nrml-fill-tb-wrap-height: 44px !default;
$tab-focus-bg-color: rgba($accent, .12) !default;
$tab-fill-focus-bg-color: rgba($accent, .8) !default;
$tab-hover-bg-color: $grey-200 !default;
$tab-active-bg-color: $grey-200 !default;
$tab-fill-active-bg-color: $accent !default;
$tab-fill-disable-active-bg-color: rgba($accent, .38) !default;
$tab-alt-active-bg-color: $primary !default;
$tab-alt-disable-active-bg-color: $primary !default;
$tab-alt-item-bg-color: $primary !default;
$tab-alt-active-focus-bg-color: inherit !default;
$tab-key-alt-active-focus-bg-color: rgba($grey-white, .12) !default;
$tab-alt-focus-top-bottom-bg-color: inherit !default;
$tab-alt-hover-bg-color: rgba($grey-white, .12) !default;
$tab-fill-active-wrap-corner: 3px 3px 0 0 !default;
$tab-fill-even-active-wrap-corner: 0 0 3px 3px !default;
$tab-alt-tb-active-bg-color: rgba($primary, 0) !default;
$tab-fill-big-wrap-padding: 0 24px !default;
$tab-fill-nrml-wrap-padding: 0 18px !default;
$tab-mob-fill-wrap-padding: 0 18px !default;
$tab-bg-big-wrap-padding: 0 24px !default;
$tab-bg-nrml-wrap-padding: 0 18px !default;
$tab-item-hover-bg-color: inherit !default;
$tab-bg-item-wrap-hover-bg-color: $tab-item-hover-bg-color !default;
$tab-mob-item-hover-bg-color: inherit !default;
$tab-pop-text-color: rgba($grey-light-font, .54) !default;
$tab-alt-disable-active-item-opacity: 1 !default;
$tab-big-active-item-wrap-margin-bottom: 0 !default;
$tab-nrml-active-item-wrap-margin-bottom: 0 !default;
$tab-fill-big-active-item-wrap-margin-bottom: 0 !default;
$tab-fill-nrml-active-item-wrap-margin-bottom: 0 !default;
$tab-bg-big-active-item-wrap-margin-bottom: 0 !default;
$tab-bg-nrml-active-item-wrap-margin-bottom: 0 !default;
$tab-alt-big-active-item-padding: 0 !default;
$tab-alt-nrml-active-item-padding: 0 !default;
$tab-active-text-container-margin: 0 !default;
$tab-big-text-container-margin-right: 22px !default;
$tab-nrml-text-container-margin-right: 20px !default;
$tab-rtl-big-text-container-margin-right: 0 !default;
$tab-rtl-nrml-text-container-margin-right: 0 !default;
$tab-rtl-big-text-container-margin-left: 22px !default;
$tab-rtl-nrml-text-container-margin-left: 20px !default;
$tab-big-active-text-container-height: 48px !default;
$tab-big-active-it-text-container-height: 48px !default;
$tab-fill-big-active-it-text-container-height: 46px !default;
$tab-fill-nrml-active-it-text-container-height: 36px !default;
$tab-big-even-active-text-container-height: 48px !default;
$tab-nrml-even-active-text-container-height: 36px !default;
$tab-even-active-text-container-margin: 0 !default;
$tab-big-font-size: 14px !default;
$tab-nrml-font-size: 13px !default;
$tab-font-weight: 500 !default;
$tab-semi-font-weight: 500 !default;
$tab-light-font-weight: 500 !default;
$tab-big-it-text-margin: 0 0 0 12px !default;
$tab-nrml-it-text-margin: 0 0 0 10px !default;
$tab-mob-it-text-margin: 0 0 0 14px !default;
$tab-rtl-mob-it-text-margin: 0 14px 0 0 !default;
$tab-rtl-big-it-text-margin: 0 12px 0 0 !default;
$tab-rtl-nrml-it-text-margin: 0 10px 0 0 !default;
$tab-big-icon-top-margin: 10px 0 0 !default;
$tab-nrml-icon-top-margin: 8px 0 0 !default;
$tab-big-icon-bottom-margin: 0 0 10px !default;
$tab-nrml-icon-bottom-margin: 0 0 8px !default;
$tab-big-pop-text-padding-right: 12px !default;
$tab-nrml-pop-text-padding-right: 10px !default;
$tab-rtl-big-pop-text-padding-left: 12px !default;
$tab-rtl-nrml-pop-text-padding-left: 10px !default;
$tab-big-even-active-text-height: 48px !default;
$tab-nrml-even-active-text-height: 36px !default;
$tab-fill-big-even-active-text-height: 46px !default;
$tab-fill-nrml-even-active-text-height: 34px !default;
$tab-text-color: rgba($grey-light-font, .54) !default;
$tab-wrap-comb-icon-color: $tab-text-color !default;
$tab-disable-text-color: rgba($grey-light-font, .38) !default;
$tab-active-text-color: rgba($accent, 1) !default;
$tab-disable-active-text-color: rgba($accent, .38) !default;
$tab-focus-text-color: rgba($accent, 1) !default;
$tab-hover-text-color: rgba($grey-light-font, .87) !default;
$tab-pop-active-item-text-color: $tab-active-text-color !default;
$tab-alt-text-color: rgba($primary-font, .7) !default;
$tab-alt-disable-text-color: rgba($primary-font, .38) !default;
$tab-alt-active-text-color: rgba($primary-font, 1) !default;
$tab-alt-disable-active-text-color: rgba($primary-font, .38) !default;
$tab-alt-hover-text-color: rgba($accent, 1) !default;
$tab-alt-active-hover-text-color: rgba($primary-font, 1) !default;
$tab-alt-active-style-hover-icon-color: $tab-alt-active-hover-text-color !default;
$tab-clone-item-bg-color: $grey-white !default;
$tab-clone-text-color: rgba($grey-light-font, .54) !default;
$tab-icon-container-width: auto !default;
$tab-nav-pop-press-border: 0 !default;
$tab-big-nav-icon-container-size: 24px !default;
$tab-nrml-nav-icon-container-size: 24px !default;
$tab-big-nav-icon-size: 12px !default;
$tab-nrml-nav-icon-size: 12px !default;
$tab-mob-nav-icon-size: 16px !default;
$tab-big-icon-size: 20px !default;
$tab-nrml-icon-size: 18px !default;
$tab-mob-icon-size: 20px !default;
$tab-mob-nav-vicon-size: 12px !default;
$tab-big-icon-container-size: 24px !default;
$tab-nrml-icon-container-size: 20px !default;
$tab-mob-icon-container-size: 20px !default;
$tab-big-nav-icon-top: 2px !default;
$tab-nrml-nav-icon-top: 2px !default;
$tab-big-nav-pop-icon-top: 5px !default;
$tab-nrml-nav-pop-icon-top: 5px !default;
$tab-big-safari-nav-icon-top: 4px !default;
$tab-nrml-safari-nav-icon-top: 4px !default;
$tab-big-safari-pop-icon-top: 6px !default;
$tab-nrml-safari-pop-icon-top: 6px !default;
$tab-big-ie-nav-icon-line-height: 15px !default;
$tab-nrml-ie-nav-icon-line-height: 14px !default;
$tab-big-ie-pop-icon-line-height: 15px !default;
$tab-nrml-ie-pop-icon-line-height: 15px !default;
$tab-big-edge-nav-icon-line-height: 13px !default;
$tab-nrml-edge-nav-icon-line-height: 11px !default;
$tab-big-edge-pop-icon-line-height: 15px !default;
$tab-nrml-edge-pop-icon-line-height: 14px !default;
$tab-comb-icon-color: rgba($accent, 1) !default;
$tab-comb-text-color: rgba($accent, 1) !default;
$tab-close-icon-size: 10px !default;
$tab-big-close-icon-size: 10px !default;
$tab-nrml-close-icon-size: 10px !default;
$tab-mob-close-icon-size: 12px !default;
$tab-close-icon-minwidth: 10px !default;
$tab-big-close-icon-margin: 0 0 0 12px !default;
$tab-nrml-close-icon-margin: 0 0 0 10px !default;
$tab-mob-close-icon-margin: 0 0 0 14px !default;
$tab-rtl-mob-close-icon-margin: 0 14px 0 0 !default;
$tab-rtl-big-close-icon-margin: 0 12px 0 0 !default;
$tab-rtl-nrml-close-icon-margin: 0 10px 0 0 !default;
$tab-rtl-big-pop-close-icon-margin: 0 !default;
$tab-rtl-nrml-pop-close-icon-margin: 0 !default;
$tab-big-close-icon-top-bottom-right: 24px !default;
$tab-nrml-close-icon-top-bottom-right: 18px !default;
$tab-big-pop-close-icon-top-bottom-right: 18px !default;
$tab-nrml-pop-close-icon-top-bottom-right: 18px !default;
$tab-rtl-big-close-icon-top-bottom-right: 0 !default;
$tab-rtl-nrml-close-icon-top-bottom-right: 0 !default;
$tab-rtl-big-close-icon-top-bottom-left: 24px !default;
$tab-rtl-nrml-close-icon-top-bottom-left: 18px !default;
$tab-rtl-big-pop-close-icon-top-bottom-left: 18px !default;
$tab-rtl-nrml-pop-close-icon-top-bottom-left: 18px !default;
$tab-pop-close-icon-right: 16px !default;
$tab-rtl-pop-close-icon-left: 16px !default;
$tab-mob-close-icon-top-bottom-right: 12px !default;
$tab-rtl-mob-close-icon-top-bottom-left: 12px !default;
$tab-big-pop-close-top: 0 !default;
$tab-nrml-pop-close-top: 0 !default;
$tab-big-safari-close-icon-top: 0 !default;
$tab-nrml-safari-close-icon-top: -1px !default;
$tab-close-icon-color: rgba($grey-light-font, .54) !default;
$tab-active-close-icon-color: rgba($grey-light-font, .54) !default;
$tab-close-icon-hover-color: $tab-hover-text-color !default;
$tab-close-icon-active-color: $tab-hover-text-color !default;
$tab-alt-close-icon-color: rgba($primary-font, .54) !default;
$tab-alt-active-wrap-close-icon-color: $tab-alt-close-icon-color !default;
$tab-alt-active-close-icon-color: rgba($primary-font, .7) !default;
$tab-alt-active-hover-close-icon-color: rgba($primary-font, 1) !default;
$tab-pop-bg-color: $grey-white !default;
$tab-pop-border: 0 !default;
$tab-pop-border-radius: 0 !default;
$tab-big-nav-right-icon-padding: 0 12px !default;
$tab-nrml-nav-right-icon-padding: 0 9px !default;
$tab-mob-nav-right-icon-padding: 0 12px !default;
$tab-rtl-big-nav-right-icon-padding: 0 12px !default;
$tab-rtl-nrml-nav-right-icon-padding: 0 9px !default;
$tab-big-nav-left-icon-padding: 0 12px !default;
$tab-nrml-nav-left-icon-padding: 0 9px !default;
$tab-rtl-big-nav-left-icon-padding: 0 12px !default;
$tab-rtl-nrml-nav-left-icon-padding: 0 9px !default;
$tab-big-nav-tb-icon-top: 0 !default;
$tab-nrml-nav-tb-icon-top: 0 !default;
$tab-big-nav-icons-top: -2px !default;
$tab-nrml-nav-icons-top: 0 !default;
$tab-hover-nav-bg-color: initial !default;
$tab-bg-hover-nav-bg-color: inherit !default;
$tab-nav-icon-color: $tab-text-color !default;
$tab-alt-nav-icon-color: $tab-alt-text-color !default;
$tab-active-nav-icon-color: $tab-active-text-color !default;
$tab-hover-nav-icon-color: $tab-hover-text-color !default;
$tab-alt-hover-nav-icon-color: $tab-alt-hover-text-color !default;
$tab-disable-nav-icon-color: rgba($grey-light-font, .54) !default;
$tab-nav-focus-bg-color: rgba($grey-light-font, .12) !default;
$tab-nav-focus-icon-color: rgba($grey-light-font, .87) !default;
$tab-bg-nav-focus-icon-color: rgba($grey-white, .87) !default;
$tab-pop-icon-color: rgba($grey-light-font, .54) !default;
$tab-pop-icon-hover-bg-color: initial !default;
$tab-pop-icon-hover-border: initial !default;
$tab-pop-up-icon-hover-border: $tab-pop-icon-hover-border !default;
$tab-pop-up-down-icon-hover-border: $tab-pop-icon-hover-border !default;
$tab-pop-icon-focus-border: initial !default;
$tab-pop-up-icon-focus-border: $tab-pop-icon-focus-border !default;
$tab-pop-icon-active-border: 0 !default;
$tab-even-active-item-bottom-border-color: 0 !default;
$tab-pop-up-icon-active-border: $tab-pop-icon-active-border !default;
$tab-pop-border-box-shadow: none !default;
$tab-bg-hover-nav-icon-color: $primary-font !default;
$tab-pop-box-shadow: 0 2px 2px 1px rgba(0, 0, 0, .21) !default;
$tab-pop-icon-transition: transform .1s ease !default;
$tab-pop-up-icon-transform: rotate(180deg) !default;
$tab-pop-down-icon-transform: rotate(0deg) !default;
$hscroll-alt-ribble-animation-border-frame: rgba(0, 0, 0, .12) !default;
$hscroll-alt-ribble-animation-shadow-frame: 0 0 0 0 $hscroll-alt-ribble-animation-border-frame !default;
$hscroll-alt-ribble-animation-shadow-frame-end: 0 0 0 200px rgba(0, 0, 0, .12) !default;
$tbar-alt-ribble-animation-border-frame: rgba(0, 0, 0, .12) !default;
$tbar-alt-ribble-animation-shadow-frame: 0 0 0 0 $tbar-alt-ribble-animation-border-frame !default;
$tbar-alt-ribble-animation-shadow-frame-end: 0 0 0 200px rgba(0, 0, 0, .12) !default;
$tab-bgr-active-last-margin: 0 !default;
$tab-bgr-hscroll-items-nav-width: 50px !default;
$tab-bgr-item-textwrap-height: $tab-big-height !default;
$tab-bgr-active-icon-top: 0 !default;
$tab-bgr-icon-line-height: 1 !default;
$tab-bgr-mob-item-iconright-margin: 0 !default;
$tab-bgr-pop-item-textwrap-height: $tab-big-pop-item-height !default;
$tab-bgr-scroll-nav-arrow-before-top: 0 !default;
$tab-bgr-hor-pop-lineheight: 24px !default;
$tab-bgr-hor-focus-pop-hover-top: 0 !default;
$tab-bgr-bottom-active-close-top: 0 !default;
$tab-bgr-bottom-active-itop-close-top: 0 !default;
$tab-bgr-bottom-active-text-padding-top: 0 !default;
$tab-bgr-bottom-active-last-close-top: 0 !default;
$tab-bgr-vertical-icon-min-height: 38px !default;
$tab-bgr-vertical-icon-min-width: auto !default;
$tab-bgr-rtl-bottom-item-margin: 0 !default;
$tab-bgr-rtl-bottom-active-margin: 0 !default;
$tab-bgr-fill-first-last-padding: 0 !default;
$tab-bgr-fill-item-textwrap-height: $tab-big-fill-height !default;
$tab-bgr-fill-active-ileft-textwrap-height: 46px !default;
$tab-bgr-fill-focused-wrap-height: $tab-focus-big-fill-height !default;
$tab-bgr-rtl-icon-right-margin: 0 !default;
$tab-bgr-background-active-padding: $tab-alt-big-active-item-padding !default;
$tab-bgr-fill-active-item-padding: 0 !default;
$tab-bgr-item-text-margin-bottom: 0 !default;
$tab-nrml-hscroll-items-nav-width: 40px !default;
$tab-nrml-hscroll-items-bdr-radius: 0 !default;
$tab-nrml-item-text-wrap-height: $tab-nrml-height !default;
$tab-nrml-item-tabicon-line-height: 1 !default;
$tab-nrml-pop-item-textwrap-height: $tab-nrml-pop-item-height !default;
$tab-nrml-scroll-nav-arrow-before-top: 0 !default;
$tab-nrml-hor-pop-lineheight: 24px !default;
$tab-nrml-mob-hor-pop-lineheight: 24px !default;
$tab-nrml-hor-focus-pop-lineheight: 24px !default;
$tab-nrml-hor-focus-pop-hover-top: 0 !default;
$tab-nrml-vertical-icon-min-height: 36px !default;
$tab-nrml-vertical-icon-min-width: 33px !default;
$tab-nrml-vertical-tabicon-margin: 0 !default;
$tab-nrml-vertical-rtl-active-margin: 0 !default;
$tab-nrml-fill-item-textwrap-height: $tab-nrml-fill-height !default;
$tab-nrml-fill-focused-wrap-height: $tab-focus-nrml-fill-height !default;
$tab-nrml-rtl-icon-right-margin: 0 !default;
$tab-nrml-hdr-before-display: unset !default;
$tab-nrml-item-active-position: unset !default;
$tab-nrml-item-active-hover-before-left: unset !default;
$tab-nrml-item-active-hover-before-right: unset !default;
$tab-nrml-item-active-before-bg-font: unset !default;
$tab-nrml-item-active-before-bottom: unset !default;
$tab-nrml-item-active-before-content: unset !default;
$tab-nrml-item-active-before-height: unset !default;
$tab-nrml-item-active-before-left: unset !default;
$tab-nrml-item-active-before-position: unset !default;
$tab-nrml-item-active-before-transition: unset !default;
$tab-nrml-item-active-after-font: unset !default;
$tab-nrml-item-active-after-content: unset !default;
$tab-nrml-item-active-after-display: unset !default;
$tab-nrml-item-active-after-font-weight: unset !default;
$tab-nrml-item-active-after-height: unset !default;
$tab-nrml-item-active-after-overflow: unset !default;
$tab-nrml-hscroll-bar-padding: 0 !default;
$tab-nrml-vertical-indicator-bdr-radius: 0 !default;
$tab-nrml-indicator-transition: left .125s cubic-bezier(.35, 0, .25, 1), right .25s cubic-bezier(.35, 0, .25, 1) !default;
$tab-nrml-active-item-tabicon-before-top: 0 !default;
$tab-nrml-bottom-active-before-top: unset !default;
$tab-nrml-vertical-indicator-transition: top .125s cubic-bezier(.35, 0, .25, 1), bottom .25s cubic-bezier(.35, 0, .25, 1) !default;
$tab-nrml-vertical-hover-before-height: unset !default;
$tab-nrml-vertical-before-bottom: unset !default;
$tab-nrml-vertical-before-height: unset !default;
$tab-nrml-vertical-before-left: unset !default;
$tab-nrml-vertical-before-transition: unset !default;
$tab-nrml-vertical-text-position: unset !default;
$tab-nrml-vertical-wrap-before-content: unset !default;
$tab-nrml-vertical-wrap-before-display: unset !default;
$tab-nrml-vertical-wrap-before-position: unset !default;
$tab-nrml-vertical-wrap-before-top: unset !default;
$tab-nrml-vertical-wrap-before-width: unset !default;
$tab-nrml-vertical-wrap-before-border: unset !default;
$tab-nrml-vertical-indicator-width: 2px !default;
$tab-nrml-bottom-wrap-before-top: unset !default;
$tab-nrml-background-vertical-indicator-display: block !default;
$tab-nrml-background-active-padding: $tab-alt-nrml-active-item-padding !default;
$tab-nrml-item-text-margin-bottom: 0 !default;
@mixin tbar-alt-btn-animation-after {
  animation: tbar-alt-popup-shadow 1s ease-out 0ms;
}
@keyframes tbar-alt-popup-shadow {
  0% {
    border-color: $tbar-alt-ribble-animation-border-frame;
    box-shadow: $tbar-alt-ribble-animation-shadow-frame;
  }

  100% {
    box-shadow: $tbar-alt-ribble-animation-shadow-frame-end;
  }
}
@mixin hscroll-alt-btn-animation-after {
  animation: hscroll-alt-popup-shadow 1s ease-out 0ms;
}
@keyframes hscroll-alt-popup-shadow {
  0% {
    border-color: $hscroll-alt-ribble-animation-border-frame;
    box-shadow: $hscroll-alt-ribble-animation-shadow-frame;
  }

  100% {
    box-shadow: $hscroll-alt-ribble-animation-shadow-frame-end;
  }
}
$tab-alt-wrap-hover-close-icon-focus-or-active-color: $primary-font !default;
$tab-vscroll-nrml-padding: 16px 0 !default;
$tab-vscroll-big-padding: 24px 0 !default;
$tab-big-v-wrap-padding: 0 24px !default;
$tab-nrml-v-wrap-padding: 0 18px !default;
$tab-indicator-display: block !default;
$tab-big-more-btn-line-height: 46px !default;
$tab-nrml-more-btn-line-height: 35px !default;
$tab-pop-more-icon-margin: 0 0 0 10px !default;
$tab-rtl-pop-more-icon-margin: 0 10px 0 0 !default;
$tab-vscroll-nav-border: 1px solid transparent !default;
$tab-vscroll-nav-border-color: rgba($grey-light-font, .12) !default;
$tab-v-nav-focus-bg-color: rgba($grey-light-font, .12) !default;
$tab-v-nav-hover-bg-color: rgba($grey-light-font, .12) !default;
$tab-v-popup-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12) !default;
$tab-v-nav-icon-border-width: 0 !default;
$tab-v-big-close-icon-top-bottom-right: -24px !default;
$tab-v-nrml-close-icon-top-bottom-right: -18px !default;
$tab-v-rtl-big-close-icon-top-bottom-right: 0 !default;
$tab-v-rtl-nrml-close-icon-top-bottom-right: 0 !default;
$tab-v-rtl-big-close-icon-top-bottom-left: -24px !default;
$tab-v-rtl-nrml-close-icon-top-bottom-left: -18px !default;
$tab-v-pop-focus-outline: 0 !default;
$tab-header-font-weight: normal !default;
$tab-header-before-border-color: unset !default;
$tab-header-before-border-width: 0 !default;
$tab-header-border-bottom: $tab-hdr-border !default;
$tab-item-hover-bg: inherit !default;
$tab-item-wrap-border: 0 !default;
$tab-clone-item-wrap-border: 0 !default;
$tab-item-wrap-hover-border: 0 !default;
$tab-item-wrap-hover-border-radius: 0 !default;
$tab-item-active-hover-bg: inherit !default;
$tab-item-active-wrap-border: 0 !default;
$tab-active-wrap-position: unset !default;
$tab-active-wrap-before-border: unset !default;
$tab-active-wrap-before-content: unset !default;
$tab-active-wrap-before-display: unset !default;
$tab-active-wrap-before-position: unset !default;
$tab-active-wrap-before-top: unset !default;
$tab-active-wrap-before-width: unset !default;
$tab-focused-wrap-focus-border: 0 !default;
$tab-focused-wrap-focus-icon-color: $tab-focus-text-color !default;
$tab-content-font-size: 14px !default;
$tab-item-active-tabwrap-border: 0 !default;
$tab-item-left-icon-before-top: 1px !default;
$tab-item-icon-before-top: 1px !default;
$tab-item-icon-before-left: 2px !default;
$tab-item-active-border-bottom: initial !default;
$tab-pop-wrap-hover-border: 0 !default;
$tab-pop-wrap-hover-border-radius: 0 !default;
$tab-pop-wrap-hover-close-color: $tab-hover-text-color !default;
$tab-pop-wrap-active-border-color: unset !default;
$tab-pop-wrap-active-icon-font: $tab-pop-text-color !default;
$tab-pop-icon-border: 0 !default;
$tab-pop-focus-icon-active-bg: $tab-pop-icon-hover-bg-color !default;
$tab-pop-focus-icon-active-color: $tab-nav-focus-icon-color !default;
$tab-pop-focus-bg: transparent !default;
$tab-pop-hover-bg: transparent !default;
$tab-pop-nav-active-bg: inherit !default;
$tab-pop-nav-active-border: 0 !default;
$tab-pop-nav-active-border-radius: 0 !default;
$tab-pop-active-icons-color: rgba($grey-light-font, .87) !default;
$tab-scroll-nav-bg: inherit !default;
$tab-scroll-nav-arrow-font: $tab-nav-icon-color !default;
$tab-scroll-arrow-hover-border: $tab-item-active-wrap-border !default;
$tab-scroll-arrow-active-border: $tab-item-active-wrap-border !default;
$tab-scroll-focus-arrow-border: $tab-item-active-wrap-border !default;
$tab-scroll-focus-arrow-active-bg: $tab-nav-focus-bg-color !default;
$tab-scroll-focus-arrow-active-border-color: unset !default;
$tab-scroll-focus-arrow-active-color: $tab-nav-focus-icon-color !default;
$tab-scroll-active-border: 0 !default;
$tab-scroll-overlay-arrow-color: $tab-disable-nav-icon-color !default;
$tab-scroll-overlay-arrow-hover-color: $tab-disable-nav-icon-color !default;
$tab-scroll-overlay-arrow-hover-bg: $tab-hover-nav-bg-color !default;
$tab-scroll-overlay-arrow-hover-border-color: unset !default;
$tab-scroll-focused-nav-focus-icon-bg: $tab-nav-focus-bg-color !default;
$tab-scroll-focused-nav-focus-icon-border: $tab-pop-up-icon-focus-border !default;
$tab-bottom-border-color: unset !default;
$tab-bottom-border-width: 0 !default;
$tab-bottom-hidden-items-border-color: unset !default;
$tab-bottom-hidden-items-border-width: 0 !default;
$tab-bottom-active-border-color: $tab-active-item-border-color unset unset !default;
$tab-bottom-active-border-width: 0 !default;
$tab-bottom-active-margin-top: 0 !default;
$tab-vertical-scroll-hover-border-color: $tab-vscroll-nav-border-color transparent transparent !default;
$tab-vertical-scroll-hover-active-bg: $tab-v-nav-focus-bg-color !default;
$tab-vertical-focus-active-arrow-color: none !default;
$tab-vertical-pop-icon-hover-color: $tab-hover-nav-icon-color !default;
$tab-vertical-pop-icon-active-color: $tab-nav-focus-icon-color !default;
$tab-vertical-focused-nav-focus-border-color: $tab-vertical-scroll-hover-border-color !default;
$tab-vertical-before-border-width: 0 !default;
$tab-fill-wrap-hover-icon-color: $tab-hover-text-color !default;
$tab-fill-active-tabwrap-border-color: unset !default;
$tab-fill-active-text-before-border: unset !default;
$tab-fill-active-close-hover-color: $tab-alt-active-hover-close-icon-color !default;
$tab-fill-pop-active-icon-color: $tab-pop-text-color !default;
$tab-fill-pop-wrap-hover-bg: $tab-hover-bg-color !default;
$tab-fill-pop-wrap-hover-text-color: $tab-hover-text-color !default;
$tab-fill-pop-wrap-hover-icon-color: $tab-hover-text-color !default;
$tab-fill-pop-wrap-hover-close-color: $tab-hover-text-color !default;
$tab-fill-focused-active-tabwrap-focus-border-color: unset !default;
$tab-fill-focused-active-wrap-focus-text-color: $tab-alt-active-style-hover-icon-color !default;
$tab-fill-focused-active-wrap-focus-close-color: $tab-hover-text-color !default;
$tab-background-indicator-bottom: 0 !default;
$tab-background-wrap-border-color: unset !default;
$tab-background-close-hover-color: $tab-alt-active-wrap-close-icon-color !default;
$tab-background-pop-icon-hover-bg: $tab-bg-hover-nav-bg-color !default;
$tab-background-pop-icon-hover-border-color: unset !default;
$tab-background-focused-active-wrap-focus-bg: $tab-key-alt-active-focus-bg-color !default;
$tab-background-focused-active-wrap-focus-border-color: unset !default;
$tab-background-wrap-hover-text-color: $tab-alt-active-text-color !default;
$tab-background-wrap-hover-icon-color: $tab-alt-active-text-color !default;
$tab-background-wrap-hover-close-color: $tab-alt-text-color !default;
$tab-background-close-hover-active-color: $tab-alt-wrap-hover-close-icon-focus-or-active-color !default;
$tab-background-wrap-hover-bg-color: $tab-bg-item-wrap-hover-bg-color !default;
$tab-background-wrap-hover-border-color: unset !default;
$tab-background-active-bg: inherit !default;
$tab-background-active-text-color: $tab-alt-active-text-color !default;
$tab-background-active-icon-color: $tab-alt-active-text-color !default;
$tab-background-active-close-color: $tab-alt-active-close-icon-color !default;
$tab-background-active-wrap-bg: $tab-alt-active-bg-color !default;
$tab-background-active-wrap-border-color: unset !default;
$tab-background-active-wrap-border-radius: $tab-active-item-border-radius !default;
$tab-background-active-wrap-border-width: 0 !default;
$tab-background-active-hover-text-color: $tab-alt-active-text-color !default;
$tab-background-active-hover-icon-color: $tab-alt-active-text-color !default;
$tab-background-active-hover-close-color: $tab-alt-active-close-icon-color !default;
$tab-background-active-hover-bg: $tab-background-active-wrap-bg !default;
$tab-background-active-hover-border-color: $tab-background-active-wrap-border-color !default;
$tab-background-active-close-hover-color: $tab-background-close-hover-active-color !default;
$tab-background-activeitem-close-active-color: $tab-background-close-hover-active-color !default;
$tab-background-active-wrap-focus-bg: $tab-alt-active-focus-bg-color !default;
$tab-background-active-wrap-focus-text-color: $tab-alt-active-text-color !default;
$tab-background-active-wrap-focus-icon: $tab-alt-active-text-color !default;
$tab-background-active-wrap-focushover-text-color: $tab-background-active-wrap-focus-icon !default;
$tab-background-active-wrap-focushover-close-color: $tab-alt-active-close-icon-color !default;
$tab-background-active-text-icon-color: $tab-alt-active-text-color !default;
$tab-background-active-close-icon-color: $tab-alt-active-close-icon-color !default;
$tab-background-active-close-text-hover-color: $tab-alt-active-hover-close-icon-color !default;
$tab-background-pop-text-color: $tab-alt-text-color !default;
$tab-background-pop-wrap-hover-bg: $tab-alt-hover-bg-color !default;
$tab-background-pop-wrap-hover-text-color: $tab-alt-active-text-color !default;
$tab-background-pop-wrap-active-text-color: $tab-background-pop-text-color !default;
$tab-background-pop-wrap-active-icon-color: $tab-background-pop-text-color !default;
$tab-background-pop-wrap-active-close-color: $tab-background-pop-text-color !default;
$tab-background-scroll-bg: inherit !default;
$tab-background-scroll-arrow-border: 0 !default;
$tab-background-scroll-arrow-hover-bg: $tab-bg-hover-nav-bg-color !default;
$tab-background-scroll-arrow-hover-border-color: unset !default;
$tab-background-scroll-arrow-active-bg: unset !default;
$tab-background-scroll-arrow-active-color: $tab-alt-nav-icon-color !default;
$tab-background-scroll-focus-arrow-border: 0 !default;
$tab-background-scroll-focus-arrow-bg: $tab-nav-focus-bg-color !default;
$tab-background-nav-focus-active-bg: $tab-nav-focus-bg-color !default;
$tab-background-nav-focus-active-border-color: unset !default;
$tab-background-nav-focus-active-color: $tab-bg-hover-nav-icon-color !default;
$tab-background-hor-nav-bg: transparent !default;
$tab-background-pop-icon-color: $tab-alt-nav-icon-color !default;
$tab-background-pop-icon-border: 0 !default;
$tab-background-pop-active-hover-bg: $tab-background-pop-icon-hover-bg !default;
$tab-background-pop-active-hover-color: $tab-bg-hover-nav-icon-color !default;
$tab-background-focus-pop-bg: $tab-nav-focus-bg-color !default;
$tab-background-focus-pop-border: $tab-pop-up-icon-focus-border !default;
$tab-background-focus-pop-hover-bg: $tab-background-pop-icon-hover-bg !default;
$tab-background-focused-wrap-focus-bg: $tab-alt-hover-bg-color !default;
$tab-background-focused-wrap-focus-border-color: unset !default;
$tab-background-popicon-focus-active-bg: $tab-pop-focus-icon-active-bg !default;
$tab-background-popicon-focus-active-border-color: unset !default;
$tab-background-popicon-focus-active-color: $tab-bg-hover-nav-icon-color !default;
$tab-background-bottom-active-wrap-border-color: unset !default;
$tab-background-bottom-active-wrap-border-radius: 0 !default;
$tab-background-bottom-active-wrap-border-width: 0 !default;
$tab-background-vertical-left-active-border-color: unset !default;
$tab-background-vertical-left-active-border-width: 0 !default;
$tab-background-vertical-left-active-wrap-border-radius: 0 !default;
$tab-background-vertical-left-active-wrap-border-width: 0 !default;
$tab-background-vertical-right-active-border-width: 0 !default;
$tab-background-vertical-right-active-wrap-border-radius: 0 !default;
$tab-background-vertical-right-active-wrap-border-width: 0 !default;
$tab-background-focused-active-wrap-hover-bg: $tab-background-active-wrap-bg !default;
$tab-background-focused-active-wrap-hover-border: 0 !default;
$tab-background-focused-active-wrap-hover-border-radius: $tab-background-active-wrap-border-radius !default;
$tab-background-focused-active-wrap-focus-text-color: $tab-alt-active-hover-text-color !default;
$tab-background-focused-active-wrap-focus-icon-color: $tab-alt-active-hover-text-color !default;
$tab-background-focused-active-wrap-focus-close-color: $tab-alt-text-color !default;
$tab-background-focused-active-wrap-hover-text-color: $tab-background-active-hover-text-color !default;
$tab-background-focused-active-wrap-hover-icon-color: $tab-background-active-hover-icon-color !default;
$tab-background-focused-active-wrap-hover-close-color: $tab-background-active-close-icon-color !default;
$tab-background-focused-active-focus-hover-text-color: $tab-alt-active-hover-text-color !default;
$tab-background-focused-active-focus-hover-close-color: $tab-alt-text-color !default;
$tab-background-focused-wrap-focus-close-color: $tab-alt-text-color !default;
$tab-background-focused-active-wrap-icon-color: $tab-alt-active-hover-text-color !default;
$tab-background-accent-indicator-bg: $tab-border-color !default;
$tab-background-active-text-font-weight: $tab-light-font-weight !default;
$tab-background-text-color: $tab-alt-text-color !default;
$tab-fill-background-disable-text-color: $tab-disable-text-color !default;

@mixin tab-mob-last-item-margin {
  @media screen and (max-width: 480px) {
    margin: $tab-mob-last-item-margin;
  }
}
@mixin tab-mob-close-icon-margin {
  @media screen and (max-width: 480px) {
    margin: $tab-mob-close-icon-margin;
  }
}
@include export-module('tab-layout') {
  .e-tab {
    display: block;
    position: relative;

    &.e-hidden {
      display: none;
    }

    &.e-fill-mode .e-content,
    &.e-fill-mode .e-content .e-item,
    &.e-fill-mode .e-content .e-item > div,
    &.e-fill-mode .e-content .e-item > div > .e-blazor-template {
      height: 100%;
    }

    .e-tab-header {
      height: $tab-nrml-height;
      min-height: $tab-nrml-height;

      @media screen and (max-width: 480px) {
        height: $tab-big-height;
        min-height: $tab-big-height;
      }

      &::before {
        content: '';
        position: absolute;
        display: $tab-nrml-hdr-before-display;
      }

      &:not(.e-vertical) {

        &::before {
          bottom: 0;
          top: 0;
          width: 100%;
        }
      }

      .e-toolbar-items {
        height: auto;
        margin: 0;
        min-height: $tab-nrml-height;
        position: relative;

        @media screen and (max-width: 480px) {
          min-height: $tab-big-height;
        }

        &:not(.e-tbar-pos) .e-toolbar-item:first-child,
        &:not(.e-tbar-pos) .e-toolbar-item:last-child {
          margin: $tab-nrml-first-item-margin;
        }

        &:not(.e-tbar-pos) .e-toolbar-item:last-child {
          margin: $tab-nrml-last-item-margin;
          padding-bottom: $tab-big-boot-active-item-padding-bottom;
          @include tab-mob-last-item-margin;
        }

        .e-toolbar-item.e-active {
          position: $tab-nrml-item-active-position;

          &:hover::before {
            left: $tab-nrml-item-active-hover-before-left;
            right: $tab-nrml-item-active-hover-before-right;
          }

          &::before {
            background-color: $tab-nrml-item-active-before-bg-font;
            bottom: $tab-nrml-item-active-before-bottom;
            content: $tab-nrml-item-active-before-content;
            height: $tab-nrml-item-active-before-height;
            left: $tab-nrml-item-active-before-left;
            position: $tab-nrml-item-active-before-position;
            right: $tab-nrml-item-active-before-left;
            transition: $tab-nrml-item-active-before-transition;
          }

          &::after {
            color: $tab-nrml-item-active-after-font;
            content: $tab-nrml-item-active-after-content;
            display: $tab-nrml-item-active-after-display;
            font-weight: $tab-nrml-item-active-after-font-weight;
            height: $tab-nrml-item-active-after-height;
            overflow: $tab-nrml-item-active-after-overflow;
            visibility: $tab-nrml-item-active-after-overflow;
          }
        }
      }

      .e-toolbar-items.e-hscroll {

        &.e-scroll-device {
          padding: 0;

          .e-scroll-right-nav {
            display: none;
          }
        }

        .e-hscroll-bar {
          padding: $tab-nrml-hscroll-bar-padding;
        }

        .e-scroll-nav {
          width: $tab-nrml-hscroll-items-nav-width;
          border-radius: $tab-nrml-hscroll-items-bdr-radius;
        }

        &:not(.e-tbar-pos) .e-toolbar-item:last-child {
          margin: $tab-nrml-scroll-last-item-margin;
          @include tab-mob-last-item-margin;

          &.e-active {
            margin: $tab-nrml-active-scroll-last-item-margin;
          }
        }
      }

      .e-hscroll-bar {
        overflow: hidden;
      }

      .e-indicator {
        display: $tab-anim-border-display;
        position: absolute;

        &.e-hidden {
          display: none;
        }
      }

      &:not(.e-vertical) .e-indicator {
        bottom: 0;
        height: $tab-border-width;
        left: 0;
        right: 0;
        border-radius: $tab-nrml-vertical-indicator-bdr-radius;
        transition: $tab-nrml-indicator-transition;
      }

      .e-toolbar-item {

        &.e-hidden {
          display: none;
        }

        &:not(.e-separator) {
          height: $tab-nrml-height;
          margin: $tab-nrml-item-margin;
          min-height: $tab-nrml-height;
          min-width: auto;
          padding: $tab-nrml-item-padding;

          @media screen and (max-width: 480px) {
            height: $tab-big-height;
            min-height: $tab-big-height;
          }
        }

        .e-tab-wrap {
          height: $tab-nrml-height;
          padding: $tab-nrml-wrap-padding;
          width: 100%;

          @media screen and (max-width: 480px) {
            height: $tab-big-height;
            padding: $tab-mob-wrap-padding;
          }
        }

        .e-text-wrap {
          align-content: center;
          align-items: center;
          display: inline-flex;
          height: $tab-nrml-item-text-wrap-height;

          @media screen and (max-width: 480px) {
            height: $tab-big-height;
          }
        }

        .e-tab-text {
          display: inherit;
          font-family: $tab-font-family;
          font-size: $tab-nrml-font-size;
          font-weight: $tab-font-weight;
          text-transform: $tab-text-transform;
          align-self: auto;
          margin-bottom: $tab-nrml-item-text-margin-bottom;

          @media screen and (max-width: 480px) {
            font-size: $tab-big-font-size;
          }
        }

        &.e-active {
          margin: $tab-nrml-boot-item-margin;
          padding-bottom: $tab-nrml-even-boot-active-item-padding-bottom;

          @media screen and (max-width: 480px) {
            margin: $tab-mob-boot-item-margin;
          }

          .e-text-wrap {
            height: $tab-nrml-item-text-wrap-height;
            margin-top: $tab-active-text-container-margin;

            @media screen and (max-width: 480px) {
              height: $tab-big-active-text-container-height;
            }
          }

          .e-tab-wrap {
            margin-bottom: $tab-nrml-active-item-wrap-margin-bottom;
          }

          .e-tab-text {
            font-weight: $tab-semi-font-weight;
          }

          .e-tab-icon::before {
            top: $tab-item-icon-before-top;
            left: $tab-item-icon-before-left;
          }

          .e-icon-top.e-tab-icon::before,
          .e-icon-bottom.e-tab-icon::before {
            left: 0;
          }

          &.e-ileft,
          &.e-iright {

            .e-text-wrap {
              height: $tab-nrml-item-text-wrap-height;
              padding: 0;

              @media screen and (max-width: 480px) {
                height: $tab-big-active-it-text-container-height;
              }
            }
          }
        }

        .e-icons.e-close-icon {
          display: none;
          margin: $tab-nrml-close-icon-margin;
          min-width: $tab-close-icon-minwidth;
          @include tab-mob-close-icon-margin;
        }

        .e-close-icon,
        .e-close-icon::before {
          font-size: $tab-nrml-close-icon-size;

          @media screen and (max-width: 480px) {
            font-size: $tab-mob-close-icon-size;
          }
        }

        .e-close-icon::before {
          top: 1px;
        }

        .e-icons.e-tab-icon {
          display: inline-flex;
          height: $tab-nrml-icon-container-size;
          min-width: $tab-nrml-icon-container-size;
          width: $tab-nrml-icon-container-size;
          line-height: $tab-nrml-item-tabicon-line-height;

          @media screen and (max-width: 480px) {
            height: $tab-mob-icon-container-size;
            width: $tab-mob-icon-container-size;
          }
        }

        .e-tab-icon,
        .e-tab-icon::before {
          font-size: $tab-nrml-icon-size;

          @media screen and (max-width: 480px) {
            font-size: $tab-mob-icon-size;
          }
        }

        .e-tab-icon::before {
          position: relative;
          top: $tab-item-icon-before-top;
          left: $tab-item-icon-before-left;
        }

        .e-icon-top.e-tab-icon::before,
        .e-icon-bottom.e-tab-icon::before {
          left: 0;
        }

        &.e-icon {

          .e-tab-wrap {
            justify-content: center;
            padding: $tab-nrml-io-wrap-padding;

            @media screen and (max-width: 480px) {
              padding: $tab-big-wrap-padding;
            }
          }
        }

        .e-icon-right {
          margin: 0 0 0 8px;

          @media screen and (max-width: 480px) {
            margin: $tab-bgr-mob-item-iconright-margin;
          }
        }

        .e-icon-left + .e-tab-text {
          margin: $tab-nrml-it-text-margin;

          @media screen and (max-width: 480px) {
            margin: $tab-mob-it-text-margin;
          }
        }

        &.e-itop,
        &.e-ibottom {
          height: $tab-nrml-icon-top-bottom-item-height;

          @media screen and (max-width: 480px) {
            height: $tab-nrml-icon-top-bottom-item-height;
          }

          .e-tab-wrap {
            align-items: center;
            display: flex;
            height: $tab-nrml-icon-top-bottom-item-height;
            position: relative;

            @media screen and (max-width: 480px) {
              height: $tab-nrml-icon-top-bottom-item-height;
            }

            &:focus {

              .e-text-wrap {
                height: auto;
              }
            }
          }

          .e-text-wrap {
            display: block;
            height: auto;
          }

          &.e-active {

            .e-tab-wrap {

              &:focus {

                .e-text-wrap {
                  height: auto;
                }
              }

              .e-text-wrap {
                height: auto;
              }
            }
          }

          .e-close-icon {
            position: absolute;
            right: $tab-nrml-close-icon-top-bottom-right;
            top: calc(50% - 6px);

            @media screen and (max-width: 480px) {
              right: $tab-mob-close-icon-top-bottom-right;
            }
          }
        }

        &.e-itop .e-tab-text {
          margin: $tab-nrml-icon-top-margin;

          @media screen and (max-width: 480px) {
            margin: $tab-big-icon-top-margin;
          }
        }

        &.e-ibottom .e-tab-text {
          margin: $tab-nrml-icon-bottom-margin;

          @media screen and (max-width: 480px) {
            margin: $tab-big-icon-bottom-margin;
          }
        }

        .e-tab-icon.e-icon-top,
        .e-tab-icon.e-icon-bottom {
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin: auto;
        }
      }

      .e-toolbar-pop {
        overflow-y: auto;
        padding: $tab-pop-padding;

        .e-toolbar-item {
          height: $tab-nrml-pop-item-height;
          min-height: $tab-nrml-pop-item-height;
          min-width: auto;

          @media screen and (max-width: 480px) {
            height: $tab-mob-pop-item-height;
            min-height: $tab-mob-pop-item-height;
          }

          &:not(.e-separator) {
            margin: $tab-pop-item-margin;
            padding: 0;
          }

          .e-tab-wrap {
            height: $tab-nrml-pop-item-height;
            padding: $tab-nrml-pop-item-wrap-padding;
            text-align: initial;

            @media screen and (max-width: 480px) {
              height: $tab-mob-pop-item-height;
              padding: $tab-mob-pop-item-wrap-padding;
            }
          }

          .e-text-wrap {
            height: $tab-nrml-pop-item-textwrap-height;
            width: 100%;
          }

          .e-tab-text {
            display: inline-flex;
            width: 100%;
          }

          .e-close-icon {
            margin: 0 0 0 8px;
          }

          .e-close-icon::before {
            top: $tab-nrml-pop-close-top;
          }

          .e-tab-text + .e-close-icon[style = 'display:block'] {
            padding-right: $tab-nrml-pop-text-padding-right;

            @media screen and (max-width: 480px) {
              padding-right: 0;
            }
          }

          &.e-itop,
          &.e-ibottom {
            height: $tab-pop-nrml-icon-top-bottom-item-height;

            @media screen and (max-width: 480px) {
              height: $tab-pop-big-icon-top-bottom-item-height;
            }

            &:not(.e-separator) {
              min-height: $tab-pop-nrml-icon-top-bottom-item-height;

              @media screen and (max-width: 480px) {
                min-height: $tab-pop-big-icon-top-bottom-item-height;
              }
            }

            .e-tab-wrap {
              height: $tab-pop-nrml-icon-top-bottom-item-height;

              @media screen and (max-width: 480px) {
                height: $tab-pop-big-icon-top-bottom-item-height;
              }
            }

            .e-text-wrap {
              display: inline-flex;
              height: auto;
            }

            .e-tab-text {
              display: block;
              justify-content: center;
              margin: 0 0 0 10px;
              padding-left: 0;
              padding-right: 10px;

              @media screen and (max-width: 480px) {
                justify-content: center;
                margin: 0 0 0 12px;
                padding-left: 0;
                padding-right: 0;
              }
            }

            .e-close-icon {
              right: $tab-nrml-pop-close-icon-top-bottom-right;
              top: initial;
            }
          }

          &.e-ibottom {

            .e-tab-text {
              margin: 0;
            }
          }

          .e-tab-icon.e-icon-top,
          .e-tab-icon.e-icon-bottom {
            display: inline-flex;
          }
        }
      }

      &.e-close-show {

        .e-icons.e-close-icon {
          align-items: center;
          align-self: center;
          display: inline-flex;

          @media screen and (max-width: 480px) {
            display: none;
          }
        }

        .e-toolbar-item.e-active .e-close-icon {

          @media screen and (max-width: 480px) {
            display: inline-flex;
          }
        }

        .e-toolbar-item.e-itop .e-text-wrap,
        .e-toolbar-item.e-ibottom .e-text-wrap {
          margin-right: $tab-nrml-text-container-margin-right;
        }
      }

      .e-scroll-nav {
        height: $tab-nrml-height;
        min-height: $tab-nrml-height;
        min-width: auto;
        width: auto;

        @media screen and (max-width: 480px) {
          height: $tab-big-height;
          min-height: $tab-big-height;
        }

        &.e-scroll-left-nav {
          padding: $tab-nrml-nav-left-icon-padding;
        }

        &.e-scroll-right-nav {
          padding: $tab-nrml-nav-right-icon-padding;

          @media screen and (max-width: 480px) {
            padding: $tab-mob-nav-right-icon-padding;
          }
        }

        .e-nav-left-arrow::before,
        .e-nav-right-arrow::before {
          font-size: $tab-nrml-nav-icon-size;
          line-height: $tab-nrml-nav-icon-container-size;
          position: relative;
          top: $tab-nrml-scroll-nav-arrow-before-top;
          vertical-align: initial;
        }

        .e-nav-arrow {
          font-size: $tab-nrml-nav-icon-size;
          height: $tab-nrml-nav-icon-container-size;
          line-height: $tab-nrml-nav-icon-container-size;
          width: $tab-nrml-nav-icon-container-size;

          @media screen and (max-width: 480px) {
            font-size: $tab-big-nav-icon-size;
            height: $tab-big-nav-icon-container-size;
            line-height: $tab-big-nav-icon-container-size;
            width: $tab-big-nav-icon-container-size;
          }
        }
      }

      .e-hor-nav {
        height: $tab-nrml-height;
        min-height: $tab-nrml-height;
        min-width: auto;
        padding: $tab-nrml-nav-right-icon-padding;
        width: auto;

        @media screen and (max-width: 480px) {
          height: $tab-big-height;
          min-height: $tab-big-height;
          padding: $tab-mob-nav-right-icon-padding;
        }

        .e-popup-up-icon,
        .e-popup-down-icon {
          font-size: $tab-nrml-nav-icon-size;
          height: $tab-nrml-nav-icon-container-size;
          line-height: $tab-nrml-nav-icon-container-size;
          width: $tab-nrml-nav-icon-container-size;

          @media screen and (max-width: 480px) {
            font-size: $tab-big-nav-icon-size;
            height: $tab-big-nav-icon-container-size;
            line-height: $tab-big-nav-icon-container-size;
            width: $tab-big-nav-icon-container-size;
          }

          &::before {
            font-size: $tab-nrml-nav-icon-size;
            line-height: $tab-nrml-nav-icon-container-size;
            position: relative;
            top: 0;
            vertical-align: initial;

            @media screen and (max-width: 480px) {
              font-size: $tab-mob-nav-icon-size;
            }
          }

          &:hover {
            line-height: $tab-nrml-hor-pop-lineheight;

            &::before {
              line-height: $tab-nrml-hor-pop-lineheight;
              top: 0;

              @media screen and (max-width: 480px) {
                line-height: $tab-nrml-mob-hor-pop-lineheight;
              }
            }
          }
        }

        .e-popup-up-icon {
          transform: $tab-pop-up-icon-transform;
          transition: $tab-pop-icon-transition;

          @media screen and (max-width: 480px) {
            transform: none;
            transition: none;
          }
        }

        .e-popup-down-icon {
          transform: $tab-pop-down-icon-transform;
          transition: $tab-pop-icon-transition;

          @media screen and (max-width: 480px) {
            transform: none;
            transition: none;
          }
        }

        &:focus {

          .e-popup-up-icon,
          .e-popup-down-icon {
            line-height: $tab-nrml-hor-focus-pop-lineheight;

            &::before {
              line-height: $tab-nrml-hor-focus-pop-lineheight;
              top: 0;

              @media screen and (max-width: 480px) {
                line-height: $tab-nrml-mob-hor-pop-lineheight;
              }
            }

            &:hover {
              line-height: $tab-nrml-hor-focus-pop-lineheight;
              top: $tab-nrml-hor-focus-pop-hover-top;
            }
          }
        }
      }

      &.e-horizontal-bottom {

        .e-toolbar-items .e-toolbar-item.e-active {
          position: $tab-nrml-item-active-position;

          &:hover::before {
            left: $tab-nrml-item-active-hover-before-left;
            right: $tab-nrml-item-active-hover-before-right;
          }

          &::before {
            background-color: $tab-nrml-item-active-before-bg-font;
            content: $tab-nrml-item-active-before-content;
            height: $tab-nrml-item-active-before-height;
            left: $tab-nrml-item-active-before-left;
            position: $tab-nrml-item-active-before-position;
            right: $tab-nrml-item-active-before-left;
            top: $tab-nrml-item-active-before-bottom;
            transition: $tab-nrml-item-active-before-transition;
          }

          &::after {
            color: $tab-nrml-item-active-after-font;
            content: $tab-nrml-item-active-after-content;
            display: $tab-nrml-item-active-after-display;
            font-weight: $tab-nrml-item-active-after-font-weight;
            height: $tab-nrml-item-active-after-height;
            overflow: $tab-nrml-item-active-after-overflow;
            visibility: $tab-nrml-item-active-after-overflow;
          }
        }

        &::before {
          bottom: auto;
        }

        .e-hscroll-bar {
          margin-top: $tab-nrml-active-item-tabicon-before-top;
        }

        .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
          margin: $tab-nrml-even-last-item-margin;
          padding-bottom: 0;
          padding-top: $tab-nrml-even-boot-active-item-padding-bottom;

          @media screen and (max-width: 480px) {
            margin: $tab-mob-even-last-item-margin;
          }
        }

        .e-indicator {
          bottom: auto;
          top: 0;
        }

        .e-toolbar-item {

          &.e-active {
            margin: $tab-nrml-even-boot-item-margin;
            padding-bottom: 0;
            padding-top: $tab-nrml-boot-active-item-padding-bottom;

            @media screen and (max-width: 480px) {
              margin: $tab-mob-even-boot-item-margin;
            }

            .e-text-wrap {
              height: $tab-nrml-item-text-wrap-height;
              padding: 0;

              @media screen and (max-width: 480px) {
                height: $tab-big-active-text-container-height;
              }
            }

            .e-text-wrap::before,
            &:not(.e-separator):last-child .e-text-wrap::before {
              top: $tab-nrml-bottom-active-before-top;
            }

            &.e-itop {
              .e-tab-wrap:focus .e-close-icon::before {
                top: 0;
              }
            }
          }

          &.e-itop,
          &.e-ibottom {

            .e-text-wrap {
              height: initial;
            }

            &.e-active {

              .e-text-wrap {
                height: initial;
                padding: 0;
              }

              .e-text-wrap::before {
                bottom: auto;
                top: 0;
              }
            }
          }
        }
      }

      &.e-reorder-active-item .e-toolbar-pop .e-toolbar-item.e-active .e-text-wrap::before {
        display: none;
      }

      &.e-vertical {
        max-width: 150px;
        z-index: 1;

        &::before {
          bottom: 0;
          height: 100%;
          left: 0;
          top: 0;
        }

        &[style *= 'overflow: hidden']::before {
          bottom: $tab-nrml-vertical-scroll-nav-top-bottom;
          height: auto;
          top: $tab-nrml-vertical-scroll-nav-top-bottom;

          @media screen and (max-width: 480px) {
            bottom: 0;
            top: 0;
          }
        }

        .e-indicator {
          display: $tab-indicator-display;
          transition: $tab-nrml-vertical-indicator-transition;
          width: $tab-border-width;
        }

        .e-toolbar-items {
          height: inherit;
          flex-direction: column;
          align-items: normal;

          &.e-vscroll:not(.e-scroll-device) {
            padding: $tab-vscroll-nrml-padding;
          }
        }

        .e-toolbar-item {

          &:last-child {
            margin: 0;
          }

          &:not(.e-separator) {
            margin: 0;
          }

          .e-tab-wrap {
            padding: $tab-nrml-v-wrap-padding;
          }

          .e-text-wrap {
            position: relative;
            width: 100%;
          }

          .e-tab-text,
          .e-tab-icon::before {
            text-align: center;
            width: 100%;
          }

          .e-tab-text {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          &.e-active {

            .e-text-wrap::before {
              display: none;
            }
          }

          &.e-ileft.e-icon {
            min-height: $tab-nrml-vertical-icon-min-height;
            min-width: $tab-nrml-vertical-icon-min-width;

            .e-tab-icon {
              margin: $tab-nrml-vertical-tabicon-margin;
            }
          }

          &.e-itop,
          &.e-ibottom {

            .e-close-icon {
              right: $tab-v-nrml-close-icon-top-bottom-right;
            }
          }
        }

        .e-toolbar-pop {
          top: initial !important; /* stylelint-disable-line declaration-no-important */
        }

        &.e-vertical-left {
          float: left;

          &::before {
            right: 0;
          }

          .e-indicator {
            left: auto;
            right: 0;
            @if ($skin-name == 'Material3') {
              border-radius: 3px 0 0 3px;
            }
            @else if ($skin-name == 'fluent2') {
              border-radius: $tab-nrml-vertical-indicator-bdr-radius;
            }
          }

          .e-toolbar-items .e-toolbar-item.e-active {

            &:hover::before {
              bottom: $tab-nrml-item-active-before-bottom;
              height: $tab-nrml-vertical-hover-before-height;
            }

            &::before {
              background-color: $tab-nrml-item-active-before-bg-font;
              bottom: $tab-nrml-vertical-before-bottom;
              content: $tab-nrml-item-active-before-content;
              height: $tab-nrml-vertical-before-height;
              left: $tab-nrml-vertical-before-left;
              position: $tab-nrml-item-active-before-position;
              right: $tab-nrml-item-active-before-bottom;
              transition: $tab-nrml-vertical-before-transition;
              width: $tab-nrml-item-active-before-height;
            }

            &::after {
              color: $tab-nrml-item-active-after-font;
              content: $tab-nrml-item-active-after-content;
              display: $tab-nrml-item-active-after-display;
              font-weight: $tab-nrml-item-active-after-font-weight;
              height: $tab-nrml-item-active-after-height;
              overflow: $tab-nrml-item-active-after-overflow;
              visibility: $tab-nrml-item-active-after-overflow;
            }
          }
        }

        &.e-vertical-right {
          float: right;

          &::before {
            right: auto;
          }

          .e-indicator {
            left: 0;
            right: auto;
            @if ($skin-name == 'Material3') {
              border-radius: 0 3px 3px 0;
            }
            @else if ($skin-name == 'fluent2') {
              border-radius: $tab-nrml-vertical-indicator-bdr-radius;
            }
          }

          .e-tab-wrap {
            text-align: right;
          }

          .e-toolbar-pop {

            .e-tab-text {
              width: auto;
            }
          }

          .e-toolbar-items .e-toolbar-item.e-active {

            &:hover::before {
              bottom: $tab-nrml-item-active-before-bottom;
              height: $tab-nrml-vertical-hover-before-height;
            }

            &::before {
              background-color: $tab-nrml-item-active-before-bg-font;
              bottom: $tab-nrml-vertical-before-bottom;
              content: $tab-nrml-item-active-before-content;
              height: $tab-nrml-vertical-before-height;
              left: $tab-nrml-item-active-before-bottom;
              position: $tab-nrml-item-active-before-position;
              right: $tab-nrml-vertical-before-left;
              transition: $tab-nrml-vertical-before-transition;
              width: $tab-nrml-item-active-before-height;
            }

            &::after {
              color: $tab-nrml-item-active-after-font;
              content: $tab-nrml-item-active-after-content;
              display: $tab-nrml-item-active-after-display;
              font-weight: $tab-nrml-item-active-after-font-weight;
              height: $tab-nrml-item-active-after-height;
              overflow: $tab-nrml-item-active-after-overflow;
              visibility: $tab-nrml-item-active-after-overflow;
            }
          }
        }

        .e-scroll-nav {
          height: $tab-nrml-vertical-nav-arrow-size;
          justify-content: center;
          min-height: $tab-nrml-vertical-nav-arrow-size;
          width: 100%;

          .e-nav-arrow {
            font-size: $tab-nrml-vertical-nav-arrow-font-size;
            height: $tab-nrml-vertical-nav-arrow-size;
            line-height: $tab-nrml-vertical-nav-arrow-size;
            margin: 0 auto;
            width: $tab-nrml-vertical-nav-arrow-size;
          }
        }

        .e-hor-nav {
          padding: $tab-nrml-wrap-padding;
          width: 100%;

          .e-popup-up-icon,
          .e-popup-down-icon {
            height: 100%;
            transform: none;
            transition: none;
            width: 100%;

            &::before {
              float: left;
              font-family: $tab-font-family;
              font-size: $tab-nrml-font-size;
              font-weight: $tab-font-weight;
              line-height: $tab-nrml-more-btn-line-height;
              text-align: left;
              text-transform: $tab-text-transform;
              transform: none;
              transition: none;
            }

            &::after {
              float: left;
              font-size: 12px;
              line-height: $tab-nrml-more-btn-line-height;
              margin: $tab-pop-more-icon-margin;
              vertical-align: initial;

              @media screen and (max-width: 480px) {
                font-size: $tab-big-font-size;
              }
            }
          }
        }

        .e-scroll-device {

          .e-scroll-nav,
          .e-scroll-overlay {
            display: none;
          }
        }

        &.e-toolpop {

          .e-toolbar-items {
            height: auto;
          }
        }
      }
    }

    &.e-rtl {

      .e-tab-header.e-vertical {

        .e-toolbar-items:not(.e-tbar-pos) {

          .e-toolbar-item:last-child {
            margin: 0;
          }
        }

        .e-toolbar-item {

          &:not(.e-separator) {
            margin: 0;

            &.e-itop .e-close-icon,
            &.e-ibottom .e-close-icon {
              left: $tab-v-rtl-nrml-close-icon-top-bottom-left;
            }
          }
        }

        .e-hor-nav {
          padding: $tab-nrml-wrap-padding;

          .e-popup-up-icon,
          .e-popup-down-icon {

            &::before {
              float: right;
            }

            &::after {
              float: right;
              margin: $tab-rtl-pop-more-icon-margin;
            }
          }
        }
      }
    }

    .e-content {
      position: relative;

      .e-item {

        &.e-view {
          bottom: 0;
          left: 0;
          overflow: hidden;
          position: absolute;
          right: 0;
          top: 0;
        }
      }

      > .e-item {
        display: none;

        &.e-active {
          background: inherit;
          display: block;
        }
      }

      &.e-progress {
        overflow: hidden;
      }
    }

    &.e-vertical-tab {

      .e-content {
        display: flex;

        .e-item.e-active {
          display: flex;
          flex: none;
        }

        .e-item,
        .e-item > :first-child {
          width: 100%;
        }
      }
    }

    &.e-vertical-icon {

      > .e-tab-header {
        height: $tab-nrml-tb-icon-height;
        min-height: $tab-nrml-tb-icon-height;

        &.e-reorder-active-item .e-toolbar-pop {

          .e-toolbar-item.e-active .e-tab-wrap::before,
          .e-toolbar-item:not(.e-separator):last-child.e-active .e-tab-wrap::before {
            display: none;
          }
        }

        > .e-toolbar-items {
          height: $tab-nrml-tb-icon-height;
        }

        .e-toolbar-item.e-active,
        .e-toolbar-item:not(.e-separator):last-child.e-active {
          .e-text-wrap {
            position: $tab-nrml-vertical-text-position;
          }

          .e-text-wrap::before {
            border: $tab-active-wrap-before-border;
            content: $tab-active-wrap-before-content;
            display: none;
            position: $tab-active-wrap-before-position;
            top: $tab-active-wrap-before-top;
            width: $tab-active-wrap-before-width;
          }

          .e-tab-wrap::before {
            content: $tab-nrml-vertical-wrap-before-content;
            display: $tab-nrml-vertical-wrap-before-display;
            position: $tab-nrml-vertical-wrap-before-position;
            top: $tab-nrml-vertical-wrap-before-top;
            width: $tab-nrml-vertical-wrap-before-width;
            border: $tab-nrml-vertical-wrap-before-border;
          }
        }

        .e-scroll-nav {
          height: $tab-nrml-icon-top-bottom-item-height;
        }

        .e-hor-nav {
          height: $tab-nrml-icon-top-bottom-item-height;

          .e-popup-up-icon::before,
          .e-popup-down-icon::before {

            @media screen and (max-width: 480px) {
              font-size: $tab-mob-nav-vicon-size;
            }
          }
        }

        &.e-vertical {

          .e-indicator {
            width: $tab-nrml-vertical-indicator-width;
          }

          .e-toolbar-item.e-active,
          .e-toolbar-item:not(.e-separator):last-child.e-active {

            .e-tab-wrap::before {
              display: none;
            }

            .e-text-wrap {
              position: relative;
            }
          }

          .e-scroll-nav {
            height: $tab-nrml-vertical-nav-arrow-size;
          }
        }

        &.e-horizontal-bottom {

          .e-toolbar-item.e-active,
          .e-toolbar-item:not(.e-separator):last-child.e-active {
            .e-tab-wrap::before {
              top: $tab-nrml-bottom-wrap-before-top;
            }
          }
        }
      }

      &.e-rtl .e-tab-header .e-toolbar-item.e-active {
        margin: $tab-nrml-vertical-rtl-active-margin;
      }

      &.e-vertical-tab {

        .e-tab-header.e-vertical .e-toolbar-items {
          height: inherit;
        }
      }
    }

    &.e-focused {

      .e-tab-header .e-toolbar-item {

        .e-tab-wrap:focus {
          height: $tab-nrml-height;

          .e-text-wrap {
            height: $tab-nrml-item-text-wrap-height;
          }
        }

        &.e-itop,
        &.e-ibottom {

          .e-tab-wrap:focus {
            height: $tab-nrml-icon-top-bottom-item-height;
          }

          .e-tab-wrap:focus .e-text-wrap {
            height: auto;
          }

          &.e-active .e-tab-wrap:focus .e-text-wrap {
            height: auto;
          }
        }
      }

      .e-tab-header .e-toolbar-pop {

        .e-toolbar-item {

          .e-tab-wrap:focus {
            height: $tab-nrml-pop-item-height;

            .e-text-wrap {
              height: $tab-nrml-pop-item-textwrap-height;
            }
          }

          &.e-itop .e-tab-wrap:focus,
          &.e-ibottom .e-tab-wrap:focus {
            height: $tab-nrml-height;
          }
        }
      }

      .e-tab-header .e-horizontal-bottom {

        .e-toolbar-item {

          &.e-active {

            .e-tab-wrap:focus .e-text-wrap {
              height: $tab-nrml-item-text-wrap-height;
              padding: 0;
            }
          }

          &.e-itop,
          &.e-ibottom {

            .e-tab-wrap:focus .e-text-wrap {
              height: auto;
            }

            &.e-active .e-tab-wrap:focus .e-text-wrap {
              height: auto;
            }
          }
        }
      }
    }

    &.e-template {

      .e-tab-header {

        .e-toolbar-items,
        .e-toolbar-pop {

          .e-toolbar-item .e-tab-wrap .e-text-wrap .e-tab-text > div {
            display: inherit;
          }
        }
      }

      .e-content {

        > .e-item {
          display: none;

          &.e-active {
            background: inherit;
            display: block;
          }
        }
      }
    }

    &.e-ie .e-tab-header {

      .e-hor-nav.e-ie-align,
      .e-scroll-nav.e-ie-align {
        display: flex;

        .e-popup-up-icon,
        .e-popup-down-icon {
          display: block;
          line-height: $tab-nrml-ie-pop-icon-line-height;
        }

        .e-nav-left-arrow,
        .e-nav-right-arrow {
          display: block;
          line-height: $tab-nrml-ie-nav-icon-line-height;
        }
      }

      .e-popup-up-icon,
      .e-popup-down-icon {
        transform: none;
        transition: none;
      }

      .e-popup-up-icon::before {
        transform: $tab-pop-up-icon-transform;
        transition: $tab-pop-icon-transition;

        @media screen and (max-width: 480px) {
          transform: none;
          transition: none;
        }
      }

      .e-popup-down-icon::before {
        transform: $tab-pop-down-icon-transform;
        transition: $tab-pop-icon-transition;

        @media screen and (max-width: 480px) {
          transform: none;
          transition: none;
        }
      }
    }

    &.e-edge .e-tab-header {

      .e-hor-nav.e-ie-align,
      .e-scroll-nav.e-ie-align {
        display: flex;

        .e-popup-up-icon,
        .e-popup-down-icon {
          display: block;
          line-height: $tab-nrml-edge-pop-icon-line-height;
          position: relative;
        }

        .e-nav-left-arrow,
        .e-nav-right-arrow {
          display: block;
          line-height: $tab-nrml-edge-nav-icon-line-height;
          position: relative;
        }
      }

      .e-popup-up-icon {
        transform: $tab-pop-up-icon-transform;
        transition: $tab-pop-icon-transition;

        @media screen and (max-width: 480px) {
          transform: none;
          transition: none;
        }
      }

      .e-popup-down-icon {
        transform: $tab-pop-down-icon-transform;
        transition: $tab-pop-icon-transition;

        @media screen and (max-width: 480px) {
          transform: none;
          transition: none;

          @media screen and (max-width: 480px) {
            transform: none;
            transition: none;
          }
        }
      }

      .e-popup-up-icon::before {
        transform: $tab-pop-up-icon-transform;
        transition: $tab-pop-icon-transition;

        @media screen and (max-width: 480px) {
          transform: none;
          transition: none;
        }
      }

      .e-popup-down-icon::before {
        transform: $tab-pop-down-icon-transform;
        transition: $tab-pop-icon-transition;

        @media screen and (max-width: 480px) {
          transform: none;
          transition: none;
        }
      }
    }

    &.e-safari .e-tab-header {

      .e-close-icon::before {
        top: $tab-nrml-safari-close-icon-top;
      }

      .e-hor-nav,
      .e-scroll-nav {

        .e-popup-up-icon::before,
        .e-popup-down-icon::before {
          top: 0;
        }

        .e-nav-left-arrow::before,
        .e-nav-right-arrow::before {
          top: 0;
        }
      }
    }

    &.e-disable {
      pointer-events: none;
    }

    &.e-fill {

      .e-tab-header {
        height: $tab-nrml-fill-height;
        min-height: $tab-nrml-fill-height;

        @media screen and (max-width: 480px) {
          height: $tab-mob-fill-height;
          min-height: $tab-mob-fill-height;
        }

        .e-indicator {
          display: none;
        }

        .e-toolbar-items {
          height: auto;
          min-height: $tab-nrml-fill-height;

          @media screen and (max-width: 480px) {
            min-height: $tab-mob-fill-height;
          }

          &:not(.e-tbar-pos) .e-toolbar-item:first-child,
          &:not(.e-tbar-pos) .e-toolbar-item:last-child {
            margin: $tab-nrml-alt-first-item-margin;
            padding: $tab-nrml-background-active-padding;
          }
        }

        .e-toolbar-item {

          &:not(.e-separator) {
            height: $tab-nrml-fill-height;
            margin: $tab-alt-item-margin;
            min-height: $tab-nrml-fill-height;
            padding: $tab-nrml-background-active-padding;

            @media screen and (max-width: 480px) {
              height: $tab-mob-fill-height;
              min-height: $tab-mob-fill-height;
            }

            &.e-itop,
            &.e-ibottom {
              height: $tab-nrml-fill-icon-top-bottom-item-height;
              min-height: $tab-nrml-fill-icon-top-bottom-item-height;
            }
          }

          .e-tab-wrap {
            height: $tab-nrml-fill-height;
            padding: $tab-fill-nrml-wrap-padding;

            @media screen and (max-width: 480px) {
              height: $tab-mob-fill-height;
              padding: $tab-mob-fill-wrap-padding;
            }
          }

          .e-text-wrap {
            height: $tab-nrml-fill-item-textwrap-height;

            @media screen and (max-width: 480px) {
              height: $tab-mob-fill-height;
            }
          }

          &.e-active {
            padding: $tab-nrml-background-active-padding;

            .e-tab-wrap {
              margin-bottom: $tab-fill-nrml-active-item-wrap-margin-bottom;
            }

            .e-text-wrap {
              height: $tab-nrml-fill-item-textwrap-height;

              @media screen and (max-width: 480px) {
                height: $tab-mob-fill-height;
              }
            }

            .e-tab-text {
              font-weight: $tab-light-font-weight;
            }

            &.e-ileft .e-text-wrap,
            &.e-iright .e-text-wrap {
              height: $tab-fill-nrml-active-it-text-container-height;

              @media screen and (max-width: 480px) {
                height: $tab-fill-big-active-it-text-container-height;
              }
            }
          }

          &.e-itop .e-tab-wrap,
          &.e-ibottom .e-tab-wrap {
            height: $tab-nrml-fill-icon-top-bottom-item-height;
          }

          &.e-itop .e-text-wrap,
          &.e-ibottom .e-text-wrap {
            height: auto;
          }
        }

        .e-toolbar-pop {

          .e-toolbar-item.e-itop:not(.e-separator),
          .e-toolbar-item.e-ibottom:not(.e-separator) {
            height: auto;
          }
        }

        &.e-horizontal-bottom {

          .e-toolbar-items:not(.e-tbar-pos) {

            .e-toolbar-item {

              &:first-child.e-active,
              &:last-child.e-active {
                padding: 0;
              }

              &.e-itop,
              &:first-child.e-itop,
              &:last-child.e-itop {
                padding-top: 0;
              }
            }
          }

          .e-toolbar-item {

            &.e-active {
              margin-right: 0;
              padding: 0;

              .e-text-wrap {
                height: $tab-nrml-fill-item-textwrap-height;

                @media screen and (max-width: 480px) {
                  height: $tab-mob-fill-height;
                }
              }
            }

            &.e-itop,
            &.e-ibottom {

              .e-tab-wrap:focus .e-text-wrap {
                height: $tab-nrml-fill-tb-wrap-height;
              }

              .e-text-wrap {
                height: auto;
              }
            }
          }
        }

        &.e-vertical {

          .e-toolbar-items {
            height: inherit;

            .e-toolbar-item {

              .e-tab-wrap {
                padding: $tab-nrml-v-wrap-padding;
              }
            }
          }
        }
      }

      &.e-focused {

        .e-tab-header .e-toolbar-item {

          .e-tab-wrap:focus {
            height: $tab-focus-nrml-fill-height;

            .e-text-wrap {
              height: $tab-nrml-fill-focused-wrap-height;
            }
          }

          &.e-active .e-tab-wrap:focus {

            .e-text-wrap {
              height: $tab-nrml-fill-focused-wrap-height;
            }
          }

          &.e-itop .e-tab-wrap:focus,
          &.e-ibottom .e-tab-wrap:focus {
            height: $tab-nrml-focus-fill-icon-top-bottom-item-height;

            .e-text-wrap {
              height: auto;

              @media screen and (max-width: 480px) {
                height: auto;
              }
            }
          }
        }
      }

      &.e-rtl {

        .e-tab-header .e-toolbar-item:not(.e-separator) {
          margin: $tab-nrml-rtl-alt-item-margin;
        }
      }

      &.e-vertical-icon {

        > .e-tab-header {
          height: $tab-nrml-tb-icon-height - 2;
          min-height: $tab-nrml-tb-icon-height - 2;
        }
      }
    }

    &.e-background {

      .e-tab-header {

        .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
        .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
          margin: $tab-nrml-alt-first-item-margin;
          padding: $tab-nrml-background-active-padding;
        }

        .e-toolbar-item {

          &:not(.e-separator) {
            margin: 0;
            padding: $tab-nrml-background-active-padding;
          }

          .e-tab-wrap {
            padding: $tab-bg-nrml-wrap-padding;

            @media screen and (max-width: 480px) {
              padding: $tab-mob-fill-wrap-padding;
            }
          }

          &.e-active {
            padding: $tab-nrml-background-active-padding;
            @if ($skin-name == 'fluent2') {
              &::before {
                background-color: $tab-background-text-color;
              }
            }

            .e-tab-wrap {
              margin-bottom: $tab-bg-nrml-active-item-wrap-margin-bottom;
            }

            .e-tab-text {
              font-weight: $tab-background-active-text-font-weight;
            }
          }
        }

        &.e-vertical {

          .e-indicator {
            display: $tab-nrml-background-vertical-indicator-display;
          }

          .e-toolbar-items {

            .e-toolbar-item {

              .e-tab-wrap {
                padding: $tab-nrml-v-wrap-padding;
              }
            }
          }
        }

        &.e-horizontal-bottom {

          .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child.e-active,
          .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child.e-active {
            padding: 0;
          }

          .e-toolbar-item.e-active {
            margin: 0;
            padding: 0;
          }
        }

        .e-toolbar-pop {

          .e-toolbar-item {

            &.e-active {

              .e-text-wrap {
                height: $tab-nrml-pop-item-textwrap-height;
              }
            }
          }
        }
      }

      &.e-rtl {

        .e-tab-header .e-toolbar-item {

          &:not(.e-separator) {
            margin: $tab-nrml-rtl-alt-item-margin;
          }
        }
      }
    }

    &.e-rtl {

      .e-tab-header {

        .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
        .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
          margin: $tab-nrml-rtl-item-margin;
        }

        .e-toolbar-item:not(.e-separator) {
          margin: $tab-nrml-rtl-item-margin;

          .e-icon-right {
            margin: $tab-nrml-rtl-icon-right-margin;
          }

          .e-icon-left + .e-tab-text {
            margin: $tab-rtl-nrml-it-text-margin;
          }

          .e-icons.e-close-icon {
            margin: $tab-rtl-nrml-close-icon-margin;
          }

          &.e-itop .e-close-icon,
          &.e-ibottom .e-close-icon {
            left: $tab-rtl-nrml-close-icon-top-bottom-left;
            right: auto;
          }
        }

        &.e-close-show {

          .e-toolbar-item.e-itop .e-text-wrap,
          .e-toolbar-item.e-ibottom .e-text-wrap {
            margin-left: $tab-rtl-nrml-text-container-margin-left;
            margin-right: $tab-rtl-nrml-text-container-margin-right;
          }
        }

        &.e-horizontal-bottom {

          .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:not(.e-separator) {
            margin: $tab-nrml-vertical-rtl-active-margin;
          }
        }

        .e-toolbar-pop {

          .e-toolbar-item {
            margin: 0;

            .e-tab-text {
              padding-left: $tab-rtl-nrml-pop-text-padding-left;
              padding-right: 0;

              @media screen and (max-width: 480px) {
                padding-left: 0;
              }
            }

            .e-icons.e-close-icon {
              margin: $tab-rtl-nrml-pop-close-icon-margin;
            }

            .e-close-icon {
              left: $tab-rtl-pop-close-icon-left;
              right: auto;
            }

            &.e-itop .e-close-icon,
            &.e-ibottom .e-close-icon {
              left: $tab-rtl-big-pop-close-icon-top-bottom-left;
              right: auto;
            }

            &.e-itop .e-tab-text,
            &.e-ibottom .e-tab-text {
              margin: 0;
              padding-left: 10px;
              padding-right: 0;

              @media screen and (max-width: 480px) {
                margin: 0;
                padding-left: 10px;
                padding-right: 0;
              }
            }
          }
        }

        .e-hor-nav,
        .e-scroll-right-nav {
          padding: $tab-rtl-nrml-nav-right-icon-padding;
        }

        .e-scroll-left-nav {
          padding: $tab-rtl-nrml-nav-left-icon-padding;
        }
      }
    }

    &.e-vertical-tab.e-icon-tab {

      .e-tab-header.e-vertical {

        .e-hor-nav .e-popup-up-icon,
        .e-hor-nav .e-popup-down-icon {

          &::before {
            display: none;
          }

          &::after {
            margin: 0;
            width: 100%;
          }
        }
      }
    }
  }

  .e-tab-clone-element {
    overflow: visible;
    z-index: 10000;

    &:not(.e-separator) {
      height: $tab-nrml-height;
      margin: $tab-nrml-item-margin;
      min-height: $tab-nrml-height;
      min-width: auto;
      padding: $tab-nrml-item-padding;

      @media screen and (max-width: 480px) {
        height: $tab-big-height;
        min-height: $tab-big-height;
      }
    }

    .e-tab-wrap {
      height: $tab-nrml-height;
      padding: $tab-nrml-wrap-padding;
      width: 100%;

      @media screen and (max-width: 480px) {
        height: $tab-big-height;
        padding: $tab-mob-wrap-padding;
      }
    }

    .e-text-wrap {
      align-content: center;
      align-items: center;
      display: inline-flex;
      height: $tab-nrml-item-text-wrap-height;

      @media screen and (max-width: 480px) {
        height: $tab-big-height;
      }
    }

    .e-tab-text {
      display: inherit;
      font-family: $tab-font-family;
      font-size: $tab-nrml-font-size;
      font-weight: $tab-font-weight;
      text-transform: $tab-text-transform;
      align-self: auto;

      @media screen and (max-width: 480px) {
        font-size: $tab-big-font-size;
      }
    }

    .e-icons.e-close-icon {
      display: none;
    }

    .e-close-icon,
    .e-close-icon::before {
      font-size: $tab-nrml-close-icon-size;

      @media screen and (max-width: 480px) {
        font-size: $tab-mob-close-icon-size;
      }
    }

    .e-close-icon::before {
      top: 1px;
    }

    .e-icons.e-tab-icon {
      display: inline-flex;
      height: $tab-nrml-icon-container-size;
      min-width: $tab-nrml-icon-container-size;
      width: $tab-nrml-icon-container-size;
      line-height: $tab-nrml-item-tabicon-line-height;

      @media screen and (max-width: 480px) {
        height: $tab-mob-icon-container-size;
        width: $tab-mob-icon-container-size;
      }
    }

    .e-tab-icon,
    .e-tab-icon::before {
      font-size: $tab-nrml-icon-size;

      @media screen and (max-width: 480px) {
        font-size: $tab-mob-icon-size;
      }
    }

    &.e-icon {

      .e-tab-wrap {
        justify-content: center;
        padding: $tab-nrml-io-wrap-padding;

        @media screen and (max-width: 480px) {
          padding: $tab-big-wrap-padding;
        }
      }
    }

    .e-icon-right {
      margin: 0 0 0 8px;

      @media screen and (max-width: 480px) {
        margin: $tab-bgr-mob-item-iconright-margin;
      }
    }

    .e-icon-left + .e-tab-text {
      margin: $tab-nrml-it-text-margin;

      @media screen and (max-width: 480px) {
        margin: $tab-mob-it-text-margin;
      }
    }

    &.e-itop,
    &.e-ibottom {
      height: $tab-nrml-icon-top-bottom-item-height;

      @media screen and (max-width: 480px) {
        height: $tab-nrml-icon-top-bottom-item-height;
      }

      .e-tab-wrap {
        align-items: center;
        display: flex;
        height: $tab-nrml-icon-top-bottom-item-height;
        position: relative;

        @media screen and (max-width: 480px) {
          height: $tab-nrml-icon-top-bottom-item-height;
        }

        &:focus {

          .e-text-wrap {
            height: auto;
          }
        }
      }

      .e-text-wrap {
        display: block;
        height: auto;
      }

      .e-close-icon {
        position: absolute;
        right: $tab-nrml-close-icon-top-bottom-right;
        top: calc(50% - 6px);

        @media screen and (max-width: 480px) {
          right: $tab-mob-close-icon-top-bottom-right;
        }
      }
    }

    &.e-itop .e-tab-text {
      margin: $tab-nrml-icon-top-margin;

      @media screen and (max-width: 480px) {
        margin: $tab-big-icon-top-margin;
      }
    }

    &.e-ibottom .e-tab-text {
      margin: $tab-nrml-icon-bottom-margin;

      @media screen and (max-width: 480px) {
        margin: $tab-big-icon-bottom-margin;
      }
    }

    .e-tab-icon.e-icon-top,
    .e-tab-icon.e-icon-bottom {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto;
    }

    &.e-close-show {

      .e-icons.e-close-icon {
        align-items: center;
        align-self: center;
        display: inline-flex;

        @media screen and (max-width: 480px) {
          display: none;
        }
      }

      .e-toolbar-item.e-itop .e-text-wrap,
      .e-toolbar-item.e-ibottom .e-text-wrap {
        margin-right: $tab-nrml-text-container-margin-right;
      }
    }
  }
}


@include export-module('tab-theme') {
  .e-tab {
    background: $tab-bg-color;
    border: $tab-border;

    .e-tab-header {
      background: inherit;
      border: 0;
      border-radius: 0;
      box-shadow: none;
      font-weight: $tab-header-font-weight;
      border-bottom: $tab-header-border-bottom;

      &::before {
        border-color: $tab-header-before-border-color;
        border-style: solid;
      }

      &:not(.e-vertical) {
        &::before {
          border-width: $tab-header-before-border-width;
        }
      }

      &:not(.e-vertical) {

        .e-toolbar-item.e-active {
          border-bottom: $tab-item-active-border-bottom;
          margin-bottom: 0;
        }
      }

      .e-toolbar-items {
        background: inherit;
      }

      .e-indicator {
        background: $tab-border-color;
      }

      .e-toolbar-item {
        background: inherit;
        border: $tab-item-border;

        &:hover {
          background: $tab-item-hover-bg;
        }

        &.e-active {
          @if ($skin-name == 'Bootstrap5') {
            background: $tab-item-active-bg;
          }
        }

        &.e-active:hover {
          background: $tab-item-active-hover-bg;
        }

        .e-ripple-element {
          background: $tab-ripple-bg-color;
        }

        .e-tab-wrap {
          border-radius: $tab-active-item-border-radius;
          color: $tab-text-color;
          border: $tab-item-wrap-border;

          .e-tab-icon {
            color: $tab-wrap-comb-icon-color;
          }

          &:hover {
            background: $tab-item-hover-bg-color;
            border: $tab-item-wrap-hover-border;
            border-radius: $tab-item-wrap-hover-border-radius;

            @media screen and (max-width: 480px) {
              background: $tab-mob-item-hover-bg-color;
            }

            .e-tab-text,
            .e-tab-icon {
              color: $tab-hover-text-color;
            }
          }
        }

        &.e-active {
          border: $tab-active-item-border;
          border-radius: $tab-top-active-item-border-radius;

          .e-tab-wrap {
            border: $tab-item-active-tabwrap-border;
            &:hover {

              .e-tab-text,
              .e-tab-icon {
                color: $tab-active-text-color;
              }
            }
          }

          .e-text-wrap {
            position: $tab-active-wrap-position;

            &::before {
              border: $tab-active-wrap-before-border;
              content: $tab-active-wrap-before-content;
              display: $tab-active-wrap-before-display;
              position: $tab-active-wrap-before-position;
              width: $tab-active-wrap-before-width;
              @if ($skin-name == 'Material3') {
                border-radius: 3px 3px 0 0;
                bottom: $tab-active-wrap-before-top;
              }
              @else {
                top: $tab-active-wrap-before-top;
              }
            }
          }

          .e-tab-text,
          .e-tab-icon {
            color: $tab-active-text-color;
          }

          &.e-ileft,
          &.e-iright {

            .e-tab-icon {
              color: $tab-comb-icon-color;
            }

            .e-tab-text {
              color: $tab-comb-text-color;
            }
          }

          .e-close-icon {
            color: $tab-active-close-icon-color;
          }
        }

        .e-close-icon {
          color: $tab-close-icon-color;

          &:hover {
            color: $tab-close-icon-hover-color;
          }

          &:active {
            color: $tab-close-icon-active-color;
          }

          @if $skin-name == 'bootstrap5.3' {
            &:focus {
              color: $tab-close-icon-focus-color;
            }
          }
        }

        &.e-disable.e-overlay {
          background: inherit;
          opacity: 1;
          @if $skin-name =='bootstrap5.3' {
            opacity: .65;
          }
          pointer-events: none;

          .e-tab-text,
          .e-tab-icon {
            color: $tab-disable-text-color;
          }
        }
      }

      &.e-reorder-active-item:not(.e-vertical) .e-toolbar-pop .e-toolbar-item.e-active {
        border: $tab-active-item-hide-border;
      }

      .e-toolbar-pop {
        background: $tab-pop-bg-color;
        border: $tab-pop-border;
        border-radius: $tab-pop-border-radius;
        box-shadow: $tab-pop-box-shadow;
        overflow-y: auto;

        &[e-animate = 'true'] {
          overflow-y: hidden;
        }

        @media screen and (max-width: 480px) {
          box-shadow: $tab-pop-box-shadow;
        }

        .e-toolbar-item {

          .e-tab-wrap {

            .e-tab-text,
            .e-tab-icon {
              color: $tab-pop-text-color;
            }

            &:hover {
              background: $tab-hover-bg-color;
              border: $tab-pop-wrap-hover-border;
              border-radius: $tab-pop-wrap-hover-border-radius;

              .e-tab-text,
              .e-tab-icon,
              .e-close-icon {
                color: $tab-pop-wrap-hover-close-color;
                @if $skin-name == 'bootstrap5.3' {
                  color: $tab-alt-active-style-hover-text-color;
                }
              }
            }

            &:active {
              background: $tab-active-bg-color;
              border-color: $tab-pop-wrap-active-border-color;

              .e-tab-text,
              .e-tab-icon {
                color: $tab-pop-wrap-active-icon-font;
              }
            }
          }

          &.e-active .e-tab-wrap {

            .e-tab-text,
            .e-tab-icon {
              color: $tab-active-text-color;
            }
          }

          &.e-disable.e-overlay {
            .e-tab-text,
            .e-tab-icon {
              color: $tab-disable-text-color;
            }
          }
        }
      }

      .e-scroll-nav,
      .e-hscroll:not(.e-scroll-device) .e-scroll-nav {
        background: $tab-scroll-nav-bg;
        border: 0;

        .e-nav-arrow {
          color: $tab-scroll-nav-arrow-font;
          border: $tab-item-active-wrap-border;

          &:hover {
            background: $tab-hover-nav-bg-color;
            color: $tab-hover-nav-icon-color;
            border: $tab-scroll-arrow-hover-border;
          }

          &:active {
            box-shadow: none;
            border: $tab-scroll-arrow-active-border;
          }
        }

        &:focus {
          background: $tab-scroll-nav-bg;

          .e-nav-arrow {
            background: $tab-nav-focus-bg-color;
            color: $tab-nav-focus-icon-color;
            border: $tab-scroll-focus-arrow-border;

            &:hover {
              background: $tab-hover-nav-bg-color;
              color: $tab-hover-nav-icon-color;
            }

            &:active {
              background: $tab-scroll-focus-arrow-active-bg;
              border-color: $tab-scroll-focus-arrow-active-border-color;
              color: $tab-scroll-focus-arrow-active-color;
            }
          }
        }

        &:active {
          box-shadow: none;
          background: $tab-scroll-nav-bg;
          border: $tab-scroll-active-border;
          @if $skin-name == 'bootstrap5.3' or $skin-name == 'fluent2' {
            background: $tab-vertical-scroll-hover-active-bg;
            .e-nav-arrow {
              color: $tab-pop-focus-icon-active-color;
            }
          }
        }

        &.e-overlay {

          .e-nav-arrow {
            color: $tab-scroll-overlay-arrow-color;

            &:hover {
              color: $tab-scroll-overlay-arrow-hover-color;
              background: $tab-scroll-overlay-arrow-hover-bg;
              border-color: $tab-scroll-overlay-arrow-hover-border-color;
            }
          }
        }
      }

      .e-hor-nav,
      .e-hor-nav:not(.e-expended-nav) {
        background: transparent;
        border: 0;

        .e-popup-up-icon,
        .e-popup-down-icon {
          color: $tab-pop-icon-color;
          border: $tab-pop-icon-border;

          &:hover {
            background: $tab-pop-icon-hover-bg-color;
            border: $tab-pop-up-icon-hover-border;
            border-radius: $tab-active-item-border-radius;
          }
        }

        .e-popup-down-icon {

          &:hover {
            color: $tab-hover-nav-icon-color;
          }
        }

        &:focus {
          background: $tab-pop-focus-bg;
          .e-popup-up-icon,
          .e-popup-down-icon {
            background: $tab-nav-focus-bg-color;
            border: $tab-pop-up-icon-focus-border;
            border-radius: $tab-active-item-border-radius;
            color: $tab-nav-focus-icon-color;

            &:hover {
              background: $tab-pop-icon-hover-bg-color;
              border: $tab-pop-up-down-icon-hover-border;
              border-radius: $tab-active-item-border-radius;
            }

            &:active {
              background: $tab-pop-focus-icon-active-bg;
              color: $tab-pop-focus-icon-active-color;
              border: $tab-pop-up-icon-active-border;
              border-radius: $tab-active-item-border-radius;
              box-shadow: $tab-pop-border-box-shadow;
            }
          }
        }

        &:hover {
          border: 0;
          background: $tab-pop-hover-bg;

          &:active {
            background: transparent;
            @if $skin-name == 'bootstrap5.3' {
              background: $tab-pop-focus-bg;
            }
          }
        }

        &:active {
          box-shadow: none;
          background: $tab-pop-focus-bg;
          @if $skin-name == 'bootstrap5.3' or $skin-name == 'fluent2' {
            .e-popup-down-icon,
            .e-popup-up-icon {
              color: $tab-pop-focus-icon-active-color;
            }
          }
        }

        &.e-nav-active {
          background: $tab-pop-nav-active-bg;
          border: $tab-pop-nav-active-border;
          box-shadow: none;
          border-radius: $tab-pop-nav-active-border-radius;

          .e-icons {
            color: $tab-pop-active-icons-color;
          }

          &:focus {
            border: $tab-pop-nav-active-border;
          }
        }
      }

      &.e-horizontal-bottom {
        border-color: $tab-bottom-border-color;
        border-style: solid;
        border-width: $tab-bottom-border-width;

        &[style *= 'overflow: hidden'] {
          border-color: $tab-bottom-border-color;
          border-style: solid;
          border-width: $tab-bottom-border-width;
        }

        &[style *= 'overflow: hidden'] .e-toolbar-items {
          border-color: $tab-bottom-hidden-items-border-color;
          border-style: solid;
          border-width: $tab-bottom-hidden-items-border-width;
        }

        .e-toolbar-item {

          .e-tab-wrap:hover {
            border-radius: $tab-bottom-active-item-border-radius;
          }

          &.e-active {
            border-color: $tab-bottom-active-border-color;
            border-style: solid;
            border-width: $tab-bottom-active-border-width;
            margin-top: $tab-bottom-active-margin-top;
            border-radius: $tab-bottom-active-item-border-radius;
          }
        }
      }

      &.e-vertical {
        border-bottom: 0;

        &::before {
          border-width: $tab-vertical-before-border-width;
        }

        .e-toolbar-item {

          &.e-active {
            border-bottom: $tab-hdr-border;
            border-top: $tab-hdr-border;
          }
        }

        &.e-vertical-left {

          .e-toolbar-item {

            .e-tab-wrap:hover {
              border-radius: $tab-left-active-item-border-radius;
            }

            &.e-active {
              border-radius: $tab-left-active-item-border-radius;
              border-right: $tab-item-active-border-bottom;
            }
          }
        }

        &.e-vertical-right {

          .e-toolbar-item {

            .e-tab-wrap:hover {
              border-radius: $tab-right-active-item-border-radius;
            }

            &.e-active {
              border-left: $tab-item-active-border-bottom;
              border-radius: $tab-right-active-item-border-radius;
            }
          }
        }

        .e-toolbar-pop {
          box-shadow: $tab-v-popup-box-shadow;
        }

        .e-scroll-nav {
          background: inherit;
          border: $tab-vscroll-nav-border;

          &.e-scroll-up-nav {
            border-bottom-color: $tab-vscroll-nav-border-color;
          }

          &.e-scroll-down-nav {
            border-top-color: $tab-vscroll-nav-border-color;
          }

          .e-nav-arrow {
            border: 0;

            &:hover {
              background: none;
            }
          }

          &:hover {
            background: $tab-v-nav-hover-bg-color;
            border-color: $tab-vertical-scroll-hover-border-color;

            &:active:focus {
              background: $tab-vertical-scroll-hover-active-bg;
            }

            .e-nav-arrow {
              color: $tab-hover-nav-icon-color;

              &:hover {
                background: none;
              }
            }
          }

          &:focus {
            background: $tab-v-nav-focus-bg-color;

            &:hover:active {

              .e-nav-arrow {
                color: $tab-vertical-focus-active-arrow-color;
              }
            }

            .e-nav-arrow {
              background: none;

              &:hover {
                background: none;
                border: 0;
              }
            }
          }

          &:active {
            @if $skin-name == 'bootstrap5.3' {
              background: $tab-vertical-scroll-hover-active-bg;
            }
          }
        }

        .e-hor-nav {

          .e-popup-up-icon,
          .e-popup-down-icon {
            border: 0;

            &:hover {
              background: inherit;
              border: 0;
              color: $tab-vertical-pop-icon-hover-color;
            }
          }

          &:focus {

            .e-popup-up-icon,
            .e-popup-down-icon {
              background: inherit;
              border: 0;

              &:hover,
              &:active,
              &:active:hover {
                background: inherit;
                border: 0;
                box-shadow: none;
              }

              &:active {
                color: $tab-vertical-pop-icon-active-color;
              }
            }
          }

          &:hover {

            .e-popup-up-icon,
            .e-popup-down-icon {
              border: 0;
            }
          }
        }
      }
    }

    &.e-focused > .e-tab-header {

      .e-toolbar-item {
        @if ($skin-name == 'Material3') {
          &.e-active .e-tab-wrap:focus {
            background: $tab-active-focus-bg-color;

            .e-tab-icon,
            .e-tab-text {
              color: $tab-active-text-color;
            }
          }
        }

        .e-tab-wrap:focus {
          @if ($skin-name == 'fluent2') {
            box-shadow: $tab-focus-box-shadow;
          }
          background: $tab-focus-bg-color;
          border: $tab-focused-wrap-focus-border;

          .e-tab-icon,
          .e-tab-text {
            color: $tab-focus-text-color;
          }

          .e-tab-icon {
            color: $tab-focused-wrap-focus-icon-color;
          }
        }
      }

      .e-scroll-nav {

        &:focus {

          .e-nav-arrow {
            color: $tab-nav-focus-icon-color;
          }
        }
      }

      .e-hor-nav {

        &:focus {

          .e-popup-up-icon,
          .e-popup-down-icon {
            color: $tab-nav-focus-icon-color;
            background: $tab-scroll-focused-nav-focus-icon-bg;
            border: $tab-scroll-focused-nav-focus-icon-border;
          }
        }
      }

      &.e-vertical {

        .e-scroll-nav {

          &:focus {
            border-color: $tab-vertical-focused-nav-focus-border-color;

            .e-nav-arrow {
              border: 0;
            }
          }
        }

        .e-hor-nav {

          .e-popup-up-icon,
          .e-popup-down-icon {
            border: 0;
          }

          &:focus {
            outline: $tab-v-pop-focus-outline;
          }
        }
      }
    }

    &.e-disable {
      pointer-events: none;

      .e-tab-header {

        .e-indicator {
          background: $tab-disable-border-color;
        }

        .e-toolbar-item {

          .e-tab-wrap {
            color: $tab-disable-text-color;
          }

          &.e-active {

            .e-tab-text,
            .e-tab-icon {
              color: $tab-disable-active-text-color;
            }
          }
        }
      }

      .e-content {
        opacity: $tab-disable-cnt-opacity;
      }
    }

    &.e-fill {

      .e-tab-header {
        border-bottom: $tab-fill-hdr-border;

        .e-toolbar-items.e-hscroll {
          border: 0;
        }

        .e-toolbar-item {
          border: 0;

          .e-ripple-element {
            background: $tab-fill-ripple-bg-color;
          }

          .e-tab-wrap {
            color: $tab-text-color;

            &:hover {
              @if ($skin-name == 'fluent2') {
                background: $tab-fill-wrap-hover-bg;
              }
            }

            &:hover .e-tab-text,
            &:hover .e-tab-icon {
              color: $tab-hover-text-color;
              @if ($skin-name == 'fluent2') {
                color: $tab-fill-wrap-hover-icon-color;
              }
            }

            &:hover .e-tab-icon {
              color: $tab-fill-wrap-hover-icon-color;
            }

            @if ($skin-name == 'fluent2') {
              &:hover .e-close-icon {
                color: $tab-fill-wrap-hover-icon-color;
              }
            }
          }

          .e-text-wrap {
            margin-top: 0;
          }

          &.e-active {
            border: 0;

            &.e-disable.e-overlay {

              .e-tab-text,
              .e-tab-icon {
                color: $tab-fill-background-disable-text-color;
              }
            }

            .e-tab-wrap {
              background: $tab-fill-active-bg-color;
              border-radius: $tab-active-item-border-radius;
              border-color: $tab-fill-active-tabwrap-border-color;

              &:hover .e-tab-text,
              &:hover .e-tab-icon {
                color: $tab-alt-active-text-color;
              }
            }

            .e-text-wrap {
              margin-top: 0;
            }

            .e-text-wrap::before {
              border: $tab-fill-active-text-before-border;
            }

            .e-tab-text,
            .e-tab-icon {
              color: $tab-alt-active-text-color;
            }

            .e-close-icon {
              color: $tab-alt-active-close-icon-color;
            }

            .e-close-icon:hover {
              color: $tab-fill-active-close-hover-color;
            }
          }
        }

        .e-toolbar-pop {

          .e-toolbar-item:active:hover {
            .e-tab-text,
            .e-tab-icon {
              color: $tab-fill-pop-active-icon-color;
            }
          }

          .e-tab-wrap {
            &:hover {
              background: $tab-fill-pop-wrap-hover-bg;

              .e-tab-text {
                color: $tab-fill-pop-wrap-hover-text-color;
              }

              .e-tab-icon {
                color: $tab-fill-pop-wrap-hover-icon-color;
              }

              .e-close-icon {
                color: $tab-fill-pop-wrap-hover-close-color;
              }
            }
          }
        }

        &.e-horizontal-bottom {
          border-bottom: 0;
          border-top: $tab-fill-hdr-border;

          .e-toolbar-item {

            &.e-active .e-text-wrap {
              margin-top: 0;
            }
          }
        }

        &.e-vertical {
          border-bottom: 0;

          &.e-vertical-left {
            border-right: $tab-fill-hdr-border;
          }

          &.e-vertical-right {
            border-left: $tab-fill-hdr-border;
          }
        }
      }

      &.e-corner {

        .e-tab-header {

          .e-toolbar-item {

            &.e-active .e-tab-wrap {
              border-radius: $tab-fill-active-wrap-corner;
            }
          }

          &.e-horizontal-bottom {

            .e-toolbar-item.e-active .e-tab-wrap {
              border-radius: $tab-fill-even-active-wrap-corner;
            }
          }
        }
      }

      &.e-disable {

        .e-tab-header {
          border-bottom: $tab-fill-disable-hdr-border;

          .e-toolbar-item {

            .e-tab-wrap {
              color: $tab-disable-text-color;
            }

            &.e-active {
              opacity: $tab-alt-disable-active-item-opacity;

              .e-tab-wrap,
              .e-tab-wrap:focus {
                background: $tab-fill-disable-active-bg-color;
              }

              .e-tab-wrap .e-tab-text,
              .e-tab-wrap .e-tab-icon,
              .e-tab-wrap:focus .e-tab-text,
              .e-tab-wrap:focus .e-tab-icon {
                color: $tab-alt-disable-active-text-color;
              }
            }
          }

          &.e-horizontal-bottom {
            border-bottom: 0;
            border-top: $tab-fill-disable-hdr-border;
          }
        }
      }

      &.e-focused .e-tab-header {

        .e-toolbar-item {

          .e-tab-wrap:focus .e-tab-icon,
          .e-tab-wrap:focus .e-tab-text,
          .e-tab-wrap:focus .e-close-icon {
            color: $tab-hover-text-color;
          }

          &.e-active .e-tab-wrap:focus {
            background: $tab-fill-focus-bg-color;
            border-color: $tab-fill-focused-active-tabwrap-focus-border-color;

            .e-tab-text {
              color: $tab-fill-focused-active-wrap-focus-text-color;
            }

            .e-close-icon {
              color: $tab-fill-focused-active-wrap-focus-close-color;
            }

            .e-tab-text,
            .e-tab-icon {
              color: $tab-alt-active-style-hover-icon-color;
            }
          }
        }
      }
    }

    &.e-background {

      .e-tab-header {
        background: $tab-alt-hdr-bg-color;
        border: 0;

        .e-toolbar-items {
          background: $tab-alt-item-bg-color;
        }

        .e-toolbar-items.e-hscroll {
          border: 0;
        }

        .e-indicator {
          background: $tab-alt-border-color;
          bottom: $tab-background-indicator-bottom;
        }

        .e-toolbar-item {
          border: 0;

          .e-ripple-element {
            background: $tab-alt-ripple-bg-color;
          }

          .e-tab-wrap {
            background: $tab-alt-item-bg-color;
            border-color: $tab-background-wrap-border-color;

            .e-tab-icon,
            .e-close-icon {
              color: $tab-alt-text-color;
            }

            .e-tab-text {
              color: $tab-background-text-color;
            }

            .e-close-icon:hover {
              color: $tab-background-close-hover-color;
            }

            &:hover {
              background: $tab-bg-item-wrap-hover-bg-color;
              background-color: $tab-background-wrap-hover-bg-color;
              border-color: $tab-background-wrap-hover-border-color;

              .e-tab-text {
                color: $tab-background-wrap-hover-text-color;
              }

              .e-tab-icon {
                color: $tab-background-wrap-hover-icon-color;
              }

              .e-close-icon {
                color: $tab-background-wrap-hover-close-color;

                &:hover,
                &:active {
                  color: $tab-background-close-hover-active-color;
                }
              }
            }
          }

          &.e-active {
            border: 0;
            margin: 0;
            background: $tab-background-active-bg;

            &.e-disable.e-overlay {

              .e-tab-text,
              .e-tab-icon {
                color: $tab-fill-background-disable-text-color;
              }
            }

            .e-tab-wrap {
              background: $tab-background-active-wrap-bg;
              border-color: $tab-background-active-wrap-border-color;
              border-radius: $tab-background-active-wrap-border-radius;
              border-style: solid;
              border-width: $tab-background-active-wrap-border-width;

              .e-tab-text {
                color: $tab-background-active-text-color;
              }

              .e-tab-icon {
                color: $tab-background-active-icon-color;
              }

              .e-close-icon {
                color: $tab-background-active-close-color;
              }

              &:hover {
                background: $tab-background-active-hover-bg;
                border-color: $tab-background-active-hover-border-color;
                .e-tab-text {
                  color: $tab-background-active-hover-text-color;
                }

                .e-tab-icon {
                  color: $tab-background-active-hover-icon-color;
                }

                .e-close-icon {
                  color: $tab-background-active-hover-close-color;

                  &:hover {
                    color: $tab-background-active-close-hover-color;
                  }

                  &:active {
                    color: $tab-background-activeitem-close-active-color;
                  }
                }

                &:focus {
                  background: $tab-background-active-wrap-focus-bg;

                  .e-tab-icon,
                  .e-tab-text {
                    color: $tab-background-active-wrap-focus-text-color;
                  }
                }
              }

              &:focus {
                background: $tab-alt-active-focus-bg-color;

                .e-tab-text,
                .e-tab-icon {
                  color: $tab-background-active-wrap-focus-icon;
                }
              }

              &:focus:hover {

                .e-tab-text {
                  color: $tab-background-active-wrap-focushover-text-color;
                }

                .e-close-icon {
                  color: $tab-background-active-wrap-focushover-close-color;
                }
              }
            }

            .e-text-wrap::before {
              border: $tab-nrml-bottom-wrap-before-top;
              @if ($skin-name == 'Material3') {
                border-radius: 3px 3px 0 0;
              }
            }

            .e-tab-text,
            .e-tab-icon {
              color: $tab-background-active-text-icon-color;
            }

            .e-close-icon {
              color: $tab-background-active-close-icon-color;
            }

            .e-close-icon:hover,
            .e-tab-text:hover {
              color: $tab-background-active-close-text-hover-color;
            }

            &.e-itop .e-tab-wrap,
            &.e-ibottom .e-tab-wrap {
              background: $tab-alt-tb-active-bg-color;
            }
          }

          &.e-disable {
            .e-tab-text,
            .e-tab-icon,
            .e-close-icon {
              color: $tab-alt-disable-text-color;
            }
          }
        }

        .e-toolbar-pop {

          .e-toolbar-item {
            background: $tab-alt-item-bg-color;

            .e-tab-wrap {

              .e-tab-text,
              .e-tab-icon,
              .e-close-icon {
                color: $tab-background-pop-text-color;
              }

              &:hover {
                background: $tab-background-pop-wrap-hover-bg;

                .e-tab-text,
                .e-tab-icon,
                .e-close-icon {
                  color: $tab-background-pop-wrap-hover-text-color;
                }
              }

              &:active {
                .e-tab-text {
                  color: $tab-background-pop-wrap-active-text-color;
                }

                .e-tab-icon {
                  color: $tab-background-pop-wrap-active-icon-color;
                }

                .e-close-icon {
                  color: $tab-background-pop-wrap-active-close-color;
                }
              }
            }
          }
        }

        .e-hscroll:not(.e-scroll-device) .e-scroll-nav,
        .e-scroll-nav {
          background: $tab-background-scroll-bg;

          .e-nav-arrow {
            color: $tab-alt-nav-icon-color;
            border: $tab-background-scroll-arrow-border;

            &:hover {
              background: $tab-background-scroll-arrow-hover-bg;
              color: $tab-bg-hover-nav-icon-color;
              border-color: $tab-background-scroll-arrow-hover-border-color;
            }

            &:active {
              background: $tab-background-scroll-arrow-active-bg;
              color: $tab-background-scroll-arrow-active-color;
            }
          }

          &:focus {

            .e-nav-arrow {
              color: $tab-bg-hover-nav-icon-color;
              border: $tab-background-scroll-focus-arrow-border;
              background: $tab-background-scroll-focus-arrow-bg;

              &:hover {
                background: $tab-bg-hover-nav-bg-color;
                color: $tab-bg-hover-nav-icon-color;
              }

              &:active {
                background: $tab-background-nav-focus-active-bg;
                border-color: $tab-background-nav-focus-active-border-color;
                color: $tab-background-nav-focus-active-color;
              }
            }
          }

          &:active::after {
            @include hscroll-alt-btn-animation-after;
          }
        }

        .e-hor-nav:not(.e-expended-nav),
        .e-hor-nav {
          background: $tab-background-hor-nav-bg;

          .e-popup-up-icon,
          .e-popup-down-icon {
            color: $tab-background-pop-icon-color;
            border: $tab-background-pop-icon-border;

            &:hover {
              background: $tab-background-pop-icon-hover-bg;
              border-color: $tab-background-pop-icon-hover-border-color;
              color: $tab-bg-hover-nav-icon-color;
            }

            &:active {
              &:hover {
                background: $tab-background-pop-active-hover-bg;
                color: $tab-background-pop-active-hover-color;
              }
            }
          }

          &:focus {

            .e-popup-up-icon,
            .e-popup-down-icon {
              color: $tab-bg-hover-nav-icon-color;
              background: $tab-background-focus-pop-bg;
              border: $tab-background-focus-pop-border;

              &:hover {
                background: $tab-background-focus-pop-hover-bg;
              }

              &:active {
                background: $tab-background-popicon-focus-active-bg;
                border-color: $tab-background-popicon-focus-active-border-color;
                color: $tab-background-popicon-focus-active-color;
              }
            }
          }

          &:active::after {
            @include tbar-alt-btn-animation-after;
          }

          &.e-nav-active {
            .e-popup-up-icon {
              color: $tab-bg-hover-nav-icon-color;
            }
          }
        }

        &.e-horizontal-bottom {
          .e-toolbar-item.e-active {
            border: 0;
            margin: 0;

            .e-tab-wrap {
              border-color: $tab-background-bottom-active-wrap-border-color;
              border-radius: $tab-background-bottom-active-wrap-border-radius;
              border-style: solid;
              border-width: $tab-background-bottom-active-wrap-border-width;
            }
          }
        }

        &.e-vertical {

          &.e-vertical-left {
            .e-toolbar-item.e-active {
              border-color: $tab-background-vertical-left-active-border-color;
              border-style: solid;
              border-width: $tab-background-vertical-left-active-border-width;
              margin: 0;
              @if ($skin-name == 'fluent2') {
                &::before {
                  background-color: $tab-background-text-color;
                }
              }

              .e-tab-wrap {
                border-color: $tab-background-bottom-active-wrap-border-color;
                border-radius: $tab-background-vertical-left-active-wrap-border-radius;
                border-style: solid;
                border-width: $tab-background-vertical-left-active-wrap-border-width;
              }
            }
          }

          &.e-vertical-right {
            .e-toolbar-item.e-active {
              border-color: $tab-background-vertical-left-active-border-color;
              border-style: solid;
              border-width: $tab-background-vertical-right-active-border-width;
              margin: 0;

              @if ($skin-name == 'fluent2') {
                &::before {
                  background-color: $tab-background-text-color;
                }
              }

              .e-tab-wrap {
                border-color: $tab-background-bottom-active-wrap-border-color;
                border-radius: $tab-background-vertical-right-active-wrap-border-radius;
                border-style: solid;
                border-width: $tab-background-vertical-right-active-wrap-border-width;
              }
            }
          }

          .e-scroll-nav {

            .e-nav-arrow {
              border: 0;
            }

            &:focus {

              .e-nav-arrow {
                border: 0;

                &:hover {
                  background: none;
                }
              }
            }

            &:hover {

              .e-nav-arrow {
                color: $tab-bg-hover-nav-icon-color;
              }
            }
          }

          .e-hor-nav {

            &:focus {

              .e-popup-up-icon,
              .e-popup-down-icon {
                background: inherit;
                border-color: transparent;

                &:hover {
                  background: inherit;
                  border-color: transparent;
                }
              }
            }

            .e-popup-up-icon,
            .e-popup-down-icon {

              &:hover {
                background: inherit;
                border-color: transparent;
              }
            }
          }
        }
      }

      &.e-disable .e-tab-header {

        .e-indicator {
          background: $tab-alt-disable-border-color;
        }

        .e-toolbar-item {

          .e-tab-wrap {
            color: $tab-alt-disable-text-color;

            .e-tab-text,
            .e-tab-icon {
              color: $tab-alt-disable-text-color;
            }

            &:focus {

              .e-tab-text,
              .e-tab-icon {
                color: $tab-alt-disable-text-color;
              }
            }
          }

          &.e-active {
            opacity: $tab-alt-disable-active-item-opacity;

            .e-tab-wrap,
            .e-tab-wrap:focus {
              background: $tab-alt-disable-active-bg-color;
              color: $tab-alt-disable-active-text-color;

              .e-tab-text,
              .e-tab-icon {
                color: $tab-alt-disable-active-text-color;
              }
            }
          }
        }
      }

      &.e-accent .e-tab-header {

        .e-indicator {
          background: $tab-background-accent-indicator-bg;
        }

        &.e-disable {

          .e-indicator {
            background: $tab-disable-border-color;
          }
        }
      }

      &.e-focused .e-tab-header {

        .e-toolbar-item {

          .e-tab-wrap:focus {
            @if ($skin-name == 'fluent2') {
              box-shadow: $tab-focus-box-shadow;
            }
            background: $tab-background-focused-wrap-focus-bg;
            border-color: $tab-background-focused-wrap-focus-border-color;

            .e-tab-icon,
            .e-tab-text,
            .e-close-icon {
              color: $tab-background-focused-wrap-focus-close-color;
            }
          }

          &.e-active {

            .e-tab-wrap:focus {
              background: $tab-background-focused-active-wrap-focus-bg;
              border-color: $tab-background-focused-active-wrap-focus-border-color;
            }

            .e-tab-wrap:focus .e-tab-text,
            .e-tab-wrap:focus .e-tab-icon {
              color: $tab-background-focused-active-wrap-icon-color;
            }

            .e-tab-wrap:hover {
              background: $tab-background-focused-active-wrap-hover-bg;
              border: $tab-background-focused-active-wrap-hover-border;
              border-radius: $tab-background-focused-active-wrap-hover-border-radius;
            }

            .e-tab-wrap:focus {
              .e-tab-text {
                color: $tab-background-focused-active-wrap-focus-text-color;
              }

              .e-tab-icon {
                color: $tab-background-focused-active-wrap-focus-icon-color;
              }

              .e-close-icon {
                color: $tab-background-focused-active-wrap-focus-close-color;
              }
            }

            .e-tab-wrap:hover {
              .e-tab-text {
                color: $tab-background-focused-active-wrap-hover-text-color;
              }

              .e-tab-icon {
                color: $tab-background-focused-active-wrap-hover-icon-color;
              }

              .e-close-icon {
                color: $tab-background-focused-active-wrap-hover-close-color;
              }
            }

            .e-tab-wrap:focus:hover {
              .e-tab-text {
                color: $tab-background-focused-active-focus-hover-text-color;
              }
              .e-close-icon {
                color: $tab-background-focused-active-focus-hover-close-color;
              }
            }

            &.e-itop .e-tab-wrap,
            &.e-ibottom .e-tab-wrap {
              background: $tab-alt-tb-active-bg-color;

              &:focus {
                background: $tab-key-alt-active-focus-bg-color;
              }
            }
          }
        }

        .e-scroll-nav {

          &:focus {

            .e-nav-arrow {
              color: $tab-bg-nav-focus-icon-color;
            }
          }
        }

        .e-hor-nav {

          &:focus {

            .e-popup-up-icon,
            .e-popup-down-icon {
              color: $tab-bg-nav-focus-icon-color;
            }
          }
        }
      }
    }

    &.e-rtl {

      .e-tab-header {

        .e-hscroll.e-rtl {

          .e-scroll-nav {
            border: 0;
          }
        }
      }
    }

    .e-content {
      background: inherit;

      .e-item {
        background: inherit;
        color: $tab-content-font-color;
        font-size: $tab-content-font-size;
      }
    }
  }

  .e-tab-clone-element {
    background: $tab-clone-item-bg-color;
    color: $tab-clone-text-color;

    .e-tab-wrap {
      border-radius: $tab-active-item-border-radius;
      color: $tab-clone-text-color;
      border: $tab-clone-item-wrap-border;

      .e-tab-icon {
        color: $tab-clone-text-color;
      }
    }
  }
}


@include export-module('tab-material-icons') {
  #{&}.e-tab {

    .e-tab-header {

      .e-close-icon::before {
        content: '\e7fc';
        position: relative;
      }
    }

    &.e-vertical-icon .e-tab-header {

      @media screen and (max-width: 480px) {

        .e-popup-up-icon::before {
          content: '\e82a';
        }

        .e-popup-down-icon::before {
          content: '\e83d';
        }
      }
    }

    &.e-vertical-tab {

      .e-tab-header {

        .e-popup-up-icon {

          &::before {
            content: 'More';
          }

          &::after {
            content: '\e919';
          }
        }

        .e-popup-down-icon {

          &::before {
            content: 'More';
          }

          &::after {
            content: '\e919';
          }
        }
      }

      &.e-icon-tab {

        .e-tab-header {

          .e-popup-up-icon {

            &::before {
              content: '';
            }

            &::after {
              content: '\ebb9';
            }
          }

          .e-popup-down-icon {

            &::before {
              content: '';
            }

            &::after {
              content: '\ebb9';
            }
          }
        }
      }
    }
  }

  .e-tab-clone-element {

    .e-close-icon::before {
      content: '\e7fc';
      position: relative;
    }
  }
}

@mixin tab-mob-last-item-margin {
  @media screen and (max-width: 480px) {
    margin: $tab-mob-last-item-margin;
  }
}
@mixin tab-mob-close-icon-margin {
  @media screen and (max-width: 480px) {
    margin: $tab-mob-close-icon-margin;
  }
}
@include export-module('tab-bigger') {
  .e-bigger .e-tab,
  .e-tab.e-bigger {

    .e-tab-header {
      height: $tab-big-height;
      min-height: $tab-big-height;

      .e-toolbar-items {
        height: auto;
        min-height: auto;

        &:not(.e-tbar-pos) .e-toolbar-item:first-child,
        &:not(.e-tbar-pos) .e-toolbar-item:last-child {
          margin: $tab-big-first-item-margin;

          @media screen and (max-width: 480px) {
            margin: $tab-nrml-first-item-margin;
          }
        }

        &:not(.e-tbar-pos) .e-toolbar-item:last-child {
          padding-bottom: $tab-big-boot-active-item-padding-bottom;
          @include tab-mob-last-item-margin;
        }

        .e-indicator + .e-toolbar-item:last-child.e-active {
          margin: $tab-bgr-active-last-margin;
        }
      }

      .e-toolbar-items.e-hscroll {

        &:not(.e-tbar-pos) .e-toolbar-item:last-child {
          margin: $tab-big-scroll-last-item-margin;
          @include tab-mob-last-item-margin;

          &.e-active {
            margin: $tab-big-active-last-item-margin;
          }
        }

        .e-scroll-nav {
          width: $tab-bgr-hscroll-items-nav-width;
        }
      }

      .e-toolbar-item {

        &:not(.e-separator) {
          height: $tab-big-height;
          margin: $tab-big-item-margin;
          min-height: $tab-big-height;
          min-width: auto;
          padding: $tab-big-item-padding;

          @media screen and (max-width: 480px) {
            margin: $tab-nrml-item-margin;
          }
        }

        &:not(.e-separator).e-itop,
        &:not(.e-separator).e-ibottom {
          height: $tab-big-icon-top-bottom-item-height;
          min-height: $tab-big-icon-top-bottom-item-height;
        }

        .e-tab-wrap {
          height: $tab-big-height;
          padding: $tab-big-wrap-padding;

          @media screen and (max-width: 480px) {
            padding: $tab-mob-wrap-padding;
          }
        }

        .e-text-wrap {
          height: $tab-bgr-item-textwrap-height;
        }

        .e-tab-text {
          font-size: $tab-big-font-size;
          margin-bottom: $tab-bgr-item-text-margin-bottom;
        }

        &.e-ileft:not(.e-icon) .e-tab-icon::before {
          position: relative;
          top: $tab-item-left-icon-before-top;
        }

        &.e-active {
          margin: $tab-big-boot-item-margin;
          padding-bottom: $tab-big-boot-active-item-padding-bottom;

          @media screen and (max-width: 480px) {
            margin: $tab-mob-boot-item-margin;
          }

          &.e-ileft:not(.e-icon) .e-tab-icon::before {
            position: relative;
            top: $tab-item-left-icon-before-top;
          }

          .e-text-wrap {
            height: $tab-bgr-item-textwrap-height;
            margin-top: $tab-active-text-container-margin;
          }

          &.e-ileft .e-text-wrap,
          &.e-iright .e-text-wrap {
            height: $tab-bgr-item-textwrap-height;
          }
        }

        .e-icons.e-close-icon {
          cursor: pointer;
          margin: $tab-big-close-icon-margin;
          @include tab-mob-close-icon-margin;
        }

        .e-icons.e-close-icon,
        .e-close-icon::before {
          font-size: $tab-big-close-icon-size;

          @media screen and (max-width: 480px) {
            font-size: $tab-mob-close-icon-size;
          }
        }

        .e-icons.e-tab-icon {
          height: $tab-big-icon-container-size;
          min-width: $tab-big-icon-container-size;
          width: $tab-big-icon-container-size;
          line-height: $tab-bgr-icon-line-height;
        }

        .e-tab-icon,
        .e-tab-icon::before {
          font-size: $tab-big-icon-size;
        }

        &.e-icon {

          .e-tab-wrap {
            padding: $tab-big-io-wrap-padding;
          }
        }

        .e-icon-right {
          margin: 0 0 0 12px;

          @media screen and (max-width: 480px) {
            margin: $tab-bgr-mob-item-iconright-margin;
          }
        }

        .e-icon-left + .e-tab-text {
          margin: $tab-big-it-text-margin;

          @media screen and (max-width: 480px) {
            margin: $tab-mob-it-text-margin;
          }
        }

        &.e-itop,
        &.e-ibottom {
          height: $tab-big-icon-top-bottom-item-height;

          .e-tab-wrap {
            height: $tab-big-icon-top-bottom-item-height;

            &:focus .e-text-wrap {
              height: auto;
            }
          }

          &.e-active .e-tab-wrap {
            height: $tab-big-icon-top-bottom-item-height;

            &:focus .e-text-wrap {
              height: auto;
            }
          }

          .e-close-icon {
            right: $tab-big-close-icon-top-bottom-right;
          }
        }

        &.e-itop .e-tab-text {
          margin: $tab-big-icon-top-margin;
        }

        &.e-ibottom .e-tab-text {
          margin: $tab-big-icon-bottom-margin;
        }
      }

      &.e-close-show {

        .e-toolbar-item.e-itop .e-text-wrap,
        .e-toolbar-item.e-ibottom .e-text-wrap {
          margin-right: $tab-big-text-container-margin-right;
        }
      }

      .e-toolbar-pop {

        .e-toolbar-item {
          height: $tab-big-pop-item-height;
          min-height: $tab-big-pop-item-height;

          @if ($skin-name == 'fluent2') {
            &:not(.e-separator) {
              margin: 1px 6px;
            }
          }

          .e-tab-wrap {
            height: $tab-big-pop-item-height;
            padding: $tab-big-pop-item-wrap-padding;

            @media screen and (max-width: 480px) {
              padding: $tab-mob-pop-item-wrap-padding;
            }
          }

          .e-text-wrap {
            height: $tab-bgr-pop-item-textwrap-height;
          }

          .e-tab-text + .e-close-icon[style = 'display:block'] {
            padding-right: $tab-big-pop-text-padding-right;

            @media screen and (max-width: 480px) {
              padding-right: 0;
            }
          }

          .e-close-icon {
            margin: 0 0 0 8px;
          }

          .e-close-icon::before {
            top: $tab-big-pop-close-top;
          }

          &.e-itop,
          &.e-ibottom {

            .e-close-icon {
              right: $tab-big-pop-close-icon-top-bottom-right;
            }
          }

          &.e-itop:not(.e-separator),
          &.e-ibottom:not(.e-separator) {
            min-height: $tab-pop-big-icon-top-bottom-item-height;

            @media screen and (max-width: 480px) {
              min-height: $tab-pop-big-icon-top-bottom-item-height;
            }
          }

          &.e-itop,
          &.e-itop .e-tab-wrap,
          &.e-ibottom,
          &.e-ibottom .e-tab-wrap {
            height: $tab-pop-big-icon-top-bottom-item-height;

            @media screen and (max-width: 480px) {
              height: $tab-pop-big-icon-top-bottom-item-height;
            }
          }

          &.e-itop .e-tab-text,
          &.e-ibottom .e-tab-text {
            margin: 0;
            padding-left: 0;
            padding-right: 12px;

            @media screen and (max-width: 480px) {
              margin: 0;
              padding-left: 0;
              padding-right: 0;
            }
          }
        }
      }

      .e-scroll-nav {
        height: $tab-big-height;
        min-height: $tab-big-height;
        padding: $tab-big-nav-right-icon-padding;

        @media screen and (max-width: 480px) {
          padding: $tab-mob-nav-right-icon-padding;
        }

        &.e-scroll-right-nav {
          padding: $tab-big-nav-right-icon-padding;
        }

        &.e-scroll-left-nav {
          padding: $tab-big-nav-left-icon-padding;
        }

        .e-nav-arrow {
          font-size: $tab-big-nav-icon-size;
          height: $tab-big-nav-icon-container-size;
          line-height: $tab-big-nav-icon-container-size;
          width: $tab-big-nav-icon-container-size;

          @media screen and (max-width: 480px) {
            font-size: $tab-big-nav-icon-size;
            height: $tab-big-nav-icon-container-size;
            line-height: $tab-big-nav-icon-container-size;
            width: $tab-big-nav-icon-container-size;
          }
        }

        .e-nav-left-arrow,
        .e-nav-right-arrow {

          &::before {
            font-size: $tab-big-nav-icon-size;
            line-height: $tab-big-nav-icon-container-size;
            top: $tab-bgr-scroll-nav-arrow-before-top;
            vertical-align: initial;
          }
        }
      }

      &.e-vertical {
        .e-hor-nav {
          .e-popup-up-icon,
          .e-popup-down-icon {
            height: 100%;
            width: 100%;
          }
        }
      }

      .e-hor-nav {
        height: $tab-big-height;
        min-height: $tab-big-height;
        padding: $tab-big-nav-right-icon-padding;

        @media screen and (max-width: 480px) {
          padding: $tab-mob-nav-right-icon-padding;
        }

        .e-popup-up-icon,
        .e-popup-down-icon {
          font-size: $tab-big-nav-icon-size;
          height: $tab-big-nav-icon-container-size;
          line-height: $tab-big-nav-icon-container-size;
          width: $tab-big-nav-icon-container-size;

          @media screen and (max-width: 480px) {
            font-size: $tab-big-nav-icon-size;
            height: $tab-big-nav-icon-container-size;
            line-height: $tab-big-nav-icon-container-size;
            width: $tab-big-nav-icon-container-size;
          }

          &::before {
            font-size: $tab-big-nav-icon-size;
            line-height: $tab-big-nav-icon-container-size;
            top: 0;
            vertical-align: initial;

            @media screen and (max-width: 480px) {
              font-size: $tab-mob-nav-icon-size;
            }
          }

          &:hover {
            line-height: $tab-bgr-hor-pop-lineheight;

            &::before {
              line-height: $tab-bgr-hor-pop-lineheight;
              top: 0;
            }
          }
        }

        &:focus {

          .e-popup-up-icon,
          .e-popup-down-icon {
            line-height: $tab-bgr-hor-pop-lineheight;

            &::before {
              line-height: $tab-bgr-hor-pop-lineheight;
              top: 0;
            }

            &:hover {
              line-height: $tab-bgr-hor-pop-lineheight;
              top: $tab-bgr-hor-focus-pop-hover-top;
            }
          }
        }
      }

      .e-hor-nav.e-ie-align,
      .e-scroll-nav.e-ie-align {

        .e-popup-up-icon,
        .e-popup-down-icon {
          line-height: $tab-big-ie-pop-icon-line-height;
        }

        .e-nav-left-arrow,
        .e-nav-right-arrow {
          line-height: $tab-big-ie-nav-icon-line-height;
        }
      }

      &.e-horizontal-bottom {

        .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child.e-active {
          margin: $tab-big-even-last-item-margin;
        }

        .e-toolbar-item {

          &.e-active {
            margin: $tab-big-even-boot-item-margin;
            padding-bottom: 0;

            @media screen and (max-width: 480px) {
              margin: $tab-mob-even-boot-item-margin;
            }

            .e-tab-text {
              padding-top: $tab-big-even-boot-active-item-padding-bottom;
            }

            .e-close-icon::before {
              top: $tab-bgr-bottom-active-close-top;
            }

            &.e-itop .e-close-icon::before,
            &.e-ibottom .e-close-icon::before {
              top: $tab-bgr-bottom-active-itop-close-top;
            }
          }

          &.e-active:last-child {
            margin: $tab-big-even-last-item-margin;
            padding-bottom: 0;

            @media screen and (max-width: 480px) {
              margin: $tab-mob-even-last-item-margin;
            }

            .e-tab-text {
              padding-top: $tab-bgr-bottom-active-text-padding-top;
            }

            .e-close-icon::before {
              top: $tab-bgr-bottom-active-last-close-top;
            }
          }

          &.e-itop,
          &.e-ibottom {
            padding-bottom: 0;
            padding-top: 0;
          }

          &.e-ileft.e-active .e-text-wrap {
            margin: $tab-big-even-ileft-active-text-wrap-margin;
          }

          &.e-active .e-text-wrap {
            height: $tab-big-even-active-text-container-height;
            padding: 0;
          }

          &.e-itop.e-active .e-text-wrap,
          &.e-ibottom.e-active .e-text-wrap {
            height: initial;
          }

          &.e-ileft.e-active {
            height: $tab-big-even-active-text-container-height;
          }
        }
      }

      &.e-vertical {
        max-width: 150px;

        &[style *= 'overflow: hidden']::before {
          bottom: $tab-big-vertical-scroll-nav-top-bottom;
          top: $tab-big-vertical-scroll-nav-top-bottom;

          @media screen and (max-width: 480px) {
            bottom: 0;
            top: 0;
          }
        }

        .e-toolbar-items {
          height: inherit;

          &:not(.e-tbar-pos) {

            .e-toolbar-item:last-child {
              margin: 0;
            }
          }
        }

        .e-toolbar-items.e-vscroll:not(.e-scroll-device) {
          padding: $tab-vscroll-big-padding;
        }

        .e-toolbar-item {

          .e-tab-wrap {
            padding: $tab-big-v-wrap-padding;
          }

          &.e-itop,
          &.e-ibottom {

            .e-close-icon {
              right: $tab-v-big-close-icon-top-bottom-right;
            }
          }

          &.e-ileft.e-icon {
            min-height: $tab-bgr-vertical-icon-min-height;
            min-width: $tab-bgr-vertical-icon-min-width;
          }
        }

        .e-scroll-nav {
          height: $tab-big-vertical-nav-arrow-size;
          min-height: $tab-big-vertical-nav-arrow-size;

          .e-nav-arrow {
            font-size: $tab-big-vertical-nav-arrow-font-size;
            height: $tab-big-vertical-nav-arrow-size;
            line-height: $tab-big-vertical-nav-arrow-size;
            width: $tab-big-vertical-nav-arrow-size;
          }
        }

        .e-hor-nav {
          padding: $tab-big-wrap-padding;

          .e-popup-up-icon,
          .e-popup-down-icon {

            &::before {
              line-height: $tab-big-more-btn-line-height;
              @if $skin-name == 'Material3' {
                font-size: $tab-big-font-size;
              }
            }

            &::after {
              font-size: $tab-big-font-size;
              @if $skin-name == 'Material3' {
                font-size: $tab-big-nav-icon-size;
              }
              line-height: $tab-big-more-btn-line-height;
              margin: $tab-pop-more-icon-margin;
            }
          }
        }

        &.e-toolpop {

          .e-toolbar-items {
            height: auto;
          }
        }
      }
    }

    &.e-vertical-icon {

      > .e-tab-header {
        height: $tab-big-tb-icon-height;
        min-height: $tab-big-tb-icon-height;

        > .e-toolbar-items {
          height: $tab-big-tb-icon-height;
        }

        .e-scroll-nav {
          height: $tab-big-icon-top-bottom-item-height;
        }

        .e-hor-nav {
          height: $tab-big-icon-top-bottom-item-height;

          .e-popup-up-icon::before,
          .e-popup-down-icon::before {

            @media screen and (max-width: 480px) {
              font-size: $tab-mob-nav-vicon-size;
            }
          }
        }
      }

      &.e-vertical-tab {

        .e-tab-header.e-vertical {

          .e-toolbar-items {
            height: inherit;
          }

          .e-scroll-nav {
            height: $tab-big-vertical-nav-arrow-size;
          }
        }
      }
    }

    &.e-focused {

      .e-tab-header {

        .e-toolbar-item {

          .e-tab-wrap:focus {
            height: $tab-big-height;
          }

          .e-tab-wrap:focus .e-text-wrap {
            height: $tab-bgr-item-textwrap-height;
          }

          &.e-itop,
          &.e-ibottom {

            .e-tab-wrap:focus {
              height: $tab-big-focus-icon-top-bottom-item-height;

              .e-text-wrap {
                height: auto;
              }
            }

            &.e-active .e-tab-wrap:focus .e-text-wrap {
              height: auto;
            }
          }
        }

        .e-toolbar-pop {

          .e-toolbar-item {

            .e-tab-wrap:focus {
              height: $tab-big-pop-item-height;

              .e-text-wrap {
                height: $tab-bgr-pop-item-textwrap-height;
              }
            }

            &.e-itop .e-tab-wrap:focus,
            &.e-ibottom .e-tab-wrap:focus {
              height: $tab-big-height;
            }
          }
        }

        &.e-horizontal-bottom {

          .e-toolbar-item {

            &.e-active {

              .e-tab-wrap:focus .e-text-wrap {
                height: $tab-bgr-item-textwrap-height;
                padding: 0;
              }

              &.e-ileft {
                height: $tab-big-even-active-text-container-height;
              }
            }
          }
        }
      }
    }

    &.e-ie .e-tab-header {

      .e-hor-nav.e-ie-align,
      .e-scroll-nav.e-ie-align {

        .e-popup-up-icon,
        .e-popup-down-icon {
          line-height: $tab-big-ie-pop-icon-line-height;
        }

        .e-nav-left-arrow,
        .e-nav-right-arrow {
          line-height: $tab-big-ie-nav-icon-line-height;
        }
      }
    }

    &.e-edge .e-tab-header {

      .e-hor-nav.e-ie-align,
      .e-scroll-nav.e-ie-align {

        .e-popup-up-icon,
        .e-popup-down-icon {
          line-height: $tab-big-edge-pop-icon-line-height;
        }

        .e-nav-left-arrow,
        .e-nav-right-arrow {
          line-height: $tab-big-edge-nav-icon-line-height;
        }
      }
    }

    &.e-safari .e-tab-header {

      .e-close-icon::before {
        top: $tab-big-safari-close-icon-top;
      }

      .e-hor-nav,
      .e-scroll-nav {

        .e-popup-up-icon::before,
        .e-popup-down-icon::before {
          top: 0;
        }

        .e-nav-left-arrow::before,
        .e-nav-right-arrow::before {
          top: 0;
        }
      }
    }

    &.e-rtl {

      .e-tab-header {

        &.e-horizontal-bottom {

          .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:not(.e-separator) {
            margin: $tab-bgr-rtl-bottom-item-margin;

            &.e-active {
              margin: $tab-bgr-rtl-bottom-active-margin;
            }
          }
        }
      }
    }

    &.e-fill {

      .e-tab-header {
        height: $tab-big-fill-height;
        min-height: $tab-big-fill-height;

        @media screen and (max-width: 480px) {
          height: $tab-mob-fill-height;
          min-height: $tab-mob-fill-height;
        }

        .e-toolbar-items {
          height: auto;
          min-height: $tab-big-fill-height;

          @media screen and (max-width: 480px) {
            min-height: $tab-mob-fill-height;
          }
        }

        .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
        .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
          margin: $tab-big-alt-first-item-margin;
          padding: $tab-bgr-fill-active-item-padding;
        }

        .e-toolbar-item {

          &:not(.e-separator) {
            height: $tab-big-fill-height;
            margin: $tab-alt-item-margin;
            min-height: $tab-big-fill-height;
            padding: $tab-bgr-fill-active-item-padding;

            @media screen and (max-width: 480px) {
              height: $tab-mob-fill-height;
              min-height: $tab-mob-fill-height;
            }
          }

          &:not(.e-separator).e-itop,
          &:not(.e-separator).e-ibottom {
            height: $tab-big-fill-icon-top-bottom-item-height;
            min-height: $tab-big-fill-icon-top-bottom-item-height;
          }

          .e-tab-wrap {
            height: $tab-big-fill-height;
            padding: $tab-fill-big-wrap-padding;

            @media screen and (max-width: 480px) {
              height: $tab-mob-fill-height;
              padding: $tab-fill-big-wrap-padding;
            }
          }

          .e-text-wrap {
            height: $tab-bgr-fill-item-textwrap-height;

            @media screen and (max-width: 480px) {
              height: $tab-mob-fill-height;
            }
          }

          &.e-active {
            padding: $tab-bgr-fill-active-item-padding;

            .e-tab-wrap {
              margin-bottom: $tab-fill-big-active-item-wrap-margin-bottom;
            }

            .e-text-wrap {
              height: $tab-bgr-fill-item-textwrap-height;

              @media screen and (max-width: 480px) {
                height: $tab-mob-fill-height;
              }
            }

            &.e-ileft .e-text-wrap,
            &.e-iright .e-text-wrap {
              height: $tab-bgr-fill-active-ileft-textwrap-height;

              @media screen and (max-width: 480px) {
                height: $tab-fill-big-active-it-text-container-height;
              }
            }
          }

          &.e-itop .e-tab-wrap,
          &.e-ibottom .e-tab-wrap {
            height: $tab-big-fill-icon-top-bottom-item-height;
          }
        }

        .e-toolbar-pop {

          .e-toolbar-item.e-itop:not(.e-separator),
          .e-toolbar-item.e-ibottom:not(.e-separator) {
            height: auto;
          }
        }

        &.e-vertical {

          .e-toolbar-items {

            .e-toolbar-item {

              .e-tab-wrap {
                padding: $tab-big-v-wrap-padding;
              }
            }
          }
        }

        &.e-horizontal-bottom {

          .e-toolbar-item.e-active .e-text-wrap {
            height: $tab-fill-big-even-active-text-height;
          }

          .e-toolbar-item.e-itop .e-text-wrap,
          .e-toolbar-item.e-ibottom .e-text-wrap {
            height: auto;
          }

          .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap,
          .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap {
            height: $tab-big-fill-tb-wrap-height;
          }
        }
      }

      &.e-focused {

        .e-tab-header {

          .e-toolbar-item {

            .e-tab-wrap:focus {
              height: $tab-focus-big-fill-height;
            }

            .e-tab-wrap:focus .e-text-wrap {
              height: $tab-bgr-fill-focused-wrap-height;
            }

            &.e-active .e-tab-wrap:focus {

              .e-text-wrap {
                height: $tab-bgr-fill-focused-wrap-height;
              }
            }

            &.e-itop .e-tab-wrap:focus,
            &.e-ibottom .e-tab-wrap:focus {
              height: $tab-big-focus-fill-icon-top-bottom-item-height;

              .e-text-wrap {
                height: auto;

                @media screen and (max-width: 480px) {
                  height: auto;
                }
              }
            }
          }
        }
      }

      &.e-rtl .e-tab-header {

        .e-toolbar-item:not(.e-separator) {
          margin: $tab-big-rtl-alt-item-margin;
        }
      }

      &.e-vertical-icon {

        > .e-tab-header {
          height: $tab-big-tb-icon-height - 2;
          min-height: $tab-big-tb-icon-height - 2;
        }
      }
    }

    &.e-background {

      .e-tab-header {

        .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
        .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
          margin: $tab-big-alt-first-item-margin;
          padding: $tab-bgr-background-active-padding;
        }

        .e-toolbar-item {

          &:not(.e-separator) {
            padding: $tab-bgr-background-active-padding;
          }

          .e-tab-wrap {
            padding: $tab-bg-big-wrap-padding;

            @media screen and (max-width: 480px) {
              padding: $tab-bg-big-wrap-padding;
            }
          }

          &.e-active {
            padding: $tab-bgr-background-active-padding;

            .e-tab-wrap {
              margin-bottom: $tab-bg-big-active-item-wrap-margin-bottom;
            }
          }
        }

        &.e-vertical {

          .e-toolbar-items {

            .e-toolbar-item {

              .e-tab-wrap {
                padding: $tab-big-v-wrap-padding;
              }
            }
          }
        }
      }

      &.e-rtl .e-tab-header {

        .e-toolbar-item:not(.e-separator) {
          margin: $tab-big-rtl-alt-item-margin;
        }
      }
    }

    &.e-rtl {

      .e-tab-header {

        .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
        .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
          margin: $tab-big-rtl-item-margin;
        }

        .e-toolbar-item:not(.e-separator) {
          margin: $tab-big-rtl-item-margin;

          .e-icon-right {
            margin: $tab-bgr-rtl-icon-right-margin;
          }

          .e-icon-left + .e-tab-text {
            margin: $tab-rtl-big-it-text-margin;

            @media screen and (max-width: 480px) {
              margin: $tab-rtl-mob-it-text-margin;
            }
          }

          .e-icons.e-close-icon {
            margin: $tab-rtl-big-close-icon-margin;

            @media screen and (max-width: 480px) {
              margin: $tab-rtl-mob-close-icon-margin;
            }
          }

          &.e-itop .e-close-icon,
          &.e-ibottom .e-close-icon {
            left: $tab-rtl-big-close-icon-top-bottom-left;
            right: auto;
          }
        }

        &.e-close-show {

          .e-toolbar-item.e-itop .e-text-wrap,
          .e-toolbar-item.e-ibottom .e-text-wrap {
            margin-left: $tab-rtl-big-text-container-margin-left;
            margin-right: $tab-rtl-big-text-container-margin-right;
          }
        }

        .e-toolbar-pop {

          .e-toolbar-item {
            margin: 0;

            .e-tab-text {
              padding-left: $tab-rtl-big-pop-text-padding-left;
              padding-right: 0;

              @media screen and (max-width: 480px) {
                padding-left: 0;
              }
            }

            .e-icons.e-close-icon {
              margin: $tab-rtl-big-pop-close-icon-margin;
            }

            &.e-itop,
            &.e-ibottom {

              .e-close-icon {
                left: $tab-rtl-big-pop-close-icon-top-bottom-left;
                right: auto;
              }
            }

            &.e-itop .e-tab-text,
            &.e-ibottom .e-tab-text {
              margin: 0;
              padding-left: 12px;
              padding-right: 0;

              @media screen and (max-width: 480px) {
                margin: 0;
                padding-left: 0;
                padding-right: 0;
              }
            }
          }
        }

        .e-hor-nav,
        .e-scroll-right-nav {
          padding: $tab-rtl-big-nav-right-icon-padding;
        }

        .e-scroll-left-nav {
          padding: $tab-rtl-big-nav-left-icon-padding;
        }

        &.e-vertical {

          .e-toolbar-item {

            &:not(.e-separator) {
              margin: 0;
            }

            &.e-itop,
            &.e-ibottom {

              .e-close-icon {
                left: $tab-v-rtl-big-close-icon-top-bottom-left;
              }
            }
          }

          .e-hor-nav {
            padding: $tab-big-wrap-padding;
          }

          .e-popup-up-icon,
          .e-popup-down-icon {

            &::after {
              margin: $tab-rtl-pop-more-icon-margin;
            }
          }
        }
      }
    }
  }

  .e-bigger .e-tab-clone-element {

    &:not(.e-separator) {
      height: $tab-big-height;
      margin: $tab-big-item-margin;
      min-height: $tab-big-height;
      min-width: auto;
      padding: $tab-big-item-padding;

      @media screen and (max-width: 480px) {
        margin: $tab-nrml-item-margin;
      }
    }

    &:not(.e-separator).e-itop,
    &:not(.e-separator).e-ibottom {
      height: $tab-big-icon-top-bottom-item-height;
      min-height: $tab-big-icon-top-bottom-item-height;
    }

    .e-tab-wrap {
      height: $tab-big-height;
      padding: $tab-big-wrap-padding;

      @media screen and (max-width: 480px) {
        padding: $tab-mob-wrap-padding;
      }
    }

    .e-text-wrap {
      height: $tab-bgr-item-textwrap-height;
    }

    .e-tab-text {
      font-size: $tab-big-font-size;
      align-self: auto;
    }

    .e-icons.e-close-icon {
      cursor: pointer;
      margin: $tab-big-close-icon-margin;
      @include tab-mob-close-icon-margin;
    }

    .e-icons.e-close-icon,
    .e-close-icon::before {
      font-size: $tab-big-close-icon-size;

      @media screen and (max-width: 480px) {
        font-size: $tab-mob-close-icon-size;
      }
    }

    .e-icons.e-tab-icon {
      height: $tab-big-icon-container-size;
      min-width: $tab-big-icon-container-size;
      width: $tab-big-icon-container-size;
      line-height: $tab-bgr-icon-line-height;
    }

    .e-tab-icon,
    .e-tab-icon::before {
      font-size: $tab-big-icon-size;
    }

    &.e-icon {

      .e-tab-wrap {
        padding: $tab-big-io-wrap-padding;
      }
    }

    .e-icon-right {
      margin: 0 0 0 12px;

      @media screen and (max-width: 480px) {
        margin: $tab-bgr-mob-item-iconright-margin;
      }
    }

    .e-icon-left + .e-tab-text {
      margin: $tab-big-it-text-margin;

      @media screen and (max-width: 480px) {
        margin: $tab-mob-it-text-margin;
      }
    }

    &.e-itop,
    &.e-ibottom {
      height: $tab-big-icon-top-bottom-item-height;

      .e-tab-wrap {
        height: $tab-big-icon-top-bottom-item-height;

        &:focus .e-text-wrap {
          height: auto;
        }
      }

      &.e-active .e-tab-wrap {
        height: $tab-big-icon-top-bottom-item-height;

        &:focus .e-text-wrap {
          height: auto;
        }
      }

      .e-close-icon {
        right: $tab-big-close-icon-top-bottom-right;
      }
    }

    &.e-itop .e-tab-text {
      margin: $tab-big-icon-top-margin;
    }

    &.e-ibottom .e-tab-text {
      margin: $tab-big-icon-bottom-margin;
    }
  }
}

@import "../base/base.gd.scss";
@import "../inputs/input.gd.scss";
@import "../popups/popup.gd.scss";

$timepicker-skin-name: 'material' !default;
$timepicker-default-text-indent: 16px !default;
$timepicker-list-bigger-line-height: 48px !default;
$timepicker-list-normal-line-height: 36px !default;
$timepicker-list-normal-font-size: 13px !default;
$timepicker-list-bigger-font-size: 14px !default;
$timepicker-list-normal-text-indent: $timepicker-default-text-indent !default;
$timepicker-list-bigger-text-indent: 16px !default;
$timepicker-popup-border-radius: 2px !default;
$timepicker-list-font-weight: normal !default;
$timepicker-popup-shadow: 0 5px 5px -3px rgba($grey-light-font, .2), 0 8px 10px 1px rgba($grey-light-font, .14), 0 3px 14px 2px rgba($grey-light-font, .12) !default;
$timepicker-list-default-font-color: rgba($grey-light-font, .87) !default;
$timepicker-list-default-border-style: none !default;
$timepicker-list-border-color: none !default;
$timepicker-list-hover-border-color: none !default;
$timepicker-list-bg-color: $grey-white !default;
$timepicker-list-active-bg-color: $grey-200 !default;
$timepicker-list-active-font-color: $accent !default;
$timepicker-list-active-icon-color: $accent !default;
$timepicker-list-hover-bg-color: $grey-200 !default;
$timepicker-list-hover-font-color: $grey-light-font !default;
$timepicker-list-popup-icon-active-color: $accent !default;
$timepicker-list-active-hover-bg-color: $grey-200 !default;
$timepicker-list-active-hover-font-color: $accent !default;
$timepicker-list-normal-padding: 0 !default;
$timepicker-list-bigger-padding: 0 !default;
$timepicker-font-icon: '\e20c' !default;
$timepicker-icon-normal-font-size: 16px !default;
$timepicker-icon-bigger-font-size: 18px !default;
$timepicker-normal-input-min-height: 20px !default;
$timepicker-normal-input-min-width: 20px !default;
$timepicker-bigger-input-min-height: 22px !default;
$timepicker-bigger-input-min-width: 22px !default;
$timepicker-disable-text: rgba($grey-light-font, .38) !default;
$timepicker-disable-opacity: 1 !default;
$timepicker-default-overlay: rgba($grey-black, .6) !default;

// mouse small
$timepicker-list-small-font-size: 12px !default;
$timepicker-list-small-line-height: 26px !default;
$timepicker-list-small-text-indent: 12px !default;
$timepicker-list-small-font-color: $grey-light-font !default;

// mouse small icon
$timepicker-icon-small-font-size: 14px !default;

// Touch small
$timepicker-list-bigger-small-font-size: 13px !default;
$timepicker-list-bigger-small-line-height: 40px !default;
$timepicker-list-bigger-small-text-indent: 16px !default;

// Touch small icon
$timepicker-icon-bigger-small-font-size: 18px !default;

// modal dialog colors
$modal-header-bg-color: $primary !default;
$modal-header-text-color: $grey-white !default;

// modal dialog portrait dimensions
$modal-header-height: 10% !default;
$modal-header-padding: 2.5vh 2.5vw 2.5vh 1.5vw !default;
$modal-header-display-style: flex !default;
$modal-header-content-align: center !default;
$modal-header-portrait-font-size: 2vh !default;
$modal-close-icon-float: left !default;
$modal-portrait-content-padding: 1vh 2vw !default;
$modal-content-height: 90% !default;
$modal-content-overflow: auto !default;
$modal-header-title-transform: uppercase !default;
$modal-header-border-bottom: none !default;
$modal-list-line-height: 5vh !default;
$modal-landscape-list-line-height: 10vh !default;

// modal dialog landscape dimensions
$modal-header-landscape-height: 15% !default;
$modal-content-landscape-height: 85% !default;
$modal-header-landscape-font-size: 2vw !default;
$modal-landscape-padding: 1vh 1vw !default;
$modal-list-item-padding: 1vh 0 !default;
$modal-mobile-font-size: 14px !default;
$modal-tablet-font-size: 24px !default;

@include export-module('timepicker-layout') {
  //   timepicker layout
  .e-input-group.e-control-wrapper.e-time-wrapper.e-non-edit.e-input-focus .e-input:focus ~ .e-clear-icon,
  .e-float-input.e-control-wrapper.e-input-group.e-time-wrapper.e-non-edit.e-input-focus input:focus ~ .e-clear-icon {
    display: flex;
  }

  .e-time-wrapper,
  #{if(&, '&', '*')}.e-control-wrapper.e-time-wrapper {
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-tap-highlight-color: transparent;

    #{if(&, '&', '*')} .e-time-icon.e-icons {
      font-size: $timepicker-icon-normal-font-size;
      @if $timepicker-skin-name == 'material' or $timepicker-skin-name == 'material-dark' or $timepicker-skin-name == 'Material3' {
        min-height: $timepicker-normal-input-min-height;
        min-width: $timepicker-normal-input-min-width;
      }
      @if $timepicker-skin-name == 'Material3' {
        border-radius: $timepicker-normal-icon-border-radius;
        margin: $timepicker-icon-margin;
      }
    }

    #{if(&, '&', '*')} .e-time-icon.e-icons.e-disabled {
      pointer-events: none;
    }

    #{if(&, '&', '*')} span {
      cursor: pointer;
    }
  }

  #{&}.e-timepicker.e-time-modal {
    @if $timepicker-skin-name != 'Material3' {
      background-color: $timepicker-default-overlay;
    }
    @if $timepicker-skin-name == 'Material3' {
      background: $timepicker-default-overlay;
    }
    height: 100%;
    left: 0;
    opacity: .5;
    pointer-events: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
  }

  #{&}.e-timepicker.e-popup {
    border-style: solid;
    border-width: 1px;
    overflow: auto;

    #{if(&, '&', '*')} .e-content {
      position: relative;
    }

    #{if(&, '&', '*')} .e-list-parent.e-ul {
      margin: 0;
      padding: $timepicker-list-normal-padding 0;

      #{if(&, '&', '*')} .e-list-item {
        cursor: default;
        font-size: $timepicker-list-normal-font-size;
        overflow: hidden;
        position: relative;
        text-overflow: ellipsis;
        vertical-align: middle;
        white-space: nowrap;
        width: 100%;
      }

      #{if(&, '&', '*')} .e-list-item.e-hover {
        cursor: pointer;
      }
    }
  }

  #{&}.e-timepicker.e-popup {
    #{if(&, '&', '*')} .e-list-parent.e-ul .e-list-item {
      line-height: $timepicker-list-normal-line-height;
      text-indent: $timepicker-list-normal-text-indent;
    }
  }

  .e-small #{&}.e-timepicker.e-popup,
  #{if(&, '&', '*')}.e-small#{&}.e-timepicker.e-popup {
    #{if(&, '&', '*')} .e-list-parent.e-ul {
      #{if(&, '&', '*')} .e-list-item {
        font-size: $timepicker-list-small-font-size;
        line-height: $timepicker-list-small-line-height;
        text-indent: $timepicker-list-small-text-indent;
      }
    }
  }

  .e-small .e-time-wrapper,
  #{if(&, '&', '*')}.e-small.e-time-wrapper,
  #{if(&, '&', '*')}.e-small .e-control-wrapper.e-time-wrapper,
  #{if(&, '&', '*')}.e-control-wrapper.e-small.e-time-wrapper {
    #{if(&, '&', '*')} .e-time-icon.e-icons {
      font-size: $timepicker-icon-small-font-size;
      @if $timepicker-skin-name == 'Material3' {
        min-height: $timepicker-small-input-min-height;
        min-width: $timepicker-small-input-min-width;
        border-radius: $timepicker-small-icon-border-radius;
        margin: $timepicker-time-small-icon-margin;
      }
    }
  }

  .e-content-placeholder.e-timepicker.e-placeholder-timepicker {
    background-size: 250px 33px;
    min-height: 33px;
  }
}

/* stylelint-disable */
.e-time-overflow {
  overflow: hidden;
}

.e-timepicker-mob-popup-wrap {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  max-height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1002;

  .e-timepicker.e-popup.e-lib.e-control.e-popup-open {
    left: 0 !important;
    position: relative;
    top: 0 !important;
  }
}

.e-timepicker-mob-popup-wrap .e-popup-expand.e-timepicker.e-popup,
.e-datetimepicker.e-popup-expand.e-timepicker.e-popup,
.e-timepicker-mob-popup-wrap .e-popup-expand.e-datetimepicker.e-popup,
.e-datetimepicker.e-popup-expand.e-popup {
  border-radius: 0;
}

.e-timepicker-mob-popup-wrap .e-popup-expand,
.e-datetimepicker.e-popup-expand {
    #{if(&, '&', '*')} .e-model-header {
      height: $modal-header-height;
      padding: $modal-header-padding;
      display: $modal-header-display-style;
      align-items: $modal-header-content-align;
      font-size: $modal-header-portrait-font-size;
      border-bottom: $modal-header-border-bottom;

      #{if(&, '&', '*')} .e-popup-close {
        float: $modal-close-icon-float;
        padding: $modal-portrait-content-padding;
      }

      #{if(&, '&', '*')} .e-model-title {
        padding: $modal-portrait-content-padding;
        text-transform: $modal-header-title-transform;
        font-weight: 500;
      }

      #{if(&, '&', '*')} .e-btn.e-popup-close {
        font-size: $modal-header-portrait-font-size;
      }
    }

    #{if(&, '&', '*')} .e-content {
      height: $modal-content-height;
      overflow: $modal-content-overflow;

      #{if(&, '&', '*')} .e-list-parent.e-ul .e-list-item {
        padding: $modal-list-item-padding;
        line-height: $modal-list-line-height;

        @media (max-device-width: 768px) {
          font-size: $modal-mobile-font-size;
        }

        @media (min-device-width: 768px) {
          font-size: $modal-tablet-font-size;
        }
      }
    }
}

@media screen and (orientation: landscape) {
  .e-timepicker-mob-popup-wrap .e-popup-expand,
  .e-datetimepicker.e-popup-expand {
      #{if(&, '&', '*')} .e-model-header {
        height: $modal-header-landscape-height;
        font-size: $modal-header-landscape-font-size;

        #{if(&, '&', '*')} .e-popup-close {
          padding: $modal-landscape-padding;
        }

        #{if(&, '&', '*')} .e-model-title {
          padding: $modal-landscape-padding;
        }

        #{if(&, '&', '*')} .e-btn.e-popup-close {
          font-size: $modal-header-landscape-font-size;
        }
      }

      #{if(&, '&', '*')} .e-content {
        height: $modal-content-landscape-height;

        #{if(&, '&', '*')} .e-list-parent.e-ul .e-list-item {
          padding: $modal-list-item-padding;
          line-height: $modal-landscape-list-line-height;
        }
      }
    }
}
/* stylelint-enable */

@include export-module('timepicker-theme') {
  .e-time-wrapper {
    #{if(&, '&', '*')} .e-input-group-icon.e-icons.e-active {
      @if $timepicker-skin-name == 'bootstrap4' or $timepicker-skin-name == 'bootstrap5' or $timepicker-skin-name == 'bootstrap5.3' {
        background: $timepicker-icon-active-bg-color;
        border-color: $timepicker-active-border-color;
      }
      color: $timepicker-list-active-icon-color;
    }

    #{if(&, '&', '*')}.e-input-group:not(.e-disabled) .e-input-group-icon.e-active:active {
      color: $timepicker-list-popup-icon-active-color;
      @if $timepicker-skin-name == 'Material3' {
        border: $timepicker-icon-active-border;
        border-radius: $timepicker-icon-active-border-radius;
        background: $timepicker-icon-active-bg-color;
      }
    }
  }

  #{&}.e-timepicker.e-popup {
    @if $timepicker-skin-name != 'Material3' {
      background-color: $timepicker-list-bg-color;
    }
    @if $timepicker-skin-name == 'Material3' {
      background: $timepicker-list-bg-color;
    }
    border: $timepicker-list-border-color;
    border-radius: $timepicker-popup-border-radius;
    box-shadow: $timepicker-popup-shadow;

    #{if(&, '&', '*')} .e-list-parent.e-ul {
      @if $timepicker-skin-name != 'Material3' {
        background-color: $timepicker-list-bg-color;
      }
      @if $timepicker-skin-name == 'Material3' {
        background: $timepicker-list-bg-color;
      }

      #{if(&, '&', '*')} li.e-list-item {
        border: $timepicker-list-default-border-style;
        color: $timepicker-list-default-font-color;
      }

      #{if(&, '&', '*')} .e-list-item.e-disabled {
        color: $timepicker-disable-text;
        opacity: $timepicker-disable-opacity;
        pointer-events: none;
        touch-action: none;
      }

      #{if(&, '&', '*')} .e-list-item.e-hover {
        @if $skin-name == 'tailwind3' {
          background: $timepicker-list-hover-bg-color;
          color: $timepicker-list-hover-font-color;
        }
      }

      #{if(&, '&', '*')} .e-list-item.e-navigation {
        @if $skin-name == 'tailwind3' {
          background: $timepicker-list-navigation-bg-color;
          color: $timepicker-list-navigation-font-color;
          box-shadow: $timepicker-list-navigation-box-shadow;
        }
      }

      #{if(&, '&', '*')} .e-list-item:focus {
        @if $skin-name == 'tailwind3' {
          background: $timepicker-list-focus-bg-color;
          color: $timepicker-list-focus-font-color;
        }
      }

      #{if(&, '&', '*')} .e-list-item.e-hover,
      #{if(&, '&', '*')} .e-list-item.e-navigation,
      #{if(&, '&', '*')} .e-list-item:focus {
        @if $timepicker-skin-name != 'Material3' {
          background-color: $timepicker-list-hover-bg-color;
        }
        @if $timepicker-skin-name == 'Material3' {
          background: $timepicker-list-hover-bg-color;
        }
        border: $timepicker-list-hover-border-color;
        color: $timepicker-list-hover-font-color;
      }

      #{if(&, '&', '*')} .e-list-item.e-active {
        @if $timepicker-skin-name != 'Material3' and $timepicker-skin-name != 'tailwind3' {
          background-color: $timepicker-list-active-bg-color;
        }
        @if $timepicker-skin-name == 'Material3' {
          background: $timepicker-list-active-bg-color;
        }
        color: $timepicker-list-active-font-color;
        @if $skin-name == 'tailwind3' {
          font-weight: 500;
        }
      }

      #{if(&, '&', '*')} .e-list-item.e-active.e-hover {
        @if $timepicker-skin-name != 'Material3' {
          background-color: $timepicker-list-active-hover-bg-color;
        }
        @if $timepicker-skin-name == 'Material3' {
          background: $timepicker-list-active-hover-bg-color;
        }
        color: $timepicker-list-active-hover-font-color;
      }
    }
  }

  .e-small #{&}.e-timepicker.e-popup,
  #{if(&, '&', '*')}.e-small#{&}.e-timepicker.e-popup {
    #{if(&, '&', '*')} .e-list-parent.e-ul {
      #{if(&, '&', '*')} .e-list-item {
        color: $timepicker-list-small-font-color;
      }
    }
  }

  .e-timepicker-mob-popup-wrap .e-timepicker.e-popup-expand,
  .e-datetimepicker.e-popup-expand {

    #{if(&, '&', '*')} .e-model-header {
      background-color: $modal-header-bg-color;
      color: $modal-header-text-color;

      #{if(&, '&', '*')} .e-popup-close {
        color: $modal-header-text-color;
        font-weight: 500;
      }
    }
  }
}


@include export-module('timepicker-material-icons') {

  /*! component icons */

  .e-time-wrapper,
  #{if(&, '&', '*')}.e-control-wrapper.e-time-wrapper {

    #{if(&, '&', '*')} .e-time-icon.e-icons::before {
      content: '\e20c';
    }
  }

  // Outline textbox icon configuration

  .e-time-wrapper.e-outline .e-input-group-icon.e-time-icon,
  .e-time-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-time-icon {
    min-height: $zero-value;
    min-width: 20px;
  }

  .e-small .e-time-wrapper.e-outline .e-input-group-icon.e-time-icon,
  .e-time-wrapper.e-control-wrapper.e-small.e-outline .e-input-group-icon.e-time-icon,
  .e-small .e-time-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-time-icon {
    min-height: $zero-value;
    min-width: 18px;
  }

  .e-timepicker-mob-popup-wrap .e-timepicker.e-popup-expand,
  .e-datetimepicker.e-popup-expand {

    #{if(&, '&', '*')} .e-model-header {

      #{if(&, '&', '*')} .e-popup-close::before {
        content: '\e932';
        font-family: 'e-icons';
      }
    }
  }
}

@include export-module('timepicker-bigger') {

  .e-bigger .e-time-wrapper,
  #{if(&, '&', '*')}.e-bigger.e-time-wrapper,
  #{if(&, '&', '*')}.e-bigger .e-control-wrapper .e-time-wrapper,
  #{if(&, '&', '*')}.e-control-wrapper.e-bigger.e-time-wrapper {
    #{if(&, '&', '*')} .e-time-icon.e-icons {
      font-size: $timepicker-icon-bigger-font-size;
      @if $timepicker-skin-name == 'material' or $timepicker-skin-name == 'material-dark' or $timepicker-skin-name == 'Material3' {
        min-height: $timepicker-bigger-input-min-height;
        min-width: $timepicker-bigger-input-min-width;
      }
      @if $timepicker-skin-name == 'Material3' {
        border-radius: $timepicker-bigger-icon-border-radius;
        margin: $timepicker-bigger-icon-margin;
      }
    }
  }

  .e-bigger #{&}.e-timepicker.e-popup,
  #{if(&, '&', '*')}.e-bigger#{&}.e-timepicker.e-popup {
    #{if(&, '&', '*')} .e-list-parent.e-ul {
      padding: $timepicker-list-bigger-padding 0;

      #{if(&, '&', '*')} .e-list-item {
        font-size: $timepicker-list-bigger-font-size;
        line-height: $timepicker-list-bigger-line-height;
        text-indent: $timepicker-list-bigger-text-indent;
      }
    }
  }

  .e-bigger .e-content-placeholder.e-timepicker.e-placeholder-timepicker,
  .e-bigger.e-content-placeholder.e-timepicker.e-placeholder-timepicker {
    background-size: 250px 40px;
    min-height: 40px;
  }

  .e-small.e-bigger #{&}.e-timepicker.e-popup,
  #{if(&, '&', '*')}.e-small.e-bigger#{&}.e-timepicker.e-popup {
    #{if(&, '&', '*')} .e-list-parent.e-ul {
      #{if(&, '&', '*')} .e-list-item {
        font-size: $timepicker-list-bigger-small-font-size;
        line-height: $timepicker-list-bigger-small-line-height;
        text-indent: $timepicker-list-bigger-small-text-indent;
      }
    }
  }

  .e-small.e-bigger .e-time-wrapper,
  #{if(&, '&', '*')}.e-small.e-bigger.e-time-wrapper,
  #{if(&, '&', '*')}.e-small.e-bigger .e-control-wrapper.e-time-wrapper,
  #{if(&, '&', '*')}.e-control-wrapper.e-small.e-bigger.e-time-wrapper {
    #{if(&, '&', '*')} .e-time-icon.e-icons {
      font-size: $timepicker-icon-bigger-small-font-size;
      @if $timepicker-skin-name == 'Material3' {
        min-height: $timepicker-bigger-small-input-min-height;
        min-width: $timepicker-bigger-small-input-min-width;
        border-radius: $timepicker-bigger-small-icon-border-radius;
        margin: $timepicker-bigger-small-icon-margin;
      }
    }
  }

  .e-bigger.e-small #{&}.e-timepicker.e-popup,
  #{if(&, '&', '*')}.e-bigger.e-small#{&}.e-timepicker.e-popup {
    #{if(&, '&', '*')} .e-list-parent.e-ul {
      #{if(&, '&', '*')} .e-list-item {
        color: $timepicker-list-small-font-color;
      }
    }
  }

  .e-bigger .e-time-wrapper.e-outline .e-input-group-icon.e-time-icon,
  .e-time-wrapper.e-control-wrapper.e-bigger.e-outline .e-input-group-icon.e-time-icon,
  .e-bigger .e-time-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-time-icon {
    @if $skin-name == 'material-dark' or $skin-name == 'material' {
      min-height: $zero-value;
      min-width: 24px;
    }
  }

  .e-bigger.e-small .e-outline.e-time-wrapper .e-input-group-icon.e-time-icon,
  .e-bigger.e-small.e-outline.e-time-wrapper .e-input-group-icon.e-time-icon,
  .e-time-wrapper.e-control-wrapper.e-bigger.e-small.e-outline .e-input-group-icon.e-time-icon,
  .e-bigger.e-small .e-time-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-time-icon {
    @if $skin-name == 'material-dark' or $skin-name == 'material' {
      min-height: $zero-value;
      min-width: 20px;
    }
  }
}

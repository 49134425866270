@import "../base/base.gd.scss";
@import "../inputs/input.gd.scss";
@import "../inputs/textbox.gd.scss";

/* stylelint-disable-line no-empty-source */

.e-input-group.e-multi-line-input.e-auto-width {
  width: auto;
}

.e-input-group.e-multi-line-input {
  textarea.e-resize-x {
    resize: horizontal;
  }

  textarea.e-resize-y {
    resize: vertical;
  }

  textarea.e-resize-xy {
    resize: both;
  }

  textarea.e-textarea.e-resize-none {
    resize: none;
  }
}

.e-float-input .e-clear-icon:hover,
.e-float-input.e-control-wrapper .e-clear-icon:hover,
.e-input-group .e-clear-icon:hover,
.e-input-group.e-control-wrapper .e-clear-icon:hover {
  background: none;
  border: none;
}

.e-float-input:not(.e-disabled) .e-clear-icon:hover,
.e-float-input.e-control-wrapper:not(.e-disabled) .e-clear-icon:hover,
.e-input-group:not(.e-disabled) .e-clear-icon:hover,
.e-input-group.e-control-wrapper:not(.e-disabled) .e-clear-icon:hover {
  background: none;
}


/* stylelint-disable-line no-empty-source */


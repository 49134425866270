@import "../base/base.gd.scss";

$input-skin-name: 'material' !default;
$zero-value: 0 !default;
$one-value: 1px !default;
$input-child-padding-bottom: $zero-value !default;
$input-child-small-bigger-padding-bottom: $zero-value !default;
$input-child-small-padding-bottom: $zero-value !default;
$input-child-bigger-padding-bottom: $zero-value !default;
$input-group-full-border: $border-size $border-type !default;
$input-child-small-bigger-padding-top: $zero-value !default;
$input-child-small-padding-top: $zero-value !default;
$input-child-padding-top: $zero-value !default;
$input-child-bigger-padding-top: $zero-value !default;
$input-success-color: $success-font-color !default;
$input-warning-color: $warning-font-color !default;
$input-error-color: $error-font-color !default;
$input-disable-bg-color: transparent !default;
$input-active-bg-color: $active-bg-color !default;
$input-active-font-color: $active-font-color !default;
$input-press-font-color: $active-font-color !default;

$textarea-float-top: -22px !default;
$input-box-border-radius: $zero-value !default;
$input-group-child-border-width: $zero-value !default;
$input-bg-color: transparent !default;
$input-font-size: 13px !default;
$input-bigger-font-size: 14px !default;
$input-small-font-size: 12px !default;
$input-bigger-small-font-size: 13px !default;
$input-padding: 4px $zero-value 4px !default;
$textarea-padding: 4px $zero-value 4px !default;
$textarea-bigger-padding: 5px $zero-value 5px !default;
$textarea-small-padding: 4px $zero-value 4px !default;
$textarea-bigger-small-padding: 4px $zero-value 4px !default;
$textarea-min-height: 15px !default;
$textarea-bigger-min-height: 17px !default;
$textarea-bigger-small-min-height: 15px !default;
$textarea-small-min-height: 14px !default;
$float-input-padding: 4px $zero-value 4px !default;
$float-label-padding: $zero-value !default;
$float-input-wrap-padding-top: $zero-value !default;
$input-padding-bottom: 4px !default;
$input-focus-padding-bottom: 3px !default;
$input-clear-icon: 12px !default;
$input-bigger-clear-icon: 12px !default;
$input-small-clear-icon: 10px !default;
$input-bigger-small-clear-icon: 12px !default;
$input-clear-icon-padding: $zero-value !default;
$input-clear-icon-position: center !default;
$input-bigger-padding: 5px $zero-value 5px !default;
$float-input-bigger-padding: 5px $zero-value 5px !default;
$float-input-bigger-wrap-padding-top: $zero-value !default;
$input-bigger-padding-bottom: 5px !default;
$input-focus-bigger-padding-bottom: 4px !default;
$input-child-padding: $zero-value !default;
$input-child-min-height: 18px !default;
$input-child-min-width: 18px !default;
$input-bigger-child-padding: $zero-value !default;
$input-bigger-child-min-height: 20px !default;
$input-bigger-child-min-width: 20px !default;
$input-margin-bottom: 4px !default;
$input-bigger-margin-bottom: 8px !default;
$input-small-margin-bottom: 4px !default;
$input-small-bigger-margin-bottom: 4px !default;
$input-margin-top: 16px !default;
$input-bigger-margin-top: 17px !default;
$input-small-margin-top: 16px !default;
$input-small-bigger-margin-top: 17px !default;
$input-small-padding: 4px $zero-value 4px !default;
$float-input-small-padding: 4px $zero-value 4px !default;
$float-input-small-wrap-padding-top: $zero-value !default;
$input-small-padding-bottom: 4px !default;
$input-focus-small-padding-bottom: 3px !default;
$input-bigger-small-padding: 4px $zero-value 4px !default;
$float-input-bigger-small-padding: 4px $zero-value 4px !default;
$float-input-bigger-small-wrap-padding-top: $zero-value !default;
$input-bigger-small-padding-bottom: 4px !default;
$input-focus-bigger-small-padding-bottom: 3px !default;
$input-bigger-small-child-padding: $zero-value !default;
$input-bigger-small-child-min-height: 18px !default;
$input-bigger-small-child-min-width: 18px !default;
$input-small-child-padding: $zero-value !default;
$input-small-child-min-height: 16px !default;
$input-small-child-min-width: 16px !default;
$input-font-family: $font-family !default;
$input-font-style: normal !default;
$input-font-normal: normal !default;
$input-border: $zero-value $border-type !default;
$float-input-border: $zero-value $border-type !default;
$input-box-border-width: $zero-value $zero-value $one-value $zero-value !default;
$float-input-border-width: $zero-value $zero-value $one-value $zero-value !default;
$input-focus-border-width: $zero-value $zero-value 2px $zero-value !default;
$input-box-border-color: rgba($grey-light-font, .42) !default;
$input-font-color: rgba($grey-light-font, .87) !default;
$input-icon-font-color: rgba($grey-black, .54) !default;
$input-clear-icon-color: rgba($grey-light-font, .54) !default;
$input-clear-icon-hover-color: rgba($grey-light-font, .54) !default;
$input-error-color: $error-font !default;
$input-accent: $accent !default;
$input-placeholder: rgba($grey-light-font, .42) !default;
$input-active-border-color: $accent !default;
$input-group-active-border-color: transparent !default;
$input-disable-border-type: dashed !default;
$input-disable-border-color: $input-box-border-color !default;
$input-disabled-group-bg-color: transparent !default;
$input-border-left-width: $zero-value !default;
$input-group-active-border: transparent !default;
$input-group-border: 1px $border-type !default;
$input-group-border-width: $zero-value !default;
$input-group-border-radius: 2px !default;
$input-group-pressed-bg: transparent !default;
$input-hover-bg-color:  $grey-200 !default;
$input-active-accent-color: $accent !default;
$input-valid-group-border-width: 2px !default;
$input-header-font-color: rgba($grey-light-font, .42) !default;
$input-child-margin-bottom: 4px !default;
$input-child-margin-right: 4px !default;
$input-child-margin-top: 4px !default;
$float-input-child-margin-top: 2px !default;
$input-child-small-bigger-margin-bottom: 4px !default;
$input-child-small-bigger-margin-right: 4px !default;
$input-child-small-bigger-margin-top: 4px !default;
$float-input-child-small-bigger-margin-top: 4px !default;
$input-child-small-margin-bottom: 4px !default;
$input-child-small-margin-right: 4px !default;
$input-child-small-margin-top: 4px !default;
$float-input-child-small-margin-top: 4px !default;
$input-child-bigger-margin-bottom: 5px !default;
$input-child-bigger-margin-right: 8px !default;
$input-child-bigger-margin-top: 6px !default;
$float-input-child-bigger-margin-top: 6px !default;
$input-disable-group-border-width: $zero-value $zero-value $one-value $zero-value !default;
$input-opacity: 1 !default;
$input-opacity-filter: 100 !default;
$input-group-border-width-focus: $zero-value $zero-value $one-value $zero-value !default;
$input-group-border-type-focus: $border-type !default;
$input-group-border-color-focus: $input-box-border-color !default;
$input-group-border-color-hover: $input-box-border-color !default;
$input-group-border-width-hover: 1px !default;
$input-group-border-right-focus: transparent !default;
$input-group-border-left-focus: transparent !default;
$input-group-full-border-color: $input-box-border-color !default;
$input-group-full-border-width: $zero-value $zero-value $one-value $zero-value !default;
$input-group-success-color: $input-success-color !default;
$input-group-warning-color: $input-warning-color !default;
$input-group-error-color: $input-error-color !default;
$input-valid-border-bottom-width: 2px !default;
$input-group-pressed-color: rgba($grey-black, .54) !default;
$input-select-font-color: $grey-dark-font !default;
$input-right-border-width: $zero-value !default;
$input-text-indent: $zero-value !default;
$input-small-text-indent: $zero-value !default;
$input-bigger-text-indent: $zero-value !default;
$input-small-bigger-text-indent: $zero-value !default;
$input-disable-font-color: rgba($grey-light-font, .42) !default;
$float-label-font-color: rgba($grey-light-font, .54) !default;
$float-label-disbale-font-color: $input-disable-font-color !default;
$float-label-font-size: 13px !default;
$float-label-bigger-font-size: 14px !default;
$float-label-small-font-size: 12px !default;
$float-label-bigger-small-font-size: 13px !default;
$float-placeholder-font-size: 13px !default;
$float-placeholder-bigger-font-size: 14px !default;
$float-placeholder-small-font-size: 12px !default;
$float-placeholder-bigger-small-font-size: 13px !default;
$input-border-size: 1px !default;
$input-normal-height: 32px - $input-border-size !default;
$input-bigger-height: 40px - $input-border-size !default;
$input-small-height: 26px - $input-border-size !default;
$input-bigger-small-height: 36px - $input-border-size !default;
$float-input-normal-height: 32px !default;
$float-input-bigger-height: 40px !default;
$float-input-small-height: 26px !default;
$float-input-bigger-small-height: 36px !default;
$input-full-height: 100% !default;
$textarea-normal-height: auto !default;
$textarea-bigger-height: auto !default;
$textarea-small-height: auto !default;
$textarea-bigger-small-height: auto !default;
$textarea-full-height: 100% !default;
$input-group-disabled-color: rgba($grey-light-font, .26) !default;
$input-group-hovered-color: $input-icon-font-color !default;
$input-icon-font-size: 12px !default;
$input-bigger-icon-font-size: 12px !default;
$input-small-icon-font-size: 12px !default;
$input-bigger-small-icon-font-size: 12px !default;
$input-inner-wrap-margin-left: 8px !default;
$input-clear-icon-padding-bottom: 4px !default;
$input-clear-icon-padding-right: 4px !default;
$input-clear-icon-padding-left: $input-clear-icon-padding-right !default;
$input-clear-icon-padding-top: 4px !default;
$float-input-clear-icon-padding-top: 4px !default;
$input-clear-icon-small-bigger-padding-bottom: 4px !default;
$input-clear-icon-small-bigger-padding-right: 4px !default;
$input-clear-icon-small-bigger-padding-top: 4px !default;
$float-input-clear-icon-small-bigger-padding-top: 4px !default;
$input-clear-icon-small-padding-bottom: 4px !default;
$input-clear-icon-small-padding-right: 4px !default;
$input-clear-icon-small-padding-top: 4px !default;
$float-input-clear-icon-small-padding-top: 4px !default;
$input-clear-icon-bigger-padding-bottom: 5px !default;
$input-clear-icon-bigger-padding-right: 8px !default;
$input-clear-icon-bigger-padding-top: 6px !default;
$float-input-clear-icon-bigger-padding-top: 6px !default;
$input-clear-icon-min-height: 18px !default;
$input-clear-icon-min-width: 18px !default;
$input-bigger-clear-icon-min-height: 20px !default;
$input-bigger-clear-icon-min-width: 20px !default;
$input-bigger-small-clear-icon-min-height: 18px !default;
$input-bigger-small-clear-icon-min-width: 18px !default;
$input-small-clear-icon-min-height: 16px !default;
$input-small-clear-icon-min-width: 16px !default;
$input-smaller-min-height: 16px !default;
$textarea-smaller-min-height: 18px !default;
$input-min-height: 19px !default;
$textarea-min-height: 23px !default;
$input-bigger-min-height: 23px !default;
$input-bigger-smaller-min-height: 19px !default;
$float-label-rtl-value: -7px !default;

$input-left-icon-font-size: 20px !default;
$input-bigger-left-icon-font-size: 20px !default;
$input-small-left-icon-font-size: 20px !default;
$input-bigger-small-left-icon-font-size: 20px !default;
$input-left-child-min-height: 30px !default;
$input-left-child-min-width: 30px !default;
$input-bigger-left-child-min-height: 32px !default;
$input-bigger-left-child-min-width: 32px !default;
$input-small-left-child-min-height: 28px !default;
$input-small-left-child-min-width: 28px !default;
$input-bigger-small-left-child-min-height: 30px !default;
$input-bigger-small-left-child-min-width: 30px !default;
$input-icon-inner-width: 10px !default;
$input-icon-inner-height: 10px !default;
$input-readonly-bg-color: none !default;

// Outline textbox

$outline-border-color: rgba($grey-black, .24) !default;
$outline-input-font-color: rgba($grey-light-font, .87) !default;
$outline-input-label-font-color: rgba($grey-light-font, .54) !default;
$outline-input-font-size: 14px !default;
$outline-hover-border-color: rgba($grey-black, .87) !default;
$outline-hover-font-color: rgba($grey-black, .87) !default;
$outline-active-input-border: 1px !default;
$outline-input-padding-left: 12px !default;
$outline-input-padding-top: 10px !default;
$outline-input-padding-bottom: 9px !default;
$outline-input-min-height: 38px !default;
$outline-input-icon-margin-left: $zero-value !default;
$outline-input-icon-margin-right: 12px !default;
$outline-small-input-min-height: 34px !default;
$outline-small-input-padding-left: 10px !default;
$outline-small-input-padding-top: 7px !default;
$outline-small-input-icon-margin-left: 10px !default;
$outline-small-input-icon-margin-right: 6px !default;
$outline-bigger-input-min-height: 54px !default;
$outline-bigger-input-icon-margin-left: 16px !default;
$outline-bigger-input-icon-margin-right: 8px !default;
$outline-bigger-input-padding-left: 16px !default;
$outline-bigger-input-padding-top: 15px !default;
$outline-small-bigger-input-min-height: 38px !default;
$outline-small-bigger-input-margin-top: 10px !default;
$outline-small-bigger-input-margin-bottom: 9px !default;
$outline-small-bigger-input-margin-left: 12px !default;
$outline-small-bigger-icon-margin-left: 12px !default;
$outline-small-bigger-icon-margin-right: 8px !default;
$outline-input-border: $zero-value !default;
$outline-input-group-border-width: $zero-value !default;
$outline-disabled-border-color: rgba($grey-black, .06) !default;
$outline-float-label-top: -7px !default;
$outline-label-before-element-margin-top: 6px !default;
$outline-wrapper-border-infocused: 2px !default;
$outline-label-min-width: 9px !default;
$outline-small-label-min-width: 7px !default;
$outline-bigger-label-min-width: 13px !default;
$outline-bigger-small-label-min-width: 9px !default;
$outline-label-height: 8px !default;
$outline-label-margin: 4px !default;
$outline-label-border-radius: 5px !default;
$outline-label-before-left: -1px !default;
$outline-label-after-left: 2px !default;
$outline-input-icon-padding-top-bottom: 9px !default;
$outline-input-icon-font-size: 16px !default;
$outline-input-clear-icon-font-size: 16px !default;
$outline-floating-label-font-size: 12px !default;
$outline-bigger-input-font-size: 16px !default;
$outline-bigger-input-icon-padding: 15px !default;
$outline-bigger-input-icon-font-size: 20px !default;
$outline-bigger-clear-icon-font-size: 20px !default;
$outline-small-bigger-input-label-font-size: 12px !default;
$outline-small-bigger-input-icon-font-size: 18px !default;
$outline-small-bigger-clear-icon-font-size: 18px !default;
$outline-label-font-color-with-value: rgba($grey-light-font, .6) !default;
$outline-valid-input-font-size: 14px !default;
$outline-label-default-line-height: 13px !default;
$outline-label-before-left-rtl: 5px !default;
$outline-label-after-left-rtl: -6px !default;
$outline-label-line-height: 54px !default;
$outline-bigger-label-line-height: 70px !default;
$outline-small-bigger-label-line-height: 53px !default;
$outline-small-label-line-height: 46px !default;
$outline-textarea-label-line-height: 50px !default;
$outline-valid-textarea-font-size: 14px !default;
$outline-textarea-small-label-line-height: 60px !default;
$outline-textarea-bigger-label-line-height: 66px !default;
$outline-textarea-small-bigger-label-line-height: 60px !default;
$outline-textarea-margin-top: 8px $zero-value 1px !default;
$outline-small-textarea-margin-top: 8px $zero-value 1px !default;
$outline-bigger-textarea-maring-top: 8px $zero-value 1px !default;
$outline-small-input-font-size: 13px !default;
$outline-small-input-icon-font-size: 14px !default;
$outline-small-clear-icon-font-size: 14px !default;
$outline-small-label-font-size: 11px !default;
$outline-bigger-label-font-size: 12px !default;
$outline-small-bigger-input-font-size: 14px !default;
$outline-small-bigger-label-font-size: 12px !default;
$outline-bigger-small-group-icon-top: 9px !default;
$outline-bigger-small-group-icon-bottom: 9px !default;
$outline-input-small-clear-icon: 14px !default;
$outline-input-clear-icon: 16px !default;
$outline-input-bigger-clear-icon: 20px !default;
$outline-input-bigger-small-clear-icon: 18px !default;
$outline-input-clear-icon-hover-color: rgba($grey-light-font, .87) !default;
$outline-input-clear-icon-active-color: rgba($grey-light-font, .87) !default;

$outline-float-label-font-size: 14px !default;
$bigger-outline-float-label-font-size: 16px !default;
$bigger-small-outline-float-label-font-size: 14px !default;
$small-outline-float-label-font-size: 13px !default;
$outline-float-label-disbale-font-color: rgba($grey-light-font, .38) !default;
$outline-disabled-input-font-color: rgba($grey-light-font, .38) !default;
$outline-input-group-disabled-color: rgba($grey-light-font, .38) !default;

@mixin input-sizing {
  box-sizing: content-box;
}

@mixin input-height ($height) {
  content: '';
}

@mixin input-state-color ($color) {
  border-bottom-color: $color;
}

@mixin input-selection {
  background: $input-accent;
  color: $accent-font;
}

@mixin float-label-alignment {
  content: '';
}

/* stylelint-disable property-no-vendor-prefix */
@mixin input-group-animation {
  -moz-transition: .2s cubic-bezier(.4, 0, .4, 1);
  bottom: 0;
  content: '';
  height: 2px;
  position: absolute;
  transition: .2s cubic-bezier(.4, 0, .4, 1);
  width: 0;
}

@mixin input-group-animation-left {
  left: 50%;
}

@mixin input-group-animation-width {
  width: 50%;
}

@mixin input-group-animation-right {
  right: 50%;
}

@mixin input-group-animation-bg {
  background: $input-active-border-color;
}

@mixin input-group-hover-bg {
  background: $input-group-border-color-hover;
}

@mixin input-group-success-animation-bg {
  background: $input-success-color;
}

@mixin input-group-warning-animation-bg {
  background: $input-warning-color;
}

@mixin input-group-error-animation-bg {
  background: $input-error-color;
}

@mixin input-ripple-parent {
  position: relative;
}

@mixin input-ripple-style {
  background: $grey-400;
  border-radius: 100%;
  content: '';
  display: block;
  height: 50%;
  left: 25%;
  opacity: .75;
  position: absolute;
  top: 25%;
  transform: scale(0);
  width: 50%;
}

@mixin input-ripple-animation {
  animation: e-input-ripple .45s linear;
}

@keyframes e-input-ripple {
  100% {
    opacity: 0;
    transform: scale(4);
  }
}

@keyframes slideTopUp {
  from{ transform: translate3d($zero-value, $zero-value, $zero-value) scale(1); }
  to{ transform: translate3d($zero-value, $zero-value, $zero-value) scale(1); }
}

//Filled input configuration
$filled-input-font-size: 14px !default;
$bigger-filled-input-font-size: 16px !default;
$bigger-small-filled-input-font-size: 14px !default;
$small-filled-input-font-size: 13px !default;
$filled-float-label-font-size: 14px !default;
$bigger-filled-float-label-font-size: 16px !default;
$bigger-small-filled-float-label-font-size: 14px !default;
$small-filled-float-label-font-size: 13px !default;
$filled-input-clear-icon-size: 16px !default;
$bigger-filled-input-clear-icon-size: 20px !default;
$bigger-small-filled-input-clear-icon-size: 18px !default;
$small-filled-input-clear-icon-size: 14px !default;
$filled-input-icon-size: 16px !default;
$bigger-filled-input-icon-size: 20px !default;
$bigger-small-filled-input-icon-size: 18px !default;
$small-filled-input-icon-size: 14px !default;

$filled-float-input-wrapper-padding: $zero-value 10px $zero-value 12px !default;
$bigger-filled-float-input-wrapper-padding: $zero-value 12px $zero-value 16px !default;
$bigger-small-filled-float-input-wrapper-padding: $zero-value 10px $zero-value 12px !default;
$small-filled-float-input-wrapper-padding: $zero-value 6px $zero-value 8px !default;

$filled-float-input-wrapper-rtl-padding: $zero-value 12px $zero-value 10px !default;
$bigger-filled-float-input-wrapper-rtl-padding: $zero-value 16px $zero-value 12px !default;
$bigger-small-filled-float-input-wrapper-rtl-padding: $zero-value 12px $zero-value 10px !default;
$small-filled-float-input-wrapper-rtl-padding: $zero-value 8px $zero-value 6px !default;

$filled-wrapper-padding: $zero-value 10px $zero-value 12px !default;
$bigger-filled-wrapper-padding: $zero-value 12px $zero-value 16px !default;
$bigger-small-filled-wrapper-padding: $zero-value 10px $zero-value 12px !default;
$small-filled-wrapper-padding: $zero-value 6px $zero-value 8px !default;

$filled-wrapper-rtl-padding: $zero-value 12px $zero-value 10px !default;
$bigger-filled-wrapper-rtl-padding: $zero-value 16px $zero-value 12px !default;
$bigger-small-filled-wrapper-rtl-padding: $zero-value 12px $zero-value 10px !default;
$small-filled-wrapper-rtl-padding: $zero-value 8px $zero-value 6px !default;

$filled-wrapper-margin: $zero-value !default;

$filled-input-padding: 10px 2px 10px $zero-value !default;
$filled-bigger-input-padding: 16px 4px 16px $zero-value !default;
$filled-bigger-small-input-padding: 10px 2px 10px $zero-value !default;
$filled-small-input-padding: 7px 2px 7px $zero-value !default;

$filled-input-rtl-padding: 10px $zero-value 10px 2px !default;
$filled-bigger-input-rtl-padding: 16px $zero-value 16px 4px !default;
$filled-bigger-small-input-rtl-padding: 10px $zero-value 10px 2px !default;
$filled-small-input-rtl-padding: 7px $zero-value 7px 2px !default;

$filled-input-padding-bottom: 10px !default;
$filled-bigger-input-padding-bottom: 16px !default;
$filled-bigger-small-input-padding-bottom: 10px !default;
$filled-small-input-padding-bottom: 7px !default;

$filled-float-input-padding: 14px 2px 5px $zero-value !default;
$filled-bigger-float-input-padding: 20px 4px 6px $zero-value !default;
$filled-bigger-small-float-input-padding: 14px 2px 5px $zero-value !default;
$filled-small-float-input-padding: 12px 2px 4px $zero-value !default;

$filled-float-input-rtl-padding: 14px $zero-value 5px 2px !default;
$filled-bigger-float-input-rtl-padding: 20px $zero-value 6px 4px !default;
$filled-bigger-small-float-input-rtl-padding: 14px $zero-value 5px 2px !default;
$filled-small-float-input-rtl-padding: 12px $zero-value 4px 2px !default;

$filled-textarea-padding: $zero-value 12px 9px !default;
$filled-bigger-textarea-padding: $zero-value 16px 8px !default;
$filled-bigger-small-textarea-padding: $zero-value 12px 10px !default;
$filled-small-textarea-padding: $zero-value 8px 7px !default;

$filled-float-textarea-padding: $zero-value 12px 5px !default;
$filled-bigger-float-textarea-padding: $zero-value 16px 8px !default;
$filled-bigger-small-float-textarea-padding: $zero-value 12px 5px !default;
$filled-small-float-textarea-padding: $zero-value 8px 4px !default;

$filled-textarea-wrapper-padding: 10px $zero-value $zero-value !default;
$bigger-filled-textarea-wrapper-padding: 16px $zero-value $zero-value !default;
$bigger-small-filled-textarea-wrapper-padding: 10px $zero-value $zero-value !default;
$small-filled-textarea-wrapper-padding: 7px $zero-value $zero-value !default;

$filled-float-textarea-wrapper-padding: 14px $zero-value $zero-value !default;
$bigger-filled-float-textarea-wrapper-padding: 20px $zero-value $zero-value !default;
$bigger-small-filled-float-textarea-wrapper-padding: 14px $zero-value $zero-value !default;
$small-filled-float-textarea-wrapper-padding: 12px $zero-value $zero-value !default;

$filled-input-label-top: 12px !default;
$bigger-filled-input-label-top: 18px !default;
$bigger-small-filled-input-label-top: 12px !default;
$small-filled-input-label-top: 10px !default;
$filled-input-label-top-after-floating: 12px !default;
$bigger-filled-input-label-top-after-floating: 18px !default;
$bigger-small-filled-input-label-top-after-floating: 12px !default;
$small-filled-input-label-top-after-floating: 10px !default;
$filled-input-label-left: 12px !default;
$bigger-filled-input-label-left: 16px !default;
$bigger-small-filled-input-label-left: 12px !default;
$small-filled-input-label-left: 8px !default;
$filled-input-label-initial-transform: none !default;
$filled-input-label-line-height: 1.2 !default;
$bigger-filled-input-label-line-height: 1.25 !default;
$small-filled-input-label-line-height: 1.2 !default;
$bigger-small-filled-input-label-line-height: 1.25 !default;
$filled-input-label-width: auto !default;

$filled-default-input-min-height: 39px !default;
$bigger-filled-default-input-min-height: 55px !default;
$bigger-small-filled-default-input-min-height: 39px !default;
$small-filled-default-input-min-height: 35px !default;

$filled-input-min-height: 39px !default;
$bigger-filled-input-min-height: 55px !default;
$bigger-small-filled-input-min-height: 39px !default;
$small-filled-input-min-height: 35px !default;

$filled-input-icon-min-height: 20px !default;
$bigger-filled-input-icon-min-height: 24px !default;
$bigger-small-filled-input-icon-min-height: 20px !default;
$small-filled-input-icon-min-height: 18px !default;

$filled-input-left-icon-min-height: 20px !default;
$bigger-filled-input-left-icon-min-height: 24px !default;
$bigger-small-filled-input-left-icon-min-height: 20px !default;
$small-filled-input-left-icon-min-height: 18px !default;

$filled-input-left-icon-min-width: 20px !default;
$bigger-filled-input-left-icon-min-width: 24px !default;
$bigger-small-filled-input-left-icon-min-width: 20px !default;
$small-filled-input-left-icon-min-width: 18px !default;

$filled-input-bg-color: $grey-100 !default;
$filled-input-overlay-bg-color: rgba($grey-black, .87) !default;
$filled-input-overlay-focused-bg-color: #ececec !default;
$filled-input-overlay-activated-bg-color: #dcdcdc !default;
$background-color-transition: opacity 15ms linear,
  background-color 15ms linear !default;
$filled-input-hover-border-color: rgba($grey-black, .87) !default;
$filled-input-float-label-color: rgba($grey-light-font, .6) !default;
$filled-input-disabled-font-color: rgba($grey-light-font, .38) !default;
$filled-input-disabled-bg-color: $grey-50 !default;
$filled-input-disabled-border-color: rgba($grey-black, .06) !default;
$filled-input-clear-icon-hover-color: rgba($grey-light-font, .87) !default;
$filled-input-clear-icon-active-color: rgba($grey-light-font, .87) !default;

@include export-module('input-layout') {

  /*! input layout */
  .e-input-group,
  .e-input-group.e-control-wrapper {
    display: table;
    line-height: 1.4;
    margin-bottom: $input-margin-bottom;
  }

  input.e-input#{$css},
  .e-input-group input.e-input,
  .e-input-group.e-control-wrapper input.e-input,
  textarea.e-input#{$css},
  .e-input-group textarea.e-input,
  .e-input-group.e-control-wrapper textarea.e-input {
    border: $input-border;
    border-width: $input-box-border-width;
    height: auto;
    line-height: inherit;
    margin: $zero-value;
    margin-bottom: $input-margin-bottom;
    outline: none;
    padding: $input-padding;
    text-indent: $input-text-indent;
    width: 100%;
  }

  input.e-input#{$css},
  textarea.e-input#{$css},
  .e-input-group,
  .e-input-group.e-control-wrapper,
  .e-input-group.e-disabled,
  .e-input-group.e-control-wrapper.e-disabled {
    font-family: $input-font-family;
    font-size: $input-font-size;
    font-weight: normal;
    @if ($input-skin-name == 'tailwind') {
      font-feature-settings: 'calt' 0;
    }
  }

  .e-input-group input.e-input,
  .e-input-group.e-control-wrapper input.e-input,
  .e-input-group textarea.e-input,
  .e-input-group.e-control-wrapper textarea.e-input,
  .e-input-group.e-small .e-input,
  .e-input-group.e-small.e-control-wrapper .e-input,
  .e-small .e-input-group .e-input,
  .e-small .e-input-group.e-control-wrapper .e-input {
    font: inherit;
  }

  input.e-input#{$css},
  .e-input-group input.e-input,
  .e-input-group input,
  .e-input-group.e-control-wrapper input.e-input,
  .e-input-group.e-control-wrapper input,
  .e-float-input input,
  .e-float-input.e-input-group input,
  .e-float-input.e-control-wrapper input,
  .e-float-input.e-control-wrapper.e-input-group input,
  input.e-input#{$css}:focus,
  .e-input-group input.e-input:focus,
  .e-input-group input:focus,
  .e-input-group.e-control-wrapper input.e-input:focus,
  .e-input-group.e-control-wrapper input:focus,
  .e-float-input input:focus,
  .e-float-input.e-input-group input:focus,
  .e-float-input.e-control-wrapper input:focus,
  .e-float-input.e-control-wrapper.e-input-group input:focus,
  .e-input-group.e-input-focus input.e-input,
  .e-input-group.e-input-focus input,
  .e-input-group.e-control-wrapper.e-input-focus input.e-input,
  .e-input-group.e-control-wrapper.e-input-focus input,
  .e-float-input.e-input-focus input,
  .e-float-input.e-input-group.e-input-focus input,
  .e-float-input.e-control-wrapper.e-input-focus input,
  .e-float-input.e-control-wrapper.e-input-group.e-input-focus input {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  input.e-input#{$css},
  .e-input-group input.e-input,
  .e-input-group input,
  .e-input-group.e-control-wrapper input.e-input,
  .e-input-group.e-control-wrapper input,
  .e-float-input input,
  .e-float-input.e-input-group input,
  .e-float-input.e-control-wrapper input,
  .e-float-input.e-control-wrapper.e-input-group input,
  .e-input-group,
  .e-input-group.e-control-wrapper,
  .e-float-input,
  .e-float-input.e-control-wrapper,
  .e-input#{$css}.e-corner,
  .e-input-group.e-corner.e-rtl input.e-input:only-child,
  .e-input-group.e-control-wrapper.e-corner.e-rtl input.e-input:only-child,
  .e-input-group.e-input-focus.e-corner,
  .e-input-group.e-control-wrapper.e-input-focus.e-corner,
  textarea.e-input#{$css},
  .e-input-group textarea.e-input,
  .e-input-group textarea,
  .e-input-group.e-control-wrapper textarea.e-input,
  .e-input-group.e-control-wrapper textarea,
  .e-float-input textarea,
  .e-float-input.e-input-group textarea,
  .e-float-input.e-control-wrapper textarea,
  .e-float-input.e-control-wrapper.e-input-group textarea {
    @if $skin-name == 'fluent2' {
      border-radius: 4px;
    }
    @else {
      border-radius: $input-box-border-radius;
    }
  }

  .e-input.e-small,
  .e-input-group.e-small,
  .e-input-group.e-control-wrapper.e-small,
  .e-input-group.e-small .e-input,
  .e-input-group.e-small input,
  .e-input-group.e-control-wrapper.e-small .e-input,
  .e-input-group.e-control-wrapper.e-small input,
  .e-float-input.e-small input,
  .e-float-input.e-input-group.e-small input,
  .e-float-input.e-control-wrapper.e-small input,
  .e-float-input.e-control-wrapper.e-input-group.e-small input,
  .e-float-input.e-small,
  .e-float-input.e-control-wrapper.e-small,
  .e-small .e-input-group,
  .e-small .e-input-group.e-control-wrapper,
  .e-small .e-input-group .e-input,
  .e-small .e-input-group input,
  .e-small .e-input-group.e-control-wrapper .e-input,
  .e-small .e-input-group.e-control-wrapper input,
  .e-small .e-float-input input,
  .e-small .e-float-input.e-input-group input,
  .e-small .e-float-input.e-control-wrapper input,
  .e-small .e-float-input.e-control-wrapper.e-input-group input,
  .e-small .e-float-input,
  .e-small .e-float-input.e-control-wrapper {
    @if ($input-skin-name == 'tailwind' or $input-skin-name == 'tailwind3' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      border-radius: $input-small-border-radius;
    }
  }

  .e-input#{$css}:focus {
    border-width: $input-focus-border-width;
    padding-bottom: $input-padding-bottom;
  }

  .e-input.e-small#{$css}:focus {
    border-width: $input-focus-border-width;
    padding-bottom: $input-small-padding-bottom;
  }

  .e-input#{$css}:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding-bottom: $input-focus-padding-bottom;
    }
  }

  .e-input.e-small#{$css}:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      padding-bottom: $input-focus-small-padding-bottom;
    }
  }

  .e-input-group input.e-input:focus,
  .e-input-group.e-control-wrapper input.e-input:focus,
  .e-input-group textarea.e-input:focus,
  .e-input-group.e-control-wrapper textarea.e-input:focus,
  .e-input-group.e-input-focus input.e-input,
  .e-input-group.e-control-wrapper.e-input-focus input.e-input {
    padding: $input-padding;
  }

  .e-input-group textarea.e-input:focus,
  .e-input-group.e-control-wrapper textarea.e-input:focus {
    padding: $textarea-padding;
  }

  .e-input-group .e-input-group-icon,
  .e-input-group.e-control-wrapper .e-input-group-icon {
    align-items: center;
    border: $input-border;
    border-width: $input-group-child-border-width;
    box-sizing: content-box;
    cursor: pointer;
    flex-direction: column;
    font-size: $input-icon-font-size;
    justify-content: center;
    line-height: 1;
    min-height: $input-child-min-height;
    min-width: $input-child-min-width;
    padding: $input-child-padding;
    text-align: center;
    @if ($input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      border: 1px solid;
      border-bottom: $zero-value;
      border-collapse: collapse;
      border-top: $zero-value;
    }
    @if $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      border-radius: $input-child-icon-border-radius;
    }
  }

  /* stylelint-disable property-no-vendor-prefix */
  .e-input-group:not(.e-disabled) .e-input-group-icon:hover,
  .e-input-group.e-control-wrapper:not(.e-disabled) .e-input-group-icon:hover {
    @if $input-skin-name == 'highcontrast' {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border-collapse: collapse;
      border-width: $zero-value + px 1px $zero-value + px 1px;
      box-sizing: border-box;
    }
  }

  .e-input-group .e-input-group-icon:last-child,
  .e-input-group.e-control-wrapper .e-input-group-icon:last-child {
    @if ($input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      border-right-width: $zero-value;
    }
  }

  .e-input-group:not(.e-rtl):not(.e-disabled) .e-input-group-icon:last-child:hover,
  .e-input-group.e-control-wrapper:not(.e-rtl):not(.e-disabled) .e-input-group-icon:last-child:hover,
  .e-input-group:not(.e-disabled) .e-input-group-icon:not(:last-child):hover + .e-input-group-icon:not(:last-child):hover .e-input-group.e-control-wrapper:not(.e-disabled) .e-input-group-icon:not(:last-child):hover + .e-input-group-icon:not(:last-child):hover,
  .e-input-group:not(.e-disabled) .e-input-group-icon:first-child:hover,
  .e-input-group.e-control-wrapper:not(.e-disabled) .e-input-group-icon:first-child:hover,
  .e-input-group:not(.e-rtl):not(.e-disabled) .e-input + .e-input-group-icon:last-child:hover,
  .e-input-group.e-control-wrapper:not(.e-rtl):not(.e-disabled) .e-input + .e-input-group-icon:last-child:hover,
  .e-input-group:not(.e-disabled) .e-input-group-icon:not(:last-child):hover,
  .e-input-group.e-control-wrapper:not(.e-disabled) .e-input-group-icon:not(:last-child):hover,
  .e-float-input.e-input-group.e-small:not(:disabled) .e-input-group-icon:first-child:hover,
  .e-float-input.e-control-wrapper.e-input-group.e-small:not(:disabled) .e-input-group-icon:first-child:hover,
  .e-input-group.e-rtl:not(.e-disabled) .e-input-group-icon:last-child:hover,
  .e-input-group.e-control-wrapper.e-rtl:not(.e-disabled) .e-input-group-icon:last-child:hover,
  .e-input-group.e-small:not(.e-disabled):not(.e-float-input):not(.e-rtl) .e-input-group-icon:last-child:hover,
  .e-input-group.e-float-icon-left .e-input-group-icon:last-child:hover,
  .e-input-group.e-control-wrapper.e-small:not(.e-disabled):not(.e-float-input):not(.e-rtl) .e-input-group-icon:last-child:hover,
  .e-input-group.e-control-wrapper.e-float-icon-left .e-input-group-icon:last-child:hover,
  .e-input-group.e-small:not(.e-disabled):not(.e-float-input):not(.e-bigger):not(.e-float-icon-left) .e-input-group-icon:not(:last-child):hover,
  .e-input-group.e-float-icon-left .e-input-group-icon:not(:last-child):hover,
  .e-input-group.e-control-wrapper.e-small:not(.e-disabled):not(.e-float-input):not(.e-bigger):not(.e-float-icon-left) .e-input-group-icon:not(:last-child):hover,
  .e-input-group.e-control-wrapper.e-float-icon-left .e-input-group-icon:not(:last-child):hover,
  .e-input-group.e-small.e-float-icon-left:not(.e-disabled) .e-input-group-icon:first-child:hover,
  .e-input-group.e-control-wrapper.e-small.e-float-icon-left:not(.e-disabled) .e-input-group-icon:first-child:hover,
  .e-float-input.e-input-group.e-small:not(.e-float-icon-left):not(.e-input-group):not(.e-disabled) .e-input-group-icon:last-child:hover,
  .e-float-input.e-control-wrapper.e-input-group.e-small:not(.e-float-icon-left):not(.e-input-group):not(.e-disabled) .e-input-group-icon:last-child:hover,
  .e-float-input.e-input-group.e-small.e-float-icon-left .e-input-group-icon:not(:last-child):hover,
  .e-float-input.e-input-group.e-float-icon-left .e-input-group-icon:not(:last-child):hover,
  .e-float-input.e-control-wrapper.e-input-group.e-small.e-float-icon-left .e-input-group-icon:not(:last-child):hover,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-group-icon:not(:last-child):hover,
  .e-float-input.e-input-group.e-small.e-float-icon-left .e-input-group-icon:last-child:hover,
  .e-float-input.e-input-group.e-float-icon-left .e-input-group-icon:last-child:hover,
  .e-float-input.e-control-wrapper.e-input-group.e-small.e-float-icon-left .e-input-group-icon:last-child:hover,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-group-icon:last-child:hover {
    @if $input-skin-name == 'highcontrast' {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border-width: $zero-value + px 1px $zero-value + px 1px;
      box-sizing: border-box;
    }
  }

  .e-input-group .e-input-group-icon + .e-input-group-icon .e-input-group.e-control-wrapper .e-input-group-icon + .e-input-group-icon {
    @if ($input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      border-left-width: $zero-value;
    }
  }

  .e-input-group .e-input-group-icon:first-child,
  .e-input-group.e-control-wrapper .e-input-group-icon:first-child {
    @if ($input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'tailwind' or $input-skin-name == 'tailwind3') {
      border-left-width: $zero-value;
    }
  }

  .e-input-group.e-rtl .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-rtl .e-input-group-icon,
  .e-rtl .e-input-group .e-input-group-icon,
  .e-rtl .e-input-group.e-control-wrapper .e-input-group-icon,
  .e-input-group.e-rtl .e-input-group-icon + .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-rtl .e-input-group-icon + .e-input-group-icon,
  .e-rtl .e-input-group .e-input-group-icon + .e-input-group-icon,
  .e-rtl .e-input-group.e-control-wrapper .e-input-group-icon + .e-input-group-icon {
    @if ($input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      border-left-width: 1px;
      border-right-width: $zero-value;
    }
  }

  .e-input-group.e-rtl input ~ .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-rtl input ~ .e-input-group-icon,
  .e-rtl .e-input-group input ~ .e-input-group-icon,
  .e-rtl .e-input-group.e-control-wrapper input ~ .e-input-group-icon {
    @if ($input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      border-left-width: $zero-value;
      border-right: 1px solid $input-box-border-color;
    }
  }

  .e-input-group.e-rtl input ~ .e-input-group-icon + .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-rtl input ~ .e-input-group-icon + .e-input-group-icon,
  .e-rtl .e-input-group input ~ .e-input-group-icon + .e-input-group-icon,
  .e-rtl .e-input-group.e-control-wrapper input ~ .e-input-group-icon + .e-input-group-icon {
    @if ($input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      border-left-width: $zero-value;
      border-right-width: 1px;
    }
  }

  .e-input-group .e-input-group-icon + .e-input-group-icon,
  .e-input-group.e-control-wrapper .e-input-group-icon + .e-input-group-icon {
    @if ($input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      border-left-width: $zero-value;
    }
  }

  .e-input-group .e-input-group-icon:last-child,
  .e-input-group.e-control-wrapper .e-input-group-icon:last-child {
    @if ($input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'tailwind' or $input-skin-name == 'tailwind3') {
      border-bottom-right-radius: $input-group-icon-border-radius;
      border-top-right-radius: $input-group-icon-border-radius;
    }
  }

  .e-input-group .e-input-group-icon:first-child,
  .e-input-group.e-control-wrapper .e-input-group-icon:first-child {
    @if ($input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'tailwind' or $input-skin-name == 'tailwind3') {
      border-bottom-left-radius: $input-group-icon-border-radius;
      border-top-left-radius: $input-group-icon-border-radius;
    }
  }

  .e-input-group.e-rtl .e-input-group-icon:last-child,
  .e-input-group.e-control-wrapper.e-rtl .e-input-group-icon:last-child,
  .e-rtl .e-input-group .e-input-group-icon:last-child,
  .e-rtl .e-input-group.e-control-wrapper .e-input-group-icon:last-child {
    @if ($input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'tailwind' or $input-skin-name == 'tailwind3') {
      border-bottom-left-radius: $input-group-icon-border-radius;
      border-bottom-right-radius: $zero-value;
      border-top-left-radius: $input-group-icon-border-radius;
      border-top-right-radius: $zero-value;
    }
  }

  .e-input-group.e-rtl .e-input-group-icon:first-child,
  .e-input-group.e-control-wrapper.e-rtl .e-input-group-icon:first-child,
  .e-rtl .e-input-group .e-input-group-icon:first-child,
  .e-rtl .e-input-group.e-control-wrapper .e-input-group-icon:first-child {
    @if ($input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      border-bottom-left-radius: $zero-value;
      border-bottom-right-radius: $input-group-icon-border-radius;
      border-top-left-radius: $zero-value;
      border-top-right-radius: $input-group-icon-border-radius;
    }
  }

  .e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-float-input.e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon,
  .e-float-input.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $input-left-icon-font-size;
      margin: $zero-value;
      min-height: $input-left-child-min-height;
      min-width: $input-left-child-min-width;
      padding: $zero-value;
    }
  }

  .e-input-group.e-small.e-float-icon-left > .e-input-group-icon,
  .e-input-group.e-float-icon-left > .e-input-group-icon.e-small,
  .e-input-group.e-control-wrapper.e-small.e-float-icon-left > .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon.e-small,
  .e-small .e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-small .e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon,
  .e-float-input.e-input-group.e-small.e-float-icon-left > .e-input-group-icon,
  .e-float-input.e-input-group.e-float-icon-left > .e-input-group-icon.e-small,
  .e-small .e-float-input.e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-float-input.e-control-wrapper.e-input-group.e-small.e-float-icon-left > .e-input-group-icon,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left > .e-input-group-icon.e-small,
  .e-small .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      font-size: $input-small-left-icon-font-size;
      margin: $zero-value;
      min-height: $input-small-left-child-min-height;
      min-width: $input-small-left-child-min-width;
      padding: $zero-value;
    }
  }

  .e-input-group.e-float-icon-left:not(.e-disabled) > .e-input-group-icon:active,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-disabled) > .e-input-group-icon:active {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      background: transparent;
    }
  }

  .e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      cursor: auto;
    }
  }

  .e-input#{$css}[disabled],
  .e-input-group .e-input[disabled],
  .e-input-group.e-control-wrapper .e-input#{$css}[disabled],
  .e-input-group.e-disabled,
  .e-input-group.e-disabled input,
  .e-input-group.e-disabled input.e-input,
  .e-input-group.e-disabled textarea,
  .e-input-group.e-disabled textarea.e-input,
  .e-input-group.e-control-wrapper.e-disabled,
  .e-input-group.e-control-wrapper.e-disabled input,
  .e-input-group.e-control-wrapper.e-disabled input.e-input,
  .e-input-group.e-control-wrapper.e-disabled textarea,
  .e-input-group.e-control-wrapper.e-disabled textarea.e-input,
  .e-float-input.e-disabled input,
  .e-float-input.e-disabled textarea,
  .e-float-input input[disabled],
  .e-float-input input.e-disabled,
  .e-float-input textarea[disabled],
  .e-float-input textarea.e-disabled,
  .e-float-input.e-control-wrapper.e-disabled input,
  .e-float-input.e-control-wrapper.e-disabled textarea,
  .e-float-input.e-control-wrapper input[disabled],
  .e-float-input.e-control-wrapper input.e-disabled,
  .e-float-input.e-control-wrapper textarea[disabled],
  .e-float-input.e-control-wrapper textarea.e-disabled,
  .e-input-group.e-disabled span,
  .e-input-group.e-control-wrapper.e-disabled span,
  .e-input-group.e-disabled input.e-input:not(:valid):first-child ~ .e-clear-icon,
  .e-input-group.e-control-wrapper.e-disabled input.e-input:not(:valid):first-child ~ .e-clear-icon,
  .e-float-input.e-disabled input:not(:valid):first-child ~ .e-clear-icon,
  .e-float-input.e-input-group.e-disabled input:not(:valid):first-child ~ .e-clear-icon,
  .e-float-input.e-input-group.e-control-wrapper.e-disabled input:not(:valid):first-child ~ .e-clear-icon,
  .e-float-input.e-control-wrapper.e-disabled input:not(:valid):first-child ~ .e-clear-icon,
  .e-input-group.e-disabled .e-clear-icon.e-clear-icon-hide,
  .e-input-group.e-control-wrapper.e-disabled .e-clear-icon.e-clear-icon-hide {
    cursor: not-allowed;
  }

  .e-input#{$css}[disabled],
  .e-input-group.e-disabled,
  .e-input-group.e-control-wrapper.e-disabled,
  .e-float-input input[disabled],
  .e-float-input input.e-disabled,
  .e-float-input.e-control-wrapper input[disabled],
  .e-float-input.e-control-wrapper input.e-disabled  {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' {
      border-color: $input-disable-border-color;
      border-style: $input-disable-border-type;
    }
  }

  .e-input-group.e-disabled,
  .e-input-group.e-control-wrapper.e-disabled {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' {
      border-bottom-style: $input-disable-border-type;
      border-width: $input-disable-group-border-width;
    }
  }

  .e-input#{$css}[disabled],
  .e-input-group.e-disabled,
  .e-input-group.e-control-wrapper.e-disabled,
  .e-float-input.e-disabled,
  .e-float-input input[disabled],
  .e-float-input input.e-disabled,
  .e-float-input.e-disabled input,
  .e-float-input.e-control-wrapper.e-disabled,
  .e-float-input.e-control-wrapper input[disabled],
  .e-float-input.e-control-wrapper input.e-disabled,
  .e-float-input.e-control-wrapper.e-disabled input,
  .e-float-input textarea[disabled],
  .e-float-input textarea.e-disabled,
  .e-float-input.e-disabled textarea,
  .e-float-input.e-control-wrapper textarea[disabled],
  .e-float-input.e-control-wrapper textarea.e-disabled,
  .e-float-input.e-control-wrapper.e-disabled textarea {
    filter: alpha(opacity=$input-opacity-filter);
    opacity: $input-opacity;
  }

  .e-input#{$css}.e-rtl,
  .e-input-group.e-rtl,
  .e-input-group.e-control-wrapper.e-rtl,
  .e-float-input.e-rtl,
  .e-float-input.e-control-wrapper.e-rtl {
    direction: rtl;
  }

  .e-input-group,
  .e-input-group.e-control-wrapper,
  .e-float-custom-tag.e-input-group,
  .e-float-custom-tag.e-input-group.e-control-wrapper,
  .e-input-custom-tag,
  .e-input-custom-tag.e-input-group,
  .e-input-custom-tag.e-input-group.e-control-wrapper {
    display: inline-flex;
    vertical-align: middle;
  }

  .e-float-input:not(.e-input-group),
  .e-float-input.e-control-wrapper:not(.e-input-group),
  .e-float-custom-tag,
  .e-float-custom-tag.e-control-wrapper {
    display: inline-block;
  }

  .e-input-group .e-input-group-icon,
  .e-input-group.e-control-wrapper .e-input-group-icon {
    display: flex;
  }

  .e-input-group .e-input-group-icon:first-child,
  .e-input-group.e-control-wrapper .e-input-group-icon:first-child {
    @if ($input-skin-name != 'bootstrap' and $input-skin-name != 'bootstrap4' and $input-skin-name != 'bootstrap5' and $input-skin-name != 'tailwind3' and $input-skin-name != 'bootstrap5.3' and $input-skin-name != 'tailwind' and $input-skin-name != 'FluentUI') {
      border-left-width: $input-border-left-width;
    }
  }

  .e-input-group .e-input-group-icon,
  .e-input-group.e-control-wrapper .e-input-group-icon {
    white-space: nowrap;
  }

  .e-input-group .e-input-group-icon:not(:last-child),
  .e-input-group.e-control-wrapper .e-input-group-icon:not(:last-child) {
    @if ($input-skin-name != 'bootstrap' and $input-skin-name != 'bootstrap4' and $input-skin-name != 'bootstrap5' and $input-skin-name != 'bootstrap5.3' and $input-skin-name != 'FluentUI') {
      border-right-width: $zero-value;
    }
  }

  .e-input + .e-input-group-icon,
  .e-input-group .e-input + .e-input-group-icon,
  .e-input-group.e-control-wrapper .e-input + .e-input-group-icon {
    @if ($input-skin-name != 'bootstrap' and $input-skin-name != 'bootstrap4' and $input-skin-name != 'bootstrap5' and $input-skin-name != 'bootstrap5.3' and $input-skin-name != 'FluentUI') {
      border-left-width: $zero-value;
    }
  }

  .e-input-group.e-corner .e-input:first-child,
  .e-input-group.e-corner .e-input-group-icon:first-child,
  .e-input-group.e-control-wrapper.e-corner .e-input:first-child,
  .e-input-group.e-control-wrapper.e-corner .e-input-group-icon:first-child {
    border-bottom-left-radius: $input-box-border-radius;
    border-top-left-radius: $input-box-border-radius;
  }

  .e-input-group.e-corner .e-input:last-child,
  .e-input-group.e-corner .e-input-group-icon:last-child,
  .e-input-group.e-control-wrapper.e-corner .e-input:last-child,
  .e-input-group.e-control-wrapper.e-corner .e-input-group-icon:last-child {
    border-bottom-right-radius: $input-box-border-radius;
    border-top-right-radius: $input-box-border-radius;
  }

  .e-input-group.e-rtl .e-input-group-icon:first-child,
  .e-input-group.e-control-wrapper.e-rtl .e-input-group-icon:first-child  {
    @if ($input-skin-name != 'bootstrap' and $input-skin-name != 'bootstrap4' and $input-skin-name != 'bootstrap5' and $input-skin-name != 'bootstrap5.3' and $input-skin-name != 'FluentUI') {
      border-left-width: $zero-value;
      border-right-width: $input-border-left-width;
    }
  }

  .e-input-group.e-rtl .e-input-group-icon:last-child,
  .e-input-group.e-control-wrapper.e-rtl .e-input-group-icon:last-child {
    @if ($input-skin-name != 'bootstrap' and $input-skin-name != 'bootstrap4' and $input-skin-name != 'FluentUI' and $input-skin-name != 'bootstrap5' and $input-skin-name != 'tailwind3' and $input-skin-name != 'bootstrap5.3' and $input-skin-name != 'tailwind') or $input-skin-name == 'highcontrast' {
      border-left-width: $input-border-left-width;
      border-right-width: $zero-value;
    }
  }

  .e-input-group.e-rtl .e-input-group-icon:not(:last-child),
  .e-input-group.e-control-wrapper.e-rtl .e-input-group-icon:not(:last-child) {
    @if ($input-skin-name != 'bootstrap' and $input-skin-name != 'bootstrap4' and $input-skin-name != 'bootstrap5' and $input-skin-name != 'bootstrap5.3' and $input-skin-name != 'tailwind3' and $input-skin-name != 'tailwind' and $input-skin-name != 'FluentUI') {
      border-left-width: $input-border-left-width;
    }
  }

  .e-input-group.e-rtl:not(.e-disabled) .e-input-group-icon:not(:last-child):hover,
  .e-input-group.e-control-wrapper.e-rtl:not(.e-disabled) .e-input-group-icon:not(:last-child):hover {
    @if $input-skin-name == 'highcontrast' {
      border-width: $zero-value + px 1px $zero-value + px 1px;
    }
  }

  .e-input-group.e-rtl .e-input-group-icon + .e-input,
  .e-input-group.e-control-wrapper.e-rtl .e-input-group-icon + .e-input {
    @if ($input-skin-name != 'bootstrap' and $input-skin-name != 'bootstrap4' and $input-skin-name != 'bootstrap5' and $input-skin-name != 'bootstrap5.3' and $input-skin-name != 'FluentUI') {
      border-right-width: $zero-value;
    }
  }

  input.e-input.e-small#{$css},
  textarea.e-input.e-small#{$css},
  .e-small input.e-input#{$css},
  .e-small textarea.e-input#{$css},
  .e-input-group.e-small,
  .e-small .e-input-group,
  .e-input-group.e-control-wrapper.e-small,
  .e-small .e-input-group.e-control-wrapper,
  .e-input-group.e-small.e-disabled,
  .e-small .e-input-group.e-disabled,
  .e-input-group.e-control-wrapper.e-small.e-disabled,
  .e-small .e-input-group.e-control-wrapper.e-disabled {
    font-size: $input-small-font-size;
  }

  .e-input#{$css}.e-small,
  .e-input-group.e-small .e-input,
  .e-input-group.e-control-wrapper.e-small .e-input {
    line-height: inherit;
    padding: $input-small-padding;
  }

  .e-input-group.e-small .e-input:focus,
  .e-input-group.e-control-wrapper.e-small .e-input:focus,
  .e-input-group.e-small.e-input-focus .e-input,
  .e-input-group.e-control-wrapper.e-small.e-input-focus .e-input {
    padding: $input-small-padding;
  }

  .e-input-group.e-small .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-small .e-input-group-icon,
  .e-small .e-input-group .e-input-group-icon,
  .e-small .e-input-group.e-control-wrapper .e-input-group-icon {
    font-size: $input-small-icon-font-size;
    min-height: $input-small-child-min-height;
    min-width: $input-small-child-min-width;
    padding: $input-small-child-padding;
    @if $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      border-radius: $input-small-child-border-radius;
    }
  }

  .e-input-group.e-small:not(.e-disabled) .e-input-group-icon:first-child:hover,
  .e-input-group.e-float-icon-left .e-input-group-icon:first-child:hover,
  .e-input-group.e-control-wrapper.e-small:not(.e-disabled) .e-input-group-icon:first-child:hover,
  .e-input-group.e-control-wrapper.e-float-icon-left .e-input-group-icon:first-child:hover {
    @if $input-skin-name == 'highcontrast' {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border-width: $zero-value + px  1px $zero-value + px 1px;
      box-sizing: border-box;
    }
  }

  label.e-float-text,
  .e-float-input label.e-float-text,
  .e-float-input.e-control-wrapper label.e-float-text,
  .e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    font-size: $float-placeholder-font-size;
    font-style: $input-font-style;
    font-weight: normal;
    left: 0;
    overflow: hidden;
    padding-left: $float-label-padding;
    pointer-events: none;
    position: absolute;
    text-overflow: ellipsis;
    top: -11px;
    transform: translate3d(0, 16px, 0) scale(1);
    transform-origin: left top;
    transition: .25s cubic-bezier(.25, .8, .25, 1);
    user-select: none;
    white-space: nowrap;
    width: 100%;
  }

  label.e-float-text,
  .e-float-input label.e-float-text,
  .e-float-input.e-control-wrapper label.e-float-text,
  .e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    top: -11px;
  }

  label.e-float-text,
  .e-float-input label.e-float-text,
  .e-float-input.e-control-wrapper label.e-float-text,
  .e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @include float-label-alignment;
    font-style: $input-font-style;
  }

  .e-float-input.e-small label.e-float-text,
  .e-float-input.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-small label.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    font-size: $float-placeholder-small-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -11px;
    }
  }

  .e-float-input .e-input-in-wrap label.e-float-text,
  .e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -11px;
    }
  }

  .e-float-input input:focus ~ label.e-float-text,
  .e-float-input input:valid ~ label.e-float-text,
  .e-float-input input ~ label.e-label-top.e-float-text,
  .e-float-input input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input input label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper input:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper input ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper input label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-input-focus input ~ label.e-float-text,
  .e-float-input.e-input-focus input ~ label.e-float-text {
    font-size: $float-label-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      top: -9px;
      transform: translate3d(0, -6px, 0) scale(.92);
    }
    @else if $input-skin-name == 'fluent2' {
      padding: 0;
      top: -15px;
      transform: translate3d(0, -6px, 0) scale(.92);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      padding-right: 0;
      transform: translate3d(-10px, -43px, 0) scale(1);
    }
    @else if ($input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4') {
      padding-right: 0;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if ($input-skin-name == 'tailwind' or $input-skin-name == 'tailwind3') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -35px, 0) scale(1);
      @if $input-skin-name == 'tailwind3' {
        padding-left: 0;
        left: 12px;
        top: 40%;
      }
    }
    @else if ($input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -36px, 0) scale(1);
    }
    @else if ($input-skin-name == 'FluentUI') {
      font-weight: 600;
      padding-right: 0;
      transform: translate3d(-10px, -41px, 0) scale(1);
    }
  }

  .e-float-input.e-small input:focus ~ label.e-float-text,
  .e-float-input.e-small input:valid ~ label.e-float-text,
  .e-float-input.e-small input ~ label.e-label-top.e-float-text,
  .e-float-input.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small input label.e-float-text.e-label-top,
  .e-small .e-float-input input:focus ~ label.e-float-text,
  .e-small .e-float-input input:valid ~ label.e-float-text,
  .e-small .e-float-input input ~ label.e-label-top.e-float-text,
  .e-small .e-float-input input[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-float-input input[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-float-input input label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-small input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small input:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small input ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small input label.e-float-text.e-label-top,
  .e-small .e-float-input.e-control-wrapper input:focus ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper input:valid ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper input ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-control-wrapper input[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-control-wrapper input[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-control-wrapper input label.e-float-text.e-label-top {
    font-size: $float-label-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -9px;
      transform: translate3d(0, -6px, 0) scale(.92);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      padding-right: 0;
      transform: translate3d(-10px, -43px, 0) scale(1);
    }
    @else if ($input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4') {
      padding-right: 0;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if ($input-skin-name == 'tailwind' or $input-skin-name == 'tailwind3') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -34px, 0) scale(1);
    }
    @else if ($input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -35px, 0) scale(1);
    }
    @else if ($input-skin-name == 'FluentUI') {
      font-style: $input-font-normal;
      font-weight: 600;
      padding-right: 0;
      transform: translate3d(-10px, -38px, 0) scale(1);
    }
  }

  .e-float-input .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-float-input .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-float-input .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-float-input .e-input-in-wrap input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input .e-input-in-wrap input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input .e-input-in-wrap input label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper .e-input-in-wrap input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper .e-input-in-wrap input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper .e-input-in-wrap input label.e-float-text.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -9px;
    }
  }

  .e-float-input.e-small input:focus ~ label.e-float-text,
  .e-float-input.e-small input:valid ~ label.e-float-text,
  .e-float-input.e-small input ~ label.e-label-top.e-float-text,
  .e-small .e-float-input input ~ label.e-label-top.e-float-text,
  .e-float-input.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small input:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small input ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-control-wrapper input ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small.e-input-focus input-group-animation ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-input-focus input ~ label.e-float-text {
    font-size: $float-label-small-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -8px;
    }
    @if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'highcontrast' or $input-skin-name == 'tailwind' or $input-skin-name == 'tailwind3' {
      padding-right: 0;
      top: 17px;
    }
    @if $input-skin-name == 'tailwind3' {
      padding-left: 0;
      left: 12px;
    }
  }

  .e-float-input.e-small .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-float-input.e-small .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-float-input.e-small .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-small .e-float-input .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-float-input.e-small .e-input-in-wrap input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small .e-input-in-wrap input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-control-wrapper .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small .e-input-in-wrap input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small .e-input-in-wrap input[disabled] ~ label.e-label-top.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      top: -7px;
    }
  }

  .e-float-input,
  .e-float-input.e-control-wrapper {
    line-height: 1.4;
    margin-bottom: $input-margin-bottom;
    margin-top: $input-margin-top;
    padding-top: $float-input-wrap-padding-top;
    position: relative;
    width: 100%;
  }

  .e-float-input.e-small,
  .e-float-input.e-control-wrapper.e-small,
  .e-small .e-float-input.e-control-wrapper {
    line-height: 1.35;
    margin-bottom: $input-small-margin-bottom;
    margin-top: $input-small-margin-top;
    padding-top: $float-input-small-wrap-padding-top;
  }

  .e-input-group.e-small,
  .e-input-group.e-control-wrapper.e-small,
  .e-small .e-input-group,
  .e-small .e-input-group.e-control-wrapper {
    line-height: normal;
  }

  .e-float-input.e-no-float-label,
  .e-float-input.e-small.e-no-float-label,
  .e-small .e-float-input.e-no-float-label,
  .e-float-input.e-control-wrapper.e-no-float-label,
  .e-float-input.e-control-wrapper.e-small.e-no-float-label,
  .e-small .e-float-input.e-control-wrapper.e-no-float-label {
    margin-top: $zero-value;
  }

  .e-float-input,
  .e-float-input.e-control-wrapper,
  .e-float-input.e-disabled,
  .e-float-input.e-control-wrapper.e-disabled,
  .e-float-input.e-input-group.e-disabled,
  .e-float-input.e-input-group.e-control-wrapper.e-disabled {
    font-family: $input-font-family;
    font-size: $input-font-size;
    font-weight: normal;
  }

  .e-float-input input,
  .e-float-input textarea,
  .e-float-input.e-control-wrapper input,
  .e-float-input.e-control-wrapper textarea {
    border: $float-input-border;
    border-width: $float-input-border-width;
    display: block;
    font: inherit;
    width: 100%;
  }

  .e-float-input input,
  .e-float-input.e-control-wrapper input {
    min-width: 0;
    padding: $float-input-padding;
  }

  .e-float-input input,
  .e-input-group input,
  .e-float-input.e-control-wrapper input,
  .e-input-group.e-control-wrapper input,
  .e-float-input textarea,
  .e-input-group textarea,
  .e-float-input.e-control-wrapper textarea,
  .e-input-group.e-control-wrapper textarea {
    text-indent: $input-text-indent;
  }

  .e-float-input.e-small.e-disabled,
  .e-small .e-float-input.e-disabled,
  .e-float-input.e-control-wrapper.e-small.e-disabled,
  .e-small .e-float-input.e-control-wrapper.e-disabled,
  .e-float-input.e-input-group.e-small.e-disabled,
  .e-small .e-float-input.e-input-group.e-disabled,
  .e-float-input.e-input-group.e-control-wrapper.e-small.e-disabled,
  .e-small .e-float-input.e-input-group.e-control-wrapper.e-disabled,
  .e-float-input.e-small,
  .e-small .e-float-input,
  .e-float-input.e-control-wrapper.e-small,
  .e-small .e-float-input.e-control-wrapper {
    font-size: $input-small-font-size;
  }

  .e-float-input.e-small input,
  .e-float-input.e-control-wrapper.e-small input {
    font: inherit;
    line-height: inherit;
    padding: $float-input-small-padding;
  }

  .e-float-input input:focus,
  .e-float-input.e-control-wrapper input:focus,
  .e-float-input textarea:focus,
  .e-float-input.e-control-wrapper textarea:focus,
  .e-float-input.e-input-focus input,
  .e-float-input.e-control-wrapper.e-input-focus input,
  .e-input-group.e-control-container.valid.modified,
  .e-input-group.e-control-container.invalid,
  .e-float-input.e-control-container.valid.modified,
  .e-float-input.e-control-container.invalid {
    outline: none;
  }

  label.e-float-text,
  .e-float-input label.e-float-text,
  .e-float-input.e-control-wrapper label.e-float-text {
    font-family: inherit;
  }

  .e-float-input input:valid ~ label.e-float-text,
  .e-float-input input:focus ~ label.e-float-text,
  .e-float-input input:valid ~ label.e-float-text.e-label-top,
  .e-float-input input ~ label.e-float-text.e-label-top,
  .e-float-input .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-float-input .e-input-in-wrap input:valid ~ label.e-float-text.e-label-top,
  .e-float-input .e-input-in-wrap input ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper input:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper input:valid ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper input ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper .e-input-in-wrap input:valid ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper .e-input-in-wrap input ~ label.e-float-text.e-label-top,
  .e-float-input.e-input-focus input ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-input-focus input ~ label.e-float-text,
  .e-float-input textarea:valid ~ label.e-float-text,
  .e-float-input textarea:focus ~ label.e-float-text,
  .e-float-input textarea:valid ~ label.e-float-text.e-label-top,
  .e-float-input textarea ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper textarea:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper textarea:valid ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper textarea ~ label.e-float-text.e-label-top {
    @if ($input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      font-weight: $input-float-font-weight;
    }
    user-select: text;
  }

  .e-float-input.e-outline.e-float-icon-left:not(.e-rtl) .e-input-in-wrap input ~ label.e-float-text.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      left: -34px;
      width: auto;
    }
  }

  .e-float-input.e-outline.e-float-icon-left.e-rtl .e-input-in-wrap input ~ label.e-float-text.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      right: -34px;
      width: auto;
    }
  }

  label.e-float-text,
  .e-float-input label.e-float-text,
  .e-float-input.e-control-wrapper label.e-float-text,
  .e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-small:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    font-weight: normal;
  }

  .e-float-input:not(.e-input-group) .e-float-line::before,
  .e-float-input:not(.e-input-group) .e-float-line::after,
  .e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::before,
  .e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::after {
    bottom: 0;
    content: '';
    height: 2px;
    position: absolute;
    transition: .2s ease;
    width: 0;
  }

  .e-float-input:not(.e-input-group) .e-float-line::before,
  .e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::before {
    left: 50%;
  }

  .e-float-input:not(.e-input-group) .e-float-line::after,
  .e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::after {
    right: 50%;
  }

  .e-float-input:not(.e-input-group) input:focus ~ .e-float-line::before,
  .e-float-input:not(.e-input-group) textarea:focus ~ .e-float-line::before,
  .e-float-input:not(.e-input-group) input:focus ~ .e-float-line::after,
  .e-float-input:not(.e-input-group) textarea:focus ~ .e-float-line::after,
  .e-float-input.e-control-wrapper:not(.e-input-group) input:focus ~ .e-float-line::before,
  .e-float-input.e-control-wrapper:not(.e-input-group) textarea:focus ~ .e-float-line::before,
  .e-float-input.e-control-wrapper:not(.e-input-group) input:focus ~ .e-float-line::after,
  .e-float-input.e-control-wrapper:not(.e-input-group) textarea:focus ~ .e-float-line::after,
  .e-float-input:not(.e-input-group).e-input-focus input ~ .e-float-line::before,
  .e-float-input:not(.e-input-group).e-input-focus input ~ .e-float-line::after,
  .e-float-input.e-control-wrapper:not(.e-input-group).e-input-focus input ~ .e-float-line::before,
  .e-float-input.e-control-wrapper:not(.e-input-group).e-input-focus input ~ .e-float-line::after {
    width: 50%;
  }

  .e-float-input .e-float-line,
  .e-float-input.e-control-wrapper .e-float-line {
    display: block;
    position: relative;
    width: 100%;
  }

  .e-float-input.e-rtl label.e-float-text,
  .e-float-input.e-control-wrapper.e-rtl label.e-float-text,
  .e-rtl .e-float-input label.e-float-text,
  .e-rtl .e-float-input.e-control-wrapper label.e-float-text,
  .e-rtl label.e-float-text,
  .e-rtl .e-float-input.e-control-wrapper label.e-float-text,
  .e-rtl.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    right: 0;
    transform-origin: right top;
    @if $input-skin-name == 'fluent2' {
      padding-right: 8px;
    }
  }

  .e-float-input.e-rtl:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-float-input.e-rtl.e-control-wrapper:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input.e-control-wrapper:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-float-input.e-rtl input:not(:focus):not(:valid) label.e-float-text,
  .e-float-input.e-rtl input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input input:not(:focus):not(:valid) label.e-float-text,
  .e-rtl .e-float-input input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-rtl input:not(:focus):not(:valid) label.e-float-text,
  .e-rtl .e-float-input.e-control-wrapper input:not(:focus):not(:valid) label.e-float-text,
  .e-float-input.e-rtl.e-control-wrapper input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input.e-control-wrapper input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-float-input.e-rtl.e-small:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-float-input.e-rtl.e-control-wrapper.e-small:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input.e-small:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input.e-control-wrapper.e-small:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-rtl:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-rtl.e-control-wrapper:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-small.e-rtl .e-float-input:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-small.e-rtl .e-float-input.e-control-wrapper:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-float-input.e-small.e-rtl input:not(:focus):not(:valid) label.e-float-text,
  .e-float-input.e-rtl.e-small input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input.e-small input:not(:focus):not(:valid) label.e-float-text,
  .e-rtl .e-float-input.e-small input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-rtl input:not(:focus):not(:valid) label.e-float-text,
  .e-small .e-float-input.e-rtl input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-small.e-rtl .e-float-input input:not(:focus):not(:valid) label.e-float-text,
  .e-small.e-rtl .e-float-input input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-small.e-rtl input:not(:focus):not(:valid) label.e-float-text,
  .e-float-input.e-control-wrapper.e-rtl.e-small input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input.e-control-wrapper.e-small input:not(:focus):not(:valid) label.e-float-text,
  .e-rtl .e-float-input.e-control-wrapper.e-small input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper.e-rtl input:not(:focus):not(:valid) label.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-rtl input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-small.e-rtl .e-float-input.e-control-wrapper input:not(:focus):not(:valid) label.e-float-text,
  .e-small.e-rtl .e-float-input.e-control-wrapper input:not(:focus):not(:valid) label.e-float-text.e-label-bottom {
    padding-right: $float-label-padding;
  }

  .e-input-group.e-corner.e-rtl .e-input:first-child,
  .e-input-group.e-corner.e-rtl .e-input-group-icon:first-child,
  .e-input-group.e-control-wrapper.e-corner.e-rtl .e-input:first-child,
  .e-input-group.e-control-wrapper.e-corner.e-rtl .e-input-group-icon:first-child {
    border-bottom-left-radius: $zero-value;
    border-bottom-right-radius: $input-box-border-radius;
    border-top-left-radius: $zero-value;
    border-top-right-radius: $input-box-border-radius;
  }

  .e-input-group.e-corner.e-rtl .e-input:last-child,
  .e-input-group.e-corner.e-rtl .e-input-group-icon:last-child,
  .e-input-group.e-control-wrapper.e-corner.e-rtl .e-input:last-child,
  .e-input-group.e-control-wrapper.e-corner.e-rtl .e-input-group-icon:last-child  {
    border-bottom-left-radius: $input-box-border-radius;
    border-bottom-right-radius: $zero-value;
    border-top-left-radius: $input-box-border-radius;
    border-top-right-radius: $zero-value;
  }

  .e-input-group.e-warning::before,
  .e-input-group.e-control-wrapper.e-warning::before {
    content: '';
  }

  .e-float-input input[disabled],
  .e-float-input input.e-disabled,
  .e-float-input.e-control-wrapper input[disabled],
  .e-float-input.e-control-wrapper input.e-disabled {
    background: transparent;
    background-image: none;
    cursor: not-allowed;
    @if $input-skin-name == 'highcontrast' {
      color: $disable;
    }
  }

  .e-input-group.e-rtl .e-input:not(:first-child):focus,
  .e-input-group.e-control-wrapper.e-rtl .e-input:not(:first-child):focus {
    border-right-width: $input-right-border-width;
  }

  .e-input-group input.e-input,
  .e-input-group.e-control-wrapper input.e-input {
    min-width: 0;
    width: 100%;
  }

  .e-input-group input.e-input,
  .e-input-group textarea.e-input,
  .e-input-group input.e-input:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]):not(:focus),
  .e-input-group textarea.e-input:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]),
  .e-input-group.e-control-wrapper input.e-input,
  .e-input-group.e-control-wrapper textarea.e-input,
  .e-input-group.e-control-wrapper input.e-input:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]):not(:focus),
  .e-input-group.e-control-wrapper textarea.e-input:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]) {
    border: $input-border;
    border-width: $input-group-border-width;
  }

  .e-input-group input.e-input,
  .e-input-group textarea.e-input,
  .e-input-group.e-control-wrapper input.e-input,
  .e-input-group.e-control-wrapper textarea.e-input,
  .e-float-input input.e-input,
  .e-float-input.e-control-wrapper input.e-input {
    margin-bottom: $zero-value;
  }

  .e-input-group.e-input-focus::before,
  .e-input-group.e-control-wrapper.e-input-focus::before,
  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
  .e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::before,
  .e-float-input.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::before,
  .e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::before,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::before {
    @if $input-skin-name == 'fluent2' {
      border-bottom-right-radius: 10px;
    }
  }

  .e-input-group.e-input-focus::after,
  .e-input-group.e-control-wrapper.e-input-focus::after,
  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
  .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
  .e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::after,
  .e-float-input.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::after,
  .e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::after,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::after {
    @if $input-skin-name == 'fluent2' {
      border-bottom-left-radius: 10px;
    }
  }

  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
  .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
  .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
  .e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::before,
  .e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::after,
  .e-float-input.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::before,
  .e-float-input.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::after,
  .e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::before,
  .e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::after,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::before,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::after,
  .e-filled.e-float-input.e-input-group.e-float-icon-left .e-float-line::before,
  .e-filled.e-float-input.e-input-group.e-float-icon-left .e-float-line::after,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-float-line::before,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-float-line::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      @include input-group-animation;
    }
  }

  .e-input-group::before,
  .e-input-group::after,
  .e-input-group.e-control-wrapper::before,
  .e-input-group.e-control-wrapper::after  {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' {
      @include input-group-animation;
    }
  }

  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-input-group.e-float-icon-left .e-input-in-wrap:not(.e-float-input)::before,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-input-group.e-control-wrapper.e-float-icon-left .e-input-in-wrap:not(.e-float-input)::before,
  .e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::before,
  .e-float-input.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::before,
  .e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::before,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::before,
  .e-filled.e-input-group.e-float-icon-left::before,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left::before,
  .e-filled.e-float-input.e-input-group.e-float-icon-left .e-float-line::before,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-float-line::before {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      @include input-group-animation-left;
    }
  }

  .e-input-group::before,
  .e-input-group.e-control-wrapper::before {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' {
      @include input-group-animation-left;
    }
  }

  .e-input-group:not(.e-float-icon-left):not(.e-float-input).e-input-focus::before,
  .e-input-group:not(.e-float-icon-left):not(.e-float-input).e-input-focus::after,
  .e-input-group.e-float-icon-left:not(.e-float-input).e-input-focus .e-input-in-wrap::before,
  .e-input-group.e-float-icon-left:not(.e-float-input).e-input-focus .e-input-in-wrap::after,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input).e-input-focus::before,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input).e-input-focus::after,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input).e-input-focus .e-input-in-wrap::before,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input).e-input-focus .e-input-in-wrap::after,
  .e-float-input.e-input-group:not(.e-float-icon-left).e-input-focus .e-float-line::before,
  .e-float-input.e-input-group:not(.e-float-icon-left).e-input-focus .e-float-line::after,
  .e-float-input.e-input-group.e-float-icon-left.e-input-focus .e-input-in-wrap .e-float-line::before,
  .e-float-input.e-input-group.e-float-icon-left.e-input-focus .e-input-in-wrap .e-float-line::after,
  .e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left).e-input-focus .e-float-line::before,
  .e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left).e-input-focus .e-float-line::after,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left.e-input-focus .e-input-in-wrap .e-float-line::before,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left.e-input-focus .e-input-in-wrap .e-float-line::after,
  .e-filled.e-float-input.e-input-group.e-float-icon-left.e-input-focus .e-float-line::before,
  .e-filled.e-float-input.e-input-group.e-float-icon-left.e-input-focus .e-float-line::after,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left.e-input-focus .e-float-line::before,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left.e-input-focus .e-float-line::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      @include input-group-animation-width;
    }
  }

  .e-input-group.e-input-focus::before,
  .e-input-group.e-input-focus::after,
  .e-input-group.e-control-wrapper.e-input-focus::before,
  .e-input-group.e-control-wrapper.e-input-focus::after {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' {
      @include input-group-animation-width;
    }
  }

  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
  .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
  .e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::after,
  .e-float-input.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::after,
  .e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::after,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::after,
  .e-filled.e-input-group.e-float-icon-left:not(.e-float-input)::after,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)::after,
  .e-filled.e-float-input.e-input-group.e-float-icon-left .e-float-line::after,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-float-line::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      @include input-group-animation-right;
    }
  }

  .e-input-group::after,
  .e-input-group.e-control-wrapper::after {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' {
      @include input-group-animation-right;
    }
  }

  .e-input-group,
  .e-input-group.e-control-wrapper {
    position: relative;
    width: 100%;
  }

  .e-input-group .e-input-group-icon:hover,
  .e-input-group.e-rtl.e-corner .e-input-group-icon:hover,
  .e-input-group.e-control-wrapper .e-input-group-icon:hover,
  .e-input-group.e-control-wrapper.e-rtl.e-corner .e-input-group-icon:hover {
    @if ($input-skin-name != 'bootstrap' and $input-skin-name != 'bootstrap4' and $input-skin-name != 'bootstrap5' and $input-skin-name != 'bootstrap5.3' and $input-skin-name != 'Material3') {
      border-radius: $input-group-border-radius;
    }
  }

  .e-input#{$css}.e-small,
  .e-input-group.e-small,
  .e-input-group.e-control-wrapper.e-small {
    margin-bottom: $input-small-margin-bottom;
  }

  .e-input-group .e-input-group-icon,
  .e-input-group.e-control-wrapper .e-input-group-icon {
    margin-bottom: $input-child-margin-bottom;
    margin-right: $input-child-margin-right;
    margin-top: $input-child-margin-top;
  }

  .e-float-input.e-input-group .e-input-group-icon,
  .e-float-input.e-control-wrapper.e-input-group .e-input-group-icon {
    @if $input-skin-name != 'Material3' {
      margin-top: $float-input-child-margin-top;
    }
  }

  .e-input-group.e-small .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-small .e-input-group-icon,
  .e-small .e-input-group .e-input-group-icon,
  .e-small .e-input-group.e-control-wrapper .e-input-group-icon {
    @if ($input-skin-name != 'Material3') {
      margin-bottom: $input-child-small-margin-bottom;
      margin-right: $input-child-small-margin-right;
      margin-top: $input-child-small-margin-top;
    }
    @else {
      margin: $input-child-small-margin;
    }
  }

  .e-float-input.e-input-group.e-small .e-input-group-icon,
  .e-small .e-float-input.e-input-group .e-input-group-icon,
  .e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon,
  .e-small .e-float-input.e-control-wrapper.e-input-group .e-input-group-icon {
    @if $input-skin-name != 'Material3' and $input-skin-name != 'fluent2' {
      margin-top: $float-input-child-small-margin-top;
    }
  }

  .e-input-group .e-input-group-icon:last-child,
  .e-input-group.e-control-wrapper .e-input-group-icon:last-child {
    @if $skin-name == 'fluent2' {
      margin-right: 4px;
    }
  }

  .e-input-group .e-input-group-icon:last-child,
  .e-input-group.e-control-wrapper .e-input-group-icon:last-child,
  .e-input-group.e-small .e-input-group-icon:last-child,
  .e-input-group.e-control-wrapper.e-small .e-input-group-icon:last-child {
    @if $input-skin-name != 'fluent' {
      margin-right: $zero-value;
    }
    @if $input-skin-name == 'fluent2' {
      margin: $zero-value;
    }
  }

  .e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-style: $input-group-border-type-focus;
      border-width: $input-group-border-width-focus;
    }
  }

  .e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-input-group.e-input-focus:not(.e-float-icon-left).e-warning:not(.e-success):not(.e-error),
  .e-input-group.e-input-focus:not(.e-float-icon-left).e-error:not(.e-success):not(.e-warning),
  .e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error),
  .e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error,
  .e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error) input,
  .e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error) input,
  .e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error) input,
  .e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error input,
  .e-input-group.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left).e-warning:not(.e-success):not(.e-error),
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left).e-error:not(.e-success):not(.e-warning),
  .e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error),
  .e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error,
  .e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error) input,
  .e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error) input,
  .e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error) input,
  .e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error input,
  .e-input-group.e-control-wrapper.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-style: $input-group-border-type-focus;
      border-width: $input-group-border-width-focus;
    }
  }

  .e-input-group,
  .e-input-group.e-control-wrapper {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' {
      border-bottom: $input-group-border;
    }
  }

  .e-input-group:not(.e-float-icon-left),
  .e-input-group.e-control-wrapper:not(.e-float-icon-left),
  .e-filled.e-input-group.e-float-icon-left,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      border-bottom: $input-group-border;
    }
  }

  .e-input-group:not(.e-float-icon-left),
  .e-input-group.e-success:not(.e-float-icon-left),
  .e-input-group.e-warning:not(.e-float-icon-left),
  .e-input-group.e-error:not(.e-float-icon-left),
  .e-input-group.e-control-wrapper:not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border: $input-group-full-border;
      border-width: $input-group-full-border-width;
    }
    @if $input-skin-name == 'Material3' {
      padding-top: 1px;
    }
  }

  .e-underline.e-input-group:not(.e-float-icon-left),
  .e-underline.e-input-group.e-success:not(.e-float-icon-left),
  .e-underline.e-input-group.e-warning:not(.e-float-icon-left),
  .e-underline.e-input-group.e-error:not(.e-float-icon-left),
  .e-underline.e-input-group.e-control-wrapper:not(.e-float-icon-left),
  .e-underline.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
  .e-underline.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
  .e-underline.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
    @if $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      border: $input-group-full-border;
      border-width: $input-group-full-border-width;
      padding-top: 1px;
      border-radius: 0;
    }
  }

  .e-input-group,
  .e-input-group.e-success,
  .e-input-group.e-warning,
  .e-input-group.e-error,
  .e-input-group.e-control-wrapper,
  .e-input-group.e-control-wrapper.e-success,
  .e-input-group.e-control-wrapper.e-warning,
  .e-input-group.e-control-wrapper.e-error {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' {
      border: $input-group-full-border;
      border-width: $input-group-full-border-width;
    }
  }

  .e-input-group.e-rtl.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input:not(:first-child):focus,
  .e-input-group.e-control-wrapper.e-rtl.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input:not(:first-child):focus {
    border-right-width: $zero-value;
  }

  .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left).e-disabled,
  .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error).e-disabled .e-input-in-wrap,
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left).e-disabled,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error).e-disabled .e-input-in-wrap {
    background: $input-disable-bg-color;
    color: $input-disable-font-color;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      background-image: linear-gradient(90deg, $input-disable-border-color 0, $input-disable-border-color 33%, transparent 0);
      background-position: bottom -1px left 0;
      background-repeat: repeat-x;
      background-size: 4px 1px;
      border-bottom-color: transparent;
      color: $input-disable-font-color;
    }
    @if $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      border-color: $input-disable-border-color;
    }
  }

  .e-input-group:not(.e-success):not(.e-warning):not(.e-error).e-disabled,
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error).e-disabled {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' {
      border-style: $input-disable-border-type;
    }
  }

  .e-input-group .e-input-group-icon,
  .e-input-group.e-control-wrapper .e-input-group-icon {
    @if $input-skin-name != 'fluent2' {
      @include input-ripple-parent;
    }
  }

  .e-input-group:not(.e-filled) .e-input-group-icon::after,
  .e-input-group.e-control-wrapper:not(.e-filled) .e-input-group-icon::after {
    @if $input-skin-name != 'fluent2' {
      @include input-ripple-style;
    }
  }

  .e-input-group .e-input-group-icon.e-input-btn-ripple::after,
  .e-input-group.e-control-wrapper .e-input-group-icon.e-input-btn-ripple::after {
    @if $input-skin-name != 'fluent2' {
      @include input-ripple-animation;
    }
  }

  input.e-input#{$css}::-ms-clear,
  .e-float-input input::-ms-clear,
  .e-float-input.e-control-wrapper input::-ms-clear,
  .e-input:not(:valid):not(.e-control):first-child ~ .e-clear-icon,
  .e-input-group input:not(.e-control).e-input:not(:valid):first-child ~ .e-clear-icon,
  .e-input-group.e-control-wrapper input.e-input:not(:valid):not(.e-control):first-child ~ .e-clear-icon,
  .e-float-input input:not(:valid):not(.e-control):first-child ~ .e-clear-icon,
  .e-float-input.e-control-wrapper input:not(:valid):not(.e-control):first-child ~ .e-clear-icon,
  .e-float-input.e-input-group input:not(:valid):not(.e-control):first-child ~ .e-clear-icon,
  .e-float-input.e-input-group.e-control-wrapper input:not(:valid):not(.e-control):first-child ~ .e-clear-icon {
    display: none;
  }

  .e-input-group .e-clear-icon.e-clear-icon-hide,
  .e-input-group.e-control-wrapper .e-clear-icon.e-clear-icon-hide,
  .e-float-input.e-control-wrapper.e-hidden,
  .e-input-group.e-control-wrapper.e-hidden {
    display: none;
  }

  input.e-input[type = 'search']::-webkit-search-decoration,
  input.e-input[type = 'search']::-webkit-search-cancel-button,
  input.e-input[type = 'search']::-webkit-search-results-button,
  input.e-input[type = 'search']::-webkit-search-results-decoration,
  .e-float-input input[type = 'search']::-webkit-search-decoration,
  .e-float-input input[type = 'search']::-webkit-search-cancel-button,
  .e-float-input input[type = 'search']::-webkit-search-results-button,
  .e-float-input input[type = 'search']::-webkit-search-results-decoration,
  .e-float-input.e-control-wrapper input[type = 'search']::-webkit-search-decoration,
  .e-float-input.e-control-wrapper input[type = 'search']::-webkit-search-cancel-button,
  .e-float-input.e-control-wrapper input[type = 'search']::-webkit-search-results-button,
  .e-float-input.e-control-wrapper input[type = 'search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  .e-float-input.e-input-group .e-float-line,
  .e-float-input.e-input-group.e-control-wrapper .e-float-line,
  .e-float-input.e-control-wrapper.e-input-group .e-float-line,
  .e-float-input.e-control-wrapper.e-input-group.e-control-wrapper .e-float-line {
    @if $input-skin-name == 'fluent2' {
      bottom: -.1px;
      position: absolute;
    }
    @else {
      bottom: -1px;
      position: absolute;
    }
  }

  .e-float-input.e-input-group input,
  .e-float-input.e-input-group textarea,
  .e-float-input.e-input-group.e-control-wrapper input,
  .e-float-input.e-input-group.e-control-wrapper textarea {
    border: $zero-value;
  }

  .e-float-input.e-input-group input:focus,
  .e-input-group input:focus,
  .e-float-input.e-input-group textarea:focus,
  .e-float-input.e-input-group.e-control-wrapper input:focus,
  .e-float-input.e-input-group.e-control-wrapper textarea {
    @if $input-skin-name == 'tailwind' or $input-skin-name == 'tailwind3' {
      box-shadow: none;
    }
  }

  .e-float-input.e-input-group .e-float-line,
  .e-float-input.e-input-group .e-float-text,
  .e-float-input.e-input-group.e-control-wrapper .e-float-line,
  .e-float-input.e-input-group.e-control-wrapper .e-float-text  {
    right: $zero-value;
  }


  input.e-input#{$css}::-webkit-input-placeholder,
  input.e-input#{$css}:-moz-placeholder,
  input.e-input#{$css}:-ms-input-placeholder,
  input.e-input#{$css}::-moz-placeholder,
  textarea.e-input#{$css}::-webkit-input-placeholder,
  textarea.e-input#{$css}:-moz-placeholder,
  textarea.e-input#{$css}:-ms-input-placeholder,
  textarea.e-input#{$css}::-moz-placeholder,
  textarea.e-input#{$css}::-webkit-textarea-placeholder,
  textarea.e-input#{$css}:-moz-placeholder,
  textarea.e-input#{$css}:-ms-textarea-placeholder,
  textarea.e-input#{$css}::-moz-placeholder {
    font-size: $input-font-size;
    font-style: $input-font-style;
  }

  .e-small input.e-input#{$css}::-webkit-input-placeholder,
  input.e-small.e-input#{$css}::-webkit-input-placeholder,
  .e-small input.e-input#{$css}:-moz-placeholder,
  input.e-small.e-input#{$css}:-moz-placeholder,
  .e-small input.e-input#{$css}:-ms-input-placeholder,
  input.e-small.e-input#{$css}:-ms-input-placeholder,
  .e-small input.e-input#{$css}::-moz-placeholder,
  input.e-small.e-input#{$css}::-moz-placeholder,
  .e-small textarea.e-input#{$css}::-webkit-input-placeholder,
  textarea.e-small.e-input#{$css}::-webkit-input-placeholder,
  .e-small textarea.e-input#{$css}:-moz-placeholder,
  textarea.e-small.e-input#{$css}:-moz-placeholder,
  .e-small textarea.e-input#{$css}:-ms-input-placeholder,
  textarea.e-small.e-input#{$css}:-ms-input-placeholder,
  .e-small textarea.e-input#{$css}::-moz-placeholder,
  textarea.e-small.e-input#{$css}::-moz-placeholder,
  .e-small textarea.e-input#{$css}::-webkit-textarea-placeholder,
  textarea.e-small.e-input#{$css}::-webkit-textarea-placeholder,
  .e-small textarea.e-input#{$css}:-moz-placeholder,
  textarea.e-small.e-input#{$css}:-moz-placeholder,
  .e-small textarea.e-input#{$css}:-ms-input-placeholder,
  textarea.e-small.e-input#{$css}:-ms-input-placeholder,
  .e-small textarea.e-input#{$css}::-moz-placeholder,
  textarea.e-small.e-input#{$css}::-moz-placeholder {
    font-size: $input-small-font-size;
    font-style: $input-font-style;
  }

  input.e-input#{$css}:-moz-placeholder,
  textarea.e-input#{$css}:-moz-placeholder,
  .e-input-group input.e-input:-moz-placeholder,
  .e-input-group textarea.e-input:-moz-placeholder,
  .e-input-group.e-control-wrapper input.e-input:-moz-placeholder,
  .e-input-group.e-control-wrapper textarea.e-input:-moz-placeholder,
  input.e-input#{$css}:-moz-placeholder,
  .e-input-group input.e-input:-moz-placeholder,
  .e-input-group.e-control-wrapper input.e-input:-moz-placeholder,
  textarea.e-input#{$css}:-moz-placeholder,
  input.e-input#{$css}::-moz-placeholder,
  textarea.e-input#{$css}::-moz-placeholder,
  input.e-input#{$css}::-webkit-input-placeholder,
  textarea.e-input#{$css}::-webkit-input-placeholder {
    font-style: $input-font-style;
    user-select: none;
  }

  input.e-input#{$css}:-ms-input-placeholder,
  textarea.e-input#{$css}:-ms-input-placeholder {
    font-style: $input-font-style;
  }

  input.e-input#{$css},
  .e-input-group input,
  .e-input-group.e-control-wrapper input,
  .e-input-group input.e-input,
  .e-input-group.e-control-wrapper input.e-input {
    @include input-sizing;
    @include input-height ($input-normal-height);
  }

  .e-float-input:not(.e-input-group) input,
  .e-float-input.e-control-wrapper:not(.e-input-group) input {
    @include input-sizing;
    @include input-height ($float-input-normal-height);
  }

  .e-control input.e-input,
  .e-control .e-input-group input,
  .e-control .e-input-group input.e-input,
  .e-control .e-input-group.e-control-wrapper input,
  .e-control .e-input-group.e-control-wrapper input.e-input,
  .e-control .e-float-input input,
  .e-control .e-float-input.e-control-wrapper input,
  .e-control.e-input-group input,
  .e-control.e-input-group input.e-input,
  .e-control.e-input-group.e-control-wrapper input,
  .e-control.e-input-group.e-control-wrapper input.e-input,
  .e-control.e-float-input input,
  .e-control.e-float-input.e-control-wrapper input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      @include input-sizing;
    }
  }

  input.e-input.e-small#{$css},
  .e-input-group.e-small input,
  .e-input-group.e-small input.e-input,
  .e-small .e-input-group input,
  .e-small .e-input-group input.e-input,
  .e-input-group.e-control-wrapper.e-small input,
  .e-input-group.e-control-wrapper.e-small input.e-input,
  .e-small .e-input-group.e-control-wrapper input,
  .e-small .e-input-group.e-control-wrapper input.e-input,
  .e-float-input.e-small input,
  .e-float-input.e-small input.e-input,
  .e-small .e-float-input input,
  .e-small .e-float-input input.e-input,
  .e-float-input.e-control-wrapper.e-small input,
  .e-float-input.e-control-wrapper.e-small input.e-input,
  .e-small .e-float-input.e-control-wrapper input,
  .e-small .e-float-input.e-control-wrapper input.e-input {
    @include input-sizing;
    @include input-height ($input-small-height);
  }

  .e-float-input.e-small:not(.e-input-group) input,
  .e-float-input.e-small:not(.e-input-group) input.e-input,
  .e-small .e-float-input:not(.e-input-group) input,
  .e-small .e-float-input:not(.e-input-group) input.e-input .e-float-input.e-control-wrapper.e-small:not(.e-input-group) input,
  .e-float-input.e-control-wrapper.e-small:not(.e-input-group) input.e-input,
  .e-small .e-float-input.e-control-wrapper:not(.e-input-group) input,
  .e-small .e-float-input.e-control-wrapper:not(.e-input-group) input.e-input  {
    @include input-sizing;
    @include input-height ($float-input-small-height);
  }

  textarea.e-input#{$css},
  .e-input-group textarea,
  .e-input-group.e-control-wrapper textarea,
  .e-float-input textarea,
  .e-float-input.e-control-wrapper textarea {
    @include input-sizing;
    @include input-height ($textarea-normal-height);
  }

  textarea.e-input.e-small#{$css},
  .e-input-group.e-small textarea,
  .e-input-group.e-small textarea.e-input,
  .e-small .e-input-group textarea,
  .e-small .e-input-group textarea.e-input,
  .e-input-group.e-control-wrapper.e-small textarea,
  .e-input-group.e-control-wrapper.e-small textarea.e-input,
  .e-small .e-input-group.e-control-wrapper textarea,
  .e-small .e-input-group.e-control-wrapper textarea.e-input,
  .e-float-input.e-small textarea,
  .e-float-input.e-small textarea.e-input,
  .e-small .e-float-input textarea,
  .e-small .e-float-input textarea.e-input,
  .e-float-input.e-control-wrapper.e-small textarea,
  .e-float-input.e-control-wrapper.e-small textarea.e-input,
  .e-small .e-float-input.e-control-wrapper textarea,
  .e-small .e-float-input.e-control-wrapper textarea.e-input {
    @include input-sizing;
    @include input-height ($textarea-small-height);
  }

  input.e-input#{$css}.e-small,
  .e-input-group input.e-input.e-small,
  .e-input-group.e-control-wrapper input.e-input.e-small,
  .e-input-group.e-small .e-input,
  .e-input-group.e-control-wrapper.e-small .e-input,
  .e-small input.e-input#{$css},
  .e-small .e-input-group .e-input,
  .e-small .e-input-group.e-control-wrapper .e-input,
  .e-float-input.e-small input,
  .e-float-input input.e-small,
  .e-small .e-float-input input,
  .e-float-input.e-control-wrapper.e-small input,
  .e-float-input.e-control-wrapper input.e-small,
  .e-small .e-float-input.e-control-wrapper input,
  textarea.e-input#{$css}.e-small,
  .e-input-group textarea.e-input.e-small,
  .e-input-group.e-control-wrapper input.e-input-group textarea.e-input.e-small,
  .e-small input.e-input#{$css},
  .e-float-input.e-small textarea,
  .e-float-input textarea.e-small,
  .e-small .e-float-input textarea,
  .e-float-input.e-control-wrapper.e-small textarea,
  .e-float-input.e-control-wrapper textarea.e-small,
  .e-small .e-float-input.e-control-wrapper textarea {
    text-indent: $input-small-text-indent;
  }

  input.e-input#{$css},
  .e-input-group input.e-input,
  .e-input-group input,
  .e-input-group.e-control-wrapper input.e-input,
  .e-input-group.e-control-wrapper input,
  .e-float-input input.e-input,
  .e-float-input input,
  .e-float-input.e-control-wrapper input.e-input,
  .e-float-input.e-control-wrapper input,
  .e-input-group input.e-input:focus,
  .e-input-group.e-control-wrapper input.e-input:focus,
  .e-float-input.e-control-wrapper input:focus,
  .e-float-input input:focus,
  .e-input-group.e-input-focus input.e-input,
  .e-input-group.e-control-wrapper.e-input-focus input.e-input,
  .e-float-input.e-control-wrapper.e-input-focus input,
  .e-float-input.e-input-focus input {
    padding-left: $input-text-indent;
    text-indent: 0;
  }

  textarea.e-input#{$css},
  .e-input-group textarea.e-input,
  .e-input-group textarea,
  .e-input-group.e-control-wrapper textarea.e-input,
  .e-input-group.e-control-wrapper textarea,
  .e-float-input textarea.e-input,
  .e-float-input textarea,
  .e-float-input.e-control-wrapper textarea.e-input,
  .e-float-input.e-control-wrapper textarea,
  .e-input-group textarea.e-input:focus,
  .e-input-group.e-control-wrapper textarea.e-input:focus,
  .e-float-input.e-control-wrapper textarea:focus,
  .e-float-input textarea:focus {
    @if $input-skin-name == 'fluent2' {
      padding-left: 12px;
    }
    @else {
      padding-left: $input-text-indent;
    }
    text-indent: 0;
  }

  input.e-input.e-rtl#{$css},
  .e-input-group.e-rtl input.e-input,
  .e-input-group.e-control-container.e-rtl input.e-control,
  .e-input-group.e-control-wrapper.e-rtl input.e-input,
  .e-float-input.e-rtl input,
  .e-float-input.e-control-wrapper.e-rtl input,
  .e-rtl .e-input-group input.e-input,
  .e-rtl .e-input-group.e-control-wrapper input.e-input,
  .e-rtl .e-float-input input,
  .e-rtl .e-float-input.e-control-wrapper input,
  .e-input-group.e-rtl input.e-input,
  .e-input-group.e-control-wrapper.e-rtl input.e-input,
  .e-float-input.e-rtl input,
  .e-float-input.e-control-wrapper.e-rtl input,
  .e-rtl .e-input-group input.e-input,
  .e-rtl .e-input-group.e-control-wrapper input.e-input,
  .e-rtl .e-float-input input,
  .e-rtl .e-float-input.e-control-wrapper input,
  .e-input-group.e-rtl input.e-input:focus,
  .e-input-group.e-control-wrapper.e-rtl input.e-input:focus,
  .e-float-input.e-rtl input:focus,
  .e-float-input.e-control-wrapper.e-rtl input:focus,
  .e-rtl .e-input-group input.e-input:focus,
  .e-rtl .e-input-group.e-control-wrapper input.e-input:focus,
  .e-rtl .e-float-input input:focus,
  .e-rtl .e-float-input.e-control-wrapper input:focus,
  .e-input-group.e-rtl.e-input-focus input.e-input,
  .e-input-group.e-control-wrapper.e-rtl.e-input-focus input.e-input,
  .e-rtl .e-input-group.e-input-focus input.e-input,
  .e-rtl .e-input-group.e-control-wrapper.e-input-focus input.e-input,
  .e-float-input.e-rtl.e-input-focus input,
  .e-float-input.e-control-wrapper.e-rtl.e-input-focus input,
  .e-rtl .e-float-input.e-input-focus input,
  .e-rtl .e-float-input.e-control-wrapper.e-input-focus input {
    padding-left: 0;
    padding-right: $input-text-indent;
    text-indent: 0;
  }

  textarea.e-input.e-rtl#{$css},
  .e-input-group:not(.e-outline).e-rtl textarea.e-input,
  .e-input-group:not(.e-outline).e-control-wrapper.e-rtl textarea.e-input,
  .e-float-input:not(.e-outline).e-rtl textarea,
  .e-float-input:not(.e-outline).e-control-wrapper.e-rtl textarea,
  .e-rtl .e-input-group:not(.e-outline) textarea.e-input,
  .e-rtl .e-input-group:not(.e-outline).e-control-wrapper textarea.e-input,
  .e-rtl .e-float-input:not(.e-outline) textarea,
  .e-rtl .e-float-input:not(.e-outline).e-control-wrapper textarea,
  .e-input-group:not(.e-outline).e-rtl textarea.e-input,
  .e-input-group:not(.e-outline).e-control-wrapper.e-rtl textarea.e-input,
  .e-float-input:not(.e-outline).e-rtl textarea,
  .e-float-input:not(.e-outline).e-control-wrapper.e-rtl textarea,
  .e-rtl .e-input-group:not(.e-outline) textarea.e-input,
  .e-rtl .e-input-group:not(.e-outline).e-control-wrapper textarea.e-input,
  .e-rtl .e-float-input:not(.e-outline) textarea,
  .e-rtl .e-float-input:not(.e-outline).e-control-wrapper textarea,
  .e-input-group:not(.e-outline).e-rtl textarea.e-input:focus,
  .e-input-group:not(.e-outline).e-control-wrapper.e-rtl textarea.e-input:focus,
  .e-float-input:not(.e-outline).e-rtl textarea:focus,
  .e-float-input:not(.e-outline).e-control-wrapper.e-rtl textarea:focus,
  .e-rtl .e-input-group:not(.e-outline) textarea.e-input:focus,
  .e-rtl .e-input-group:not(.e-outline).e-control-wrapper textarea.e-input:focus,
  .e-rtl .e-float-input:not(.e-outline) textarea:focus,
  .e-rtl .e-float-input:not(.e-outline).e-control-wrapper textarea:focus {
    padding-right: $input-text-indent;
    text-indent: 0;
  }

  input.e-input.e-small#{$css},
  .e-small input.e-input#{$css},
  .e-input-group.e-small input.e-input,
  .e-input-group.e-control-wrapper.e-small input.e-input,
  .e-float-input.e-small input,
  .e-float-input.e-control-wrapper input.e-small,
  .e-float-input.e-small input,
  .e-float-input.e-control-wrapper input.e-small,
  .e-input-group input.e-input.e-small,
  .e-input-group.e-control-wrapper input.e-input.e-small,
  .e-small .e-float-input input,
  .e-small .e-float-input.e-control-wrapper input,
  .e-small .e-input-group input.e-input,
  .e-small .e-input-group.e-control-wrapper input.e-input,
  .e-input-group.e-small input.e-input:focus,
  .e-input-group.e-control-wrapper.e-small input.e-input:focus,
  .e-float-input.e-small input:focus,
  .e-float-input.e-control-wrapper.e-small input:focus,
  .e-small .e-input-group.e-control-wrapper input.e-input:focus,
  .e-small .e-input-group input.e-input:focus,
  .e-small .e-float-input input:focus,
  .e-small .e-float-input.e-control-wrapper input:focus,
  .e-input-group.e-small.e-input-focus input.e-input,
  .e-input-group.e-control-wrapper.e-small.e-input-focus input.e-input,
  .e-small .e-input-group.e-control-wrapper.e-input-focus input.e-input,
  .e-small .e-input-group.e-input-focus input.e-input,
  .e-float-input.e-small.e-input-focus input,
  .e-float-input.e-control-wrapper.e-input-focus.e-small input,
  .e-small .e-float-input.e-input-focus input,
  .e-small .e-float-input.e-control-wrapper.e-input-focus input,
  textarea.e-input.e-small#{$css},
  .e-small textarea.e-input#{$css},
  .e-input-group.e-small textarea.e-input,
  .e-input-group.e-control-wrapper.e-small textarea.e-input,
  .e-float-input.e-control-wrapper.e-small textarea,
  .e-float-input.e-control-wrapper textarea.e-small,
  .e-float-input.e-small textarea,
  .e-float-input textarea.e-small,
  .e-input-group textarea.e-input.e-small,
  .e-input-group.e-control-wrapper textarea.e-input.e-small,
  .e-small .e-float-input.e-control-wrapper textarea,
  .e-small .e-float-input textarea,
  .e-small .e-input-group textarea.e-input,
  .e-small .e-input-group.e-control-wrapper textarea.e-input,
  .e-input-group.e-small textarea.e-input:focus,
  .e-input-group.e-control-wrapper.e-small textarea.e-input:focus,
  .e-float-input.e-small textarea:focus,
  .e-float-input.e-control-wrapper.e-small textarea:focus,
  .e-small .e-input-group textarea.e-input:focus,
  .e-small .e-input-group.e-control-wrapper textarea.e-input:focus,
  .e-small .e-float-input.e-control-wrapper textarea:focus,
  .e-small .e-float-input textarea:focus {
    padding-left: $input-small-text-indent;
    text-indent: 0;
  }

  .e-rtl input.e-input.e-small#{$css},
  input.e-input#{$css}.e-small.e-rtl,
  .e-small.e-rtl input.e-input#{$css},
  .e-small input.e-input.e-rtl#{$css},
  .e-float-input.e-control-wrapper.e-small.e-rtl input,
  .e-float-input.e-small.e-rtl input,
  .e-input-group.e-small.e-rtl input.e-input,
  .e-input-group.e-control-wrapper.e-small.e-rtl input.e-input,
  .e-rtl .e-float-input.e-small input,
  .e-rtl .e-float-input.e-control-wrapper.e-small input,
  .e-rtl .e-input-group.e-small input.e-input,
  .e-rtl .e-input-group.e-control-wrapper.e-small input.e-input,
  .e-float-input.e-rtl input.e-small,
  .e-float-input.e-control-wrapper.e-rtl input.e-small,
  .e-input-group.e-rtl input.e-input.e-small,
  .e-input-group.e-control-wrapper.e-rtl input.e-input.e-small,
  .e-rtl .e-float-input input.e-small,
  .e-rtl .e-float-input.e-control-wrapper input.e-small,
  .e-rtl .e-input-group input.e-input.e-small,
  .e-rtl .e-input-group.e-control-wrapper input.e-input.e-small,
  .e-small .e-float-input.e-rtl input,
  .e-small .e-float-input.e-control-wrapper.e-rtl input,
  .e-small .e-input-group.e-rtl input.e-input,
  .e-small .e-input-group.e-control-wrapper.e-rtl input.e-input,
  .e-small.e-rtl .e-float-input.e-control-wrapper input,
  .e-small.e-rtl .e-float-input input,
  .e-small.e-rtl .e-input-group.e-control-wrapper input.e-input,
  .e-small.e-rtl .e-input-group input.e-input,
  .e-small.e-rtl .e-input-group.e-control-wrapper input.e-input:focus,
  .e-small.e-rtl .e-input-group input.e-input:focus,
  .e-small.e-rtl .e-float-input.e-control-wrapper input:focus,
  .e-small.e-rtl .e-float-input input:focus,
  .e-small .e-input-group.e-control-wrapper.e-rtl input.e-input:focus,
  .e-small .e-input-group.e-rtl input.e-input:focus,
  .e-small .e-float-input.e-control-wrapper.e-rtl input:focus,
  .e-small .e-float-input.e-rtl input:focus,
  .e-small.e-rtl .e-input-group.e-control-wrapper.e-input-focus input.e-input,
  .e-small.e-rtl .e-input-group.e-input-focus input.e-input,
  .e-small .e-input-group.e-control-wrapper.e-rtl.e-input-focus input.e-input,
  .e-small .e-input-group.e-rtl.e-input-focus input.e-input,
  .e-small.e-rtl .e-float-input.e-control-wrapper.e-input-focus input,
  .e-small.e-rtl .e-float-input.e-input-focus input,
  .e-small .e-float-input.e-control-wrapper.e-rtl.e-input-focus input,
  .e-small .e-float-input.e-rtl.e-input-focus input {
    padding-left: 0;
    padding-right: $input-small-text-indent;
    text-indent: 0;
  }

  .e-rtl textarea.e-input.e-small#{$css},
  textarea.e-input.e-small.e-rtl#{$css},
  .e-small.e-rtl textarea.e-input#{$css},
  .e-small textarea.e-input.e-rtl#{$css},
  .e-float-input:not(.e-outline).e-small.e-rtl textarea,
  .e-float-input:not(.e-outline).e-control-wrapper.e-small.e-rtl textarea,
  .e-input-group:not(.e-outline).e-small.e-rtl textarea.e-input,
  .e-input-group:not(.e-outline).e-control-wrapper.e-small.e-rtl textarea.e-input,
  .e-rtl .e-float-input:not(.e-outline).e-control-wrapper.e-small textarea,
  .e-rtl .e-float-input:not(.e-outline).e-small textarea,
  .e-rtl .e-input-group:not(.e-outline).e-small textarea.e-input,
  .e-rtl .e-input-group:not(.e-outline).e-control-wrapper.e-small textarea.e-input,
  .e-float-input:not(.e-outline).e-control-wrapper.e-rtl textarea.e-small,
  .e-float-input:not(.e-outline).e-rtl textarea.e-small,
  .e-input-group:not(.e-outline).e-rtl textarea.e-input.e-small,
  .e-input-group:not(.e-outline).e-control-wrapper.e-rtl textarea.e-input.e-small,
  .e-rtl .e-float-input:not(.e-outline).e-control-wrapper textarea.e-small,
  .e-rtl .e-float-input:not(.e-outline) textarea.e-small,
  .e-rtl .e-input-group:not(.e-outline) textarea.e-input.e-small,
  .e-rtl .e-input-group:not(.e-outline).e-control-wrapper textarea.e-input.e-small,
  .e-small .e-float-input:not(.e-outline).e-control-wrapper.e-rtl textarea,
  .e-small .e-float-input:not(.e-outline).e-rtl textarea,
  .e-small .e-input-group:not(.e-outline).e-rtl textarea.e-input,
  .e-small .e-input-group:not(.e-outline).e-control-wrapper.e-rtl textarea.e-input,
  .e-small.e-rtl .e-float-input:not(.e-outline).e-control-wrapper textarea,
  .e-small.e-rtl .e-float-input:not(.e-outline) textarea,
  .e-small.e-rtl .e-input-group:not(.e-outline) textarea.e-input,
  .e-small.e-rtl .e-input-group:not(.e-outline).e-control-wrapper textarea.e-input,
  .e-small.e-rtl .e-input-group:not(.e-outline) textarea.e-input:focus,
  .e-small.e-rtl .e-input-group:not(.e-outline).e-control-wrapper textarea.e-input:focus,
  .e-small.e-rtl .e-float-input:not(.e-outline).e-control-wrapper textarea:focus,
  .e-small.e-rtl .e-float-input:not(.e-outline) textarea:focus,
  .e-small .e-input-group:not(.e-outline).e-rtl textarea.e-input:focus,
  .e-small .e-input-group:not(.e-outline).e-control-wrapper.e-rtl textarea.e-input:focus,
  .e-small .e-float-input:not(.e-outline).e-control-wrapper.e-rtl textarea:focus,
  .e-small .e-float-input:not(.e-outline).e-rtl textarea:focus {
    padding-right: $input-small-text-indent;
    text-indent: 0;
  }
  // Clear-icon support
  .e-float-input .e-clear-icon,
  .e-float-input.e-control-wrapper .e-clear-icon,
  .e-input-group .e-clear-icon,
  .e-input-group.e-control-wrapper .e-clear-icon {
    background: transparent;
    border: $zero-value;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: $input-clear-icon-position;
    line-height: 1;
    min-width: 24px;
    outline: none;
    padding: $input-clear-icon-padding;
    text-align: center;
  }

  .e-float-input .e-clear-icon::before,
  .e-float-input.e-control-wrapper .e-clear-icon::before,
  .e-input-group .e-clear-icon::before,
  .e-input-group.e-control-wrapper .e-clear-icon::before {
    font-size: $input-clear-icon;
    padding: $zero-value;
    text-align: center;
    vertical-align: middle;
  }

  .e-float-input.e-static-clear .e-clear-icon.e-clear-icon-hide,
  .e-float-input.e-control-wrapper.e-static-clear .e-clear-icon.e-clear-icon-hide,
  .e-input-group.e-static-clear .e-clear-icon.e-clear-icon-hide,
  .e-input-group.e-control-wrapper.e-static-clear .e-clear-icon.e-clear-icon-hide {
    cursor: pointer;
    display: flex;
  }

  .e-float-input.e-small .e-clear-icon::before,
  .e-float-input.e-control-wrapper.e-small .e-clear-icon::before,
  .e-input-group.e-small .e-clear-icon::before,
  .e-input-group.e-control-wrapper.e-small .e-clear-icon::before,
  .e-float-input.e-control-wrapper input.e-small:first-child ~ .e-clear-icon::before,
  .e-small .e-float-input.e-control-wrapper .e-clear-icon::before,
  .e-float-input input.e-small:first-child ~ .e-clear-icon::before,
  .e-small .e-float-input .e-clear-icon::before,
  .e-small .e-input-group .e-clear-icon::before,
  .e-small .e-input-group.e-control-wrapper .e-clear-icon::before {
    font-size: $input-small-clear-icon;
  }

  .e-input-group.e-static-clear .e-clear-icon.e-clear-icon-hide,
  .e-input-group.e-control-wrapper.e-static-clear .e-clear-icon.e-clear-icon-hide {
    cursor: pointer;
  }

  .e-float-input.e-control-wrapper input[readonly]:first-child ~ .e-clear-icon,
  .e-float-input.e-control-wrapper.e-input-group input[readonly]:first-child ~ .e-clear-icon,
  .e-float-input input[readonly]:first-child ~ .e-clear-icon,
  .e-float-input.e-input-group input[readonly]:first-child ~ .e-clear-icon,
  .e-input-group input[readonly]:first-child ~ .e-clear-icon.e-clear-icon-hide,
  .e-float-input.e-control-wrapper.e-input-group input[readonly]:first-child ~ .e-clear-icon,
  .e-float-input.e-input-group.e-control-wrapper input[readonly]:first-child ~ .e-clear-icon,
  .e-input-group.e-control-wrapper input[readonly]:first-child .e-clear-icon.e-clear-icon-hide  {
    cursor: auto;
  }

  .e-input-group .e-clear-icon,
  .e-input-group.e-control-wrapper .e-clear-icon {
    min-height: $input-clear-icon-min-height;
    min-width: $input-clear-icon-min-width;
    @if $input-skin-name != 'Material3' and $input-skin-name != 'fluent2' {
      padding-bottom: $input-clear-icon-padding-bottom;
      padding-left: $input-clear-icon-padding-left;
      padding-right: $input-clear-icon-padding-right;
      padding-top: $input-clear-icon-padding-top;
    }
    @else {
      padding: $input-clear-icon-padding;
      margin: $input-clear-icon-margin;
      border-radius: $input-clear-icon-hover-border-radius;
    }
  }

  .e-float-input.e-input-group .e-clear-icon,
  .e-float-input.e-input-group.e-control-wrapper .e-clear-icon {
    @if $input-skin-name != 'Material3' and $input-skin-name != 'fluent2' and $input-skin-name != 'tailwind3' {
      padding-right: $input-clear-icon-padding-right;
      padding-top: $float-input-clear-icon-padding-top;
    }
  }

  .e-input-group.e-small .e-clear-icon,
  .e-input-group .e-clear-icon.e-small,
  .e-input-group.e-control-wrapper.e-small .e-clear-icon,
  .e-input-group.e-control-wrapper .e-clear-icon.e-small {
    @if $input-skin-name != 'Material3' and $input-skin-name != 'fluent2' {
      min-height: $input-bigger-clear-icon-min-height;
      min-width: $input-bigger-clear-icon-min-width;
      padding-bottom: $input-clear-icon-bigger-padding-bottom;
      padding-right: $input-clear-icon-bigger-padding-right;
      padding-top: $input-clear-icon-bigger-padding-top;
    }
    @else {
      min-height: $input-bigger-small-clear-icon-min-height;
      min-width: $input-bigger-small-clear-icon-min-width;
      padding: $input-clear-icon-padding;
      margin: $input-clear-icon-margin;
      border-radius: $input-bigger-clear-icon-border-radius;
    }
  }

  .e-input-group.e-small .e-clear-icon,
  .e-input-group .e-clear-icon.e-small,
  .e-small .e-input-group .e-clear-icon,
  .e-input-group.e-control-wrapper.e-small .e-clear-icon,
  .e-input-group.e-control-wrapper .e-clear-icon.e-small,
  .e-small .e-input-group.e-control-wrapper .e-clear-icon {
    min-height: $input-small-clear-icon-min-height;
    min-width: $input-small-clear-icon-min-width;
    @if $input-skin-name != 'Material3' and $input-skin-name != 'fluent2' {
      padding-bottom: $input-clear-icon-small-padding-bottom;
      padding-right: $input-clear-icon-small-padding-right;
      padding-top: $input-clear-icon-small-padding-top;
    }
    @else {
      padding: $input-clear-icon-padding;
      margin: $input-clear-icon-margin;
      border-radius: $input-small-clear-icon-border-radius;
    }
  }

  .e-input-group.e-float-input.e-small .e-clear-icon,
  .e-input-group.e-float-input .e-clear-icon.e-small,
  .e-small .e-input-group.e-float-input .e-clear-icon,
  .e-input-group.e-control-wrapper.e-float-input.e-small .e-clear-icon,
  .e-input-group.e-control-wrapper.e-float-input .e-clear-icon.e-small,
  .e-small .e-input-group.e-control-wrapper.e-float-input .e-clear-icon,
  .e-input-group.e-float-input.e-control-wrapper.e-small .e-clear-icon,
  .e-input-group.e-float-input.e-control-wrapper .e-clear-icon.e-small,
  .e-small .e-input-group.e-float-input.e-control-wrapper .e-clear-icon,
  .e-input-group.e-control-wrapper.e-float-input.e-small .e-clear-icon,
  .e-input-group.e-control-wrapper.e-float-input .e-clear-icon.e-small,
  .e-small .e-input-group.e-control-wrapper.e-float-input .e-clear-icon {
    @if $input-skin-name != 'Material3' and $input-skin-name != 'fluent2' and $input-skin-name != 'tailwind3' {
      padding-right: $input-clear-icon-small-padding-right;
      padding-top: $float-input-clear-icon-small-padding-top;
    }
  }

  .e-input#{$css}:not(:valid),
  .e-input#{$css}:valid,
  .e-float-input.e-control-wrapper input:not(:valid),
  .e-float-input.e-control-wrapper input:valid,
  .e-float-input input:not(:valid),
  .e-float-input input:valid,
  .e-input-group input:not(:valid),
  .e-input-group input:valid,
  .e-input-group.e-control-wrapper input:not(:valid),
  .e-input-group.e-control-wrapper input:valid,
  .e-float-input.e-control-wrapper textarea:not(:valid),
  .e-float-input.e-control-wrapper textarea:valid,
  .e-float-input textarea:not(:valid),
  .e-float-input textarea:valid,
  .e-input-group.e-control-wrapper textarea:not(:valid),
  .e-input-group.e-control-wrapper textarea:valid,
  .e-input-group textarea:not(:valid),
  .e-input-group textarea:valid {
    box-shadow: none;
  }

  .e-input#{$css}:not(:valid),
  .e-input#{$css}:valid,
  .e-float-input input:valid,
  .e-input-group input:valid {
    @if $input-skin-name == 'fluent2' {
      border-bottom-color: $input-box-border-color;
      border-top-color: $border;
      border-left-color: $border;
      border-right-color: $border;
      border-radius: 4px;
      height: 32px;
    }
  }

  .e-input-group .e-input-in-wrap,
  .e-input-group.e-control-wrapper .e-input-in-wrap,
  .e-float-input .e-input-in-wrap,
  .e-float-input.e-control-wrapper .e-input-in-wrap {
    width: 100%;
    @if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'highcontrast' or $input-skin-name == 'tailwind' or $input-skin-name == 'tailwind3' {
      display: flex;
      position: relative;
    }
  }

  .e-float-input .e-input-in-wrap label.e-float-text,
  .e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text {
    right: 0;
    @if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'highcontrast' or $input-skin-name == 'tailwind' or $input-skin-name == 'tailwind3' {
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
      white-space: nowrap;
    }
  }

  .e-input-group .e-input-in-wrap,
  .e-input-group.e-control-wrapper .e-input-in-wrap,
  .e-float-input .e-input-in-wrap,
  .e-float-input.e-control-wrapper .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      display: flex;
      position: relative;
      width: 100%;
    }
  }

  .e-float-input.e-float-icon-left .e-input-in-wrap,
  .e-float-input.e-control-wrapper.e-float-icon-left .e-input-in-wrap,
  .e-input-group.e-float-icon-left .e-input-in-wrap,
  .e-input-group.e-control-wrapper.e-float-icon-left .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border: $input-group-full-border;
      border-width: $input-group-full-border-width;
      margin-left: $input-inner-wrap-margin-left;
    }
    @if $input-skin-name == 'fluent2' {
      border-width: $input-group-full-border-width;
      margin-left: $input-inner-wrap-margin-left;
    }
  }

  .e-rtl .e-float-input.e-float-icon-left .e-input-in-wrap,
  .e-rtl .e-float-input.e-control-wrapper.e-float-icon-left .e-input-in-wrap,
  .e-rtl .e-input-group.e-float-icon-left .e-input-in-wrap,
  .e-rtl .e-input-group.e-control-wrapper.e-float-icon-left .e-input-in-wrap .e-float-input.e-control-wrapper.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-float-input.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-input-group.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-input-group.e-control-wrapper.e-float-icon-left.e-rtl .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      margin-left: $zero-value;
      margin-right: $input-inner-wrap-margin-left;
    }
  }

  .e-float-input label.e-float-text.e-label-bottom,
  .e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      transform: translate3d(0, 16px, 0) scale(1);
    }
    @if $input-skin-name == 'fluent2' {
      transform: translate3d(0, 16px, 0) scale(1);
      padding-left: 7px;
    }
  }

  .e-float-input textarea:focus ~ label.e-float-text,
  .e-float-input textarea:valid ~ label.e-float-text,
  .e-float-input textarea ~ label.e-label-top.e-float-text,
  .e-float-input textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input textarea label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper textarea:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper textarea label.e-float-text.e-label-top {
    font-size: $float-label-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      top: $textarea-float-top;
      transform: translate3d(0, 6px, 0) scale(.92);
      @if $input-skin-name == 'tailwind3' {
        left: 2px;
      }
    }
    @else if $input-skin-name == 'fabric' or  $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      top: 15px;
      transform: translate3d(-10px, -40px, 0) scale(1);
    }
    @else if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' {
      padding-right: 0;
      transform: translate3d(-10px, -37px, 0) scale(1);
    }
    @else if $input-skin-name == 'tailwind' {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -33px, 0) scale(1);
    }
    @else if ($input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -36px, 0) scale(1);
    }
    @else if ($input-skin-name == 'FluentUI') {
      font-style: $input-font-normal;
      font-weight: 600;
      padding-right: 0;
      transform: translate3d(-10px, -41px, 0) scale(1);
    }
  }

  .e-float-input.e-small textarea:focus ~ label.e-float-text,
  .e-float-input.e-small textarea:valid ~ label.e-float-text,
  .e-float-input.e-small textarea ~ label.e-label-top.e-float-text,
  .e-small .e-float-input textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small textarea:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small textarea ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-control-wrapper textarea ~ label.e-label-top.e-float-text {
    font-size: $float-label-small-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -21px;
    }
    @if $input-skin-name == 'tailwind3' {
      top: -21px;
      left: 2px;
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'highcontrast' or $input-skin-name == 'tailwind' or $input-skin-name == 'tailwind3' {
      top: 16px;
    }
  }

  .e-float-input textarea ~ .e-float-text,
  .e-float-input.e-control-wrapper textarea ~ .e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -11px;
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'highcontrast' {
      top: 15px;
    }
    @else if $input-skin-name == 'tailwind' or $input-skin-name == 'tailwind3' {
      top: 13px;
    }
  }

  .e-float-input.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    font-size: $float-placeholder-small-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -11px;
    }
  }

  .e-float-input.e-small textarea ~ label.e-float-text,
  .e-float-input textarea ~ label.e-float-text.e-small,
  .e-float-input textarea.e-small ~ label.e-float-text,
  .e-small .e-float-input textarea ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small textarea ~ label.e-float-text,
  .e-float-input.e-control-wrapper textarea ~ label.e-float-text.e-small,
  .e-float-input.e-control-wrapper textarea.e-small ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper textarea ~ label.e-float-text {
    font-size: $float-placeholder-small-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -11px;
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'highcontrast' or $input-skin-name == 'tailwind' or $input-skin-name == 'tailwind3' {
      top: 15px;
    }
  }

  .e-input-group.e-small:not(.e-float-input) .e-input,
  .e-small .e-input-group:not(.e-float-input) .e-input,
  .e-input-group.e-control-wrapper.e-small:not(.e-float-input) .e-input,
  .e-small .e-input-group.e-control-wrapper:not(.e-float-input) .e-input,
  .e-float-input.e-small input,
  .e-small .e-float-input input,
  .e-float-input.e-input-group.e-small input,
  .e-small .e-float-input.e-input-group input,
  .e-float-input.e-input-group.e-control-wrapper.e-small input,
  .e-small .e-float-input.e-input-group.e-control-wrapper input,
  .e-float-input.e-control-wrapper.e-small input,
  .e-small .e-float-input.e-control-wrapper input,
  .e-float-input.e-control-wrapper.e-input-group.e-small input,
  .e-small .e-float-input.e-control-wrapper.e-input-group input,
  .e-float-input.e-control-wrapper.e-input-group.e-small input,
  .e-small .e-float-input.e-control-wrapper.e-input-group input {
    @if $input-skin-name == 'material' {
      min-height: 16px;
    }
    @if $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      min-height: 14px;
    }
  }

  .e-input-group input.e-input,
  .e-float-input.e-input-group input,
  .e-input-group.e-control-wrapper input.e-input,
  .e-float-input.e-input-group.e-control-wrapper input,
  .e-float-input input,
  .e-float-input.e-control-wrapper input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      min-height: 22px;
    }
  }

  .e-input-group:hover:not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-control-wrapper:hover:not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-float-input:hover:not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-float-input:hover:not(.e-input-group):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) input:not([disabled]),
  .e-float-input:hover:not(.e-input-group):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) textarea:not([disabled]),
  .e-float-input:hover:not(.e-input-group):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) input:not([disabled]),
  .e-float-input:hover:not(.e-input-group):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) textarea:not([disabled]),
  .e-float-input.e-control-wrapper:hover:not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) input:not([disabled]),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) textarea:not([disabled]),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) input:not([disabled]),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) textarea:not([disabled]) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-bottom-width: $input-group-border-width-hover;
    }
    @else if $input-skin-name == 'fluent2' {
      border-bottom-width: $input-group-border-width-hover;
      border-color: $input-group-border-color-hover;
    }
  }

  .e-input-group:hover:not(.e-disabled),
  .e-input-group.e-control-wrapper:hover:not(.e-disabled),
  .e-float-input:hover:not(.e-disabled),
  .e-float-input:hover:not(.e-input-group):not(.e-disabled) input:not([disabled]),
  .e-float-input:hover:not(.e-input-group):not(.e-disabled) textarea:not([disabled]),
  .e-float-input:hover:not(.e-input-group):not(.e-disabled) input:not([disabled]),
  .e-float-input:hover:not(.e-input-group):not(.e-disabled) textarea:not([disabled]),
  .e-float-input.e-control-wrapper:hover:not(.e-disabled),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-disabled) input:not([disabled]),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-disabled) textarea:not([disabled]),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-disabled) input:not([disabled]),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-disabled) textarea:not([disabled]) {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' {
      border-bottom-width: $input-group-border-width-hover;
    }
  }

  .e-input-group.e-float-icon-left:not(.e-disabled):not(.e-input-focus) .e-input-in-wrap:hover,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-disabled):not(.e-input-focus) .e-input-in-wrap:hover,
  .e-float-input.e-float-icon-left:not(.e-disabled):not(.e-input-focus) .e-input-in-wrap:hover,
  .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-disabled):not(.e-input-focus) .e-input-in-wrap:hover {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      border-bottom-width: $input-group-border-width-hover;
    }
  }

  .e-input-group:not(.e-disabled):not(.e-float-icon-left)::before,
  .e-input-group:not(.e-disabled):not(.e-float-icon-left)::after,
  .e-input-group.e-float-icon-left:not(.e-disabled) .e-input-in-wrap::before,
  .e-input-group.e-float-icon-left:not(.e-disabled) .e-input-in-wrap::after,
  .e-input-group.e-control-wrapper:not(.e-disabled):not(.e-float-icon-left)::before,
  .e-input-group.e-control-wrapper:not(.e-disabled):not(.e-float-icon-left)::after,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-disabled) .e-input-in-wrap::before,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-disabled) .e-input-in-wrap::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      bottom: -2px;
    }
    @else if $input-skin-name == 'fluent2' {
      bottom: -.3px;
    }
  }

  .e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error),
  .e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error,
  .e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error),
  .e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error,
  .e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) input,
  .e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error) input,
  .e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error) input,
  .e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error input,
  .e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) textarea,
  .e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error) textarea,
  .e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error) textarea,
  .e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error textarea,
  .e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) input,
  .e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error) input,
  .e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error) input,
  .e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error input,
  .e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) textarea,
  .e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error) textarea,
  .e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error) textarea,
  .e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error textarea,
  .e-input-group.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-input-group.e-input-focus:not(.e-float-icon-left).e-warning:not(.e-success):not(.e-error),
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left).e-warning:not(.e-success):not(.e-error),
  .e-input-group.e-input-focus:not(.e-float-icon-left).e-error:not(.e-success):not(.e-warning),
  .e-input-group.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-input-group.e-float-icon-left.e-input-focus.e-success:not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-input-group.e-float-icon-left.e-input-focus.e-warning:not(.e-success):not(.e-error) .e-input-in-wrap,
  .e-input-group.e-float-icon-left.e-input-focus.e-error:not(.e-success):not(.e-warning) .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-style: $input-group-border-type-focus;
      border-width: $zero-value $zero-value 1px;
    }
  }

  .e-float-input:not(.e-outline) input:-webkit-autofill ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-autofill:not(.e-outline) input:-webkit-autofill ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus):not(.e-outline) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom {
    font-size: $float-label-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3'  {
      top: -9px;
      transform: translate3d(0, -6px, 0) scale(.92);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      padding-right: 0;
      transform: translate3d(-10px, -43px, 0) scale(1);
    }
    @else if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' {
      font-weight: bold;
      padding-right: 0;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if ($input-skin-name == 'FluentUI') {
      font-style: $input-font-normal;
      font-weight: 600;
      padding-right: 0;
      transform: translate3d(-10px, -41px, 0) scale(1);
    }
    user-select: text;
  }

  .e-small .e-float-input:not(.e-outline) input:-webkit-autofill ~ label.e-float-text,
  .e-small.e-float-input:not(.e-outline) input:-webkit-autofill ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-autofill:not(.e-outline) input:-webkit-autofill ~ label.e-float-text,
  .e-small.e-float-input.e-control-wrapper.e-autofill:not(.e-outline) input:-webkit-autofill ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus):not(.e-outline) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus):not(.e-outline) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom {
    font-size: $float-label-small-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -8px;
      transform: translate3d(0, -6px, 0) scale(.92);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      padding-right: 0;
      top: 17px;
      transform: translate3d(-10px, -43px, 0) scale(1);
    }
    @else if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' {
      font-weight: bold;
      padding-right: 0;
      top: 17px;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if ($input-skin-name == 'FluentUI') {
      font-style: $input-font-normal;
      font-weight: 600;
      padding-right: 0;
      top: 17px;
      transform: translate3d(-10px, -38px, 0) scale(1);
    }
    user-select: text;
  }

  .e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or $input-skin-name == 'bootstrap' or $input-skin-name == 'highcontrast' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' {
      @include textarea-float-label-alignment;
      font-style: $input-font-style;
    }
  }

  .e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      transform: translate3d(0, 16px, 0) scale(1);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or  $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      transform: translate3d(0, 16px, 0) scale(1);
    }
    @else if $input-skin-name == 'bootstrap' {
      padding-right: 0;
      transform: translate3d(0, 16px, 0) scale(1);
    }
  }

  .e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    font-size: $float-placeholder-font-size;
    font-style: $input-font-style;
    font-weight: normal;
    left: 0;
    overflow: hidden;
    padding-left: $float-label-padding;
    pointer-events: none;
    position: absolute;
    text-overflow: ellipsis;
    top: -11px;
    transform: translate3d(0, 16px, 0) scale(1);
    transform-origin: left top;
    transition: .25s cubic-bezier(.25, .8, .25, 1);
    user-select: none;
    white-space: nowrap;
    width: 100%;
  }

  .e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom {
    font-size: $float-label-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -9px;
      transform: translate3d(0, -6px, 0) scale(.92);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      padding-right: 0;
      transform: translate3d(-10px, -43px, 0) scale(1);
    }
    @else if $input-skin-name == 'bootstrap' {
      font-weight: $input-float-font-weight;
      padding-right: 0;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if ($input-skin-name == 'tailwind' or $input-skin-name == 'tailwind3'  or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -40px, 0) scale(1);
    }
    @else if $input-skin-name == 'FluentUI' {
      font-style: $input-font-normal;
      font-weight: 600;
      padding-right: 0;
      transform: translate3d(-10px, -41px, 0) scale(1);
    }
    user-select: text;
  }

  .e-small .e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-small.e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-small.e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom {
    font-size: $float-label-small-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -8px;
      transform: translate3d(0, -6px, 0) scale(.92);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      padding-right: 0;
      top: 17px;
      transform: translate3d(-10px, -43px, 0) scale(1);
    }
    @else if $input-skin-name == 'bootstrap' {
      font-weight: $input-float-font-weight;
      padding-right: 0;
      top: 17px;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if $input-skin-name == 'FluentUI' {
      font-style: $input-font-normal;
      font-weight: 600;
      padding-right: 0;
      top: 17px;
      transform: translate3d(-10px, -37px, 0) scale(1);
    }
    user-select: text;
  }

  .e-float-input.e-small textarea:focus ~ label.e-float-text,
  .e-float-input.e-small textarea:valid ~ label.e-float-text,
  .e-float-input.e-small textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-small textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small textarea label.e-float-text.e-label-top,
  .e-small .e-float-input textarea:focus ~ label.e-float-text,
  .e-small .e-float-input textarea:valid ~ label.e-float-text,
  .e-small .e-float-input textarea ~ label.e-label-top.e-float-text,
  .e-small .e-float-input textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-float-input textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-float-input textarea label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-small textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small textarea:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small textarea label.e-float-text.e-label-top,
  .e-small .e-float-input.e-control-wrapper textarea:focus ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper textarea:valid ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper textarea ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-control-wrapper textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-control-wrapper textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-control-wrapper textarea label.e-float-text.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      transform: translate3d(0, 6px, 0) scale(.86);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      padding-right: 0;
      transform: translate3d(-10px, -43px, 0) scale(1);
    }
    @else if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' {
      padding-right: 0;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if ($input-skin-name == 'tailwind') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -31px, 0) scale(1);
    }
    @else if ($input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -35px, 0) scale(1);
    }
    @else if ($input-skin-name == 'FluentUI') {
      font-style: $input-font-normal;
      font-weight: 600;
      padding-right: 0;
      transform: translate3d(-10px, -37px, 0) scale(1);
    }
  }

  .e-float-input textarea[disabled],
  .e-float-input textarea.e-disabled,
  .e-float-input.e-control-wrapper textarea[disabled],
  .e-float-input.e-control-wrapper textarea.e-disabled  {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' {
      border-color: $input-disable-border-color;
      border-style: $input-disable-border-type;
    }
  }

  .e-float-input textarea[disabled],
  .e-float-input textarea.e-disabled,
  .e-float-input.e-control-wrapper textarea[disabled],
  .e-float-input.e-control-wrapper textarea.e-disabled {
    background: transparent;
    background-image: none;
    cursor: not-allowed;
    @if $input-skin-name == 'highcontrast' {
      color: $disable;
    }
  }

  textarea.e-input#{$css},
  .e-input-group textarea,
  .e-input-group textarea.e-input,
  .e-input-group.e-input-focus textarea,
  .e-input-group.e-input-focus textarea.e-input,
  .e-input-group.e-control-wrapper textarea,
  .e-input-group.e-control-wrapper.e-input-focus textarea,
  .e-input-group.e-control-wrapper textarea.e-input,
  .e-input-group.e-control-wrapper.e-input-focus textarea.e-input,
  .e-float-input textarea,
  .e-float-input.e-control-wrapper textarea {
    line-height: 1.5;
    min-height: $textarea-min-height;
    min-width: 0;
    padding: $textarea-padding;
    resize: vertical;
  }

  .e-input-group.e-small textarea,
  .e-input-group.e-small textarea.e-input,
  .e-input-group textarea.e-small,
  .e-input-group textarea.e-input.e-small,
  .e-input-group.e-control-wrapper.e-small textarea,
  .e-input-group.e-control-wrapper.e-small textarea.e-input,
  .e-small .e-input-group textarea,
  .e-small .e-input-group textarea.e-input,
  .e-float-input.e-small textarea,
  .e-float-input textarea.e-small,
  .e-float-input.e-control-wrapper.e-small textarea,
  .e-float-input.e-control-wrapper textarea.e-small,
  .e-small .e-float-input textarea,
  .e-small .e-float-input.e-control-wrapper textarea {
    font: inherit;
    min-height: $textarea-small-min-height;
    padding: $textarea-small-padding;
  }

  .e-input-group.e-input-focus.e-small textarea,
  .e-input-group.e-input-focus.e-small textarea.e-input,
  .e-input-group.e-input-focus textarea.e-small,
  .e-input-group.e-input-focus textarea.e-input.e-small,
  .e-input-group.e-input-focus textarea.e-input.e-small,
  .e-input-group.e-control-wrapper.e-input-focus.e-small textarea,
  .e-input-group.e-control-wrapper.e-input-focus.e-small textarea.e-input,
  .e-small .e-input-group.e-input-focus textarea,
  .e-small .e-input-group.e-input-focus textarea.e-input {
    font: inherit;
    min-height: $textarea-small-min-height;
    padding: $textarea-small-padding;
  }

  .e-input-group.e-small textarea:focus,
  .e-input-group.e-small textarea.e-input:focus,
  .e-input-group textarea.e-small:focus,
  .e-input-group textarea.e-input.e-small:focus,
  .e-input-group.e-control-wrapper.e-small textarea:focus,
  .e-input-group.e-control-wrapper.e-small textarea.e-input:focus,
  .e-small .e-input-group textarea:focus,
  .e-small .e-input-group textarea.e-input:focus,
  .e-float-input.e-small textarea:focus,
  .e-float-input textarea.e-small:focus,
  .e-float-input.e-control-wrapper.e-small textarea:focus,
  .e-float-input.e-control-wrapper textarea.e-small:focus,
  .e-small .e-float-input textarea:focus,
  .e-small .e-float-input.e-control-wrapper textarea:focus {
    padding: $textarea-small-padding;
  }

  input.e-input.e-small#{$css},
  textarea.e-input.e-small#{$css},
  .e-small input.e-input#{$css},
  .e-small textarea.e-input #{$css} {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      min-height: $input-smaller-min-height;
    }
  }

  input.e-input#{$css},
  textarea.e-input#{$css} {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      min-height: $input-min-height;
    }
  }

  // Outline textbox

  textarea.e-outline.e-input,
  .e-outline.e-input-group textarea.e-input,
  .e-outline.e-input-group textarea,
  .e-outline.e-input-group.e-control-wrapper textarea.e-input,
  .e-outline.e-input-group.e-control-wrapper textarea,
  .e-outline.e-float-input textarea.e-input,
  .e-outline.e-float-input textarea,
  .e-outline.e-float-input.e-control-wrapper textarea.e-input,
  .e-outline.e-float-input.e-control-wrapper textarea {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      width: calc(100% - 1px);
    }
  }

  .e-outline.e-float-input.e-control-wrapper.e-numeric label.e-float-text.e-label-bottom span.e-float-text-content.e-float-text-overflow {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      width: calc(100% - 80px);
    }
  }

  .e-outline.e-float-input.e-control-wrapper.e-numeric label.e-float-text.e-label-top span.e-float-text-content.e-float-text-overflow,
  .e-outline.e-float-input.e-control-wrapper label.e-float-text.e-label-top span.e-float-text-content.e-float-text-overflow,
  .e-outline.e-float-input.e-control-wrapper label.e-float-text.e-label-bottom span.e-float-text-content.e-float-text-overflow,
  .e-outline.e-float-input.e-control-wrapper.e-static-clear label.e-float-text.e-label-top span.e-float-text-content.e-float-text-overflow,
  .e-outline.e-float-input.e-control-wrapper.e-numeric.e-static-clear label.e-float-text.e-label-top span.e-float-text-content.e-float-text-overflow {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
  }

  .e-outline.e-float-input.e-control-wrapper.e-static-clear label.e-float-text.e-label-bottom span.e-float-text-content.e-float-text-overflow,
  .e-outline.e-float-input.e-control-wrapper label.e-float-text.e-label-bottom span.e-float-text-content.e-float-text-overflow.e-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      width: calc(100% - 45px);
    }
  }

  .e-outline.e-float-input.e-control-wrapper.e-static-clear label.e-float-text.e-label-bottom span.e-float-text-content.e-float-text-overflow.e-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      width: calc(100% - 75px);
    }
  }

  .e-outline.e-float-input.e-control-wrapper.e-numeric.e-static-clear label.e-float-text.e-label-bottom span.e-float-text-content.e-float-text-overflow {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      width: calc(100% - 110px);
    }
  }

  .e-outline.e-float-input.e-input-group:not(.e-float-icon-left).e-input-focus .e-float-line::before,
  .e-outline.e-float-input.e-input-group:not(.e-float-icon-left).e-input-focus .e-float-line::after,
  .e-outline.e-float-input.e-input-group.e-float-icon-left.e-input-focus .e-input-in-wrap .e-float-line::before,
  .e-outline.e-float-input.e-input-group.e-float-icon-left.e-input-focus .e-input-in-wrap .e-float-line::after,
  .e-outline.e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left).e-input-focus .e-float-line::before,
  .e-outline.e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left).e-input-focus .e-float-line::after,
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left.e-input-focus .e-input-in-wrap .e-float-line::before,
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left.e-input-focus .e-input-in-wrap .e-float-line::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      width: 0%;
    }
  }

  .e-outline.e-input-group.e-control-wrapper,
  .e-outline.e-input-group,
  .e-outline.e-input-group:not(.e-float-icon-left),
  .e-outline.e-float-input,
  .e-outline.e-float-input.e-control-wrapper,
  .e-outline.e-input-group:not(.e-float-icon-left),
  .e-outline.e-input-group.e-success:not(.e-float-icon-left),
  .e-outline.e-input-group.e-warning:not(.e-float-icon-left),
  .e-outline.e-input-group.e-error:not(.e-float-icon-left),
  .e-outline.e-input-group.e-control-wrapper:not(.e-float-icon-left),
  .e-outline.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
  .e-outline.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
  .e-outline.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border: 1px solid $outline-border-color;
      border-radius: 4px;
      border-width: 1px;
      fill: transparent;
      transition: border .2s, box-shadow .2s;
    }
  }

  .e-input-group.e-control-wrapper,
  .e-input-group,
  .e-input-group:not(.e-float-icon-left),
  .e-float-input,
  .e-float-input.e-control-wrapper,
  .e-input-group:not(.e-float-icon-left),
  .e-input-group.e-success:not(.e-float-icon-left),
  .e-input-group.e-warning:not(.e-float-icon-left),
  .e-input-group.e-error:not(.e-float-icon-left),
  .e-input-group.e-control-wrapper:not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
    @if $input-skin-name == 'fluent2' {
      border: 1px solid $outline-border-color;
      border-radius: 4px;
      border-width: 1px;
      fill: transparent;
      transition: border .2s, box-shadow .2s;
    }
  }

  .e-underline.e-input-group.e-control-wrapper,
  .e-underline.e-input-group,
  .e-underline.e-input-group:not(.e-float-icon-left),
  .e-underline.e-float-input,
  .e-underline.e-float-input.e-control-wrapper,
  .e-underline.e-input-group:not(.e-float-icon-left),
  .e-underline.e-input-group.e-success:not(.e-float-icon-left),
  .e-underline.e-input-group.e-warning:not(.e-float-icon-left),
  .e-underline.e-input-group.e-error:not(.e-float-icon-left),
  .e-underline.e-input-group.e-control-wrapper:not(.e-float-icon-left),
  .e-underline.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
  .e-underline.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
  .e-underline.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
    @if $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      border: 1px solid $underline-border-color;
      border-width: $input-group-full-border-width;
      padding-top: 1px;
      border-radius: 0;
    }
  }

  .e-outline.e-float-input,
  .e-outline.e-float-input.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-top: $zero-value;
    }
  }

  .e-outline.e-float-input.e-valid-input:not(.e-success):not(.e-error):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-valid-input:hover:not(.e-success):not(.e-error):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-valid-input.e-control-wrapper:hover:not(.e-success):not(.e-error):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-valid-input.e-control-wrapper:not(.e-success):not(.e-error):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-valid-input.e-control-wrapper:hover:not(.e-success):not(.e-error):not(.e-warning):not(.e-float-icon-left):not(.e-disabled),
  .e-outline.e-float-input.e-valid-input.e-control-wrapper:not(.e-success):not(.e-error):not(.e-warning):not(.e-float-icon-left):not(.e-disabled),
  .e-outline.e-float-input.e-input-group.e-valid-input:not(.e-success):not(.e-error):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-group.e-valid-input:hover:not(.e-success):not(.e-error):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-valid-input:not(.e-success):not(.e-error):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-valid-input:hover:not(.e-success):not(.e-error):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-valid-input.e-success:not(.e-error):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-valid-input.e-success:hover:not(.e-error):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-group.e-valid-input.e-success:not(.e-error):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-group.e-valid-input.e-success:hover:not(.e-error):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-success:not(.e-error):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-success:hover:not(.e-error):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-valid-input.e-error:not(.e-success):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-valid-input.e-error:hover:not(.e-success):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-group.e-valid-input.e-error:not(.e-success):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-group.e-valid-input.e-error:hover:not(.e-success):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-error:not(.e-success):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-error:hover:not(.e-success):not(.e-warning):not(.e-float-icon-left),
  .e-outline.e-float-input.e-valid-input.e-warning:not(.e-success):not(.e-error):not(.e-float-icon-left),
  .e-outline.e-float-input.e-valid-input.e-warning:hover:not(.e-success):not(.e-error):not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-group.e-valid-input.e-warning:not(.e-success):not(.e-error):not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-group.e-valid-input.e-warning:hover:not(.e-success):not(.e-error):not(.e-float-icon-left),
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-warning:not(.e-success):not(.e-error):not(.e-float-icon-left),
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-warning:hover:not(.e-success):not(.e-error):not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-group.e-valid-input.e-float-icon-left:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-outline.e-float-input.e-input-group.e-valid-input.e-control-wrapper.e-float-icon-left:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-outline.e-float-input.e-valid-input.e-float-icon-left:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-outline.e-float-input.e-valid-input.e-control-wrapper.e-float-icon-left:not(.e-input-focus):hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-float-icon-left:not(.e-warning):not(.e-success):not(.e-error) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-top-color: transparent;
    }
  }

  .e-outline.e-input-group,
  .e-outline .e-input-group,
  .e-outline .e-input-group.e-control-wrapper,
  .e-outline.e-float-input,
  .e-outline.e-float-input.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $outline-input-font-size;
    }
  }

  .e-outline.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-outline.e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-outline.e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-outline.e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-outline.e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error),
  .e-outline.e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error,
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error),
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error,
  .e-outline.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error),
  .e-outline.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error),
  .e-outline.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error),
  .e-outline.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error,
  .e-outline.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error),
  .e-outline.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error),
  .e-outline.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error),
  .e-outline.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error,
  .e-outline.e-input-group.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-outline.e-input-group.e-input-focus:not(.e-float-icon-left).e-warning:not(.e-success):not(.e-error),
  .e-outline.e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-outline.e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left).e-warning:not(.e-success):not(.e-error),
  .e-outline.e-input-group.e-input-focus:not(.e-float-icon-left).e-error:not(.e-success):not(.e-warning),
  .e-outline.e-input-group.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error),
  .e-outline.e-input-group.e-float-icon-left.e-input-focus.e-success:not(.e-warning):not(.e-error),
  .e-outline.e-input-group.e-float-icon-left.e-input-focus.e-warning:not(.e-success):not(.e-error),
  .e-outline.e-input-group.e-float-icon-left.e-input-focus.e-error:not(.e-success):not(.e-warning) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-width: $outline-active-input-border;
    }
  }

  input.e-outline.e-input,
  .e-outline.e-input-group input.e-input,
  .e-outline.e-input-group input,
  .e-outline.e-input-group.e-control-wrapper input.e-input,
  .e-outline.e-input-group.e-control-wrapper input,
  .e-outline.e-float-input input.e-input,
  .e-outline.e-float-input input,
  .e-outline.e-float-input.e-control-wrapper input.e-input,
  .e-outline.e-float-input.e-control-wrapper input,
  .e-outline.e-input-group:not(.e-float-icon-left) input.e-input:focus,
  .e-outline.e-input-group.e-control-wrapper:not(.e-float-icon-left) input.e-input:focus,
  .e-outline.e-float-input.e-control-wrapper:not(.e-float-icon-left) input:focus,
  .e-outline.e-float-input:not(.e-float-icon-left) input:focus,
  .e-outline.e-float-input.e-control-wrapper:not(.e-float-icon-left).e-input-focus input,
  .e-outline.e-float-input:not(.e-float-icon-left).e-input-focus input,
  .e-outline.e-input-group:not(.e-float-icon-left).e-input-focus input.e-input,
  .e-outline.e-input-group.e-control-wrapper:not(.e-float-icon-left).e-input-focus input.e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      box-sizing: border-box;
      padding: $outline-input-padding-top $outline-input-padding-left $outline-input-padding-bottom;
    }
  }

  textarea.e-outline.e-input,
  .e-outline.e-input-group textarea.e-input,
  .e-outline.e-input-group textarea,
  .e-outline.e-input-group.e-control-wrapper textarea.e-input,
  .e-outline.e-input-group.e-control-wrapper textarea,
  .e-outline.e-float-input textarea.e-input,
  .e-outline.e-float-input textarea,
  .e-outline.e-float-input.e-control-wrapper textarea.e-input,
  .e-outline.e-float-input.e-control-wrapper textarea,
  .e-outline.e-input-group:not(.e-float-icon-left) textarea.e-input:focus,
  .e-outline.e-input-group.e-control-wrapper:not(.e-float-icon-left) textarea.e-input:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'tailwind3' {
      box-sizing: border-box;
      margin: $outline-textarea-margin-top;
      padding: $zero-value $outline-input-padding-left $outline-input-padding-left;
    }
  }

  .e-outline.e-input-group input.e-input,
  .e-outline.e-float-input.e-input-group input,
  .e-outline.e-input-group.e-control-wrapper input.e-input,
  .e-outline.e-float-input.e-input-group.e-control-wrapper input,
  .e-outline.e-float-input input,
  .e-outline.e-float-input.e-control-wrapper input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      box-sizing: border-box;
      min-height: $outline-input-min-height;
    }
  }

  .e-outline.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-outline.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
  .e-outline.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
  .e-outline.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
  .e-outline.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-outline.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
  .e-outline.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
  .e-outline.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
  .e-outline.e-float-input:not(.e-input-group) .e-float-line::before,
  .e-outline.e-float-input:not(.e-input-group) .e-float-line::after,
  .e-outline.e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::before,
  .e-outline.e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      content: none;
    }
  }

  .e-outline.e-input-group.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-outline.e-input-group.e-input-focus.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-focus.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-outline.e-float-input.e-input-focus.e-control-wrapper:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-outline.e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-outline.e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-outline.e-float-input.e-input-focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-outline.e-float-input.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-color: $input-active-border-color;
      box-shadow: inset 1px 1px $input-active-border-color, inset -1px 0 $input-active-border-color, inset 0 -1px $input-active-border-color;
    }
  }

  .e-outline.e-float-input.e-float-icon-left .e-input-in-wrap,
  .e-outline.e-float-input.e-control-wrapper.e-float-icon-left .e-input-in-wrap,
  .e-outline.e-input-group.e-float-icon-left .e-input-in-wrap,
  .e-outline.e-input-group.e-control-wrapper.e-float-icon-left .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border: $input-group-border-width;
      border-width: $input-group-border-width;
      margin-left: $zero-value;
    }
  }

  .e-outline.e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-left: 4px;
      margin-right: $zero-value;
    }
  }

  .e-outline.e-input-group.e-rtl.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-rtl.e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-input-group.e-rtl.e-control-wrapper.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-input-group.e-rtl.e-control-wrapper.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-right: 4px;
      margin-left: $zero-value;
    }
  }

  .e-outline.e-float-input .e-clear-icon,
  .e-outline.e-float-input.e-control-wrapper .e-clear-icon,
  .e-outline.e-input-group .e-clear-icon,
  .e-outline.e-input-group.e-control-wrapper .e-clear-icon {
    @if $input-skin-name == 'material' {
      font-size: $outline-input-clear-icon-font-size;
      padding: 9px $outline-input-icon-margin-right 9px $zero-value;
    }
    @if $input-skin-name == 'Material3' {
      font-size: $outline-input-clear-icon-font-size;
      padding: $zero-value;
      height: 32px;
      margin: 4px;
    }
  }

  .e-outline.e-input-group.e-float-icon-left .e-input,
  .e-outline.e-float-input.e-input-group.e-float-icon-left .e-input,
  .e-outline.e-input-group.e-control-wrapper.e-float-icon-left .e-input,
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-float-icon-left .e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      padding-left: $zero-value;
    }
  }

  .e-outline.e-input-group .e-input-group-icon,
  .e-outline.e-input-group.e-control-wrapper .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $outline-input-icon-font-size;
      margin: 9px $outline-input-icon-margin-right 9px $zero-value;
    }
  }

  .e-outline.e-input-group.e-prepend .e-input-group-icon,
  .e-outline.e-input-group.e-prepend.e-control-wrapper .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $outline-input-icon-font-size;
      margin: 9px $zero-value 9px $outline-input-icon-margin-right;
    }
  }

  .e-outline.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error) input,
  .e-outline.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error) input,
  .e-outline.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error) input,
  .e-outline.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error input,
  .e-outline.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) textarea,
  .e-outline.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error) textarea,
  .e-outline.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error) textarea,
  .e-outline.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error textarea,
  .e-outline.e-input-group.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-outline.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error) input,
  .e-outline.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error) input,
  .e-outline.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error) input,
  .e-outline.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error input,
  .e-outline.e-input-group.e-control-wrapper.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border: $zero-value;
    }
  }

  .e-outline.e-input-group .e-input-group-icon + .e-input-group-icon:last-child,
  .e-outline.e-input-group.e-small .e-input-group-icon + .e-input-group-icon:last-child,
  .e-outline.e-input-group.e-control-wrapper .e-input-group-icon + .e-input-group-icon:last-child,
  .e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon + .e-input-group-icon:last-child {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-left: $zero-value;
    }
  }

  .e-outline.e-input-group.e-small:not(.e-float-input) .e-input,
  .e-small .e-outline.e-input-group:not(.e-float-input) .e-input,
  .e-outline.e-input-group.e-control-wrapper.e-small:not(.e-float-input) .e-input,
  .e-small .e-outline.e-input-group.e-control-wrapper:not(.e-float-input) .e-input,
  .e-outline.e-float-input.e-small input,
  .e-small .e-outline.e-float-input input,
  .e-outline.e-float-input.e-input-group.e-small input,
  .e-small .e-outline.e-float-input.e-input-group input,
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-small input,
  .e-small .e-outline.e-float-input.e-input-group.e-control-wrapper input,
  .e-outline.e-float-input.e-control-wrapper.e-small input,
  .e-small .e-outline.e-float-input.e-control-wrapper input,
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-small input,
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-small input,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-input-group input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      box-sizing: border-box;
      min-height: $outline-small-input-min-height;
    }
  }

  .e-outline.e-float-input.e-small input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-small input:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-small input ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input input ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small input:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small input ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper input ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-small textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-small textarea:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-small textarea ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input textarea ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-small textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-small textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small textarea:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small textarea ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper textarea ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-small.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'tailwind3' {
      font-size: $outline-small-label-font-size;
    }
  }

  .e-outline.e-input-group,
  .e-outline.e-input-group.e-control-wrapper,
  .e-outline.e-float-input,
  .e-outline.e-float-input.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $outline-valid-input-font-size;
    }
  }

  .e-outline.e-input-group.e-small,
  .e-small .e-outline.e-input-group,
  .e-outline.e-input-group.e-control-wrapper.e-small,
  .e-small .e-outline.e-input-group.e-control-wrapper,
  .e-outline.e-float-input.e-small,
  .e-small .e-outline.e-float-input,
  .e-outline.e-float-input.e-control-wrapper.e-small,
  .e-small .e-outline.e-float-input.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $outline-small-input-font-size;
    }
  }

  input.e-input.e-small.e-outline,
  .e-small input.e-input.e-outline,
  .e-input-group.e-small.e-outline input.e-input,
  .e-outline.e-input-group.e-control-wrapper.e-small input.e-input,
  .e-outline.e-float-input.e-small input,
  .e-outline.e-float-input.e-control-wrapper input.e-small,
  .e-outline.e-float-input.e-small input,
  .e-outline.e-float-input.e-control-wrapper input.e-small,
  .e-outline.e-input-group input.e-input.e-small,
  .e-outline.e-input-group.e-control-wrapper input.e-input.e-small,
  .e-small .e-outline.e-float-input input,
  .e-small .e-outline.e-float-input.e-control-wrapper input,
  .e-small .e-outline.e-input-group input.e-input,
  .e-small .e-outline.e-input-group.e-control-wrapper input.e-input,
  .e-outline.e-input-group.e-small input.e-input:focus,
  .e-outline.e-input-group.e-control-wrapper.e-small input.e-input:focus,
  .e-outline.e-float-input.e-small input:focus,
  .e-outline.e-float-input.e-control-wrapper.e-small input:focus,
  .e-small .e-outline.e-input-group.e-control-wrapper input.e-input:focus,
  .e-small .e-outline.e-input-group input.e-input:focus,
  .e-small .e-outline.e-float-input input:focus,
  .e-small .e-outline.e-float-input.e-control-wrapper input:focus,
  .e-outline.e-float-input.e-small.e-input-focus input,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-input-focus input,
  .e-small .e-outline.e-float-input.e-input-focus input,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-input-focus input,
  .e-outline.e-input-group.e-small.e-input-focus input.e-input,
  .e-outline.e-input-group.e-control-wrapper.e-small.e-input-focus input.e-input,
  .e-small .e-outline.e-input-group.e-control-wrapper.e-input-focus input.e-input,
  .e-small .e-outline.e-input-group.e-input-focus input.e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      padding: $outline-small-input-padding-top $outline-small-input-padding-left;
    }
  }

  textarea.e-input.e-small.e-outline,
  .e-small textarea.e-input.e-outline,
  .e-input-group.e-small.e-outline textarea.e-input,
  .e-outline.e-input-group.e-control-wrapper.e-small textarea.e-input,
  .e-outline.e-float-input.e-control-wrapper textarea.e-small,
  .e-outline.e-float-input.e-small textarea,
  .e-outline.e-input-group textarea.e-input.e-small,
  .e-outline.e-input-group.e-control-wrapper textarea.e-input.e-small,
  .e-small .e-outline.e-float-input textarea,
  .e-small .e-outline.e-float-input.e-control-wrapper textarea,
  .e-small .e-outline.e-input-group textarea.e-input,
  .e-small .e-outline.e-input-group.e-control-wrapper textarea.e-input,
  .e-outline.e-input-group.e-small textarea.e-input:focus,
  .e-outline.e-input-group.e-control-wrapper.e-small textarea.e-input:focus,
  .e-outline.e-float-input.e-small textarea:focus,
  .e-outline.e-float-input.e-control-wrapper.e-small textarea:focus,
  .e-small .e-outline.e-input-group.e-control-wrapper textarea.e-input:focus,
  .e-small .e-outline.e-input-group textarea.e-input:focus,
  .e-small .e-outline.e-float-input textarea:focus,
  .e-small .e-outline.e-float-input.e-control-wrapper textarea:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      box-sizing: border-box;
      margin: $outline-small-textarea-margin-top;
      padding: $zero-value $outline-small-input-padding-left $outline-small-input-padding-left;
    }
  }

  .e-outline.e-input-group.e-small.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-input-group.e-float-icon-left > .e-input-group-icon.e-small,
  .e-outline.e-input-group.e-control-wrapper.e-small.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon.e-small,
  .e-small .e-outline.e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-small .e-outline.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-input-group.e-small.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-input-group.e-float-icon-left > .e-input-group-icon.e-small,
  .e-small .e-outline.e-float-input.e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-small.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left > .e-input-group-icon.e-small,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-left: $zero-value;
      margin-right: $outline-small-input-icon-margin-right;
    }
  }

  .e-outline.e-input-group.e-small .e-input-group-icon,
  .e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon,
  .e-small .e-outline.e-input-group .e-input-group-icon,
  .e-small .e-outline.e-input-group.e-control-wrapper .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-left: $zero-value;
      margin-right: $outline-small-input-icon-margin-left;
    }
  }

  .e-outline.e-input-group.e-prepend.e-small .e-input-group-icon,
  .e-outline.e-input-group.e-prepend.e-control-wrapper.e-small .e-input-group-icon,
  .e-small .e-outline.e-input-group.e-prepend .e-input-group-icon,
  .e-small .e-outline.e-input-group.e-prepend.e-control-wrapper .e-input-group-icon,
  .e-rtl.e-outline.e-input-group.e-small .e-input-group-icon,
  .e-rtl .e-outline.e-input-group.e-small .e-input-group-icon,
  .e-rtl.e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon,
  .e-rtl .e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon,
  .e-small .e-rtl.e-outline.e-input-group .e-input-group-icon,
  .e-rtl.e-small .e-outline.e-input-group .e-input-group-icon,
  .e-small .e-rtl.e-outline.e-input-group.e-control-wrapper .e-input-group-icon .e-rtl.e-small .e-outline.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-rtl.e-outline.e-input-group.e-small .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl .e-outline.e-input-group.e-small .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl .e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon + .e-input-group-icon:last-child {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-left: $outline-small-input-icon-margin-left;
      margin-right: $zero-value;
    }
  }

  .e-outline.e-input-group.e-small .e-clear-icon,
  .e-outline.e-input-group .e-clear-icon.e-small,
  .e-small .e-outline.e-input-group .e-clear-icon,
  .e-outline.e-input-group.e-control-wrapper.e-small .e-clear-icon,
  .e-outline.e-input-group.e-control-wrapper .e-clear-icon.e-small,
  .e-small .e-outline.e-input-group.e-control-wrapper .e-clear-icon {
    @if $input-skin-name == 'material' {
      font-size: $outline-small-clear-icon-font-size;
      padding: 7px $outline-small-input-icon-margin-left 7px $zero-value;
    }
    @if $input-skin-name == 'Material3' {
      font-size: $outline-small-clear-icon-font-size;
    }
  }

  .e-outline.e-float-input.e-input-group.e-small .e-input-group-icon,
  .e-small .e-outline.e-float-input.e-input-group .e-input-group-icon,
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-top: $outline-bigger-small-group-icon-top;
    }
  }

  .e-outline.e-float-input input,
  .e-outline.e-float-input textarea,
  .e-outline.e-float-input.e-control-wrapper input,
  .e-outline.e-float-input.e-control-wrapper textarea {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border: $outline-input-border;
      border-width: $outline-input-group-border-width;
    }
  }

  .e-outline label.e-float-text,
  .e-outline.e-float-input label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper label.e-float-text,
  .e-outline.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      box-sizing: border-box;
      display: flex;
      left: $zero-value;
      line-height: $outline-label-default-line-height;
      position: absolute;
      top: $outline-float-label-top;
      transform: translate3d($zero-value, $zero-value, $zero-value) scale(1);
      transition: color .2s, font-size .2s, line-height .2s;
    }
  }

  .e-outline.e-float-input.e-small:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-outline:not(.e-valid-input):not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid) ~ label.e-float-text,
  .e-small.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-small.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      line-height: $outline-small-label-line-height;
    }
  }

  .e-outline.e-float-input.e-small:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-outline:not(.e-valid-input):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid) ~ label.e-float-text,
  .e-small.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-small.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-outline.e-float-input.e-small:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-outline:not(.e-valid-input):not(.e-valid-input) textarea:not(:focus):not(:valid) ~ label.e-float-text,
  .e-small.e-outline.e-float-input:not(.e-valid-input) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-small.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      line-height: $outline-textarea-small-label-line-height;
    }
  }

  .e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) input:valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) input:valid ~ label.e-float-text.e-label-bottom,
  .e-outline:not(.e-valid-input):not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid) ~ label.e-float-text,
  .e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      line-height: $outline-label-line-height;
    }
  }

  .e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:valid ~ label.e-float-text.e-label-bottom,
  .e-outline:not(.e-valid-input):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid) ~ label.e-float-text,
  .e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-outline.e-float-input:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline:not(.e-valid-input):not(.e-valid-input) textarea:not(:focus):not(:valid) ~ label.e-float-text,
  .e-outline.e-float-input:not(.e-valid-input) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper:not(.e-valid-input) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      line-height: $outline-textarea-label-line-height;
    }
  }

  .e-outline label.e-float-text::before,
  .e-outline label.e-float-text::after,
  .e-outline.e-float-input label.e-float-text::before,
  .e-outline.e-float-input label.e-float-text::after,
  .e-outline.e-float-input.e-control-wrapper label.e-float-text::before,
  .e-outline.e-float-input.e-control-wrapper label.e-float-text::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-top: solid $outline-wrapper-border-infocused;
      border-top-color: transparent;
      box-shadow: inset $zero-value 1px transparent;
      box-sizing: border-box;
      content: ' ';
      display: block;
      height: $outline-label-height;
      margin-top: $outline-label-before-element-margin-top;
      min-width: $outline-label-min-width;
      pointer-events: none;
      position: relative;
      transition: border-color .1s, box-shadow .2s;
    }
  }

  .e-outline.e-float-icon-left label.e-float-text::before,
  .e-outline.e-float-icon-left label.e-float-text::after,
  .e-outline.e-float-input.e-float-icon-left label.e-float-text::before,
  .e-outline.e-float-input.e-float-icon-left label.e-float-text::after,
  .e-outline.e-float-input.e-control-wrapper.e-float-icon-left label.e-float-text::before,
  .e-outline.e-float-input.e-control-wrapper.e-float-icon-left label.e-float-text::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      transition: none;
    }
  }

  .e-small.e-outline label.e-float-text::before,
  .e-small.e-outline label.e-float-text::after,
  .e-small.e-outline.e-float-input label.e-float-text::before,
  .e-small.e-outline.e-float-input label.e-float-text::after,
  .e-small.e-outline.e-float-input.e-control-wrapper label.e-float-text::before,
  .e-small.e-outline.e-float-input.e-control-wrapper label.e-float-text::after,
  .e-small .e-outline label.e-float-text::before,
  .e-small .e-outline label.e-float-text::after,
  .e-small .e-outline.e-float-input label.e-float-text::before,
  .e-small .e-outline.e-float-input label.e-float-text::after,
  .e-small .e-outline.e-float-input.e-control-wrapper label.e-float-text::before,
  .e-small .e-outline.e-float-input.e-control-wrapper label.e-float-text::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      min-width: $outline-small-label-min-width;
    }
  }

  .e-outline.e-valid-input:not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-valid-input:not(.e-input-focus) label.e-float-text::after,
  .e-outline.e-float-input.e-valid-input:not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-float-input.e-valid-input:not(.e-input-focus) label.e-float-text::after,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input:not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input:not(.e-input-focus) label.e-float-text::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-top-color: $outline-border-color;
      border-width: 1px;
    }
  }

  .e-outline.e-valid-input.e-success:not(.e-error):not(.e-warning):not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-valid-input.e-success:not(.e-error):not(.e-warning):not(.e-input-focus) label.e-float-text::after,
  .e-outline.e-float-input.e-valid-input.e-success:not(.e-error):not(.e-warning):not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-float-input.e-valid-input.e-success:not(.e-error):not(.e-warning):not(.e-input-focus) label.e-float-text::after,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-success:not(.e-error):not(.e-warning):not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-success:not(.e-error):not(.e-warning):not(.e-input-focus) label.e-float-text::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-top-color: $input-success-color;
    }
  }

  .e-outline.e-success.e-input-focus:not(.e-error):not(.e-warning) label.e-float-text::before,
  .e-outline.e-success.e-input-focus:not(.e-error):not(.e-warning) label.e-float-text::after,
  .e-outline.e-float-input.e-success.e-input-focus:not(.e-error):not(.e-warning) label.e-float-text::before,
  .e-outline.e-float-input.e-success.e-input-focus:not(.e-error):not(.e-warning) label.e-float-text::after,
  .e-outline.e-float-input.e-control-wrapper.e-success.e-input-focus:not(.e-error):not(.e-warning) label.e-float-text::before,
  .e-outline.e-float-input.e-control-wrapper.e-success.e-input-focus:not(.e-error):not(.e-warning) label.e-float-text::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-color: $input-success-color;
    }
  }

  .e-outline.e-valid-input.e-error:not(.e-success):not(.e-warning):not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-valid-input.e-error:not(.e-success):not(.e-warning):not(.e-input-focus) label.e-float-text::after,
  .e-outline.e-float-input.e-valid-input.e-error:not(.e-success):not(.e-warning):not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-float-input.e-valid-input.e-error:not(.e-success):not(.e-warning):not(.e-input-focus) label.e-float-text::after,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-error:not(.e-success):not(.e-warning):not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-error:not(.e-success):not(.e-warning):not(.e-input-focus) label.e-float-text::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-top-color: $input-error-color;
    }
  }

  .e-outline.e-error.e-input-focus:not(.e-success):not(.e-warning) label.e-float-text::before,
  .e-outline.e-error.e-input-focus:not(.e-success):not(.e-warning) label.e-float-text::after,
  .e-outline.e-float-input.e-error.e-input-focus:not(.e-success):not(.e-warning) label.e-float-text::before,
  .e-outline.e-float-input.e-error.e-input-focus:not(.e-success):not(.e-warning) label.e-float-text::after,
  .e-outline.e-float-input.e-control-wrapper.e-error.e-input-focus:not(.e-success):not(.e-warning) label.e-float-text::before,
  .e-outline.e-float-input.e-control-wrapper.e-error.e-input-focus:not(.e-success):not(.e-warning) label.e-float-text::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-color: $input-error-color;
    }
  }

  .e-outline.e-valid-input.e-warning:not(.e-success):not(.e-error):not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-valid-input.e-warning:not(.e-success):not(.e-error):not(.e-input-focus) label.e-float-text::after,
  .e-outline.e-float-input.e-valid-input.e-warning:not(.e-success):not(.e-error):not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-float-input.e-valid-input.e-warning:not(.e-success):not(.e-error):not(.e-input-focus) label.e-float-text::after,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-warning:not(.e-success):not(.e-error):not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-warning:not(.e-success):not(.e-error):not(.e-input-focus) label.e-float-text::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-top-color: $input-warning-color;
    }
  }

  .e-outline.e-warning.e-input-focus:not(.e-success):not(.e-error) label.e-float-text::before,
  .e-outline.e-warning.e-input-focus:not(.e-success):not(.e-error) label.e-float-text::after,
  .e-outline.e-float-input.e-warning.e-input-focus:not(.e-success):not(.e-error) label.e-float-text::before,
  .e-outline.e-float-input.e-warning.e-input-focus:not(.e-success):not(.e-error) label.e-float-text::after,
  .e-outline.e-float-input.e-control-wrapper.e-warning.e-input-focus:not(.e-success):not(.e-error) label.e-float-text::before,
  .e-outline.e-float-input.e-control-wrapper.e-warning.e-input-focus:not(.e-success):not(.e-error) label.e-float-text::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-color: $input-warning-color;
    }
  }

  .e-outline label.e-float-text::before,
  .e-outline.e-float-input label.e-float-text::before,
  .e-outline.e-float-input.e-control-wrapper label.e-float-text::before {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-left: solid 1px transparent;
      border-radius: $outline-label-margin $zero-value;
      left: $outline-label-before-left;
      margin-right: $outline-label-margin;
    }
  }

  .e-rtl.e-outline label.e-float-text::before,
  .e-rtl.e-outline.e-float-input label.e-float-text::before,
  .e-rtl.e-outline.e-float-input.e-control-wrapper label.e-float-text::before {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-left: $zero-value;
      border-radius: $zero-value $outline-label-margin;
      left: $outline-label-before-left-rtl;
    }
  }

  .e-rtl.e-outline label.e-float-text::after,
  .e-rtl.e-outline.e-float-input label.e-float-text::after,
  .e-rtl.e-outline.e-float-input.e-control-wrapper label.e-float-text::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-radius: $outline-label-border-radius $zero-value;
      border-right: $zero-value;
      left: $outline-label-after-left-rtl;
    }
  }

  .e-outline label.e-float-text::after,
  .e-outline.e-float-input label.e-float-text::after,
  .e-outline.e-float-input.e-control-wrapper label.e-float-text::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-radius: $zero-value $outline-label-border-radius;
      border-right: solid 1px transparent;
      flex-grow: 1;
      left: $outline-label-after-left;
      margin-left: $outline-label-margin;
    }
  }

  .e-outline.e-input-focus label.e-float-text::before,
  .e-outline.e-input-focus label.e-float-text::after,
  .e-outline.e-float-input.e-input-focus label.e-float-text::before,
  .e-outline.e-float-input.e-input-focus label.e-float-text::after,
  .e-outline.e-float-input.e-control-wrapper.e-input-focus label.e-float-text::before,
  .e-outline.e-float-input.e-control-wrapper.e-input-focus label.e-float-text::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-top-color: $input-active-border-color;
    }
  }

  .e-outline.e-float-input.e-input-focus:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled),
  .e-outline.e-float-input.e-input-focus:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled):not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-group.e-input-focus:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled):not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-group.e-input-focus:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled),
  .e-outline.e-float-input.e-control-wrapper.e-input-focus:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled):not(.e-float-icon-left),
  .e-outline.e-float-input.e-control-wrapper.e-input-focus:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled),
  .e-outline.e-float-input.e-input-focus:hover:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled),
  .e-outline.e-float-input.e-input-group.e-input-focus:hover:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled),
  .e-outline.e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled),
  .e-outline.e-float-input.e-input-focus:hover:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled):not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-group.e-input-focus:hover:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled):not(.e-float-icon-left),
  .e-outline.e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-error):not(.e-success):not(.e-warning):not(.e-disabled):not(.e-float-icon-left) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-top-color: transparent;
      box-shadow: inset 1px $zero-value $input-active-border-color, inset -1px $zero-value $input-active-border-color, inset $zero-value -1px $input-active-border-color;
    }
  }

  .e-outline.e-float-input input:focus ~ label.e-float-text,
  .e-outline.e-float-input input:valid ~ label.e-float-text,
  .e-outline.e-float-input input ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input input[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input input[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input input label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper input:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper input ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper input[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper input[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper input label.e-float-text.e-label-top,
  .e-outline.e-float-input textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input textarea:valid ~ label.e-float-text,
  .e-outline.e-float-input textarea ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input textarea label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper textarea:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper textarea ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper textarea label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $outline-floating-label-font-size;
      top: $outline-float-label-top;
      transform: translate3d($zero-value, $zero-value, $zero-value) scale(1);
    }
  }

  .e-outline.e-float-input.e-rtl .e-clear-icon,
  .e-rtl .e-outline.e-float-input .e-clear-icon,
  .e-outline.e-float-input.e-control-wrapper.e-rtl .e-clear-icon,
  e-rtl .e-outline.e-float-input.e-control-wrapper .e-clear-icon,
  .e-outline.e-input-group.e-rtl .e-clear-icon,
  .e-rtl .e-outline.e-input-group .e-clear-icon,
  .e-outline.e-input-group.e-control-wrapper.e-rtl .e-clear-icon,
  .e-rtl .e-outline.e-input-group.e-control-wrapper.e-rtl .e-clear-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      padding-left: $outline-input-icon-margin-right;
      padding-right: $outline-input-icon-margin-left;
    }
  }

  .e-rtl .e-outline.e-input-group .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group.e-small .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl .e-outline.e-input-group.e-small .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl .e-outline.e-input-group.e-control-wrapper .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group.e-control-wrapper .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl .e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon + .e-input-group-icon:last-child,
  .e-outline.e-float-input.e-rtl.e-float-icon-left .e-input-in-wrap,
  .e-outline.e-float-input.e-rtl.e-control-wrapper.e-float-icon-left .e-input-in-wrap,
  .e-outline.e-input-group.e-rtl.e-float-icon-left .e-input-in-wrap,
  .e-outline.e-input-group.e-rtl.e-control-wrapper.e-float-icon-left .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-right: $zero-value;
    }
  }

  .e-rtl .e-outline.e-input-group .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group .e-input-group-icon + .e-input-group-icon:last-child {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-left: $outline-input-icon-margin-right;
    }
  }

  .e-rtl.e-outline.e-input-group .e-input-group-icon,
  .e-rtl .e-outline.e-input-group .e-input-group-icon,
  .e-rtl.e-outline.e-input-group.e-control-wrapper .e-input-group-icon .e-rtl .e-outline.e-input-group.e-control-wrapper .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-left: $outline-input-icon-margin-right;
      margin-right: $outline-input-icon-margin-left;
    }
  }

  .e-rtl.e-outline.e-input-group.e-small .e-clear-icon,
  .e-rtl .e-outline.e-input-group.e-small .e-clear-icon,
  .e-rtl.e-outline.e-input-group .e-clear-icon.e-small,
  .e-rtl .e-outline.e-input-group .e-clear-icon.e-small,
  .e-rtl.e-small .e-outline.e-input-group .e-clear-icon,
  .e-small .e-rtl.e-outline.e-input-group .e-clear-icon,
  .e-rtl.e-outline.e-input-group.e-control-wrapper.e-small .e-clear-icon,
  .e-rtl .e-outline.e-input-group.e-control-wrapper.e-small .e-clear-icon,
  .e-rtl .e-outline.e-input-group.e-control-wrapper .e-clear-icon.e-small,
  .e-small .e-rtl.e-outline.e-input-group.e-control-wrapper .e-clear-icon .e-rtl.e-small .e-outline.e-input-group.e-control-wrapper .e-clear-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      padding-left: $outline-small-input-icon-margin-left;
      padding-right: $zero-value;
    }
  }

  .e-rtl .e-outline.e-input-group .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl .e-outline.e-input-group.e-control-wrapper .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group.e-control-wrapper .e-input-group-icon + .e-input-group-icon:last-child {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-left: $outline-input-icon-margin-right;
      margin-right: $zero-value;
    }
  }

  .e-outline.e-float-input textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input textarea:valid ~ label.e-float-text,
  .e-outline.e-float-input textarea ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input textarea label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper textarea:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper textarea ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper textarea label.e-float-text.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'tailwind3' {
      top: $outline-float-label-top;
      transform: translate3d($zero-value, $zero-value, $zero-value) scale(1);
    }
    @if $input-skin-name == 'tailwind3' {
      left: 2px;
    }
  }

  .e-outline textarea,
  textarea.e-outline,
  .e-outline.e-float-input textarea,
  .e-outline.e-float-input.e-control-wrapper textarea,
  .e-outline.e-float-input.e-input-group textarea {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      box-sizing: border-box;
    }
  }

  .e-outline.e-float-input.e-valid-input:not(.e-input-focus) input:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input:not(.e-input-focus) input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-valid-input:not(.e-input-focus) textarea:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input:not(.e-input-focus) textarea:focus ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      color: $outline-label-font-color-with-value;
    }
  }

  .e-rtl.e-outline.e-float-input.e-small textarea ~ label.e-float-text,
  .e-rtl.e-outline.e-float-input textarea ~ label.e-float-text.e-small,
  .e-rtl.e-outline.e-float-input textarea.e-small ~ label.e-float-text,
  .e-small .e-rtl.e-outline.e-float-input textarea ~ label.e-float-text,
  .e-rtl.e-outline.e-float-input.e-control-wrapper.e-small textarea ~ label.e-float-text,
  .e-rtl.e-outline.e-float-input.e-control-wrapper textarea ~ label.e-float-text.e-small,
  .e-rtl.e-outline.e-float-input.e-control-wrapper textarea.e-small ~ label.e-float-text,
  .e-small .e-rtl.e-outline.e-float-input.e-control-wrapper textarea ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      top: $outline-float-label-top;
    }
  }

  .e-outline.e-float-input.e-small .e-clear-icon::before,
  .e-outline.e-float-input.e-control-wrapper.e-small .e-clear-icon::before,
  .e-outline.e-input-group.e-small .e-clear-icon::before,
  .e-outline.e-input-group.e-control-wrapper.e-small .e-clear-icon::before,
  .e-outline.e-float-input.e-control-wrapper input.e-small:first-child ~ .e-clear-icon::before,
  .e-outline.e-small .e-float-input.e-control-wrapper .e-clear-icon::before,
  .e-outline.e-float-input input.e-small:first-child ~ .e-clear-icon::before,
  .e-outline.e-small .e-float-input .e-clear-icon::before,
  .e-outline.e-small .e-input-group .e-clear-icon::before,
  .e-outline.e-small .e-input-group.e-control-wrapper .e-clear-icon::before {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $outline-input-small-clear-icon;
    }
  }

  .e-outline.e-float-input .e-clear-icon::before,
  .e-outline.e-float-input.e-control-wrapper .e-clear-icon::before,
  .e-outline.e-input-group .e-clear-icon::before,
  .e-outline.e-input-group.e-control-wrapper .e-clear-icon::before {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $outline-input-clear-icon;
    }
  }

  .e-outline.e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline label.e-float-text,
  .e-outline.e-float-input label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper label.e-float-text,
  .e-outline.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $outline-float-label-font-size;
    }
  }

  .e-outline.e-float-input.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-small.e-outline.e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-small.e-outline.e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small.e-outline.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-small label.e-float-text,
  .e-outline.e-float-input.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-small label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-small textarea ~ label.e-float-text,
  .e-outline.e-float-input textarea ~ label.e-float-text.e-small,
  .e-outline.e-float-input textarea.e-small ~ label.e-float-text,
  .e-small .e-outline.e-float-input textarea ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small textarea ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper textarea ~ label.e-float-text.e-small,
  .e-outline.e-float-input.e-control-wrapper textarea.e-small ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper textarea ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $small-outline-float-label-font-size;
    }
  }

  .e-outline.e-float-input input:-webkit-autofill ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-autofill input:-webkit-autofill ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      animation-name: slideTopUp;/* stylelint-disable-line no-unknown-animations */
    }
  }

  .e-small .e-outline.e-float-input input:-webkit-autofill ~ label.e-float-text,
  .e-small.e-outline.e-float-input input:-webkit-autofill ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-autofill input:-webkit-autofill ~ label.e-float-text,
  .e-small.e-outline.e-float-input.e-control-wrapper.e-autofill input:-webkit-autofill ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small.e-outline.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      animation-name: slideTopUp;/* stylelint-disable-line no-unknown-animations */
    }
  }

  .e-filled.e-input-group,
  .e-filled.e-input-group.e-control-wrapper,
  .e-filled.e-float-input,
  .e-filled.e-float-input.e-control-wrapper {
    line-height: 1;
  }

  .e-filled input.e-input#{$css},
  .e-filled.e-input-group input,
  .e-filled.e-input-group.e-control-wrapper input,
  .e-filled.e-input-group input.e-input,
  .e-filled.e-input-group.e-control-wrapper input.e-input,
  .e-filled.e-float-input:not(.e-input-group) input,
  .e-filled.e-float-input.e-control-wrapper:not(.e-input-group) input,
  .e-control .e-filled input.e-input,
  .e-control .e-filled.e-input-group input,
  .e-control .e-filled.e-input-group input.e-input,
  .e-control .e-filled.e-input-group.e-control-wrapper input,
  .e-control .e-filled.e-input-group.e-control-wrapper input.e-input,
  .e-control .e-filled.e-float-input input,
  .e-control .e-filled.e-float-input.e-control-wrapper input,
  .e-control.e-filled.e-input-group input,
  .e-control.e-filled.e-input-group input.e-input,
  .e-control.e-filled.e-input-group.e-control-wrapper input,
  .e-control.e-filled.e-input-group.e-control-wrapper input.e-input,
  .e-control.e-filled.e-float-input input,
  .e-control.e-filled.e-float-input.e-control-wrapper input,
  .e-filled input.e-input.e-small#{$css},
  .e-filled.e-input-group.e-small input,
  .e-filled.e-input-group.e-small input.e-input,
  .e-small .e-filled.e-input-group input,
  .e-small .e-filled.e-input-group input.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-small input,
  .e-filled.e-input-group.e-control-wrapper.e-small input.e-input,
  .e-small .e-filled.e-input-group.e-control-wrapper input,
  .e-small .e-filled.e-input-group.e-control-wrapper input.e-input,
  .e-filled.e-float-input.e-small input,
  .e-filled.e-float-input.e-small input.e-input,
  .e-small .e-filled.e-float-input input,
  .e-small .e-filled.e-float-input input.e-input,
  .e-filled.e-float-input.e-control-wrapper.e-small input,
  .e-filled.e-float-input.e-control-wrapper.e-small input.e-input,
  .e-small .e-filled.e-float-input.e-control-wrapper input,
  .e-small .e-filled.e-float-input.e-control-wrapper input.e-input,
  .e-filled.e-float-input.e-small:not(.e-input-group) input,
  .e-filled.e-float-input.e-small:not(.e-input-group) input.e-input,
  .e-small .e-filled.e-float-input:not(.e-input-group) input,
  .e-small .e-filled.e-float-input:not(.e-input-group) input.e-input .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-input-group) input,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-input-group) input.e-input,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-input-group) input,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-input-group) input.e-input,
  .e-filled textarea.e-input#{$css},
  .e-filled.e-input-group textarea,
  .e-filled.e-input-group.e-control-wrapper textarea,
  .e-filled.e-float-input textarea,
  .e-filled.e-float-input.e-control-wrapper textarea,
  .e-filled textarea.e-input.e-small#{$css},
  .e-filled.e-input-group.e-small textarea,
  .e-filled.e-input-group.e-small textarea.e-input,
  .e-small .e-filled.e-input-group textarea,
  .e-small .e-filled.e-input-group textarea.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-small textarea,
  .e-filled.e-input-group.e-control-wrapper.e-small textarea.e-input,
  .e-small .e-filled.e-input-group.e-control-wrapper textarea,
  .e-small .e-filled.e-input-group.e-control-wrapper textarea.e-input,
  .e-filled.e-float-input.e-small textarea,
  .e-filled.e-float-input.e-small textarea.e-input,
  .e-small .e-filled.e-float-input textarea,
  .e-small .e-filled.e-float-input textarea.e-input,
  .e-filled.e-float-input.e-control-wrapper.e-small textarea,
  .e-filled.e-float-input.e-control-wrapper.e-small textarea.e-input,
  .e-small .e-filled.e-float-input.e-control-wrapper textarea,
  .e-small .e-filled.e-float-input.e-control-wrapper textarea.e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      box-sizing: border-box;
    }
  }

  .e-filled.e-float-input input,
  .e-filled.e-float-input textarea,
  .e-filled.e-float-input.e-control-wrapper input,
  .e-filled.e-float-input.e-control-wrapper textarea {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      border: $float-input-border;
      border-width: $zero-value;
    }
  }

  .e-filled.e-float-input:hover:not(.e-input-group):not(.e-disabled) input:not([disabled]),
  .e-filled.e-float-input:hover:not(.e-input-group):not(.e-disabled) textarea:not([disabled]),
  .e-filled.e-float-input:hover:not(.e-input-group):not(.e-disabled) input:not([disabled]),
  .e-filled.e-float-input:hover:not(.e-input-group):not(.e-disabled) textarea:not([disabled]),
  .e-filled.e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-disabled) input:not([disabled]),
  .e-filled.e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-disabled) textarea:not([disabled]),
  .e-filled.e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-disabled) input:not([disabled]),
  .e-filled.e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-disabled) textarea:not([disabled]),
  .e-filled.e-input-group.e-float-icon-left:not(.e-disabled):not(.e-input-focus) .e-input-in-wrap:hover,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-disabled):not(.e-input-focus) .e-input-in-wrap:hover,
  .e-filled.e-float-input.e-float-icon-left:not(.e-disabled):not(.e-input-focus) .e-input-in-wrap:hover,
  .e-filled.e-float-input.e-control-wrapper.e-float-icon-left:not(.e-disabled):not(.e-input-focus) .e-input-in-wrap:hover {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      border-bottom-width: $zero-value;
    }
  }

  .e-filled.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) input,
  .e-filled.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error) input,
  .e-filled.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error) input,
  .e-filled.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error input,
  .e-filled.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) textarea,
  .e-filled.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error) textarea,
  .e-filled.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error) textarea,
  .e-filled.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error textarea,
  .e-filled.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) input,
  .e-filled.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error) input,
  .e-filled.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error) input,
  .e-filled.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error input,
  .e-filled.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) textarea,
  .e-filled.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error) textarea,
  .e-filled.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error) textarea,
  .e-filled.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error textarea,
  .e-filled.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error) input,
  .e-filled.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error) input,
  .e-filled.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error) input,
  .e-filled.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error input,
  .e-filled.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error) input,
  .e-filled.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error) input,
  .e-filled.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error) input,
  .e-filled.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      border-width: $zero-value;
    }
  }

  .e-filled.e-input-group,
  .e-filled.e-input-group.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      border-radius: 4px 4px $zero-value $zero-value;
      padding: $filled-wrapper-padding;
    }
  }

  .e-filled.e-input-group.e-small,
  .e-filled.e-input-group.e-control-wrapper.e-small,
  .e-small .e-filled.e-input-group.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      margin-top: $filled-wrapper-margin;
      padding: $small-filled-wrapper-padding;
    }
  }

  .e-filled.e-float-input,
  .e-filled.e-float-input.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      border: $input-group-full-border;
      border-radius: 4px 4px $zero-value $zero-value;
      border-width: $input-group-full-border-width;
      margin-top: $filled-wrapper-margin;
      padding: $filled-float-input-wrapper-padding;
    }
  }

  .e-filled.e-float-input.e-small,
  .e-filled.e-float-input.e-control-wrapper.e-small,
  .e-small .e-filled.e-float-input.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      margin-top: $filled-wrapper-margin;
      padding: $small-filled-float-input-wrapper-padding;
    }
  }

  .e-rtl.e-filled.e-input-group,
  .e-rtl.e-filled.e-input-group.e-control-wrapper,
  .e-rtl .e-filled.e-input-group,
  .e-rtl .e-filled.e-input-group.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-wrapper-rtl-padding;
    }
  }

  .e-rtl.e-filled.e-input-group.e-small,
  .e-rtl.e-filled.e-input-group.e-control-wrapper.e-small,
  .e-small .e-rtl.e-filled.e-input-group.e-control-wrapper,
  .e-rtl .e-filled.e-input-group.e-small,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-small,
  .e-small.e-rtl .e-filled.e-input-group.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $small-filled-wrapper-rtl-padding;
    }
  }

  .e-rtl.e-filled.e-float-input,
  .e-rtl.e-filled.e-float-input.e-control-wrapper,
  .e-rtl .e-filled.e-float-input,
  .e-rtl .e-filled.e-float-input.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-float-input-wrapper-rtl-padding;
    }
  }

  .e-rtl.e-filled.e-float-input.e-small,
  .e-rtl.e-filled.e-float-input.e-control-wrapper.e-small,
  .e-small .e-rtl.e-filled.e-float-input.e-control-wrapper,
  .e-rtl .e-filled.e-float-input.e-small,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-small,
  .e-small.e-rtl .e-filled.e-float-input.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $small-filled-float-input-wrapper-rtl-padding;
    }
  }

  .e-filled input.e-input#{$css},
  .e-filled.e-input-group input.e-input,
  .e-filled.e-input-group.e-control-wrapper input.e-input,
  .e-filled textarea.e-input#{$css},
  .e-filled.e-input-group textarea.e-input,
  .e-filled.e-input-group.e-control-wrapper textarea.e-input,
  .e-filled.e-input-group input.e-input:focus,
  .e-filled.e-input-group.e-control-wrapper input.e-input:focus,
  .e-filled.e-input-group textarea.e-input:focus,
  .e-filled.e-input-group.e-control-wrapper textarea.e-input:focus,
  .e-filled.e-input-group.e-input-focus input.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-input-focus input.e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-input-padding;
    }
  }

  .e-filled .e-input#{$css}:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding-bottom: $filled-input-padding-bottom;
    }
  }

  .e-filled .e-input.e-small#{$css}:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding-bottom: $filled-small-input-padding-bottom;
    }
  }

  .e-filled .e-input#{$css}.e-small,
  .e-filled.e-input-group.e-small .e-input,
  .e-filled.e-input-group.e-control-wrapper.e-small .e-input,
  .e-filled.e-input-group.e-small .e-input:focus,
  .e-filled.e-input-group.e-control-wrapper.e-small .e-input:focus,
  .e-filled.e-input-group.e-small.e-input-focus .e-input,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-input-focus .e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-small-input-padding;
    }
  }

  .e-filled.e-float-input input,
  .e-filled.e-float-input.e-control-wrapper input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-float-input-padding;
    }
  }

  .e-filled.e-float-input.e-small input,
  .e-filled.e-float-input.e-control-wrapper.e-small input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-small-float-input-padding;
    }
  }

  .e-filled input.e-input.e-rtl#{$css},
  .e-filled.e-input-group.e-rtl input.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-rtl input.e-input,
  .e-rtl .e-filled.e-input-group input.e-input,
  .e-rtl .e-filled.e-input-group.e-control-wrapper input.e-input,
  .e-filled.e-input-group.e-rtl input.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-rtl input.e-input,
  .e-rtl .e-filled.e-input-group input.e-input,
  .e-rtl .e-filled.e-input-group.e-control-wrapper input.e-input,
  .e-filled.e-input-group.e-rtl input.e-input:focus,
  .e-filled.e-input-group.e-control-wrapper.e-rtl input.e-input:focus,
  .e-rtl .e-filled.e-input-group input.e-input:focus,
  .e-rtl .e-filled.e-input-group.e-control-wrapper input.e-input:focus,
  .e-filled.e-input-group.e-rtl.e-input-focus input.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-rtl.e-input-focus input.e-input,
  .e-rtl .e-filled.e-input-group.e-input-focus input.e-input,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-input-focus input.e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-input-rtl-padding;
      text-indent: 0;
    }
  }

  .e-filled.e-float-input.e-rtl input,
  .e-filled.e-float-input.e-control-wrapper.e-rtl input,
  .e-rtl .e-filled.e-float-input input,
  .e-rtl .e-filled.e-float-input.e-control-wrapper input,
  .e-filled.e-float-input.e-rtl input,
  .e-filled.e-float-input.e-control-wrapper.e-rtl input,
  .e-rtl .e-filled.e-float-input input,
  .e-rtl .e-filled.e-float-input.e-control-wrapper input,
  .e-filled.e-float-input.e-rtl input:focus,
  .e-filled.e-float-input.e-control-wrapper.e-rtl input:focus,
  .e-rtl .e-filled.e-float-input input:focus,
  .e-rtl .e-filled.e-float-input.e-control-wrapper input:focus,
  .e-filled.e-float-input.e-rtl.e-input-focus input,
  .e-filled.e-float-input.e-control-wrapper.e-rtl.e-input-focus input,
  .e-rtl .e-filled.e-float-input.e-input-focus input,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-focus input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-float-input-rtl-padding;
      text-indent: 0;
    }
  }

  .e-rtl .e-filled input.e-input.e-small#{$css},
  .e-filled input.e-input#{$css}.e-small.e-rtl,
  .e-small.e-rtl .e-filled input.e-input#{$css},
  .e-small .e-filled input.e-input.e-rtl#{$css},
  .e-filled.e-input-group.e-small.e-rtl input.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-rtl input.e-input,
  .e-rtl .e-filled.e-input-group.e-small input.e-input,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-small input.e-input,
  .e-filled.e-input-group.e-rtl input.e-input.e-small,
  .e-filled.e-input-group.e-control-wrapper.e-rtl input.e-input.e-small,
  .e-rtl .e-filled.e-input-group input.e-input.e-small,
  .e-rtl .e-filled.e-input-group.e-control-wrapper input.e-input.e-small,
  .e-small .e-filled.e-input-group.e-rtl input.e-input,
  .e-small .e-filled.e-input-group.e-control-wrapper.e-rtl input.e-input,
  .e-small.e-rtl .e-filled.e-input-group.e-control-wrapper input.e-input,
  .e-small.e-rtl .e-filled.e-input-group input.e-input,
  .e-small.e-rtl .e-filled.e-input-group.e-control-wrapper input.e-input:focus,
  .e-small.e-rtl .e-filled.e-input-group input.e-input:focus,
  .e-small .e-filled.e-input-group.e-control-wrapper.e-rtl input.e-input:focus,
  .e-small .e-filled.e-input-group.e-rtl input.e-input:focus,
  .e-small.e-rtl .e-filled.e-input-group.e-control-wrapper.e-input-focus input.e-input,
  .e-small.e-rtl .e-filled.e-input-group.e-input-focus input.e-input,
  .e-small .e-filled.e-input-group.e-control-wrapper.e-rtl.e-input-focus input.e-input,
  .e-small .e-filled.e-input-group.e-rtl.e-input-focus input.e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-small-input-rtl-padding;
      text-indent: 0;
    }
  }

  .e-filled.e-float-input.e-control-wrapper.e-small.e-rtl input,
  .e-filled.e-float-input.e-small.e-rtl input,
  .e-rtl .e-filled.e-float-input.e-small input,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-small input,
  .e-filled.e-float-input.e-rtl input.e-small,
  .e-filled.e-float-input.e-control-wrapper.e-rtl input.e-small,
  .e-rtl .e-filled.e-float-input input.e-small,
  .e-rtl .e-filled.e-float-input.e-control-wrapper input.e-small,
  .e-small .e-filled.e-float-input.e-rtl input,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-rtl input,
  .e-small.e-rtl .e-filled.e-float-input.e-control-wrapper input,
  .e-small.e-rtl .e-filled.e-float-input input,
  .e-small.e-rtl .e-filled.e-float-input.e-control-wrapper input:focus,
  .e-small.e-rtl .e-filled.e-float-input input:focus,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-rtl input:focus,
  .e-small .e-filled.e-float-input.e-rtl input:focus,
  .e-small.e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-focus input,
  .e-small.e-rtl .e-filled.e-float-input.e-input-focus input,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-rtl.e-input-focus input,
  .e-small .e-filled.e-float-input.e-rtl.e-input-focus input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-small-float-input-rtl-padding;
      text-indent: 0;
    }
  }

  .e-filled.e-float-input,
  .e-filled.e-float-input.e-control-wrapper,
  .e-filled.e-float-input.e-disabled,
  .e-filled.e-float-input.e-control-wrapper.e-disabled,
  .e-filled.e-float-input.e-input-group.e-disabled,
  .e-filled.e-float-input.e-input-group.e-control-wrapper.e-disabled {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $filled-input-font-size;
    }
  }

  .e-filled.e-float-input.e-small,
  .e-small .e-filled.e-float-input,
  .e-filled.e-float-input.e-control-wrapper.e-small,
  .e-small .e-filled.e-float-input.e-control-wrapper,
  .e-filled.e-float-input.e-small.e-disabled,
  .e-small .e-filled.e-float-input.e-disabled,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-disabled,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-disabled,
  .e-filled.e-float-input.e-input-group.e-small.e-disabled,
  .e-small .e-filled.e-float-input.e-input-group.e-disabled,
  .e-filled.e-float-input.e-input-group.e-control-wrapper.e-small.e-disabled,
  .e-small .e-filled.e-float-input.e-input-group.e-control-wrapper.e-disabled {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $small-filled-input-font-size;
    }
  }

  .e-filled.e-input-group.e-small:not(.e-float-input) .e-input,
  .e-small .e-filled.e-input-group:not(.e-float-input) .e-input,
  .e-filled.e-input-group.e-control-wrapper.e-small:not(.e-float-input) .e-input,
  .e-small .e-filled.e-input-group.e-control-wrapper:not(.e-float-input) .e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      min-height: $small-filled-default-input-min-height;
    }
  }

  .e-filled.e-float-input.e-small input,
  .e-small .e-filled.e-float-input input,
  .e-filled.e-float-input.e-input-group.e-small input,
  .e-small .e-filled.e-float-input.e-input-group input,
  .e-filled.e-float-input.e-input-group.e-control-wrapper.e-small input,
  .e-small .e-filled.e-float-input.e-input-group.e-control-wrapper input,
  .e-filled.e-float-input.e-control-wrapper.e-small input,
  .e-small .e-filled.e-float-input.e-control-wrapper input,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small input,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-input-group input,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small input,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-input-group input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      min-height: $small-filled-input-min-height;
    }
  }

  .e-filled.e-input-group input.e-input,
  .e-filled.e-input-group.e-control-wrapper input.e-input,
  .e-filled.e-input-group:not(.e-float-input) input.e-input,
  .e-filled.e-input-group:not(.e-float-input).e-control-wrapper input.e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      min-height: $filled-default-input-min-height;
    }
  }

  .e-float-input.e-filled.e-input-group.e-control-wrapper input,
  .e-float-input.e-filled input,
  .e-float-input.e-filled.e-control-wrapper input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      min-height: $filled-input-min-height;
    }
  }

  .e-filled label.e-float-text,
  .e-filled.e-float-input label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper label.e-float-text,
  .e-filled.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $filled-float-label-font-size;
      left: $filled-input-label-left;
      letter-spacing: .009375em;
      line-height: 1.15;
      padding-left: $float-label-padding;
      pointer-events: none;
      right: auto;
      top: $filled-input-label-top;
      transform: none;
      transition: transform 150ms cubic-bezier(.4, $zero-value, .2, 1), color 150ms cubic-bezier(.4, $zero-value, .2, 1);
      width: 100%;
    }
  }

  .e-filled.e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $filled-float-label-font-size;
      padding-left: $float-label-padding;
      top: $filled-input-label-top;
      transform: none;
      width: 100%;
    }
  }

  .e-filled.e-float-input input:focus ~ label.e-float-text,
  .e-filled.e-float-input input:valid ~ label.e-float-text,
  .e-filled.e-float-input input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input input label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper input label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $filled-float-label-font-size;
      top: $filled-input-label-top-after-floating;
      transform: translateY(-50%) scale(.75);
    }
    @if $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      left: 1px;
    }
  }

  .e-filled.e-float-input textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input textarea label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper textarea label.e-float-text.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $filled-float-label-font-size;
      top: $filled-input-label-top-after-floating;
      transform: translateY(-50%) scale(.75);
    }
  }

  .e-filled.e-float-input input:-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-autofill input:-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $filled-float-label-font-size;
      top: $filled-input-label-top-after-floating;
      transform: translateY(-50%) scale(.75);
      user-select: text;
    }
  }

  .e-filled.e-float-input.e-small input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small input ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small input ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-small textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small textarea ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small textarea ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper textarea ~ label.e-label-top.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $small-filled-float-label-font-size;
      top: $small-filled-input-label-top-after-floating;
    }
  }

  .e-small .e-filled.e-float-input input:-webkit-autofill ~ label.e-float-text,
  .e-small.e-filled.e-float-input input:-webkit-autofill ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-autofill input:-webkit-autofill ~ label.e-float-text,
  .e-small.e-filled.e-float-input.e-control-wrapper.e-autofill input:-webkit-autofill ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small.e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-small.e-filled.e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-small.e-filled.e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small.e-filled.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $small-filled-float-label-font-size;
      top: $small-filled-input-label-top-after-floating;
      transform: translateY(-50%) scale(.75);
      user-select: text;
    }
  }

  .e-filled.e-float-input.e-small label.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small textarea ~ label.e-float-text,
  .e-filled.e-float-input textarea ~ label.e-float-text.e-small,
  .e-filled.e-float-input textarea.e-small ~ label.e-float-text,
  .e-small .e-filled.e-float-input textarea ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small textarea ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper textarea ~ label.e-float-text.e-small,
  .e-filled.e-float-input.e-control-wrapper textarea.e-small ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper textarea ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $small-filled-float-label-font-size;
      top: $small-filled-input-label-top;
    }
  }

  .e-filled.e-float-input label.e-float-text,
  .e-filled.e-float-input label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper label.e-float-text.e-label-bottom,
  .e-filled.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      left: $filled-input-label-left;
    }
  }

  .e-filled.e-float-input.e-rtl label.e-float-text,
  .e-filled.e-float-input.e-rtl label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input label.e-float-text .e-filled.e-float-input.e-control-wrapper.e-rtl label.e-float-text,
  .e-filled.e-float-input.e-rtl.e-control-wrapper label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input.e-control-wrapper label.e-float-text,
  .e-filled.e-float-input.e-rtl:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-rtl:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      right: $filled-input-label-left;
    }
  }

  .e-filled.e-float-input.e-small label.e-float-text,
  .e-filled.e-float-input.e-small label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      left: $small-filled-input-label-left;
    }
  }

  .e-filled.e-float-input.e-small.e-rtl label.e-float-text,
  .e-filled.e-float-input.e-rtl.e-small label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input.e-small label.e-float-text .e-filled.e-float-input.e-control-wrapper.e-rtl.e-small label.e-float-text,
  .e-filled.e-float-input.e-rtl.e-control-wrapper.e-small label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-small label.e-float-text,
  .e-small .e-filled.e-float-input.e-rtl label.e-float-text,
  .e-small .e-filled.e-float-input.e-rtl label.e-float-text.e-label-bottom,
  .e-rtl.e-small .e-filled.e-float-input label.e-float-text .e-small .e-filled.e-float-input.e-control-wrapper.e-rtl label.e-float-text,
  .e-small .e-filled.e-float-input.e-rtl.e-control-wrapper label.e-float-text.e-label-bottom,
  .e-rtl.e-small .e-filled.e-float-input.e-control-wrapper label.e-float-text,
  .e-small.e-filled.e-float-input.e-rtl:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-small.e-filled.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-small.e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-rtl:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-small .e-filled.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-small .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-filled.e-float-input.e-rtl:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-small.e-filled.e-float-input:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-small.e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-rtl:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-small .e-filled.e-float-input:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-small .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      right: $small-filled-input-label-left;
    }
  }

  .e-filled.e-float-input .e-float-line,
  .e-float-input.e-filled.e-control-wrapper .e-float-line {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      bottom: -1px;
      position: absolute;
    }
  }

  .e-float-input.e-filled .e-float-line,
  .e-float-input.e-filled .e-float-text,
  .e-float-input.e-filled.e-control-wrapper .e-float-line,
  .e-float-input.e-filled.e-control-wrapper .e-float-text,
  .e-filled.e-float-input.e-rtl .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-rtl .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input .e-input-in-wrap label.e-float-text .e-filled.e-float-input.e-control-wrapper.e-rtl .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-rtl.e-control-wrapper .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-rtl:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small.e-rtl .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-rtl.e-small .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input.e-small .e-input-in-wrap label.e-float-text .e-filled.e-float-input.e-control-wrapper.e-rtl.e-small .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-rtl.e-control-wrapper.e-small .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-small .e-input-in-wrap label.e-float-text,
  .e-small .e-filled.e-float-input.e-rtl .e-input-in-wrap label.e-float-text,
  .e-small .e-filled.e-float-input.e-rtl .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-rtl.e-small .e-filled.e-float-input .e-input-in-wrap label.e-float-text .e-small .e-filled.e-float-input.e-control-wrapper.e-rtl .e-input-in-wrap label.e-float-text,
  .e-small .e-filled.e-float-input.e-rtl.e-control-wrapper .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-rtl.e-small .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text,
  .e-small.e-filled.e-float-input.e-rtl:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-small.e-filled.e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-small.e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-rtl:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-small .e-filled.e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-small .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      right: $zero-value;
    }
  }

  .e-filled.e-input-group:not(.e-disabled):not(.e-float-icon-left)::before,
  .e-filled.e-filled.e-input-group:not(.e-disabled):not(.e-float-icon-left)::after,
  .e-filled.e-input-group.e-control-wrapper:not(.e-disabled):not(.e-float-icon-left)::before,
  .e-filled.e-input-group.e-control-wrapper:not(.e-disabled):not(.e-float-icon-left)::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      bottom: -1px;
    }
    @if $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      bottom: -.1px;
    }
  }

  .e-filled.e-input-group .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-filled.e-input-group.e-small .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper.e-small .e-input-group-icon,
  .e-small .e-filled.e-input-group .e-input-group-icon,
  .e-small .e-filled.e-input-group.e-control-wrapper .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $filled-input-icon-size;
      margin-bottom: $zero-value;
      margin-top: $zero-value;
      min-height: $filled-input-icon-min-height;
      min-width: $filled-input-icon-min-height;
      padding: $zero-value $zero-value $zero-value 8px;
    }
  }

  .e-rtl.e-filled.e-input-group .e-input-group-icon,
  .e-rtl.e-filled.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-rtl.e-filled.e-input-group.e-small .e-input-group-icon,
  .e-rtl.e-filled.e-input-group.e-control-wrapper.e-small .e-input-group-icon,
  .e-small .e-rtl.e-filled.e-input-group .e-input-group-icon,
  .e-small .e-rtl.e-filled.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-rtl .e-filled.e-input-group .e-input-group-icon,
  .e-rtl .e-filled.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-rtl .e-filled.e-input-group.e-small .e-input-group-icon,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-small .e-input-group-icon,
  .e-small.e-rtl .e-filled.e-input-group .e-input-group-icon,
  .e-small.e-rtl .e-filled.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-rtl.e-filled.e-float-input.e-input-group .e-input-group-icon,
  .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon,
  .e-rtl.e-filled.e-float-input.e-input-group.e-small .e-input-group-icon,
  .e-small .e-rtl.e-filled.e-float-input.e-input-group .e-input-group-icon,
  .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon,
  .e-small .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon,
  .e-rtl .e-filled.e-float-input.e-input-group .e-input-group-icon,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon,
  .e-rtl .e-filled.e-float-input.e-input-group.e-small .e-input-group-icon,
  .e-small.e-rtl .e-filled.e-float-input.e-input-group .e-input-group-icon,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon,
  .e-small.e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $zero-value 8px $zero-value $zero-value;
    }
  }

  .e-filled.e-input-group.e-small .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper.e-small .e-input-group-icon,
  .e-small .e-filled.e-input-group .e-input-group-icon,
  .e-small .e-filled.e-input-group.e-control-wrapper .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $small-filled-input-icon-size;
      min-height: $small-filled-input-icon-min-height;
      min-width: $small-filled-input-icon-min-height;
      padding: $zero-value $zero-value $zero-value 4px;
    }
  }

  .e-rtl.e-filled.e-input-group.e-small .e-input-group-icon,
  .e-rtl.e-filled.e-input-group.e-control-wrapper.e-small .e-input-group-icon,
  .e-small .e-rtl.e-filled.e-input-group .e-input-group-icon,
  .e-small .e-rtl.e-filled.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-rtl .e-filled.e-input-group.e-small .e-input-group-icon,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-small .e-input-group-icon,
  .e-small.e-rtl .e-filled.e-input-group .e-input-group-icon,
  .e-small.e-rtl .e-filled.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-rtl.e-filled.e-float-input.e-input-group.e-small .e-input-group-icon,
  .e-small .e-rtl.e-filled.e-float-input.e-input-group .e-input-group-icon,
  .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon,
  .e-small .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon,
  .e-rtl .e-filled.e-float-input.e-input-group.e-small .e-input-group-icon,
  .e-small.e-rtl .e-filled.e-float-input.e-input-group .e-input-group-icon,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon,
  .e-small.e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $zero-value 4px $zero-value $zero-value;
    }
  }

  .e-filled.e-float-input.e-input-group .e-input-group-icon,
  .e-filled.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon,
  .e-filled.e-float-input.e-input-group.e-small .e-input-group-icon,
  .e-small .e-filled.e-float-input.e-input-group .e-input-group-icon,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $filled-input-icon-size;
      margin-bottom: $zero-value;
      margin-top: $zero-value;
      padding: 8px;
      @if $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
        padding: 0;
        margin: 9px 12px 9px 0;
      }
    }
  }

  .e-filled.e-float-input.e-input-group.e-small .e-input-group-icon,
  .e-small .e-filled.e-float-input.e-input-group .e-input-group-icon,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $small-filled-input-icon-size;
      padding: $zero-value $zero-value $zero-value 4px;
    }
  }

  .e-filled.e-float-input .e-clear-icon,
  .e-filled.e-float-input.e-control-wrapper .e-clear-icon,
  .e-filled.e-input-group .e-clear-icon,
  .e-filled.e-input-group.e-control-wrapper .e-clear-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $filled-input-clear-icon-size;
      padding: $zero-value $zero-value $zero-value 8px;
    }
    @if $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $filled-input-clear-icon-size;
      padding: $input-clear-icon-padding;
    }
  }

  .e-filled.e-input-group .e-clear-icon,
  .e-filled.e-input-group.e-control-wrapper .e-clear-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      min-height: $filled-input-icon-min-height;
      min-width: $filled-input-icon-min-height;
      padding: $zero-value $zero-value $zero-value 8px;
    }
    @if $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      min-height: $filled-input-icon-min-height;
      min-width: $filled-input-icon-min-height;
      padding: $input-clear-icon-padding;
    }
  }

  .e-filled.e-float-input.e-input-group .e-clear-icon,
  .e-filled.e-float-input.e-input-group.e-control-wrapper .e-clear-icon {
    @if $input-skin-name == 'material' {
      padding: $zero-value $zero-value $zero-value 8px;
    }
    @if $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $zero-value;
      margin: 2px;
      height: 32px;
    }
  }

  .e-filled.e-input-group.e-small .e-clear-icon,
  .e-filled.e-input-group .e-clear-icon.e-small,
  .e-small .e-filled.e-input-group .e-clear-icon,
  .e-filled.e-input-group.e-control-wrapper.e-small .e-clear-icon,
  .e-filled.e-input-group.e-control-wrapper .e-clear-icon.e-small,
  .e-small .e-filled.e-input-group.e-control-wrapper .e-clear-icon {
    @if $input-skin-name == 'material' {
      font-size: $small-filled-input-clear-icon-size;
      min-height: $small-filled-input-icon-min-height;
      min-width: $small-filled-input-icon-min-height;
      padding: $zero-value $zero-value $zero-value 4px;
    }
    @if $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $small-filled-input-clear-icon-size;
      min-height: $small-filled-input-icon-min-height;
      min-width: $small-filled-input-icon-min-height;
      padding: $zero-value;
    }
  }

  .e-filled.e-input-group.e-float-input.e-small .e-clear-icon,
  .e-filled.e-input-group.e-float-input .e-clear-icon.e-small,
  .e-small .e-filled.e-input-group.e-float-input .e-clear-icon,
  .e-filled.e-input-group.e-control-wrapper.e-float-input.e-small .e-clear-icon,
  .e-filled.e-input-group.e-control-wrapper.e-float-input .e-clear-icon.e-small,
  .e-small .e-filled.e-input-group.e-control-wrapper.e-float-input .e-clear-icon,
  .e-filled.e-input-group.e-float-input.e-control-wrapper.e-small .e-clear-icon,
  .e-filled.e-input-group.e-float-input.e-control-wrapper .e-clear-icon.e-small,
  .e-small .e-filled.e-input-group.e-float-input.e-control-wrapper .e-clear-icon,
  .e-filled.e-input-group.e-control-wrapper.e-float-input.e-small .e-clear-icon,
  .e-filled.e-input-group.e-control-wrapper.e-float-input .e-clear-icon.e-small,
  .e-small .e-filled.e-input-group.e-control-wrapper.e-float-input .e-clear-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      padding: $zero-value $zero-value $zero-value 4px;
    }
  }

  .e-rtl.e-filled.e-input-group .e-clear-icon,
  .e-rtl.e-filled.e-input-group.e-control-wrapper .e-clear-icon,
  .e-rtl.e-filled.e-input-group.e-control-wrapper.e-small .e-clear-icon,
  .e-small .e-rtl.e-filled.e-input-group .e-clear-icon,
  .e-small .e-rtl.e-filled.e-input-group.e-control-wrapper .e-clear-icon,
  .e-rtl .e-filled.e-input-group .e-clear-icon,
  .e-rtl .e-filled.e-input-group.e-control-wrapper .e-clear-icon,
  .e-rtl .e-filled.e-input-group.e-small .e-clear-icon,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-small .e-clear-icon,
  .e-small.e-rtl .e-filled.e-input-group .e-clear-icon,
  .e-small.e-rtl .e-filled.e-input-group.e-control-wrapper .e-clear-icon,
  .e-rtl.e-filled.e-float-input.e-input-group .e-clear-icon,
  .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group .e-clear-icon,
  .e-rtl.e-filled.e-float-input.e-input-group.e-small .e-clear-icon,
  .e-small .e-rtl.e-filled.e-float-input.e-input-group .e-clear-icon,
  .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-clear-icon,
  .e-small .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group .e-clear-icon,
  .e-rtl .e-filled.e-float-input.e-input-group .e-clear-icon,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group .e-clear-icon,
  .e-rtl .e-filled.e-float-input.e-input-group.e-small .e-clear-icon,
  .e-small.e-rtl .e-filled.e-float-input.e-input-group .e-clear-icon,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-clear-icon,
  .e-small.e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group .e-clear-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      padding: $zero-value 8px $zero-value $zero-value;
    }
  }

  .e-rtl.e-filled.e-input-group.e-small .e-clear-icon,
  .e-rtl.e-filled.e-input-group.e-control-wrapper.e-small .e-clear-icon,
  .e-small .e-rtl.e-filled.e-input-group .e-clear-icon,
  .e-small .e-rtl.e-filled.e-input-group.e-control-wrapper .e-clear-icon,
  .e-rtl .e-filled.e-input-group.e-small .e-clear-icon,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-small .e-clear-icon,
  .e-small.e-rtl .e-filled.e-input-group .e-clear-icon,
  .e-small.e-rtl .e-filled.e-input-group.e-control-wrapper .e-clear-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      padding: $zero-value 4px $zero-value $zero-value;
    }
  }

  .e-rtl.e-filled.e-float-input.e-input-group.e-small .e-clear-icon,
  .e-small .e-rtl.e-filled.e-float-input.e-input-group .e-clear-icon,
  .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-clear-icon,
  .e-small .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group .e-clear-icon,
  .e-rtl .e-filled.e-float-input.e-input-group.e-small .e-clear-icon,
  .e-small.e-rtl .e-filled.e-float-input.e-input-group .e-clear-icon,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-clear-icon,
  .e-small.e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group .e-clear-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      padding: $zero-value 4px $zero-value $zero-value;
    }
    @if $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $zero-value;
    }
  }

  .e-filled.e-float-input .e-clear-icon::before,
  .e-filled.e-float-input.e-control-wrapper .e-clear-icon::before,
  .e-filled.e-input-group .e-clear-icon::before,
  .e-filled.e-input-group.e-control-wrapper .e-clear-icon::before {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $filled-input-clear-icon-size;
    }
  }

  .e-filled.e-float-input.e-small .e-clear-icon::before,
  .e-filled.e-float-input.e-control-wrapper.e-small .e-clear-icon::before,
  .e-filled.e-input-group.e-small .e-clear-icon::before,
  .e-filled.e-input-group.e-control-wrapper.e-small .e-clear-icon::before,
  .e-filled.e-float-input.e-control-wrapper input.e-small:first-child ~ .e-clear-icon::before,
  .e-small .e-filled.e-float-input.e-control-wrapper .e-clear-icon::before,
  .e-filled.e-float-input input.e-small:first-child ~ .e-clear-icon::before,
  .e-small .e-filled.e-float-input .e-clear-icon::before,
  .e-small .e-filled.e-input-group .e-clear-icon::before,
  .e-small .e-filled.e-input-group.e-control-wrapper .e-clear-icon::before {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $small-filled-input-clear-icon-size;
    }
  }

  .e-filled.e-float-input .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-filled.e-float-input .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-filled.e-float-input .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input .e-input-in-wrap input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input .e-input-in-wrap input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input .e-input-in-wrap input label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap input label.e-float-text.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      top: $filled-input-label-top-after-floating;
    }
  }

  .e-filled.e-float-input.e-small .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small .e-input-in-wrap input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small .e-input-in-wrap input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small .e-input-in-wrap input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small .e-input-in-wrap input[disabled] ~ label.e-label-top.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      top: $small-filled-input-label-top-after-floating;
    }
  }

  .e-filled.e-input-group.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-filled.e-input-group.e-float-icon-left.e-input-focus.e-success:not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-filled.e-input-group.e-float-icon-left.e-input-focus.e-warning:not(.e-success):not(.e-error) .e-input-in-wrap,
  .e-filled.e-input-group.e-float-icon-left.e-input-focus.e-error:not(.e-success):not(.e-warning) .e-input-in-wrap,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left.e-input-focus.e-success:not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left.e-input-focus.e-warning:not(.e-success):not(.e-error) .e-input-in-wrap,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left.e-input-focus.e-error:not(.e-success):not(.e-warning) .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      border-style: none;
      border-width: $zero-value;
    }
  }

  .e-filled.e-float-input .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      top: $filled-input-label-top;
    }
  }

  .e-filled.e-float-input.e-small .e-input-in-wrap label.e-float-text,
  .e-small .e-filled.e-float-input .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small .e-input-in-wrap label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      top: $small-filled-input-label-top;
    }
  }

  .e-filled.e-input-group:not(.e-float-input).e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group:not(.e-float-input).e-control-wrapper.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $filled-input-icon-size;
      margin: $zero-value;
      min-height: $filled-input-left-icon-min-height;
      min-width: $filled-input-left-icon-min-width;
      padding: $zero-value;
    }
  }

  .e-filled.e-input-group:not(.e-float-input).e-small.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group:not(.e-float-input).e-float-icon-left > .e-input-group-icon.e-small,
  .e-filled.e-input-group:not(.e-float-input).e-control-wrapper.e-small.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group:not(.e-float-input).e-control-wrapper.e-float-icon-left > .e-input-group-icon.e-small,
  .e-small .e-filled.e-input-group:not(.e-float-input).e-float-icon-left > .e-input-group-icon,
  .e-small .e-filled.e-input-group:not(.e-float-input).e-control-wrapper.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $small-filled-input-icon-size;
      margin: $zero-value;
      min-height: $small-filled-input-left-icon-min-height;
      min-width: $small-filled-input-left-icon-min-width;
      padding: $zero-value;
    }
  }

  .e-filled.e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-float-input.e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-float-input.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      margin: $zero-value;
      min-height: $filled-input-left-icon-min-height;
      min-width: $filled-input-left-icon-min-width;
      padding: $zero-value;
    }
  }

  .e-filled.e-input-group.e-small.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group.e-float-icon-left > .e-input-group-icon.e-small,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon.e-small,
  .e-small .e-filled.e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-small .e-filled.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-float-input.e-input-group.e-small.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-float-input.e-input-group.e-float-icon-left > .e-input-group-icon.e-small,
  .e-small .e-filled.e-float-input.e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left > .e-input-group-icon.e-small,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      margin: $zero-value;
      min-height: $small-filled-input-left-icon-min-height;
      min-width: $small-filled-input-left-icon-min-width;
      padding: $zero-value;
    }
  }

  .e-filled.e-float-input .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-filled.e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-small .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      left: $zero-value;
    }
  }

  .e-filled.e-input-group .e-input-group-icon:last-child,
  .e-filled.e-input-group.e-small .e-input-group-icon:last-child,
  .e-small .e-filled.e-input-group .e-input-group-icon:last-child,
  .e-filled.e-input-group.e-control-wrapper .e-input-group-icon:last-child,
  .e-filled.e-input-group.e-small.e-control-wrapper .e-input-group-icon:last-child,
  .e-small .e-filled.e-input-group.e-control-wrapper .e-input-group-icon:last-child,
  .e-filled.e-input-group .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-filled.e-input-group.e-small .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper.e-small .e-input-group-icon,
  .e-small .e-filled.e-input-group .e-input-group-icon,
  .e-small .e-filled.e-input-group.e-control-wrapper .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      margin-right: $zero-value;
    }
  }

  .e-filled.e-input-group.e-rtl .e-input-group-icon:last-child,
  .e-filled.e-input-group.e-small.e-rtl .e-input-group-icon:last-child,
  .e-small .e-filled.e-input-group.e-rtl .e-input-group-icon:last-child,
  .e-filled.e-input-group.e-control-wrapper.e-rtl .e-input-group-icon:last-child,
  .e-filled.e-input-group.e-small.e-control-wrapper.e-rtl .e-input-group-icon:last-child,
  .e-small .e-filled.e-input-group.e-control-wrapper.e-rtl .e-input-group-icon:last-child,
  .e-rtl .e-filled.e-input-group .e-input-group-icon:last-child,
  .e-rtl .e-filled.e-input-group.e-small .e-input-group-icon:last-child,
  .e-rtl.e-small .e-filled.e-input-group .e-input-group-icon:last-child,
  .e-rtl .e-filled.e-input-group.e-control-wrapper .e-input-group-icon:last-child,
  .e-rtl .e-filled.e-input-group.e-small.e-control-wrapper .e-input-group-icon:last-child,
  .e-rtl.e-small .e-filled.e-input-group.e-control-wrapper .e-input-group-icon:last-child {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      margin-left: $zero-value;
    }
  }

  .e-filled.e-rtl.e-input-group .e-input-group-icon,
  .e-filled.e-rtl.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-rtl .e-filled.e-input-group .e-input-group-icon,
  .e-rtl .e-filled.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-filled.e-input-group.e-small.e-rtl .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-rtl .e-input-group-icon,
  .e-small .e-filled.e-input-group.e-rtl .e-input-group-icon,
  .e-small .e-filled.e-input-group.e-control-wrapper.e-rtl .e-input-group-icon,
  .e-rtl .e-filled.e-input-group.e-small .e-input-group-icon,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-small .e-input-group-icon,
  .e-rtl.e-small .e-filled.e-input-group .e-input-group-icon,
  .e-rtl.e-small .e-filled.e-input-group.e-control-wrapper .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      margin-left: $zero-value;
      margin-right: $zero-value;
    }
  }

  .e-filled textarea.e-input#{$css},
  .e-filled.e-input-group.e-multi-line-input textarea,
  .e-filled.e-input-group.e-control-wrapper.e-multi-line-input textarea,
  .e-filled.e-float-input.e-multi-line-input textarea,
  .e-filled.e-float-input.e-control-wrapper.e-multi-line-input textarea {
    box-sizing: border-box;
  }

  .e-filled textarea.e-input#{$css},
  .e-filled.e-input-group textarea,
  .e-filled.e-input-group textarea.e-input,
  .e-filled.e-input-group.e-input-focus textarea,
  .e-filled.e-input-group.e-input-focus textarea.e-input,
  .e-filled.e-input-group.e-control-wrapper textarea,
  .e-filled.e-input-group.e-control-wrapper.e-input-focus textarea,
  .e-filled.e-input-group.e-control-wrapper textarea.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-input-focus textarea.e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-textarea-padding;
    }
  }

  .e-filled.e-float-input textarea,
  .e-filled.e-float-input.e-control-wrapper textarea {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-float-textarea-padding;
    }
  }

  .e-filled.e-input-group.e-small textarea,
  .e-filled.e-input-group.e-small textarea.e-input,
  .e-filled.e-input-group textarea.e-small,
  .e-filled.e-input-group textarea.e-input.e-small,
  .e-filled.e-input-group.e-control-wrapper.e-small textarea,
  .e-filled.e-input-group.e-control-wrapper.e-small textarea.e-input,
  .e-small .e-filled.e-input-group textarea,
  .e-small .e-filled.e-input-group textarea.e-input,
  .e-filled.e-input-group.e-input-focus.e-small textarea,
  .e-filled.e-input-group.e-input-focus.e-small textarea.e-input,
  .e-filled.e-input-group.e-input-focus textarea.e-small,
  .e-filled.e-input-group.e-input-focus textarea.e-input.e-small,
  .e-filled.e-input-group.e-input-focus textarea.e-input.e-small,
  .e-filled.e-input-group.e-control-wrapper.e-input-focus.e-small textarea,
  .e-filled.e-input-group.e-control-wrapper.e-input-focus.e-small textarea.e-input,
  .e-small .e-filled.e-input-group.e-input-focus textarea,
  .e-small .e-filled.e-input-group.e-input-focus textarea.e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-small-textarea-padding;
    }
  }

  .e-filled.e-float-input.e-small textarea,
  .e-filled.e-float-input textarea.e-small,
  .e-filled.e-float-input.e-control-wrapper.e-small textarea,
  .e-filled.e-float-input.e-control-wrapper textarea.e-small,
  .e-small .e-filled.e-float-input textarea,
  .e-small .e-filled.e-float-input.e-control-wrapper textarea {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-small-float-textarea-padding;
    }
  }

  .e-filled.e-input-group.e-multi-line-input,
  .e-filled.e-input-group.e-control-wrapper.e-multi-line-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-textarea-wrapper-padding;
    }
  }

  .e-filled.e-input-group.e-small.e-multi-line-input,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-multi-line-input,
  .e-small .e-filled.e-input-group.e-control-wrapper.e-multi-line-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $small-filled-textarea-wrapper-padding;
    }
  }

  .e-filled.e-float-input.e-multi-line-input,
  .e-filled.e-float-input.e-control-wrapper.e-multi-line-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-float-textarea-wrapper-padding;
    }
  }

  .e-filled.e-float-input.e-small.e-multi-line-input,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-multi-line-input,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-multi-line-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $small-filled-float-textarea-wrapper-padding;
    }
  }

  .e-filled textarea.e-input.e-rtl#{$css},
  .e-filled.e-input-group.e-multi-line-input.e-rtl textarea.e-input,
  .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-rtl textarea.e-input,
  .e-rtl .e-filled.e-input-group.e-multi-line-input textarea.e-input,
  .e-rtl .e-filled.e-input-group.e-multi-line-input.e-control-wrapper textarea.e-input,
  .e-filled.e-input-group.e-multi-line-input.e-rtl textarea.e-input,
  .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-rtl textarea.e-input,
  .e-rtl .e-filled.e-input-group.e-multi-line-input textarea.e-input,
  .e-rtl .e-filled.e-input-group.e-multi-line-input.e-control-wrapper textarea.e-input,
  .e-filled.e-input-group.e-multi-line-input.e-rtl textarea.e-input:focus,
  .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-rtl textarea.e-input:focus,
  .e-rtl .e-filled.e-input-group.e-multi-line-input textarea.e-input:focus,
  .e-rtl .e-filled.e-input-group.e-multi-line-input.e-control-wrapper textarea.e-input:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-textarea-padding;
      text-indent: 0;
    }
  }

  .e-filled.e-float-input.e-multi-line-input.e-rtl textarea,
  .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-rtl textarea,
  .e-rtl .e-filled.e-float-input.e-multi-line-input textarea,
  .e-rtl .e-filled.e-float-input.e-multi-line-input.e-control-wrapper textarea,
  .e-filled.e-float-input.e-multi-line-input.e-rtl textarea,
  .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-rtl textarea,
  .e-rtl .e-filled.e-float-input.e-multi-line-input textarea,
  .e-rtl .e-filled.e-float-input.e-multi-line-input.e-control-wrapper textarea,
  .e-filled.e-float-input.e-multi-line-input.e-rtl textarea:focus,
  .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-rtl textarea:focus,
  .e-rtl .e-filled.e-float-input.e-multi-line-input textarea:focus,
  .e-rtl .e-filled.e-float-input.e-multi-line-input.e-control-wrapper textarea:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-float-textarea-padding;
      text-indent: 0;
    }
  }

  .e-rtl .e-filled textarea.e-input.e-small#{$css},
  .e-filled textarea.e-input#{$css}.e-small.e-rtl,
  .e-small.e-rtl .e-filled textarea.e-input#{$css},
  .e-small .e-filled textarea.e-input.e-rtl#{$css},
  .e-filled.e-input-group.e-multi-line-input.e-small.e-rtl textarea.e-input,
  .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-small.e-rtl textarea.e-input,
  .e-rtl .e-filled.e-input-group.e-multi-line-input.e-small textarea.e-input,
  .e-rtl .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-small textarea.e-input,
  .e-filled.e-input-group.e-multi-line-input.e-rtl textarea.e-input.e-small,
  .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-rtl textarea.e-input.e-small,
  .e-rtl .e-filled.e-input-group.e-multi-line-input textarea.e-input.e-small,
  .e-rtl .e-filled.e-input-group.e-multi-line-input.e-control-wrapper textarea.e-input.e-small,
  .e-small .e-filled.e-input-group.e-multi-line-input.e-rtl textarea.e-input,
  .e-small .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-rtl textarea.e-input,
  .e-small.e-rtl .e-filled.e-input-group.e-multi-line-input.e-control-wrapper textarea.e-input,
  .e-small.e-rtl .e-filled.e-input-group.e-multi-line-input textarea.e-input,
  .e-small.e-rtl .e-filled.e-input-group.e-multi-line-input.e-control-wrapper textarea.e-input:focus,
  .e-small.e-rtl .e-filled.e-input-group.e-multi-line-input textarea.e-input:focus,
  .e-small .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-rtl textarea.e-input:focus,
  .e-small .e-filled.e-input-group.e-multi-line-input.e-rtl textarea.e-input:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-small-textarea-padding;
      text-indent: 0;
    }
  }

  .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-small.e-rtl textarea,
  .e-filled.e-float-input.e-multi-line-input.e-small.e-rtl textarea,
  .e-rtl .e-filled.e-float-input.e-multi-line-input.e-small textarea,
  .e-rtl .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-small textarea,
  .e-filled.e-float-input.e-multi-line-input.e-rtl textarea.e-small,
  .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-rtl textarea.e-small,
  .e-rtl .e-filled.e-float-input.e-multi-line-input textarea.e-small,
  .e-rtl .e-filled.e-float-input.e-multi-line-input.e-control-wrapper textarea.e-small,
  .e-small .e-filled.e-float-input.e-multi-line-input.e-rtl textarea,
  .e-small .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-rtl textarea,
  .e-small.e-rtl .e-filled.e-float-input.e-multi-line-input.e-control-wrapper textarea,
  .e-small.e-rtl .e-filled.e-float-input.e-multi-line-input textarea,
  .e-small.e-rtl .e-filled.e-float-input.e-multi-line-input.e-control-wrapper textarea:focus,
  .e-small.e-rtl .e-filled.e-float-input.e-multi-line-input textarea:focus,
  .e-small .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-rtl textarea:focus,
  .e-small .e-filled.e-float-input.e-multi-line-input.e-rtl textarea:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-small-float-textarea-padding;
      text-indent: 0;
    }
  }

  .e-filled.e-float-input.e-float-icon-left .e-input-in-wrap,
  .e-filled.e-float-input.e-control-wrapper.e-float-icon-left .e-input-in-wrap,
  .e-filled.e-input-group.e-float-icon-left .e-input-in-wrap,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      border: $input-group-full-border;
      border-width: $zero-value;
      margin-left: 8px;
    }
  }

  .e-rtl .e-filled.e-float-input.e-float-icon-left .e-input-in-wrap,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-float-icon-left .e-input-in-wrap,
  .e-rtl .e-filled.e-input-group.e-float-icon-left .e-input-in-wrap,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-float-icon-left .e-input-in-wrap .e-filled.e-float-input.e-control-wrapper.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-filled.e-float-input.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-filled.e-input-group.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left.e-rtl .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      margin-left: $zero-value;
      margin-right: 8px;
    }
  }

  .e-filled.e-float-input.e-float-icon-left.e-small .e-input-in-wrap,
  .e-filled.e-float-input.e-control-wrapper.e-float-icon-left.e-small .e-input-in-wrap,
  .e-filled.e-input-group.e-float-icon-left.e-small .e-input-in-wrap,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left.e-small .e-input-in-wrap,
  .e-small .e-filled.e-float-input.e-float-icon-left .e-input-in-wrap,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-float-icon-left .e-input-in-wrap,
  .e-small .e-filled.e-input-group.e-float-icon-left .e-input-in-wrap,
  .e-small .e-filled.e-input-group.e-control-wrapper.e-float-icon-left .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      margin-left: 4px;
    }
  }

  .e-rtl.e-small .e-filled.e-float-input.e-float-icon-left .e-input-in-wrap,
  .e-rtl.e-small .e-filled.e-float-input.e-control-wrapper.e-float-icon-left .e-input-in-wrap,
  .e-rtl.e-small .e-filled.e-input-group.e-float-icon-left .e-input-in-wrap,
  .e-rtl.e-small .e-filled.e-input-group.e-control-wrapper.e-float-icon-left .e-input-in-wrap,
  .e-rtl .e-small.e-filled.e-float-input.e-float-icon-left .e-input-in-wrap,
  .e-rtl .e-small.e-filled.e-float-input.e-control-wrapper.e-float-icon-left .e-input-in-wrap,
  .e-rtl .e-small.e-filled.e-input-group.e-float-icon-left .e-input-in-wrap,
  .e-rtl .e-small.e-filled.e-input-group.e-control-wrapper.e-float-icon-left .e-input-in-wrap,
  .e-small.e-filled.e-float-input.e-control-wrapper.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-small.e-filled.e-float-input.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-small.e-filled.e-input-group.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-small.e-filled.e-input-group.e-control-wrapper.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-small .e-filled.e-float-input.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-small .e-filled.e-input-group.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-small .e-filled.e-input-group.e-control-wrapper.e-float-icon-left.e-rtl .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      margin-left: $zero-value;
      margin-right: 4px;
    }
  }

  .e-filled.e-input-group.e-float-icon-left:not(.e-float-input)::before,
  .e-filled.e-input-group.e-float-icon-left:not(.e-float-input)::after,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)::before,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      @include input-group-animation;
      bottom: -1px;
    }
  }

  .e-filled.e-input-group.e-float-icon-left:not(.e-float-input).e-input-focus::before,
  .e-filled.e-input-group.e-float-icon-left:not(.e-float-input).e-input-focus::after,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input).e-input-focus::before,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input).e-input-focus::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      @include input-group-animation-width;
      bottom: -1px;
    }
  }

  .e-filled.e-input-group.e-float-icon-left:not(.e-float-input).e-input-focus .e-input-in-wrap::before,
  .e-filled.e-input-group.e-float-icon-left:not(.e-float-input).e-input-focus .e-input-in-wrap::after,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input).e-input-focus .e-input-in-wrap::before,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input).e-input-focus .e-input-in-wrap::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      width: $zero-value;
    }
  }

  .e-filled.e-input-group.e-float-icon-left,
  .e-filled.e-input-group.e-success.e-float-icon-left,
  .e-filled.e-input-group.e-warning.e-float-icon-left,
  .e-filled.e-input-group.e-error.e-float-icon-left,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left,
  .e-filled.e-input-group.e-control-wrapper.e-success.e-float-icon-left,
  .e-filled.e-input-group.e-control-wrapper.e-warning.e-float-icon-left,
  .e-filled.e-input-group.e-control-wrapper.e-error.e-float-icon-left {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      border: $input-group-full-border;
      border-width: $input-group-full-border-width;
    }
  }

  .e-input-group.e-control-container.e-control-wrapper.e-float-input.e-prepend.e-append:not(.e-outline) .e-input-in-wrap,
  .e-input-group.e-control-container.e-control-wrapper.e-float-input.e-prepend:not(.e-outline) .e-input-in-wrap {
    @if $input-skin-name == 'material' {
      border-bottom: 1px solid;
      border-bottom-color: $input-box-border-color;
    }
  }

  .e-error.e-input-group.e-control-container.e-control-wrapper.e-float-input.e-prepend.e-append .e-input-in-wrap,
  .e-error.e-input-group.e-control-container.e-control-wrapper.e-float-input.e-prepend .e-input-in-wrap {
    @if $input-skin-name == 'material' {
      border-bottom: 1px solid;
      border-bottom-color: $error-font-color;
    }
  }

  .e-success.e-input-group.e-control-container.e-control-wrapper.e-float-input.e-prepend.e-append .e-input-in-wrap,
  .e-success.e-input-group.e-control-container.e-control-wrapper.e-float-input.e-prepend .e-input-in-wrap {
    @if $input-skin-name == 'material' {
      border-bottom: 1px solid;
      border-bottom-color: $success-font-color;
    }
  }

  .e-input-group:not(.e-outline).e-prepend.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group:not(.e-outline).e-control-wrapper.e-prepend.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-float-input:not(.e-outline).e-input-group.e-prepend.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-float-input:not(.e-outline).e-input-group.e-prepend.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-float-input:not(.e-outline).e-input-group.e-prepend.e-input-focus:not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error),
  .e-float-input:not(.e-outline).e-input-group.e-prepend.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error,
  .e-float-input:not(.e-outline).e-control-wrapper.e-input-group.e-prepend.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-float-input:not(.e-outline).e-control-wrapper.e-input-group.e-prepend.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-float-input:not(.e-outline).e-control-wrapper.e-input-group.e-prepend.e-input-focus:not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error),
  .e-float-input:not(.e-outline).e-control-wrapper.e-input-group.e-prepend.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error,
  .e-float-input:not(.e-outline).e-control-wrapper.e-prepend.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) input,
  .e-float-input:not(.e-outline).e-control-wrapper.e-prepend.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error) input,
  .e-float-input:not(.e-outline).e-control-wrapper.e-prepend.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error) input,
  .e-float-input:not(.e-outline).e-control-wrapper.e-prepend.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error input,
  .e-float-input:not(.e-outline).e-control-wrapper.e-prepend.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) textarea,
  .e-float-input:not(.e-outline).e-control-wrapper.e-prepend.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error) textarea,
  .e-float-input:not(.e-outline).e-control-wrapper.e-prepend.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error) textarea,
  .e-float-input:not(.e-outline).e-control-wrapper.e-prepend.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error textarea,
  .e-float-input:not(.e-outline).e-prepend.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) input,
  .e-float-input:not(.e-outline).e-prepend.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error) input,
  .e-float-input:not(.e-outline).e-prepend.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error) input,
  .e-float-input:not(.e-outline).e-prepend.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error input,
  .e-float-input:not(.e-outline).e-prepend.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) textarea,
  .e-float-input:not(.e-outline).e-prepend.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error) textarea,
  .e-float-input:not(.e-outline).e-prepend.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error) textarea,
  .e-float-input:not(.e-outline).e-prepend.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error textarea,
  .e-input-group:not(.e-outline).e-prepend.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-input-group:not(.e-outline).e-prepend.e-input-focus:not(.e-float-icon-left).e-warning:not(.e-success):not(.e-error),
  .e-input-group:not(.e-outline).e-control-wrapper.e-prepend.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-input-group:not(.e-outline).e-control-wrapper.e-prepend.e-input-focus:not(.e-float-icon-left).e-warning:not(.e-success):not(.e-error),
  .e-input-group:not(.e-outline).e-prepend.e-input-focus:not(.e-float-icon-left).e-error:not(.e-success):not(.e-warning),
  .e-input-group:not(.e-outline).e-float-icon-left.e-prepend.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-input-group:not(.e-outline).e-float-icon-left.e-prepend.e-input-focus.e-success:not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-input-group:not(.e-outline).e-float-icon-left.e-prepend.e-input-focus.e-warning:not(.e-success):not(.e-error) .e-input-in-wrap,
  .e-input-group:not(.e-outline).e-float-icon-left.e-prepend.e-input-focus.e-error:not(.e-success):not(.e-warning) .e-input-in-wrap,
  .e-input-group:not(.e-outline).e-prepend:not(.e-float-icon-left),
  .e-input-group:not(.e-outline).e-prepend.e-success:not(.e-float-icon-left),
  .e-input-group:not(.e-outline).e-prepend.e-warning:not(.e-float-icon-left),
  .e-input-group:not(.e-outline).e-prepend.e-error:not(.e-float-icon-left),
  .e-input-group:not(.e-outline).e-control-wrapper.e-prepend:not(.e-float-icon-left),
  .e-input-group:not(.e-outline).e-control-wrapper.e-prepend.e-success:not(.e-float-icon-left),
  .e-input-group:not(.e-outline).e-control-wrapper.e-prepend.e-warning:not(.e-float-icon-left),
  .e-input-group:not(.e-outline).e-control-wrapper.e-prepend.e-error:not(.e-float-icon-left),
  .e-input-group:not(.e-outline).e-prepend:hover:not(.e-disabled):not(.e-float-icon-left),
  .e-input-group:not(.e-outline).e-control-wrapper.e-prepend:hover:not(.e-disabled):not(.e-float-icon-left),
  .e-float-input:not(.e-outline).e-prepend:hover:not(.e-disabled):not(.e-float-icon-left),
  .e-float-input:not(.e-outline).e-prepend:hover:not(.e-input-group):not(.e-disabled) input:not([disabled]),
  .e-float-input:not(.e-outline).e-prepend:hover:not(.e-input-group):not(.e-disabled) textarea:not([disabled]),
  .e-float-input:not(.e-outline).e-prepend:hover:not(.e-input-group):not(.e-disabled) input:not([disabled]),
  .e-float-input:not(.e-outline).e-prepend:hover:not(.e-input-group):not(.e-disabled) textarea:not([disabled]),
  .e-float-input:not(.e-outline).e-control-wrapper.e-prepend:hover:not(.e-disabled):not(.e-float-icon-left),
  .e-float-input:not(.e-outline).e-control-wrapper.e-prepend:hover:not(.e-input-group):not(.e-disabled) input:not([disabled]),
  .e-float-input:not(.e-outline).e-control-wrapper.e-prepend:hover:not(.e-input-group):not(.e-disabled) textarea:not([disabled]),
  .e-float-input:not(.e-outline).e-control-wrapper.e-prepend:hover:not(.e-input-group):not(.e-disabled) input:not([disabled]),
  .e-float-input:not(.e-outline).e-control-wrapper.e-prepend:hover:not(.e-input-group):not(.e-disabled) textarea:not([disabled]) {
    @if $input-skin-name == 'material' {
      border-bottom: transparent;
    }
  }
}


/* stylelint-disable-line no-empty-source */


@include export-module('input-theme') {

  /*! input theme */

  input.e-input#{$css},
  .e-input-group input.e-input,
  .e-input-group.e-control-wrapper input.e-input,
  .e-input-group textarea.e-input,
  .e-input-group.e-control-wrapper textarea.e-input,
  textarea.e-input#{$css},
  .e-float-input input,
  .e-float-input.e-control-wrapper input,
  .e-float-input textarea,
  .e-float-input.e-control-wrapper textarea {
    background: $input-bg-color;
    border-color: $input-box-border-color;
    color: $input-font-color;
  }

  .e-input-group,
  .e-input-group.e-control-wrapper,
  .e-float-input,
  .e-float-input.e-input-group,
  .e-float-input.e-control-wrapper,
  .e-float-input.e-input-group.e-control-wrapper {
    background: $input-bg-color;
    color: $input-font-color;
    @if $input-skin-name == 'fluent2' {
      border-bottom-color: $input-border-bottom-color;
    }
  }

  .e-input-group .e-input-group-icon,
  .e-input-group.e-control-wrapper .e-input-group-icon {
    background: transparent;
    border-color: $input-box-border-color;
    color: $input-icon-font-color;
    @if ($input-skin-name == 'FluentUI') {
      border-color: $input-group-icon-border-color;
    }
    @if ($input-skin-name == 'Material3' or $input-skin-name == 'fluent2') {
      margin: 0;
    }
  }

  .e-input-group.e-disabled .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-disabled .e-input-group-icon,
  .e-float-input.e-disabled .e-clear-icon,
  .e-float-input.e-control-wrapper.e-disabled .e-clear-icon,
  .e-input-group.e-disabled .e-clear-icon,
  .e-input-group.e-control-wrapper.e-disabled .e-clear-icon {
    color: $input-group-disabled-color;
  }

  /* stylelint-disable property-no-vendor-prefix */
  /* stylelint-disable selector-no-vendor-prefix */
  .e-input#{$css}[disabled],
  .e-input-group .e-input[disabled],
  .e-input-group.e-control-wrapper .e-input[disabled],
  .e-input-group.e-disabled,
  .e-input-group.e-control-wrapper.e-disabled,
  .e-float-input input[disabled],
  .e-float-input.e-control-wrapper input[disabled],
  .e-float-input textarea[disabled],
  .e-float-input.e-control-wrapper textarea[disabled],
  .e-float-input.e-disabled,
  .e-float-input.e-control-wrapper.e-disabled  {
    @if ($input-skin-name != 'bootstrap5' and $input-skin-name != 'bootstrap5.3') {
      -webkit-text-fill-color: $input-disable-font-color;
    }
    background: $input-disable-bg-color;
    color: $input-disable-font-color;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      background-image: linear-gradient(90deg, $input-disable-border-color 0, $input-disable-border-color 33%, transparent 0);
      background-position: bottom -1px left 0;
      background-repeat: repeat-x;
      background-size: 4px 1px;
      border-bottom-color: transparent;
    }

    @else {
      border-color: $input-disable-border-color;
    }
  }

  .e-input-group input.e-input,
  .e-input-group.e-control-wrapper input.e-input,
  .e-float-input input,
  .e-float-input.e-control-wrapper input,
  .e-input-group textarea.e-input,
  .e-input-group.e-control-wrapper textarea.e-input,
  .e-float-input textarea,
  .e-float-input.e-control-wrapper textarea,
  .e-input-group .e-input[disabled],
  .e-input-group.e-control-wrapper .e-input[disabled],
  .e-input-group.e-disabled input.e-input,
  .e-input-group.e-control-wrapper.e-disabled input.e-input,
  .e-input-group.e-disabled textarea.e-input,
  .e-input-group.e-control-wrapper.e-disabled textarea.e-input {
    background: none;
    color: inherit;
  }

  .e-input-group .e-input[readonly],
  .e-input-group.e-control-wrapper .e-input[readonly],
  .e-float-input input[readonly],
  .e-float-input.e-control-wrapper input[readonly],
  .e-float-input textarea[readonly],
  .e-float-input.e-control-wrapper textarea[readonly] {
    background: $input-readonly-bg-color;
    @if $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3'  {
      color: $input-readonly-color;
    }
    @if $input-skin-name != 'bootstrap4' and $input-skin-name != 'bootstrap5' and $input-skin-name != 'bootstrap5.3' {
      color: inherit;
    }
  }

  .e-input-group .e-input[readonly] ~ span.e-input-group-icon,
  .e-input-group.e-control-wrapper .e-input[readonly] ~ span.e-input-group-icon,
  .e-float-input input[readonly] ~ span.e-input-group-icon,
  .e-float-input.e-control-wrapper input[readonly] ~ span.e-input-group-icon,
  .e-float-input.e-control-wrapper input[readonly] ~ span.e-clear-icon {
    @if $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' {
      background: $input-readonly-bg-color;
      color: $input-icon-font-color;
    }
  }

  .e-float-input.e-disabled input,
  .e-float-input.e-control-wrapper.e-disabled input,
  .e-float-input.e-disabled textarea,
  .e-float-input.e-control-wrapper.e-disabled textarea,
  .e-float-input input[disabled],
  .e-float-input.e-control-wrapper input[disabled],
  .e-float-input textarea[disabled],
  .e-float-input.e-control-wrapper textarea[disabled] {
    color: inherit;
  }

  /*! Added color to textbox for disbaled state */
  .e-float-input:not(.e-disabled) input[disabled],
  .e-float-input.e-control-wrapper:not(.e-disabled) input[disabled],
  .e-float-input:not(.e-disabled) textarea[disabled],
  .e-float-input.e-control-wrapper:not(.e-disabled) textarea[disabled] {
    -webkit-text-fill-color: $input-disable-font-color;
    color: $input-disable-font-color;
  }

  .e-input-group.e-disabled .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-disabled .e-input-group-icon {
    background: $input-disabled-group-bg-color;
    border-color: $input-disable-border-color;
  }

  .e-input-group:not(.e-disabled) .e-input-group-icon:hover,
  .e-input-group.e-control-wrapper:not(.e-disabled) .e-input-group-icon:hover {
    @if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'highcontrast' {
      background: $input-hover-bg-color;
    }
    @if $input-skin-name == 'bootstrap4' {
      border-color: $input-group-border-color-hover;
    }
    @if $input-skin-name == 'highcontrast' {
      border-color: $input-group-border-color-hover;
    }
    @if $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      border: $input-icon-hover-border;
      background: $input-icon-hover-bg-color;
    }
    color: $input-group-hovered-color;
  }

  .e-input-group.e-disabled .e-input-group-icon:hover,
  .e-input-group.e-control-wrapper.e-disabled .e-input-group-icon:hover {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      background: transparent;
    }
  }

  .e-input#{$css}.e-success,
  .e-input#{$css}.e-success:focus,
  .e-input-group.e-success input.e-input,
  .e-input-group.e-control-wrapper.e-success input.e-input,
  .e-input-group.e-success .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-success .e-input-group-icon,
  .e-input-group.e-success textarea.e-input,
  .e-input-group.e-control-wrapper.e-success textarea.e-input {
    border-color: $input-success-color;
  }

  .e-input#{$css}.e-warning,
  .e-input#{$css}.e-warning:focus,
  .e-input-group.e-warning input.e-input,
  .e-input-group.e-control-wrapper.e-warning input.e-input,
  .e-input-group.e-warning .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-warning .e-input-group-icon,
  .e-input-group.e-warning textarea.e-input,
  .e-input-group.e-control-wrapper.e-warning textarea.e-input {
    border-color: $input-warning-color;
  }

  .e-input + .e-input-group-icon:not(.e-input-group):not(.e-float-icon-left):last-child:hover,
  .e-input-group.e-success .e-input + .e-input-group-icon:last-child:hover,
  .e-input-group.e-success.e-control-wrapper .e-input + .e-input-group-icon:last-child:hover {
    @if $input-skin-name == 'highcontrast' {
      border-color: $input-success-color;
    }
  }

  .e-input#{$css}.e-error,
  .e-input#{$css}.e-error:focus,
  .e-input-group.e-error input.e-input,
  .e-input-group.e-control-wrapper.e-error input.e-input,
  .e-input-group.e-control-wrapper.e-error .e-input-group-icon,
  .e-input-group.e-error .e-input-group-icon,
  .e-input-group.e-error textarea.e-input,
  .e-input-group.e-control-wrapper.e-error textarea.e-input {
    border-color: $input-error-color;
  }

  label.e-float-text,
  .e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    color: $input-header-font-color;
    @if $input-skin-name == 'highcontrast' {
      color: $input-placeholder;
    }
  }

  .e-float-input.e-error label.e-float-text,
  .e-float-input.e-control-wrapper.e-error label.e-float-text,
  .e-float-input.e-error input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-error input:focus ~ label.e-float-text,
  .e-float-input.e-error.e-input-focus input ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-error.e-input-focus input ~ label.e-float-text,
  .e-float-input.e-error textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-error textarea:focus ~ label.e-float-text {
    color: $input-error-color;
    @if $input-skin-name == 'highcontrast' {
      color: $content-font;
    }
    @if ($input-skin-name == 'FluentUI') {
      color: $float-label-font-color;
    }
  }

  .e-float-input input,
  .e-float-input textarea,
  .e-float-input.e-control-wrapper input,
  .e-float-input.e-control-wrapper textarea,
  .e-input-group,
  .e-input-group.e-control-wrapper {
    border-bottom-color: $input-box-border-color;
  }

  .e-float-input.e-success input,
  .e-float-input.e-success textarea,
  .e-float-input.e-control-wrapper.e-success input,
  .e-float-input.e-control-wrapper.e-success textarea {
    @include input-state-color ($input-success-color);
  }

  .e-float-input.e-warning input,
  .e-float-input.e-control-wrapper.e-warning input,
  .e-float-input.e-warning textarea,
  .e-float-input.e-control-wrapper.e-warning textarea {
    @include input-state-color ($input-warning-color);
  }

  .e-float-input.e-error input,
  .e-float-input.e-control-wrapper.e-error input,
  .e-float-input.e-error textarea,
  .e-float-input.e-control-wrapper.e-error textarea {
    @include input-state-color ($input-error-color);
  }

  label.e-float-text,
  .e-float-input label.e-float-text,
  .e-float-input.e-control-wrapper label.e-float-text,
  .e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  label.e-float-text,
  .e-float-input label.e-float-text,
  .e-float-input.e-control-wrapper label.e-float-text,
  .e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,

  .e-float-input.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,

  .e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,

  .e-float-input.e-control-wrapper.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,

  .e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,

  .e-float-input.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,

  .e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,

  .e-float-input.e-control-wrapper.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'highcontrast' {
      color: $content-font-alt;
    }
  }

  .e-float-input:not(.e-input-group) .e-float-line::before,
  .e-float-input:not(.e-input-group) .e-float-line::after,
  .e-float-input:not(.e-input-group) .e-float-line::before,
  .e-float-input:not(.e-input-group) .e-float-line::after,
  .e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::before,
  .e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::after,
  .e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::before,
  .e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      background: $input-active-accent-color;
    }
  }

  .e-float-input.e-success:not(.e-input-group) .e-float-line::before,
  .e-float-input.e-success:not(.e-input-group) .e-float-line::after,
  .e-float-input.e-success:not(.e-input-group) .e-float-line::before,
  .e-float-input.e-success:not(.e-input-group) .e-float-line::after,
  .e-float-input.e-control-wrapper.e-success:not(.e-input-group) .e-float-line::before,
  .e-float-input.e-control-wrapper.e-success:not(.e-input-group) .e-float-line::after,
  .e-float-input.e-control-wrapper.e-success:not(.e-input-group) .e-float-line::before,
  .e-float-input.e-control-wrapper.e-success:not(.e-input-group) .e-float-line::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      background: $input-success-color;
    }
  }

  .e-float-input.e-warning:not(.e-input-group) .e-float-line::before,
  .e-float-input.e-warning:not(.e-input-group) .e-float-line::after,
  .e-float-input.e-warning:not(.e-input-group) .e-float-line::before,
  .e-float-input.e-warning:not(.e-input-group) .e-float-line::after,
  .e-float-input.e-control-wrapper.e-warning:not(.e-input-group) .e-float-line::before,
  .e-float-input.e-control-wrapper.e-warning:not(.e-input-group) .e-float-line::after,
  .e-float-input.e-control-wrapper.e-warning:not(.e-input-group) .e-float-line::before,
  .e-float-input.e-control-wrapper.e-warning:not(.e-input-group) .e-float-line::after  {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      background: $input-warning-color;
    }
  }

  input.e-input#{$css}:-moz-placeholder,
  input.e-input#{$css}:-moz-placeholder,
  textarea.e-input#{$css}:-moz-placeholder,
  input.e-input#{$css}::-moz-placeholder,
  textarea.e-input#{$css}::-moz-placeholder,
  .e-input-group input.e-input:-moz-placeholder,
  .e-input-group input.e-input:-moz-placeholder,
  .e-input-group.e-control-wrapper input.e-input:-moz-placeholder,
  .e-input-group input.e-input:-moz-placeholder,
  .e-input-group.e-control-wrapper input.e-input:-moz-placeholder,
  .e-input-group textarea.e-input:-moz-placeholder,
  .e-input-group.e-control-wrapper textarea.e-input:-moz-placeholder,
  .e-input-group input.e-input::-moz-placeholder,
  .e-input-group.e-control-wrapper input.e-input::-moz-placeholder,
  .e-input-group textarea.e-input::-moz-placeholder,
  .e-input-group.e-control-wrapper textarea.e-input::-moz-placeholder {
    color: $input-placeholder;
  }

  input.e-input#{$css}::-webkit-input-placeholder,
  textarea.e-input#{$css}::-webkit-input-placeholder,
  .e-input-group input.e-input::-webkit-input-placeholder,
  .e-input-group textarea.e-input::-webkit-input-placeholder,
  .e-input-group.e-control-wrapper input.e-input::-webkit-input-placeholder,
  .e-input-group.e-control-wrapper textarea.e-input::-webkit-input-placeholder {
    color: $input-placeholder;
  }

  input.e-input#{$css}:-ms-input-placeholder,
  textarea.e-input#{$css}:-ms-input-placeholder,
  .e-input-group input.e-input:-ms-input-placeholder,
  .e-input-group.e-control-wrapper input.e-input:-ms-input-placeholder,
  .e-input-group.e-control-wrapper textarea.e-input:-ms-input-placeholder,
  .e-input-group textarea.e-input:-ms-input-placeholder {
    color: $input-placeholder;
  }

  .e-float-input:not(.e-error):not(.e-input-focus):not(.e-disabled) input:not(:focus):not(:valid) ~ label.e-float-text:not(.e-label-top),
  .e-float-input:not(.e-error):not(.e-input-focus) textarea:not(:focus):not(:valid) ~ label.e-float-text:not(.e-label-top),
  .e-float-input.e-control-wrapper:not(.e-error):not(.e-input-focus):not(.e-disabled) input:not(:focus):not(:valid) ~ label.e-float-text:not(.e-label-top),
  .e-float-input:not(.e-input-focus):not(.e-disabled) textarea:not(:focus):not(:valid) ~ label.e-float-text:not(.e-label-top),
  .e-float-input:not(.e-input-focus) textarea:not(:focus):not(:valid) ~ label.e-float-text:not(.e-label-top),
  .e-float-input.e-control-wrapper:not(.e-input-focus):not(.e-disabled) textarea:not(:focus):not(:valid) ~ label.e-float-text:not(.e-label-top) {
    color: $input-placeholder;
  }

  .e-float-input.e-error:not(.e-input-group) .e-float-line::before,
  .e-float-input.e-error:not(.e-input-group) .e-float-line::after,
  .e-float-input.e-error:not(.e-input-group) .e-float-line::before,
  .e-float-input.e-error:not(.e-input-group) .e-float-line::after,
  .e-float-input.e-control-wrapper.e-error:not(.e-input-group) .e-float-line::before,
  .e-float-input.e-control-wrapper.e-error:not(.e-input-group) .e-float-line::after,
  .e-float-input.e-control-wrapper.e-error:not(.e-input-group) .e-float-line::before,
  .e-float-input.e-control-wrapper.e-error:not(.e-input-group) .e-float-line::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      background: $input-error-color;
    }
  }

  .e-input-group:not(.e-disabled) .e-input-group-icon:active,
  .e-input-group.e-control-wrapper:not(.e-disabled) .e-input-group-icon:active {
    @if $skin-name != 'bootstrap5.3' and $skin-name != 'tailwind3' {
      background: $input-group-pressed-bg;
    }
    color: $input-group-pressed-color;
    @if $input-skin-name == 'bootstrap' {
      -webkit-box-shadow: $input-icon-shadow;
      box-shadow: $input-icon-shadow;
    }
    @if $input-skin-name == 'bootstrap4' {
      -webkit-box-shadow: $input-icon-shadow;
      border-color: $pressed-border-color;
      box-shadow: $input-icon-shadow;
    }
    @if $input-skin-name == 'highcontrast' {
      border-left-color: transparent;
      border-right-color: transparent;
    }
  }

  .e-float-input.e-input-group.e-small.e-float-icon-left .e-input-group-icon:last-child:hover,
  .e-float-input.e-input-group.e-float-icon-left .e-input-group-icon:last-child:hover,
  .e-float-input.e-control-wrapper.e-input-group.e-small.e-float-icon-left .e-input-group-icon:last-child:hover,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-group-icon:last-child:hover,
  .e-float-input.e-input-group.e-small:not(.e-float-icon-left):not(.e-input-group):not(.e-disabled) .e-input-group-icon:last-child:hover,
  .e-float-input.e-control-wrapper.e-input-group.e-small:not(.e-float-icon-left):not(.e-input-group):not(.e-disabled) .e-input-group-icon:last-child:hover,
  .e-input-group.e-small:not(.e-disabled):not(.e-float-input):not(.e-rtl) .e-input-group-icon:last-child:hover,
  .e-input-group.e-float-icon-left .e-input-group-icon:last-child:hover,
  .e-input-group.e-control-wrapper.e-small:not(.e-disabled):not(.e-float-input):not(.e-rtl) .e-input-group-icon:last-child:hover,
  .e-input-group.e-control-wrapper.e-float-icon-left .e-input-group-icon:last-child:hover,
  .e-input-group:not(.e-rtl):not(.e-disabled) .e-input + .e-input-group-icon:last-child:hover,
  .e-input-group.e-control-wrapper:not(.e-rtl):not(.e-disabled) .e-input + .e-input-group-icon:last-child:hover,
  .e-input-group:not(.e-disabled) .e-input-group-icon:not(:last-child):hover + .e-input-group-icon:not(:last-child):hover,
  .e-input-group.e-control-wrapper:not(.e-disabled) .e-input-group-icon:not(:last-child):hover + .e-input-group-icon:not(:last-child):hover,
  .e-input-group:not(.e-rtl):not(.e-disabled) .e-input-group-icon:last-child:hover,
  .e-input-group.e-control-wrapper:not(.e-rtl):not(.e-disabled) .e-input-group-icon:last-child:hover {
    @if $input-skin-name == 'highcontrast' {
      border-right-color: transparent;
      background-color: $input-hover-bg-color;
    }
  }

  .e-float-input.e-input-group.e-small.e-float-icon-left .e-input-group-icon:not(:last-child):hover,
  .e-float-input.e-input-group.e-float-icon-left .e-input-group-icon:not(:last-child):hover,
  .e-float-input.e-control-wrapper.e-input-group.e-small.e-float-icon-left .e-input-group-icon:not(:last-child):hover,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-group-icon:not(:last-child):hover,
  .e-input-group.e-small.e-float-icon-left:not(.e-disabled) .e-input-group-icon:first-child:hover,
  .e-input-group.e-control-wrapper.e-small.e-float-icon-left:not(.e-disabled) .e-input-group-icon:first-child:hover,
  .e-input-group.e-small:not(.e-disabled) .e-input-group-icon:first-child:hover,
  .e-input-group.e-float-icon-left .e-input-group-icon:first-child:hover,
  .e-input-group.e-control-wrapper.e-small:not(.e-disabled) .e-input-group-icon:first-child:hover,
  .e-input-group.e-control-wrapper.e-float-icon-left .e-input-group-icon:first-child:hover,
  .e-input-group.e-rtl:not(.e-disabled) .e-input-group-icon:last-child:hover,
  .e-input-group.e-control-wrapper.e-rtl:not(.e-disabled) .e-input-group-icon:last-child:hover,
  .e-input-group:not(.e-disabled) .e-input-group-icon:first-child:hover,
  .e-input-group.e-control-wrapper:not(.e-disabled) .e-input-group-icon:first-child:hover {
    @if $input-skin-name == 'highcontrast' {
      border-left-color: transparent;
    }
  }

  input.e-input#{$css}::selection,
  textarea.e-input#{$css}::selection,
  .e-input-group input.e-input::selection,
  .e-input-group.e-control-wrapper input.e-input::selection,
  .e-float-input input::selection,
  .e-float-input.e-control-wrapper input::selection,
  .e-input-group textarea.e-input::selection,
  .e-input-group.e-control-wrapper textarea.e-input::selection,
  .e-float-input textarea::selection,
  .e-float-input.e-control-wrapper textarea::selection,
  .e-float-input.e-small textarea::selection,
  .e-float-input textarea::selection {
    @include input-selection;
  }

  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
  .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
  .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
  .e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::before,
  .e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::after,
  .e-float-input.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::before,
  .e-float-input.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::after,
  .e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::before,
  .e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::after,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::before,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::after,
  .e-filled.e-input-group.e-float-icon-left:not(.e-float-input)::before,
  .e-filled.e-input-group.e-float-icon-left:not(.e-float-input)::after,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)::before,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)::after,
  .e-filled.e-float-input.e-input-group.e-float-icon-left .e-float-line::before,
  .e-filled.e-float-input.e-input-group.e-float-icon-left .e-float-line::after,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-float-line::before,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-float-line::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      @include input-group-animation-bg;
    }
  }

  .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) input:focus,
  .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) textarea:focus,
  .e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group).e-input-focus input,
  .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group).e-input-focus input {
    @if $input-skin-name == 'fluent2' {
      border-top: $input-active-border-color;
    }
  }

  .e-input-group::before,
  .e-input-group::after,
  .e-input-group.e-control-wrapper::before,
  .e-input-group.e-control-wrapper::after {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' {
      @include input-group-animation-bg;
    }
  }

  .e-input-group:not(.e-float-icon-left):not(.e-float-input).e-success::before,
  .e-input-group:not(.e-float-icon-left):not(.e-float-input).e-success::after,
  .e-input-group.e-float-icon-left.e-success:not(.e-float-input) .e-input-in-wrap::before,
  .e-input-group.e-float-icon-left.e-success:not(.e-float-input) .e-input-in-wrap::after,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input).e-success::before,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input).e-success::after,
  .e-input-group.e-control-wrapper.e-float-icon-left.e-success:not(.e-float-input) .e-input-in-wrap::before,
  .e-input-group.e-control-wrapper.e-float-icon-left.e-success:not(.e-float-input) .e-input-in-wrap::after,
  .e-float-input.e-input-group:not(.e-float-icon-left).e-success .e-float-line::before,
  .e-float-input.e-input-group:not(.e-float-icon-left).e-success .e-float-line::after,
  .e-float-input.e-input-group.e-float-icon-left.e-success .e-input-in-wrap .e-float-line::before,
  .e-float-input.e-input-group.e-float-icon-left.e-success .e-input-in-wrap .e-float-line::after,
  .e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left).e-success .e-float-line::before,
  .e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left).e-success .e-float-line::after,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left.e-success .e-input-in-wrap .e-float-line::before,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left.e-success .e-input-in-wrap .e-float-line::after,
  .e-float-input.e-input-group.e-control-wrapper:not(.e-float-icon-left).e-success .e-float-line::before,
  .e-float-input.e-input-group.e-control-wrapper:not(.e-float-icon-left).e-success .e-float-line::after,
  .e-float-input.e-input-group.e-control-wrapper.e-float-icon-left.e-success .e-input-in-wrap .e-float-line::before,
  .e-float-input.e-input-group.e-control-wrapper.e-float-icon-left.e-success .e-input-in-wrap .e-float-line::after,
  .e-filled.e-input-group.e-float-icon-left.e-success:not(.e-float-input)::before,
  .e-filled.e-input-group.e-float-icon-left.e-success:not(.e-float-input)::after,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left.e-success:not(.e-float-input)::before,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left.e-success:not(.e-float-input)::after,
  .e-filled.e-float-input.e-input-group.e-float-icon-left.e-success .e-float-line::before,
  .e-filled.e-float-input.e-input-group.e-float-icon-left.e-success .e-float-line::after,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left.e-success .e-float-line::before,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left.e-success .e-float-line::after,
  .e-filled.e-float-input.e-input-group.e-control-wrapper.e-float-icon-left.e-success .e-float-line::before,
  .e-filled.e-float-input.e-input-group.e-control-wrapper.e-float-icon-left.e-success .e-float-line::after  {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      @include input-group-success-animation-bg;
    }
  }

  .e-input-group.e-success::before,
  .e-input-group.e-success::after,
  .e-input-group.e-control-wrapper.e-success::before,
  .e-input-group.e-control-wrapper.e-success::after {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' {
      @include input-group-success-animation-bg;
    }
  }

  .e-input-group:not(.e-float-icon-left).e-warning:not(.e-float-input)::before,
  .e-input-group:not(.e-float-icon-left).e-warning:not(.e-float-input)::after,
  .e-input-group.e-float-icon-left.e-warning:not(.e-float-input) .e-input-in-wrap::before,
  .e-input-group.e-float-icon-left.e-warning:not(.e-float-input) .e-input-in-wrap::after,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left).e-warning:not(.e-float-input)::before,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left).e-warning:not(.e-float-input)::after,
  .e-input-group.e-control-wrapper.e-float-icon-left.e-warning:not(.e-float-input) .e-input-in-wrap::before,
  .e-input-group.e-control-wrapper.e-float-icon-left.e-warning:not(.e-float-input) .e-input-in-wrap::after,
  .e-float-input.e-input-group:not(.e-float-icon-left).e-warning .e-float-line::before,
  .e-float-input.e-input-group:not(.e-float-icon-left).e-warning .e-float-line::after,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left.e-warning .e-input-in-wrap .e-float-line::before,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left.e-warning .e-input-in-wrap .e-float-line::after,
  .e-filled.e-input-group.e-float-icon-left.e-warning:not(.e-float-input)::before,
  .e-filled.e-input-group.e-float-icon-left.e-warning:not(.e-float-input)::after,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left.e-warning:not(.e-float-input)::before,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left.e-warning:not(.e-float-input)::after,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left.e-warning .e-float-line::before,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left.e-warning .e-float-line::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      @include input-group-warning-animation-bg;
    }
  }

  .e-input-group.e-warning::before,
  .e-input-group.e-warning::after,
  .e-input-group.e-control-wrapper.e-warning::before,
  .e-input-group.e-control-wrapper.e-warning::after {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' {
      @include input-group-warning-animation-bg;
    }
  }

  .e-input-group:not(.e-float-icon-left).e-error:not(.e-float-input)::before,
  .e-input-group:not(.e-float-icon-left).e-error:not(.e-float-input)::after,
  .e-input-group.e-float-icon-left.e-error:not(.e-float-input) .e-input-in-wrap::before,
  .e-input-group.e-float-icon-left.e-error:not(.e-float-input) .e-input-in-wrap::after,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left).e-error:not(.e-float-input)::before,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left).e-error:not(.e-float-input)::after,
  .e-input-group.e-control-wrapper.e-float-icon-left.e-error:not(.e-float-input) .e-input-in-wrap::before,
  .e-input-group.e-control-wrapper.e-float-icon-left.e-error:not(.e-float-input) .e-input-in-wrap::after,
  .e-float-input.e-input-group:not(.e-float-icon-left).e-error .e-float-line::before,
  .e-float-input.e-input-group:not(.e-float-icon-left).e-error .e-float-line::after,
  .e-float-input.e-input-group.e-float-icon-left.e-error .e-input-in-wrap .e-float-line::before,
  .e-float-input.e-input-group.e-float-icon-left.e-error .e-input-in-wrap .e-float-line::after,
  .e-float-input.e-input-group.e-control-wrapper:not(.e-float-icon-left).e-error .e-float-line::before,
  .e-float-input.e-input-group.e-control-wrapper:not(.e-float-icon-left).e-error .e-float-line::after,
  .e-float-input.e-input-group.e-control-wrapper.e-float-icon-left.e-error .e-input-in-wrap .e-float-line::before,
  .e-float-input.e-input-group.e-control-wrapper.e-float-icon-left.e-error .e-input-in-wrap .e-float-line::after,
  .e-filled.e-input-group.e-float-icon-left.e-error:not(.e-float-input)::before,
  .e-filled.e-input-group.e-float-icon-left.e-error:not(.e-float-input)::after,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left.e-error:not(.e-float-input)::before,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left.e-error:not(.e-float-input)::after,
  .e-filled.e-float-input.e-input-group.e-float-icon-left.e-error .e-float-line::before,
  .e-filled.e-float-input.e-input-group.e-float-icon-left.e-error .e-float-line::after,
  .e-filled.e-float-input.e-input-group.e-control-wrapper.e-float-icon-left.e-error .e-float-line::before,
  .e-filled.e-float-input.e-input-group.e-control-wrapper.e-float-icon-left.e-error .e-float-line::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      @include input-group-error-animation-bg;
    }
  }

  .e-input-group.e-error::before,
  .e-input-group.e-error::after,
  .e-input-group.e-control-wrapper.e-error::before,
  .e-input-group.e-control-wrapper.e-error::after {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' {
      @include input-group-error-animation-bg;
    }
  }

  .e-input-group.e-success .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-success .e-input-group-icon,
  .e-input-group.e-warning .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-warning .e-input-group-icon,
  .e-input-group.e-error .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-error .e-input-group-icon {
    color: $input-icon-font-color;
  }

  .e-input-group.e-success:not(.e-disabled):not(:active) .e-input-group-icon:hover,
  .e-input-group.e-control-wrapper.e-success:not(.e-disabled):not(:active) .e-input-group-icon:hover,
  .e-input-group.e-warning:not(.e-disabled):not(:active) .e-input-group-icon:hover,
  .e-input-group.e-control-wrapper.e-warning:not(.e-disabled):not(:active) .e-input-group-icon:hover,
  .e-input-group.e-error:not(.e-disabled):not(:active) .e-input-group-icon:hover,
  .e-input-group.e-control-wrapper.e-error:not(.e-disabled):not(:active) .e-input-group-icon:hover {
    color: $input-icon-font-color;
    @if $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'FluentUI' {
      color: $input-group-hovered-color;
    }
  }

  .e-input-group.e-success:not(.e-disabled) .e-input-group-icon:active,
  .e-input-group.e-control-wrapper.e-success:not(.e-disabled) .e-input-group-icon:active,
  .e-input-group.e-warning:not(.e-disabled) .e-input-group-icon:active,
  .e-input-group.e-control-wrapper.e-warning:not(.e-disabled) .e-input-group-icon:active,
  .e-input-group.e-error:not(.e-disabled) .e-input-group-icon:active,
  .e-input-group.e-control-wrapper.e-error:not(.e-disabled) .e-input-group-icon:active {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      color: $input-icon-font-color;
    }
  }

  .e-input-group input.e-input,
  .e-input-group.e-control-wrapper input.e-input,
  .e-input-group textarea.e-input,
  .e-input-group.e-control-wrapper textarea.e-input,
  .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input:focus,
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input:focus,
  .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input,
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input {
    border-color: $input-box-border-color;
  }

  .e-input#{$css}:focus:not(.e-success):not(.e-warning):not(.e-error),
  .e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) input:focus,
  .e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) textarea:focus,
  .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) input:focus,
  .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) textarea:focus,
  .e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group).e-input-focus input,
  .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group).e-input-focus input {
    @if $input-skin-name != 'fluent2' {
      border-color: $input-active-border-color;
    }
    @if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4'  {
      -webkit-box-shadow: $input-border-shadow;
      box-shadow: $input-border-shadow;
    }
    @if $input-skin-name == 'tailwind' or $input-skin-name == 'tailwind3' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'FluentUI'  {
      border-radius: $input-groupo-focus-border-radius;
      box-shadow: $input-group-focus-box-shadow;
    }
    @if $input-skin-name == 'bootstrap4'  {
      transition: $input-transition-shadow;
    }
    @if $input-skin-name == 'fluent2' {
      border-bottom-color: $input-active-border-color;
      padding-bottom: 2px;
      border-bottom-width: 2px;
    }
  }

  textarea.e-input#{$css}:focus:not(.e-success):not(.e-warning):not(.e-error) {
    @if $input-skin-name == 'fluent2' {
      border-bottom-color: $input-active-border-color;
      padding-bottom: 4px;
      border-bottom-width: 0;
    }
  }

  .e-input-group .e-input#{$css}:focus:not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group .e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) input:focus,
  .e-input-group .e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) textarea:focus,
  .e-input-group .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) input:focus,
  .e-input-group .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) textarea:focus,
  .e-input-group .e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group).e-input-focus input,
  .e-input-group .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group).e-input-focus input {
    @if $input-skin-name == 'tailwind' or $input-skin-name == 'tailwind3' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'FluentUI' {
      box-shadow: none;
    }
  }


  .e-input#{$css}:focus:not(.e-success):not(.e-warning).e-error,
  .e-float-input:not(.e-success):not(.e-warning).e-error:not(.e-input-group) input:focus,
  .e-float-input:not(.e-success):not(.e-warning).e-error:not(.e-input-group) textarea:focus,
  .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning).e-error:not(.e-input-group) input:focus,
  .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning).e-error:not(.e-input-group) textarea:focus,
  .e-float-input:not(.e-success):not(.e-warning).e-error:not(.e-input-group).e-input-focus input,
  .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning).e-error:not(.e-input-group).e-input-focus input,
  .e-input-group.e-input-focus:not(.e-success):not(.e-warning).e-error,
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning).e-error,
  .e-input-group:not(.e-disabled):active:not(.e-success):not(.e-warning).e-error,
  .e-input-group.e-control-wrapper:not(.e-disabled):active:not(.e-success):not(.e-warning).e-error {
    @if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'FluentUI' {
      -webkit-box-shadow: $input-error-border-shadow;
      box-shadow: $input-error-border-shadow;
    }
  }


  .e-input#{$css}:focus:not(.e-error):not(.e-warning).e-success,
  .e-float-input:not(.e-error):not(.e-warning).e-success:not(.e-input-group) input:focus,
  .e-float-input:not(.e-error):not(.e-warning).e-success:not(.e-input-group) textarea:focus,
  .e-float-input.e-control-wrapper:not(.e-error):not(.e-warning).e-success:not(.e-input-group) input:focus,
  .e-float-input.e-control-wrapper:not(.e-error):not(.e-warning).e-success:not(.e-input-group) textarea:focus,
  .e-float-input:not(.e-error):not(.e-warning).e-success:not(.e-input-group).e-input-focus input,
  .e-float-input.e-control-wrapper:not(.e-error):not(.e-warning).e-success:not(.e-input-group).e-input-focus input {
    @if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'FluentUI'  {
      -webkit-box-shadow: $input-success-border-shadow;
      box-shadow: $input-success-border-shadow;
    }
  }


  .e-input#{$css}:focus:not(.e-success):not(.e-error).e-warning,
  .e-float-input:not(.e-success):not(.e-error).e-warning:not(.e-input-group) input:focus,
  .e-float-input:not(.e-success):not(.e-error).e-warning:not(.e-input-group) textarea:focus,
  .e-float-input.e-control-wrapper:not(.e-success):not(.e-error).e-warning:not(.e-input-group) input:focus,
  .e-float-input.e-control-wrapper:not(.e-success):not(.e-error).e-warning:not(.e-input-group) textarea:focus,
  .e-float-input:not(.e-success):not(.e-error).e-warning:not(.e-input-group).e-input-focus input,
  .e-float-input.e-control-wrapper:not(.e-success):not(.e-error).e-warning:not(.e-input-group).e-input-focus input,
  .e-input-group.e-input-focus:not(.e-success):not(.e-error).e-warning,
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-error).e-warning,
  .e-input-group:not(.e-disabled):active:not(.e-success):not(.e-error).e-warning,
  .e-input-group.e-control-wrapper:not(.e-disabled):active:not(.e-success):not(.e-error).e-warning {
    @if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'FluentUI' {
      -webkit-box-shadow: $input-warning-border-shadow;
      box-shadow: $input-warning-border-shadow;
    }
  }

  .e-input-group:not(.e-float-input):not(.e-float-icon-left) .e-input:focus:not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap .e-input:focus:not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group.e-control-wrapper:not(.e-float-input):not(.e-float-icon-left) .e-input:focus:not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap .e-input:focus:not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group:not(.e-float-input):not(.e-float-icon-left).e-input-focus .e-input:not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group.e-float-icon-left:not(.e-float-input).e-input-focus .e-input-in-wrap .e-input:not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group.e-control-wrapper:not(.e-float-input):not(.e-float-icon-left).e-input-focus .e-input:not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input).e-input-focus .e-input-in-wrap .e-input:not(.e-success):not(.e-warning):not(.e-error) {
    @if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4'  {
      box-shadow: none;
    }
  }

  .e-input-group:not(.e-success):not(.e-warning):not(.e-error) input.e-input:focus,
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error) input.e-input:focus,
  .e-input-group:not(.e-success):not(.e-warning):not(.e-error).e-input-focus input.e-input,
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error).e-input-focus input.e-input,
  .e-input-group:not(.e-success):not(.e-warning):not(.e-error) textarea.e-input:focus,
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error) textarea.e-input:focus {
    border-color: $input-group-active-border-color;
  }

  .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-float-input.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-input-group.e-input-focus.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-input-group.e-control-wrapper.e-input-focus.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-float-input.e-input-focus.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-float-input.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
  .e-float-input.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]),
  .e-float-input.e-control-wrapper.e-input-focus.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
  .e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-color: $input-group-border-color-focus;
    }
  }

  .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' and $input-skin-name != 'fluent2' and $input-skin-name != 'tailwind3' {
      border-color: $input-group-border-color-focus;
    }
    @if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4'  {
      -webkit-box-shadow: $input-border-shadow;
      box-shadow: $input-border-shadow;
    }
    @if $input-skin-name == 'bootstrap4'  {
      transition: $input-transition-shadow;
    }
    @if $input-skin-name == 'tailwind' or $input-skin-name == 'tailwind3' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'FluentUI' {
      border-color: $input-group-border-color-focus;
      border-radius: $input-groupo-focus-border-radius;
      box-shadow: $input-group-focus-box-shadow;
    }
  }

  .e-input-group.e-input-focus:not(.e-error):not(.e-warning).e-success,
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-error):not(.e-warning).e-success,
  .e-input-group:not(.e-disabled):active:not(.e-error):not(.e-warning).e-success,
  .e-input-group.e-control-wrapper:not(.e-disabled):active:not(.e-error):not(.e-warning).e-success {
    @if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'FluentUI' {
      -webkit-box-shadow: $input-success-border-shadow;
      box-shadow: $input-success-border-shadow;
    }
  }

  .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input:first-child:focus,
  .e-input-group.e-input-focus.e-rtl:not(.e-success):not(.e-warning):not(.e-error) span:last-child.e-input-group-icon,
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input:first-child:focus,
  .e-input-group.e-control-wrapper.e-input-focus.e-rtl:not(.e-success):not(.e-warning):not(.e-error) span:last-child.e-input-group-icon {
    @if ($input-skin-name != 'bootstrap' and $input-skin-name != 'bootstrap4' and $input-skin-name != 'FluentUI' and $input-skin-name != 'bootstrap5' and $input-skin-name != 'bootstrap5.3') {
      border-color: $input-group-border-right-focus;
    }
  }

  .e-input-group.e-input-focus.e-rtl:not(.e-success):not(.e-warning):not(.e-error) span.e-input-group-icon,
  .e-input-group.e-input-focus.e-rtl:not(.e-success):not(.e-warning):not(.e-error) span:first-child.e-input-group-icon,
  .e-input-focus.e-rtl:not(.e-success):not(.e-warning):not(.e-error) .e-input:first-child:focus,
  .e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input:last-child:focus,
  .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) span.e-input-group-icon,
  .e-input-group.e-control-wrapper.e-input-focus.e-rtl:not(.e-success):not(.e-warning):not(.e-error) span.e-input-group-icon,
  .e-input-group.e-control-wrapper.e-input-focus.e-rtl:not(.e-success):not(.e-warning):not(.e-error) span:first-child.e-input-group-icon,
  .e-input-focus.e-control-wrapper.e-rtl:not(.e-success):not(.e-warning):not(.e-error) .e-input:first-child:focus,
  .e-input-focus.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error) .e-input:last-child:focus,
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) span.e-input-group-icon {
    @if ($input-skin-name != 'bootstrap' and $input-skin-name != 'bootstrap4' and $input-skin-name != 'bootstrap5' and $input-skin-name != 'FluentUI' and $input-skin-name != 'bootstrap5.3') {
      border-color: $input-group-border-left-focus;
    }
  }

  .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) span:first-child.e-input-group-icon,
  .e-input-group.e-input-focus.e-rtl:not(.e-success):not(.e-warning):not(.e-error) .e-input:last-child:focus,
  .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) span:first-child.e-input-group-icon,
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) span:first-child.e-input-group-icon,
  .e-input-group.e-control-wrapper.e-input-focus.e-rtl:not(.e-success):not(.e-warning):not(.e-error) .e-input:last-child:focus,
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) span:first-child.e-input-group-icon {
    @if ($input-skin-name != 'bootstrap' and $input-skin-name != 'bootstrap4' and $input-skin-name != 'bootstrap5' and $input-skin-name != 'bootstrap5.3') {
      border-color: transparent;
    }
  }

  .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left).e-input-focus,
  .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap.e-input-focus,
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left).e-input-focus,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap.e-input-focus,
  .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap.e-input-focus,
  .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap.e-input-focus {
    @if $input-skin-name == 'fluent2' {
      border-bottom-color: $input-group-focus-border-bottom-color;
    }
  }

  .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-color: $input-group-full-border-color;
    }
    @else if $input-skin-name == 'fluent2' {
      border-bottom-color: $input-group-full-border-bottom-color;
    }
  }

  .e-input-group:not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error) {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' and $input-skin-name != 'fluent2' {
      border-color: $input-group-full-border-color;
    }
    @if $input-skin-name == 'tailwind' or $input-skin-name == 'tailwind3' {
      box-shadow: $input-group-border-shadow;
    }
    @if $input-skin-name == 'FluentUI' {
      box-shadow: none;
    }
  }


  .e-input-group:not(.e-disabled):active:not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group.e-control-wrapper:not(.e-disabled):active:not(.e-success):not(.e-warning):not(.e-error) {
    @if $input-skin-name == 'fabric' or $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'highcontrast' {
      border-color: $input-active-border-color;
    }
    @if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4'  {
      -webkit-box-shadow: $input-border-shadow;
      box-shadow: $input-border-shadow;
    }
    @if $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' {
      box-shadow: $input-group-focus-box-shadow;
    }
  }

  .e-input-group.e-disabled:not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group.e-control-wrapper.e-disabled:not(.e-success):not(.e-warning):not(.e-error) {
    @if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'highcontrast' {
      border-color: $input-disable-border-color;
    }
  }

  .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left) .e-input:focus,
  .e-input-group.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input:focus,
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left) .e-input:focus,
  .e-input-group.e-control-wrapper.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input:focus,
  .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left) .e-input,
  .e-input-group.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input,
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left) .e-input,
  .e-input-group.e-control-wrapper.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      border-bottom-color: transparent;
      border-top-color: transparent;
    }
  }

  .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input:focus,
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input:focus,
  .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input,
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' {
      border-bottom-color: transparent;
      border-top-color: transparent;
    }
  }

  .e-input-group.e-success,
  .e-input-group.e-control-wrapper.e-success,
  .e-float-icon-left.e-input-group.e-success,
  .e-float-icon-left.e-control-wrapper.e-input-group.e-success,
  .e-input-group.e-warning,
  .e-input-group.e-control-wrapper.e-warning,
  .e-float-icon-left.e-input-group.e-warning,
  .e-float-icon-left.e-input-group.e-control-wrapper.e-warning,
  .e-input-group.e-error,
  .e-input-group.e-control-wrapper.e-error,
  .e-float-icon-left.e-input-group.e-error,
  .e-float-icon-left.e-input-group.e-control-wrapper.e-error,
  .e-input-group.e-float-icon-left,
  .e-input-group.e-control-wrapper.e-float-icon-left {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-color: transparent;
    }
  }

  .e-input-group.e-success,
  .e-input-group.e-control-wrapper.e-success,
  .e-input-group.e-success:not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left) {
    @include input-state-color ($input-group-success-color);
  }

  .e-input-group.e-success.e-input-focus,
  .e-input-group.e-control-wrapper.e-success.e-input-focus,
  .e-input-group.e-success:not(.e-float-icon-left).e-input-focus,
  .e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left).e-input-focus {
    @if $skin-name == 'tailwind3' {
      box-shadow: $input-success-focus-box-shadow;
    }
  }

  .e-input-group.e-float-icon-left.e-success .e-input-in-wrap,
  .e-input-group.e-control-wrapper.e-float-icon-left.e-success .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      @include input-state-color ($input-group-success-color);
    }
  }

  .e-input-group.e-warning,
  .e-input-group.e-control-wrapper.e-warning,
  .e-input-group.e-warning:not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left) {
    @include input-state-color ($input-group-warning-color);
  }

  .e-input-group.e-warning.e-input-focus,
  .e-input-group.e-control-wrapper.e-warning.e-input-focus,
  .e-input-group.e-warning:not(.e-float-icon-left).e-input-focus,
  .e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left).e-input-focus {
    @if $skin-name == 'tailwind3'  {
      box-shadow: $input-warning-focus-box-shadow;
    }
  }

  .e-input-group.e-float-icon-left.e-warning .e-input-in-wrap,
  .e-input-group.e-control-wrapper.e-float-icon-left.e-warning .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      @include input-state-color ($input-group-warning-color);
    }
  }

  .e-input-group.e-error,
  .e-input-group.e-control-wrapper.e-error,
  .e-input-group.e-error:not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
    @include input-state-color ($input-group-error-color);
  }

  .e-input-group.e-error.e-input-focus,
  .e-input-group.e-control-wrapper.e-error.e-input-focus,
  .e-input-group.e-error:not(.e-float-icon-left).e-input-focus,
  .e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left).e-input-focus {
    @if $skin-name == 'tailwind3' {
      box-shadow: $input-error-focus-box-shadow;
    }
  }

  .e-input-group.e-float-icon-left.e-error .e-input-in-wrap,
  .e-input-group.e-control-wrapper.e-float-icon-left.e-error .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      @include input-state-color ($input-group-error-color);
    }
  }

  .e-float-input .e-clear-icon,
  .e-float-input.e-control-wrapper .e-clear-icon,
  .e-input-group .e-clear-icon,
  .e-input-group.e-control-wrapper .e-clear-icon {
    color: $input-clear-icon-color;
  }

  .e-float-input .e-clear-icon:active,
  .e-float-input.e-control-wrapper .e-clear-icon:active,
  .e-input-group .e-clear-icon:active,
  .e-input-group.e-control-wrapper .e-clear-icon:active {
    @if ($input-skin-name == 'FluentUI') {
      color: $input-group-pressed-color;
    }
  }

  .e-float-input .e-clear-icon:hover,
  .e-float-input.e-control-wrapper .e-clear-icon:hover,
  .e-input-group .e-clear-icon:hover,
  .e-input-group.e-control-wrapper .e-clear-icon:hover {
    color: $input-clear-icon-hover-color;
    @if ($input-skin-name == 'FluentUI') {
      background: $input-clear-icon-hover-bg-color;
    }
    @if $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      border: $input-clear-icon-hover-border;
      background: $input-clear-icon-hover-bg-color;
    }
  }

  .e-float-input:not(.e-disabled) .e-clear-icon:hover,
  .e-float-input.e-control-wrapper:not(.e-disabled) .e-clear-icon:hover,
  .e-input-group:not(.e-disabled) .e-clear-icon:hover,
  .e-input-group.e-control-wrapper:not(.e-disabled) .e-clear-icon:hover {
    @if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4'  {
      color: $input-clear-icon-hover-color;
    }
    @if ($input-skin-name == 'FluentUI') {
      background: $input-clear-icon-hover-bg-color;
    }
  }

  .e-float-input.e-input-focus .e-input:focus,
  .e-float-input.e-control-wrapper.e-input-focus .e-input:focus {
    border-bottom-color: transparent;
    border-top-color: transparent;
  }

  .e-float-input:not(.e-error) input:focus ~ label.e-float-text,
  .e-float-input:not(.e-error) input:valid ~ label.e-float-text,
  .e-float-input:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-float-input:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input:not(.e-error) input label.e-float-text.e-label-top,
  .e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-float-input.e-small:not(.e-error) input:focus ~ label.e-float-text,
  .e-float-input.e-small:not(.e-error) input:valid ~ label.e-float-text,
  .e-float-input.e-small:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-float-input.e-small:not(.e-error) input[readonly] ~ label.e-float-text,
  .e-float-input.e-small:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-small .e-float-input:not(.e-error) input:focus ~ label.e-float-text,
  .e-small .e-float-input:not(.e-error) input:valid ~ label.e-float-text,
  .e-small .e-float-input:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-small .e-float-input:not(.e-error) input[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-float-input:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-small .e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) input:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) input[readonly] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper:not(.e-error) input:valid ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-control-wrapper:not(.e-error) input[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-float-input.e-control-wrapper:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-float-input:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-float-input:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-float-input:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input:not(.e-error) textarea label.e-float-text.e-label-top,
  .e-float-input.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-float-input.e-small:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-float-input.e-small:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-small:not(.e-error) textarea[readonly] ~ label.e-float-text,
  .e-float-input.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-small .e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-small .e-float-input:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-small .e-float-input:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-small .e-float-input:not(.e-error) textarea[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-float-input:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small .e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small.e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-autofill:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-autofill:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small.e-float-input.e-control-wrapper.e-autofill:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-float-input:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small .e-float-input:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small.e-float-input:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-autofill:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-autofill:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small.e-float-input.e-control-wrapper.e-autofill:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text {
    color: $float-label-font-color;
  }

  .e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error) input:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input .e-control-wrapper:not(.e-error) input label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error) textarea label.e-float-text.e-label-top {
    @if $input-skin-name != 'fluent2' and $input-skin-name != 'tailwind3' {
      color: $float-label-font-color;
    }
    @else {
      color: $float-label-top-font-color;
    }
  }

  .e-float-input:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,

  .e-float-input.e-small:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    color: $input-header-font-color;
    @if $input-skin-name == 'highcontrast' {
      color: $input-placeholder;
    }
  }

  .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[readonly] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-control-wrapper:not(.e-error) textarea[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-float-input.e-control-wrapper:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top {
    color: $float-label-font-color;
  }

  .e-float-input.e-input-group.e-disabled .e-float-text,
  .e-float-input.e-input-group.e-disabled .e-float-text.e-label-top,
  .e-float-input input[disabled] ~ label.e-float-text,
  .e-float-input input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-disabled label.e-float-text,
  .e-float-input.e-disabled label.e-float-text.e-label-top,
  .e-float-input:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-float-input:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-float-input.e-small:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input textarea[disabled] ~ label.e-float-text,
  .e-float-input textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-float-input:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-float-input.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-input-group.e-disabled .e-float-text,
  .e-float-input.e-control-wrapper.e-input-group.e-disabled .e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-disabled input[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-disabled input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper input[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-disabled label.e-float-text,
  .e-float-input.e-control-wrapper.e-disabled label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-disabled:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-disabled:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-disabled.e-small:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-disabled.e-small:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-disabled textarea[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-disabled textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper textarea[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-disabled:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-disabled:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-disabled.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-disabled.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top {
    color: $float-label-disbale-font-color;
  }

  .e-float-input:not(.e-error) input:focus ~ label.e-float-text,
  .e-float-input.e-small:not(.e-error) input:focus ~ label.e-float-text,
  .e-small .e-float-input:not(.e-error) input:focus ~ label.e-float-text,
  .e-float-input:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-float-input.e-small:not(.e-error) input[readonly]:focus ~ label.e-float-text,
  .e-small .e-float-input:not(.e-error) input[readonly]:focus ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) input[readonly]:focus ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper:not(.e-error) input[readonly]:focus ~ label.e-float-text.e-label-top,
  .e-float-input:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small:not(.e-error).e-input-focus input[readonly] ~ label.e-float-text,
  .e-small .e-float-input:not(.e-error).e-input-focus input[readonly] ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input[readonly] ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input[readonly] ~ label.e-float-text.e-label-top,
  .e-float-input:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-float-input.e-small:not(.e-error) textarea[readonly]:focus ~ label.e-float-text,
  .e-small .e-float-input:not(.e-error) textarea[readonly]:focus ~ label.e-float-text.e-label-top,
  .e-small .e-float-input.e-control-wrapper:not(.e-error) textarea[readonly]:focus ~ label.e-float-text.e-label-top,
  .e-float-input:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small:not(.e-error).e-input-focus textarea[readonly] ~ label.e-float-text,
  .e-small .e-float-input:not(.e-error).e-input-focus textarea[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-float-input.e-control-wrapper:not(.e-error).e-input-focus textarea[readonly] ~ label.e-float-text.e-label-top,
  .e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-float-input.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-small .e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) input:focus ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-float-input.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-small .e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      color: $input-active-accent-color;
    }
  }

  input.e-input#{$css}:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]):not(:focus),
  textarea.e-input#{$css}:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]) {
    @if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or $input-skin-name == 'bootstrap' {
      border-color: $input-group-border-color-hover;
    }
  }

  .e-input-group:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-input-group.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-float-input.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-float-input:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-float-input:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
  .e-float-input:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]),
  .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-color: $input-group-border-color-hover;
    }
    @else if $input-skin-name == 'fluent2' {
      border-bottom-color: $input-group-full-border-bottom-color;
    }
  }

  .e-underline.e-input-group:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-underline.e-input-group.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-underline.e-float-input.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-underline.e-float-input:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-underline.e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-underline.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-underline.e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-underline.e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-underline.e-float-input:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
  .e-underline.e-float-input:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]),
  .e-underline.e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
  .e-underline.e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]),
  .e-underline.e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover {
    @if $input-skin-name == 'fluent' {
      border-color: $input-group-border-color-hover;
    }
  }

  .e-input-group:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-input-group.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-float-input:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-float-input:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
  .e-float-input:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]),
  .e-float-input.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]) {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' and $input-skin-name != 'bootstrap' and $input-skin-name != 'bootstrap4' and $input-skin-name != 'bootstrap5' and $input-skin-name != 'bootstrap5.3' and $input-skin-name != 'tailwind3' and $input-skin-name != 'highcontrast' and $input-skin-name != 'tailwind' and $input-skin-name != 'FluentUI' and $input-skin-name != 'fluent2' {
      border-color: $input-group-border-color-hover;
    }
  }

  .e-input-group:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-input-group.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-float-input.e-input-group:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-float-input.e-input-group.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-float-input:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]):not(:focus),
  .e-float-input:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]):not(:focus),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]):not(:focus),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]):not(:focus) {
    @if $input-skin-name == 'highcontrast' {
      border-color: $input-group-border-color-hover;
    }
  }

  .e-input-group.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-input-group.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-float-input.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-float-input.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
  .e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
  .e-float-input.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]),
  .e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]) {
    @if $input-skin-name != 'material' and $input-skin-name != 'Material3' and $input-skin-name != 'fluent2' {
      border-color: $input-group-border-color-focus;
    }
  }

  .e-input-group:active:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-input-group.e-control-wrapper:active:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-float-input:active:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-float-input.e-control-wrapper:active:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-float-input:active:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
  .e-float-input.e-control-wrapper:active:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
  .e-float-input:active:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]),
  .e-float-input.e-control-wrapper:active:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]) {
    @if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'highcontrast' or $input-skin-name == 'FluentUI' {
      border-color: $input-active-border-color;
    }
  }

  .e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-float-input.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-float-input.e-control-wrapper.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-input-group.e-control-wrapper.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-input-group.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-float-input.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-input-group.e-input-focus.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-input-group.e-control-wrapper.e-input-focus.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-float-input.e-input-focus.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-float-input.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
  .e-float-input.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]),
  .e-float-input.e-control-wrapper.e-input-focus.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
  .e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-color: $input-group-border-color-hover;
    }
  }

  .e-outline.e-input-group,
  .e-outline.e-input-group.e-control-wrapper,
  .e-outline.e-float-input,
  .e-outline.e-float-input.e-input-group,
  .e-outline.e-float-input.e-control-wrapper,
  .e-outline.e-float-input.e-input-group.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      color: $outline-input-font-color;
    }
  }

  .e-outline.e-input-group.e-disabled,
  .e-outline.e-input-group.e-control-wrapper.e-disabled,
  .e-outline.e-float-input.e-disabled,
  .e-outline.e-float-input.e-input-group.e-disabled,
  .e-outline.e-float-input.e-control-wrapper.e-disabled,
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-disabled {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      color: $outline-disabled-input-font-color;
    }
  }

  .e-outline.e-input-group:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-outline.e-input-group.e-control-wrapper:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-outline.e-float-input.e-control-wrapper:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-outline.e-input-group.e-float-icon-left:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-outline.e-input-group.e-control-wrapper.e-float-icon-left:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-outline.e-float-input.e-float-icon-left:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-outline.e-float-input.e-control-wrapper.e-float-icon-left:not(.e-input-focus):hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-outline.e-float-input:hover:not(.e-input-focus):not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-color: $outline-hover-border-color;
      color: $outline-hover-font-color;
    }
  }

  .e-underline.e-input-group:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-underline.e-input-group.e-control-wrapper:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-underline.e-float-input.e-control-wrapper:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-underline.e-input-group.e-float-icon-left:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-underline.e-input-group.e-control-wrapper.e-float-icon-left:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-underline.e-float-input.e-float-icon-left:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-underline.e-float-input.e-control-wrapper.e-float-icon-left:not(.e-input-focus):hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-underline.e-float-input:hover:not(.e-input-focus):not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) {
    @if $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      border-color: $outline-hover-border-color;
      color: $outline-hover-font-color;
    }
  }

  .e-underline.e-input-group:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-underline.e-input-group.e-control-wrapper:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-underline.e-float-input.e-control-wrapper:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-underline.e-input-group.e-float-icon-left:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-underline.e-input-group.e-control-wrapper.e-float-icon-left:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-underline.e-float-input.e-float-icon-left:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-underline.e-float-input.e-control-wrapper.e-float-icon-left:not(.e-input-focus):hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-underline.e-float-input:hover:not(.e-input-focus):not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) {
    @if $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      border-bottom-color: $underline-hover-border-color;
      color: $outline-hover-font-color;
    }
  }

  .e-outline.e-input-group.e-valid-input:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) label.e-float-text::before,
  .e-outline.e-input-group.e-valid-input:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) label.e-float-text::after,
  .e-outline.e-input-group.e-control-wrapper.e-valid-input:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) label.e-float-text::before,
  .e-outline.e-input-group.e-control-wrapper.e-valid-input:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) label.e-float-text::after,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) label.e-float-text::before,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) label.e-float-text::after,
  .e-outline.e-input-group.e-float-icon-left.e-valid-input:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) label.e-float-text::before,
  .e-outline.e-input-group.e-float-icon-left.e-valid-input:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) label.e-float-text::after,
  .e-outline.e-input-group.e-control-wrapper.e-float-icon-left.e-valid-input:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) label.e-float-text::before,
  .e-outline.e-input-group.e-control-wrapper.e-float-icon-left.e-valid-input:hover:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) label.e-float-text::after,
  .e-outline.e-float-input.e-float-icon-left:hover.e-valid-input:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) label.e-float-text::before,
  .e-outline.e-float-input.e-float-icon-left:hover.e-valid-input:not(.e-input-focus):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) label.e-float-text::after,
  .e-outline.e-float-input.e-control-wrapper.e-float-icon-left.e-valid-input:not(.e-input-focus):hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) label.e-float-text::before,
  .e-outline.e-float-input.e-control-wrapper.e-float-icon-left.e-valid-input:not(.e-input-focus):hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) label.e-float-text::after,
  .e-outline.e-float-input:hover.e-valid-input:not(.e-input-focus):not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) label.e-float-text::before,
  .e-outline.e-float-input:hover.e-valid-input:not(.e-input-focus):not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) label.e-float-text::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-color: $outline-hover-border-color;
    }
  }

  .e-outline.e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error),
  .e-outline.e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-color: $input-active-border-color;
      box-shadow: inset 1px 1px $input-active-border-color, inset -1px 0 $input-active-border-color, inset 0 -1px $input-active-border-color;
    }
  }

  .e-outline.e-input-group:not(.e-input-focus),
  .e-outline.e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left):not(.e-input-focus),
  .e-outline.e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left):not(.e-input-focus),
  .e-outline.e-input-group.e-float-icon-left:not(.e-input-focus),
  .e-outline.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-input-focus) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-color: $outline-border-color;
    }
  }

  .e-outline.e-input-group.e-error,
  .e-outline.e-input-group.e-control-wrapper.e-error,
  .e-outline.e-input-group.e-error:not(.e-float-icon-left),
  .e-outline.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left),
  .e-outline.e-float-input.e-error,
  .e-outline.e-float-input.e-input-group.e-error,
  .e-outline.e-float-input.e-control-wrapper.e-error,
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-error {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-color: $input-error-color;
    }
  }

  .e-outline.e-float-input.e-success.e-input-focus,
  .e-outline.e-float-input.e-control-wrapper.e-success.e-input-focus,
  .e-outline.e-float-input.e-input-group.e-success.e-input-focus,
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-success.e-input-focus,
  .e-outline.e-float-input.e-input-group.e-success.e-input-focus:not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-success.e-input-focus:not(.e-float-icon-left),
  .e-outline.e-float-input.e-warning.e-input-focus,
  .e-outline.e-float-input.e-control-wrapper.e-warning.e-input-focus,
  .e-outline.e-float-input.e-input-group.e-warning.e-input-focus,
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-warning.e-input-focus,
  .e-outline.e-float-input.e-input-group.e-warning.e-input-focus:not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-warning.e-input-focus:not(.e-float-icon-left),
  .e-outline.e-float-input.e-error.e-input-focus,
  .e-outline.e-float-input.e-control-wrapper.e-error.e-input-focus,
  .e-outline.e-float-input.e-input-group.e-error.e-input-focus,
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-error.e-input-focus,
  .e-outline.e-float-input.e-input-group.e-error.e-input-focus:not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-error.e-input-focus:not(.e-float-icon-left),
  .e-outline.e-float-input.e-input-group.e-disabled.e-valid-input:not(.e-input-focus),
  .e-outline.e-float-input.e-input-group.e-disabled.e-valid-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left):not(.e-input-focus),
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-disabled.e-valid-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left):not(.e-input-focus) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-top-color: transparent;
    }
  }

  .e-outline.e-input-group.e-success,
  .e-outline.e-input-group.e-control-wrapper.e-success,
  .e-outline.e-input-group.e-success:not(.e-float-icon-left),
  .e-outline.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
  .e-outline.e-float-input.e-success,
  .e-outline.e-float-input.e-input-group.e-success,
  .e-outline.e-float-input.e-control-wrapper.e-success,
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-success {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-color: $input-success-color;
    }
  }

  .e-outline.e-input-group.e-warning,
  .e-outline.e-input-group.e-control-wrapper.e-warning,
  .e-outline.e-input-group.e-warning:not(.e-float-icon-left),
  .e-outline.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
  .e-outline.e-float-input.e-warning,
  .e-outline.e-float-input.e-input-group.e-warning,
  .e-outline.e-float-input.e-control-wrapper.e-warning,
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-warning {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-color: $input-warning-color;
    }
  }

  .e-outline.e-input-group.e-input-focus.e-error:not(.e-success):not(.e-warning),
  .e-outline.e-input-group.e-control-wrapper.e-input-focus.e-error:not(.e-success):not(.e-warning) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      box-shadow: inset 1px 1px $input-error-color, inset -1px $zero-value $input-error-color, inset $zero-value -1px $input-error-color;
    }
  }

  .e-outline.e-input-group.e-input-focus.e-success:not(.e-error):not(.e-warning),
  .e-outline.e-input-group.e-control-wrapper.e-input-focus.e-success:not(.e-error):not(.e-warning) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      box-shadow: inset 1px 1px $input-success-color, inset -1px $zero-value $input-success-color, inset $zero-value -1px $input-success-color;
    }
  }

  .e-outline.e-input-group.e-input-focus.e-warning:not(.e-error):not(.e-success),
  .e-outline.e-input-group.e-control-wrapper.e-input-focus.e-warning:not(.e-error):not(.e-success) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      box-shadow: inset 1px 1px $input-warning-color, inset -1px $zero-value $input-warning-color, inset $zero-value -1px $input-warning-color;
    }
  }

  .e-outline.e-float-input.e-input-focus.e-error:not(.e-success):not(.e-warning),
  .e-outline.e-float-input.e-control-wrapper.e-input-focus.e-error:not(.e-success):not(.e-warning),
  .e-outline.e-float-input.e-input-group.e-input-focus.e-error:not(.e-success):not(.e-warning),
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-input-focus.e-error:not(.e-success):not(.e-warning) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      box-shadow: inset 1px $zero-value $input-error-color, inset -1px $zero-value $input-error-color, inset $zero-value -1px $input-error-color;
    }
  }

  .e-outline.e-float-input.e-input-focus.e-success:not(.e-error):not(.e-warning),
  .e-outline.e-float-input.e-control-wrapper.e-input-focus.e-success:not(.e-error):not(.e-warning),
  .e-outline.e-float-input.e-input-group.e-input-focus.e-success:not(.e-error):not(.e-warning),
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-input-focus.e-success:not(.e-error):not(.e-warning) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      box-shadow: inset 1px $zero-value $input-success-color, inset -1px $zero-value $input-success-color, inset $zero-value -1px $input-success-color;
    }
  }

  .e-outline.e-float-input.e-input-focus.e-warning:not(.e-error):not(.e-success),
  .e-outline.e-float-input.e-control-wrapper.e-input-focus.e-warning:not(.e-error):not(.e-success),
  .e-outline.e-float-input.e-input-group.e-input-focus.e-warning:not(.e-error):not(.e-success),
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-input-focus.e-warning:not(.e-error):not(.e-success) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      box-shadow: inset 1px $zero-value $input-warning-color, inset -1px $zero-value $input-warning-color, inset $zero-value -1px $input-warning-color;
    }
  }

  .e-outline.e-input-group.e-disabled:not(.e-input-focus),
  .e-outline.e-input-group.e-disabled:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left):not(.e-input-focus),
  .e-outline.e-input-group.e-control-wrapper.e-disabled:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left):not(.e-input-focus),
  .e-outline.e-input-group.e-disabled.e-float-icon-left:not(.e-input-focus),
  .e-outline.e-input-group.e-disabled.e-control-wrapper.e-float-icon-left:not(.e-input-focus),
  .e-outline.e-float-input.e-input-group.e-disabled.e-valid-input:not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-float-input.e-input-group.e-disabled.e-valid-input:not(.e-input-focus) label.e-float-text::after,
  .e-outline.e-float-input.e-input-group.e-disabled.e-valid-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left):not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-float-input.e-input-group.e-disabled.e-valid-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left):not(.e-input-focus) label.e-float-text::after,
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-disabled.e-valid-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left):not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-disabled.e-valid-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left):not(.e-input-focus) label.e-float-text::after,
  .e-outline.e-disabled.e-valid-input:not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-disabled.e-valid-input:not(.e-input-focus) label.e-float-text::after,
  .e-outline.e-disabled.e-float-input.e-valid-input:not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-disabled.e-float-input.e-valid-input:not(.e-input-focus) label.e-float-text::after,
  .e-outline.e-disabled.e-float-input.e-control-wrapper.e-valid-input:not(.e-input-focus) label.e-float-text::before,
  .e-outline.e-disabled.e-float-input.e-control-wrapper.e-valid-input:not(.e-input-focus) label.e-float-text::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      border-color: $outline-disabled-border-color;
    }
  }

  .e-outline.e-float-input.e-success.e-input-focus input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-input-group.e-success.e-valid-input label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-small.e-success.e-input-focus input:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-success input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-success textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-success textarea:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-success textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-success input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-success input:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-success input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-success textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-success textarea:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-success textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-success label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-input-group.e-success label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-success label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-valid-input.e-success:not(.e-input-focus) input:valid ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-success:not(.e-input-focus) input:focus ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-valid-input.e-success:not(.e-input-focus) textarea:valid ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-success:not(.e-input-focus) textarea:focus ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-success.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-success.e-input-focus input ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-success.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-success.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-success.e-input-focus input ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-success.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      color: $input-success-color;
    }
  }

  .e-outline.e-float-input.e-error.e-input-focus input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-input-group.e-error.e-valid-input label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-small.e-error.e-input-focus input:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-error input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-error textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-error textarea:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-error textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-error input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-error input:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-error input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-error textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-error textarea:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-error textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-error label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-input-group.e-error label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-error label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-valid-input.e-error:not(.e-input-focus) input:valid ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-error:not(.e-input-focus) input:focus ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-valid-input.e-error:not(.e-input-focus) textarea:valid ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-error:not(.e-input-focus) textarea:focus ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-error.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-error.e-input-focus input ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-error.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-error.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-error.e-input-focus input ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-error.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      color: $input-error-color;
    }
  }

  .e-outline.e-float-input.e-warning.e-input-focus input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-input-group.e-warning.e-valid-input label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-small.e-warning.e-input-focus input:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-warning input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-warning textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-warning textarea:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-warning textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-warning input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-warning input:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-warning input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-warning textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-warning textarea:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-warning textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-warning label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-input-group.e-warning label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-warning label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-valid-input.e-warning:not(.e-input-focus) input:valid ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-warning:not(.e-input-focus) input:focus ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-valid-input.e-warning:not(.e-input-focus) textarea:valid ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-valid-input.e-warning:not(.e-input-focus) textarea:focus ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-warning.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-warning.e-input-focus input ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-warning.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-warning.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-warning.e-input-focus input ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-warning.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      color: $input-warning-color;
    }
  }

  .e-outline.e-float-input.e-input-group.e-valid-input.e-input-focus label.e-label-top,
  .e-outline.e-float-input.e-input-group.e-input-focus label.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-valid-input.e-input-focus label.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-input-focus label.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      color: $input-active-border-color;
    }
  }

  .e-outline.e-input[disabled],
  .e-outline.e-input-group .e-input[disabled],
  .e-outline.e-input-group.e-control-wrapper .e-input[disabled],
  .e-outline.e-input-group.e-disabled,
  .e-outline.e-input-group.e-control-wrapper.e-disabled,
  .e-outline.e-float-input input[disabled],
  .e-outline.e-float-input.e-control-wrapper input[disabled],
  .e-outline.e-float-input textarea[disabled],
  .e-outline.e-float-input.e-control-wrapper textarea[disabled],
  .e-outline.e-float-input.e-disabled,
  .e-outline.e-float-input.e-control-wrapper.e-disabled,
  .e-outline.e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left).e-disabled,
  .e-outline.e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error).e-disabled .e-input-in-wrap,
  .e-outline.e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left).e-disabled,
  .e-outline.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error).e-disabled .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      background-image: none;
      border-bottom-color: $outline-disabled-border-color;
      border-color: $outline-disabled-border-color;
    }
  }

  .e-outline.e-float-input.e-input-group.e-disabled .e-float-text,
  .e-outline.e-float-input.e-input-group.e-disabled .e-float-text.e-label-top,
  .e-outline.e-float-input input[disabled] ~ label.e-float-text,
  .e-outline.e-float-input input[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-disabled label.e-float-text,
  .e-outline.e-float-input.e-disabled label.e-float-text.e-label-top,
  .e-outline.e-float-input:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-outline.e-float-input:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-small:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-small:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input textarea[disabled] ~ label.e-float-text,
  .e-outline.e-float-input textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-outline.e-float-input:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-disabled .e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-disabled .e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-disabled input[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-disabled input[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper input[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper input[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-disabled label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-disabled label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-disabled:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-disabled:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-disabled.e-small:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-disabled.e-small:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-disabled textarea[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-disabled textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper textarea[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-disabled:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-disabled:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-disabled.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-disabled.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      color: $outline-float-label-disbale-font-color;
    }
  }

  .e-outline.e-input-group.e-disabled .e-input-group-icon,
  .e-outline.e-input-group.e-control-wrapper.e-disabled .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      color: $outline-input-group-disabled-color;
    }
  }

  .e-outline.e-float-input:not(.e-disabled) .e-clear-icon:hover,
  .e-outline.e-float-input.e-control-wrapper:not(.e-disabled) .e-clear-icon:hover,
  .e-outline.e-input-group:not(.e-disabled) .e-clear-icon:hover,
  .e-outline.e-input-group.e-control-wrapper:not(.e-disabled) .e-clear-icon:hover {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      color: $outline-input-clear-icon-hover-color;
    }
  }

  .e-outline.e-float-input:not(.e-disabled) .e-clear-icon:active,
  .e-outline.e-float-input.e-control-wrapper:not(.e-disabled) .e-clear-icon:active,
  .e-outline.e-input-group:not(.e-disabled) .e-clear-icon:active,
  .e-outline.e-input-group.e-control-wrapper:not(.e-disabled) .e-clear-icon:active {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      color: $outline-input-clear-icon-active-color;
    }
  }

  .e-filled.e-input-group,
  .e-filled.e-input-group.e-control-wrapper,
  .e-filled.e-float-input,
  .e-filled.e-float-input.e-input-group,
  .e-filled.e-float-input.e-control-wrapper,
  .e-filled.e-float-input.e-input-group.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      background: $filled-input-bg-color;
      transition: $background-color-transition;
    }
  }

  .e-filled.e-input-group:hover,
  .e-filled.e-input-group.e-control-wrapper:hover,
  .e-filled.e-float-input:hover,
  .e-filled.e-float-input.e-input-group:hover,
  .e-filled.e-float-input.e-control-wrapper:hover,
  .e-filled.e-float-input.e-input-group.e-control-wrapper:hover {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      background: $filled-input-overlay-focused-bg-color;
      transition: $background-color-transition;
    }
  }

  .e-filled.e-input-group.e-input-focus,
  .e-filled.e-input-group.e-control-wrapper.e-input-focus,
  .e-filled.e-float-input.e-input-focus,
  .e-filled.e-float-input.e-input-group.e-input-focus,
  .e-filled.e-float-input.e-control-wrapper.e-input-focus,
  .e-filled.e-float-input.e-input-group.e-control-wrapper.e-input-focus,
  .e-filled.e-input-group:hover.e-input-focus,
  .e-filled.e-input-group:hover.e-control-wrapper.e-input-focus,
  .e-filled.e-float-input:hover.e-input-focus,
  .e-filled.e-float-input:hover.e-input-group.e-input-focus,
  .e-filled.e-float-input:hover.e-control-wrapper.e-input-focus,
  .e-filled.e-float-input:hover.e-input-group.e-control-wrapper.e-input-focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      background: $filled-input-overlay-activated-bg-color;
      transition: $background-color-transition;
    }
  }

  .e-filled.e-input-group:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-filled.e-input-group.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-filled.e-float-input.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-filled.e-float-input:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-filled.e-float-input:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-filled.e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-filled.e-input-group.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-filled.e-input-group.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-filled.e-float-input.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-filled.e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-filled.e-float-input.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-filled.e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-filled.e-input-group.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-filled.e-input-group.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-filled.e-float-input.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-filled.e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-filled.e-float-input.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-filled.e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
  .e-filled.e-input-group:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled).e-float-icon-left,
  .e-filled.e-input-group.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled).e-float-icon-left,
  .e-filled.e-float-input.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled).e-float-icon-left,
  .e-filled.e-float-input:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled).e-float-icon-left,
  .e-filled.e-input-group.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled).e-float-icon-left,
  .e-filled.e-input-group.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled).e-float-icon-left,
  .e-filled.e-float-input.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled).e-float-icon-left,
  .e-filled.e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled).e-float-icon-left,
  .e-filled.e-input-group.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled).e-float-icon-left,
  .e-filled.e-input-group.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled).e-float-icon-left,
  .e-filled.e-float-input.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled).e-float-icon-left,
  .e-filled.e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled).e-float-icon-left {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      border-color: $filled-input-hover-border-color;
    }
  }

  .e-filled.e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error),
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error),
  .e-filled.e-float-input:not(.e-success):not(.e-warning):not(.e-error),
  .e-filled.e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      border-color: $input-group-full-border-color;
    }
  }

  .e-filled.e-float-input.e-success,
  .e-filled.e-float-input.e-control-wrapper.e-success,
  .e-filled.e-input-group.e-float-icon-left.e-success,
  .e-filled.e-input-group.e-float-icon-left.e-control-wrapper.e-success {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      @include input-state-color ($input-success-color);
    }
  }

  .e-filled.e-float-input.e-warning,
  .e-filled.e-float-input.e-control-wrapper.e-warning,
  .e-filled.e-input-group.e-float-icon-left.e-warning,
  .e-filled.e-input-group.e-float-icon-left.e-control-wrapper.e-warning {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      @include input-state-color ($input-warning-color);
    }
  }

  .e-filled.e-float-input.e-error,
  .e-filled.e-float-input.e-control-wrapper.e-error,
  .e-filled.e-input-group.e-float-icon-left.e-error,
  .e-filled.e-input-group.e-float-icon-left.e-control-wrapper.e-error {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      @include input-state-color ($input-error-color);
    }
  }

  .e-filled label.e-float-text,
  .e-filled.e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input:not(.e-error) input:valid ~ label.e-float-text,
  .e-filled.e-float-input:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input:not(.e-error) input label.e-float-text.e-label-top,
  .e-filled.e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-input-group.e-filled textarea.e-input:-ms-input-placeholder,
  input.e-filled.e-input#{$css}::-webkit-input-placeholder,
  .e-input-group.e-filled.e-control-wrapper input.e-input::-webkit-input-placeholder,
  .e-input-group.e-filled input.e-input::-webkit-input-placeholder,
  textarea.e-filled.e-input#{$css}::-webkit-input-placeholder,
  .e-input-group.e-filled textarea.e-input::-webkit-input-placeholder,
  .e-input-group.e-filled.e-control-wrapper textarea.e-input::-webkit-input-placeholder,
  .e-filled.e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small.e-filled.e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small.e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small.e-filled.e-float-input:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small.e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  input.e-filled.e-input#{$css}:-moz-placeholder,
  .e-input-group.e-filled input.e-input:-moz-placeholder,
  .e-input-group.e-filled input.e-input:-moz-placeholder,
  .e-input-group.e-filled.e-control-wrapper input.e-input:-moz-placeholder,
  input.e-filled.e-input#{$css}:-moz-placeholder,
  .e-input-group.e-filled input.e-input:-moz-placeholder,
  .e-input-group.e-filled.e-control-wrapper input.e-input:-moz-placeholder,
  textarea.e-filled.e-input#{$css}:-moz-placeholder,
  .e-input-group.e-filled textarea.e-input:-moz-placeholder,
  .e-input-group.e-filled.e-control-wrapper textarea.e-input:-moz-placeholder,
  input.e-filled.e-input#{$css}::-moz-placeholder,
  .e-input-group.e-filled input.e-input::-moz-placeholder,
  .e-input-group.e-filled.e-control-wrapper input.e-input::-moz-placeholder,
  textarea.e-filled.e-input#{$css}::-moz-placeholder,
  .e-input-group.e-filled textarea.e-input::-moz-placeholder,
  .e-input-group.e-filled.e-control-wrapper textarea.e-input::-moz-placeholder,
  input.e-filled.e-input#{$css}:-ms-input-placeholder,
  .e-input-group.e-filled input.e-input:-ms-input-placeholder,
  .e-input-group.e-filled.e-control-wrapper input.e-input:-ms-input-placeholder,
  textarea.e-filled.e-input#{$css}:-ms-input-placeholder,
  .e-input-group.e-filled.e-control-wrapper textarea.e-input:-ms-input-placeholder {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      color: $filled-input-float-label-color;
    }
  }

  .e-filled.e-float-input.e-error label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-error label.e-float-text,
  .e-filled.e-float-input.e-error input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-error input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-error textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-error textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-error.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-error.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      color: $input-error-color;
    }
  }

  .e-filled.e-float-input.e-success label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-success label.e-float-text,
  .e-filled.e-float-input.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-success.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-success.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-success input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-success input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-success input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-success input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-success input label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-success.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-success input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-success input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-success input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-success input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-success input label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-success.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-success input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-success input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-success input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-success input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input .e-control-wrapper.e-success input label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-success.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-success input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-success input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-success input[readonly] ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-success input[disabled] ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-success input:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-success input:valid ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-success input ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-success input[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input.e-success input[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-small.e-success.e-input-focus input ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-success.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-success input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-success input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-success input[readonly] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-success input[disabled] ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-success input:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-success input:valid ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-success input ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-success input[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-success input[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-success.e-input-focus input ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-success.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      color: $input-success-color;
    }
  }

  .e-filled.e-float-input.e-warning label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-warning label.e-float-text,
  .e-filled.e-float-input.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-warning.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-warning.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-warning input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-warning input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-warning input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-warning input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-warning input label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-warning.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-warning input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-warning input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-warning input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-warning input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-warning input label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-warning.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-warning input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-warning input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-warning input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-warning input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input .e-control-wrapper.e-warning input label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-warning.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-warning input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-warning input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-warning input[readonly] ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-warning input[disabled] ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-warning input:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-warning input:valid ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-warning input ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-warning input[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input.e-warning input[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-small.e-warning.e-input-focus input ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-warning.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      color: $input-warning-color;
    }
  }

  .e-filled.e-float-input:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input:not(.e-error) input:valid ~ label.e-float-text,
  .e-filled.e-float-input:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input:not(.e-error) input label.e-float-text.e-label-top,
  .e-filled.e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input .e-control-wrapper:not(.e-error) input label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error) input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error) input[readonly] ~ label.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error) input:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error) input:valid ~ label.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error) input[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input[readonly] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error) input:valid ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error) input[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input:not(.e-input-focus):not(.e-disabled) textarea:not(:focus):not(:valid) ~ label.e-float-text:not(.e-label-top),
  .e-filled.e-float-input:not(.e-input-focus) textarea:not(:focus):not(:valid) ~ label.e-float-text:not(.e-label-top),
  .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus):not(.e-disabled) textarea:not(:focus):not(:valid) ~ label.e-float-text:not(.e-label-top),
  .e-filled.e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input:not(.e-error) textarea label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error) textarea[readonly] ~ label.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error) textarea[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[readonly] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      color: $filled-input-float-label-color;
    }
  }

  .e-filled.e-float-input:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error) input:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error) input[readonly]:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error) input[readonly]:focus ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input[readonly]:focus ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper:not(.e-error) input[readonly]:focus ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error).e-input-focus input[readonly] ~ label.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error).e-input-focus input[readonly] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input[readonly] ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error).e-input-focus input[readonly] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error) textarea[readonly]:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error) textarea[readonly]:focus ~ label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea[readonly]:focus ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error).e-input-focus textarea[readonly] ~ label.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error).e-input-focus textarea[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error).e-input-focus textarea[readonly] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-small .e-filled.e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      color: $input-active-accent-color;
    }
  }

  .e-input-group textarea.e-input:-ms-input-placeholder,
  .e-input-group textarea.e-input:-moz-placeholder,
  .e-input-group .e-input:-ms-input-placeholder,
  input.e-input#{$css}::-webkit-input-placeholder {
    @if $input-skin-name == 'tailwind' or $input-skin-name == 'tailwind3' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' {
      color: $input-placeholder-color;
    }
  }

  .e-filled.e-float-input:not(.e-disabled) .e-clear-icon:hover,
  .e-filled.e-float-input.e-control-wrapper:not(.e-disabled) .e-clear-icon:hover,
  .e-filled.e-input-group:not(.e-disabled) .e-clear-icon:hover,
  .e-filled.e-input-group.e-control-wrapper:not(.e-disabled) .e-clear-icon:hover {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      color: $filled-input-clear-icon-hover-color;
    }
  }

  .e-filled.e-float-input:not(.e-disabled) .e-clear-icon:active,
  .e-filled.e-float-input.e-control-wrapper:not(.e-disabled) .e-clear-icon:active,
  .e-filled.e-input-group:not(.e-disabled) .e-clear-icon:active,
  .e-filled.e-input-group.e-control-wrapper:not(.e-disabled) .e-clear-icon:active {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      color: $filled-input-clear-icon-active-color;
    }
  }

  .e-filled.e-input#{$css}[disabled],
  .e-input-group.e-filled .e-input[disabled],
  .e-input-group.e-filled.e-control-wrapper .e-input[disabled],
  .e-input-group.e-filled.e-disabled,
  .e-input-group.e-filled.e-control-wrapper.e-disabled,
  .e-filled.e-float-input input[disabled],
  .e-filled.e-float-input.e-control-wrapper input[disabled],
  .e-filled.e-float-input textarea[disabled],
  .e-filled.e-float-input.e-control-wrapper textarea[disabled],
  .e-filled.e-float-input.e-disabled,
  .e-filled.e-float-input.e-control-wrapper.e-disabled {
    @if $input-skin-name == 'material' {
      background: $filled-input-disabled-bg-color;
      background-image: none;
      background-position: initial;
      background-repeat: no-repeat;
      background-size: $zero-value;
      border-color: $filled-input-disabled-border-color;
      color: $filled-input-disabled-font-color;
    }
    @if $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      //background: $filled-input-disabled-bg-color;
      background-image: none;
      background-position: initial;
      background-repeat: no-repeat;
      background-size: $zero-value;
      border-color: $filled-input-disabled-border-color;
      color: $filled-input-disabled-font-color;
    }
  }

  .e-filled.e-float-input.e-disabled:not(.e-success):not(.e-warning):not(.e-error),
  .e-filled.e-float-input.e-control-wrapper.e-disabled:not(.e-success):not(.e-warning):not(.e-error) {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      border-color: $filled-input-disabled-border-color;
    }
  }

  .e-filled.e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left).e-disabled,
  .e-filled.e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error).e-disabled .e-input-in-wrap,
  .e-filled.e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left).e-disabled,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error).e-disabled .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      background: $filled-input-disabled-bg-color;
      background-image: none;
      background-position: initial;
      background-repeat: no-repeat;
      background-size: $zero-value;
      border-color: $filled-input-disabled-border-color;
      color: $filled-input-disabled-font-color;
    }
  }

  .e-filled.e-float-input.e-input-group.e-disabled .e-float-text,
  .e-filled.e-float-input.e-input-group.e-disabled .e-float-text.e-label-top,
  .e-filled.e-float-input input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-disabled label.e-float-text,
  .e-filled.e-float-input.e-disabled label.e-float-text.e-label-top,
  .e-filled.e-float-input:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input textarea[disabled] ~ label.e-float-text,
  .e-filled.e-float-input textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-filled.e-float-input:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-disabled .e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-disabled .e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-disabled input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-disabled input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-disabled label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-disabled label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-disabled:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-disabled:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-disabled.e-small:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-disabled.e-small:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-disabled textarea[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-disabled textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper textarea[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-disabled:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-disabled:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-disabled.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-disabled.e-small:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  input.e-filled.e-disabled.e-input#{$css}:-moz-placeholder,
  .e-input-group.e-filled.e-disabled input.e-input:-moz-placeholder,
  .e-input-group.e-filled.e-disabled input.e-input:-moz-placeholder,
  .e-input-group.e-filled.e-disabled.e-control-wrapper input.e-input:-moz-placeholder,
  input.e-filled.e-disabled.e-input#{$css}:-moz-placeholder,
  .e-input-group.e-filled.e-disabled input.e-input:-moz-placeholder,
  .e-input-group.e-filled.e-disabled.e-control-wrapper input.e-input:-moz-placeholder,
  textarea.e-filled.e-disabled.e-input#{$css}:-moz-placeholder,
  .e-input-group.e-filled.e-disabled textarea.e-input:-moz-placeholder,
  .e-input-group.e-filled.e-disabled.e-control-wrapper textarea.e-input:-moz-placeholder,
  input.e-filled.e-disabled.e-input#{$css}::-moz-placeholder,
  .e-input-group.e-filled.e-disabled input.e-input::-moz-placeholder,
  .e-input-group.e-filled.e-disabled.e-control-wrapper input.e-input::-moz-placeholder,
  textarea.e-filled.e-disabled.e-input#{$css}::-moz-placeholder,
  .e-input-group.e-filled.e-disabled textarea.e-input::-moz-placeholder,
  .e-input-group.e-filled.e-disabled.e-control-wrapper textarea.e-input::-moz-placeholder,
  input.e-filled.e-disabled.e-input#{$css}:-ms-input-placeholder,
  .e-input-group.e-filled.e-disabled input.e-input:-ms-input-placeholder,
  .e-input-group.e-filled.e-disabled.e-control-wrapper input.e-input:-ms-input-placeholder,
  textarea.e-filled.e-disabled.e-input#{$css}:-ms-input-placeholder,
  .e-input-group.e-filled.e-disabled.e-control-wrapper textarea.e-input:-ms-input-placeholder,
  .e-input-group.e-filled.e-disabled textarea.e-input:-ms-input-placeholder,
  input.e-filled.e-disabled.e-input#{$css}::-webkit-input-placeholder,
  .e-input-group.e-filled.e-disabled.e-control-wrapper input.e-input::-webkit-input-placeholder,
  .e-input-group.e-filled.e-disabled input.e-input::-webkit-input-placeholder,
  textarea.e-filled.e-disabled.e-input#{$css}::-webkit-input-placeholder,
  .e-input-group.e-filled.e-disabled textarea.e-input::-webkit-input-placeholder,
  .e-input-group.e-filled.e-disabled.e-control-wrapper textarea.e-input::-webkit-input-placeholder,
  .e-filled.e-float-input.e-disabled .e-clear-icon,
  .e-filled.e-float-input.e-control-wrapper.e-disabled .e-clear-icon,
  .e-input-group.e-filled.e-disabled .e-clear-icon,
  .e-input-group.e-filled.e-control-wrapper.e-disabled .e-clear-icon,
  .e-input-group.e-filled.e-disabled .e-input-group-icon,
  .e-input-group.e-filled.e-control-wrapper.e-disabled .e-input-group-icon,
  .e-filled.e-float-input:not(.e-disabled) input[disabled],
  .e-filled.e-float-input.e-control-wrapper:not(.e-disabled) input[disabled],
  .e-filled.e-float-input:not(.e-disabled) textarea[disabled],
  .e-filled.e-float-input.e-control-wrapper:not(.e-disabled) textarea[disabled] {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      color: $filled-input-disabled-font-color;
    }
  }

  .e-filled.e-float-input.e-success textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-success textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small.e-success textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-success textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-success textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-success textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-success textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-success textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-success textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-success textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-success textarea label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-success textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-success textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-success textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-success textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-success textarea label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-small.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-success textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-success textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-success textarea[readonly] ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-success textarea[disabled] ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-success textarea:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-success textarea:valid ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-success textarea ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-success textarea[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input.e-success textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-success textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-success textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-success textarea[readonly] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-success textarea[disabled] ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-success textarea:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-success textarea:valid ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-success textarea ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-success textarea[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-success textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-success input:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-success textarea:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-success input:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-success textarea:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-success.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-success.e-input-focus input ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-success.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-success.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-success.e-input-focus input ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-success.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-success:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-success:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small.e-filled.e-float-input.e-success:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-autofill.e-success:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-autofill.e-success:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small.e-filled.e-float-input.e-control-wrapper.e-autofill.e-success:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-success textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-success textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small.e-filled.e-float-input.e-success textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-autofill.e-success textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-autofill.e-success textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small.e-filled.e-float-input.e-control-wrapper.e-autofill.e-success textarea:not(:focus):-webkit-autofill ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      color: $input-success-color;
    }
  }

  .e-filled.e-float-input.e-control-wrapper.e-small.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-warning input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-warning input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-warning input[readonly] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-warning input[disabled] ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-warning input:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-warning input:valid ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-warning input ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-warning input[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-warning input[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-warning.e-input-focus input ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-warning.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-warning textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-warning textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small.e-warning textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-warning textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-warning textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-warning textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-warning textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-warning textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-warning textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-warning textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-warning textarea label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-warning textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-warning textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-warning textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-warning textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-warning textarea label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-small.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-warning textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-warning textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-warning textarea[readonly] ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-warning textarea[disabled] ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-warning textarea:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-warning textarea:valid ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-warning textarea ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-warning textarea[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input.e-warning textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-warning textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-warning textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-warning textarea[readonly] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-warning textarea[disabled] ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-warning textarea:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-warning textarea:valid ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-warning textarea ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-warning textarea[readonly] ~ label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-warning textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-warning input:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-warning textarea:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-warning input:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-warning textarea:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-warning.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-warning.e-input-focus input ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-warning.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-warning.e-input-focus inputs ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-warning.e-input-focus input ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-warning.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-warning:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-warning:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small.e-filled.e-float-input.e-warning:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-autofill.e-warning:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-autofill.e-warning:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small.e-filled.e-float-input.e-control-wrapper.e-autofill.e-warning:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-warning textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-warning textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small.e-filled.e-float-input.e-warning textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-autofill.e-warning textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-autofill.e-warning textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-small.e-filled.e-float-input.e-control-wrapper.e-autofill.e-warning textarea:not(:focus):-webkit-autofill ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      color: $input-warning-color;
    }
  }
}


@include export-module('input-material-icons') {
  .e-float-input,
  .e-float-input.e-control-wrapper {
    .e-clear-icon::before {
      content: '\e932';
      font-family: 'e-icons';
    }
  }

  .e-input-group,
  .e-input-group.e-control-wrapper {
    .e-clear-icon::before {
      content: '\e932';
      font-family: 'e-icons';
    }
  }

  .e-filled.e-float-input,
  .e-filled.e-float-input.e-control-wrapper {
    .e-clear-icon::before {
      content: '\e208';
      font-family: 'e-icons';
    }
  }

  .e-filled.e-input-group,
  .e-filled.e-input-group.e-control-wrapper {
    .e-clear-icon::before {
      content: '\e208';
      font-family: 'e-icons';
    }
  }

  .e-outline.e-float-input,
  .e-outline.e-float-input.e-control-wrapper {
    .e-clear-icon::before {
      content: '\e208';
      font-family: 'e-icons';
    }
  }

  .e-outline.e-input-group,
  .e-outline.e-input-group.e-control-wrapper {
    .e-clear-icon::before {
      content: '\e208';
      font-family: 'e-icons';
    }
  }
}

@include export-module('input-bigger') {

  .e-input-group.e-bigger .e-input,
  .e-input-group.e-bigger.e-control-wrapper .e-input,
  .e-bigger .e-input-group .e-input,
  .e-bigger .e-input-group.e-control-wrapper .e-input {
    font: inherit;
  }

  .e-input.e-bigger#{$css}:focus,
  .e-bigger .e-input#{$css}:focus {
    padding-bottom: $input-bigger-padding-bottom;
  }

  .e-input.e-bigger#{$css}:focus,
  .e-bigger .e-input#{$css}:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding-bottom: $input-focus-bigger-padding-bottom;
    }
  }

  input.e-input.e-bigger#{$css},
  textarea.e-input.e-bigger#{$css},
  .e-bigger input.e-input#{$css},
  .e-bigger textarea.e-input#{$css},
  .e-input-group.e-bigger,
  .e-bigger .e-input-group,
  .e-input-group.e-control-wrapper.e-bigger,
  .e-bigger .e-input-group.e-control-wrapper {
    @if ($input-skin-name == 'tailwind' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      border-radius: $input-bigger-border-radius;
    }
    font-size: $input-bigger-font-size;
  }

  .e-input-group.e-bigger .e-input,
  .e-input-group.e-bigger input,
  .e-input-group.e-control-wrapper.e-bigger .e-input,
  .e-input-group.e-control-wrapper.e-bigger input,
  .e-float-input.e-bigger input,
  .e-float-input.e-input-group.e-bigger input,
  .e-float-input.e-control-wrapper.e-bigger input,
  .e-float-input.e-control-wrapper.e-input-group.e-bigger input,
  .e-float-input.e-bigger,
  .e-float-input.e-control-wrapper.e-bigger,
  .e-bigger .e-input-group .e-input,
  .e-bigger .e-input-group input,
  .e-bigger .e-input-group.e-control-wrapper .e-input,
  .e-bigger .e-input-group.e-control-wrapper input,
  .e-bigger .e-float-input input,
  .e-bigger .e-float-input.e-input-group input,
  .e-bigger .e-float-input.e-control-wrapper input,
  .e-bigger .e-float-input.e-control-wrapper.e-input-group input,
  .e-bigger .e-float-input,
  .e-bigger .e-float-input.e-control-wrapper {
    @if ($input-skin-name == 'tailwind' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      border-radius: $input-bigger-border-radius;
    }
  }


  input.e-input.e-bigger#{$css},
  textarea.e-input.e-bigger#{$css},
  .e-input-group.e-bigger .e-input,
  .e-input-group.e-bigger.e-control-wrapper .e-input,
  .e-bigger input.e-input#{$css},
  .e-bigger textarea.e-input#{$css},
  .e-bigger .e-input-group .e-input,
  .e-bigger .e-input-group.e-control-wrapper .e-input {
    line-height: inherit;
    margin-bottom: $input-bigger-margin-bottom;
    padding: $input-bigger-padding;
  }

  .e-input-group.e-bigger .e-input:focus,
  .e-bigger .e-input-group .e-input:focus,
  .e-input-group.e-control-wrapper.e-bigger .e-input:focus,
  .e-bigger .e-input-group.e-control-wrapper .e-input:focus,
  .e-input-group.e-bigger.e-input-focus .e-input,
  .e-bigger .e-input-group.e-input-focus .e-input,
  .e-input-group.e-control-wrapper.e-bigger.e-input-focus .e-input,
  .e-bigger .e-input-group.e-control-wrapper.e-input-focus .e-input {
    padding: $input-bigger-padding;
  }
  /* stylelint-disable property-no-vendor-prefix */
  .e-input-group.e-bigger.e-float-icon-left:not(.e-disabled) .e-input-group-icon:first-child:hover,
  .e-input-group.e-control-wrapper.e-bigger.e-float-icon-left:not(.e-disabled) .e-input-group-icon:first-child:hover {
    @if $input-skin-name == 'highcontrast' {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border-width: $zero-value + px 1px $zero-value + px 1px;
      box-sizing: border-box;
    }
  }

  .e-input-group.e-bigger .e-input-group-icon,
  .e-input-group .e-input-group-icon.e-bigger,
  .e-bigger .e-input-group .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-bigger .e-input-group-icon,
  .e-input-group.e-control-wrapper .e-input-group-icon.e-bigger,
  .e-bigger .e-input-group.e-control-wrapper .e-input-group-icon {
    @if $skin-name != 'fluent2' {
      min-height: $input-bigger-child-min-height;
      min-width: $input-bigger-child-min-width;
    }
    @if $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      border-radius: $input-bigger-icon-border-radius;
    }
  }

  .e-input-group.e-bigger .e-input-group-icon,
  .e-input-group .e-input-group-icon.e-bigger,
  .e-bigger .e-input-group .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-bigger .e-input-group-icon,
  .e-input-group.e-control-wrapper .e-input-group-icon.e-bigger,
  .e-bigger .e-input-group.e-control-wrapper .e-input-group-icon {
    font-size: $input-bigger-icon-font-size;
    padding: $input-bigger-child-padding;
  }

  .e-input-group.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-input-group.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-float-input.e-input-group.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-float-input.e-input-group.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-float-input.e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon,
  .e-float-input.e-input-group.e-control-wrapper.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-float-input.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-float-input.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $input-bigger-left-icon-font-size;
      margin: $zero-value;
      min-height: $input-bigger-left-child-min-height;
      min-width: $input-bigger-left-child-min-width;
      padding: $zero-value;
    }
  }

  .e-input.e-small.e-bigger#{$css}:focus,
  .e-bigger .e-input.e-small#{$css}:focus {
    padding-bottom: $input-bigger-small-padding-bottom;
  }

  .e-input.e-small.e-bigger#{$css}:focus,
  .e-bigger .e-input.e-small#{$css}:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding-bottom: $input-focus-bigger-small-padding-bottom;
    }
  }

  input.e-input.e-bigger.e-small#{$css},
  textarea.e-input.e-bigger.e-small#{$css},
  .e-bigger.e-small input.e-input#{$css},
  .e-bigger.e-small textarea.e-input#{$css},
  .e-input-group.e-bigger.e-small,
  .e-bigger.e-small .e-input-group,
  .e-bigger.e-small .e-input-group.e-control-wrapper {
    @if ($input-skin-name == 'tailwind' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      border-radius: $input-bigger-small-border-radius;
    }
  }

  .e-input-group.e-bigger.e-small .e-input,
  .e-input-group.e-bigger.e-small input,
  .e-input-group.e-control-wrapper.e-bigger.e-small .e-input,
  .e-input-group.e-control-wrapper.e-bigger.e-small input,
  .e-float-input.e-bigger.e-small input,
  .e-float-input.e-input-group.e-bigger.e-small input,
  .e-float-input.e-control-wrapper.e-bigger.e-small input,
  .e-float-input.e-control-wrapper.e-input-group.e-bigger.e-small input,
  .e-float-input.e-bigger.e-small,
  .e-float-input.e-control-wrapper.e-bigger.e-small,
  .e-bigger.e-small .e-input-group .e-input,
  .e-bigger.e-small .e-input-group input,
  .e-bigger.e-small .e-input-group.e-control-wrapper .e-input,
  .e-bigger.e-small .e-input-group.e-control-wrapper input,
  .e-bigger.e-small .e-float-input input,
  .e-bigger.e-small .e-float-input.e-input-group input,
  .e-bigger.e-small .e-float-input.e-control-wrapper input,
  .e-bigger.e-small .e-float-input.e-control-wrapper.e-input-group input,
  .e-bigger.e-small .e-float-input,
  .e-bigger.e-small .e-float-input.e-control-wrapper {
    @if ($input-skin-name == 'tailwind' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      border-radius: $input-bigger-small-border-radius;
    }
  }

  .e-input-group.e-small.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-input-group.e-small.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-input-group.e-small.e-float-icon-left > .e-input-group-icon,
  .e-float-input.e-input-group.e-small.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-float-input.e-input-group.e-small.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-input-group.e-float-input.e-small.e-float-icon-left > .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-small.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-small.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-input-group.e-control-wrapper.e-small.e-float-icon-left > .e-input-group-icon,
  .e-float-input.e-control-wrapper.e-input-group.e-small.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-float-input.e-control-wrapper.e-input-group.e-small.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-input-group.e-control-wrapper.e-float-input.e-small.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $input-bigger-small-left-icon-font-size;
      margin: $zero-value;
      min-height: $input-bigger-small-left-child-min-height;
      min-width: $input-bigger-small-left-child-min-width;
      padding: $zero-value;
    }
  }

  .e-input.e-small.e-bigger#{$css},
  .e-input-group.e-small.e-bigger,
  .e-small.e-bigger .e-input-group,
  .e-input-group.e-control-wrapper.e-small.e-bigger,
  .e-small.e-bigger .e-input-group.e-control-wrapper,
  .e-bigger .e-input#{$css}.e-small,
  .e-small .e-input#{$css}.e-bigger,
  .e-bigger .e-input-group.e-small,
  .e-bigger .e-input-group.e-control-wrapper.e-small,
  .e-small .e-input-group.e-bigger,
  .e-small .e-input-group.e-control-wrapper.e-bigger,
  .e-input-group.e-small.e-bigger.e-disabled,
  .e-small.e-bigger .e-input-group.e-disabled,
  .e-input-group.e-control-wrapper.e-small.e-bigger.e-disabled,
  .e-small.e-bigger .e-input-group.e-control-wrapper.e-disabled,
  .e-bigger .e-input#{$css}.e-small.e-disabled,
  .e-small .e-input#{$css}.e-bigger.e-disabled,
  .e-bigger .e-input-group.e-small.e-disabled,
  .e-bigger .e-input-group.e-control-wrapper.e-small.e-disabled,
  .e-small .e-input-group.e-bigger.e-disabled,
  .e-small .e-input-group.e-control-wrapper.e-bigger.e-disabled {
    font-size: $input-bigger-small-font-size;
  }

  .e-input-group.e-small.e-bigger .e-input,
  .e-input-group.e-small .e-input.e-bigger,
  .e-input-group.e-control-wrapper.e-small.e-bigger .e-input,
  .e-input-group.e-control-wrapper.e-small .e-input.e-bigger,
  .e-bigger .e-input-group.e-small .e-input,
  .e-bigger .e-input-group.e-control-wrapper.e-small .e-input {
    font: inherit;
    padding: $input-bigger-small-padding;
  }

  .e-input-group.e-small.e-bigger .e-input:focus,
  .e-input-group.e-small .e-input.e-bigger:focus,
  .e-bigger .e-input-group.e-small .e-input:focus,
  .e-input-group.e-control-wrapper.e-small.e-bigger .e-input:focus,
  .e-input-group.e-control-wrapper.e-small .e-input.e-bigger:focus,
  .e-bigger .e-input-group.e-control-wrapper.e-small .e-input:focus,
  .e-input-group.e-small.e-bigger.e-input-focus .e-input,
  .e-input-group.e-small.e-input-focus .e-input.e-bigger,
  .e-bigger .e-input-group.e-small.e-input-focus .e-input,
  .e-input-group.e-control-wrapper.e-small.e-bigger.e-input-focus .e-input,
  .e-input-group.e-control-wrapper.e-small.e-input-focus .e-input.e-bigger,
  .e-bigger .e-input-group.e-control-wrapper.e-small.e-input-focus .e-input,
  .e-input.e-small.e-bigger#{$css},
  .e-bigger .e-input#{$css}.e-small,
  .e-small .e-input#{$css}.e-bigger {
    padding: $input-bigger-small-padding;
  }

  .e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger,
  .e-small.e-bigger .e-input-group .e-input-group-icon,
  .e-small.e-bigger .e-input-group.e-control-wrapper .e-input-group-icon,
  .e-bigger .e-input-group.e-small .e-input-group-icon,
  .e-bigger .e-input-group.e-control-wrapper.e-small .e-input-group-icon {
    font-size: $input-bigger-small-icon-font-size;
    min-height: $input-bigger-small-child-min-height;
    min-width: $input-bigger-small-child-min-width;
    padding: $input-bigger-small-child-padding;
    @if $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      border-radius: $input-bigger-small-child-border-radius;
      margin: $input-bigger-small-child-margin;
    }
  }

  .e-float-input.e-bigger label.e-float-text,
  .e-float-input label.e-float-text.e-bigger,
  .e-float-input input.e-bigger ~ label.e-float-text,
  .e-bigger .e-float-input label.e-float-text,
  .e-float-input.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger label.e-float-text,
  .e-float-input.e-control-wrapper label.e-float-text.e-bigger,
  .e-float-input.e-control-wrapper input.e-bigger ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    font-size: $float-placeholder-bigger-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -8px;
    }
  }

  .e-float-input.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom  {
    @include float-label-alignment;
    font-style: $input-font-style;
  }

  .e-float-input.e-small.e-bigger label.e-float-text,
  .e-bigger .e-float-input.e-small label.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-small.e-bigger label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    font-size: $float-placeholder-bigger-small-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -9px;
    }
    font-style: $input-font-style;
  }

  .e-float-input.e-bigger .e-input-in-wrap label.e-float-text,
  .e-float-input .e-input-in-wrap label.e-float-text.e-bigger,
  .e-float-input .e-input-in-wrap input.e-bigger ~ label.e-float-text,
  .e-bigger .e-float-input .e-input-in-wrap label.e-float-text,
  .e-float-input.e-bigger:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger .e-input-in-wrap label.e-float-text,
  .e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text.e-bigger,
  .e-float-input.e-control-wrapper .e-input-in-wrap input.e-bigger ~ label.e-float-text,
  .e-bigger.e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom  {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      top: -8px;
    }
  }

  .e-float-input.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input.e-input-focus input ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-input-focus input ~ label.e-float-text {
    font-size: $float-label-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      top: -9px;
      transform: translate3d(0, -6px, 0) scale(.92);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      padding-right: 0;
      transform: translate3d(-10px, -43px, 0) scale(1);
    }
    @else if ($input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4') {
      padding-right: 0;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if ($input-skin-name == 'tailwind') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -34px, 0) scale(1);
    }
    @else if ($input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -35px, 0) scale(1);
    }
    @else if ($input-skin-name == 'FluentUI') {
      font-style: $input-font-normal;
      font-weight: 600;
      padding-right: 0;
      transform: translate3d(-10px, -38px, 0) scale(1);
    }
  }

  .e-float-input.e-bigger input:focus ~ label.e-float-text,
  .e-float-input.e-bigger input:valid ~ label.e-float-text,
  .e-float-input.e-bigger input ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger input label.e-float-text.e-label-top,
  .e-bigger .e-float-input input:focus ~ label.e-float-text,
  .e-bigger .e-float-input input:valid ~ label.e-float-text,
  .e-bigger .e-float-input input ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input input label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-bigger input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger input:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger input ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger input label.e-float-text.e-label-top,
  .e-bigger .e-float-input.e-control-wrapper input:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper input:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper input ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper input label.e-float-text.e-label-top,
  .e-float-input.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input.e-input-focus input ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      transform: translate3d(0, -6px, 0) scale(.86);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      padding-right: 0;
      transform: translate3d(-10px, -43px, 0) scale(1);
    }
    @else if $input-skin-name == 'bootstrap' {
      padding-right: 0;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if ($input-skin-name == 'bootstrap4') {
      padding-right: 0;
      transform: translate3d(-10px, -42px, 0) scale(1);
    }
    @else if ($input-skin-name == 'tailwind') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -37px, 0) scale(1);
    }
    @else if ($input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if ($input-skin-name == 'FluentUI') {
      font-style: $input-font-normal;
      font-weight: 600;
      padding-right: 0;
      transform: translate3d(-10px, -45px, 0) scale(1);
    }
  }

  .e-float-input.e-bigger.e-small input:focus ~ label.e-float-text,
  .e-float-input.e-bigger.e-small input:valid ~ label.e-float-text,
  .e-float-input.e-bigger.e-small input ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger.e-small input label.e-float-text.e-label-top,
  .e-bigger .e-float-input.e-small input:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-small input:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-small input ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small input label.e-float-text.e-label-top,
  .e-small .e-float-input.e-bigger input:focus ~ label.e-float-text,
  .e-small .e-float-input.e-bigger input:valid ~ label.e-float-text,
  .e-small .e-float-input.e-bigger input ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-bigger input label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-bigger.e-small input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-small input:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-small input ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-small input label.e-float-text.e-label-top,
  .e-bigger .e-float-input.e-control-wrapper.e-small input:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small input:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small input ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small input label.e-float-text.e-label-top,
  .e-small .e-float-input.e-control-wrapper.e-bigger input:focus ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-bigger input:valid ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-bigger input ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-bigger input label.e-float-text.e-label-top,
  .e-float-input.e-bigger.e-small.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input.e-small.e-input-focus input-group-animation ~ label.e-float-text,
  .e-small .e-float-input.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-small.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small.e-input-focus input ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-bigger.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      transform: translate3d(0, -6px, 0) scale(.92);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      padding-right: 0;
      transform: translate3d(-10px, -43px, 0) scale(1);
    }
    @else if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' {
      padding-right: 0;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if ($input-skin-name == 'tailwind') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -34px, 0) scale(1);
    }
    @else if ($input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -36px, 0) scale(1);
    }
    @else if ($input-skin-name == 'FluentUI') {
      font-style: $input-font-normal;
      font-weight: 600;
      padding-right: 0;
      transform: translate3d(-10px, -41px, 0) scale(1);
    }
  }

  .e-float-input.e-bigger input:focus ~ label.e-float-text,
  .e-float-input.e-bigger input:valid ~ label.e-float-text,
  .e-float-input.e-bigger input ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input input:focus ~ label.e-float-text,
  .e-bigger .e-float-input input:valid ~ label.e-float-text,
  .e-bigger .e-float-input input ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger input:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger input ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper input:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper input:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper input ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input.e-input-focus input ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-input-focus input ~ label.e-float-text {
    font-size: $float-label-bigger-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      top: -9px;
    }
    @if $input-skin-name == 'fluent2' {
      top: -14px;
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'highcontrast' or $input-skin-name == 'tailwind' {
      padding-right: 0;
      top: 14px;
    }
    @else if $input-skin-name == 'tailwind3' {
      top: -23px;
      left: 2px;
    }
  }

  .e-float-input.e-bigger .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-float-input.e-bigger .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-float-input.e-bigger .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger .e-input-in-wrap input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger .e-input-in-wrap input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-bigger .e-float-input .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-bigger .e-float-input .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input .e-input-in-wrap input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input .e-input-in-wrap input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger .e-input-in-wrap input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger .e-input-in-wrap input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper .e-input-in-wrap input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper .e-input-in-wrap input[disabled] ~ label.e-label-top.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      top: -7px;
    }
    @if $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      top: -14px;
    }
  }

  .e-float-input.e-small.e-bigger input:focus ~ label.e-float-text,
  .e-float-input.e-small.e-bigger input:valid ~ label.e-float-text,
  .e-float-input.e-small.e-bigger input ~ label.e-label-top.e-float-text,
  .e-float-input.e-small.e-bigger input[readonly] ~ label.e-float-text,
  .e-float-input.e-small.e-bigger input[disabled] ~ label.e-float-text,
  .e-bigger .e-float-input.e-small input:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-small input:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-small input ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small input[readonly] ~ label.e-float-text,
  .e-bigger .e-float-input.e-small input[disabled] ~ label.e-float-text,
  .e-bigger.e-small .e-float-input.e-control-wrapper input:focus ~ label.e-float-text,
  .e-bigger.e-small .e-float-input.e-control-wrapper input:valid ~ label.e-float-text,
  .e-bigger.e-small .e-float-input.e-control-wrapper input ~ label.e-label-top.e-float-text,
  .e-bigger.e-small .e-float-input.e-control-wrapper input[readonly] ~ label.e-float-text,
  .e-bigger.e-small .e-float-input.e-control-wrapper input[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger input:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger input ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger input[readonly] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger input[disabled] ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small input:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small input:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small input ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small input[readonly] ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small input[disabled] ~ label.e-float-text,
  .e-float-input.e-small.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input.e-small.e-input-focus input ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small.e-input-focus input ~ label.e-float-text {
    font-size: $float-label-bigger-small-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      top: -7px;
    }
    @else if $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      top: -10px;
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or  $input-skin-name == 'highcontrast' or $input-skin-name == 'tailwind' {
      padding-right: 0;
      top: 16px;
    }
  }

  .e-float-input.e-small.e-bigger .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-float-input.e-small.e-bigger .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-float-input.e-small.e-bigger .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-float-input.e-small.e-bigger .e-input-in-wrap input[readonly] ~ label.e-float-text,
  .e-float-input.e-small.e-bigger .e-input-in-wrap input[disabled] ~ label.e-float-text,
  .e-bigger .e-float-input.e-small .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-small .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-small .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small .e-input-in-wrap input[readonly] ~ label.e-float-text,
  .e-bigger .e-float-input.e-small .e-input-in-wrap input[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger .e-input-in-wrap input[readonly] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger .e-input-in-wrap input[disabled] ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small .e-input-in-wrap input[readonly] ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small .e-input-in-wrap input[disabled] ~ label.e-float-text,
  .e-float-input.e-small .e-input-in-wrap label.e-float-text,
  .e-small .e-float-input .e-input-in-wrap label.e-float-text,
  .e-float-input.e-small:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-small .e-input-in-wrap label.e-float-text,
  .e-small .e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-small.e-bigger .e-input-in-wrap label.e-float-text,
  .e-bigger .e-float-input.e-small .e-input-in-wrap label.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-bigger:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger.e-small:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-small.e-bigger .e-input-in-wrap label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small .e-input-in-wrap label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      top: -10px;
    }
  }

  .e-float-input.e-bigger,
  .e-bigger .e-float-input,
  .e-float-input.e-control-wrapper.e-bigger,
  .e-bigger .e-float-input.e-control-wrapper {
    line-height: 1.5;
    margin-bottom: $input-bigger-margin-bottom;
    margin-top: $input-bigger-margin-top;
    padding-top: $float-input-bigger-wrap-padding-top;
    position: relative;
    width: 100%;
  }

  .e-float-input.e-small.e-bigger,
  .e-bigger.e-small .e-float-input.e-small,
  .e-bigger .e-float-input.e-small,
  .e-small .e-float-input.e-bigger,
  .e-float-input.e-control-wrapper.e-small.e-bigger,
  .e-bigger.e-small .e-float-input.e-control-wrapper.e-small,
  .e-bigger .e-float-input.e-control-wrapper.e-small,
  .e-small .e-float-input.e-control-wrapper.e-bigger {
    line-height: 1.4;
    margin-bottom: $input-small-bigger-margin-bottom;
    margin-top: $input-small-bigger-margin-top;
    padding-top: $float-input-bigger-small-wrap-padding-top;
  }

  .e-input-group.e-bigger,
  .e-bigger .e-input-group,
  .e-input-group.e-control-wrapper.e-bigger,
  .e-bigger .e-input-group.e-control-wrapper {
    line-height: 1.5;
  }

  .e-input-group.e-small.e-bigger,
  .e-bigger.e-small .e-input-group.e-small,
  .e-bigger .e-input-group.e-small,
  .e-small .e-input-group.e-bigger,
  .e-input-group.e-control-wrapper.e-small.e-bigger,
  .e-bigger.e-small .e-input-group.e-control-wrapper.e-small,
  .e-bigger .e-input-group.e-control-wrapper.e-small,
  .e-small .e-input-group.e-control-wrapper.e-bigger {
    line-height: normal;
  }

  .e-float-input.e-bigger.e-no-float-label,
  .e-bigger .e-float-input.e-no-float-label,
  .e-float-input.e-small.e-bigger.e-no-float-label,
  .e-bigger .e-float-input.e-small.e-no-float-label,
  .e-small .e-float-input.e-bigger.e-no-float-label,
  .e-float-input.e-control-wrapper.e-bigger.e-no-float-label,
  .e-bigger .e-float-input.e-control-wrapper.e-no-float-label,
  .e-float-input.e-control-wrapper.e-small.e-bigger.e-no-float-label,
  .e-bigger .e-float-input.e-control-wrapper.e-small.e-no-float-label,
  .e-small .e-float-input.e-control-wrapper.e-bigger.e-no-float-label {
    margin-top: $zero-value;
  }

  .e-float-input.e-bigger.e-disabled,
  .e-bigger .e-float-input.e-disabled,
  .e-float-input.e-control-wrapper.e-bigger.e-disabled,
  .e-bigger .e-float-input.e-control-wrapper.e-disabled,
  .e-float-input.e-input-group.e-bigger.e-disabled,
  .e-bigger .e-float-input.e-input-group.e-disabled,
  .e-float-input.e-input-group.e-control-wrapper.e-bigger.e-disabled,
  .e-bigger .e-float-input.e-input-group.e-control-wrapper.e-disabled,
  .e-float-input.e-bigger,
  .e-bigger .e-float-input,
  .e-float-input.e-control-wrapper.e-bigger,
  .e-bigger .e-float-input.e-control-wrapper {
    font-size: $input-bigger-font-size;
  }

  .e-float-input.e-bigger input,
  .e-float-input input.e-bigger,
  .e-bigger .e-float-input input,
  .e-float-input.e-control-wrapper.e-bigger input,
  .e-float-input.e-control-wrapper input.e-bigger,
  .e-bigger .e-float-input.e-control-wrapper input {
    font: inherit;
    line-height: inherit;
    padding: $float-input-bigger-padding;
  }

  .e-float-input.e-small.e-bigger.e-disabled,
  .e-small.e-bigger .e-float-input.e-disabled,
  .e-bigger .e-float-input.e-small.e-disabled,
  .e-small .e-float-input.e-bigger.e-disabled,
  .e-float-input.e-control-wrapper.e-small.e-bigger.e-disabled,
  .e-small.e-bigger .e-float-input.e-control-wrapper.e-disabled,
  .e-bigger .e-float-input.e-control-wrapper.e-small.e-disabled,
  .e-small .e-float-input.e-control-wrapper.e-bigger.e-disabled,
  .e-float-input.e-input-group.e-small.e-bigger.e-disabled,
  .e-small.e-bigger .e-float-input.e-input-group.e-disabled,
  .e-bigger .e-float-input.e-input-group.e-small.e-disabled,
  .e-small .e-float-input.e-input-group.e-bigger.e-disabled,
  .e-float-input.e-input-group.e-control-wrapper.e-small.e-bigger.e-disabled,
  .e-small.e-bigger .e-float-input.e-input-group.e-control-wrapper.e-disabled,
  .e-bigger .e-float-input.e-input-group.e-control-wrapper.e-small.e-disabled,
  .e-small .e-float-input.e-input-group.e-control-wrapper.e-bigger.e-disabled,
  .e-float-input.e-small.e-bigger,
  .e-small.e-bigger .e-float-input,
  .e-bigger .e-float-input.e-small,
  .e-small .e-float-input.e-bigger,
  .e-float-input.e-control-wrapper.e-small.e-bigger,
  .e-small.e-bigger .e-float-input.e-control-wrapper,
  .e-bigger .e-float-input.e-control-wrapper.e-small,
  .e-small .e-float-input.e-control-wrapper.e-bigger {
    font-size: $input-bigger-small-font-size;
  }

  .e-float-input.e-small.e-bigger input,
  .e-float-input.e-small input.e-bigger,
  .e-bigger .e-float-input.e-small input,
  .e-float-input.e-control-wrapper.e-small.e-bigger input,
  .e-float-input.e-control-wrapper.e-small input.e-bigger,
  .e-bigger .e-float-input.e-control-wrapper.e-small input {
    font: inherit;
    line-height: inherit;
    padding: $float-input-bigger-small-padding;
  }

  .e-bigger .e-float-input.e-outline.e-float-icon-left:not(.e-rtl) .e-input-in-wrap input ~ label.e-float-text.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      left: -40px;
      width: auto;
    }
  }

  .e-bigger .e-float-input.e-outline.e-float-icon-left.e-rtl .e-input-in-wrap input ~ label.e-float-text.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      right: -40px;
      width: auto;
    }
  }

  .e-float-input.e-bigger:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger.e-control-wrapper:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-small.e-bigger:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger.e-control-wrapper:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-small.e-bigger:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-small:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    font-weight: normal;
  }

  .e-float-input.e-rtl.e-bigger:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-float-input.e-rtl.e-control-wrapper.e-bigger:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input.e-bigger:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-rtl:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-rtl.e-control-wrapper:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-bigger.e-rtl .e-float-input:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-bigger.e-rtl .e-float-input.e-control-wrapper:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger.e-rtl input:not(:focus):not(:valid) label.e-float-text,
  .e-float-input.e-rtl.e-bigger input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input.e-bigger input:not(:focus):not(:valid) label.e-float-text,
  .e-rtl .e-float-input.e-bigger input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-rtl input:not(:focus):not(:valid) label.e-float-text,
  .e-bigger .e-float-input.e-rtl input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-bigger.e-rtl .e-float-input input:not(:focus):not(:valid) label.e-float-text,
  .e-bigger.e-rtl .e-float-input input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger.e-rtl input:not(:focus):not(:valid) label.e-float-text,
  .e-float-input.e-control-wrapper.e-rtl.e-bigger input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input.e-control-wrapper.e-bigger input:not(:focus):not(:valid) label.e-float-text,
  .e-rtl .e-float-input.e-control-wrapper.e-bigger input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper.e-rtl input:not(:focus):not(:valid) label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-rtl input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-bigger.e-rtl .e-float-input.e-control-wrapper input:not(:focus):not(:valid) label.e-float-text,
  .e-bigger.e-rtl .e-float-input.e-control-wrapper input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-float-input.e-rtl.e-bigger.e-small:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-float-input.e-rtl.e-control-wrapper.e-bigger.e-small:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input.e-bigger.e-small:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input.e-rtl:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input.e-rtl.e-control-wrapper:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-bigger.e-small.e-rtl .e-float-input:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-bigger.e-small.e-rtl .e-float-input.e-control-wrapper:not(.e-input-focus) label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger.e-small.e-rtl input:not(:focus):not(:valid) label.e-float-text,
  .e-float-input.e-rtl.e-bigger.e-small input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input.e-bigger.e-small input:not(:focus):not(:valid) label.e-float-text,
  .e-rtl .e-float-input.e-bigger.e-small input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input.e-rtl input:not(:focus):not(:valid) label.e-float-text,
  .e-bigger.e-small .e-float-input.e-rtl input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-bigger.e-small.e-rtl .e-float-input input:not(:focus):not(:valid) label.e-float-text,
  .e-bigger.e-small.e-rtl .e-float-input input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger.e-small.e-rtl input:not(:focus):not(:valid) label.e-float-text,
  .e-float-input.e-control-wrapper.e-rtl.e-bigger.e-small input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-rtl .e-float-input.e-control-wrapper.e-bigger.e-small input:not(:focus):not(:valid) label.e-float-text,
  .e-rtl .e-float-input.e-control-wrapper.e-bigger.e-small input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input.e-control-wrapper.e-rtl input:not(:focus):not(:valid) label.e-float-text,
  .e-bigger.e-small .e-float-input.e-control-wrapper.e-rtl input:not(:focus):not(:valid) label.e-float-text.e-label-bottom,
  .e-bigger.e-small.e-rtl .e-float-input.e-control-wrapper input:not(:focus):not(:valid) label.e-float-text,
  .e-bigger.e-small.e-rtl .e-float-input.e-control-wrapper input:not(:focus):not(:valid) label.e-float-text.e-label-bottom {
    padding-right: $float-label-padding;
  }

  .e-input-group.e-bigger .e-input,
  .e-input-group.e-control-wrapper.e-bigger .e-input,
  .e-bigger .e-input-group .e-input,
  .e-bigger .e-input-group.e-control-wrapper .e-input,
  .e-float-input.e-bigger .e-input,
  .e-bigger .e-float-input .e-input,
  .e-float-input.e-control-wrapper.e-bigger .e-input,
  .e-bigger .e-float-input.e-control-wrapper .e-input {
    margin-bottom: $zero-value;
  }

  .e-input#{$css}.e-small.e-bigger,
  .e-input-group.e-small.e-bigger,
  .e-input-group.e-control-wrapper.e-small.e-bigger,
  .e-bigger .e-input#{$css}.e-small,
  .e-bigger .e-input-group.e-small,
  .e-bigger .e-input-group.e-control-wrapper.e-small {
    line-height: inherit;
    margin-bottom: $input-small-bigger-margin-bottom;
  }

  .e-input-group.e-bigger .e-input-group-icon,
  .e-input-group .e-input-group-icon.e-bigger,
  .e-input-group.e-control-wrapper.e-bigger .e-input-group-icon,
  .e-input-group.e-control-wrapper .e-input-group-icon.e-bigger,
  .e-bigger .e-input-group .e-input-group-icon,
  .e-bigger .e-input-group.e-control-wrapper .e-input-group-icon {
    @if ($input-skin-name != 'Material3' and $input-skin-name != 'fluent2') {
      margin-bottom: $input-child-bigger-margin-bottom;
      margin-right: $input-child-bigger-margin-right;
      margin-top: $input-child-bigger-margin-top;
    }
    @else {
      margin: $input-child-bigger-margin;
    }
  }

  .e-float-input.e-input-group.e-bigger .e-input-group-icon,
  .e-float-input.e-input-group .e-input-group-icon.e-bigger,
  .e-bigger .e-float-input.e-input-group .e-input-group-icon,
  .e-float-input.e-control-wrapper.e-input-group.e-bigger .e-input-group-icon,
  .e-float-input.e-control-wrapper.e-input-group .e-input-group-icon.e-bigger,
  .e-bigger .e-float-input.e-control-wrapper.e-input-group .e-input-group-icon {
    @if $input-skin-name != 'Material3' and $input-skin-name != 'fluent2' {
      margin-top: $float-input-child-bigger-margin-top;
    }
  }

  .e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon,
  .e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger,
  .e-small .e-bigger.e-input-group .e-input-group-icon,
  .e-small .e-bigger.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-bigger .e-input-group.e-small .e-input-group-icon,
  .e-bigger .e-input-group.e-control-wrapper.e-small .e-input-group-icon {
    @if $input-skin-name != 'fluent2' {
      margin-bottom: $input-child-small-bigger-margin-bottom;
      margin-right: $input-child-small-bigger-margin-right;
      margin-top: $input-child-small-bigger-margin-top;
    }
  }

  .e-float-input.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-float-input.e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-bigger .e-float-input.e-input-group.e-small .e-input-group-icon,
  .e-float-input.e-control-wrapper.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-bigger .e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon {
    @if $input-skin-name != 'Material3' and $input-skin-name != 'fluent2' {
      margin-top: $float-input-child-small-bigger-margin-top;
    }
  }

  .e-input-group.e-bigger .e-input-group-icon:last-child,
  .e-input-group .e-input-group-icon.e-bigger:last-child,
  .e-bigger .e-input-group .e-input-group-icon:last-child,
  .e-input-group.e-small.e-bigger .e-input-group-icon:last-child,
  .e-input-group.e-small .e-input-group-icon.e-bigger:last-child,
  .e-input-group.e-control-wrapper.e-bigger .e-input-group-icon:last-child,
  .e-input-group.e-control-wrapper .e-input-group-icon.e-bigger:last-child,
  .e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon:last-child,
  .e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger:last-child,
  .e-bigger .e-input-group.e-control-wrapper.e-small .e-input-group-icon:last-child,
  .e-bigger .e-input-group.e-small .e-input-group-icon:last-child {
    @if $input-skin-name != 'fluent' {
      margin-right: $zero-value;
    }
    @if $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      margin: $zero-value;
    }
  }

  .e-bigger input.e-input#{$css}::-webkit-input-placeholder,
  input.e-bigger.e-input#{$css}::-webkit-input-placeholder,
  .e-bigger input.e-input#{$css}:-moz-placeholder,
  input.e-bigger.e-input#{$css}:-moz-placeholder,
  .e-bigger input.e-input#{$css}:-ms-input-placeholder,
  input.e-bigger.e-input#{$css}:-ms-input-placeholder,
  .e-bigger input.e-input#{$css}::-moz-placeholder,
  input.e-bigger.e-input#{$css}::-moz-placeholder,
  .e-bigger textarea.e-input#{$css}::-webkit-input-placeholder,
  textarea.e-bigger.e-input#{$css}::-webkit-input-placeholder,
  .e-bigger textarea.e-input#{$css}:-moz-placeholder,
  textarea.e-bigger.e-input#{$css}:-moz-placeholder,
  .e-bigger textarea.e-input#{$css}:-ms-input-placeholder,
  textarea.e-bigger.e-input#{$css}:-ms-input-placeholder,
  .e-bigger textarea.e-input#{$css}::-moz-placeholder,
  textarea.e-bigger.e-input#{$css}::-moz-placeholder,
  .e-bigger textarea.e-input#{$css}::-webkit-textarea-placeholder,
  textarea.e-bigger.e-input#{$css}::-webkit-textarea-placeholder,
  .e-bigger textarea.e-input#{$css}:-moz-placeholder,
  textarea.e-bigger.e-input#{$css}:-moz-placeholder,
  .e-bigger textarea.e-input#{$css}:-ms-textarea-placeholder,
  textarea.e-bigger.e-input#{$css}:-ms-textarea-placeholder,
  .e-bigger textarea.e-input#{$css}::-moz-placeholder,
  textarea.e-bigger.e-input#{$css}::-moz-placeholder {
    font-size: $input-bigger-font-size;
    font-style: $input-font-style;
  }

  .e-bigger input.e-small.e-input#{$css}::-webkit-input-placeholder,
  .e-small input.e-bigger.e-input#{$css}::-webkit-input-placeholder,
  .e-bigger input.e-small.e-input#{$css}:-moz-placeholder,
  .e-small input.e-bigger.e-input#{$css}:-moz-placeholder,
  .e-bigger input.e-small.e-input#{$css}:-ms-input-placeholder,
  .e-small input.e-bigger.e-input#{$css}:-ms-input-placeholder,
  .e-bigger input.e-small.e-input#{$css}::-moz-placeholder,
  .e-small input.e-bigger.e-input#{$css}::-moz-placeholder,
  .e-bigger textarea.e-small.e-input#{$css}::-webkit-input-placeholder,
  .e-small textarea.e-bigger.e-input#{$css}::-webkit-input-placeholder,
  .e-bigger textarea.e-small.e-input#{$css}:-moz-placeholder,
  .e-small textarea.e-bigger.e-input#{$css}:-moz-placeholder,
  .e-bigger intextareaput.e-small.e-input#{$css}:-ms-input-placeholder,
  .e-small textarea.e-bigger.e-input#{$css}:-ms-input-placeholder,
  .e-bigger textarea.e-small.e-input#{$css}::-moz-placeholder,
  .e-small textarea.e-bigger.e-input#{$css}::-moz-placeholder,
  .e-bigger textarea.e-small.e-input#{$css}::-webkit-textarea-placeholder,
  .e-small textarea.e-bigger.e-input#{$css}::-webkit-textarea-placeholder,
  .e-bigger textarea.e-small.e-input#{$css}:-moz-placeholder,
  .e-small textarea.e-bigger.e-input#{$css}:-moz-placeholder,
  .e-bigger textarea.e-small.e-input#{$css}:-ms-input-placeholder,
  .e-small textarea.e-bigger.e-input#{$css}:-ms-input-placeholder,
  .e-bigger textarea.e-small.e-input#{$css}::-moz-placeholder,
  .e-small textarea.e-bigger.e-input#{$css}::-moz-placeholder {
    font-size: $input-bigger-small-font-size;
    font-style: $input-font-style;
  }

  input.e-input.e-bigger#{$css},
  .e-bigger input.e-input#{$css},
  .e-input-group.e-bigger input,
  .e-input-group.e-bigger input.e-input,
  .e-input-group.e-control-wrapper.e-bigger input,
  .e-input-group.e-control-wrapper.e-bigger input.e-input,
  .e-bigger .e-input-group input,
  .e-bigger .e-input-group input.e-input,
  .e-bigger .e-input-group.e-control-wrapper input,
  .e-bigger .e-input-group.e-control-wrapper input.e-input,
  .e-float-input.e-bigger input,
  .e-float-input.e-bigger input.e-input,
  .e-bigger .e-float-input input,
  .e-bigger .e-float-input input.e-input,
  .e-float-input.e-control-wrapper.e-bigger input,
  .e-float-input.e-control-wrapper.e-bigger input.e-input,
  .e-bigger .e-float-input.e-control-wrapper input,
  .e-bigger .e-float-input.e-control-wrapper input.e-input {
    @include input-sizing;
    @include input-height ($input-bigger-height);
  }

  .e-float-input.e-bigger:not(.e-input-group) input,
  .e-float-input.e-bigger:not(.e-input-group) input.e-input,
  .e-bigger .e-float-input:not(.e-input-group) input,
  .e-bigger .e-float-input:not(.e-input-group) input.e-input,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-input-group) input,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-input-group) input.e-input,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-input-group) input,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-input-group) input.e-input {
    @include input-sizing;
    @include input-height ($float-input-bigger-height);
  }

  input.e-input.e-small.e-bigger#{$css},
  .e-input-group.e-bigger.e-small input,
  .e-input-group.e-bigger.e-small input.e-input,
  .e-bigger.e-small .e-input-group input,
  .e-bigger.e-small .e-input-group input.e-input,
  .e-input-group.e-control-wrapper.e-bigger.e-small input,
  .e-input-group.e-control-wrapper.e-bigger.e-small input.e-input,
  .e-bigger.e-small .e-input-group.e-control-wrapper input,
  .e-bigger.e-small .e-input-group.e-control-wrapper input.e-input,
  .e-float-input.e-bigger.e-small input,
  .e-float-input.e-bigger.e-small input.e-input,
  .e-bigger.e-small .e-float-input input,
  .e-bigger.e-small .e-float-input input.e-input,
  .e-float-input.e-control-wrapper.e-bigger.e-small input,
  .e-float-input.e-control-wrapper.e-bigger.e-small input.e-input,
  .e-bigger.e-small .e-float-input.e-control-wrapper input,
  .e-bigger.e-small .e-float-input.e-control-wrapper input.e-input {
    @include input-sizing;
    @include input-height ($input-bigger-small-height);
  }

  .e-float-input.e-bigger.e-small:not(.e-input-group) input,
  .e-float-input.e-bigger.e-small:not(.e-input-group) input.e-input,
  .e-bigger.e-small .e-float-input:not(.e-input-group) input,
  .e-bigger.e-small .e-float-input:not(.e-input-group) input.e-input,
  .e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-input-group) input,
  .e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-input-group) input.e-input,
  .e-bigger.e-small .e-float-input.e-control-wrapper:not(.e-input-group) input,
  .e-bigger.e-small .e-float-input.e-control-wrapper:not(.e-input-group) input.e-input {
    @include input-sizing;
    @include input-height ($float-input-bigger-small-height);
  }

  .e-input-group textarea.e-input.e-bigger,
  .e-input-group.e-control-wrapper textarea.e-input.e-bigger,
  textarea.e-input.e-bigger#{$css},
  .e-input-group.e-bigger textarea,
  .e-input-group.e-bigger textarea.e-input,
  .e-bigger .e-input-group textarea,
  .e-bigger .e-input-group textarea.e-input,
  .e-input-group.e-control-wrapper.e-bigger textarea,
  .e-input-group.e-control-wrapper.e-bigger textarea.e-input,
  .e-bigger .e-input-group.e-control-wrapper textarea,
  .e-bigger .e-input-group.e-control-wrapper textarea.e-input,
  .e-float-input.e-bigger textarea,
  .e-float-input.e-bigger textarea.e-input,
  .e-bigger .e-float-input textarea,
  .e-bigger .e-float-input textarea.e-input,
  .e-float-input.e-control-wrapper.e-bigger textarea,
  .e-float-input.e-control-wrapper.e-bigger textarea.e-input,
  .e-bigger .e-float-input.e-control-wrapper textarea,
  .e-bigger .e-float-input.e-control-wrapper textarea.e-input  {
    @include input-sizing;
    @include input-height ($textarea-bigger-height);
  }

  textarea.e-input.e-small.e-bigger#{$css},
  .e-input-group.e-bigger.e-small textarea,
  .e-input-group.e-bigger.e-small textarea.e-input,
  .e-bigger.e-small .e-input-group textarea,
  .e-bigger.e-small .e-input-group textarea.e-input,
  .e-input-group.e-control-wrapper.e-bigger.e-small textarea,
  .e-input-group.e-control-wrapper.e-bigger.e-small textarea.e-input,
  .e-bigger.e-small .e-input-group.e-control-wrapper textarea,
  .e-bigger.e-small .e-input-group.e-control-wrapper textarea.e-input,
  .e-float-input.e-bigger.e-small textarea,
  .e-float-input.e-bigger.e-small textarea.e-input,
  .e-bigger.e-small .e-float-input textarea,
  .e-bigger.e-small .e-float-input textarea.e-input,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea.e-input,
  .e-bigger.e-small .e-float-input.e-control-wrapper textarea,
  .e-bigger.e-small .e-float-input.e-control-wrapper textarea.e-input {
    @include input-sizing;
    @include input-height ($textarea-bigger-small-height);
  }

  input.e-input.e-bigger#{$css},
  .e-input-group input.e-input.e-bigger,
  .e-input-group input.e-input.e-bigger,
  .e-input-group.e-control-wrapper input.e-input.e-bigger,
  .e-input-group.e-control-wrapper input.e-input.e-bigger,
  .e-input-group.e-bigger .e-input,
  .e-input-group.e-control-wrapper.e-bigger .e-input,
  .e-bigger input.e-input#{$css},
  .e-bigger .e-input-group .e-input,
  .e-bigger .e-input-group.e-control-wrapper .e-input,
  .e-float-input.e-bigger input,
  .e-float-input input.e-bigger,
  .e-bigger .e-float-input input,
  .e-float-input.e-control-wrapper.e-bigger input,
  .e-float-input.e-control-wrapper input.e-bigger,
  .e-bigger .e-float-input.e-control-wrapper input {
    text-indent: $input-bigger-text-indent;
  }

  input.e-input.e-bigger#{$css},
  .e-bigger input.e-input#{$css},
  .e-input-group.e-bigger input.e-input,
  .e-input-group.e-control-wrapper.e-bigger input.e-input,
  .e-float-input.e-control-wrapper.e-bigger input,
  .e-float-input.e-control-wrapper input.e-bigger,
  .e-float-input.e-bigger input,
  .e-float-input input.e-bigger,
  .e-input-group input.e-input.e-bigger,
  .e-input-group.e-control-wrapper input.e-input.e-bigger,
  .e-bigger .e-float-input.e-control-wrapper input,
  .e-bigger .e-float-input input,
  .e-bigger .e-input-group.e-control-wrapper input.e-input,
  .e-bigger .e-input-group input.e-input,
  .e-input-group.e-bigger input.e-input:focus,
  .e-input-group.e-control-wrapper.e-bigger input.e-input:focus,
  .e-float-input.e-control-wrapper.e-bigger input:focus,
  .e-float-input.e-bigger input:focus,
  .e-bigger .e-input-group input.e-input:focus,
  .e-bigger .e-input-group.e-control-wrapper input.e-input:focus,
  .e-bigger .e-float-input.e-control-wrapper input:focus,
  .e-bigger .e-float-input input:focus,
  .e-input-group.e-bigger.e-input-focus input.e-input,
  .e-input-group.e-control-wrapper.e-bigger.e-input-focus input.e-input,
  .e-bigger .e-input-group.e-input-focus input.e-input,
  .e-bigger .e-input-group.e-control-wrapper.e-input-focus input.e-input,
  .e-float-input.e-control-wrapper.e-bigger.e-input-focus input,
  .e-float-input.e-bigger.e-input-focus input,
  .e-bigger .e-float-input.e-control-wrapper.e-input-focus input,
  .e-bigger .e-float-input.e-input-focus input {
    @if $input-skin-name == 'fluent2' {
      padding: 2px 4px;
    }
    @else {
      padding-left: $input-bigger-text-indent;
    }
    text-indent: 0;
  }

  .e-rtl input.e-input.e-bigger#{$css},
  input.e-input.e-bigger.e-rtl#{$css},
  .e-bigger.e-rtl input.e-input#{$css},
  .e-bigger input.e-input.e-rtl#{$css},
  .e-float-input.e-control-wrapper.e-bigger.e-rtl input,
  .e-float-input.e-bigger.e-rtl input,
  .e-input-group.e-bigger.e-rtl input.e-input,
  .e-input-group.e-control-wrapper.e-bigger.e-rtl input.e-input,
  .e-rtl .e-float-input.e-control-wrapper.e-bigger input,
  .e-rtl .e-float-input.e-bigger input,
  .e-rtl .e-input-group.e-bigger input.e-input,
  .e-rtl .e-input-group.e-control-wrapper.e-bigger input.e-input,
  .e-float-input.e-control-wrapper.e-rtl input.e-bigger,
  .e-float-input.e-rtl input.e-bigger,
  .e-input-group.e-rtl input.e-input.e-bigger,
  .e-input-group.e-control-wrapper.e-rtl input.e-input.e-bigger,
  .e-rtl .e-float-input.e-control-wrapper input.e-bigger,
  .e-rtl .e-float-input input.e-bigger,
  .e-rtl .e-input-group input.e-input.e-bigger,
  .e-rtl .e-input-group.e-control-wrapper input.e-input.e-bigger,
  .e-bigger .e-float-input.e-control-wrapper.e-rtl input,
  .e-bigger .e-float-input.e-rtl input,
  .e-bigger .e-input-group.e-rtl input.e-input,
  .e-bigger .e-input-group.e-control-wrapper.e-rtl input.e-input,
  .e-bigger.e-rtl .e-float-input.e-control-wrapper input,
  .e-bigger.e-rtl .e-float-input input,
  .e-bigger.e-rtl .e-input-group input.e-input,
  .e-bigger.e-rtl .e-input-group.e-control-wrapper input.e-input,
  .e-bigger.e-rtl .e-input-group input.e-input:focus,
  .e-bigger.e-rtl .e-input-group.e-control-wrapper input.e-input:focus,
  .e-bigger.e-rtl .e-float-input.e-control-wrapper input:focus,
  .e-bigger.e-rtl .e-float-input input:focus,
  .e-bigger .e-input-group.e-rtl input.e-input:focus,
  .e-bigger .e-input-group.e-control-wrapper.e-rtl input.e-input:focus,
  .e-bigger .e-float-input.e-rtl input:focus,
  .e-bigger .e-float-input.e-control-wrapper.e-rtl input:focus,
  .e-bigger.e-rtl .e-input-group.e-input-focus input.e-input,
  .e-bigger.e-rtl .e-input-group.e-control-wrapper.e-input-focus input.e-input,
  .e-bigger .e-input-group.e-rtl.e-input-focus input.e-input,
  .e-bigger .e-input-group.e-control-wrapper.e-rtl.e-input-focus input.e-input,
  .e-bigger.e-rtl .e-float-input.e-control-wrapper.e-input-focus input,
  .e-bigger.e-rtl .e-float-input.e-input-focus input,
  .e-bigger .e-float-input.e-rtl.e-input-focus input,
  .e-bigger .e-float-input.e-control-wrapper.e-rtl.e-input-focus input {
    padding-left: 0;
    padding-right: $input-bigger-text-indent;
    text-indent: 0;
  }

  textarea.e-input.e-bigger#{$css},
  .e-bigger textarea.e-input#{$css},
  .e-input-group.e-bigger textarea.e-input,
  .e-input-group.e-control-wrapper.e-bigger textarea.e-input,
  .e-float-input.e-control-wrapper.e-bigger textarea,
  .e-float-input.e-control-wrapper textarea.e-bigger,
  .e-float-input.e-bigger textarea,
  .e-float-input textarea.e-bigger,
  .e-input-group textarea.e-input.e-bigger,
  .e-input-group.e-control-wrapper textarea.e-input.e-bigger,
  .e-bigger .e-float-input.e-control-wrapper textarea,
  .e-bigger .e-float-input textarea,
  .e-bigger .e-input-group textarea.e-input,
  .e-bigger .e-input-group.e-control-wrapper textarea.e-input,
  .e-input-group.e-bigger textarea.e-input:focus,
  .e-input-group.e-control-wrapper.e-bigger textarea.e-input:focus,
  .e-float-input.e-control-wrapper.e-bigger textarea:focus,
  .e-float-input.e-bigger textarea:focus,
  .e-bigger .e-input-group textarea.e-input:focus,
  .e-bigger .e-input-group.e-control-wrapper textarea.e-input:focus,
  .e-bigger .e-float-input.e-control-wrapper textarea:focus,
  .e-bigger .e-float-input textarea:focus {
    @if $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $textarea-bigger-padding;
    }
    @else {
      padding-left: $input-bigger-text-indent;
    }
    text-indent: 0;
  }

  .e-rtl textarea.e-input.e-bigger#{$css},
  textarea.e-input.e-bigger.e-rtl#{$css},
  .e-bigger.e-rtl textarea.e-input#{$css},
  .e-bigger textarea.e-input.e-rtl#{$css},
  .e-float-input.e-control-wrapper:not(.e-outline).e-bigger.e-rtl textarea,
  .e-float-input:not(.e-outline).e-bigger.e-rtl textarea,
  .e-input-group:not(.e-outline).e-bigger.e-rtl textarea.e-input,
  .e-input-group:not(.e-outline).e-control-wrapper.e-bigger.e-rtl textarea.e-input,
  .e-rtl .e-float-input:not(.e-outline).e-control-wrapper.e-bigger textarea,
  .e-rtl .e-float-input:not(.e-outline).e-bigger textarea,
  .e-rtl .e-input-group:not(.e-outline).e-bigger textarea.e-input,
  .e-rtl .e-input-group:not(.e-outline).e-control-wrapper.e-bigger textarea.e-input,
  .e-float-input:not(.e-outline).e-rtl textarea.e-bigger,
  .e-float-input:not(.e-outline).e-control-wrapper.e-rtl textarea.e-bigger,
  .e-input-group:not(.e-outline).e-rtl textarea.e-input.e-bigger,
  .e-input-group:not(.e-outline).e-control-wrapper.e-rtl textarea.e-input.e-bigger,
  .e-rtl .e-float-input:not(.e-outline) textarea.e-bigger,
  .e-rtl .e-float-input:not(.e-outline).e-control-wrapper textarea.e-bigger,
  .e-rtl .e-input-group:not(.e-outline) textarea.e-input.e-bigger,
  .e-rtl .e-input-group:not(.e-outline).e-control-wrapper textarea.e-input.e-bigger,
  .e-bigger .e-float-input:not(.e-outline).e-rtl textarea,
  .e-bigger .e-float-input:not(.e-outline).e-control-wrapper.e-rtl textarea,
  .e-bigger .e-input-group:not(.e-outline).e-rtl textarea.e-input,
  .e-bigger .e-input-group:not(.e-outline).e-control-wrapper.e-rtl textarea.e-input,
  .e-bigger.e-rtl .e-float-input:not(.e-outline) textarea,
  .e-bigger.e-rtl .e-float-input:not(.e-outline).e-control-wrapper textarea,
  .e-bigger.e-rtl .e-input-group:not(.e-outline) textarea.e-input,
  .e-bigger.e-rtl .e-input-group:not(.e-outline).e-control-wrapper textarea.e-input,
  .e-bigger.e-rtl .e-input-group:not(.e-outline) textarea.e-input:focus,
  .e-bigger.e-rtl .e-input-group:not(.e-outline).e-control-wrapper textarea.e-input:focus,
  .e-bigger.e-rtl .e-float-input:not(.e-outline) textarea:focus,
  .e-bigger.e-rtl .e-float-input:not(.e-outline).e-control-wrapper textarea:focus,
  .e-bigger .e-input-group:not(.e-outline).e-rtl textarea.e-input:focus,
  .e-bigger .e-input-group:not(.e-outline).e-control-wrapper.e-rtl textarea.e-input:focus,
  .e-bigger .e-float-input:not(.e-outline).e-rtl textarea:focus,
  .e-bigger .e-float-input:not(.e-outline).e-control-wrapper.e-rtl textarea:focus {
    padding-right: $input-bigger-text-indent;
    text-indent: 0;
  }

  input.e-input.e-bigger.e-small#{$css},
  .e-bigger input.e-input.e-small#{$css},
  .e-input-group.e-small.e-bigger input.e-input,
  .e-input-group.e-control-wrapper.e-small.e-bigger input.e-input,
  .e-input-group.e-small input.e-input.e-bigger,
  .e-input-group.e-control-wrapper.e-small input.e-input.e-bigger,
  .e-bigger .e-input-group.e-small input.e-input,
  .e-bigger .e-input-group.e-control-wrapper.e-small input.e-input,
  .e-float-input.e-small.e-bigger input,
  .e-float-input.e-small input.e-bigger,
  .e-bigger .e-float-input.e-small input,
  .e-float-input.e-control-wrapper.e-small.e-bigger input,
  .e-float-input.e-control-wrapper.e-small input.e-bigger,
  .e-bigger .e-float-input.e-control-wrapper.e-small input,
  .e-bigger .e-input-group.e-small input.e-input:focus,
  .e-bigger .e-input-group.e-control-wrapper.e-small input.e-input:focus,
  .e-bigger .e-float-input.e-control-wrapper.e-small input:focus,
  .e-bigger .e-float-input.e-small input:focus,
  .e-small .e-input-group.e-bigger input.e-input:focus,
  .e-small .e-input-group.e-control-wrapper.e-bigger input.e-input:focus,
  .e-small .e-float-input.e-control-wrapper.e-bigger input:focus,
  .e-small .e-float-input.e-bigger input:focus,
  .e-input-group.e-bigger.e-small input.e-input:focus,
  .e-input-group.e-control-wrapper.e-bigger.e-small input.e-input:focus,
  .e-float-input.e-control-wrapper.e-bigger.e-small input:focus,
  .e-float-input.e-bigger.e-small input:focus,
  .e-bigger .e-input-group.e-small.e-input-focus input.e-input,
  .e-bigger .e-input-group.e-control-wrapper.e-small.e-input-focus input.e-input,
  .e-small .e-input-group.e-bigger.e-input-focus input.e-input,
  .e-small .e-input-group.e-control-wrapper.e-bigger.e-input-focus input.e-input,
  .e-input-group.e-bigger.e-small.e-input-focus input.e-input,
  .e-input-group.e-control-wrapper.e-bigger.e-small.e-input-focus input.e-input,
  .e-bigger .e-float-input.e-control-wrapper.e-small.e-input-focus input,
  .e-bigger .e-float-input.e-small.e-input-focus input,
  .e-small .e-float-input.e-control-wrapper.e-bigger.e-input-focus input,
  .e-small .e-float-input.e-bigger.e-input-focus input,
  .e-float-input.e-control-wrapper.e-bigger.e-small.e-input-focus input,
  .e-float-input.e-bigger.e-small.e-input-focus input,
  textarea.e-input.e-bigger.e-small#{$css},
  .e-bigger textarea.e-input.e-small#{$css},
  .e-input-group.e-small.e-bigger textarea.e-input,
  .e-input-group.e-control-wrapper.e-small.e-bigger textarea.e-input,
  .e-input-group.e-small textarea.e-input.e-bigger,
  .e-input-group.e-control-wrapper.e-small textarea.e-input.e-bigger,
  .e-bigger .e-input-group.e-small textarea.e-input,
  .e-bigger .e-input-group.e-control-wrapper.e-small textarea.e-input,
  .e-float-input.e-small.e-bigger textarea,
  .e-float-input.e-small textarea.e-bigger,
  .e-bigger .e-float-input.e-small textarea,
  .e-float-input.e-control-wrapper.e-small.e-bigger textarea,
  .e-float-input.e-control-wrapper.e-small textarea.e-bigger,
  .e-bigger .e-float-input.e-control-wrapper.e-small textarea,
  .e-bigger .e-input-group.e-small textarea.e-input:focus,
  .e-bigger .e-input-group.e-control-wrapper.e-small textarea.e-input:focus,
  .e-bigger .e-float-input.e-control-wrapper.e-small textarea:focus,
  .e-bigger .e-float-input.e-small textarea:focus,
  .e-small .e-input-group.e-bigger textarea.e-input:focus,
  .e-small .e-input-group.e-control-wrapper.e-bigger textarea.e-input:focus,
  .e-small .e-float-input.e-control-wrapper.e-bigger textarea:focus,
  .e-small .e-float-input.e-bigger textarea:focus,
  .e-input-group.e-bigger.e-small textarea.e-input:focus,
  .e-input-group.e-control-wrapper.e-bigger.e-small textarea.e-input:focus,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea:focus,
  .e-float-input.e-bigger.e-small textarea:focus {
    padding-left: $input-small-bigger-text-indent;
    text-indent: 0;
  }

  .e-bigger input.e-input.e-small.e-rtl#{$css},
  .e-input-group.e-small.e-bigger.e-rtl input.e-input,
  .e-input-group.e-control-wrapper.e-small.e-bigger.e-rtl input.e-input,
  .e-input-group.e-small.e-rtl input.e-input.e-bigger,
  .e-input-group.e-control-wrapper.e-small.e-rtl input.e-input.e-bigger,
  .e-bigger .e-input-group.e-small.e-rtl input.e-input,
  .e-bigger .e-input-group.e-control-wrapper.e-small.e-rtl input.e-input,
  .e-float-input.e-small.e-bigger.e-rtl input,
  .e-float-input.e-small.e-rtl input.e-bigger,
  .e-bigger .e-float-input.e-small.e-rtl input,
  .e-float-input.e-control-wrapper.e-small.e-bigger.e-rtl input,
  .e-float-input.e-control-wrapper.e-small.e-rtl input.e-bigger,
  .e-bigger .e-float-input.e-control-wrapper.e-small.e-rtl input,
  .e-bigger.e-rtl input.e-input.e-small,
  .e-rtl .e-input-group.e-small.e-bigger input.e-input,
  .e-rtl .e-input-group.e-control-wrapper.e-small.e-bigger input.e-input,
  .e-rtl .e-input-group.e-small input.e-input.e-bigger,
  .e-rtl .e-input-group.e-control-wrapper.e-small input.e-input.e-bigger,
  .e-bigger.e-rtl .e-input-group.e-small input.e-input,
  .e-bigger.e-rtl .e-input-group.e-control-wrapper.e-small input.e-input,
  .e-rtl .e-float-input.e-control-wrapper.e-small.e-bigger input,
  .e-rtl .e-float-input.e-control-wrapper.e-small input.e-bigger,
  .e-bigger.e-rtl .e-float-input.e-control-wrapper.e-small input,
  .e-rtl .e-float-input.e-small.e-bigger input,
  .e-rtl .e-float-input.e-small input.e-bigger,
  .e-bigger.e-rtl .e-float-input.e-small input,
  .e-bigger .e-input-group.e-small.e-rtl input.e-input:focus,
  .e-bigger .e-input-group.e-control-wrapper.e-small.e-rtl input.e-input:focus,
  .e-bigger .e-float-input.e-control-wrapper.e-small.e-rtl input:focus,
  .e-bigger .e-float-input.e-small.e-rtl input:focus,
  .e-small .e-input-group.e-bigger.e-rtl input.e-input:focus,
  .e-small .e-input-group.e-control-wrapper.e-bigger.e-rtl input.e-input:focus,
  .e-small .e-float-input.e-control-wrapper.e-bigger.e-rtl input:focus,
  .e-small .e-float-input.e-bigger.e-rtl input:focus,
  .e-input-group.e-bigger.e-small.e-rtl input.e-input:focus,
  .e-input-group.e-control-wrapper.e-bigger.e-small.e-rtl input.e-input:focus,
  .e-float-input.e-control-wrapper.e-bigger.e-small.e-rtl input:focus,
  .e-float-input.e-bigger.e-small.e-rtl input:focus,
  .e-bigger.e-rtl .e-input-group.e-small input.e-input:focus,
  .e-bigger.e-rtl .e-input-group.e-control-wrapper.e-small input.e-input:focus,
  .e-bigger.e-rtl .e-float-input.e-control-wrapper.e-small input:focus,
  .e-bigger.e-rtl .e-float-input.e-small input:focus,
  .e-small.e-rtl .e-input-group.e-bigger input.e-input:focus,
  .e-small.e-rtl .e-input-group.e-control-wrapper.e-bigger input.e-input:focus,
  .e-small.e-rtl .e-float-input.e-control-wrapper.e-bigger input:focus,
  .e-small.e-rtl .e-float-input.e-bigger input:focus,
  .e-rtl .e-input-group.e-bigger.e-small input.e-input:focus,
  .e-rtl .e-input-group.e-control-wrapper.e-bigger.e-small input.e-input:focus,
  .e-rtl .e-float-input.e-control-wrapper.e-bigger.e-small input:focus,
  .e-rtl .e-float-input.e-bigger.e-small input:focus,
  .e-bigger .e-float-input.e-control-wrapper.e-small.e-rtl.e-input-focus input,
  .e-bigger .e-float-input.e-small.e-rtl.e-input-focus input,
  .e-small .e-float-input.e-control-wrapper.e-bigger.e-rtl.e-input-focus input,
  .e-small .e-float-input.e-bigger.e-rtl.e-input-focus input,
  .e-float-input.e-control-wrapper.e-bigger.e-small.e-rtl.e-input-focus input,
  .e-float-input.e-bigger.e-small.e-rtl.e-input-focus input,
  .e-bigger.e-rtl .e-float-input.e-control-wrapper.e-small.e-input-focus input,
  .e-bigger.e-rtl .e-float-input.e-small.e-input-focus input,
  .e-small.e-rtl .e-float-input.e-control-wrapper.e-bigger.e-input-focus input,
  .e-small.e-rtl .e-float-input.e-bigger.e-input-focus input,
  .e-rtl .e-float-input.e-control-wrapper.e-bigger.e-small.e-input-focus input,
  .e-rtl .e-float-input.e-bigger.e-small.e-input-focus input,
  .e-bigger .e-input-group.e-small.e-rtl.e-input-focus input.e-input,
  .e-bigger .e-input-group.e-control-wrapper.e-small.e-rtl.e-input-focus input.e-input,
  .e-small .e-input-group.e-bigger.e-rtl.e-input-focus input.e-input,
  .e-small .e-input-group.e-control-wrapper.e-bigger.e-rtl.e-input-focus input.e-input,
  .e-input-group.e-bigger.e-small.e-rtl.e-input-focus input.e-input,
  .e-input-group.e-control-wrapper.e-bigger.e-small.e-rtl.e-input-focus input.e-input,
  .e-bigger.e-rtl .e-input-group.e-small.e-input-focus input.e-input,
  .e-bigger.e-rtl .e-input-group.e-control-wrapper.e-small.e-input-focus input.e-input,
  .e-small.e-rtl .e-input-group.e-bigger.e-input-focus input.e-input,
  .e-small.e-rtl .e-input-group.e-control-wrapper.e-bigger.e-input-focus input.e-input,
  .e-rtl .e-input-group.e-bigger.e-small.e-input-focus input.e-input,
  .e-rtl .e-input-group.e-control-wrapper.e-bigger.e-small.e-input-focus input.e-input {
    padding-left: 0;
    padding-right: $input-small-bigger-text-indent;
    text-indent: 0;
  }

  .e-bigger .e-input-group.e-small textarea.e-input:focus,
  .e-bigger .e-input-group.e-control-wrapper.e-small textarea.e-input:focus,
  .e-bigger .e-float-input.e-control-wrapper.e-small textarea:focus,
  .e-bigger .e-float-input.e-small textarea:focus,
  .e-small .e-input-group.e-bigger textarea.e-input:focus,
  .e-small .e-input-group.e-control-wrapper.e-bigger textarea.e-input:focus,
  .e-small .e-float-input.e-control-wrapper.e-bigger textarea:focus,
  .e-small .e-float-input.e-bigger textarea:focus,
  .e-input-group.e-bigger.e-small textarea.e-input:focus,
  .e-input-group.e-bigger textarea.e-input:focus,
  .e-input-group.e-small textarea.e-input:focus,
  .e-input-group textarea.e-input:focus,
  .e-input-group.e-control-wrapper.e-bigger.e-small textarea.e-input:focus,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea:focus,
  .e-float-input.e-bigger.e-small textarea:focus {
    @if ($input-skin-name == 'tailwind' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      box-shadow: $input-group-focus-box-shadow;
    }
  }

  .e-bigger textarea.e-input.e-small.e-rtl#{$css},
  .e-input-group:not(.e-outline).e-small.e-bigger.e-rtl textarea.e-input,
  .e-input-group:not(.e-outline).e-control-wrapper.e-small.e-bigger.e-rtl textarea.e-input,
  .e-input-group:not(.e-outline).e-small.e-rtl textarea.e-input.e-bigger,
  .e-input-group:not(.e-outline).e-control-wrapper.e-small.e-rtl textarea.e-input.e-bigger,
  .e-bigger .e-input-group:not(.e-outline).e-small.e-rtl textarea.e-input,
  .e-bigger .e-input-group:not(.e-outline).e-control-wrapper.e-small.e-rtl textarea.e-input,
  .e-float-input:not(.e-outline).e-small.e-bigger.e-rtl textarea,
  .e-float-input:not(.e-outline).e-small.e-rtl textarea.e-bigger,
  .e-bigger .e-float-input:not(.e-outline).e-small.e-rtl textarea,
  .e-float-input:not(.e-outline).e-control-wrapper.e-small.e-bigger.e-rtl textarea,
  .e-float-input:not(.e-outline).e-control-wrapper.e-small.e-rtl textarea.e-bigger,
  .e-bigger .e-float-input:not(.e-outline).e-control-wrapper.e-small.e-rtl textarea,
  .e-bigger.e-rtl textarea.e-input.e-small#{$css},
  .e-rtl .e-input-group:not(.e-outline).e-small.e-bigger textarea.e-input,
  .e-rtl .e-input-group:not(.e-outline).e-control-wrapper.e-small.e-bigger textarea.e-input,
  .e-rtl .e-input-group:not(.e-outline).e-small textarea.e-input.e-bigger,
  .e-rtl .e-input-group:not(.e-outline).e-control-wrapper.e-small textarea.e-input.e-bigger,
  .e-bigger.e-rtl .e-input-group:not(.e-outline).e-small textarea.e-input,
  .e-bigger.e-rtl .e-input-group:not(.e-outline).e-control-wrapper.e-small textarea.e-input,
  .e-rtl .e-float-input:not(.e-outline).e-control-wrapper.e-small.e-bigger textarea,
  .e-rtl .e-float-input:not(.e-outline).e-small textarea.e-bigger,
  .e-bigger.e-rtl .e-float-input:not(.e-outline).e-control-wrapper.e-small textarea,
  .e-rtl .e-float-input:not(.e-outline).e-control-wrapper.e-small.e-bigger textarea,
  .e-rtl .e-float-input:not(.e-outline).e-small textarea.e-bigger,
  .e-bigger.e-rtl .e-float-input:not(.e-outline).e-small textarea,
  .e-bigger.e-rtl .e-float-input:not(.e-outline).e-small.e-control-wrapper textarea,
  .e-bigger .e-input-group:not(.e-outline).e-small.e-rtl textarea.e-input:focus,
  .e-bigger .e-input-group:not(.e-outline).e-control-wrapper.e-small.e-rtl textarea.e-input:focus,
  .e-bigger .e-float-input:not(.e-outline).e-control-wrapper.e-small.e-rtl textarea:focus,
  .e-bigger .e-float-input:not(.e-outline).e-small.e-rtl textarea:focus,
  .e-small .e-input-group:not(.e-outline).e-bigger.e-rtl textarea.e-input:focus,
  .e-small .e-input-group:not(.e-outline).e-control-wrapper.e-bigger.e-rtl textarea.e-input:focus,
  .e-small .e-float-input:not(.e-outline).e-control-wrapper.e-bigger.e-rtl textarea:focus,
  .e-small .e-float-input:not(.e-outline).e-bigger.e-rtl textarea:focus,
  .e-input-group:not(.e-outline).e-bigger.e-small.e-rtl textarea.e-input:focus,
  .e-input-group:not(.e-outline).e-control-wrapper.e-bigger.e-small.e-rtl textarea.e-input:focus,
  .e-float-input:not(.e-outline).e-control-wrapper.e-bigger.e-small.e-rtl textarea:focus,
  .e-float-input:not(.e-outline).e-bigger.e-small.e-rtl textarea:focus,
  .e-bigger.e-rtl .e-input-group:not(.e-outline).e-small textarea.e-input:focus,
  .e-bigger.e-rtl .e-input-group:not(.e-outline).e-control-wrapper.e-small textarea.e-input:focus,
  .e-bigger.e-rtl .e-float-input:not(.e-outline).e-control-wrapper.e-small textarea:focus,
  .e-bigger.e-rtl .e-float-input:not(.e-outline).e-small textarea:focus,
  .e-small.e-rtl .e-input-group:not(.e-outline).e-bigger textarea.e-input:focus,
  .e-small.e-rtl .e-input-group:not(.e-outline).e-control-wrapper.e-bigger textarea.e-input:focus,
  .e-small.e-rtl .e-float-input:not(.e-outline).e-control-wrapper.e-bigger textarea:focus,
  .e-small.e-rtl .e-float-input:not(.e-outline).e-bigger textarea:focus,
  .e-rtl .e-input-group:not(.e-outline).e-bigger.e-small textarea.e-input:focus,
  .e-rtl .e-input-group:not(.e-outline).e-control-wrapper.e-bigger.e-small textarea.e-input:focus,
  .e-rtl .e-float-input:not(.e-outline).e-control-wrapper.e-bigger.e-small textarea:focus,
  .e-rtl .e-float-input:not(.e-outline).e-bigger.e-small textarea:focus {
    padding-right: $input-small-bigger-text-indent;
    text-indent: 0;
  }

  .e-float-input.e-small.e-bigger .e-clear-icon,
  .e-float-input.e-control-wrapper.e-small.e-bigger .e-clear-icon,
  .e-input-group.e-small.e-bigger .e-clear-icon,
  .e-input-group.e-control-wrapper.e-small.e-bigger .e-clear-icon,
  .e-small .e-input-group.e-bigger input:first-child ~ .e-clear-icon,
  .e-small .e-input-group.e-control-wrapper.e-bigger input:first-child ~ .e-clear-icon,
  .e-bigger .e-input-group.e-small input:first-child ~ .e-clear-icon,
  .e-bigger .e-input-group.e-control-wrapper.e-small input:first-child ~ .e-clear-icon,
  .e-small .e-float-input.e-control-wrapper.e-bigger input:first-child ~ .e-clear-icon,
  .e-small .e-float-input.e-bigger input:first-child ~ .e-clear-icon,
  .e-bigger .e-float-input.e-control-wrapper.e-small input:first-child ~ .e-clear-icon,
  .e-bigger .e-float-input.e-small input:first-child ~ .e-clear-icon {
    padding-bottom: $zero-value;
  }

  .e-float-input.e-bigger .e-clear-icon::before,
  .e-float-input.e-control-wrapper.e-bigger .e-clear-icon::before,
  .e-input-group.e-bigger .e-clear-icon::before,
  .e-input-group.e-control-wrapper.e-bigger .e-clear-icon::before,
  .e-float-input.e-control-wrapper input.e-bigger:first-child ~ .e-clear-icon::before,
  .e-bigger .e-float-input.e-control-wrapper .e-clear-icon::before,
  .e-float-input input.e-bigger:first-child ~ .e-clear-icon::before,
  .e-bigger .e-float-input .e-clear-icon::before,
  .e-bigger .e-input-group .e-clear-icon::before,
  .e-bigger .e-input-group.e-control-wrapper .e-clear-icon::before {
    font-size: $input-bigger-clear-icon;
  }

  .e-float-input.e-small.e-bigger .e-clear-icon::before,
  .e-float-input.e-control-wrapper.e-small.e-bigger .e-clear-icon::before,
  .e-input-group.e-small.e-bigger .e-clear-icon::before,
  .e-input-group.e-control-wrapper.e-small.e-bigger .e-clear-icon::before,
  .e-small.e-bigger .e-float-input .e-clear-icon::before,
  .e-small.e-bigger .e-float-input.e-control-wrapper .e-clear-icon::before,
  .e-small.e-bigger .e-input-group .e-clear-icon::before,
  .e-small.e-bigger .e-input-group.e-control-wrapper .e-clear-icon::before,
  .e-small .e-input-group.e-bigger input:first-child ~ .e-clear-icon::before,
  .e-small .e-input-group.e-control-wrapper.e-bigger input:first-child ~ .e-clear-icon::before,
  .e-bigger .e-input-group.e-small input:first-child ~ .e-clear-icon::before,
  .e-bigger .e-input-group.e-control-wrapper.e-small input:first-child ~ .e-clear-icon::before,
  .e-small .e-float-input.e-control-wrapper.e-bigger input:first-child ~ .e-clear-icon::before,
  .e-small .e-float-input.e-bigger input:first-child ~ .e-clear-icon::before,
  .e-bigger .e-float-input.e-control-wrapper.e-small input:first-child ~ .e-clear-icon::before,
  .e-bigger .e-float-input.e-small input:first-child ~ .e-clear-icon::before {
    font-size: $input-bigger-small-clear-icon;
  }

  .e-input-group.e-bigger .e-clear-icon,
  .e-input-group.e-control-wrapper.e-bigger .e-clear-icon,
  .e-input-group .e-clear-icon.e-bigger,
  .e-input-group.e-control-wrapper .e-clear-icon.e-bigger,
  .e-bigger .e-input-group .e-clear-icon,
  .e-bigger .e-input-group.e-control-wrapper .e-clear-icon {
    min-width: $input-bigger-clear-icon-min-width;
    @if $input-skin-name != 'Material3' and $input-skin-name != 'fluent2' {
      min-height: $input-bigger-clear-icon-min-height;
      padding-bottom: $input-clear-icon-bigger-padding-bottom;
      padding-right: $input-clear-icon-bigger-padding-right;
      padding-top: $input-clear-icon-bigger-padding-top;
    }
    @else if $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $input-bigger-clear-icon-padding;
    }
    @else {
      min-height: $input-bigger-clear-icon-min-height;
      padding: $input-clear-icon-padding;
      margin: $input-clear-icon-margin;
      border-radius: $input-bigger-clear-icon-border-radius;
    }
  }

  .e-input-group.e-float-input.e-bigger .e-clear-icon,
  .e-input-group.e-float-input .e-clear-icon.e-bigger,
  .e-bigger .e-input-group.e-float-input .e-clear-icon,
  .e-input-group.e-control-wrapper.e-float-input.e-bigger .e-clear-icon,
  .e-input-group.e-control-wrapper.e-float-input .e-clear-icon.e-bigger,
  .e-bigger .e-input-group.e-control-wrapper.e-float-input .e-clear-icon {
    @if $input-skin-name != 'Material3' and $input-skin-name != 'fluent2' and $input-skin-name != 'tailwind3' {
      padding-right: $input-clear-icon-bigger-padding-right;
      padding-top: $float-input-clear-icon-bigger-padding-top;
    }
  }

  .e-small.e-bigger .e-input-group .e-clear-icon,
  .e-small.e-bigger .e-input-group.e-control-wrapper .e-clear-icon {
    @if $input-skin-name != 'Material3' and $input-skin-name != 'fluent2' {
      min-height: $input-bigger-clear-icon-min-height;
      min-width: $input-bigger-clear-icon-min-width;
      padding-bottom: $input-clear-icon-bigger-padding-bottom;
      padding-right: $input-clear-icon-bigger-padding-right;
      padding-top: $input-clear-icon-bigger-padding-top;
    }
    @else {
      min-height: $input-bigger-small-clear-icon-min-height;
      min-width: $input-bigger-small-clear-icon-min-width;
      padding: $input-clear-icon-padding;
      margin: $input-clear-icon-margin;
      border-radius: $input-bigger-clear-icon-border-radius;
    }
  }

  .e-input-group.e-small.e-bigger .e-clear-icon,
  .e-input-group.e-small .e-clear-icon.e-bigger,
  .e-bigger .e-input-group.e-small .e-clear-icon,
  .e-input-group.e-control-wrapper.e-small.e-bigger .e-clear-icon,
  .e-input-group.e-control-wrapper.e-small .e-clear-icon.e-bigger,
  .e-bigger .e-input-group.e-control-wrapper.e-small .e-clear-icon {
    min-height: $input-bigger-small-clear-icon-min-height;
    min-width: $input-bigger-small-clear-icon-min-width;
    @if $input-skin-name != 'Material3' and $input-skin-name != 'fluent2' {
      padding-bottom: $input-clear-icon-small-bigger-padding-bottom;
      padding-right: $input-clear-icon-small-bigger-padding-right;
      padding-top: $input-clear-icon-small-bigger-padding-top;
    }
  }

  .e-input-group.e-float-input.e-small.e-bigger .e-clear-icon,
  .e-input-group.e-float-input.e-small .e-clear-icon.e-bigger,
  .e-bigger .e-input-group.e-float-input.e-small .e-clear-icon,
  .e-input-group.e-control-wrapper.e-float-input.e-small.e-bigger .e-clear-icon,
  .e-input-group.e-control-wrapper.e-float-input.e-small .e-clear-icon.e-bigger,
  .e-bigger .e-input-group.e-control-wrapper.e-float-input.e-small .e-clear-icon,
  .e-input-group.e-float-input.e-control-wrapper.e-small.e-bigger .e-clear-icon,
  .e-input-group.e-float-input.e-control-wrapper.e-small .e-clear-icon.e-bigger,
  .e-bigger .e-input-group.e-float-input.e-control-wrapper.e-small .e-clear-icon,
  .e-input-group.e-control-wrapper.e-float-input.e-small.e-bigger .e-clear-icon,
  .e-input-group.e-control-wrapper.e-float-input.e-small .e-clear-icon.e-bigger,
  .e-bigger .e-input-group.e-control-wrapper.e-float-input.e-small .e-clear-icon {
    @if $input-skin-name != 'Material3' and $input-skin-name != 'fluent2' and $input-skin-name != 'tailwind3' {
      padding-right: $input-clear-icon-small-bigger-padding-right;
      padding-top: $float-input-clear-icon-small-bigger-padding-top;
    }
  }

  .e-float-input.e-bigger textarea:focus ~ label.e-float-text,
  .e-float-input.e-bigger textarea:valid ~ label.e-float-text,
  .e-float-input.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input textarea:focus ~ label.e-float-text,
  .e-bigger .e-float-input textarea:valid ~ label.e-float-text,
  .e-bigger .e-float-input textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger textarea:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper textarea:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper textarea:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper textarea ~ label.e-label-top.e-float-text {
    font-size: $float-label-bigger-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      top: -20px;
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'highcontrast' {
      top: 14px;
    }
    @else if $input-skin-name == 'tailwind' {
      top: 12px;
    }
    @else if $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      top: -28px;
    }
  }

  .e-float-input.e-small.e-bigger textarea:focus ~ label.e-float-text,
  .e-float-input.e-small.e-bigger textarea:valid ~ label.e-float-text,
  .e-float-input.e-small.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small textarea:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-small textarea:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-small textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger textarea:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small textarea:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small textarea:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small textarea ~ label.e-label-top.e-float-text {
    font-size: $float-label-bigger-small-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      top: -20px;
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'highcontrast' {
      top: 16px;
    }
    @else if $input-skin-name == 'tailwind' {
      top: 15px;
    }
  }

  .e-float-input.e-bigger textarea ~ label.e-float-text,
  .e-float-input textarea ~ label.e-float-text.e-bigger,
  .e-float-input textarea.e-bigger ~ label.e-float-text,
  .e-bigger .e-float-input textarea ~ label.e-float-text,
  .e-float-input.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger textarea ~ label.e-float-text,
  .e-float-input.e-control-wrapper textarea ~ label.e-float-text.e-bigger,
  .e-float-input.e-control-wrapper textarea.e-bigger ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper textarea ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    font-size: $float-placeholder-bigger-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -8px;
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' or $input-skin-name == 'highcontrast' {
      top: 18px;
    }
    @else if $input-skin-name == 'tailwind' {
      top: -11px;
    }
  }

  .e-input-group.e-bigger:not(.e-float-input) .e-input,
  .e-bigger .e-input-group:not(.e-float-input) .e-input,
  .e-input-group.e-control-wrapper.e-bigger:not(.e-float-input) .e-input,
  .e-bigger .e-input-group.e-control-wrapper:not(.e-float-input) .e-input,
  .e-float-input.e-bigger input,
  .e-bigger .e-float-input input,
  .e-float-input.e-input-group.e-bigger input,
  .e-bigger .e-float-input.e-input-group input,
  .e-float-input.e-input-group.e-control-wrapper.e-bigger input,
  .e-bigger .e-float-input.e-input-group.e-control-wrapper input,
  .e-float-input.e-control-wrapper.e-bigger input,
  .e-bigger .e-float-input.e-control-wrapper input,
  .e-float-input.e-control-wrapper.e-input-group.e-bigger input,
  .e-bigger .e-float-input.e-control-wrapper.e-input-group input,
  .e-float-input.e-control-wrapper.e-input-group.e-bigger input,
  .e-bigger .e-float-input.e-control-wrapper.e-input-group input {
    @if $input-skin-name == 'material' {
      min-height: 27px;
    }
    @if $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      min-height: 28px;
    }
  }

  .e-input-group.e-bigger.e-small:not(.e-float-input) .e-input,
  .e-bigger .e-input-group.e-small:not(.e-float-input) .e-input .e-small .e-input-group.e-bigger:not(.e-float-input) .e-input,
  .e-input-group.e-control-wrapper.e-bigger.e-small:not(.e-float-input) .e-input,
  .e-bigger .e-input-group.e-control-wrapper.e-small:not(.e-float-input) .e-input .e-small .e-input-group.e-control-wrapper.e-bigger:not(.e-float-input) .e-input,
  .e-float-input.e-bigger.e-small input,
  .e-bigger .e-float-input.e-small input,
  .e-small .e-float-input.e-bigger input,
  .e-float-input.e-input-group.e-bigger.e-small input,
  .e-bigger .e-float-input.e-input-group.e-small input,
  .e-small .e-float-input.e-input-group.e-bigger input,
  .e-float-input.e-input-group.e-control-wrapper.e-bigger.e-small input,
  .e-bigger .e-float-input.e-input-group.e-control-wrapper.e-small input,
  .e-small .e-float-input.e-input-group.e-control-wrapper.e-bigger input,
  .e-float-input.e-control-wrapper.e-bigger.e-small input,
  .e-bigger .e-float-input.e-control-wrapper.e-small input,
  .e-small .e-float-input.e-control-wrapper.e-bigger input,
  .e-float-input.e-control-wrapper.e-input-group.e-bigger.e-small input,
  .e-bigger .e-float-input.e-control-wrapper.e-input-group.e-small input,
  .e-small .e-float-input.e-control-wrapper.e-input-group.e-bigger input  {
    @if $input-skin-name == 'material' {
      min-height: 28px;
    }
    @if $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      min-height: 26px;
    }
  }

  .e-bigger .e-float-input:not(.e-outline) input:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-float-input:not(.e-outline) input:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-autofill:not(.e-outline) input:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-float-input.e-control-wrapper.e-autofill:not(.e-outline) input:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus):not(.e-outline) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus):not(.e-outline) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom {
    font-size: $float-label-bigger-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      top: -8px;
      transform: translate3d(0, -6px, 0) scale(.86);
    }
    @else if $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      top: -14px;
      transform: translate3d(0, -6px, 0) scale(.86);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      padding-right: 0;
      top: 14px;
      transform: translate3d(-10px, -43px, 0) scale(1);
    }
    @else if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' {
      font-weight: bold;
      padding-right: 0;
      top: 14px;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if ($input-skin-name == 'tailwind') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -40px, 0) scale(1);
    }
    @else if ($input-skin-name == 'FluentUI') {
      font-style: $input-font-normal;
      font-weight: 600;
      padding-right: 0;
      transform: translate3d(-10px, -45px, 0) scale(1);
    }
    user-select: text;
  }

  .e-bigger .e-float-input.e-small:not(.e-outline) input:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-float-input:not(.e-outline) input:-webkit-autofill ~ label.e-float-text,
  .e-float-input.e-bigger.e-small:not(.e-outline) input:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-float-input.e-small.e-control-wrapper.e-autofill:not(.e-outline) input:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-float-input.e-control-wrapper.e-autofill:not(.e-outline) input:-webkit-autofill ~ label.e-float-text,
  .e-float-input.e-bigger.e-small.e-control-wrapper.e-autofill:not(.e-outline) input:-webkit-autofill ~ label.e-float-text,
  .e-small.e-bigger .e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus):not(.e-outline) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus):not(.e-outline) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus):not(.e-outline) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus):not(.e-outline) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom {
    font-size: $float-label-bigger-small-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      top: -10px;
      transform: translate3d(0, -6px, 0) scale(.92);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      padding-right: 0;
      top: 16px;
      transform: translate3d(-10px, -43px, 0) scale(1);
    }
    @else if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' {
      font-weight: bold;
      padding-right: 0;
      top: 16px;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if ($input-skin-name == 'FluentUI') {
      font-style: $input-font-normal;
      font-weight: 600;
      padding-right: 0;
      top: 16px;
      transform: translate3d(-10px, -41px, 0) scale(1);
    }
    user-select: text;
  }

  .e-float-input.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom  {
    @if $input-skin-name == 'fabric' or $input-skin-name == 'FluentUI' or $input-skin-name == 'bootstrap' or $input-skin-name == 'highcontrast' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' {
      @include textarea-float-label-alignment;
      font-style: $input-font-style;
    }
  }

  .e-bigger .e-float-input.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    font-size: $float-placeholder-bigger-small-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' {
      top: -10px;
    }
    font-style: $input-font-style;
  }

  .e-float-input.e-bigger.e-small textarea:focus ~ label.e-float-text,
  .e-float-input.e-bigger.e-small textarea:valid ~ label.e-float-text,
  .e-float-input.e-bigger.e-small textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger.e-small textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger.e-small textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger.e-small textarea label.e-float-text.e-label-top,
  .e-bigger .e-float-input.e-small textarea:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-small textarea:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-small textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small textarea label.e-float-text.e-label-top,
  .e-small .e-float-input.e-bigger textarea:focus ~ label.e-float-text,
  .e-small .e-float-input.e-bigger textarea:valid ~ label.e-float-text,
  .e-small .e-float-input.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-bigger textarea label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea label.e-float-text.e-label-top,
  .e-bigger .e-float-input.e-control-wrapper.e-small textarea:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small textarea:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small textarea label.e-float-text.e-label-top,
  .e-small .e-float-input.e-control-wrapper.e-bigger textarea:focus ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-bigger textarea:valid ~ label.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-float-input.e-control-wrapper.e-bigger textarea label.e-float-text.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      transform: translate3d(0, 6px, 0) scale(.92);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      padding-right: 0;
      transform: translate3d(-10px, -43px, 0) scale(1);
    }
    @else if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' or $input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3' {
      padding-right: 0;
      transform: translate3d(-10px, -37px, 0) scale(1);
    }
    @else if ($input-skin-name == 'FluentUI') {
      font-style: $input-font-normal;
      padding-right: 0;
      transform: translate3d(-10px, -41px, 0) scale(1);
    }
  }

  .e-bigger .e-float-input.e-small textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-float-input.e-bigger.e-small textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-float-input.e-small.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-float-input.e-bigger.e-small.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-small.e-bigger .e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom {
    font-size: $float-label-bigger-small-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      top: -10px;
      transform: translate3d(0, -6px, 0) scale(.92);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      padding-right: 0;
      top: 16px;
      transform: translate3d(-10px, -43px, 0) scale(1);
    }

    @else if $input-skin-name == 'bootstrap' {
      font-weight: $input-float-font-weight;
      padding-right: 0;
      top: 16px;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if $input-skin-name == 'FluentUI' {
      font-style: $input-font-normal;
      font-weight: 600;
      padding-right: 0;
      top: 16px;
      transform: translate3d(-10px, -41px, 0) scale(1);
    }
    user-select: text;
  }

  .e-bigger .e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom {
    font-size: $float-label-bigger-font-size;
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      top: -8px;
      transform: translate3d(0, -6px, 0) scale(.86);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      padding-right: 0;
      top: 14px;
      transform: translate3d(-10px, -43px, 0) scale(1);
    }
    @else if $input-skin-name == 'bootstrap' {
      font-weight: $input-float-font-weight;
      padding-right: 0;
      top: 14px;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if $input-skin-name == 'FluentUI' {
      font-style: $input-font-normal;
      font-weight: 600;
      padding-right: 0;
      top: 14px;
      transform: translate3d(-10px, -45px, 0) scale(1);
    }
    user-select: text;
  }

  .e-float-input.e-bigger textarea:focus ~ label.e-float-text,
  .e-float-input.e-bigger textarea:valid ~ label.e-float-text,
  .e-float-input.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger textarea label.e-float-text.e-label-top,
  .e-bigger .e-float-input textarea:focus ~ label.e-float-text,
  .e-bigger .e-float-input textarea:valid ~ label.e-float-text,
  .e-bigger .e-float-input textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input textarea label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-bigger textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger textarea:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger textarea label.e-float-text.e-label-top,
  .e-bigger .e-float-input.e-control-wrapper textarea:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper textarea:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper textarea label.e-float-text.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      transform: translate3d(0, 6px, 0) scale(.86);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      padding-right: 0;
      transform: translate3d(-10px, -43px, 0) scale(1);
    }
    @else if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' {
      padding-right: 0;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if ($input-skin-name == 'tailwind') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -35px, 0) scale(1);
    }
    @else if ($input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if ($input-skin-name == 'FluentUI') {
      font-style: $input-font-normal;
      font-weight: 600;
      padding-right: 0;
      transform: translate3d(-10px, -45px, 0) scale(1);
    }
  }

  .e-float-input.e-small.e-bigger textarea:focus ~ label.e-float-text,
  .e-float-input.e-small.e-bigger textarea:valid ~ label.e-float-text,
  .e-float-input.e-small.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-small.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small.e-bigger textarea label.e-float-text.e-label-top,
  .e-bigger.e-small .e-float-input textarea:focus ~ label.e-float-text,
  .e-bigger.e-small .e-float-input textarea:valid ~ label.e-float-text,
  .e-bigger.e-small .e-float-input textarea ~ label.e-label-top.e-float-text,
  .e-bigger.e-small .e-float-input textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger.e-small .e-float-input textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger.e-small .e-float-input textarea label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea label.e-float-text.e-label-top,
  .e-small.e-bigger .e-float-input.e-control-wrapper textarea:focus ~ label.e-float-text,
  .e-small.e-bigger .e-float-input.e-control-wrapper textarea:valid ~ label.e-float-text,
  .e-small.e-bigger .e-float-input.e-control-wrapper textarea ~ label.e-label-top.e-float-text,
  .e-small.e-bigger .e-float-input.e-control-wrapper textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-small.e-bigger .e-float-input.e-control-wrapper textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-small.e-bigger .e-float-input.e-control-wrapper textarea label.e-float-text.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      transform: translate3d(0, 6px, 0) scale(.86);
    }
    @else if $input-skin-name == 'fabric' or $input-skin-name == 'highcontrast' {
      font-style: $input-font-normal;
      padding-right: 0;
      transform: translate3d(-10px, -43px, 0) scale(1);
    }
    @else if $input-skin-name == 'bootstrap' or $input-skin-name == 'bootstrap4' {
      padding-right: 0;
      transform: translate3d(-10px, -39px, 0) scale(1);
    }
    @else if ($input-skin-name == 'tailwind') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -33px, 0) scale(1);
    }
    @else if ($input-skin-name == 'bootstrap5' or $input-skin-name == 'bootstrap5.3') {
      font-weight: 500;
      padding-right: 0;
      transform: translate3d(-10px, -36px, 0) scale(1);
    }
    @else if ($input-skin-name == 'FluentUI') {
      font-style: $input-font-normal;
      font-weight: 600;
      padding-right: 0;
      transform: translate3d(-10px, -41px, 0) scale(1);
    }
  }

  .e-input-group.e-bigger textarea,
  .e-input-group.e-bigger textarea.e-input,
  .e-input-group textarea.e-bigger,
  .e-input-group textarea.e-input.e-bigger,
  .e-input-group.e-control-wrapper.e-bigger textarea,
  .e-input-group.e-control-wrapper.e-bigger textarea.e-input,
  .e-input-group.e-control-wrapper textarea.e-bigger,
  .e-input-group.e-control-wrapper textarea.e-input.e-bigger,
  .e-bigger .e-input-group textarea,
  .e-bigger .e-input-group textarea.e-input,
  .e-bigger .e-input-group.e-control-wrapper textarea,
  .e-bigger .e-input-group.e-control-wrapper textarea.e-input,
  .e-float-input.e-bigger textarea,
  .e-float-input textarea.e-bigger,
  .e-float-input.e-control-wrapper.e-bigger textarea,
  .e-float-input.e-control-wrapper textarea.e-bigger,
  .e-bigger .e-float-input textarea,
  .e-bigger .e-float-input.e-control-wrapper textarea {
    font: inherit;
    line-height: 1.2;
    min-height: $textarea-bigger-min-height;
    padding: $textarea-bigger-padding;
  }

  .e-input-group.e-input-focus.e-bigger textarea,
  .e-input-group.e-input-focus.e-bigger textarea.e-input,
  .e-input-group.e-input-focus textarea.e-bigger,
  .e-input-group.e-input-focus textarea.e-input.e-bigger,
  .e-input-group.e-control-wrapper.e-input-focus.e-bigger textarea,
  .e-input-group.e-control-wrapper.e-input-focus.e-bigger textarea.e-input,
  .e-input-group.e-control-wrapper.e-input-focus textarea.e-bigger,
  .e-input-group.e-control-wrapper.e-input-focus textarea.e-input.e-bigger,
  .e-bigger .e-input-group.e-input-focus textarea,
  .e-bigger .e-input-group.e-control-wrapper.e-input-focus textarea,
  .e-bigger .e-input-group.e-control-wrapper.e-input-focus textarea.e-input,
  .e-input-group.e-control-wrapper textarea.e-bigger:focus,
  .e-input-group.e-control-wrapper textarea.e-input.e-bigger:focus,
  .e-bigger .e-input-group.e-control-wrapper textarea:focus,
  .e-bigger .e-input-group.e-control-wrapper.e-input-focus textarea:focus,
  .e-bigger .e-input-group.e-control-wrapper textarea.e-input:focus {
    font: inherit;
    line-height: 1.2;
    min-height: $textarea-bigger-min-height;
    padding: $textarea-bigger-padding;
  }

  .e-input-group.e-small.e-bigger textarea.e-input,
  .e-input-group.e-small textarea.e-input.e-bigger,
  .e-input-group textarea.e-input.e-bigger.e-small,
  .e-input-group.e-bigger textarea.e-small,
  .e-input-group.e-control-wrapper.e-small.e-bigger textarea.e-input,
  .e-input-group.e-control-wrapper.e-small textarea.e-input.e-bigger,
  .e-bigger.e-small .e-input-group textarea.e-input,
  .e-bigger.e-small .e-input-group.e-control-wrapper textarea.e-input,
  .e-bigger .input-group textarea.e-small,
  .e-bigger .e-input-group.e-small textarea.e-input,
  .e-bigger .e-input-group.e-control-wrapper.e-small textarea.e-input,
  .e-bigger .e-small.e-input-group.e-control-wrapper textarea.e-input,
  .e-small .input-group textarea.e-bigger,
  .e-small .e-input-group.e-bigger textarea.e-input,
  .e-small .e-input-group.e-control-wrapper textarea.e-input.e-bigger,
  .e-small .e-input-group.e-control-wrapper.e-input-focus textarea.e-input.e-bigger,
  .e-float-input textarea.e-bigger.e-small,
  .e-float-input.e-small.e-bigger textarea,
  .e-float-input.e-small textarea.e-bigger,
  .e-float-input.e-bigger textarea.e-small,
  .e-bigger .e-float-input.e-small textarea,
  .e-bigger.e-small .e-float-input textarea,
  .e-bigger .float-input textarea.e-small,
  .e-small .e-float-input.e-bigger textarea,
  .e-small .float-input textarea.e-bigger,
  .e-small .e-float-input.e-control-wrapper textarea.e-bigger,
  .e-float-input.e-control-wrapper.e-small.e-bigger textarea,
  .e-float-input.e-control-wrapper.e-small textarea.e-bigger,
  .e-bigger .e-float-input.e-control-wrapper.e-small textarea,
  .e-bigger.e-small .e-float-input.e-control-wrapper textarea,
  .e-bigger .e-small.e-float-input.e-control-wrapper textarea {
    font: inherit;
    line-height: 1.2;
    min-height: $textarea-bigger-small-min-height;
    padding: $textarea-bigger-small-padding;
  }

  .e-input-group.e-input-focus textarea.e-input.e-bigger.e-small,
  .e-input-group.e-input-focus.e-small.e-bigger textarea.e-input,
  .e-input-group.e-input-focus.e-small textarea.e-input.e-bigger,
  .e-input-group.e-input-focus.e-bigger textarea.e-small,
  .e-input-group.e-control-wrapper.e-input-focus.e-small.e-bigger textarea.e-input,
  .e-input-group.e-control-wrapper.e-input-focus.e-small textarea.e-input.e-bigger,
  .e-bigger.e-small .e-input-group.e-input-focus textarea.e-input,
  .e-bigger .e-input-group.e-control-wrapper.e-input-focus.e-small textarea.e-input,
  .e-bigger.e-small .e-input-group.e-control-wrapper.e-input-focus textarea.e-input,
  .e-bigger .e-small.e-input-group.e-control-wrapper.e-input-focus textarea.e-input,
  .e-small .e-input-group.e-control-wrapper.e-input-focus.e-bigger textarea.e-input {
    font: inherit;
    line-height: 1.2;
    min-height: $textarea-bigger-small-min-height;
    padding: $textarea-bigger-small-padding;
  }

  .e-bigger input.e-input,
  .e-bigger textarea.e-input,
  input.e-input.e-bigger,
  textarea.e-input.e-bigger {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      min-height: $input-bigger-min-height;
    }
  }

  .e-bigger input.e-input.e-small,
  .e-bigger .e-small input.e-input,
  .e-bigger.e-small input.e-input,
  .e-small input.e-input.e-bigger,
  input.e-input.e-bigger.e-small,
  .e-small .e-bigger input.e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      min-height: $input-bigger-smaller-min-height;
    }
  }

  input.e-input.e-bigger.e-outline,
  textarea.e-input.e-bigger.e-outline,
  .e-outline.e-bigger input.e-input,
  .e-outline.e-bigger textarea.e-input,
  .e-outline.e-input-group.e-bigger,
  .e-bigger .e-input-group.e-outline,
  .e-outline.e-input-group.e-control-wrapper.e-bigger,
  .e-bigger .e-outline.e-input-group.e-control-wrapper,
  .e-outline.e-input-group.e-bigger.e-disabled,
  .e-bigger .e-outline.e-input-group.e-disabled,
  .e-outline.e-input-group.e-control-wrapper.e-bigger.e-disabled,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-disabled,
  .e-outline.e-input-group.e-bigger,
  .e-bigger .e-outline.e-input-group,
  .e-outline.e-input-group.e-control-wrapper.e-bigger,
  .e-bigger .e-outline.e-input-group.e-control-wrapper,
  .e-outline.e-float-input.e-bigger,
  .e-bigger .e-outline.e-float-input,
  .e-outline.e-float-input.e-control-wrapper.e-bigger,
  .e-bigger .e-outline.e-float-input.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $outline-bigger-input-font-size;
    }
  }

  .e-outline.e-input-group.e-control-wrapper.e-bigger .e-input-group-icon,
  .e-bigger .e-outline.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-outline.e-input-group.e-bigger.e-disabled .e-input-group-icon,
  .e-bigger .e-outline.e-input-group.e-disabled .e-input-group-icon,
  .e-outline.e-input-group.e-control-wrapper.e-bigger.e-disabled .e-input-group-icon,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-disabled .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $outline-bigger-input-icon-font-size;
    }
  }

  .e-outline.e-input-group.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-outline.e-input-group .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-bigger .e-outline.e-input-group .e-input-group-icon + .e-input-group-icon:last-child,
  .e-outline.e-input-group.e-small.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-outline.e-input-group.e-small .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-outline.e-input-group.e-control-wrapper.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-outline.e-input-group.e-control-wrapper .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  e-outline.e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon + .e-input-group-icon:last-child,
  .e-bigger .e-outline.e-input-group.e-small .e-input-group-icon + .e-input-group-icon:last-child {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-left: $zero-value;
    }
  }

  .e-outline.e-float-input.e-bigger input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger input:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger input ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input input ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger input:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger input ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper input ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-bigger textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger textarea:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input textarea ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger textarea:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper textarea ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $outline-bigger-label-font-size;
    }
  }

  .e-outline.e-input-group.e-small.e-bigger,
  .e-small .e-outline.e-input-group.e-bigger,
  .e-bigger .e-outline.e-input-group.e-small,
  .e-bigger.e-small .e-outline.e-input-group,
  .e-outline.e-input-group.e-control-wrapper.e-small.e-bigger,
  .e-small .e-outline.e-input-group.e-control-wrapper.e-bigger,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-small,
  .e-bigger.e-small .e-outline.e-input-group.e-control-wrapper,
  .e-outline.e-float-input.e-small.e-bigger,
  .e-small .e-outline.e-float-input.e-bigger,
  .e-bigger .e-outline.e-float-input.e-small,
  .e-bigger.e-small .e-outline.e-float-input,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small,
  .e-bigger.e-small .e-outline.e-float-input.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $outline-small-bigger-input-font-size;
    }
  }

  .e-small.e-outline.e-float-input.e-bigger input:focus ~ label.e-float-text,
  .e-small.e-outline.e-float-input.e-bigger input:valid ~ label.e-float-text,
  .e-small.e-outline.e-float-input.e-bigger input ~ label.e-label-top.e-float-text,
  .e-bigger .e-small.e-outline.e-float-input input ~ label.e-label-top.e-float-text,
  .e-small.e-outline.e-float-input.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-small.e-outline.e-float-input.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-small.e-outline.e-float-input.e-control-wrapper.e-bigger input:focus ~ label.e-float-text,
  .e-small.e-outline.e-float-input.e-control-wrapper.e-bigger input:valid ~ label.e-float-text,
  .e-small.e-outline.e-float-input.e-control-wrapper.e-bigger input ~ label.e-label-top.e-float-text,
  .e-bigger .e-small.e-outline.e-float-input.e-control-wrapper input ~ label.e-label-top.e-float-text,
  .e-small.e-outline.e-float-input.e-control-wrapper.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-small.e-outline.e-float-input.e-control-wrapper.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger input:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger input:valid ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger input ~ label.e-label-top.e-float-text,
  .e-small .e-bigger.e-outline.e-float-input input ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger input:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger input:valid ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger input ~ label.e-label-top.e-float-text,
  .e-small .e-bigger.e-outline.e-float-input.e-control-wrapper input ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-bigger input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-bigger input:valid ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-bigger input ~ label.e-label-top.e-float-text,
  .e-bigger .e-small.e-outline.e-float-input input ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-bigger input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-bigger input:valid ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-bigger input ~ label.e-label-top.e-float-text,
  .e-bigger .e-small.e-outline.e-float-input.e-control-wrapper input ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-small.e-outline.e-float-input.e-bigger textarea:focus ~ label.e-float-text,
  .e-small.e-outline.e-float-input.e-bigger textarea:valid ~ label.e-float-text,
  .e-small.e-outline.e-float-input.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-small.e-outline.e-float-input textarea ~ label.e-label-top.e-float-text,
  .e-small.e-outline.e-float-input.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-small.e-outline.e-float-input.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-small.e-outline.e-float-input.e-control-wrapper.e-bigger textarea:focus ~ label.e-float-text,
  .e-small.e-outline.e-float-input.e-control-wrapper.e-bigger textarea:valid ~ label.e-float-text,
  .e-small.e-outline.e-float-input.e-control-wrapper.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-small.e-outline.e-float-input.e-control-wrapper textarea ~ label.e-label-top.e-float-text,
  .e-small.e-outline.e-float-input.e-control-wrapper.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-small.e-outline.e-float-input.e-control-wrapper.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger textarea:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger textarea:valid ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-small .e-bigger.e-outline.e-float-input textarea ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger textarea:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger textarea:valid ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-small .e-bigger.e-outline.e-float-input.e-control-wrapper textarea ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-bigger textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-bigger textarea:valid ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-small.e-outline.e-float-input textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-bigger textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-bigger textarea:valid ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-small.e-outline.e-float-input.e-control-wrapper textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-small.e-outline.e-float-input.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-small.e-outline.e-float-input.e-control-wrapper.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger input:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger input ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger input[readonly] ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger input[disabled] ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small input:valid ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small input ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small input[readonly] ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small input[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger input:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger input ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger input[readonly] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger input[disabled] ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small input:valid ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small input ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small input[readonly] ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small input[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger textarea:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger textarea[readonly] ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger textarea[disabled] ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small textarea:valid ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small textarea[readonly] ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small textarea[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger textarea:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger textarea[readonly] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger textarea[disabled] ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small textarea:valid ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small textarea[readonly] ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small textarea[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $outline-small-bigger-label-font-size;
    }
  }

  .e-outline.e-input-group.e-bigger:not(.e-float-input) .e-input,
  .e-bigger .e-outline.e-input-group:not(.e-float-input) .e-input,
  .e-outline.e-input-group.e-control-wrapper.e-bigger:not(.e-float-input) .e-input,
  .e-bigger .e-outline.e-input-group.e-control-wrapper:not(.e-float-input) .e-input,
  .e-outline.e-float-input.e-bigger input,
  .e-bigger .e-outline.e-float-input input,
  .e-outline.e-float-input.e-input-group.e-bigger input,
  .e-bigger .e-outline.e-float-input.e-input-group input,
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-bigger input,
  .e-bigger .e-outline.e-float-input.e-input-group.e-control-wrapper input,
  .e-outline.e-float-input.e-control-wrapper.e-bigger input,
  .e-bigger .e-outline.e-float-input.e-control-wrapper input,
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-bigger input,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-input-group input,
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-bigger input,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-input-group input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      box-sizing: border-box;
      min-height: $outline-bigger-input-min-height;
    }
  }

  .e-outline.e-input-group.e-bigger .e-clear-icon,
  .e-outline.e-input-group.e-control-wrapper.e-bigger .e-clear-icon,
  .e-outline.e-input-group .e-clear-icon.e-bigger,
  .e-outline.e-input-group.e-control-wrapper .e-clear-icon.e-bigger,
  .e-bigger .e-outline.e-input-group .e-clear-icon,
  .e-bigger .e-outline.e-input-group.e-control-wrapper .e-clear-icon {
    @if $input-skin-name == 'material' {
      font-size: $outline-bigger-clear-icon-font-size;
      padding: $outline-bigger-input-icon-padding $outline-bigger-input-icon-margin-left $outline-bigger-input-icon-padding $zero-value;
    }
    @if $input-skin-name == 'Material3' {
      font-size: $outline-bigger-clear-icon-font-size;
      margin: 8px;
      padding: $zero-value;
    }
  }

  .e-outline.e-input-group.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-input-group.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-input-group.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-input-group.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-float-input.e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-input-group.e-control-wrapper.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-float-input.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-left: $outline-bigger-input-icon-margin-right;
      margin-right: $zero-value;
    }
  }

  .e-outline.e-input-group.e-rtl.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-input-group.e-rtl.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-input-group.e-rtl.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-input-group.e-rtl.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-input-group.e-rtl.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-float-input.e-input-group.e-rtl.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-input-group.e-rtl.e-control-wrapper.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-input-group.e-rtl.e-control-wrapper.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-input-group.e-rtl.e-control-wrapper.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-input-group.e-rtl.e-control-wrapper.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-input-group.e-rtl.e-control-wrapper.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-float-input.e-input-group.e-rtl.e-control-wrapper.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-left: $zero-value;
      margin-right: $outline-bigger-input-icon-margin-right;
    }
  }

  .e-outline.e-input-group.e-bigger .e-input-group-icon,
  .e-outline.e-input-group .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-input-group .e-input-group-icon,
  .e-outline.e-input-group.e-control-wrapper.e-bigger .e-input-group-icon,
  .e-outline.e-input-group.e-control-wrapper .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-input-group.e-control-wrapper .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin: $outline-bigger-input-icon-padding $outline-bigger-input-icon-margin-left  $outline-bigger-input-icon-padding $zero-value;
    }
  }

  .e-outline.e-input-group.e-prepend.e-bigger .e-input-group-icon,
  .e-outline.e-input-group.e-prepend .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-input-group.e-prepend .e-input-group-icon,
  .e-outline.e-input-group.e-prepend.e-control-wrapper.e-bigger .e-input-group-icon,
  .e-outline.e-input-group.e-prepend.e-control-wrapper .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-input-group.e-prepend.e-control-wrapper .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin: $outline-bigger-input-icon-padding $zero-value $outline-bigger-input-icon-padding $outline-bigger-input-icon-margin-left;
    }
  }

  input.e-input.e-bigger.e-outline,
  .e-bigger input.e-input.e-outline,
  .e-outline.e-input-group.e-bigger:not(.e-float-icon-left) input.e-input,
  .e-outline.e-input-group.e-control-wrapper.e-bigger:not(.e-float-icon-left) input.e-input,
  .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-float-icon-left) input,
  .e-outline.e-float-input.e-control-wrapper:not(.e-float-icon-left) input.e-bigger,
  .e-outline.e-float-input.e-bigger:not(.e-float-icon-left) input,
  .e-outline.e-float-input:not(.e-float-icon-left) input.e-bigger,
  .e-outline.e-input-group:not(.e-float-icon-left) input.e-input.e-bigger,
  .e-outline.e-input-group.e-control-wrapper:not(.e-float-icon-left) input.e-input.e-bigger,
  .e-bigger .e-outline.e-float-input.e-control-wrapper:not(.e-float-icon-left) input,
  .e-bigger .e-outline.e-float-input:not(.e-float-icon-left) input,
  .e-bigger .e-outline.e-input-group.e-control-wrapper:not(.e-float-icon-left) input.e-input,
  .e-bigger .e-outline.e-input-group:not(.e-float-icon-left) input.e-input,
  .e-outline.e-input-group.e-bigger:not(.e-float-icon-left) input.e-input:focus,
  .e-outline.e-input-group.e-control-wrapper.e-bigger:not(.e-float-icon-left) input.e-input:focus,
  .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-float-icon-left) input:focus,
  .e-outline.e-float-input.e-bigger.e-float-input:not(.e-float-icon-left) input:focus,
  .e-bigger .e-outline.e-input-group.e-float-input:not(.e-float-icon-left) input.e-input:focus,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-float-input:not(.e-float-icon-left) input.e-input:focus,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-float-input:not(.e-float-icon-left) input:focus,
  .e-bigger .e-outline.e-float-input:not(.e-float-icon-left) input:focus,
  .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-float-icon-left).e-input-focus input,
  .e-outline.e-float-input.e-bigger.e-float-input:not(.e-float-icon-left).e-input-focus input,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-float-input:not(.e-float-icon-left).e-input-focus input,
  .e-bigger .e-outline.e-float-input:not(.e-float-icon-left).e-input-focus input,
  .e-outline.e-input-group.e-bigger:not(.e-float-icon-left).e-input-focus input.e-input,
  .e-outline.e-input-group.e-control-wrapper.e-bigger:not(.e-float-icon-left).e-input-focus input.e-input,
  .e-bigger .e-outline.e-input-group.e-float-input:not(.e-float-icon-left).e-input-focus input.e-input,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-float-input:not(.e-float-icon-left).e-input-focus input.e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      padding: $outline-bigger-input-padding-top $outline-bigger-input-padding-left;
    }
  }

  textarea.e-input.e-bigger.e-outline,
  .e-bigger textarea.e-input.e-outline,
  .e-outline.e-input-group.e-bigger:not(.e-float-icon-left) textarea.e-input,
  .e-outline.e-input-group.e-control-wrapper.e-bigger:not(.e-float-icon-left) textarea.e-input,
  .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-float-icon-left) textarea,
  .e-outline.e-float-input.e-control-wrapper:not(.e-float-icon-left) textarea.e-bigger,
  .e-outline.e-float-input.e-bigger:not(.e-float-icon-left) textarea,
  .e-outline.e-float-input:not(.e-float-icon-left) textarea.e-bigger,
  .e-outline.e-input-group:not(.e-float-icon-left) textarea.e-input.e-bigger,
  .e-outline.e-input-group.e-control-wrapper:not(.e-float-icon-left) textarea.e-input.e-bigger,
  .e-bigger .e-outline.e-float-input.e-control-wrapper:not(.e-float-icon-left) textarea,
  .e-bigger .e-outline.e-float-input:not(.e-float-icon-left) textarea,
  .e-bigger .e-outline.e-input-group.e-control-wrapper:not(.e-float-icon-left) textarea.e-input,
  .e-bigger .e-outline.e-input-group:not(.e-float-icon-left) textarea.e-input,
  .e-outline.e-input-group.e-bigger:not(.e-float-icon-left) textarea.e-input:focus,
  .e-outline.e-input-group.e-control-wrapper.e-bigger:not(.e-float-icon-left) textarea.e-input:focus,
  .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-float-icon-left) textarea:focus,
  .e-outline.e-float-input.e-bigger.e-float-input:not(.e-float-icon-left) textarea:focus,
  .e-bigger .e-outline.e-input-group.e-float-input:not(.e-float-icon-left) textarea.e-input:focus,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-float-input:not(.e-float-icon-left) textarea.e-input:focus,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-float-input:not(.e-float-icon-left) textarea:focus,
  .e-bigger .e-outline.e-float-input:not(.e-float-icon-left) textarea:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin: $outline-bigger-textarea-maring-top;
      padding: $zero-value $outline-bigger-input-padding-left $outline-bigger-input-padding-left;
    }
  }

  .e-outline.e-input-group.e-bigger.e-small:not(.e-float-input) .e-input,
  .e-bigger .e-outline.e-input-group.e-small:not(.e-float-input) .e-input .e-small .e-input-group.e-bigger:not(.e-float-input) .e-input,
  .e-outline.e-input-group.e-control-wrapper.e-bigger.e-small:not(.e-float-input) .e-input,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-small:not(.e-float-input) .e-input,
  .e-small .e-outline.e-input-group.e-control-wrapper.e-bigger:not(.e-float-input) .e-input,
  .e-outline.e-float-input.e-bigger.e-small input,
  .e-bigger .e-outline.e-float-input.e-small input,
  .e-small .e-outline.e-float-input.e-bigger input,
  .e-outline.e-float-input.e-input-group.e-bigger.e-small input,
  .e-bigger .e-outline.e-float-input.e-input-group.e-small input,
  .e-small .e-outline.e-float-input.e-input-group.e-bigger input,
  .e-outline.e-float-input.e-input-group.e-control-wrapper.e-bigger.e-small input,
  .e-bigger .e-outline.e-float-input.e-input-group.e-control-wrapper.e-small input,
  .e-small .e-outline.e-float-input.e-input-group.e-control-wrapper.e-bigger input,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small input,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small input,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger input,
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-bigger.e-small input,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-input-group.e-small input,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-input-group.e-bigger input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      box-sizing: border-box;
      min-height: $outline-small-bigger-input-min-height;
    }
  }

  input.e-input.e-bigger.e-small.e-outline,
  .e-bigger input.e-input.e-small.e-outline,
  .e-outline.e-input-group.e-small.e-bigger:not(.e-float-icon-left) input.e-input,
  .e-outline.e-input-group.e-control-wrapper.e-small.e-bigger:not(.e-float-icon-left) input.e-input,
  .e-outline.e-input-group.e-small:not(.e-float-icon-left) input.e-input.e-bigger,
  .e-outline.e-input-group.e-control-wrapper.e-small:not(.e-float-icon-left) input.e-input.e-bigger,
  .e-bigger .e-outline.e-input-group.e-small:not(.e-float-icon-left) input.e-input,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-small:not(.e-float-icon-left) input.e-input,
  .e-outline.e-float-input.e-small.e-bigger:not(.e-float-icon-left) input,
  .e-outline.e-float-input.e-small:not(.e-float-icon-left) input.e-bigger,
  .e-bigger .e-outline.e-float-input.e-small:not(.e-float-icon-left) input,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-float-icon-left) input,
  .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-float-icon-left) input.e-bigger,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-float-icon-left) input,
  .e-bigger .e-outline.e-input-group.e-small:not(.e-float-icon-left) input.e-input:focus,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-small:not(.e-float-icon-left) input.e-input:focus,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-float-icon-left) input:focus,
  .e-bigger .e-outline.e-float-input.e-small:not(.e-float-icon-left) input:focus,
  .e-small .e-outline.e-input-group.e-bigger:not(.e-float-icon-left) input.e-input:focus,
  .e-small .e-outline.e-input-group.e-control-wrapper.e-bigger:not(.e-float-icon-left) input.e-input:focus,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-float-icon-left) input:focus,
  .e-small .e-outline.e-float-input.e-bigger:not(.e-float-icon-left) input:focus,
  .e-outline.e-input-group.e-bigger.e-small:not(.e-float-icon-left) input.e-input:focus,
  .e-outline.e-input-group.e-control-wrapper.e-bigger.e-small:not(.e-float-icon-left) input.e-input:focus,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-float-icon-left) input:focus,
  .e-outline.e-float-input.e-bigger.e-small:not(.e-float-icon-left) input:focus,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-float-icon-left).e-input-focus input,
  .e-bigger .e-outline.e-float-input.e-small:not(.e-float-icon-left).e-input-focus input,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-float-icon-left).e-input-focus input,
  .e-small .e-outline.e-float-input.e-bigger:not(.e-float-icon-left).e-input-focus input,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-float-icon-left).e-input-focus input,
  .e-outline.e-float-input.e-bigger.e-small:not(.e-float-icon-left).e-input-focus input,
  .e-bigger .e-outline.e-input-group.e-small:not(.e-float-icon-left).e-input-focus input.e-input,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-small:not(.e-float-icon-left).e-input-focus input.e-input,
  .e-small .e-outline.e-input-group.e-bigger:not(.e-float-icon-left).e-input-focus input.e-input,
  .e-small .e-outline.e-input-group.e-control-wrapper.e-bigger:not(.e-float-icon-left).e-input-focus input.e-input,
  .e-outline.e-input-group.e-bigger.e-small:not(.e-float-icon-left).e-input-focus input.e-input,
  .e-outline.e-input-group.e-control-wrapper.e-bigger.e-small:not(.e-float-icon-left).e-input-focus input.e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      padding: $outline-small-bigger-input-margin-top $outline-small-bigger-input-margin-left $outline-small-bigger-input-margin-bottom;
    }
  }

  textarea.e-input.e-bigger.e-small.e-outline,
  .e-bigger textarea.e-input.e-small.e-outline,
  .e-outline.e-input-group.e-small.e-bigger:not(.e-float-icon-left) textarea.e-input,
  .e-outline.e-input-group.e-control-wrapper.e-small.e-bigger:not(.e-float-icon-left) textarea.e-input,
  .e-outline.e-input-group.e-small:not(.e-float-icon-left) textarea.e-input.e-bigger,
  .e-outline.e-input-group.e-control-wrapper.e-small:not(.e-float-icon-left) textarea.e-input.e-bigger,
  .e-bigger .e-outline.e-input-group.e-small:not(.e-float-icon-left) textarea.e-input,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-small:not(.e-float-icon-left) textarea.e-input,
  .e-outline.e-float-input.e-small.e-bigger:not(.e-float-icon-left) textarea,
  .e-outline.e-float-input.e-small:not(.e-float-icon-left) textarea.e-bigger,
  .e-bigger .e-outline.e-float-input.e-small:not(.e-float-icon-left) textarea,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-float-icon-left) textarea,
  .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-float-icon-left) textarea.e-bigger,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-float-icon-left) textarea,
  .e-bigger .e-outline.e-input-group.e-small:not(.e-float-icon-left) textarea.e-input:focus,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-small:not(.e-float-icon-left) textarea.e-input:focus,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-float-icon-left) textarea:focus,
  .e-bigger .e-outline.e-float-input.e-small:not(.e-float-icon-left) textarea:focus,
  .e-small .e-outline.e-input-group.e-bigger:not(.e-float-icon-left) textarea.e-input:focus,
  .e-small .e-outline.e-input-group.e-control-wrapper.e-bigger:not(.e-float-icon-left) textarea.e-input:focus,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-float-icon-left) textarea:focus,
  .e-small .e-outline.e-float-input.e-bigger:not(.e-float-icon-left) textarea:focus,
  .e-outline.e-input-group.e-bigger.e-small:not(.e-float-icon-left) textarea.e-input:focus,
  .e-outline.e-input-group.e-control-wrapper.e-bigger.e-small:not(.e-float-icon-left) textarea.e-input:focus,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-float-icon-left) textarea:focus,
  .e-outline.e-float-input.e-bigger.e-small:not(.e-float-icon-left) textarea:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      box-sizing: border-box;
      margin: $outline-bigger-textarea-maring-top;
      padding: $zero-value $outline-small-bigger-input-margin-left $outline-small-bigger-input-margin-left;
    }
  }

  .e-outline.e-input-group.e-small.e-bigger .e-clear-icon,
  .e-outline.e-input-group.e-small .e-clear-icon.e-bigger,
  .e-bigger .e-outline.e-input-group.e-small .e-clear-icon,
  .e-outline.e-input-group.e-control-wrapper.e-small.e-bigger .e-clear-icon,
  .e-outline.e-input-group.e-control-wrapper.e-small .e-clear-icon.e-bigger,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-small .e-clear-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $outline-small-bigger-clear-icon-font-size;
      padding: 9px $outline-small-bigger-icon-margin-left 9px $zero-value;
    }
  }

  .e-outline.e-input-group.e-small.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-input-group.e-small.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-input-group.e-small.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-input-group.e-small.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-input-group.e-small.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-input-group.e-float-input.e-small.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-input-group.e-control-wrapper.e-small.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-input-group.e-control-wrapper.e-small.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-small.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-small.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-outline.e-float-input.e-control-wrapper.e-input-group.e-small.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-float-input.e-small.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-left: $zero-value;
      margin-right: $outline-small-bigger-icon-margin-right;
    }
  }

  .e-outline.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-outline.e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-outline.e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon,
  .e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-input-group.e-small .e-input-group-icon,
  .e-bigger .e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin: $outline-bigger-small-group-icon-top $outline-small-bigger-icon-margin-left $outline-bigger-small-group-icon-bottom $zero-value;
    }
  }

  .e-outline.e-input-group.e-prepend.e-small.e-bigger .e-input-group-icon,
  .e-outline.e-input-group.e-prepend.e-small .e-input-group-icon.e-bigger,
  .e-outline.e-input-group.e-prepend.e-control-wrapper.e-small.e-bigger .e-input-group-icon,
  .e-outline.e-input-group.e-prepend.e-control-wrapper.e-small .e-input-group-icon.e-bigger,
  .e-bigger .e-outline.e-input-group.e-prepend.e-small .e-input-group-icon,
  .e-bigger .e-outline.e-input-group.e-prepend.e-control-wrapper.e-small .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin: $outline-bigger-small-group-icon-top $zero-value $outline-bigger-small-group-icon-bottom $outline-small-bigger-icon-margin-left;
    }
  }

  .e-outline.e-float-input.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-bigger.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-small.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-bigger.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-small.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      box-sizing: border-box;
      display: flex;
      left: $zero-value;
      line-height: $outline-label-default-line-height;
      position: absolute;
      top: $outline-float-label-top;
      transform: translate3d($zero-value, $zero-value, $zero-value) scale(1);
      transition: color .2s, font-size .2s, line-height .2s;
    }
  }

  .e-outline.e-float-input.e-bigger:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-bigger.e-control-wrapper:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-outline.e-float-input:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-outline:not(.e-valid-input):not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid) ~ label.e-float-text,
  .e-bigger .e-outline:not(.e-valid-input):not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid) ~ label.e-float-text,
  .e-bigger.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      line-height: $outline-bigger-label-line-height;
    }
  }

  .e-outline.e-float-input.e-bigger:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-bigger.e-control-wrapper:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-outline.e-float-input:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-outline:not(.e-valid-input):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid) ~ label.e-float-text,
  .e-bigger .e-outline:not(.e-valid-input):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid) ~ label.e-float-text,
  .e-bigger.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-bigger.e-control-wrapper:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-outline.e-float-input:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-outline:not(.e-valid-input):not(.e-valid-input) textarea:not(:focus):not(:valid) ~ label.e-float-text,
  .e-bigger .e-outline:not(.e-valid-input):not(.e-valid-input) textarea:not(:focus):not(:valid) ~ label.e-float-text,
  .e-bigger.e-outline.e-float-input:not(.e-valid-input) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input:not(.e-valid-input) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper:not(.e-valid-input) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      line-height: $outline-textarea-bigger-label-line-height;
    }
  }

  .e-small.e-bigger.e-outline.e-float-input:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger.e-outline:not(.e-valid-input):not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid) ~ label.e-float-text,
  .e-small.e-bigger.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-small.e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-small .e-bigger.e-outline.e-float-input:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-outline:not(.e-valid-input):not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid) ~ label.e-float-text,
  .e-small .e-bigger.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-small .e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-bigger .e-small.e-outline.e-float-input:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-outline:not(.e-valid-input):not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid) ~ label.e-float-text,
  .e-bigger .e-small.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-bigger .e-small.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) input:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-small:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-error):not(.e-valid-input):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      line-height: $outline-small-bigger-label-line-height;
    }
  }

  .e-small.e-bigger.e-outline.e-float-input:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger.e-outline:not(.e-valid-input):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid) ~ label.e-float-text,
  .e-small.e-bigger.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-small.e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-small .e-bigger.e-outline.e-float-input:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-outline:not(.e-valid-input):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid) ~ label.e-float-text,
  .e-small .e-bigger.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-small .e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-bigger .e-small.e-outline.e-float-input:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-outline:not(.e-valid-input):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid) ~ label.e-float-text,
  .e-bigger .e-small.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-bigger .e-small.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-small:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-error):not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small.e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-outline.e-float-input:not(.e-valid-input):not(.e-input-focus) textarea:valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-outline.e-float-input.e-control-wrapper:not(.e-valid-input):not(.e-input-focus) textarea:valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger.e-outline.e-float-input:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger.e-outline:not(.e-valid-input):not(.e-valid-input) textarea:not(:focus):not(:valid) ~ label.e-float-text,
  .e-small.e-bigger.e-outline.e-float-input:not(.e-valid-input) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-small.e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-small .e-bigger.e-outline.e-float-input:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-outline:not(.e-valid-input):not(.e-valid-input) textarea:not(:focus):not(:valid) ~ label.e-float-text,
  .e-small .e-bigger.e-outline.e-float-input:not(.e-valid-input) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-small .e-bigger.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-bigger .e-small.e-outline.e-float-input:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-outline.e-float-input.e-control-wrapper:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-outline:not(.e-valid-input):not(.e-valid-input) textarea:not(:focus):not(:valid) ~ label.e-float-text,
  .e-bigger .e-small.e-outline.e-float-input:not(.e-valid-input) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-bigger .e-small.e-outline.e-float-input.e-control-wrapper:not(.e-valid-input) textarea:not(:focus):not(:valid):not(.e-valid-input) ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-small:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-error):not(.e-valid-input) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      line-height: $outline-textarea-small-bigger-label-line-height;
    }
  }

  .e-bigger.e-outline label.e-float-text::before,
  .e-bigger.e-outline label.e-float-text::after,
  .e-bigger.e-outline.e-float-input label.e-float-text::before,
  .e-bigger.e-outline.e-float-input label.e-float-text::after,
  .e-bigger.e-outline.e-float-input.e-control-wrapper label.e-float-text::before,
  .e-bigger.e-outline.e-float-input.e-control-wrapper label.e-float-text::after,
  .e-bigger .e-outline label.e-float-text::before,
  .e-bigger .e-outline label.e-float-text::after,
  .e-bigger .e-outline.e-float-input label.e-float-text::before,
  .e-bigger .e-outline.e-float-input label.e-float-text::after,
  .e-bigger .e-outline.e-float-input.e-control-wrapper label.e-float-text::before,
  .e-bigger .e-outline.e-float-input.e-control-wrapper label.e-float-text::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      min-width: $outline-bigger-label-min-width;
    }
  }

  .e-bigger.e-small.e-outline label.e-float-text::before,
  .e-bigger.e-small.e-outline label.e-float-text::after,
  .e-bigger.e-small.e-outline.e-float-input label.e-float-text::before,
  .e-bigger.e-small.e-outline.e-float-input label.e-float-text::after,
  .e-bigger.e-small.e-outline.e-float-input.e-control-wrapper label.e-float-text::before,
  .e-bigger.e-small.e-outline.e-float-input.e-control-wrapper label.e-float-text::after,
  .e-bigger.e-small .e-outline label.e-float-text::before,
  .e-bigger.e-small .e-outline label.e-float-text::after,
  .e-bigger.e-small .e-outline.e-float-input label.e-float-text::before,
  .e-bigger.e-small .e-outline.e-float-input label.e-float-text::after,
  .e-bigger.e-small .e-outline.e-float-input.e-control-wrapper label.e-float-text::before,
  .e-bigger.e-small .e-outline.e-float-input.e-control-wrapper label.e-float-text::after {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      min-width: $outline-bigger-small-label-min-width;
    }
  }

  .e-rtl .e-outline.e-input-group.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl .e-outline.e-input-group .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-rtl.e-outline.e-input-group .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-bigger .e-rtl.e-outline.e-input-group .e-input-group-icon + .e-input-group-icon:last-child,
  .e-bigger.e-rtl .e-outline.e-input-group .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group.e-small.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl .e-outline.e-input-group.e-small.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group.e-small .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-rtl .e-outline.e-input-group.e-small .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-rtl.e-outline.e-input-group.e-control-wrapper.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl .e-outline.e-input-group.e-control-wrapper.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group.e-control-wrapper .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-rtl .e-outline.e-input-group.e-control-wrapper .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-rtl.e-outline.e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl .e-outline.e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-rtl .e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-rtl.e-bigger .e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon + .e-input-group-icon:last-child,
  .e-bigger .e-rtl.e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-bigger .e-outline.e-input-group.e-small .e-input-group-icon + .e-input-group-icon:last-child .e-bigger .e-rtl.e-outline.e-input-group.e-small .e-input-group-icon + .e-input-group-icon:last-child {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-right: $zero-value;
    }
  }

  .e-rtl.e-outline.e-input-group.e-bigger .e-clear-icon,
  .e-rtl.e-outline.e-input-group.e-control-wrapper.e-bigger .e-clear-icon,
  .e-rtl.e-outline.e-input-group .e-clear-icon.e-bigger,
  .e-rtl.e-outline.e-input-group.e-control-wrapper .e-clear-icon.e-bigger,
  .e-bigger .e-rtl.e-outline.e-input-group .e-clear-icon,
  .e-bigger .e-rtl.e-outline.e-input-group.e-control-wrapper .e-clear-icon .e-rtl .e-outline.e-input-group.e-bigger .e-clear-icon,
  .e-rtl .e-outline.e-input-group.e-control-wrapper.e-bigger .e-clear-icon,
  .e-rtl .e-outline.e-input-group .e-clear-icon.e-bigger,
  .e-rtl .e-outline.e-input-group.e-control-wrapper .e-clear-icon.e-bigger,
  .e-bigger.e-rtl .e-outline.e-input-group .e-clear-icon,
  .e-bigger.e-rtl .e-outline.e-input-group.e-control-wrapper .e-clear-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      padding-left: $outline-bigger-input-icon-margin-left;
      padding-right: $zero-value;
    }
  }

  .e-rtl.e-outline.e-input-group.e-bigger .e-input-group-icon,
  .e-rtl.e-outline.e-input-group .e-input-group-icon.e-bigger,
  .e-bigger .e-rtl.e-outline.e-input-group .e-input-group-icon,
  .e-rtl.e-outline.e-input-group.e-control-wrapper.e-bigger .e-input-group-icon,
  .e-rtl.e-outline.e-input-group.e-control-wrapper .e-input-group-icon.e-bigger,
  .e-bigger .e-rtl.e-outline.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-rtl .e-outline.e-input-group.e-bigger .e-input-group-icon,
  .e-rtl .e-outline.e-input-group .e-input-group-icon.e-bigger,
  .e-bigger.e-rtl .e-outline.e-input-group .e-input-group-icon,
  .e-rtl .e-outline.e-input-group.e-control-wrapper.e-bigger .e-input-group-icon,
  .e-rtl .e-outline.e-input-group.e-control-wrapper .e-input-group-icon.e-bigger,
  .e-bigger.e-rtl .e-outline.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-rtl .e-outline.e-input-group.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl .e-outline.e-input-group .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-rtl.e-outline.e-input-group .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-bigger .e-rtl.e-outline.e-input-group .e-input-group-icon + .e-input-group-icon:last-child,
  .e-bigger.e-rtl .e-outline.e-input-group .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group.e-control-wrapper.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl .e-outline.e-input-group.e-control-wrapper.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group.e-control-wrapper .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-rtl .e-outline.e-input-group.e-control-wrapper .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-left: $outline-bigger-input-icon-margin-left;
      margin-right: $zero-value;
    }
  }

  .e-rtl.e-outline.e-input-group.e-small.e-bigger .e-clear-icon,
  .e-rtl .e-outline.e-input-group.e-small.e-bigger .e-clear-icon,
  .e-rtl.e-outline.e-input-group.e-small .e-clear-icon.e-bigger,
  .e-rtl .e-outline.e-input-group.e-small .e-clear-icon.e-bigger,
  .e-rtl.e-outline.e-input-group.e-control-wrapper.e-small.e-bigger .e-clear-icon,
  .e-rtl .e-outline.e-input-group.e-control-wrapper.e-small.e-bigger .e-clear-icon,
  .e-rtl.e-outline.e-input-group.e-control-wrapper.e-small .e-clear-icon.e-bigger,
  .e-rtl .e-outline.e-input-group.e-control-wrapper.e-small .e-clear-icon.e-bigger,
  .e-bigger.e-rtl .e-outline.e-input-group.e-small .e-clear-icon,
  .e-bigger .e-rtl.e-outline.e-input-group.e-small .e-clear-icon,
  .e-bigger .e-rtl.e-outline.e-input-group.e-control-wrapper.e-small .e-clear-icon,
  .e-bigger.e-rtl .e-outline.e-input-group.e-control-wrapper.e-small .e-clear-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      padding-left: $outline-small-bigger-icon-margin-left;
      padding-right: $zero-value;
    }
  }

  .e-rtl.e-outline.e-input-group.e-small.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl .e-outline.e-input-group.e-small.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group.e-small .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-rtl .e-outline.e-input-group.e-small .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-rtl.e-outline.e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl .e-outline.e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-rtl .e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger + .e-input-group-icon.e-bigger:last-child,
  .e-rtl.e-bigger .e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon + .e-input-group-icon:last-child,
  .e-bigger .e-rtl.e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon + .e-input-group-icon:last-child,
  .e-rtl.e-bigger .e-outline.e-input-group.e-small .e-input-group-icon + .e-input-group-icon:last-child .e-bigger,
  .e-rtl.e-outline.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-rtl .e-outline.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-rtl.e-outline.e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-rtl .e-outline.e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-rtl.e-outline.e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon,
  .e-rtl .e-outline.e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon,
  .e-rtl.e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger,
  .e-rtl .e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger,
  .e-bigger.e-rtl .e-outline.e-input-group.e-small .e-input-group-icon,
  .e-bigger .e-rtl.e-outline.e-input-group.e-small .e-input-group-icon,
  .e-bigger .e-rtl.e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon,
  .e-bigger.e-rtl .e-outline.e-input-group.e-control-wrapper.e-small .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      margin-left: $outline-small-bigger-icon-margin-left;
      margin-right: $zero-value;
    }
  }

  .e-outline.e-float-input.e-small.e-bigger label.e-float-text,
  .e-bigger .e-small .e-outline.e-float-input label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small label.e-float-text,
  .e-outline.e-float-input.e-bigger.e-small input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger.e-small input:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger.e-small input ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-bigger.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-bigger.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-bigger.e-small input label.e-float-text.e-label-top,
  .e-bigger .e-outline.e-float-input.e-small input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small input:valid ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small input ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small input label.e-float-text.e-label-top,
  .e-small .e-outline.e-float-input.e-bigger input:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger input:valid ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger input ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger input label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small input:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small input ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small input label.e-float-text.e-label-top,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small input:valid ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small input ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small input label.e-float-text.e-label-top,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger input:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger input:valid ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger input ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger input label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-bigger.e-small textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger.e-small textarea:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger.e-small textarea ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-bigger.e-small textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-bigger.e-small textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-bigger.e-small textarea label.e-float-text.e-label-top,
  .e-bigger .e-outline.e-float-input.e-small textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small textarea:valid ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small textarea label.e-float-text.e-label-top,
  .e-small .e-outline.e-float-input.e-bigger textarea:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger textarea:valid ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger textarea label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small textarea:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small textarea ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small textarea label.e-float-text.e-label-top,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small textarea:valid ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small textarea label.e-float-text.e-label-top,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger textarea:focus ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger textarea:valid ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger textarea label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-bigger.e-small.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small.e-input-focus input ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small.e-input-focus input ~ label.e-float-text,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      top: $outline-float-label-top;
      transform: translate3d(0, 0, 0) scale(1);
    }
  }

  .e-outline.e-float-input.e-bigger .e-clear-icon::before,
  .e-outline.e-float-input.e-control-wrapper.e-bigger .e-clear-icon::before,
  .e-outline.e-input-group.e-bigger .e-clear-icon::before,
  .e-outline.e-input-group.e-control-wrapper.e-bigger .e-clear-icon::before,
  .e-outline.e-float-input.e-control-wrapper input.e-bigger:first-child ~ .e-clear-icon::before,
  .e-outline.e-bigger .e-float-input.e-control-wrapper .e-clear-icon::before,
  .e-outline.e-float-input input.e-bigger:first-child ~ .e-clear-icon::before,
  .e-outline.e-bigger .e-float-input .e-clear-icon::before,
  .e-outline.e-bigger .e-input-group .e-clear-icon::before,
  .e-outline.e-bigger .e-input-group.e-control-wrapper .e-clear-icon::before {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $outline-input-bigger-clear-icon;
    }
  }

  .e-outline.e-float-input.e-small.e-bigger .e-clear-icon::before,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger .e-clear-icon::before,
  .e-outline.e-input-group.e-small.e-bigger .e-clear-icon::before,
  .e-outline.e-input-group.e-control-wrapper.e-small.e-bigger .e-clear-icon::before,
  .e-outline.e-small .e-input-group.e-bigger input:first-child ~ .e-clear-icon::before,
  .e-outline.e-small .e-input-group.e-control-wrapper.e-bigger input:first-child ~ .e-clear-icon::before,
  .e-outline.e-bigger .e-input-group.e-small input:first-child ~ .e-clear-icon::before,
  .e-outline.e-bigger .e-input-group.e-control-wrapper.e-small input:first-child ~ .e-clear-icon::before,
  .e-outline.e-small .e-float-input.e-control-wrapper.e-bigger input:first-child ~ .e-clear-icon::before,
  .e-outline.e-small .e-float-input.e-bigger input:first-child ~ .e-clear-icon::before,
  .e-outline.e-bigger .e-float-input.e-control-wrapper.e-small input:first-child ~ .e-clear-icon::before,
  .e-outline.e-bigger .e-float-input.e-small input:first-child ~ .e-clear-icon::before {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $outline-input-bigger-small-clear-icon;
    }
  }

  .e-outline.e-float-input.e-bigger textarea ~ label.e-float-text,
  .e-outline.e-float-input textarea ~ label.e-float-text.e-bigger,
  .e-outline.e-float-input textarea.e-bigger ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input textarea ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-bigger textarea ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper textarea ~ label.e-float-text.e-bigger,
  .e-outline.e-float-input.e-control-wrapper textarea.e-bigger ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper textarea ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-outline.e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-outline.e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-outline.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-bigger label.e-float-text,
  .e-outline.e-float-input label.e-float-text.e-bigger,
  .e-outline.e-float-input input.e-bigger ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input label.e-float-text,
  .e-outline.e-float-input.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-bigger label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper label.e-float-text.e-bigger,
  .e-outline.e-float-input.e-control-wrapper input.e-bigger ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-bigger textarea ~ label.e-float-text,
  .e-outline.e-float-input textarea ~ label.e-float-text.e-bigger,
  .e-outline.e-float-input textarea.e-bigger ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input textarea ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-bigger textarea ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper textarea ~ label.e-float-text.e-bigger,
  .e-outline.e-float-input.e-control-wrapper textarea.e-bigger ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper textarea ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $bigger-outline-float-label-font-size;
    }
  }

  .e-bigger .e-outline.e-float-input.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-outline.e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-outline.e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-small textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-outline.e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger.e-small textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-outline.e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger.e-small.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-small.e-bigger .e-outline.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger.e-outline.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-outline.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-outline.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-small.e-bigger label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-outline.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-outline.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-outline.e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-outline.e-float-input.e-control-wrapper.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-outline.e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $bigger-small-outline-float-label-font-size;
    }
  }

  .e-bigger .e-outline.e-float-input input:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-outline.e-float-input input:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-autofill input:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-outline.e-float-input.e-control-wrapper.e-autofill input:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-outline.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      animation-name: slideTopUp;/* stylelint-disable-line no-unknown-animations */
    }
  }

  .e-bigger .e-outline.e-float-input.e-small input:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-outline.e-float-input input:-webkit-autofill ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger.e-small input:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small.e-control-wrapper.e-autofill input:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-outline.e-float-input.e-control-wrapper.e-autofill input:-webkit-autofill ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger.e-small.e-control-wrapper.e-autofill input:-webkit-autofill ~ label.e-float-text,
  .e-small.e-bigger .e-outline.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger.e-outline.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-outline.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-outline.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      animation-name: slideTopUp;/* stylelint-disable-line no-unknown-animations */
    }
  }

  .e-filled input.e-input.e-bigger#{$css},
  .e-filled.e-input-group.e-bigger input,
  .e-filled.e-input-group.e-bigger input.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-bigger input,
  .e-filled.e-input-group.e-control-wrapper.e-bigger input.e-input,
  .e-bigger .e-filled.e-input-group input,
  .e-bigger .e-filled.e-input-group input.e-input,
  .e-bigger .e-filled.e-input-group.e-control-wrapper input,
  .e-bigger .e-filled.e-input-group.e-control-wrapper input.e-input,
  .e-filled.e-float-input.e-bigger input,
  .e-filled.e-float-input.e-bigger input.e-input,
  .e-bigger .e-filled.e-float-input input,
  .e-bigger .e-filled.e-float-input input.e-input,
  .e-filled.e-float-input.e-control-wrapper.e-bigger input,
  .e-filled.e-float-input.e-control-wrapper.e-bigger input.e-input,
  .e-bigger .e-filled.e-float-input.e-control-wrapper input,
  .e-bigger .e-filled.e-float-input.e-control-wrapper input.e-input,
  .e-filled.e-float-input.e-bigger:not(.e-input-group) input,
  .e-filled.e-float-input.e-bigger:not(.e-input-group) input.e-input,
  .e-bigger .e-filled.e-float-input:not(.e-input-group) input,
  .e-bigger .e-filled.e-float-input:not(.e-input-group) input.e-input,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-input-group) input,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-input-group) input.e-input,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-input-group) input,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-input-group) input.e-input,
  .e-filled input.e-input.e-small.e-bigger#{$css},
  .e-filled.e-input-group.e-bigger.e-small input,
  .e-filled.e-input-group.e-bigger.e-small input.e-input,
  .e-bigger.e-small .e-filled.e-input-group input,
  .e-bigger.e-small .e-filled.e-input-group input.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-bigger.e-small input,
  .e-filled.e-input-group.e-control-wrapper.e-bigger.e-small input.e-input,
  .e-bigger.e-small .e-filled.e-input-group.e-control-wrapper input,
  .e-bigger.e-small .e-filled.e-input-group.e-control-wrapper input.e-input,
  .e-filled.e-float-input.e-bigger.e-small input,
  .e-filled.e-float-input.e-bigger.e-small input.e-input,
  .e-bigger.e-small .e-filled.e-float-input input,
  .e-bigger.e-small .e-filled.e-float-input input.e-input,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small input,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small input.e-input,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper input,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper input.e-input,
  .e-filled.e-float-input.e-bigger.e-small:not(.e-input-group) input,
  .e-filled.e-float-input.e-bigger.e-small:not(.e-input-group) input.e-input,
  .e-bigger.e-small .e-filled.e-float-input:not(.e-input-group) input,
  .e-bigger.e-small .e-filled.e-float-input:not(.e-input-group) input.e-input,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-input-group) input,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-input-group) input.e-input,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper:not(.e-input-group) input,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper:not(.e-input-group) input.e-input,
  .e-filled.e-input-group textarea.e-input.e-bigger,
  .e-filled.e-input-group.e-control-wrapper textarea.e-input.e-bigger,
  .e-filled textarea.e-input.e-bigger#{$css},
  .e-filled.e-input-group.e-bigger textarea,
  .e-filled.e-input-group.e-bigger textarea.e-input,
  .e-bigger .e-filled.e-input-group textarea,
  .e-bigger .e-filled.e-input-group textarea.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-bigger textarea,
  .e-filled.e-input-group.e-control-wrapper.e-bigger textarea.e-input,
  .e-bigger .e-filled.e-input-group.e-control-wrapper textarea,
  .e-bigger .e-filled.e-input-group.e-control-wrapper textarea.e-input,
  .e-filled.e-float-input.e-bigger textarea,
  .e-filled.e-float-input.e-bigger textarea.e-input,
  .e-bigger .e-filled.e-float-input textarea,
  .e-bigger .e-filled.e-float-input textarea.e-input,
  .e-filled.e-float-input.e-control-wrapper.e-bigger textarea,
  .e-filled.e-float-input.e-control-wrapper.e-bigger textarea.e-input,
  .e-bigger .e-filled.e-float-input.e-control-wrapper textarea,
  .e-bigger .e-filled.e-float-input.e-control-wrapper textarea.e-input,
  .e-filled textarea.e-input.e-small.e-bigger#{$css},
  .e-filled.e-input-group.e-bigger.e-small textarea,
  .e-filled.e-input-group.e-bigger.e-small textarea.e-input,
  .e-bigger.e-small .e-filled.e-input-group textarea,
  .e-bigger.e-small .e-filled.e-input-group textarea.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-bigger.e-small textarea,
  .e-filled.e-input-group.e-control-wrapper.e-bigger.e-small textarea.e-input,
  .e-bigger.e-small .e-filled.e-input-group.e-control-wrapper textarea,
  .e-bigger.e-small .e-filled.e-input-group.e-control-wrapper textarea.e-input,
  .e-filled.e-float-input.e-bigger.e-small textarea,
  .e-filled.e-float-input.e-bigger.e-small textarea.e-input,
  .e-bigger.e-small .e-filled.e-float-input textarea,
  .e-bigger.e-small .e-filled.e-float-input textarea.e-input,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small textarea,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small textarea.e-input,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper textarea,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper textarea.e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      box-sizing: border-box;
    }
  }

  .e-filled.e-input-group.e-bigger,
  .e-bigger .e-filled.e-input-group,
  .e-filled.e-input-group.e-control-wrapper.e-bigger,
  .e-bigger .e-filled.e-input-group.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      margin-top: $filled-wrapper-margin;
      padding: $bigger-filled-wrapper-padding;
    }
  }

  .e-filled.e-input-group.e-small.e-bigger,
  .e-bigger.e-small .e-filled.e-input-group.e-small,
  .e-bigger .e-filled.e-input-group.e-small,
  .e-small .e-filled.e-input-group.e-bigger,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger,
  .e-bigger.e-small .e-filled.e-input-group.e-control-wrapper.e-small,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-small,
  .e-small .e-filled.e-input-group.e-control-wrapper.e-bigger {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      margin-top: $filled-wrapper-margin;
      padding: $bigger-small-filled-wrapper-padding;
    }
  }

  .e-filled.e-float-input.e-bigger,
  .e-bigger .e-filled.e-float-input,
  .e-filled.e-float-input.e-control-wrapper.e-bigger,
  .e-bigger .e-filled.e-float-input.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      margin-top: $filled-wrapper-margin;
      padding: $bigger-filled-float-input-wrapper-padding;
    }
  }

  .e-filled.e-float-input.e-small.e-bigger,
  .e-bigger.e-small .e-filled.e-float-input.e-small,
  .e-bigger .e-filled.e-float-input.e-small,
  .e-small .e-filled.e-float-input.e-bigger,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper.e-small,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      margin-top: $filled-wrapper-margin;
      padding: $bigger-small-filled-float-input-wrapper-padding;
    }
  }

  .e-rtl.e-filled.e-input-group.e-bigger,
  .e-bigger .e-rtl.e-filled.e-input-group,
  .e-rtl.e-filled.e-input-group.e-control-wrapper.e-bigger,
  .e-bigger .e-rtl.e-filled.e-input-group.e-control-wrapper,
  .e-rtl .e-filled.e-input-group.e-bigger,
  .e-bigger.e-rtl .e-filled.e-input-group,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-bigger,
  .e-bigger.e-rtl .e-filled.e-input-group.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $bigger-filled-wrapper-rtl-padding;
    }
  }

  .e-rtl.e-filled.e-input-group.e-small.e-bigger,
  .e-bigger.e-small .e-rtl.e-filled.e-input-group.e-small,
  .e-bigger .e-rtl.e-filled.e-input-group.e-small,
  .e-small .e-rtl.e-filled.e-input-group.e-bigger,
  .e-rtl.e-filled.e-input-group.e-control-wrapper.e-small.e-bigger,
  .e-bigger.e-small .e-rtl.e-filled.e-input-group.e-control-wrapper.e-small,
  .e-bigger .e-rtl.e-filled.e-input-group.e-control-wrapper.e-small,
  .e-small .e-rtl.e-filled.e-input-group.e-control-wrapper.e-bigger,
  .e-rtl .e-filled.e-input-group.e-small.e-bigger,
  .e-bigger.e-small.e-rtl .e-filled.e-input-group.e-small,
  .e-bigger.e-rtl .e-filled.e-input-group.e-small,
  .e-small.e-rtl .e-filled.e-input-group.e-bigger,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger,
  .e-bigger.e-small.e-rtl .e-filled.e-input-group.e-control-wrapper.e-small,
  .e-bigger.e-rtl .e-filled.e-input-group.e-control-wrapper.e-small,
  .e-small.e-rtl .e-filled.e-input-group.e-control-wrapper.e-bigger {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $bigger-small-filled-wrapper-rtl-padding;
    }
  }

  .e-rtl.e-filled.e-float-input.e-bigger,
  .e-bigger .e-rtl.e-filled.e-float-input,
  .e-rtl.e-filled.e-float-input.e-control-wrapper.e-bigger,
  .e-bigger .e-rtl.e-filled.e-float-input.e-control-wrapper,
  .e-rtl .e-filled.e-float-input.e-bigger,
  .e-bigger.e-rtl .e-filled.e-float-input,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-bigger,
  .e-bigger.e-rtl .e-filled.e-float-input.e-control-wrapper {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $bigger-filled-float-input-wrapper-rtl-padding;
    }
  }

  .e-rtl.e-filled.e-float-input.e-small.e-bigger,
  .e-bigger.e-small .e-rtl.e-filled.e-float-input.e-small,
  .e-bigger .e-rtl.e-filled.e-float-input.e-small,
  .e-small .e-rtl.e-filled.e-float-input.e-bigger,
  .e-rtl.e-filled.e-float-input.e-control-wrapper.e-small.e-bigger,
  .e-bigger.e-small .e-rtl.e-filled.e-float-input.e-control-wrapper.e-small,
  .e-bigger .e-rtl.e-filled.e-float-input.e-control-wrapper.e-small,
  .e-small .e-rtl.e-filled.e-float-input.e-control-wrapper.e-bigger,
  .e-rtl .e-filled.e-float-input.e-small.e-bigger,
  .e-bigger.e-small.e-rtl .e-filled.e-float-input.e-small,
  .e-bigger.e-rtl .e-filled.e-float-input.e-small,
  .e-small.e-rtl .e-filled.e-float-input.e-bigger,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger,
  .e-bigger.e-small.e-rtl .e-filled.e-float-input.e-control-wrapper.e-small,
  .e-bigger.e-rtl .e-filled.e-float-input.e-control-wrapper.e-small,
  .e-small.e-rtl .e-filled.e-float-input.e-control-wrapper.e-bigger {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $bigger-small-filled-float-input-wrapper-rtl-padding;
    }
  }

  .e-filled input.e-input.e-bigger#{$css},
  .e-filled textarea.e-input.e-bigger#{$css},
  .e-filled.e-input-group.e-bigger .e-input,
  .e-filled.e-input-group.e-bigger.e-control-wrapper .e-input,
  .e-bigger .e-filled input.e-input#{$css},
  .e-bigger .e-filled textarea.e-input#{$css},
  .e-bigger .e-filled.e-input-group .e-input,
  .e-bigger .e-filled.e-input-group.e-control-wrapper .e-input,
  .e-filled.e-input-group.e-bigger .e-input:focus,
  .e-bigger .e-filled.e-input-group .e-input:focus,
  .e-filled.e-input-group.e-control-wrapper.e-bigger .e-input:focus,
  .e-bigger .e-filled.e-input-group.e-control-wrapper .e-input:focus,
  .e-filled.e-input-group.e-bigger.e-input-focus .e-input,
  .e-bigger .e-filled.e-input-group.e-input-focus .e-input,
  .e-filled.e-input-group.e-control-wrapper.e-bigger.e-input-focus .e-input,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-input-focus .e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-bigger-input-padding;
    }
  }

  .e-filled .e-input.e-bigger#{$css}:focus,
  .e-bigger .e-filled .e-input#{$css}:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding-bottom: $filled-bigger-input-padding-bottom;
    }
  }

  .e-filled .e-input.e-small.e-bigger#{$css}:focus,
  .e-bigger .e-filled .e-input.e-small#{$css}:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding-bottom: $filled-bigger-small-input-padding-bottom;
    }
  }

  .e-filled .e-input.e-small.e-bigger#{$css},
  .e-bigger .e-filled .e-input#{$css}.e-small,
  .e-small .e-filled .e-input#{$css}.e-bigger,
  .e-filled.e-input-group.e-small.e-bigger .e-input,
  .e-filled.e-input-group.e-small .e-input.e-bigger,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger .e-input,
  .e-filled.e-input-group.e-control-wrapper.e-small .e-input.e-bigger,
  .e-bigger .e-filled.e-input-group.e-small .e-input,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-small .e-input,
  .e-filled.e-input-group.e-small.e-bigger .e-input:focus,
  .e-filled.e-input-group.e-small .e-input.e-bigger:focus,
  .e-bigger .e-filled.e-input-group.e-small .e-input:focus,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger .e-input:focus,
  .e-filled.e-input-group.e-control-wrapper.e-small .e-input.e-bigger:focus,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-small .e-input:focus,
  .e-filled.e-input-group.e-small.e-bigger.e-input-focus .e-input,
  .e-filled.e-input-group.e-small.e-input-focus .e-input.e-bigger,
  .e-bigger .e-filled.e-input-group.e-small.e-input-focus .e-input,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger.e-input-focus .e-input,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-input-focus .e-input.e-bigger,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-small.e-input-focus .e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-bigger-small-input-padding;
    }
  }

  .e-filled.e-float-input.e-bigger input,
  .e-filled.e-float-input input.e-bigger,
  .e-bigger .e-filled.e-float-input input,
  .e-filled.e-float-input.e-control-wrapper.e-bigger input,
  .e-filled.e-float-input.e-control-wrapper input.e-bigger,
  .e-bigger .e-filled.e-float-input.e-control-wrapper input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-bigger-float-input-padding;
    }
  }

  .e-filled.e-float-input.e-small.e-bigger input,
  .e-filled.e-float-input.e-small input.e-bigger,
  .e-bigger .e-filled.e-float-input.e-small input,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger input,
  .e-filled.e-float-input.e-control-wrapper.e-small input.e-bigger,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-bigger-small-float-input-padding;
    }
  }

  .e-rtl .e-filled input.e-input.e-bigger#{$css},
  .e-filled input.e-input.e-bigger.e-rtl#{$css},
  .e-bigger.e-rtl .e-filled input.e-input#{$css},
  .e-bigger .e-filled input.e-input.e-rtl#{$css},
  .e-filled.e-input-group.e-bigger.e-rtl input.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-bigger.e-rtl input.e-input,
  .e-rtl .e-filled.e-input-group.e-bigger input.e-input,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-bigger input.e-input,
  .e-filled.e-input-group.e-rtl input.e-input.e-bigger,
  .e-filled.e-input-group.e-control-wrapper.e-rtl input.e-input.e-bigger,
  .e-rtl .e-filled.e-input-group input.e-input.e-bigger,
  .e-rtl .e-filled.e-input-group.e-control-wrapper input.e-input.e-bigger,
  .e-bigger .e-filled.e-input-group.e-rtl input.e-input,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-rtl input.e-input,
  .e-bigger.e-rtl .e-filled.e-input-group input.e-input,
  .e-bigger.e-rtl .e-filled.e-input-group.e-control-wrapper input.e-input,
  .e-bigger.e-rtl .e-filled.e-input-group input.e-input:focus,
  .e-bigger.e-rtl .e-filled.e-input-group.e-control-wrapper input.e-input:focus,
  .e-bigger .e-filled.e-input-group.e-rtl input.e-input:focus,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-rtl input.e-input:focus,
  .e-bigger.e-rtl .e-filled.e-input-group.e-input-focus input.e-input,
  .e-bigger.e-rtl .e-filled.e-input-group.e-control-wrapper.e-input-focus input.e-input,
  .e-bigger .e-filled.e-input-group.e-rtl.e-input-focus input.e-input,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-rtl.e-input-focus input.e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-bigger-input-rtl-padding;
      text-indent: 0;
    }
  }

  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-rtl input,
  .e-filled.e-float-input.e-bigger.e-rtl input,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-bigger input,
  .e-rtl .e-filled.e-float-input.e-bigger input,
  .e-filled.e-float-input.e-control-wrapper.e-rtl input.e-bigger,
  .e-filled.e-float-input.e-rtl input.e-bigger,
  .e-rtl .e-filled.e-float-input.e-control-wrapper input.e-bigger,
  .e-rtl .e-filled.e-float-input input.e-bigger,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-rtl input,
  .e-bigger .e-filled.e-float-input.e-rtl input,
  .e-bigger.e-rtl .e-filled.e-float-input.e-control-wrapper input,
  .e-bigger.e-rtl .e-filled.e-float-input input,
  .e-bigger.e-rtl .e-filled.e-float-input.e-control-wrapper input:focus,
  .e-bigger.e-rtl .e-filled.e-float-input input:focus,
  .e-bigger .e-filled.e-float-input.e-rtl input:focus,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-rtl input:focus,
  .e-bigger.e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-focus input,
  .e-bigger.e-rtl .e-filled.e-float-input.e-input-focus input,
  .e-bigger .e-filled.e-float-input.e-rtl.e-input-focus input,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-rtl input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-bigger-float-input-rtl-padding;
      text-indent: 0;
    }
  }

  .e-bigger .e-filled input.e-input.e-small.e-rtl#{$css},
  .e-filled.e-input-group.e-small.e-bigger.e-rtl input.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger.e-rtl input.e-input,
  .e-filled.e-input-group.e-small.e-rtl input.e-input.e-bigger,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-rtl input.e-input.e-bigger,
  .e-bigger .e-filled.e-input-group.e-small.e-rtl input.e-input,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-small.e-rtl input.e-input,
  .e-bigger.e-rtl input.e-input.e-small,
  .e-rtl .e-filled.e-input-group.e-small.e-bigger input.e-input,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger input.e-input,
  .e-rtl .e-filled.e-input-group.e-small input.e-input.e-bigger,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-small input.e-input.e-bigger,
  .e-bigger.e-rtl .e-filled.e-input-group.e-small input.e-input,
  .e-bigger.e-rtl .e-filled.e-input-group.e-control-wrapper.e-small input.e-input,
  .e-bigger .e-filled.e-input-group.e-small.e-rtl input.e-input:focus,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-small.e-rtl input.e-input:focus,
  .e-small .e-filled.e-input-group.e-bigger.e-rtl input.e-input:focus,
  .e-small .e-filled.e-input-group.e-control-wrapper.e-bigger.e-rtl input.e-input:focus,
  .e-filled.e-input-group.e-bigger.e-small.e-rtl input.e-input:focus,
  .e-filled.e-input-group.e-control-wrapper.e-bigger.e-small.e-rtl input.e-input:focus,
  .e-bigger.e-rtl .e-filled.e-input-group.e-small input.e-input:focus,
  .e-bigger.e-rtl .e-filled.e-input-group.e-control-wrapper.e-small input.e-input:focus,
  .e-small.e-rtl .e-filled.e-input-group.e-bigger input.e-input:focus,
  .e-small.e-rtl .e-filled.e-input-group.e-control-wrapper.e-bigger input.e-input:focus,
  .e-rtl .e-filled.e-input-group.e-bigger.e-small input.e-input:focus,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-bigger.e-small input.e-input:focus,
  .e-bigger .e-filled.e-input-group.e-small.e-rtl.e-input-focus input.e-input,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-small.e-rtl.e-input-focus input.e-input,
  .e-small .e-filled.e-input-group.e-bigger.e-rtl.e-input-focus input.e-input,
  .e-small .e-filled.e-input-group.e-control-wrapper.e-bigger.e-rtl.e-input-focus input.e-input,
  .e-filled.e-input-group.e-bigger.e-small.e-rtl.e-input-focus input.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-bigger.e-small.e-rtl.e-input-focus input.e-input,
  .e-bigger.e-rtl .e-filled.e-input-group.e-small.e-input-focus input.e-input,
  .e-bigger.e-rtl .e-filled.e-input-group.e-control-wrapper.e-small.e-input-focus input.e-input,
  .e-small.e-rtl .e-filled.e-input-group.e-bigger.e-input-focus input.e-input,
  .e-small.e-rtl .e-filled.e-input-group.e-control-wrapper.e-bigger.e-input-focus input.e-input,
  .e-rtl .e-filled.e-input-group.e-bigger.e-small.e-input-focus input.e-input,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-bigger.e-small.e-input-focus input.e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-bigger-small-input-rtl-padding;
      text-indent: 0;
    }
  }

  .e-filled.e-float-input.e-small.e-bigger.e-rtl input,
  .e-filled.e-float-input.e-small.e-rtl input.e-bigger,
  .e-bigger .e-filled.e-float-input.e-small.e-rtl input,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-rtl input,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-rtl input.e-bigger,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-rtl input,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger input,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-small input.e-bigger,
  .e-bigger.e-rtl .e-filled.e-float-input.e-control-wrapper.e-small input,
  .e-rtl .e-filled.e-float-input.e-small.e-bigger input,
  .e-rtl .e-filled.e-float-input.e-small input.e-bigger,
  .e-bigger.e-rtl .e-filled.e-float-input.e-small input,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-rtl input:focus,
  .e-bigger .e-filled.e-float-input.e-small.e-rtl input:focus,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger.e-rtl input:focus,
  .e-small .e-filled.e-float-input.e-bigger.e-rtl input:focus,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small.e-rtl input:focus,
  .e-filled.e-float-input.e-bigger.e-small.e-rtl input:focus,
  .e-bigger.e-rtl .e-filled.e-float-input.e-control-wrapper.e-small input:focus,
  .e-bigger.e-rtl .e-filled.e-float-input.e-small input:focus,
  .e-small.e-rtl .e-filled.e-float-input.e-control-wrapper.e-bigger input:focus,
  .e-small.e-rtl .e-filled.e-float-input.e-bigger input:focus,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small input:focus,
  .e-rtl .e-filled.e-float-input.e-bigger.e-small input:focus,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-rtl.e-input-focus input,
  .e-bigger .e-filled.e-float-input.e-small.e-rtl.e-input-focus input,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger.e-rtl.e-input-focus input,
  .e-small .e-filled.e-float-input.e-bigger.e-rtl.e-input-focus input,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small.e-rtl.e-input-focus input,
  .e-filled.e-float-input.e-bigger.e-small.e-rtl.e-input-focus input,
  .e-bigger.e-rtl .e-filled.e-float-input.e-control-wrapper.e-small.e-input-focus input,
  .e-bigger.e-rtl .e-filled.e-float-input.e-small.e-input-focus input,
  .e-small.e-rtl .e-filled.e-float-input.e-control-wrapper.e-bigger.e-input-focus input,
  .e-small.e-rtl .e-filled.e-float-input.e-bigger.e-input-focus input,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small.e-input-focus input,
  .e-rtl .e-filled.e-float-input.e-bigger.e-small.e-input-focus input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-bigger-small-float-input-rtl-padding;
      text-indent: 0;
    }
  }

  .e-filled.e-float-input.e-bigger,
  .e-bigger .e-filled.e-float-input,
  .e-filled.e-float-input.e-control-wrapper.e-bigger,
  .e-bigger .e-filled.e-float-input.e-control-wrapper,
  .e-filled.e-float-input.e-bigger.e-disabled,
  .e-bigger .e-filled.e-float-input.e-disabled,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-disabled,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-disabled,
  .e-filled.e-float-input.e-input-group.e-bigger.e-disabled,
  .e-bigger .e-filled.e-float-input.e-input-group.e-disabled,
  .e-filled.e-float-input.e-input-group.e-control-wrapper.e-bigger.e-disabled,
  .e-bigger .e-filled.e-float-input.e-input-group.e-control-wrapper.e-disabled {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $bigger-filled-input-font-size;
    }
  }

  .e-filled.e-float-input.e-small.e-bigger,
  .e-small.e-bigger .e-filled.e-float-input,
  .e-bigger .e-filled.e-float-input.e-small,
  .e-small .e-filled.e-float-input.e-bigger,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger,
  .e-small.e-bigger .e-filled.e-float-input.e-control-wrapper,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger,
  .e-filled.e-float-input.e-small.e-bigger.e-disabled,
  .e-small.e-bigger .e-filled.e-float-input.e-disabled,
  .e-bigger .e-filled.e-float-input.e-small.e-disabled,
  .e-small .e-filled.e-float-input.e-bigger.e-disabled,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-disabled,
  .e-small.e-bigger .e-filled.e-float-input.e-control-wrapper.e-disabled,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-disabled,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger.e-disabled,
  .e-filled.e-float-input.e-input-group.e-small.e-bigger.e-disabled,
  .e-small.e-bigger .e-filled.e-float-input.e-input-group.e-disabled,
  .e-bigger .e-filled.e-float-input.e-input-group.e-small.e-disabled,
  .e-small .e-filled.e-float-input.e-input-group.e-bigger.e-disabled,
  .e-filled.e-float-input.e-input-group.e-control-wrapper.e-small.e-bigger.e-disabled,
  .e-small.e-bigger .e-filled.e-float-input.e-input-group.e-control-wrapper.e-disabled,
  .e-bigger .e-filled.e-float-input.e-input-group.e-control-wrapper.e-small.e-disabled,
  .e-small .e-filled.e-float-input.e-input-group.e-control-wrapper.e-bigger.e-disabled {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $bigger-small-filled-input-font-size;
    }
  }

  .e-filled.e-input-group.e-bigger:not(.e-float-input) .e-input,
  .e-bigger .e-filled.e-input-group:not(.e-float-input) .e-input,
  .e-filled.e-input-group.e-control-wrapper.e-bigger:not(.e-float-input) .e-input,
  .e-bigger .e-filled.e-input-group.e-control-wrapper:not(.e-float-input) .e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      min-height: $bigger-filled-default-input-min-height;
    }
  }

  .e-filled.e-float-input.e-bigger input,
  .e-bigger .e-filled.e-float-input input,
  .e-filled.e-float-input.e-input-group.e-bigger input,
  .e-bigger .e-filled.e-float-input.e-input-group input,
  .e-filled.e-float-input.e-input-group.e-control-wrapper.e-bigger input,
  .e-bigger .e-filled.e-float-input.e-input-group.e-control-wrapper input,
  .e-filled.e-float-input.e-control-wrapper.e-bigger input,
  .e-bigger .e-filled.e-float-input.e-control-wrapper input,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-bigger input,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-input-group input,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-bigger input,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-input-group input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      min-height: $bigger-filled-input-min-height;
    }
  }

  .e-filled.e-input-group.e-bigger.e-small:not(.e-float-input) .e-input,
  .e-bigger .e-filled.e-input-group.e-small:not(.e-float-input) .e-input .e-small .e-filled.e-input-group.e-bigger:not(.e-float-input) .e-input,
  .e-filled.e-input-group.e-control-wrapper.e-bigger.e-small:not(.e-float-input) .e-input,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-small:not(.e-float-input) .e-input .e-small .e-filled.e-input-group.e-control-wrapper.e-bigger:not(.e-float-input) .e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      min-height: $bigger-small-filled-default-input-min-height;
    }
  }

  .e-float-input.e-bigger.e-small.e-filled input,
  .e-bigger .e-float-input.e-filled.e-small input,
  .e-small .e-float-input.e-filled.e-bigger input,
  .e-float-input.e-filled.e-input-group.e-bigger.e-small input,
  .e-bigger .e-float-input.e-filled.e-input-group.e-small input,
  .e-small .e-float-input.e-filled.e-input-group.e-bigger input,
  .e-float-input.e-filled.e-input-group.e-control-wrapper.e-bigger.e-small input,
  .e-bigger .e-float-input.e-filled.e-input-group.e-control-wrapper.e-small input,
  .e-small .e-float-input.e-filled.e-input-group.e-control-wrapper.e-bigger input,
  .e-float-input.e-filled.e-control-wrapper.e-bigger.e-small input,
  .e-bigger .e-float-input.e-filled.e-control-wrapper.e-small input,
  .e-small .e-float-input.e-filled.e-control-wrapper.e-bigger input,
  .e-float-input.e-control-wrapper.e-filled.e-input-group.e-bigger.e-small input,
  .e-bigger .e-float-input.e-control-wrapper.e-filled.e-input-group.e-small input,
  .e-small .e-float-input.e-control-wrapper.e-filled.e-input-group.e-bigger input  {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      min-height: $bigger-small-filled-input-min-height;
    }
  }

  .e-filled.e-float-input.e-bigger input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper textarea ~ label.e-label-top.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $bigger-filled-float-label-font-size;
      top: $bigger-filled-input-label-top-after-floating;
    }
    @if $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      left: 2px;
    }
  }

  .e-bigger .e-filled.e-float-input input:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-filled.e-float-input input:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-autofill input:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-filled.e-float-input.e-control-wrapper.e-autofill input:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-filled.e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-filled.e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-filled.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $bigger-filled-float-label-font-size;
      top: $bigger-filled-input-label-top-after-floating;
      transform: translateY(-50%) scale(.75);
      user-select: text;
    }
  }

  .e-filled.e-float-input.e-small.e-bigger input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger input[readonly] ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger input[disabled] ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small input[readonly] ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger input[readonly] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger input[disabled] ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small input[readonly] ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small textarea ~ label.e-label-top.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $bigger-small-filled-float-label-font-size;
      top: $bigger-small-filled-input-label-top-after-floating;
    }
  }

  .e-bigger .e-filled.e-float-input.e-small input:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-filled.e-float-input input:-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small input:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-control-wrapper.e-autofill input:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper.e-autofill input:-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small.e-control-wrapper.e-autofill input:-webkit-autofill ~ label.e-float-text,
  .e-small.e-bigger .e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger.e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-input-focus) input:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-small textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-filled.e-float-input textarea:-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
  .e-small.e-bigger .e-filled.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger.e-filled.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-filled.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-filled.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $bigger-small-filled-float-label-font-size;
      top: $bigger-small-filled-input-label-top-after-floating;
      transform: translateY(-50%) scale(.75);
      user-select: text;
    }
  }

  .e-filled.e-float-input.e-bigger label.e-float-text,
  .e-filled.e-float-input label.e-float-text.e-bigger,
  .e-filled.e-float-input input.e-bigger ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input label.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-bigger label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper label.e-float-text.e-bigger,
  .e-filled.e-float-input.e-control-wrapper input.e-bigger ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-bigger textarea ~ label.e-float-text,
  .e-filled.e-float-input textarea ~ label.e-float-text.e-bigger,
  .e-filled.e-float-input textarea.e-bigger ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input textarea ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-bigger textarea ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper textarea ~ label.e-float-text.e-bigger,
  .e-filled.e-float-input.e-control-wrapper textarea.e-bigger ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper textarea ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $bigger-filled-float-label-font-size;
      top: $bigger-filled-input-label-top;
    }
  }

  .e-filled.e-float-input.e-small.e-bigger label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-filled.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $bigger-small-filled-float-label-font-size;
      top: $bigger-small-filled-input-label-top;
      transform: none;
    }
  }

  .e-filled.e-float-input.e-bigger.e-small input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small input label.e-float-text.e-label-top,
  .e-bigger .e-filled.e-float-input.e-small input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small input label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input.e-bigger input:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-bigger input:valid ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-bigger input ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-bigger input label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small input label.e-float-text.e-label-top,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small input label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger input:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger input:valid ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger input ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger input label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-bigger.e-small.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-input-focus input ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-input-focus input ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger.e-input-focus input ~ label.e-float-text,
  .e-float-input.e-filled.e-bigger.e-small textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small textarea label.e-float-text.e-label-top,
  .e-bigger .e-filled.e-float-input.e-small textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small textarea label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input.e-bigger textarea:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-bigger textarea:valid ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-bigger textarea label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small textarea label.e-float-text.e-label-top,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small textarea label.e-float-text.e-label-top,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger textarea:focus ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger textarea:valid ~ label.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger textarea label.e-float-text.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      transform: translateY(-50%) scale(.75);
    }
  }

  .e-bigger .e-filled.e-float-input.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-filled.e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $bigger-small-filled-float-label-font-size;
      top: $bigger-small-filled-input-label-top;
    }
  }

  .e-filled.e-float-input.e-bigger label.e-float-text,
  .e-filled.e-float-input.e-bigger label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-bigger label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input label.e-float-text,
  .e-bigger .e-filled.e-float-input label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-bigger:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      left: $bigger-filled-input-label-left;
    }
  }

  .e-filled.e-float-input.e-bigger.e-rtl label.e-float-text,
  .e-filled.e-float-input.e-rtl.e-bigger label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input.e-bigger label.e-float-text .e-filled.e-float-input.e-control-wrapper.e-rtl.e-bigger label.e-float-text,
  .e-filled.e-float-input.e-rtl.e-control-wrapper.e-bigger label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-bigger label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-rtl label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-rtl label.e-float-text.e-label-bottom,
  .e-rtl.e-bigger .e-filled.e-float-input label.e-float-text .e-bigger .e-filled.e-float-input.e-control-wrapper.e-rtl label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-rtl.e-control-wrapper label.e-float-text.e-label-bottom,
  .e-rtl.e-bigger .e-filled.e-float-input.e-control-wrapper label.e-float-text,
  .e-bigger.e-filled.e-float-input.e-rtl:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-bigger.e-filled.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-bigger.e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-rtl:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-bigger .e-filled.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-filled.e-float-input.e-rtl:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-bigger.e-filled.e-float-input:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-bigger.e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-rtl:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-bigger .e-filled.e-float-input:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      right: $bigger-filled-input-label-left;
    }
  }

  .e-small .e-filled.e-float-input.e-bigger label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      left: $small-filled-input-label-left;
    }
  }

  .e-filled.e-float-input.e-small.e-bigger label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger .e-filled.e-float-input label.e-float-text,
  .e-small.e-bigger .e-filled.e-float-input label.e-float-text.e-label-bottom,
  .e-small.e-bigger .e-filled.e-float-input.e-control-wrapper label.e-float-text,
  .e-small.e-bigger .e-filled.e-float-input.e-control-wrapper label.e-float-text.e-label-bottom,
  .e-small.e-bigger .e-filled.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger .e-filled.e-float-input:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      left: $bigger-small-filled-input-label-left;
    }
  }

  .e-filled.e-float-input.e-small.e-bigger.e-rtl label.e-float-text,
  .e-filled.e-float-input.e-rtl.e-small.e-bigger label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input.e-small.e-bigger label.e-float-text .e-filled.e-float-input.e-control-wrapper.e-rtl.e-small.e-bigger label.e-float-text,
  .e-filled.e-float-input.e-rtl.e-control-wrapper.e-small.e-bigger label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger label.e-float-text,
  .e-small.e-bigger .e-filled.e-float-input.e-rtl label.e-float-text,
  .e-small.e-bigger .e-filled.e-float-input.e-rtl label.e-float-text.e-label-bottom,
  .e-rtl.e-small.e-bigger .e-filled.e-float-input label.e-float-text .e-small.e-bigger .e-filled.e-float-input.e-control-wrapper.e-rtl label.e-float-text,
  .e-small.e-bigger .e-filled.e-float-input.e-rtl.e-control-wrapper label.e-float-text.e-label-bottom,
  .e-rtl.e-small.e-bigger .e-filled.e-float-input.e-control-wrapper label.e-float-text,
  .e-small.e-bigger.e-filled.e-float-input.e-rtl:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger.e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-small.e-bigger.e-filled.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-small.e-bigger.e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-filled.e-float-input.e-rtl:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-filled.e-float-input.e-rtl:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-small.e-bigger .e-filled.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-small.e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger.e-filled.e-float-input.e-rtl:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger.e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-small.e-bigger.e-filled.e-float-input:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-small.e-bigger.e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-filled.e-float-input.e-rtl:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-filled.e-float-input.e-rtl:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-small.e-bigger .e-filled.e-float-input:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-small.e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      right: $bigger-small-filled-input-label-left;
    }
  }

  .e-filled.e-float-input.e-bigger.e-rtl .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-rtl.e-bigger .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input.e-bigger .e-input-in-wrap label.e-float-text .e-filled.e-float-input.e-control-wrapper.e-rtl.e-bigger .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-rtl.e-control-wrapper.e-bigger .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-bigger .e-input-in-wrap label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-rtl .e-input-in-wrap label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-rtl .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-rtl.e-bigger .e-filled.e-float-input .e-input-in-wrap label.e-float-text .e-bigger .e-filled.e-float-input.e-control-wrapper.e-rtl .e-input-in-wrap label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-rtl.e-control-wrapper .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-rtl.e-bigger .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text,
  .e-bigger.e-filled.e-float-input.e-rtl:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-bigger.e-filled.e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-bigger.e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-rtl:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-bigger .e-filled.e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small.e-bigger.e-rtl .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-rtl.e-small.e-bigger .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input.e-small.e-bigger .e-input-in-wrap label.e-float-text .e-filled.e-float-input.e-control-wrapper.e-rtl.e-small.e-bigger .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-rtl.e-control-wrapper.e-small.e-bigger .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger .e-input-in-wrap label.e-float-text,
  .e-small.e-bigger .e-filled.e-float-input.e-rtl .e-input-in-wrap label.e-float-text,
  .e-small.e-bigger .e-filled.e-float-input.e-rtl .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-rtl.e-small.e-bigger .e-filled.e-float-input .e-input-in-wrap label.e-float-text .e-small.e-bigger .e-filled.e-float-input.e-control-wrapper.e-rtl .e-input-in-wrap label.e-float-text,
  .e-small.e-bigger .e-filled.e-float-input.e-rtl.e-control-wrapper .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-rtl.e-small.e-bigger .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text,
  .e-small.e-bigger.e-filled.e-float-input.e-rtl:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger.e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-small.e-bigger.e-filled.e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl .e-small.e-bigger.e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-filled.e-float-input.e-rtl:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-bigger.e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-filled.e-float-input.e-rtl:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-small.e-filled.e-float-input.e-control-wrapper.e-rtl:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-small.e-bigger .e-filled.e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-rtl.e-small.e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      right: $zero-value;
    }
  }

  .e-filled.e-input-group.e-bigger .e-input-group-icon,
  .e-filled.e-input-group .e-input-group-icon.e-bigger,
  .e-filled.e-input-group.e-control-wrapper.e-bigger .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-input-group .e-input-group-icon,
  .e-bigger .e-filled.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-filled.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-filled.e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-input-group.e-small .e-input-group-icon,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-small .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $filled-input-icon-size;
      margin-bottom: $zero-value;
      margin-top: $zero-value;
      min-height: $filled-input-icon-min-height;
      min-width: $filled-input-icon-min-height;
      padding: $zero-value $zero-value $zero-value 8px;
    }
  }

  .e-rtl.e-filled.e-input-group.e-bigger .e-input-group-icon,
  .e-rtl.e-filled.e-input-group .e-input-group-icon.e-bigger,
  .e-rtl.e-filled.e-input-group.e-control-wrapper.e-bigger .e-input-group-icon,
  .e-rtl.e-filled.e-input-group.e-control-wrapper .e-input-group-icon.e-bigger,
  .e-bigger .e-rtl.e-filled.e-input-group .e-input-group-icon,
  .e-bigger .e-rtl.e-filled.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-rtl.e-filled.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-rtl.e-filled.e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-rtl.e-filled.e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon,
  .e-rtl.e-filled.e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger,
  .e-bigger .e-rtl.e-filled.e-input-group.e-small .e-input-group-icon,
  .e-bigger .e-rtl.e-filled.e-input-group.e-control-wrapper.e-small .e-input-group-icon,
  .e-rtl .e-filled.e-input-group.e-bigger .e-input-group-icon,
  .e-rtl .e-filled.e-input-group .e-input-group-icon.e-bigger,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-bigger .e-input-group-icon,
  .e-rtl .e-filled.e-input-group.e-control-wrapper .e-input-group-icon.e-bigger,
  .e-bigger.e-rtl .e-filled.e-input-group .e-input-group-icon,
  .e-bigger.e-rtl .e-filled.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-rtl .e-filled.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-rtl .e-filled.e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger,
  .e-bigger.e-rtl .e-filled.e-input-group.e-small .e-input-group-icon,
  .e-bigger.e-rtl .e-filled.e-input-group.e-control-wrapper.e-small .e-input-group-icon,
  .e-rtl.e-filled.e-float-input.e-input-group.e-bigger .e-input-group-icon,
  .e-rtl.e-filled.e-float-input.e-input-group .e-input-group-icon.e-bigger,
  .e-bigger .e-rtl.e-filled.e-float-input.e-input-group .e-input-group-icon,
  .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group.e-bigger .e-input-group-icon,
  .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon.e-bigger,
  .e-bigger .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon,
  .e-rtl.e-filled.e-float-input.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-rtl.e-filled.e-float-input.e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-bigger .e-rtl.e-filled.e-float-input.e-input-group.e-small .e-input-group-icon,
  .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-bigger .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon,
  .e-rtl .e-filled.e-float-input.e-input-group.e-bigger .e-input-group-icon,
  .e-rtl .e-filled.e-float-input.e-input-group .e-input-group-icon.e-bigger,
  .e-bigger.e-rtl .e-filled.e-float-input.e-input-group .e-input-group-icon,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group.e-bigger .e-input-group-icon,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon.e-bigger,
  .e-bigger.e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon,
  .e-rtl .e-filled.e-float-input.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-rtl .e-filled.e-float-input.e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-bigger.e-rtl .e-filled.e-float-input.e-input-group.e-small .e-input-group-icon,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-bigger.e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $zero-value 8px $zero-value $zero-value;
    }
  }

  .e-filled.e-input-group.e-bigger .e-input-group-icon,
  .e-filled.e-input-group .e-input-group-icon.e-bigger,
  .e-filled.e-input-group.e-control-wrapper.e-bigger .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-input-group .e-input-group-icon,
  .e-bigger .e-filled.e-input-group.e-control-wrapper .e-input-group-icon {
    @if $input-skin-name == 'material' {
      font-size: $bigger-filled-input-icon-size;
      min-height: $bigger-filled-input-icon-min-height;
      min-width: $bigger-filled-input-icon-min-height;
      padding: $zero-value $zero-value $zero-value 8px;
    }
    @if $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $bigger-filled-input-icon-size;
      height: $bigger-filled-input-icon-min-height;
      min-width: $bigger-filled-input-icon-min-height;
    }
  }

  .e-filled.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-filled.e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-input-group.e-small .e-input-group-icon,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-small .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $bigger-small-filled-input-icon-size;
      min-height: $bigger-small-filled-input-icon-min-height;
      min-width: $bigger-small-filled-input-icon-min-height;
      padding: $zero-value $zero-value $zero-value 8px;
    }
  }

  .e-filled.e-float-input.e-input-group.e-bigger .e-input-group-icon,
  .e-filled.e-float-input.e-input-group .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-float-input.e-input-group .e-input-group-icon,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-bigger .e-input-group-icon,
  .e-filled.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon,
  .e-filled.e-float-input.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-filled.e-float-input.e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-float-input.e-input-group.e-small .e-input-group-icon,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $filled-input-icon-size;
      margin-bottom: $zero-value;
      margin-top: $zero-value;
      padding: 8px;
      @if $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
        padding: 0;
        margin: 9px 12px 9px 0;
      }
    }
  }

  .e-filled.e-float-input.e-input-group.e-bigger .e-input-group-icon,
  .e-filled.e-float-input.e-input-group .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-float-input.e-input-group .e-input-group-icon,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-bigger .e-input-group-icon,
  .e-filled.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $bigger-filled-input-icon-size;
      padding: $zero-value $zero-value $zero-value 8px;
    }
  }

  .e-filled.e-float-input.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-filled.e-float-input.e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-float-input.e-input-group.e-small .e-input-group-icon,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $bigger-small-filled-input-icon-size;
      padding: $zero-value $zero-value $zero-value 8px;
    }
  }

  .e-filled.e-input-group.e-bigger .e-clear-icon,
  .e-filled.e-input-group.e-control-wrapper.e-bigger .e-clear-icon,
  .e-filled.e-input-group .e-clear-icon.e-bigger,
  .e-filled.e-input-group.e-control-wrapper .e-clear-icon.e-bigger,
  .e-bigger .e-filled.e-input-group .e-clear-icon,
  .e-bigger .e-filled.e-input-group.e-control-wrapper .e-clear-icon {
    @if $input-skin-name == 'material' {
      font-size: $bigger-filled-input-clear-icon-size;
      min-height: $bigger-filled-input-icon-min-height;
      min-width: $bigger-filled-input-icon-min-height;
      padding: $zero-value $zero-value $zero-value 8px;
    }
    @if $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $bigger-filled-input-clear-icon-size;
      height: $bigger-filled-input-icon-min-height;
      min-width: $bigger-filled-input-icon-min-height;
    }
  }

  .e-filled.e-input-group.e-float-input.e-bigger .e-clear-icon,
  .e-filled.e-input-group.e-float-input .e-clear-icon.e-bigger,
  .e-bigger .e-filled.e-input-group.e-float-input .e-clear-icon,
  .e-filled.e-input-group.e-control-wrapper.e-float-input.e-bigger .e-clear-icon,
  .e-filled.e-input-group.e-control-wrapper.e-float-input .e-clear-icon.e-bigger,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-float-input .e-clear-icon {
    @if $input-skin-name == 'material' {
      padding: $zero-value $zero-value $zero-value 8px;
    }
    @if $input-skin-name == 'Material3' {
      padding: $zero-value;
      margin: 8px;
    }
    @if $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $input-clear-icon-padding;
      margin: $input-clear-icon-margin;
    }
  }

  .e-filled.e-input-group.e-small.e-bigger .e-clear-icon,
  .e-filled.e-input-group.e-small .e-clear-icon.e-bigger,
  .e-bigger .e-filled.e-input-group.e-small .e-clear-icon,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger .e-clear-icon,
  .e-filled.e-input-group.e-control-wrapper.e-small .e-clear-icon.e-bigger,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-small .e-clear-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      font-size: $bigger-small-filled-input-clear-icon-size;
      min-height: $bigger-small-filled-input-icon-min-height;
      min-width: $bigger-small-filled-input-icon-min-height;
      padding: $zero-value $zero-value $zero-value 8px;
    }
    @if $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $bigger-small-filled-input-clear-icon-size;
      min-height: $bigger-small-filled-input-icon-min-height;
      min-width: $bigger-small-filled-input-icon-min-height;
      padding: $input-clear-icon-padding;
    }
  }

  .e-filled.e-input-group.e-float-input.e-small.e-bigger .e-clear-icon,
  .e-filled.e-input-group.e-float-input.e-small .e-clear-icon.e-bigger,
  .e-bigger .e-filled.e-input-group.e-float-input.e-small .e-clear-icon,
  .e-filled.e-input-group.e-control-wrapper.e-float-input.e-small.e-bigger .e-clear-icon,
  .e-filled.e-input-group.e-control-wrapper.e-float-input.e-small .e-clear-icon.e-bigger,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-float-input.e-small .e-clear-icon,
  .e-filled.e-input-group.e-float-input.e-control-wrapper.e-small.e-bigger .e-clear-icon,
  .e-filled.e-input-group.e-float-input.e-control-wrapper.e-small .e-clear-icon.e-bigger,
  .e-bigger .e-filled.e-input-group.e-float-input.e-control-wrapper.e-small .e-clear-icon,
  .e-filled.e-input-group.e-control-wrapper.e-float-input.e-small.e-bigger .e-clear-icon,
  .e-filled.e-input-group.e-control-wrapper.e-float-input.e-small .e-clear-icon.e-bigger,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-float-input.e-small .e-clear-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      padding: $zero-value $zero-value $zero-value 8px;
    }
  }

  .e-rtl.e-filled.e-input-group.e-bigger .e-clear-icon,
  .e-rtl.e-filled.e-input-group .e-clear-icon.e-bigger,
  .e-rtl.e-filled.e-input-group.e-control-wrapper.e-bigger .e-clear-icon,
  .e-rtl.e-filled.e-input-group.e-control-wrapper .e-clear-icon.e-bigger,
  .e-bigger .e-rtl.e-filled.e-input-group .e-clear-icon,
  .e-bigger .e-rtl.e-filled.e-input-group.e-control-wrapper .e-clear-icon,
  .e-rtl.e-filled.e-input-group.e-small.e-bigger .e-clear-icon,
  .e-rtl.e-filled.e-input-group.e-small .e-clear-icon.e-bigger,
  .e-rtl.e-filled.e-input-group.e-control-wrapper.e-small.e-bigger .e-clear-icon,
  .e-rtl.e-filled.e-input-group.e-control-wrapper.e-small .e-clear-icon.e-bigger,
  .e-bigger .e-rtl.e-filled.e-input-group.e-small .e-clear-icon,
  .e-bigger .e-rtl.e-filled.e-input-group.e-control-wrapper.e-small .e-clear-icon,
  .e-rtl.e-filled.e-input-group.e-small .e-clear-icon,
  .e-rtl .e-filled.e-input-group.e-bigger .e-clear-icon,
  .e-rtl .e-filled.e-input-group .e-clear-icon.e-bigger,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-bigger .e-clear-icon,
  .e-rtl .e-filled.e-input-group.e-control-wrapper .e-clear-icon.e-bigger,
  .e-bigger.e-rtl .e-filled.e-input-group .e-clear-icon,
  .e-bigger.e-rtl .e-filled.e-input-group.e-control-wrapper .e-clear-icon,
  .e-rtl .e-filled.e-input-group.e-small.e-bigger .e-clear-icon,
  .e-rtl .e-filled.e-input-group.e-small .e-clear-icon.e-bigger,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger .e-clear-icon,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-small .e-clear-icon.e-bigger,
  .e-bigger.e-rtl .e-filled.e-input-group.e-small .e-clear-icon,
  .e-bigger.e-rtl .e-filled.e-input-group.e-control-wrapper.e-small .e-clear-icon,
  .e-rtl.e-filled.e-float-input.e-input-group.e-bigger .e-clear-icon,
  .e-rtl.e-filled.e-float-input.e-input-group .e-clear-icon.e-bigger,
  .e-bigger .e-rtl.e-filled.e-float-input.e-input-group .e-clear-icon,
  .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group.e-bigger .e-clear-icon,
  .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group .e-clear-icon.e-bigger,
  .e-bigger .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group .e-clear-icon,
  .e-rtl.e-filled.e-float-input.e-input-group.e-small.e-bigger .e-clear-icon,
  .e-rtl.e-filled.e-float-input.e-input-group.e-small .e-clear-icon.e-bigger,
  .e-bigger .e-rtl.e-filled.e-float-input.e-input-group.e-small .e-clear-icon,
  .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group.e-small.e-bigger .e-clear-icon,
  .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-clear-icon.e-bigger,
  .e-bigger .e-rtl.e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-clear-icon,
  .e-rtl .e-filled.e-float-input.e-input-group.e-bigger .e-clear-icon,
  .e-rtl .e-filled.e-float-input.e-input-group .e-clear-icon.e-bigger,
  .e-bigger.e-rtl .e-filled.e-float-input.e-input-group .e-clear-icon,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group.e-bigger .e-clear-icon,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group .e-clear-icon.e-bigger,
  .e-bigger.e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group .e-clear-icon,
  .e-rtl .e-filled.e-float-input.e-input-group.e-small.e-bigger .e-clear-icon,
  .e-rtl .e-filled.e-float-input.e-input-group.e-small .e-clear-icon.e-bigger,
  .e-bigger.e-rtl .e-filled.e-float-input.e-input-group.e-small .e-clear-icon,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small.e-bigger .e-clear-icon,
  .e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-clear-icon.e-bigger,
  .e-bigger.e-rtl .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small .e-clear-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      padding: $zero-value 8px $zero-value $zero-value;
    }
  }

  .e-filled.e-float-input.e-bigger .e-clear-icon::before,
  .e-filled.e-float-input.e-control-wrapper.e-bigger .e-clear-icon::before,
  .e-filled.e-input-group.e-bigger .e-clear-icon::before,
  .e-filled.e-input-group.e-control-wrapper.e-bigger .e-clear-icon::before,
  .e-filled.e-float-input.e-control-wrapper input.e-bigger:first-child ~ .e-clear-icon::before,
  .e-bigger .e-filled.e-float-input.e-control-wrapper .e-clear-icon::before,
  .e-filled.e-float-input input.e-bigger:first-child ~ .e-clear-icon::before,
  .e-bigger .e-filled.e-float-input .e-clear-icon::before,
  .e-bigger .e-filled.e-input-group .e-clear-icon::before,
  .e-bigger .e-filled.e-input-group.e-control-wrapper .e-clear-icon::before {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $bigger-filled-input-clear-icon-size;
    }
  }

  .e-filled.e-float-input.e-small.e-bigger .e-clear-icon::before,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger .e-clear-icon::before,
  .e-filled.e-input-group.e-small.e-bigger .e-clear-icon::before,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger .e-clear-icon::before,
  .e-small .e-filled.e-input-group.e-bigger input:first-child ~ .e-clear-icon::before,
  .e-small .e-filled.e-input-group.e-control-wrapper.e-bigger input:first-child ~ .e-clear-icon::before,
  .e-bigger .e-filled.e-input-group.e-small input:first-child ~ .e-clear-icon::before,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-small input:first-child ~ .e-clear-icon::before,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger input:first-child ~ .e-clear-icon::before,
  .e-small .e-filled.e-float-input.e-bigger input:first-child ~ .e-clear-icon::before,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small input:first-child ~ .e-clear-icon::before,
  .e-bigger .e-filled.e-float-input.e-small input:first-child ~ .e-clear-icon::before {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $bigger-small-filled-input-clear-icon-size;
    }
  }

  .e-filled.e-float-input.e-bigger .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger .e-input-in-wrap input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger .e-input-in-wrap input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input .e-input-in-wrap input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input .e-input-in-wrap input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger .e-input-in-wrap input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger .e-input-in-wrap input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap input[disabled] ~ label.e-label-top.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      top: $bigger-filled-input-label-top-after-floating;
    }
  }

  .e-filled.e-float-input.e-small.e-bigger .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger .e-input-in-wrap input[readonly] ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger .e-input-in-wrap input[disabled] ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small .e-input-in-wrap input[readonly] ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small .e-input-in-wrap input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger .e-input-in-wrap input[readonly] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger .e-input-in-wrap input[disabled] ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small .e-input-in-wrap input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small .e-input-in-wrap input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small .e-input-in-wrap input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small .e-input-in-wrap input[readonly] ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small .e-input-in-wrap input[disabled] ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      top: $bigger-small-filled-input-label-top-after-floating;
    }
  }

  .e-filled.e-float-input.e-bigger .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input .e-input-in-wrap label.e-float-text.e-bigger,
  .e-filled.e-float-input .e-input-in-wrap input.e-bigger ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-bigger .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text.e-bigger,
  .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap input.e-bigger ~ label.e-float-text,
  .e-bigger.e-filled.e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom  {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      top: $bigger-filled-input-label-top;
    }
  }

  .e-filled.e-float-input.e-small.e-bigger .e-input-in-wrap label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small .e-input-in-wrap label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-bigger:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-bigger.e-small:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-filled.e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger .e-input-in-wrap label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small .e-input-in-wrap label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      top: $bigger-small-filled-input-label-top;
    }
  }

  .e-filled.e-input-group:not(.e-float-input).e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group:not(.e-float-input).e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-input-group:not(.e-float-input).e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group:not(.e-float-input).e-control-wrapper.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group:not(.e-float-input).e-control-wrapper.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-input-group:not(.e-float-input).e-control-wrapper.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $bigger-filled-input-icon-size;
      margin: $zero-value;
      min-height: $bigger-filled-input-left-icon-min-height;
      min-width: $bigger-filled-input-left-icon-min-width;
      padding: $zero-value;
    }
  }

  .e-filled.e-input-group:not(.e-float-input).e-small.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group:not(.e-float-input).e-small.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-input-group:not(.e-float-input).e-small.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-small.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-small.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-input-group.e-control-wrapper:not(.e-float-input).e-small.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      font-size: $bigger-small-filled-input-icon-size;
      margin: $zero-value;
      min-height: $bigger-small-filled-input-left-icon-min-height;
      min-width: $bigger-small-filled-input-left-icon-min-width;
      padding: $zero-value;
    }
  }

  .e-filled.e-input-group.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-float-input.e-input-group.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-float-input.e-input-group.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-float-input.e-input-group.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-float-input.e-input-group.e-control-wrapper.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-float-input.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-float-input.e-input-group.e-control-wrapper.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      margin: $zero-value;
      min-height: $bigger-filled-input-left-icon-min-height;
      min-width: $bigger-filled-input-left-icon-min-width;
      padding: $zero-value;
    }
  }

  .e-filled.e-input-group.e-small.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group.e-small.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-input-group.e-small.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-float-input.e-input-group.e-small.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-float-input.e-input-group.e-small.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-input-group.e-float-input.e-small.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-small.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small.e-bigger.e-float-icon-left > .e-input-group-icon,
  .e-filled.e-float-input.e-control-wrapper.e-input-group.e-small.e-float-icon-left > .e-input-group-icon.e-bigger,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-float-input.e-small.e-float-icon-left > .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      margin: $zero-value;
      min-height: $bigger-small-filled-input-left-icon-min-height;
      min-width: $bigger-small-filled-input-left-icon-min-width;
      padding: $zero-value;
    }
  }

  .e-filled.e-float-input.e-bigger .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-bigger .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-bigger .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-bigger:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom .e-bigger .e-filled.e-float-input .e-input-in-wrap label.e-float-text,
  .e-bigger .e-filled.e-float-input .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small.e-bigger .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger .e-input-in-wrap label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom .e-small.e-bigger .e-filled.e-float-input .e-input-in-wrap label.e-float-text,
  .e-small.e-bigger .e-filled.e-float-input .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom .e-small .e-filled.e-float-input.e-bigger .e-input-in-wrap label.e-float-text,
  .e-small.e-bigger .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text,
  .e-small.e-bigger .e-filled.e-float-input.e-control-wrapper .e-input-in-wrap label.e-float-text.e-label-bottom,
  .e-small.e-bigger .e-filled.e-float-input:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small.e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-input-focus) .e-input-in-wrap input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      left: $zero-value;
    }
  }

  .e-filled.e-input-group.e-bigger .e-input-group-icon:last-child,
  .e-bigger .e-filled.e-input-group .e-input-group-icon:last-child,
  .e-filled.e-input-group.e-small.e-bigger .e-input-group-icon:last-child,
  .e-small.e-bigger .e-filled.e-input-group .e-input-group-icon:last-child,
  .e-bigger .e-filled.e-input-group.e-small .e-input-group-icon:last-child,
  .e-small .e-filled.e-input-group.e-bigger .e-input-group-icon:last-child,
  .e-filled.e-input-group.e-bigger.e-control-wrapper .e-input-group-icon:last-child,
  .e-bigger .e-filled.e-input-group.e-control-wrapper .e-input-group-icon:last-child,
  .e-filled.e-input-group.e-small.e-bigger.e-control-wrapper .e-input-group-icon:last-child,
  .e-small.e-bigger .e-filled.e-input-group.e-control-wrapper .e-input-group-icon:last-child,
  .e-bigger .e-filled.e-input-group.e-small.e-control-wrapper .e-input-group-icon:last-child,
  .e-small .e-filled.e-input-group.e-bigger.e-control-wrapper .e-input-group-icon:last-child,
  .e-filled.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon,
  .e-small.e-bigger .e-filled.e-input-group .e-input-group-icon,
  .e-small.e-bigger .e-filled.e-input-group.e-control-wrapper .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      margin-right: $zero-value;
    }
  }

  .e-filled.e-input-group.e-bigger.e-rtl .e-input-group-icon:last-child,
  .e-bigger .e-filled.e-input-group.e-rtl .e-input-group-icon:last-child,
  .e-filled.e-input-group.e-small.e-bigger.e-rtl .e-input-group-icon:last-child,
  .e-small.e-bigger .e-filled.e-input-group.e-rtl .e-input-group-icon:last-child,
  .e-bigger .e-filled.e-input-group.e-small.e-rtl .e-input-group-icon:last-child,
  .e-small .e-filled.e-input-group.e-bigger.e-rtl .e-input-group-icon:last-child,
  .e-filled.e-input-group.e-bigger.e-control-wrapper.e-rtl .e-input-group-icon:last-child,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-rtl .e-input-group-icon:last-child,
  .e-filled.e-input-group.e-small.e-bigger.e-control-wrapper.e-rtl .e-input-group-icon:last-child,
  .e-small.e-bigger .e-filled.e-input-group.e-control-wrapper.e-rtl .e-input-group-icon:last-child,
  .e-bigger .e-filled.e-input-group.e-small.e-control-wrapper.e-rtl .e-input-group-icon:last-child,
  .e-small .e-filled.e-input-group.e-bigger.e-control-wrapper.e-rtl .e-input-group-icon:last-child,
  .e-rtl .e-filled.e-input-group.e-bigger .e-input-group-icon:last-child,
  .e-rtl.e-bigger .e-filled.e-input-group .e-input-group-icon:last-child,
  .e-rtl .e-filled.e-input-group.e-small.e-bigger .e-input-group-icon:last-child,
  .e-rtl.e-small.e-bigger .e-filled.e-input-group .e-input-group-icon:last-child,
  .e-rtl.e-bigger .e-filled.e-input-group.e-small .e-input-group-icon:last-child,
  .e-rtl.e-small .e-filled.e-input-group.e-bigger .e-input-group-icon:last-child,
  .e-rtl .e-filled.e-input-group.e-bigger.e-control-wrapper .e-input-group-icon:last-child,
  .e-rtl.e-bigger .e-filled.e-input-group.e-control-wrapper .e-input-group-icon:last-child,
  .e-rtl .e-filled.e-input-group.e-small.e-bigger.e-control-wrapper .e-input-group-icon:last-child,
  .e-rtl.e-small.e-bigger .e-filled.e-input-group.e-control-wrapper .e-input-group-icon:last-child,
  .e-rtl.e-bigger .e-filled.e-input-group.e-small.e-control-wrapper .e-input-group-icon:last-child,
  .e-rtl.e-small .e-filled.e-input-group.e-bigger.e-control-wrapper .e-input-group-icon:last-child {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      margin-left: $zero-value;
    }
  }

  .e-filled.e-rtl.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-filled.e-rtl.e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon,
  .e-small.e-bigger .e-rtl.e-filled.e-input-group .e-input-group-icon,
  .e-small.e-bigger .e-rtl.e-filled.e-input-group.e-control-wrapper .e-input-group-icon,
  .e-rtl .e-filled.e-input-group.e-small.e-bigger .e-input-group-icon,
  .e-rtl .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon,
  .e-rtl.e-small.e-bigger .e-filled.e-input-group .e-input-group-icon,
  .e-rtl.e-small.e-bigger .e-filled.e-input-group.e-control-wrapper .e-input-group-icon {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      margin-left: $zero-value;
      margin-right: $zero-value;
    }
  }

  .e-filled.e-input-group.e-bigger textarea,
  .e-filled.e-input-group.e-bigger textarea.e-input,
  .e-filled.e-input-group textarea.e-bigger,
  .e-filled.e-input-group textarea.e-input.e-bigger,
  .e-filled.e-input-group.e-control-wrapper.e-bigger textarea,
  .e-filled.e-input-group.e-control-wrapper.e-bigger textarea.e-input,
  .e-filled.e-input-group.e-control-wrapper textarea.e-bigger,
  .e-filled.e-input-group.e-control-wrapper textarea.e-input.e-bigger,
  .e-bigger .e-filled.e-input-group textarea,
  .e-bigger .e-filled.e-input-group textarea.e-input,
  .e-bigger .e-filled.e-input-group.e-control-wrapper textarea,
  .e-bigger .e-filled.e-input-group.e-control-wrapper textarea.e-input,
  .e-filled.e-input-group.e-input-focus.e-bigger textarea,
  .e-filled.e-input-group.e-input-focus.e-bigger textarea.e-input,
  .e-filled.e-input-group.e-input-focus textarea.e-bigger,
  .e-filled.e-input-group.e-input-focus textarea.e-input.e-bigger,
  .e-filled.e-input-group.e-control-wrapper.e-input-focus.e-bigger textarea,
  .e-filled.e-input-group.e-control-wrapper.e-input-focus.e-bigger textarea.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-input-focus textarea.e-bigger,
  .e-filled.e-input-group.e-control-wrapper.e-input-focus textarea.e-input.e-bigger,
  .e-bigger .e-filled.e-input-group.e-input-focus textarea,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-input-focus textarea,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-input-focus textarea.e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-bigger-textarea-padding;
    }
  }

  .e-filled.e-float-input.e-bigger textarea,
  .e-filled.e-float-input textarea.e-bigger,
  .e-filled.e-float-input.e-control-wrapper.e-bigger textarea,
  .e-filled.e-float-input.e-control-wrapper textarea.e-bigger,
  .e-bigger .e-filled.e-float-input textarea,
  .e-bigger .e-filled.e-float-input.e-control-wrapper textarea {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-bigger-float-textarea-padding;
    }
  }

  .e-filled.e-input-group.e-small.e-bigger textarea.e-input,
  .e-filled.e-input-group.e-small textarea.e-input.e-bigger,
  .e-filled.e-input-group textarea.e-input.e-bigger.e-small,
  .e-filled.e-input-group.e-bigger textarea.e-small,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger textarea.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-small textarea.e-input.e-bigger,
  .e-bigger.e-small .e-filled.e-input-group textarea.e-input,
  .e-bigger.e-small .e-filled.e-input-group.e-control-wrapper textarea.e-input,
  .e-bigger .input-group textarea.e-small,
  .e-bigger .e-filled.e-input-group.e-small textarea.e-input,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-small textarea.e-input,
  .e-bigger .e-small.e-filled.e-input-group.e-control-wrapper textarea.e-input,
  .e-small .input-group textarea.e-bigger,
  .e-small .e-filled.e-input-group.e-bigger textarea.e-input,
  .e-small .e-filled.e-input-group.e-control-wrapper textarea.e-input.e-bigger,
  .e-small .e-filled.e-input-group.e-control-wrapper.e-input-focus textarea.e-input.e-bigger,
  .e-filled.e-input-group.e-input-focus textarea.e-input.e-bigger.e-small,
  .e-filled.e-input-group.e-input-focus.e-small.e-bigger textarea.e-input,
  .e-filled.e-input-group.e-input-focus.e-small textarea.e-input.e-bigger,
  .e-filled.e-input-group.e-input-focus.e-bigger textarea.e-small,
  .e-filled.e-input-group.e-control-wrapper.e-input-focus.e-small.e-bigger textarea.e-input,
  .e-filled.e-input-group.e-control-wrapper.e-input-focus.e-small textarea.e-input.e-bigger,
  .e-bigger.e-small .e-filled.e-input-group.e-input-focus textarea.e-input,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-input-focus.e-small textarea.e-input,
  .e-bigger.e-small .e-filled.e-input-group.e-control-wrapper.e-input-focus textarea.e-input,
  .e-bigger .e-small.e-filled.e-input-group.e-control-wrapper.e-input-focus textarea.e-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-bigger-small-textarea-padding;
    }
  }

  .e-filled.e-float-input textarea.e-bigger.e-small,
  .e-filled.e-float-input.e-small.e-bigger textarea,
  .e-filled.e-float-input.e-small textarea.e-bigger,
  .e-filled.e-float-input.e-bigger textarea.e-small,
  .e-bigger .e-filled.e-float-input.e-small textarea,
  .e-bigger.e-small .e-filled.e-float-input textarea,
  .e-bigger .e-float-input textarea.e-small,
  .e-small .e-filled.e-float-input.e-bigger textarea,
  .e-small .float-input textarea.e-bigger,
  .e-small .e-filled.e-float-input.e-control-wrapper textarea.e-bigger,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger textarea,
  .e-filled.e-float-input.e-control-wrapper.e-small textarea.e-bigger,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small textarea,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper textarea,
  .e-bigger .e-small.e-filled.e-float-input.e-control-wrapper textarea {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-bigger-small-float-textarea-padding;
    }
  }

  .e-filled.e-input-group.e-multi-line-input.e-bigger,
  .e-bigger .e-filled.e-input-group.e-multi-line-input,
  .e-filled.e-input-group.e-control-wrapper.e-bigger.e-multi-line-input,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-multi-line-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $bigger-filled-textarea-wrapper-padding;
    }
  }

  .e-filled.e-input-group.e-small.e-bigger.e-multi-line-input,
  .e-bigger.e-small .e-filled.e-input-group.e-small.e-multi-line-input,
  .e-bigger .e-filled.e-input-group.e-small.e-multi-line-input,
  .e-small .e-filled.e-input-group.e-bigger.e-multi-line-input,
  .e-filled.e-input-group.e-control-wrapper.e-small.e-bigger.e-multi-line-input,
  .e-bigger.e-small .e-filled.e-input-group.e-control-wrapper.e-small.e-multi-line-input,
  .e-bigger .e-filled.e-input-group.e-control-wrapper.e-small.e-multi-line-input,
  .e-small .e-filled.e-input-group.e-control-wrapper.e-bigger.e-multi-line-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $bigger-small-filled-textarea-wrapper-padding;
    }
  }

  .e-filled.e-float-input.e-bigger.e-multi-line-input,
  .e-bigger .e-filled.e-float-input.e-multi-line-input,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-multi-line-input,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-multi-line-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $bigger-filled-float-textarea-wrapper-padding;
    }
  }

  .e-filled.e-float-input.e-small.e-bigger.e-multi-line-input,
  .e-bigger.e-small .e-filled.e-float-input.e-small.e-multi-line-input,
  .e-bigger .e-filled.e-float-input.e-small.e-multi-line-input,
  .e-small .e-filled.e-float-input.e-bigger.e-multi-line-input,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-multi-line-input,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper.e-small.e-multi-line-input,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-multi-line-input,
  .e-small .e-filled.e-float-input.e-control-wrapper.e-bigger.e-multi-line-input {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $bigger-small-filled-float-textarea-wrapper-padding;
    }
  }

  .e-rtl .e-filled textarea.e-input.e-bigger#{$css},
  .e-filled textarea.e-input.e-bigger.e-rtl#{$css},
  .e-bigger.e-rtl .e-filled textarea.e-input#{$css},
  .e-bigger .e-filled textarea.e-input.e-rtl#{$css},
  .e-filled.e-input-group.e-multi-line-input.e-bigger.e-rtl textarea.e-input,
  .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-bigger.e-rtl textarea.e-input,
  .e-rtl .e-filled.e-input-group.e-multi-line-input.e-bigger textarea.e-input,
  .e-rtl .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-bigger textarea.e-input,
  .e-filled.e-input-group.e-multi-line-input.e-rtl textarea.e-input.e-bigger,
  .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-rtl textarea.e-input.e-bigger,
  .e-rtl .e-filled.e-input-group.e-multi-line-input textarea.e-input.e-bigger,
  .e-rtl .e-filled.e-input-group.e-multi-line-input.e-control-wrapper textarea.e-input.e-bigger,
  .e-bigger .e-filled.e-input-group.e-multi-line-input.e-rtl textarea.e-input,
  .e-bigger .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-rtl textarea.e-input,
  .e-bigger.e-rtl .e-filled.e-input-group.e-multi-line-input textarea.e-input,
  .e-bigger.e-rtl .e-filled.e-input-group.e-multi-line-input.e-control-wrapper textarea.e-input,
  .e-bigger.e-rtl .e-filled.e-input-group.e-multi-line-input textarea.e-input:focus,
  .e-bigger.e-rtl .e-filled.e-input-group.e-multi-line-input.e-control-wrapper textarea.e-input:focus,
  .e-bigger .e-filled.e-input-group.e-multi-line-input.e-rtl textarea.e-input:focus,
  .e-bigger .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-rtl textarea.e-input:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-bigger-textarea-padding;
      text-indent: 0;
    }
  }

  .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-bigger.e-rtl textarea,
  .e-filled.e-float-input.e-multi-line-input.e-bigger.e-rtl textarea,
  .e-rtl .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-bigger textarea,
  .e-rtl .e-filled.e-float-input.e-multi-line-input.e-bigger textarea,
  .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-rtl textarea.e-bigger,
  .e-filled.e-float-input.e-multi-line-input.e-rtl textarea.e-bigger,
  .e-rtl .e-filled.e-float-input.e-multi-line-input.e-control-wrapper textarea.e-bigger,
  .e-rtl .e-filled.e-float-input.e-multi-line-input textarea.e-bigger,
  .e-bigger .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-rtl textarea,
  .e-bigger .e-filled.e-float-input.e-multi-line-input.e-rtl textarea,
  .e-bigger.e-rtl .e-filled.e-float-input.e-multi-line-input.e-control-wrapper textarea,
  .e-bigger.e-rtl .e-filled.e-float-input.e-multi-line-input textarea,
  .e-bigger.e-rtl .e-filled.e-float-input.e-multi-line-input.e-control-wrapper textarea:focus,
  .e-bigger.e-rtl .e-filled.e-float-input.e-multi-line-input textarea:focus,
  .e-bigger .e-filled.e-float-input.e-multi-line-input.e-rtl textarea:focus,
  .e-bigger .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-rtl textarea:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-bigger-float-textarea-padding;
      text-indent: 0;
    }
  }

  .e-bigger .e-filled textarea.e-input.e-small.e-rtl#{$css},
  .e-filled.e-input-group.e-multi-line-input.e-small.e-bigger.e-rtl textarea.e-input,
  .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-small.e-bigger.e-rtl textarea.e-input,
  .e-filled.e-input-group.e-multi-line-input.e-small.e-rtl textarea.e-input.e-bigger,
  .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-small.e-rtl textarea.e-input.e-bigger,
  .e-bigger .e-filled.e-input-group.e-multi-line-input.e-small.e-rtl textarea.e-input,
  .e-bigger .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-small.e-rtl textarea.e-input,
  .e-bigger.e-rtl textarea.e-input.e-small,
  .e-rtl .e-filled.e-input-group.e-multi-line-input.e-small.e-bigger textarea.e-input,
  .e-rtl .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-small.e-bigger textarea.e-input,
  .e-rtl .e-filled.e-input-group.e-multi-line-input.e-small textarea.e-input.e-bigger,
  .e-rtl .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-small textarea.e-input.e-bigger,
  .e-bigger.e-rtl .e-filled.e-input-group.e-multi-line-input.e-small textarea.e-input,
  .e-bigger.e-rtl .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-small textarea.e-input,
  .e-bigger .e-filled.e-input-group.e-multi-line-input.e-small.e-rtl textarea.e-input:focus,
  .e-bigger .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-small.e-rtl textarea.e-input:focus,
  .e-small .e-filled.e-input-group.e-multi-line-input.e-bigger.e-rtl textarea.e-input:focus,
  .e-small .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-bigger.e-rtl textarea.e-input:focus,
  .e-filled.e-input-group.e-multi-line-input.e-bigger.e-small.e-rtl textarea.e-input:focus,
  .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-bigger.e-small.e-rtl textarea.e-input:focus,
  .e-bigger.e-rtl .e-filled.e-input-group.e-multi-line-input.e-small textarea.e-input:focus,
  .e-bigger.e-rtl .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-small textarea.e-input:focus,
  .e-small.e-rtl .e-filled.e-input-group.e-multi-line-input.e-bigger textarea.e-input:focus,
  .e-small.e-rtl .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-bigger textarea.e-input:focus,
  .e-rtl .e-filled.e-input-group.e-multi-line-input.e-bigger.e-small textarea.e-input:focus,
  .e-rtl .e-filled.e-input-group.e-multi-line-input.e-control-wrapper.e-bigger.e-small textarea.e-input:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-bigger-small-textarea-padding;
      text-indent: 0;
    }
  }

  .e-filled.e-float-input.e-multi-line-input.e-small.e-bigger.e-rtl textarea,
  .e-filled.e-float-input.e-multi-line-input.e-small.e-rtl textarea.e-bigger,
  .e-bigger .e-filled.e-float-input.e-multi-line-input.e-small.e-rtl textarea,
  .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-small.e-bigger.e-rtl textarea,
  .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-small.e-rtl textarea.e-bigger,
  .e-bigger .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-small.e-rtl textarea,
  .e-rtl .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-small.e-bigger textarea,
  .e-rtl .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-small textarea.e-bigger,
  .e-bigger.e-rtl .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-small textarea,
  .e-rtl .e-filled.e-float-input.e-multi-line-input.e-small.e-bigger textarea,
  .e-rtl .e-filled.e-float-input.e-multi-line-input.e-small textarea.e-bigger,
  .e-bigger.e-rtl .e-filled.e-float-input.e-multi-line-input.e-small textarea,
  .e-bigger .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-small.e-rtl textarea:focus,
  .e-bigger .e-filled.e-float-input.e-multi-line-input.e-small.e-rtl textarea:focus,
  .e-small .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-bigger.e-rtl textarea:focus,
  .e-small .e-filled.e-float-input.e-multi-line-input.e-bigger.e-rtl textarea:focus,
  .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-bigger.e-small.e-rtl textarea:focus,
  .e-filled.e-float-input.e-multi-line-input.e-bigger.e-small.e-rtl textarea:focus,
  .e-bigger.e-rtl .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-small textarea:focus,
  .e-bigger.e-rtl .e-filled.e-float-input.e-multi-line-input.e-small textarea:focus,
  .e-small.e-rtl .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-bigger textarea:focus,
  .e-small.e-rtl .e-filled.e-float-input.e-multi-line-input.e-bigger textarea:focus,
  .e-rtl .e-filled.e-float-input.e-multi-line-input.e-control-wrapper.e-bigger.e-small textarea:focus,
  .e-rtl .e-filled.e-float-input.e-multi-line-input.e-bigger.e-small textarea:focus {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding: $filled-bigger-small-float-textarea-padding;
      text-indent: 0;
    }
  }

  .e-filled.e-float-input.e-float-icon-left.e-bigger.e-small .e-input-in-wrap,
  .e-filled.e-float-input.e-control-wrapper.e-float-icon-left.e-bigger.e-small .e-input-in-wrap,
  .e-filled.e-input-group.e-float-icon-left.e-bigger.e-small .e-input-in-wrap,
  .e-filled.e-input-group.e-control-wrapper.e-float-icon-left.e-bigger.e-small .e-input-in-wrap,
  .e-bigger.e-small .e-filled.e-float-input.e-float-icon-left .e-input-in-wrap,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper.e-float-icon-left .e-input-in-wrap,
  .e-bigger.e-small .e-filled.e-input-group.e-float-icon-left .e-input-in-wrap,
  .e-bigger.e-small .e-filled.e-input-group.e-control-wrapper.e-float-icon-left .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      border: $input-group-full-border;
      border-width: $zero-value;
      margin-left: 8px;
    }
  }

  .e-rtl.e-bigger.e-small .e-filled.e-float-input.e-float-icon-left .e-input-in-wrap,
  .e-rtl.e-bigger.e-small .e-filled.e-float-input.e-control-wrapper.e-float-icon-left .e-input-in-wrap,
  .e-rtl.e-bigger.e-small .e-filled.e-input-group.e-float-icon-left .e-input-in-wrap,
  .e-rtl.e-bigger.e-small .e-filled.e-input-group.e-control-wrapper.e-float-icon-left .e-input-in-wrap,
  .e-rtl .e-bigger.e-small.e-filled.e-float-input.e-float-icon-left .e-input-in-wrap,
  .e-rtl .e-bigger.e-small.e-filled.e-float-input.e-control-wrapper.e-float-icon-left .e-input-in-wrap,
  .e-rtl .e-bigger.e-small.e-filled.e-input-group.e-float-icon-left .e-input-in-wrap,
  .e-rtl .e-bigger.e-small.e-filled.e-input-group.e-control-wrapper.e-float-icon-left .e-input-in-wrap,
  .e-bigger.e-small.e-filled.e-float-input.e-control-wrapper.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-bigger.e-small.e-filled.e-float-input.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-bigger.e-small.e-filled.e-input-group.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-bigger.e-small.e-filled.e-input-group.e-control-wrapper.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-bigger.e-small .e-filled.e-float-input.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-bigger.e-small .e-filled.e-input-group.e-float-icon-left.e-rtl .e-input-in-wrap,
  .e-bigger.e-small .e-filled.e-input-group.e-control-wrapper.e-float-icon-left.e-rtl .e-input-in-wrap {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      margin-left: $zero-value;
      margin-right: 8px;
    }
  }

  .e-float-input.e-bigger:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-small.e-bigger:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    color: $input-header-font-color;
    @if $input-skin-name == 'highcontrast' {
      color: $input-placeholder;
    }
  }

  .e-float-input.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper.e-bigger:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger.e-small:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-small .e-float-input.e-control-wrapper.e-bigger textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger.e-small .e-float-input.e-control-wrapper textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger.e-small textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    @if $input-skin-name == 'highcontrast' {
      color: $content-font-alt;
    }
  }

  .e-bigger .e-input#{$css}:focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-textarea) {
    @if $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding-bottom: 0;
    }
  }

  .e-bigger textarea.e-input#{$css}:focus:not(.e-success):not(.e-warning):not(.e-error) {
    @if $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      padding-bottom: 6px;
    }
  }

  .e-float-input.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
  .e-float-input.e-bigger:not(.e-error) input:valid ~ label.e-float-text,
  .e-float-input.e-bigger:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input:not(.e-error) input:focus ~ label.e-float-text,
  .e-bigger .e-float-input:not(.e-error) input:valid ~ label.e-float-text,
  .e-bigger .e-float-input:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error) input:valid ~ label.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error) input:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error) input:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) input:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) input:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-float-input.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-float-input.e-bigger:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-float-input.e-bigger:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-bigger .e-float-input:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-bigger .e-float-input:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-float-input.e-bigger.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-autofill:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-float-input.e-control-wrapper.e-autofill:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-float-input.e-small.e-control-wrapper.e-autofill:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-float-input.e-control-wrapper.e-autofill:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-float-input.e-bigger.e-small.e-control-wrapper.e-autofill:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-float-input:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-float-input:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-float-input:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-float-input.e-bigger.e-small:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-autofill:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-float-input.e-control-wrapper.e-autofill:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-float-input.e-small.e-control-wrapper.e-autofill:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-float-input.e-control-wrapper.e-autofill:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-float-input.e-bigger.e-small.e-control-wrapper.e-autofill:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text {
    color: $float-label-font-color;
  }

  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) input:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error) input:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text {
    @if $input-skin-name != 'fluent2' {
      color: $float-label-font-color;
    }
    @else {
      color: $float-label-top-font-color;
    }
  }

  .e-float-input.e-bigger:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-small.e-bigger:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-small:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    color: $input-header-font-color;
    @if $input-skin-name == 'highcontrast' {
      color: $input-placeholder;
    }
  }

  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text {
    color: $float-label-font-color;
  }

  .e-float-input.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-float-input.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-float-input.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-disabled.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-disabled.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-disabled.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-disabled.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-disabled.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-disabled.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-disabled.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-disabled.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top {
    color: $float-label-disbale-font-color;
  }

  .e-float-input.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
  .e-bigger .e-float-input:not(.e-error) input:focus ~ label.e-float-text,
  .e-float-input.e-bigger:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[readonly]:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text .e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus textarea[readonly] ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error) input:focus ~ label.e-float-text,
  .e-float-input.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-bigger .e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) input:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-float-input.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      color: $input-active-accent-color;
    }
  }

  .e-outline.e-float-input.e-bigger.e-success.e-input-focus input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-success.e-input-focus input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger.e-success input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small:not(.e-success) input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger.e-success textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-success textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger.e-success textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small.e-success textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-success input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-success input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger.e-success input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small.e-success input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-success textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-success textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger.e-success textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small.e-success textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger.e-success.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-success.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger.e-success.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small:not(.e-success).e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-success.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-success.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger.e-success.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small.e-success.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      color: $input-success-color;
    }
  }

  .e-outline.e-float-input.e-bigger.e-error.e-input-focus input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-error.e-input-focus input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger.e-error input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small:not(.e-error) input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger.e-error textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-error textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger.e-error textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small.e-error textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-error input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-error input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger.e-error input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small.e-error input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-error textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-error textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger.e-error textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small.e-error textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger.e-error.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-error.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger.e-error.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-error.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-error.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger.e-error.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small.e-error.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      color: $input-error-color;
    }
  }

  .e-outline.e-float-input.e-bigger.e-warning.e-input-focus input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-warning.e-input-focus input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger.e-warning input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small:not(.e-warning) input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger.e-warning textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-warning textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger.e-warning textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small.e-warning textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-warning input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-warning input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small.e-warning input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-warning textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-warning textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small.e-warning textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger.e-warning.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-warning.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger.e-warning.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-small:not(.e-warning).e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger.e-warning.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-warning.e-input-focus input ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-small.e-warning.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      color: $input-warning-color;
    }
  }

  .e-outline.e-float-input.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-disabled.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-disabled.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-disabled.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-disabled.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-disabled.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-disabled.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-disabled.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-disabled.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      color: $outline-float-label-disbale-font-color;
    }
  }

  .e-filled.e-float-input.e-bigger:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-filled.e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-filled.e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-control-wrapper.e-autofill:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small.e-control-wrapper.e-autofill:not(.e-error):not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-filled.e-float-input:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-filled.e-float-input:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-control-wrapper.e-autofill:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper.e-autofill:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-bigger.e-small.e-control-wrapper.e-autofill:not(.e-error) textarea:not(:focus):-webkit-autofill ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      color: $filled-input-float-label-color;
    }
  }

  .e-filled.e-float-input.e-bigger.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-success input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-success input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-success input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-success input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-success input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-success input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-success input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-success input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-success input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-success.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-success.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-success input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-success input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-success input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-success input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-success input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-success input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-success input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-success input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-success input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-success.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-success.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-success input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-success input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-success input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-success input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-success input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-success input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-success input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-success input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-success input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-success.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-success.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      color: $input-success-color;
    }
  }

  .e-filled.e-float-input.e-bigger.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-warning input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-warning input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-warning input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-warning input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-warning input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-warning input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-warning input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-warning input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-warning input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-warning.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-warning.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-warning input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-warning input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-warning input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-warning input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-warning input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-warning input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-warning input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-warning input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-warning input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-warning.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-warning.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      color: $input-warning-color;
    }
  }

  .e-filled.e-float-input.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error) input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error) input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error) input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      color: $filled-input-float-label-color;
    }
  }

  .e-filled.e-float-input.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[readonly]:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus textarea[readonly] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      color: $input-active-accent-color;
    }
  }

  .e-filled.e-float-input.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-disabled.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-disabled.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-disabled.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-disabled.e-small.e-bigger:not(.e-error) input[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-disabled.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-disabled.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top,
  .e-filled.e-float-input.e-control-wrapper.e-disabled.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-disabled.e-small.e-bigger:not(.e-error) textarea[disabled] ~ label.e-float-text.e-label-top {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      color: $filled-input-disabled-font-color;
    }
  }

  .e-filled.e-float-input.e-bigger.e-success textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-success textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-success textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-success textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small.e-bigger.e-success textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-success textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-small.e-success textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-success textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-bigger.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-success textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-success textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-success textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-success textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-success textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-success textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-success textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-success textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-success textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-success textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-success textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-success textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-success textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-success textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-success textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-success textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-success textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-success textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-success textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-success textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-success textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-success textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-success textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-success textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-success textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-success textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-success textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-success input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-success textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-success input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-success input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-success input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-success textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-success textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-success textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-success.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-success.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-success.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-success.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-success.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-success.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-success.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-filled.e-float-input.e-success:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-success:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-filled.e-float-input.e-success:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small.e-success:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-autofill.e-success:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-filled.e-float-input.e-control-wrapper.e-autofill.e-success:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-control-wrapper.e-autofill.e-success:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper.e-autofill.e-success:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small.e-control-wrapper.e-autofill.e-success:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-success textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-filled.e-float-input.e-success textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-success textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-filled.e-float-input.e-success textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small.e-success textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-autofill.e-success textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-filled.e-float-input.e-control-wrapper.e-autofill.e-success textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-control-wrapper.e-autofill.e-success textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper.e-autofill.e-success textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-bigger.e-small.e-control-wrapper.e-autofill.e-success textarea:not(:focus):-webkit-autofill ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      color: $input-success-color;
    }
  }

  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning input:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning input ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning input[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-warning input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-warning input:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-warning input ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-warning input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-warning input[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-warning.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-warning textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-warning textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-warning textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-small.e-bigger.e-warning textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-small.e-warning textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-warning textarea:not(:focus):valid ~ label.e-float-text.e-label-bottom,
  .e-filled.e-float-input.e-bigger.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-warning textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-warning textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-warning textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-warning textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-warning textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-warning textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-warning textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-warning textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-warning textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-warning textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-warning textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-warning textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-warning textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-warning textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-warning textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-warning textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-warning textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-warning textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning textarea:valid ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning textarea ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-warning textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-warning textarea:valid ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-warning textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-warning textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-warning textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-warning input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-warning textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-warning input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning input:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-warning input:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-warning textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning textarea:focus ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-warning textarea:focus ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-warning.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-small.e-bigger.e-warning.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-warning.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-bigger.e-warning.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-warning.e-input-focus input ~ label.e-float-text,
  .e-filled.e-float-input.e-control-wrapper.e-small.e-bigger.e-warning.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-small.e-warning.e-input-focus input ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-filled.e-float-input.e-warning:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-warning:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-filled.e-float-input.e-warning:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small.e-warning:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-autofill.e-warning:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-filled.e-float-input.e-control-wrapper.e-autofill.e-warning:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-control-wrapper.e-autofill.e-warning:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper.e-autofill.e-warning:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small.e-control-wrapper.e-autofill.e-warning:not(.e-input-focus) input:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-warning textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-filled.e-float-input.e-warning textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-warning textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-filled.e-float-input.e-warning textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-float-input.e-bigger.e-small.e-warning textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-control-wrapper.e-autofill.e-warning textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-filled.e-float-input.e-control-wrapper.e-autofill.e-warning textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger .e-filled.e-float-input.e-small.e-control-wrapper.e-autofill.e-warning textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-bigger.e-small .e-filled.e-float-input.e-control-wrapper.e-autofill.e-warning textarea:not(:focus):-webkit-autofill ~ label.e-float-text,
  .e-filled.e-bigger.e-small.e-control-wrapper.e-autofill.e-warning textarea:not(:focus):-webkit-autofill ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' or $input-skin-name == 'fluent2' or $input-skin-name == 'tailwind3' {
      color: $input-warning-color;
    }
  }

  .e-outline.e-float-input.e-bigger input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger input:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger input ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-bigger input label.e-float-text.e-label-top,
  .e-bigger .e-outline.e-float-input input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input input:valid ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input input ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input input label.e-float-text.e-label-top,
  .e-bigger .e-outline.e-float-input.e-control-wrapper input:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper input:valid ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper input ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper input[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper input[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper input label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-bigger input:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger input:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger input ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger input[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger input[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrappe.e-bigger input label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-bigger textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger textarea:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-bigger textarea label.e-float-text.e-label-top,
  .e-bigger .e-outline.e-float-input textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input textarea:valid ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input textarea label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-control-wrapper.e-bigger textarea:focus ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger textarea:valid ~ label.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger textarea ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-outline.e-float-input.e-control-wrapper.e-bigger textarea label.e-float-text.e-label-top,
  .e-bigger .e-outline.e-float-input.e-control-wrapper textarea:focus ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper textarea:valid ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper textarea ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper textarea[readonly] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper textarea[disabled] ~ label.e-label-top.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper textarea label.e-float-text.e-label-top,
  .e-outline.e-float-input.e-input-focus.e-bigger input ~ label.e-float-text,
  .e-bigger .e-outline.e-float-input.e-control-wrapper.e-input-focus input ~ label.e-float-text {
    @if $input-skin-name == 'material' or $input-skin-name == 'Material3' {
      top: $outline-float-label-top;
      transform: translate3d($zero-value, $zero-value, $zero-value) scale(1);
    }
  }
}

@import "../base/base.gd.scss";
@import "../buttons/button.gd.scss";

$fab-offset: 16px !default;

$fab-border-radius: 9999px !default;
$fab-min-height: 40px !default;
$fab-min-width: 40px !default;
$fab-padding: 0 15px !default;
$fab-icon-font-size: 14px !default;

$fab-small-border-radius: $fab-border-radius !default;
$fab-small-min-height: 32px !default;
$fab-small-min-width: 32px !default;
$fab-small-padding: 0 11px !default;
$fab-small-icon-font-size: 12px !default;

$fab-bigger-border-radius: $fab-border-radius !default;
$fab-bigger-min-height: 52px !default;
$fab-bigger-min-width: 52px !default;
$fab-bigger-padding: 0 21px !default;
$fab-bigger-icon-font-size: 16px !default;

$fab-bigger-small-border-radius: $fab-border-radius !default;
$fab-bigger-small-min-height: 46px !default;
$fab-bigger-small-min-width: 46px !default;
$fab-bigger-small-padding: 0 17px !default;
$fab-bigger-small-icon-font-size: 14px !default;

$fab-box-shadow:  0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px rgba(0, 0, 0, .14), 0 1px 18px rgba(0, 0, 0, .12) !default;

@mixin fab-button-styles($border-radius, $min-height, $min-width, $padding, $icon-font-size) {
  border-radius: $border-radius;
  min-height: $min-height;
  min-width: $min-width;
  padding: $padding;

  &.e-icon-btn {
    padding: 0;
  }

  .e-btn-icon {
    font-size: $icon-font-size;
  }
}

@include export-module('floating-action-button-layout') {
  .e-fab.e-btn {
    align-items: center;
    border-radius: $fab-border-radius;
    display: inline-flex;
    min-height: $fab-min-height;
    min-width: $fab-min-width;
    padding: $fab-padding;
    position: absolute;
    z-index: 100000;

    .e-btn-icon {
      margin-top: 0;
      font-size: $fab-icon-font-size;
    }

    &.e-icon-btn {
      padding: 0;
    }

    &.e-fab-fixed {
      position: fixed;
    }

    &.e-fab-top {
      top: $fab-offset;
      &.e-fab-middle {
        top: 50%;
        transform: translateY(-50%);
        &.e-fab-left.e-fab-center {
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    &.e-fab-bottom {
      bottom: $fab-offset;
    }

    &.e-fab-left {
      left: $fab-offset;
      &.e-fab-center {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &.e-fab-right {
      right: $fab-offset;
    }
  }

  .e-rtl {
    &.e-fab.e-btn {
      &.e-fab-top {
        top: $fab-offset;
        &.e-fab-middle {
          top: 50%;
          transform: translateY(-50%);
          &.e-fab-right.e-fab-center {
            right: 50%;
            top: 50%;
            transform: translate(50%, -50%);
          }
        }
      }

      &.e-fab-bottom {
        bottom: $fab-offset;
      }

      &.e-fab-right {
        right: $fab-offset;
        &.e-fab-center {
          right: 50%;
          transform: translateX(50%);
        }
      }

      &.e-fab-left {
        left: $fab-offset;
      }
    }
  }

  .e-fab-hidden {
    visibility: hidden;
  }

  .e-small.e-fab.e-btn,
  .e-small .e-fab.e-btn {
    @include fab-button-styles($fab-small-border-radius, $fab-small-min-height, $fab-small-min-width, $fab-small-padding, $fab-small-icon-font-size);
  }
}


@include export-module('floating-action-button-theme') {
  .e-fab.e-btn {
    box-shadow: $fab-box-shadow;

    &:hover:not(:focus),
    &:active,
    &.e-active,
    &:disabled {
      box-shadow: $fab-box-shadow;
    }

    &:focus {
      @if ($skin-name != 'tailwind' and $skin-name != 'tailwind-dark' and $skin-name != 'tailwind3' and $skin-name != 'bootstrap4' and $skin-name != 'bootstrap5' and $skin-name != 'bootstrap5.3' and $skin-name != 'bootstrap5-dark') {
        box-shadow: $fab-box-shadow;
      }
      @if $skin-name == 'tailwind' {
        box-shadow: $fab-focus-box-shadow;
      }
    }
  }
}

@if $skin-name == 'tailwind3' {
  .e-fab.e-btn:focus-visible {
    box-shadow: $fab-focus-box-shadow !important; /* stylelint-disable-line declaration-no-important */
  }
}

@mixin fab-button-styles($border-radius, $min-height, $min-width, $padding, $icon-font-size) {
  border-radius: $border-radius;
  min-height: $min-height;
  min-width: $min-width;
  padding: $padding;

  &.e-icon-btn {
    padding: 0;
  }

  .e-btn-icon {
    font-size: $icon-font-size;
  }
}

@include export-module('floating-action-button-bigger') {
  .e-bigger.e-fab.e-btn,
  .e-bigger .e-fab.e-btn {
    @include fab-button-styles($fab-bigger-border-radius, $fab-bigger-min-height, $fab-bigger-min-width, $fab-bigger-padding, $fab-bigger-icon-font-size);
  }
  .e-bigger.e-small.e-fab.e-btn,
  .e-bigger.e-small .e-fab.e-btn,
  .e-bigger .e-small.e-fab.e-btn,
  .e-small .e-bigger.e-fab.e-btn {
    @include fab-button-styles($fab-bigger-small-border-radius, $fab-bigger-small-min-height, $fab-bigger-small-min-width, $fab-bigger-small-padding, $fab-bigger-small-icon-font-size);
  }
}

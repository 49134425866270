
$grid-xlfl-fieldset-padding: 15px !default;
$grid-xlfltd-padding: 12px !default;
$grid-xlfltable-border-spacing: 0 !default;
$grid-xlfl-dropdown-padding-right: 12px !default;
$grid-xlfl-radio-margin-left: 13px !default;
$grid-xlfl-min-height: 295px !default;
$grid-exlfltr-ctxtmnu-max-height: 298px !default;
$grid-bigger-exlfltr-ctxtmnu-max-height: 355px !default;
$grid-xlfl-li-left-padding: 28px !default;
$grid-xlfl-li-icon-margin-right: 12px !default;
$grid-xlfl-dlg-padding-bottom: 6px !default;
$grid-xlfltd-radio-mtcase-padding: 12px !default;
$grid-xlfl-fieldset-font-size: 14px !default;
$grid-xlfl-dlgfields-font-size: 17px !default;
$grid-xlfl-li-height: 36px !default;
$grid-xlfl-radiodiv-width: 117px !default;
$grid-xl-radio-padding-left: 23px !default;
$grid-xl-radio-padding-right: 13px !default;
$grid-xl-match-margin-left: 6px !default;
$grid-bigger-xlfl-li-height: 48px !default;
$grid-bigger-xlfl-fieldset-font-size: 15px !default;
$grid-bigger-xlfl-dlgfields-font-size: 18px !default;
$grid-bigger-xlfltd-radio-mtcase-padding: 16px !default;
$grid-bigger-xlfl-dlg-padding-bottom: 8px !default;
$grid-bigger-xlfl-li-icon-margin-right: 15px !default;
$grid-bigger-xlfl-li-left-padding: 35px !default;
$grid-bigger-xlfl-min-height: 396px !default;
$grid-bigger-xlfl-fieldset-padding: 20px !default;
$grid-bigger-xlfltd-padding: 16px !default;
$grid-bigger-xlfl-radio-margin-left: 18px !default;
$grid-bigger-xlfltable-border-spacing: 0 !default;
$grid-bigger-xlfl-dropdown-padding-right: 16px !default;
$grid-bigger-xlfl-radiodiv-width: 137px !default;
$grid-bigger-xl-radio-padding-left: 28px !default;
$grid-bigger-xl-radio-padding-right: 5px !default;
$grid-bigger-xl-match-margin-left: 8px !default;
$grid-resize-border-width: 1px !default;
$grid-xl-rtl-radio-padding-left: 0 !default;
$grid-xl-rtl-radio-padding-right: 25px !default;
$grid-checkbox-cell-padding-top-bottom: 6px !default;
$grid-checkboxfiltertext-width: 150px !default;
$grid-column-chooser-footdiv: 1px 0 0 !default;
$grid-columnchooser-footer-border-color: rgba($grey-light-font, .12) !default;
$grid-columnchooser-footer-border-opacity: 1 !default;
$grid-autofill-color: $accent !default;
$grid-bigger-checkbox-padding-topbottom: 13px !default;
$grid-checkbox-padding-topbottom: 9px !default;
$grid-checkboxfiltertext-width: 150px !default;
$grid-checkbox-content-padding-left: 9px !default;
$grid-rtl-checkbox-content-padding-left: 18px !default;
$grid-bigger-checkbox-content-padding-left: 11px !default;
$grid-rtl-bigger-checkbox-content-padding-left: 24px !default;
$grid-columnchooser-footer-border-color: rgba($grey-dark-font, .12) !default;
$grid-columnchooser-footer-border-opacity: 1 !default;
$grid-filtered-color: $accent !default;
$sfgrid-xlfl-dlg-padding: 18px !default;
$grid-excel-filter-separator: 4px !default;
$grid-dialog-footer-color: $grey-white !default;
$grid-xlfl-dlg-content-top-padding: 0 !default;
$grid-xlfl-context-menu-border-radious: 0 !default;
$grid-excel-filter-bottom-padding: 0 !default;
$grid-xlfl-li-icon-margin-arrow-icon: 17px !default;
$grid-bigger-xlfl-li-icon-margin-arrow-icon: 0 !default;
$grid-xl-radio-margin-top: 0 !default;
$grid-xlfl-skin: 'material' !default;

@mixin padding-styles($left, $right) {
  padding-left: $left;
  padding-right: $right;
}

@mixin height-styles($height, $line-height) {
  height: $height;
  line-height: $line-height;
}

@mixin padding-position-styles {
  padding-bottom: 4px;
  position: static;
}

@include export-module('excel-filter-layout') {

  /*! Excel-Filter layout */
  .sf-grid .e-excelfilter {
    &.e-dialog .e-dlg-content {
      padding-left: 0;
      padding-right: 0;
    }
    .e-dlg-content {
      overflow: visible;
      padding: 0;
      @if $theme-name == 'Material3-dark' or $theme-name == 'tailwind3-dark' {
        background: unset;
      }
    }

    .e-searchcontainer {
      padding: $sfgrid-xlfl-dlg-padding;
      padding-top: 4px;
      @include padding-styles($grid-checkbox-content-padding-left, 24px);
    }

    &.e-rtl{
      .e-searchcontainer {
        padding: $sfgrid-xlfl-dlg-padding;
        padding-top: 4px;
        @include padding-styles($grid-rtl-checkbox-content-padding-left, 0);
      }
      .e-checkbox-wrapper .e-frame + .e-label {
        margin-left: 0;
        margin-right: 8px;
      }
    }

    .e-contextmenu-container {
      &.e-sfcontextmenu {
        display: inline-block;
        position: relative;
        width: 100%;
        .e-ul {
          position: absolute;
          .e-menu-item .e-menu-icon {
            @include height-styles(26px, 26px);
          }
          .e-menu-item:not(.e-separator) {
            @include height-styles(36px, 36px);
          }
          .e-separator {
            height: auto;
          }
        }
      }
      ul .e-menu-item .e-caret {
        margin-right: 24px;
        @if $grid-xlfl-skin == 'fluent2' or $grid-xlfl-skin == 'fluent2-dark' or $grid-xlfl-skin == 'bootstrap5' {
          line-height: 36px;
          padding: 0;
        }
        @if $grid-xlfl-skin == 'tailwind3' or $grid-xlfl-skin == 'tailwind3-dark' {
          line-height: 40px;
          padding: 0;
        }
      }
    }

    .e-searchbox {
      padding-left: 8px;
    }
  }

  .sf-grid-dlg.e-xlflmenu .e-xlfl-radiodiv {
    width: unset;
  }

  #{&}.e-xlflmenu {
    min-height: $grid-xlfl-min-height;

    .e-xlfl-dlgfields {
      font-size: $grid-xlfl-dlgfields-font-size;
      @if $grid-xlfl-skin == 'material3' {
        padding-top: 12px;
      }
      @if $grid-xlfl-skin == 'tailwind3' {
        color: $content-text-color;
      }
    }

    .e-xlfl-fieldset {
      font-size: $grid-xlfl-fieldset-font-size;
      padding-top: $grid-xlfl-fieldset-padding;
      @if $grid-xlfl-skin == 'tailwind3' {
        color: $content-text-color;
      }
    }

    .e-responsive-dialog & table.e-xlfl-table tr.e-xlfl-fields:nth-child(2) td.e-xlfl-optr,
    .e-responsive-dialog & table.e-xlfl-table tr.e-xlfl-fields:nth-child(2) td.e-xlfl-value {
      @if $grid-xlfl-skin == 'material3' {
        padding-top: 16px;
      }
    }

    table.e-xlfl-table tr.e-xlfl-fields:nth-child(2) td.e-xlfl-optr,
    table.e-xlfl-table tr.e-xlfl-fields:nth-child(2) td.e-xlfl-value {
      @if $grid-xlfl-skin == 'material3' {
        padding-top: 0;
      }
    }

    .e-xlfl-optr,
    .e-xlfl-value {
      padding-top: $grid-xlfltd-padding;
    }

    .e-xlfl-radio,
    .e-xlfl-mtcase {
      padding-top: $grid-xlfltd-radio-mtcase-padding;
    }

    .e-xlfl-optr:first-child {
      padding-right: $grid-xlfl-dropdown-padding-right;
    }

    .e-xlfl-table {
      border-spacing: $grid-xlfltable-border-spacing;
      table-layout: fixed;
      width: 100%;
    }

    .e-radio + label .e-label {
      margin-top: $grid-xl-radio-margin-top;
      @include padding-styles($grid-xl-radio-padding-left, $grid-xl-radio-padding-right);
    }

    .e-xlfl-matchcasediv .e-checkbox-wrapper {
      .e-frame + .e-label {
        @if $grid-xlfl-skin == 'material3' {
          max-width: none;
        }
      }
    }

    .e-checkbox-wrapper {
      .e-frame + .e-label {
        margin-bottom: 1px;
        margin-left: $grid-xl-match-margin-left;
        @if $grid-xlfl-skin == 'fluent2' {
          margin-bottom: 7px;
        }
        @if $grid-xlfl-skin == 'tailwind3' {
          margin-bottom: 2px;
        }
      }

      .e-frame {
        margin-bottom: 1px;
        @if $grid-xlfl-skin == 'fluent2' {
          margin-bottom: 8px;
        }
      }
    }

    .e-dlg-content {
      padding-bottom: $grid-xlfl-dlg-padding-bottom;
    }

    .e-xlfl-radiodiv {
      width: $grid-xlfl-radiodiv-width;
    }

    .e-xlfl-matchcasediv {
      margin-left: 0;
      margin-right: 13px;
    }
  }

  .e-xlfl-radio-or,
  .e-xlfl-matchcasediv {
    margin-left: $grid-xlfl-radio-margin-left;
  }

  .e-emptyicon {
    opacity: 0;
  }

  #{&}.e-xlflmenu.e-rtl {
    .e-xlfl-optr {
      @include padding-styles($grid-xlfl-dropdown-padding-right, 0);
    }

    .e-radio + label .e-label {
      @include padding-styles($grid-xl-rtl-radio-padding-left, $grid-xl-rtl-radio-padding-right);
    }

    .e-xlfl-radio-or {
      margin-left: 0;
      margin-right: 13px;
    }
  }

  #{&}.e-excelfilter {
    .e-checkboxlist {
      height: 200px;
      margin-top: 5px;
      min-height: 160px;
      overflow-y: auto;
      @if $grid-xlfl-skin == 'fluent2' {
        margin-left: -12px;
      }
    }

    .e-checkboxfiltertext {
      width: $grid-checkboxfiltertext-width;
      word-break: normal;
    }

    .e-responsive-dialog & .e-checkboxfiltertext {
      @if $grid-xlfl-skin == 'material3' {
        width: 180px;
      }
    }

    .e-chk-hidden {
      -moz-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
      height: 1px;
      opacity: 0;
      width: 1px;
    }

    .e-contextmenu-wrapper ul li,
    .e-contextmenu-container ul li {
      @if $grid-xlfl-skin == 'fluent2' {
        padding: 0 12px;
      }
      @include height-styles($grid-xlfl-li-height, $grid-xlfl-li-height);
      .e-menu-icon {
        @include height-styles($grid-xlfl-li-height, $grid-xlfl-li-height);
        margin-right: $grid-xlfl-li-icon-margin-right;
      }
    }

    .e-contextmenu-wrapper ul .e-menu-item .e-caret {
      @include height-styles($grid-xlfl-li-height, $grid-xlfl-li-height);
    }

    .e-contextmenu-wrapper ul,
    .e-contextmenu-container ul:not(.e-ul) {
      border: 0;
      border-radius: $grid-xlfl-context-menu-border-radious;
      box-shadow: none;
      max-width: 300px;
      @include padding-position-styles;
      @if $grid-xlfl-skin == 'fluent2' {
        background-color: $content-bg-color;
      }
    }

    .e-dlg-content {
      @if $grid-xlfl-skin == 'material3' {
        background: $grid-dialog-footer-color;
      }
      @else {
        background-color: $grid-dialog-footer-color;
      }
      padding-top: 0;
      padding-top: $grid-xlfl-dlg-content-top-padding;
    }

    .e-searchbox,
    .e-contextmenu-wrapper,
    .e-contextmenu-wrapper ul {
      display: block;
    }

    .e-contextmenu-wrapper ul {
      @include padding-position-styles;
      @if $grid-xlfl-skin == 'fluent2' {
        padding: 4px 0;
      }
      li .e-contextmenu-container ul li {
        @include height-styles($grid-xlfl-li-height, $grid-xlfl-li-height);
      }
      .e-menu-item .e-caret {
        @include height-styles($grid-xlfl-li-height, $grid-xlfl-li-height);
        margin-right: $grid-xlfl-li-icon-margin-arrow-icon;
        @if $grid-xlfl-skin == 'fluent2' {
          margin-top: -3px;
          margin-right: 17px;
        }
        @if $grid-xlfl-skin == 'tailwind3' {
          margin-top: 1px;
        }
        @if $grid-xlfl-skin == 'tailwind3' {
          font-size: 18px;
        }
      }
    }

    .e-contextmenu-wrapper ul li .e-menu-icon,
    .e-contextmenu-container ul li .e-menu-icon {
      @include height-styles($grid-xlfl-li-height, $grid-xlfl-li-height);
      margin-right: $grid-xlfl-li-icon-margin-right;
      @if $grid-xlfl-skin == 'tailwind3' {
        font-size: 20px;
        &.e-icon-check {
          font-size: 18px;
        }
      }
    }

    &.e-rtl {
      .e-contextmenu-wrapper ul {
        li .e-menu-icon {
          margin-right: 0;
        }
        .e-menu-item .e-caret {
          @if $grid-xlfl-skin == 'material3' {
            margin-left: 14px;
          }
          @else {
            margin-left: $grid-xlfl-li-icon-margin-arrow-icon;
          }
        }
      }
    }

    .e-footer-content {
      border-style: solid;
      border-width: $grid-column-chooser-footdiv;
    }

    .e-excel-menu {
      z-index: 1000;
    }

    .e-xlsel {
      border: 0 solid;
      position: absolute;
      user-select: none;
    }

    &.e-dialog .e-dlg-content {
      padding-left: $grid-checkbox-content-padding-left;
      @if $grid-xlfl-skin == 'material3' {
        padding-right: 16px;
      }
    }

    .e-ftrchk,
    .e-searchbox {
      padding-left: $grid-checkbox-padding-topbottom;
    }

    &.e-rtl {
      .e-dlg-content {
        @include padding-styles($grid-rtl-checkbox-content-padding-left, $grid-checkbox-content-padding-left);
      }

      .e-ftrchk,
      .e-searchbox {
        @include padding-styles(0, $grid-checkbox-padding-topbottom);
      }
    }
  }

  .e-ftrchk {
    padding-bottom: $grid-checkbox-padding-topbottom;
    padding-top: $grid-checkbox-padding-topbottom;
    @if $grid-xlfl-skin == 'fluent2' {
      padding-bottom: 4px;
      padding-top: 4px;
    }
  }

  @if $grid-xlfl-skin == 'fluent2' {
    .e-ftrchk.e-infinitescroll {
      padding-left: 0;
      margin-left: 0;
    }
  }
}

.e-excel-menu.e-contextmenu.e-menu-parent {
  max-height: $grid-exlfltr-ctxtmnu-max-height;
  overflow-y: auto;
}


@include export-module('excel-filter-theme') {

  /*! Excel-Filter theme */
  #{&}.e-excelfilter {
    .e-footer-content {
      border-color: $grid-columnchooser-footer-border-color;
      opacity: $grid-columnchooser-footer-border-opacity;
    }

    .e-filtered::before {
      color: $grid-filtered-color;
    }

    .e-menu-item.e-focused .e-menu-icon.e-filtered::before {
      @if $grid-xlfl-skin == 'fluent2' {
        color: $icon-color-hover;
      }
    }

    .e-dlg-content {
      padding-bottom: $grid-excel-filter-bottom-padding;
      @if $grid-xlfl-skin == 'material3' {
        border-radius: 0;
      }
    }
  }

  li.e-separator.e-menu-item.e-excel-separator {
    margin-bottom: $grid-excel-filter-separator;
    margin-top: $grid-excel-filter-separator;
  }

  .e-xlsel {
    background-color: $grid-autofill-color;
    border-color: $grid-autofill-color;
  }
}


@include export-module('excel-filter-material-icons') {

  .e-icon-check::before {
    content: '\e7ff';
  }

  #{&}.e-excelfilter {
    .e-icon-filter::before {
      content: '\e7ee';
    }

    .e-excl-filter-icon::before {
      content: '\e251';
    }

    .e-excl-filter-icon.e-filtered::before {
      content: '\e248';
    }

    .e-search-icon::before {
      content: '\e993';
    }

    .e-chkcancel-icon::before {
      content: '\e7a7';
    }

    .e-sortascending::before {
      content: '\e734';
    }

    .e-sortdescending::before {
      content: '\e733';
    }
  }
}

@mixin padding-styles($left, $right) {
  padding-left: $left;
  padding-right: $right;
}
  
@mixin height-styles($height, $line-height) {
  height: $height;
  line-height: $line-height;
}
  
@include export-module('excel-filter-bigger') {

  /*! Excel-Filter bigger */
  .sf-grid .e-excelfilter {
    &.e-bigger {
      .e-contextmenu-wrapper ul .e-menu-item .e-caret {
        @if $grid-xlfl-skin == 'fluent2' {
          margin-right: 24px;
        }
      }
      
      .e-contextmenu-wrapper ul .e-menu-item.e-menu-caret-icon,
      &.e-contextmenu-wrapper ul .e-menu-item.e-menu-caret-icon,
      .e-contextmenu-container ul .e-menu-item.e-menu-caret-icon,
      &.e-contextmenu-container ul .e-menu-item.e-menu-caret-icon {
        @if $grid-xlfl-skin == 'fluent2' {
          padding: 0 36px 0 0;
        }
      }
    }
  }
  
  .e-bigger #{&}.e-xlflmenu.e-rtl {
    .e-radio + label .e-label {
      @include padding-styles($grid-xl-rtl-radio-padding-left, $grid-xl-rtl-radio-padding-right);
    }
  }
  
  .e-bigger.e-responsive-dialog #{&}.e-xlflmenu {
    .e-xlfl-dlgfields,
    .e-xlfl-fieldset {
      @if $grid-xlfl-skin == 'material3' {
        font-weight: 500;
        padding-top: 8px;
      }
    }
  
    .e-xlfl-optr:first-child {
      @if $grid-xlfl-skin == 'material3' {
        width: 70%;
      }
    }
  
    .e-xlfl-radio,
    .e-xlfl-mtcase,
    .e-xlfl-optr,
    .e-xlfl-value {
      @if $grid-xlfl-skin == 'material3' {
        padding-top: 24px;
      }
    }
  }
  
  .e-bigger #{&}.e-xlflmenu {
    min-height: $grid-bigger-xlfl-min-height;
  
    .e-xlfl-table {
      border-spacing: $grid-bigger-xlfltable-border-spacing;
      width: 100%;
    }
  
    .e-dlg-content {
      padding-bottom: $grid-bigger-xlfl-dlg-padding-bottom;
    }
  
    .e-radio + label .e-label {
      @include padding-styles($grid-bigger-xl-radio-padding-left, $grid-bigger-xl-radio-padding-right);
    }
  
    .e-checkbox-wrapper .e-frame + .e-label {
      margin-left: $grid-bigger-xl-match-margin-left;
      @if $grid-xlfl-skin == 'fluent2' {
        margin-top: 6px;
      }
    }
  
    .e-xlfl-radiodiv {
      width: $grid-bigger-xlfl-radiodiv-width;
    }
  
    .e-xlfl-radio-or,
    .e-xlfl-matchcasediv {
      margin-left: $grid-bigger-xlfl-radio-margin-left;
    }
  
    .e-xlfl-optr:first-child {
      padding-right: $grid-bigger-xlfl-dropdown-padding-right;
    }

    table.e-xlfl-table tr.e-xlfl-fields td.e-xlfl-value:nth-child(2) {
      @if $grid-xlfl-skin == 'tailwind3' {
        padding-left: 8px;
      }
    }
  
    &.e-rtl .e-xlfl-optr {
      padding-right: 0;
    }
  
    .e-xlfl-dlgfields {
      font-size: $grid-bigger-xlfl-dlgfields-font-size;
    }
  
    .e-xlfl-fieldset {
      font-size: $grid-bigger-xlfl-fieldset-font-size;
      padding-top: $grid-bigger-xlfl-fieldset-padding;
    }
  
    .e-xlfl-optr,
    .e-xlfl-value {
      padding-top: $grid-bigger-xlfltd-padding;
    }
  
    .e-xlfl-radio,
    .e-xlfl-mtcase {
      padding-top: $grid-bigger-xlfltd-radio-mtcase-padding;
    }
  }
  
  .e-bigger #{&}.e-excelfilter,
  #{&}.e-excelfilter.e-bigger {
    .e-xlflmenu {
      min-height: $grid-bigger-xlfl-min-height;
    }
  
    .e-ftrchk {
      padding-bottom: $grid-bigger-checkbox-padding-topbottom;
      padding-top: $grid-bigger-checkbox-padding-topbottom;
    }
  
    @if $grid-xlfl-skin == 'fluent2' {
      .e-ftrchk.e-infinitescroll {
        margin-left: 0;
        padding-left: 0;
      }
    }
  
    .e-contextmenu-wrapper ul li,
    .e-contextmenu-container ul li {
      @include height-styles($grid-bigger-xlfl-li-height, $grid-bigger-xlfl-li-height);
      @if $grid-xlfl-skin == 'fluent2' {
        padding: 0 12px;
      }
      .e-menu-icon {
        @include height-styles($grid-bigger-xlfl-li-height, $grid-bigger-xlfl-li-height);
        margin-right: $grid-bigger-xlfl-li-icon-margin-right;
        @if $grid-xlfl-skin == 'tailwind3' {
          font-size: 24px;
          &.e-icon-check {
            font-size: 22px;
          }
        }
      }
    }
  
    .e-contextmenu-wrapper ul .e-menu-item .e-caret {
      @include height-styles($grid-bigger-xlfl-li-height, $grid-bigger-xlfl-li-height);
      @if $grid-xlfl-skin == 'material3' {
        font-size: $text-xl;
        margin-right: 19px;
      }
      @else {
        margin-right: $grid-bigger-xlfl-li-icon-margin-arrow-icon;
      }
      @if $grid-xlfl-skin == 'fluent2' {
        margin-top: 2px;
      }
      @if $grid-xlfl-skin == 'tailwind3' {
        font-size: 22px;
      }
    }
  
    &.e-dialog .e-dlg-content {
      padding-left: $grid-bigger-checkbox-content-padding-left;
    }
  
    .e-ftrchk,
    .e-searchbox {
      padding-left: $grid-bigger-checkbox-padding-topbottom;
    }
  
    &.e-rtl {
      .e-contextmenu-wrapper ul {
        li .e-menu-icon {
          margin-right: 0;
        }
        .e-menu-item .e-caret {
          margin-left: $grid-bigger-xlfl-li-icon-margin-arrow-icon;
        }
      }
  
      .e-dlg-content {
        @include padding-styles($grid-rtl-bigger-checkbox-content-padding-left, $grid-bigger-checkbox-content-padding-left);
      }
  
      .e-ftrchk,
      .e-searchbox {
        @include padding-styles(0, $grid-bigger-checkbox-padding-topbottom);
      }
    }
  }
}
  
.e-bigger .e-excel-menu.e-contextmenu.e-menu-parent {
  max-height: $grid-bigger-exlfltr-ctxtmnu-max-height;
}

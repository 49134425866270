@import "../base/base.gd.scss";

// Size variables
$breadcrumb-skin: 'material' !default;
$breadcrumb-last-item-font-weight: 500 !default;
$breadcrumb-font-size: 14px !default;
$breadcrumb-bigger-font-size: 16px !default;
$breadcrumb-icon-font-size: 14px !default;
$breadcrumb-icon-bigger-font-size: 16px !default;
$breadcrumb-padding: 4px 8px !default;
$breadcrumb-padding-left: 0 !default;
$breadcrumb-padding-right: 0 !default;
$breadcrumb-bigger-padding: 4px 12px !default;
$breadcrumb-separator-padding: 4px 3px !default;
$breadcrumb-separator-bigger-padding: 4px 5px !default;
$breadcrumb-icon-right-padding: 8px !default;
$breadcrumb-icon-bigger-right-padding: 8px !default;
$breadcrumb-collapsed-icon-padding: 4px 4px !default;
$breadcrumb-collapsed-icon-bigger-padding: 5px 5px !default;
$breadcrumb-collapsed-icon-font-size: 16px !default;
$breadcrumb-collapsed-icon-bigger-font-size: 18px !default;
$breadcrumb-sibling-separator-padding: 0 !default;
$breadcrumb-sibling-separator-margin-left: -4px !default;
$breadcrumb-icon-item-padding: 2px 0 !default;
$breadcrumb-scroll-mode-lineheight: 26px !default;
$breadcrumb-popup-item-padding: 0 16px !default;
$breadcrumb-popup-border-radius: 0 !default;
$breadcrumb-popup-item-height: 36px !default;
$cmenu-li-hover-outline-offset: 0 !default;
$breadcrumb-popup-item-bigger-height: 48px !default;

// Color variables
$breadcrumb-item-color: rgba($grey-light-font, .65) !default;
$breadcrumb-separator-color: rgba($grey-light-font, .54) !default;
$breadcrumb-item-hover-color: rgba($grey-light-font, .87) !default;
$breadcrumb-item-focus-color: rgba($grey-light-font, .87) !default;
$breadcrumb-item-active-color: rgba($grey-light-font, .87) !default;
$breadcrumb-last-item-font-color: rgba($grey-light-font, .87) !default;
$breadcrumb-icon-color: rgba($grey-light-font, .54) !default;
$breadcrumb-collapsed-icon-hover-bgcolor: $grey-100 !default;
$breadcrumb-collapsed-icon-active-bgcolor: $grey-200 !default;
$breadcrumb-collapsed-icon-focus-bgcolor: $grey-100 !default;
$breadcrumb-collapsed-icon-color: rgba($grey-light-font, .54) !default;
$breadcrumb-collapsed-icon-hover-color: rgba($grey-light-font, .87) !default;
$breadcrumb-disabled-item-color: rgba($grey-light-font, .26) !default;
$breadcrumb-popup-item-color: $grey-700 !default;
$breadcrumb-popup-border: none !default;
$breadcrumb-popup-bgcolor: $grey-white !default;
$breadcrumb-popup-box-shadow: 0 5px 5px -3px rgba($grey-black, .2), 0 8px 10px 1px rgba($grey-black, .14), 0 3px 14px 2px rgba($grey-black, .12) !default;
$breadcrumb-popup-item-hover-bgcolor: $grey-200 !default;
$breadcrumb-popup-item-hover-color: rgba($grey-light-font, .87) !default;
$breadcrumb-popup-item-hover-outline: 0 solid rgba($grey-light-font, .12) !default;

@include export-module('breadcrumb-layout') {
  .e-breadcrumb {
    display: block;

    &.e-breadcrumb-wrap-mode {
      display: flex;
    }

    .e-breadcrumb-first-ol {
      align-items: flex-start;
      flex-shrink: 0;
      padding-right: 0;
    }

    .e-breadcrumb-wrapped-ol {
      flex-wrap: wrap;
      overflow: hidden;
      padding-left: 0;
    }

    &.e-breadcrumb-scroll-mode {
      line-height: $breadcrumb-scroll-mode-lineheight;
      overflow: auto;
    }

    ol {
      align-items: center;
      display: inline-flex;
      line-height: normal;
      margin: 0;
      padding: 0;
      padding-left: $breadcrumb-padding-left;
      padding-right: $breadcrumb-padding-right;
      @if $breadcrumb-skin == 'tailwind3' {
        font-weight: $font-weight-medium;
      }
    }

    @if $breadcrumb-skin == 'bootstrap' or $breadcrumb-skin == 'Material3' {
      background-color: $breadcrumb-bgcolor;
      border-radius: 4px;
    }

    li {
      list-style-type: none;
      white-space: nowrap;
    }

    .e-breadcrumb-item {
      align-items: center;
      display: flex;
      flex-shrink: 0;

      .e-breadcrumb-text {
        @if $skin-name == 'FluentUI' {
          padding: 4px;
        }
        @else {
          padding: $breadcrumb-padding;
        }

        @if $breadcrumb-skin == 'fabric' or $breadcrumb-skin == 'highcontrast' or $breadcrumb-skin == 'bootstrap5' or $breadcrumb-skin == 'bootstrap5.3' or $breadcrumb-skin == 'tailwind' {
          &:focus {
            padding: $breadcrumb-item-focus-padding;
          }
        }
      }

      &.e-icon-item .e-breadcrumb-text {
        @if $skin-name == 'fluent2' {
          padding: 9px;
        }
        @if $skin-name == 'tailwind3' {
          padding: 3px;
        }
      }

      @if $breadcrumb-skin == 'bootstrap5' or $breadcrumb-skin == 'bootstrap5.3' or $breadcrumb-skin == 'tailwind' {
        &.e-icon-item .e-breadcrumb-text:focus {
          padding: $breadcrumb-icon-only-item-focus-padding;
        }
      }

      .e-breadcrumb-text {
        align-items: center;
        display: flex;
        font-size: $breadcrumb-font-size;

        @if $breadcrumb-skin == 'fabric' or $breadcrumb-skin == 'highcontrast' {
          font-weight: $breadcrumb-last-item-font-weight;
        }

        .e-anchor-wrap {
          align-items: inherit;
          display: inherit;
          @if $breadcrumb-skin == 'fluent2' {
            line-height: 20px;
          }
        }
      }

      a.e-breadcrumb-text {
        cursor: pointer;
        text-decoration: none;
        @if $skin-name == 'FluentUI' {
          border: 1px solid transparent;
        }

        @if $breadcrumb-skin == 'bootstrap5' or $breadcrumb-skin == 'bootstrap5.3' {
          text-decoration: underline;
        }

        &:hover {
          @if $skin-name == 'FluentUI' or $skin-name == 'fluent2' {
            background-color: $content-bg-color-hover;
            color: $breadcrumb-item-hover-color;
          }
          @else {
            text-decoration: underline;
          }

          & .e-breadcrumb-icon.e-home,
          & .e-breadcrumb-icon {
            @if $skin-name == 'fluent2' {
              color: $toolbar-icon-color;
            }
          }
        }

        &:focus {
          @if $skin-name == 'FluentUI' {
            border: 1px solid $border-alt;
            color: $breadcrumb-item-active-color;
          }
          @else if $skin-name != 'fluent2' {
            text-decoration: underline;
          }

          &:not(:focus-visible) {
            @if $skin-name == 'fluent2' {
              background-color: $content-bg-color-hover;
              color: $breadcrumb-item-active-color;
            }

            & .e-breadcrumb-icon.e-home {
              @if $skin-name == 'fluent2' {
                color: $primary;
              }
            }
          }
        }

        &:active {
          @if $skin-name == 'FluentUI' or $skin-name == 'fluent2' {
            background-color: $breadcrumb-item-active-bg-color;
            color: $breadcrumb-item-active-color;
          }
          @else {
            text-decoration: underline;
          }
          @if $skin-name == 'bootstrap5.3' {
            box-shadow: none !important; /* stylelint-disable-line declaration-no-important */
          }

          & .e-breadcrumb-icon.e-home {
            @if $skin-name == 'fluent2' {
              color: $primary;
            }
          }
        }

        @if $breadcrumb-skin == 'fabric' or $breadcrumb-skin == 'highcontrast' or $breadcrumb-skin == 'tailwind' or $breadcrumb-skin == 'Material3' {
          &:hover,
          &:focus,
          &:active {
            text-decoration: none;
          }
        }

        &:focus-visible {
          @if $skin-name == 'fluent2' {
            box-shadow: $secondary-shadow-focus;
            color: $breadcrumb-item-color !important; /* stylelint-disable-line declaration-no-important */
            background-color: transparent;

            & .e-breadcrumb-icon,
            &.e-breadcrumb-url .e-breadcrumb-icon {
              color: $icon-color !important; /* stylelint-disable-line declaration-no-important */
            }
          }
        }
      }

      span.e-breadcrumb-text {
        cursor: default;
      }

      .e-breadcrumb-icon {
        font-size: $breadcrumb-icon-font-size;
        padding-right: $breadcrumb-icon-right-padding;

        @if $breadcrumb-skin == 'fabric' or $breadcrumb-skin == 'highcontrast' {
          padding-left: $breadcrumb-icon-right-padding;
        }
        &:hover {
          text-decoration: none;
        }

        &::before {
          display: inline-block;
          text-decoration: none;
        }

        @if $breadcrumb-skin == 'bootstrap5' or $breadcrumb-skin == 'bootstrap5.3' {
          &::before {
            vertical-align: middle;
          }

          &.e-home::before {
            vertical-align: sub;
          }
        }

        &.e-icons.e-home {
          @if $breadcrumb-skin == 'bootstrap' {
            font-size: 12px;
            margin-top: -1px;
          }
        }
      }

      &.e-icon-item .e-breadcrumb-icon {
        padding: $breadcrumb-icon-item-padding;
      }

      @if $breadcrumb-skin == 'bootstrap5' or $breadcrumb-skin == 'bootstrap5.3' or $breadcrumb-skin == 'tailwind' or $breadcrumb-skin == 'bootstrap' {
        &.e-icon-item {
          .e-breadcrumb-text {
            padding: $breadcrumb-icon-only-item-padding;
          }

          @if $breadcrumb-skin == 'bootstrap' {
            margin-left: -16px;
            margin-right: -6px;
          }
        }
      }

      &.e-disabled {
        @if $breadcrumb-skin == 'bootstrap5' or $breadcrumb-skin == 'bootstrap5.3' {
          opacity: $breadcrumb-disabled-item-opacity;
        }
        @else{
          opacity: 1;
        }
        pointer-events: none;
      }
    }

    .e-breadcrumb-separator {
      display: flex;
      font-size: $breadcrumb-font-size;
      padding: $breadcrumb-separator-padding;
      @if $breadcrumb-skin == 'fluent2' {
        line-height: 14px;
      }
      @if $breadcrumb-skin == 'tailwind3' {
        font-size: $text-xs;
      }

      + .e-breadcrumb-separator {
        margin-left: $breadcrumb-sibling-separator-margin-left;
        padding-left: $breadcrumb-sibling-separator-padding;
      }
    }

    &.e-rtl .e-breadcrumb-separator + .e-breadcrumb-separator {
      margin-left: 0;
      margin-right: $breadcrumb-sibling-separator-margin-left;
      padding-left: nth($breadcrumb-separator-padding, 2);
      padding-right: $breadcrumb-sibling-separator-padding;
    }

    @if $breadcrumb-skin == 'bootstrap5' or $breadcrumb-skin == 'bootstrap5.3' {
      .e-icon-item + .e-breadcrumb-separator {
        padding-left: $breadcrumb-icon-only-item-next-separator-padding-left;
      }
    }

    .e-breadcrumb-collapsed,
    .e-breadcrumb-menu {
      cursor: pointer;
      font-size: $breadcrumb-collapsed-icon-font-size;
      padding: $breadcrumb-collapsed-icon-padding;
      vertical-align: bottom;

      @if $breadcrumb-skin == 'fabric' or $breadcrumb-skin == 'highcontrast' {
        &:focus {
          padding: $breadcrumb-collapsed-icon-focus-padding;
        }
      }

      @if $breadcrumb-skin == 'fabric' or $breadcrumb-skin == 'highcontrast' or $breadcrumb-skin == 'bootstrap5' or $breadcrumb-skin == 'bootstrap5.3' or $breadcrumb-skin == 'FluentUI' {
        margin-top: 2px;
      }
      @if $breadcrumb-skin == 'bootstrap5.3' {
        border-radius: 4px;
      }
    }

    .e-breadcrumb-menu {
      display: inline-block;
    }

    .e-breadcrumb-item-wrapper {
      display: flex;
    }

    &.e-icon-right,
    .e-icon-right,
    &.e-rtl {
      .e-breadcrumb-icon {
        padding-left: $breadcrumb-icon-right-padding;
        padding-right: 0;

        @if $breadcrumb-skin == 'fabric' or $breadcrumb-skin == 'highcontrast' {
          padding-right: $breadcrumb-icon-right-padding;
        }
      }
    }

    &.e-rtl.e-icon-right .e-breadcrumb-icon,
    &.e-rtl .e-icon-right .e-breadcrumb-icon {
      padding-left: 0;
      padding-right: $breadcrumb-icon-right-padding;

      @if $breadcrumb-skin == 'fabric' or $breadcrumb-skin == 'highcontrast' {
        padding-left: $breadcrumb-icon-right-padding;
      }
    }

    &.e-disabled {
      opacity: 1;

      .e-breadcrumb-item,
      .e-breadcrumb-separator,
      .e-breadcrumb-collapsed,
      .e-breadcrumb-menu {
        pointer-events: none;
      }
    }
  }

  .e-breadcrumb-popup {
    border: $breadcrumb-popup-border;
    border-radius: $breadcrumb-popup-border-radius;
    box-shadow: $breadcrumb-popup-box-shadow;
    position: absolute;

    ul {
      margin: 0;
      padding: 0;
    }

    .e-breadcrumb-item {
      list-style-type: none;
      white-space: nowrap;
      @if $breadcrumb-skin == 'fluent2' {
        margin: 4px;
      }

      .e-breadcrumb-text {
        align-items: center;
        display: flex;
        font-size: $breadcrumb-font-size;
        height: $breadcrumb-popup-item-height;
        line-height: $breadcrumb-popup-item-height;
        padding: $breadcrumb-popup-item-padding;
        width: 100%;
        @if $breadcrumb-skin == 'fluent2' {
          border-radius: 4px;
        }

        .e-anchor-wrap {
          align-items: inherit;
          display: inherit;
          width: inherit;
        }
      }

      .e-breadcrumb-icon {
        font-size: $breadcrumb-icon-font-size;
        padding-right: $breadcrumb-icon-right-padding;
      }

      &.e-icon-item .e-breadcrumb-icon {
        padding: 0;
      }

      a.e-breadcrumb-text {
        text-decoration: none;
      }
    }

    &.e-icon-right,
    .e-icon-right,
    &.e-rtl {
      .e-breadcrumb-icon {
        padding-left: $breadcrumb-icon-right-padding;
        padding-right: 0;
      }
    }

    &.e-icon-right,
    .e-icon-right {
      .e-breadcrumb-icon {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
      }
    }

    &.e-rtl.e-icon-right .e-breadcrumb-icon,
    &.e-rtl .e-icon-right .e-breadcrumb-icon {
      padding-left: 0;
      padding-right: $breadcrumb-icon-right-padding;
    }
  }

  @if $breadcrumb-skin == 'fluent2' {
    .e-breadcrumb .e-breadcrumb-item a.e-breadcrumb-text {
      border-radius: 4px;
    }

    .e-breadcrumb .e-breadcrumb-item.e-icon-item a.e-breadcrumb-text:active .e-breadcrumb-icon {
      background-color: $content-bg-color-pressed !important; /* stylelint-disable-line declaration-no-important */
    }
  }
  @if $skin-name == 'tailwind3' {
    .e-breadcrumb .e-breadcrumb-item a.e-breadcrumb-text {
      text-decoration: none;
      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
      }
    }
  }
}


@include export-module('breadcrumb-theme') {
  .e-breadcrumb {
    .e-breadcrumb-item {
      .e-breadcrumb-text {
        color: $breadcrumb-item-color;

        &:hover,
        &:focus {
          @if $skin-name == 'Material3' {
            background: $breadcrumb-item-hover-color;
            border-radius: 4px;
          }
          @else if $skin-name != 'bootstrap5.3' {
            color: $breadcrumb-item-hover-color;
          }
          @if $skin-name == 'fluent2' {
            background-color: $content-bg-color-hover;
            color: $breadcrumb-item-hover-color !important; /* stylelint-disable-line declaration-no-important */
            border-radius: 4px;

            & .e-breadcrumb-icon {
              color: $toolbar-icon-color;
            }
          }
        }

        &:focus {
          @if $skin-name == 'Material3' {
            background: $breadcrumb-item-focus-color;
          }
        }

        &:active {
          @if $skin-name == 'Material3' {
            background: $breadcrumb-item-active-color;
            border-radius: 4px;
          }
          @else if $skin-name != 'bootstrap5.3' {
            color: $breadcrumb-item-active-color;
          }
          @else if $skin-name == 'tailwind3' {
            color: $breadcrumb-item-hover-color;
          }
        }
      }

      @if $breadcrumb-skin == 'fabric' or $breadcrumb-skin == 'highcontrast' {
        &:not([data-active-item]):not(.e-breadcrumb-separator) {
          &:hover {
            background: $breadcrumb-item-hover-bgcolor;
          }

          &:active {
            background: $breadcrumb-item-active-bgcolor;
          }
        }
      }

      @if $breadcrumb-skin == 'material' or $breadcrumb-skin == 'bootstrap' or $breadcrumb-skin == 'bootstrap5' or $breadcrumb-skin == 'bootstrap5.3' or $breadcrumb-skin == 'tailwind' {
        &.e-icon-item {
          &:hover {
            background: $breadcrumb-collapsed-icon-hover-bgcolor;
            color: $breadcrumb-collapsed-icon-hover-color;
          }

          &:active {
            background: $breadcrumb-collapsed-icon-active-bgcolor;
            color: $breadcrumb-collapsed-icon-hover-color;
          }

          @if $breadcrumb-skin == 'material' or $breadcrumb-skin == 'bootstrap' {
            .e-breadcrumb-text:focus {
              background: $breadcrumb-collapsed-icon-focus-bgcolor;
              color: $breadcrumb-collapsed-icon-hover-color;
            }
          }
        }
      }

      @if $breadcrumb-skin == 'fabric' or $breadcrumb-skin == 'highcontrast' or $breadcrumb-skin == 'bootstrap5' or $breadcrumb-skin == 'tailwind' {
        .e-breadcrumb-text:focus {
          border: $breadcrumb-item-focus-border;
        }
      }

      @if $breadcrumb-skin == 'bootstrap5.3' {
        .e-breadcrumb-text:focus {
          box-shadow: $keyboard-focus-shadow;
          border-radius: 4px;
        }
      }

      @if $skin-name == 'tailwind3' {
        .e-breadcrumb-text {
          &:focus {
            box-shadow: none;
          }
          &:focus-visible {
            box-shadow: $shadow-focus-ring2;
          }
        }
        &.e-disabled {
          .e-breadcrumb-text {
            &:focus,
            &:focus-visible {
              box-shadow: none;
              background: none;
            }
          }
        }
      }

      &[data-active-item] {
        .e-breadcrumb-text {
          color: $breadcrumb-last-item-font-color;
          font-weight: $breadcrumb-last-item-font-weight;
        }
      }

      .e-breadcrumb-icon {
        color: $breadcrumb-icon-color;
      }

      @if $breadcrumb-skin == 'highcontrast' {
        &:hover,
        .e-breadcrumb-text:focus {
          .e-breadcrumb-icon {
            color: $breadcrumb-icon-hover-color;
          }
        }

        &:active:not([data-active-item]) {
          .e-breadcrumb-icon {
            color: $breadcrumb-icon-active-color;
          }
        }
      }

      @if $breadcrumb-skin == 'bootstrap' {
        &.e-icon-item {
          &:hover,
          &:active,
          .e-breadcrumb-text:focus {
            .e-breadcrumb-icon {
              color: $breadcrumb-icon-interaction-color;
            }
          }
        }
      }
    }

    .e-breadcrumb-separator {
      color: $breadcrumb-separator-color;
    }

    .e-breadcrumb-collapsed,
    .e-breadcrumb-menu {
      color: $breadcrumb-collapsed-icon-color;

      &:hover {
        background: $breadcrumb-collapsed-icon-hover-bgcolor;
        @if $breadcrumb-skin != 'fluent2' {
          color: $breadcrumb-collapsed-icon-hover-color;
        }
      }

      &:active {
        background: $breadcrumb-collapsed-icon-active-bgcolor;
        @if $skin-name == 'FluentUI' {
          color: $breadcrumb-collapsed-icon-active-color;
        }
        @else if $breadcrumb-skin != 'fluent2' {
          color: $breadcrumb-collapsed-icon-hover-color;
        }
      }

      &:focus {
        @if $breadcrumb-skin == 'fabric' or $breadcrumb-skin == 'highcontrast' or $skin-name == 'FluentUI' {
          border: $breadcrumb-collapsed-icon-focus-border;
          color: $breadcrumb-collapsed-icon-focus-color;
        }
        @else {
          background: $breadcrumb-collapsed-icon-focus-bgcolor;
          @if $breadcrumb-skin != 'fluent2' {
            color: $breadcrumb-collapsed-icon-hover-color;
          }
        }
      }
      &:focus-visible {
        @if $skin-name == 'tailwind3' {
          box-shadow: $shadow-focus-ring2;
        }
      }
    }

    &.e-disabled .e-breadcrumb-item .e-breadcrumb-text,
    & .e-disabled.e-breadcrumb-item .e-breadcrumb-text,
    &.e-disabled .e-breadcrumb-separator {
      color: $breadcrumb-disabled-item-color;
      @if $breadcrumb-skin == 'fluent2' {
        background-color: $content-bg-color-disabled;
      }
      @if $skin-name == 'tailwind3' {
        color: $content-text-color-disabled;
      }

      .e-breadcrumb-icon {
        color: $breadcrumb-disabled-item-color;
        @if $breadcrumb-skin == 'bootstrap5' or $breadcrumb-skin == 'bootstrap5.3' {
          color: $breadcrumb-disabled-icon-color;
        }
        @if $skin-name == 'tailwind3' {
          color: $icon-color-disabled;
        }
      }
    }
  }

  .e-breadcrumb-popup {
    background: $breadcrumb-popup-bgcolor;

    .e-breadcrumb-text {
      color: $breadcrumb-popup-item-color;

      &:hover {
        background: $breadcrumb-popup-item-hover-bgcolor;
        color: $breadcrumb-popup-item-hover-color;
      }

      &:active {
        @if $breadcrumb-skin == 'bootstrap5' or $breadcrumb-skin == 'bootstrap5.3' or $breadcrumb-skin == 'bootstrap5.3' {
          background: $breadcrumb-popup-item-active-color;
          color: $breadcrumb-popup-item-text-active-color;
        }
        @if $skin-name == 'tailwind3' {
          background: $flyout-bg-color-pressed;
        }
      }

      &:focus-visible {
        @if $skin-name == 'tailwind3' {
          box-shadow: $shadow-focus-ring2;
        }
      }
    }

    .e-breadcrumb-item .e-breadcrumb-text:focus {
      background: $breadcrumb-popup-item-hover-bgcolor;
      color: $breadcrumb-popup-item-hover-color;
    }

    @if $breadcrumb-skin == 'fluent2' {
      .e-breadcrumb-item .e-breadcrumb-text:active {
        background: $flyout-bg-color-selected;
        color: $flyout-text-color-selected;
      }

      .e-breadcrumb-item.e-disabled .e-breadcrumb-text {
        color: $flyout-text-color-disabled;
      }

      .e-breadcrumb-item.e-disabled {
        opacity: 1 !important; /* stylelint-disable-line declaration-no-important */
      }
    }
  }

  @if $breadcrumb-skin == 'fluent2' {
    .e-breadcrumb .e-breadcrumb-item.e-icon-item .e-breadcrumb-text:focus .e-breadcrumb-icon,
    .e-breadcrumb .e-breadcrumb-item.e-icon-item .e-breadcrumb-text:active .e-breadcrumb-icon,
    .e-breadcrumb .e-breadcrumb-item.e-icon-item .e-breadcrumb-text:hover .e-breadcrumb-icon {
      color: $toolbar-icon-color !important; /* stylelint-disable-line declaration-no-important */
    }

    .e-breadcrumb .e-disabled.e-breadcrumb-item.e-icon-item .e-breadcrumb-text {
      background-color: transparent !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  @if $skin-name == 'tailwind3' {
    .e-breadcrumb .e-breadcrumb-item.e-icon-item:hover,
    .e-breadcrumb .e-breadcrumb-item.e-icon-item:active,
    .e-breadcrumb .e-breadcrumb-item.e-icon-item:focus {
      background: transparent;
    }

    .e-breadcrumb .e-breadcrumb-item.e-icon-item .e-breadcrumb-text:focus .e-breadcrumb-icon,
    .e-breadcrumb .e-breadcrumb-item.e-icon-item .e-breadcrumb-text:hover .e-breadcrumb-icon {
      color: $content-text-color-alt3;
    }

    .e-breadcrumb .e-breadcrumb-item.e-icon-item .e-breadcrumb-text:active .e-breadcrumb-icon {
      color: $primary;
    }
  }
}


@include export-module('breadcrumb-material-icons') {
  .e-breadcrumb {
    .e-breadcrumb-collapsed::before,
    .e-breadcrumb-menu::before {
      content: '\eb04';
    }

    .e-home {
      margin-top: -1px;

      &::before {
        content: '\e92b';
      }
    }
  }

  .e-bigger .e-breadcrumb,
  .e-bigger.e-breadcrumb {
    .e-home {
      margin-top: 0;
    }
  }
}

@include export-module('breadcrumb-bigger') {
  .e-bigger.e-breadcrumb,
  .e-bigger .e-breadcrumb {
    .e-breadcrumb-item {
      .e-breadcrumb-text {
        @if $skin-name == 'FluentUI' {
          padding: 8px;
        }
        @else {
          padding: $breadcrumb-bigger-padding;
        }

        @if $breadcrumb-skin == 'fabric' or $breadcrumb-skin == 'highcontrast' or $breadcrumb-skin == 'bootstrap5' or $breadcrumb-skin == 'bootstrap5.3' or $breadcrumb-skin == 'tailwind' {
          &:focus {
            padding: $breadcrumb-item-focus-bigger-padding;
          }
        }
      }

      @if $breadcrumb-skin == 'bootstrap5' or $breadcrumb-skin == 'bootstrap5.3' or $breadcrumb-skin == 'tailwind' {
        &.e-icon-item .e-breadcrumb-text:focus {
          padding: $breadcrumb-icon-only-item-focus-bigger-padding;
        }
      }

      @if $breadcrumb-skin == 'bootstrap5' or $breadcrumb-skin == 'bootstrap5.3' or $breadcrumb-skin == 'tailwind' or $breadcrumb-skin == 'bootstrap' {
        &.e-icon-item .e-breadcrumb-text {
          padding: $breadcrumb-icon-only-item-bigger-padding;
        }
      }

      @if $breadcrumb-skin == 'tailwind3' {
        &.e-icon-item .e-breadcrumb-text {
          padding: 5px !important; /* stylelint-disable-line declaration-no-important */
        }
      }

      .e-breadcrumb-text {
        font-size: $breadcrumb-bigger-font-size;

        .e-breadcrumb-icon {
          font-size: $breadcrumb-icon-bigger-font-size;
          padding-right: $breadcrumb-icon-bigger-right-padding;

          @if $breadcrumb-skin == 'fabric' or $breadcrumb-skin == 'highcontrast' {
            padding-left: $breadcrumb-icon-bigger-right-padding;
          }
        }

        .e-breadcrumb-icon.e-icons.e-home {
          @if $breadcrumb-skin == 'bootstrap' {
            font-size: 13px;
          }
        }
      }

      &.e-icon-item .e-breadcrumb-text .e-breadcrumb-icon {
        padding: $breadcrumb-icon-item-padding;
      }
    }

    .e-breadcrumb-separator {
      font-size: $breadcrumb-bigger-font-size;
      padding: $breadcrumb-separator-bigger-padding;

      + .e-breadcrumb-separator {
        padding-left: $breadcrumb-sibling-separator-padding;
      }
    }

    &.e-rtl .e-breadcrumb-separator + .e-breadcrumb-separator {
      padding-left: nth($breadcrumb-separator-bigger-padding, 2);
    }

    .e-breadcrumb-collapsed,
    .e-breadcrumb-menu {
      font-size: $breadcrumb-collapsed-icon-bigger-font-size;
      padding: $breadcrumb-collapsed-icon-bigger-padding;
      @if $breadcrumb-skin == 'fluent2' {
        line-height: 16px;
      }

      @if $breadcrumb-skin == 'fabric' or $breadcrumb-skin == 'highcontrast' {
        &:focus {
          padding: $breadcrumb-collapsed-icon-focus-bigger-padding;
        }
      }
    }

    &.e-icon-right,
    .e-icon-right,
    &.e-rtl {
      .e-breadcrumb-text .e-breadcrumb-icon {
        padding-left: $breadcrumb-icon-bigger-right-padding;
        padding-right: 0;

        @if $breadcrumb-skin == 'fabric' or $breadcrumb-skin == 'highcontrast' {
          padding-right: $breadcrumb-icon-bigger-right-padding;
        }
      }
    }

    &.e-rtl .e-icon-right .e-breadcrumb-text .e-breadcrumb-icon {
      padding-left: 0;
      padding-right: $breadcrumb-icon-bigger-right-padding;

      @if $breadcrumb-skin == 'fabric' or $breadcrumb-skin == 'highcontrast' {
        padding-left: $breadcrumb-icon-bigger-right-padding;
      }
    }
  }

  .e-bigger.e-breadcrumb-popup,
  .e-bigger .e-breadcrumb-popup {
    .e-breadcrumb-text {
      font-size: $breadcrumb-bigger-font-size;
      height: $breadcrumb-popup-item-bigger-height;
      line-height: $breadcrumb-popup-item-bigger-height;
      @if $skin-name == 'fluent2' {
        padding: 8px 8px 10px !important; /* stylelint-disable-line declaration-no-important */
      }

      .e-breadcrumb-icon {
        font-size: $breadcrumb-icon-bigger-font-size;
        padding-right: $breadcrumb-icon-bigger-right-padding;
      }
    }

    .e-icon-item .e-breadcrumb-text .e-breadcrumb-icon {
      padding: 0;
    }

    &.e-icon-right,
    .e-icon-right,
    &.e-rtl {
      .e-breadcrumb-icon {
        padding-left: $breadcrumb-icon-bigger-right-padding;
        padding-right: 0;
      }
    }

    &.e-rtl.e-icon-right .e-breadcrumb-icon,
    &.e-rtl .e-icon-right .e-breadcrumb-icon {
      padding-left: 0;
      padding-right: $breadcrumb-icon-bigger-right-padding;
    }
  }

  @if $breadcrumb-skin == 'fluent2' {
    .e-bigger .e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text .e-anchor-wrap {
      line-height: 22px !important; /* stylelint-disable-line declaration-no-important */
    }

    .e-bigger .e-breadcrumb .e-breadcrumb-separator {
      line-height: 16px !important; /* stylelint-disable-line declaration-no-important */
    }

    .e-bigger .e-breadcrumb .e-breadcrumb-item.e-icon-item .e-breadcrumb-text {
      padding: 12px !important; /* stylelint-disable-line declaration-no-important */
    }

    .e-bigger .e-breadcrumb-popup .e-breadcrumb-item {
      margin: 6px !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}

@import "../base/base.gd.scss";
@import "../popups/tooltip.gd.scss";

$stepper-ol-padding: 0 !default;
$stepper-step-radius: 9999px !default;
$stepper-step-width: 32px !default;
$stepper-icon-size: 16px !default;
$stepper-progress-bar-height: 1.6px !default;
$stepper-progress-value-height: 100% !default;
$stepper-progress-position: 50% !default;
$stepper-temp-outline-width: 2px solid !default;
$stepper-label-top: 1em !default;
$stepper-label-bottom: 4.5em !default;
$stepper-label-position: 2.5em !default;
$stepper-validation-position: .25em !default;
$stepper-text-padding: 0 6px !default;
$stepper-icon-text-padding: 0 8px !default;
$stepper-text-vert-padding: 6px 0 !default;
$stepper-label-optional: 3em !default;
$stepper-text-postion: 10px !default;
$stepper-optional-size: 12px !default;
$step-border: 2px solid !default;
$stepper-label-order: -1 !default;
$stepper-font-weight: 600 !default;
$stepper-text-size: 14px !default;
$stepper-tooltip-tip-bottom-height: 8px !default;

$stepper-small-step-width: 28px !default;
$stepper-small-icon-size: 14px !default;
$stepper-small-optional-size: 10px !default;
$stepper-small-text-size: 12px !default;

$stepper-bigger-step-width: 40px !default;
$stepper-bigger-icon-size: 18px !default;
$stepper-bigger-optional-size: 14px !default;
$stepper-bigger-text-size: 16px !default;

$stepper-bigger-small-step-width: 36px !default;
$stepper-bigger-small-icon-size: 16px !default;
$stepper-bigger-small-optional-size: 12px !default;
$stepper-bigger-small-text-size: 14px !default;

$step-selected-li-shadow: 0 0 0 2px $grey-white, 0 0 0 4px $primary, 0 0 0 8px $grey-white !default;
$step-keyboard-selected-li-shadow: 0 0 0 2px $grey-white, 0 0 0 4px $base-font, 0 0 0 8px $grey-white !default;
$step-valid-li-shadow: 0 0 0 2px $grey-white, 0 0 0 4px $msg-success-color-alt1, 0 0 0 8px $grey-white !default;
$step-error-li-shadow: 0 0 0 2px $grey-white, 0 0 0 4px darken($error-font, 11%), 0 0 0 8px $grey-white !default;
$step-li-shadow: 0 0 0 8px $grey-white !default;
$step-border-color: $grey-400 !default;
$step-hover-text-color: $primary-300-font !default;
$step-hover-not-text-color: $base-font !default;
$step-hover-border-color: $grey-400 !default;
$step-active-border-color: $grey-400 !default;
$stepper-outline-color: $grey-white !default;
$step-color: $base-font !default;
$step-optional-color: $grey-800 !default;
$step-bg-color: $primary-300-font !default;
$step-completed-color: $primary-300-font !default;
$step-completed-bg-color: $primary !default;
$step-progress-bg-color: $grey-400 !default;
$step-progress-value-bg-color: $primary !default;
$step-disabled-bg-color: $grey-300 !default;
$step-disabled-font-color: $grey-500 !default;
$step-disabled-color: $grey-600 !default;
$stepper-text-color: $base-font !default;
$step-valid-icon-only-color:$msg-success-color-alt1 !default;
$step-error-icon-only-color: $error-font !default;
$step-valid-completed-color: $primary-300-font !default;
$step-valid-completed-bg-color: $msg-success-color-alt1 !default;
$step-error-icon-color: $primary-300-font !default;
$step-error-completed-color: darken($error-font, 11%) !default;
$step-error-completed-bg-color: darken($error-font, 11%) !default;
$step-hover-color: $grey-500 !default;
$step-active-color: $grey-600 !default;
$step-selected-hover-color: darken($primary, 10%) !default;
$step-selected-active-color: darken($primary, 15%) !default;

@mixin stepper-styles($step-width, $icon-size, $text-size, $optional-size) {
  &.e-step-type-indicator .e-step-container:not(.e-step-item.e-step-label.e-step-text) {
    .e-icons.e-step-indicator {
      font-size: $step-width;
    }
    &.e-step-notstarted .e-icons.e-step-indicator {
      width: $step-width;
      height: $step-width;
    }
  }

  .e-step-container {
    .e-step,
    .e-indicator {
      min-width: $step-width;
      min-height: $step-width;
    }

    .e-indicator {
      font-size: $icon-size;
    }

    .e-step-text-container,
    .e-step-label-container {
      font-size: $text-size;
    }

    .e-step-content {
      font-size: $icon-size;
    }

    .e-step-label-optional {
      font-size: $optional-size;
    }
  }
  &.e-vertical .e-step-label-container.e-label-after .e-step-label-optional {
    font-size: $optional-size;
  }
}

@include export-module('stepper-layout') {
  .e-stepper {
    position: relative;
    .e-step-selected {
      .e-text,
      .e-label {
        font-weight: $stepper-font-weight;
      }
    }
    &.e-linear {
      .e-step-container {
        pointer-events: none;
      }
      .e-previous:not(.e-step-disabled),
      .e-next:not(.e-step-disabled),
      .e-step-selected:not(.e-step-disabled),
      .e-previous:not(.e-step-disabled) + li {
        pointer-events: auto;
      }
    }
    &.e-stepper-readonly,
    .e-step-disabled {
      cursor: default;
      pointer-events: none;
    }
    &.e-step-type-indicator .e-step-container {
      &:not(.e-step-item.e-step-label.e-step-text) {
        .e-icons.e-step-indicator {
          font-size: $stepper-step-width;
          border-radius: $stepper-step-radius;
        }
      }
    }

    &.e-step-type-indicator .e-step-content,
    &.e-step-type-label .e-step-content {
      padding: 0 8px;
    }

    //For Blazor Stepper Tooltip
    .e-step-container:has(.e-tooltip-wrap.e-stepper-tooltip),
    &.e-vertical.e-label-after .e-step-item.e-step-label:has(.e-tooltip-wrap.e-stepper-tooltip),
    &.e-vertical.e-label-before .e-step-item.e-step-label:has(.e-tooltip-wrap.e-stepper-tooltip) {
      position: relative;
    }
    &.e-horizontal .e-step-item.e-step-label:has(.e-tooltip-wrap.e-stepper-tooltip) {
      position: unset;
    }
    .e-tooltip-wrap.e-stepper-tooltip {
      display: none;
      justify-content: center;
      left: 50%;
      top: -$stepper-tooltip-tip-bottom-height;
      transform: translate(-50%, -100%);

      &.e-show-tooltip {
        display: inline-flex;
      }
      .e-tip-content {
        width: max-content;
      }
      .e-arrow-tip.e-tip-bottom {
        left: 50%;
        top: 100%;
        transform: translate(-50%, 0);
      }
      .e-arrow-tip-inner.e-tip-bottom {
        top: -6px;
      }
    }
    .e-step-item.e-step-label .e-tooltip-wrap.e-stepper-tooltip {
      left: unset;
      top: 0;
      transform: translate(0%, -135%);
    }
    &.e-step-type-indicator .e-tooltip-wrap.e-stepper-tooltip {
      transform: translate(-50%, -115%);
    }
    .e-stepper-steps {
      display: flex;
      padding: 0;
      margin: 0;
      justify-content: space-between;
      position: relative;
      align-items: flex-start;
      .e-step-container,
      .e-step,
      .e-step-valid.e-step-text.e-step-text-only .e-step-text-container,
      .e-step-error.e-step-text.e-step-text-only .e-step-text-container,
      .e-step-label-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .e-step-container {
      max-width: var(--max-width);
      list-style-type: none;
      cursor: pointer;
      text-align: center;
      .e-step-label-container,
      .e-step-text-container {
        max-width: clamp(100%, 10em, 100%);
        white-space: nowrap;
        font-size: $stepper-text-size;
      }
      .e-step-label-optional {
        font-size: $stepper-optional-size;
        flex-basis: 100%;
      }
      .e-step-optional-container {
        display: flex;
        flex-direction: column;
      }
      .e-step-content {
        font-size: $stepper-icon-size;
        line-height: 0;
      }
      .e-indicator {
        font-size: $stepper-icon-size;
      }
      &:not(.e-step-text-only) > .e-step-text-container {
        padding: $stepper-icon-text-padding;
      }
      &.e-step-text-only .e-step-text-container,
      &.e-step-label-only .e-step-label-container {
        padding: $stepper-text-padding;
        padding-block: 5px;
      }
      &.e-step-text-only.e-step-error .e-step-text-container,
      &.e-step-text-only.e-step-valid .e-step-text-container,
      &.e-step-label-only.e-step-error .e-step-label-container,
      &.e-step-label-only.e-step-valid .e-step-label-container {
        flex-direction: column;
        .e-step-validation-icon {
          position: relative;
          top: $stepper-validation-position;
        }
      }
      .e-step,
      .e-indicator {
        border-radius: $stepper-step-radius;
        min-width: $stepper-step-width;
        min-height: $stepper-step-width;
      }
    }
    .e-step-notstarted:not(.e-step-disabled) .e-step,
    .e-step-notstarted:not(.e-step-disabled) .e-indicator {
      border: $step-border;
    }
    &.e-horizontal {
      display: block;
      .e-stepper-steps {
        flex-direction: row;
      }
      &.e-label-before,
      &.e-label-after {
        &:has(.e-step-item.e-step-label:not(.e-step-text)) .e-stepper-progressbar {
          margin-left: var(--progress-left-position);
          top: var(--progress-top-position);
          width: var(--progress-bar-width);
        }
      }
      &.e-label-before.e-rtl,
      &.e-label-after.e-rtl {
        &:has(.e-step-item.e-step-label:not(.e-step-text)) .e-stepper-progressbar {
          margin-right: var(--progress-left-position);
          margin-left: unset;
        }
      }
      .e-step-item.e-step-label {
        gap: 1em;
        flex-direction: column;
      }
      .e-step-item.e-step-label .e-step-label-container {
        position: relative;
        &.e-label-before {
          order: -1;
        }
      }
      &.e-label-start .e-step-text-container {
        order: $stepper-label-order;
      }
      .e-step-item.e-step-text:not(.e-step-label) .e-step-label-optional {
        position: absolute;
        top: 2.5em;
        margin-left: 2.5em;
      }
      &.e-label-start .e-step-item.e-step-text:not(.e-step-label) .e-step-label-optional,
      &.e-rtl .e-step-item.e-step-text:not(.e-step-label) .e-step-label-optional {
        margin-right: 2.5em;
        margin-left: unset;
      }
      &.e-label-start.e-rtl .e-step-item.e-step-text:not(.e-step-label) .e-step-label-optional {
        margin-right: unset;
        margin-left: 2.5em;
      }
      &.e-label-before .e-stepper-steps {
        &:has(.e-step-label-optional) {
          align-items: flex-end;
        }
        .e-step-label-container.e-label-before:has(.e-step-label-optional) {
          flex-direction: column;
          gap: .5em;
        }
      }
      .e-text,
      .e-label,
      .e-step-label-optional {
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .e-step-text.e-step-text-only:has(.e-step-label-optional),
      .e-step-label.e-step-label-only:has(.e-step-label-optional),
      .e-step-item:not(.e-step-text):not(.e-step-label):has(.e-step-label-optional),
      .e-step-container:not(.e-step-item):not(.e-step-text):not(.e-step-label):has(.e-step-label-optional),
      .e-step-label-container.e-label-after:has(.e-step-label-optional),
      &.e-step-type-indicator .e-step-container {
        flex-wrap: wrap;
      }
      .e-step-item:not(.e-step-label):not(.e-step-text) .e-step-label-optional {
        margin-top: .5em;
      }
      &.e-label-after .e-step-item.e-step-label:not(.e-step-text) .e-step-label-optional {
        margin-top: .2em;
      }
    }
    &.e-vertical {
      min-height: inherit;
      height: inherit;
      display: inline-block;
      .e-step-label.e-step-label-only,
      .e-step-template {
        position: relative;
      }
      &:not(.e-label-top):not(.e-label-bottom) .e-step-label-optional {
        position: absolute;
      }
      &:not(.e-label-bottom):not(.e-label-top) .e-step-item.e-step-text:not(.e-step-label) .e-step-label-optional {
        margin-top: $stepper-label-optional;
        margin-left: 4em;
      }
      &.e-rtl:not(.e-label-bottom):not(.e-label-top) .e-step-item.e-step-text:not(.e-step-label) .e-step-label-optional {
        margin-left: unset;
        margin-right: 4em;
      }
      .e-stepper-steps {
        flex-direction: column;
        min-height: inherit;
        height: inherit;
        float: left;
        align-items: start;
        margin: $stepper-ol-padding;
        justify-content: space-between;
      }
      &.e-rtl .e-stepper-steps {
        float: right;
      }
      .e-stepper-steps:has(.e-step-item:not(.e-step-text):not(.e-step-label)),
      .e-stepper-steps:has(.e-step-text.e-step-text-only),
      .e-stepper-steps:has(.e-step-label.e-step-label-only),
      &.e-label-after .e-stepper-steps:has(.e-step-label.e-step-text:not(.e-step-item)),
      &.e-step-type-indicator .e-stepper-steps {
        align-items: center;
      }
      .e-step-item.e-step-text:not(.e-step-label) .e-step-text-container,
      .e-step-item.e-step-label:not(.e-step-text) .e-step-label-container.e-label-after {
        margin-left: $stepper-text-postion;
      }
      &.e-rtl {
        .e-step-item.e-step-text:not(.e-step-label) .e-step-text-container,
        .e-step-item.e-step-label:not(.e-step-text) .e-step-label-container.e-label-after {
          margin-left: unset;
          margin-right: $stepper-text-postion;
        }
      }
      .e-step-item:not(.e-step-label):not(.e-step-text):has(.e-step-label-optional),
      &.e-label-before .e-step-label-container.e-label-before:has(.e-step-label-optional),
      .e-step-text-only.e-step-text:has(.e-step-label-optional),
      .e-step-label-only.e-step-label:has(.e-step-label-optional) {
        flex-direction: column;
        .e-step-label-optional {
          position: unset;
        }
      }
      .e-step-item:not(.e-step-label):not(.e-step-text):has(.e-step-label-optional),
      &.e-label-before .e-step-label-container.e-label-before:has(.e-step-label-optional) {
        gap: .5em;
      }
      &.e-label-before .e-step-item.e-step-label,
      &.e-label-after .e-step-item.e-step-label {
        .e-step-label-container.e-label-after:has(.e-step-label-optional) {
          flex-direction: column;
          .e-label {
            line-height: 2em;
          }
        }
        .e-step-label-optional {
          position: unset;
        }
      }
      .e-step-container {
        &.e-step-label.e-step-text:not(.e-step-item) .e-step-label-container.e-label-after {
          position: absolute;
          .e-step-label-optional {
            left: $stepper-ol-padding;
          }
        }
        .e-step-label-container.e-label-after .e-step-label-optional {
          font-size: $stepper-optional-size;
        }
        .e-step-label-container.e-label-before .e-label {
          width: var(--label-width);
        }
        &:not(.e-step-text-only) .e-step-text-container {
          border: none;
          border-radius: unset;
          padding: $stepper-text-vert-padding;
        }
        &.e-step-text-only .e-step-text-container,
        &.e-step-label-only .e-step-label-container {
          position: unset;
        }
      }
      &.e-label-top .e-step-label-optional {
        padding-bottom: 6px;
      }
    }
    &.e-vertical.e-label-top,
    &.e-vertical.e-label-bottom {
      .e-stepper-steps {
        align-items: center;
        .e-step-container {
          flex-direction: column;
          .e-step-text-container {
            margin-left: $stepper-ol-padding;
          }
        }
      }
    }
    &.e-vertical.e-label-top .e-step-container {
      .e-step-label-optional,
      .e-step-text-container {
        order: $stepper-label-order;
      }
    }
    .e-stepper-progressbar {
      height: $stepper-progress-bar-height;
      position: absolute;
      top: var(--progress-top-position);
      width: $stepper-progress-value-height;

      >.e-progressbar-value {
        height: $stepper-progress-value-height;
        width: var(--progress-value);
        transition-property: width;
        transition-duration: var(--duration);
        transition-delay: var(--delay);
      }
    }
    &.e-vertical .e-stepper-progressbar {
      min-height: inherit;
      width: $stepper-progress-bar-height;
      top: $stepper-ol-padding;
      left: var(--progress-position);

      >.e-progressbar-value {
        width: $stepper-progress-value-height;
        height: var(--progress-value);
        transition-property: height;
      }
    }
    &.e-vertical.e-rtl .e-stepper-progressbar {
      right: var(--progress-position);
      left: unset;
    }
    &:not(.e-steps-focus):not(.e-step-type-label):not(.e-step-type-indicator) .e-step-container .e-indicator,
    .e-step-focus .e-indicator {
      z-index: 0;
    }
    .e-step-template.e-step-focus {
      outline: $stepper-temp-outline-width;
    }
  }
  .e-tooltip-wrap.e-stepper-tooltip.e-step-inprogress-tip {
    .e-arrow-tip.e-tip-bottom {
      top: 90% !important; /* stylelint-disable-line declaration-no-important */
    }
  }
  .e-small.e-stepper,
  .e-small .e-stepper {
    @include stepper-styles($stepper-small-step-width, $stepper-small-icon-size, $stepper-small-text-size, $stepper-small-optional-size);
  }
}


@mixin stepper-text-label-colors($step-label-text-color) {
  .e-text,
  .e-label {
    color: $step-label-text-color;
  }
}

@include export-module('stepper-theme') {
  .e-stepper {
    .e-step-notstarted:not(.e-step-disabled) .e-step,
    .e-step-notstarted:not(.e-step-disabled) .e-indicator,
    .e-step-notstarted .e-icons.e-step-indicator {
      border-color: $step-border-color;
    }
    .e-step-label-optional {
      color: $step-optional-color;
    }
    .e-step,
    .e-indicator {
      color: $step-color;
      background: $step-bg-color;
    }
    .e-step-indicator {
      color: transparent;
    }
    .e-step-completed .e-step,
    .e-step-inprogress .e-step,
    .e-step-completed .e-indicator,
    .e-step-inprogress .e-indicator,
    .e-step-completed .e-step-indicator,
    .e-step-inprogress .e-step-indicator {
      background: $step-completed-bg-color;
      color: $step-completed-color;
    }
    .e-step-selected .e-indicator {
      background: $step-completed-bg-color;
    }
    .e-step-valid .e-indicator {
      background: $step-valid-completed-bg-color;
      color: $step-valid-completed-color;
    }
    .e-step-error {
      .e-indicator {
        background: $step-error-completed-bg-color;
        color: $step-error-icon-color;
      }
      @include stepper-text-label-colors($step-error-completed-color);
    }
    .e-step-valid:not(.e-step-notstarted) .e-indicator:hover,
    .e-step-error .e-indicator:hover {
      color: $step-color;
    }
    .e-step-valid.e-step-text-only,
    .e-step-valid.e-step-label-only {
      .e-step-validation-icon {
        color: $step-valid-icon-only-color;
      }
    }
    .e-step-error.e-step-text-only,
    .e-step-error.e-step-label-only {
      .e-step-validation-icon {
        color: $step-error-icon-only-color;
      }
    }
    @include stepper-text-label-colors($stepper-text-color);
    .e-step-selected:not(.e-step-error),
    .e-step-completed:not(.e-step-error) {
      @include stepper-text-label-colors($step-completed-bg-color);
    }
    .e-step-label:not(.e-step-item) .e-step-label-container {
      background-color: $stepper-outline-color;
    }
    .e-step-text-container {
      background-color: $stepper-outline-color;
      border-color: transparent;
    }
    .e-step-disabled .e-indicator,
    .e-step-disabled .e-step {
      background: $step-disabled-bg-color;
      color: $step-disabled-color;
    }
    .e-step-disabled .e-text,
    .e-step-disabled .e-label,
    .e-step-disabled .e-step-validation-icon {
      color: $step-disabled-font-color;
    }
    &.e-vertical .e-step-text-only.e-step-text .e-step-label-optional,
    &.e-vertical .e-step-label-only.e-step-label .e-step-label-optional,
    &.e-vertical .e-step-item:not(.e-step-text):not(.e-step-label) .e-step-label-optional,
    &.e-vertical.e-label-top .e-step-label-optional,
    &.e-vertical.e-label-bottom .e-step-label-optional {
      background-color: $stepper-outline-color;
    }
    .e-stepper-progressbar {
      background-color: $step-progress-bg-color;
      >.e-progressbar-value {
        background-color: $step-progress-value-bg-color;
      }
    }
    &:not(.e-steps-focus) .e-step-selected .e-step {
      box-shadow: $step-selected-li-shadow;
    }
    .e-step-focus .e-step,
    .e-step-label-only.e-step-focus .e-label,
    .e-step-text-only.e-step-focus .e-text,
    .e-step-template.e-step-focus,
    &:not(.e-steps-focus) :focus-visible .e-step,
    &:not(.e-steps-focus) .e-step-text-only:focus-visible .e-text,
    &:not(.e-steps-focus) .e-step-label-only:focus-visible .e-label {
      box-shadow: $step-keyboard-selected-li-shadow;
    }

    &:not(.e-steps-focus) .e-step-valid.e-step-selected .e-step,
    .e-step-valid.e-step-focus .e-step,
    .e-step-valid.e-step-label-only.e-step-focus .e-label,
    .e-step-valid.e-step-text-only.e-step-focus .e-text,
    .e-step-valid.e-step-template.e-step-focus {
      box-shadow: $step-valid-li-shadow;
    }

    &:not(.e-steps-focus) .e-step-error.e-step-selected .e-step,
    .e-step-error.e-step-focus .e-step,
    .e-step-error.e-step-label-only.e-step-focus .e-label,
    .e-step-error.e-step-text-only.e-step-focus .e-text,
    .e-step-error.e-step-template.e-step-focus {
      box-shadow: $step-error-li-shadow;
    }

    .e-step-container:not(.e-step-focus).e-step-notstarted .e-step,
    .e-step-container:not(.e-step-focus).e-step-completed .e-step,
    &.e-steps-focus .e-step-container:not(.e-step-focus).e-step-selected .e-step {
      box-shadow: $step-li-shadow;
    }

    .e-step-notstarted:not(.e-step-disabled) .e-step:hover,
    .e-step-notstarted:not(.e-step-disabled) .e-indicator:hover {
      border-color: $step-hover-border-color;
    }

    .e-step-notstarted:not(.e-step-disabled) .e-step:active,
    .e-step-notstarted:not(.e-step-disabled) .e-indicator:active {
      border-color: $step-active-border-color;
    }

    &:not(.e-step-type-label) {
      .e-indicator:hover,
      .e-step:hover {
        color: $step-hover-text-color;
        background: $step-selected-hover-color;
      }
    }

    &:not(.e-step-type-label) .e-step-notstarted .e-indicator:hover,
    &:not(.e-step-type-label) .e-step-notstarted .e-step:hover {
      color: $step-hover-not-text-color;
    }

    &:not(.e-step-type-label) {
      .e-step-selected .e-indicator:active,
      .e-step-selected .e-step:active {
        background: $step-selected-active-color;
      }
      .e-step-valid.e-step-selected .e-indicator:active,
      .e-step-valid.e-step-selected .e-step:active {
        background: $step-valid-completed-bg-color;
      }
      .e-step-error.e-step-selected .e-indicator:active,
      .e-step-error.e-step-selected .e-step:active {
        background: $step-error-completed-bg-color;
      }
    }

    &:not(.e-step-type-label) .e-step-notstarted .e-indicator:hover,
    &:not(.e-step-type-label) .e-step-notstarted .e-step:hover {
      background: $step-hover-color;
    }

    &:not(.e-step-type-label) .e-step-notstarted .e-indicator:active,
    &:not(.e-step-type-label) .e-step-notstarted .e-step:active {
      background: $step-active-color;
    }

    &:not(.e-step-type-label) {
      .e-step-valid .e-indicator:hover,
      .e-step-valid .e-step:hover {
        color: $step-hover-text-color;
        background: $step-valid-completed-bg-color;
      }
      .e-step-error .e-indicator:hover,
      .e-step-error .e-step:hover {
        color: $step-hover-text-color;
        background: $step-error-completed-bg-color;
      }
    }
  }
}


@include export-module('stepper-material-icons') {
  .e-step-indicator::before {
    content: '\e99e';
  }
}

@mixin stepper-styles($step-width, $icon-size, $text-size, $optional-size) {
  &.e-step-type-indicator .e-step-container:not(.e-step-item.e-step-label.e-step-text) {
    .e-icons.e-step-indicator {
      font-size: $step-width;
    }
    &.e-step-notstarted .e-icons.e-step-indicator {
      width: $step-width;
      height: $step-width;
    }
  }

  .e-step-container {
    .e-step,
    .e-indicator {
      min-width: $step-width;
      min-height: $step-width;
    }

    .e-indicator {
      font-size: $icon-size;
    }

    .e-step-text-container,
    .e-step-label-container {
      font-size: $text-size;
    }

    .e-step-content {
      font-size: $icon-size;
    }

    .e-step-label-optional {
      font-size: $optional-size;
    }
  }
  &.e-vertical .e-step-label-container.e-label-after .e-step-label-optional {
    font-size: $optional-size;
  }
}

@include export-module('stepper-bigger') {
  .e-bigger.e-stepper,
  .e-bigger .e-stepper {
    @include stepper-styles($stepper-bigger-step-width, $stepper-bigger-icon-size, $stepper-bigger-text-size, $stepper-bigger-optional-size);
  }

  .e-bigger.e-small.e-stepper,
  .e-bigger.e-small .e-stepper,
  .e-bigger .e-small.e-stepper,
  .e-small .e-bigger.e-stepper {
    @include stepper-styles($stepper-bigger-small-step-width, $stepper-bigger-small-icon-size, $stepper-bigger-small-text-size, $stepper-bigger-small-optional-size);
  }
}

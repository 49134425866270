@import "../base/base.gd.scss";

$theme: 'Material' !default;
$spin-overlay-background: rgba($grey-black, .4) !default;
$spin-overlay-stroke-color: $accent !default;
$spin-overlay-font-color: $grey-white !default;
$spin-material-stroke-color: $accent !default;
$spin-boot4-stroke-color: $accent !default;
$spin-boot4-stroke-width: 4 !default;
$spin-fabric-stroke-color: rgba($accent, .4) !default;
$spin-fabric-stroke-width: 1.5 !default;
$spin-fabric-arc-color: $accent !default;
$spin-bootstrap-stroke-color: $accent !default;
$spin-label-font-family: 'Roboto', Segoe UI !default;
$spin-label-font-size: 13px !default;
$spin-label-margin-top: 16px !default;
$spin-label-color: rgba($grey-light-font, .87) !default;
$spin-padding: 10px !default;
$spin-boot5-stroke-width: 4;
$spin-tailwind-stroke-width: 4.5;
$spin-boot5-stroke-color: $accent;
$spin-tailwind-arc-color: $accent;
$spin-tailwind-stroke-color: rgba($accent, .4);

@mixin boot5-spinner-rotate {
  animation: material-spinner-rotate .75s linear infinite;
}

@mixin tw-spinner-rotate {
  animation: fabric-spinner-rotate 1.3s infinite cubic-bezier(.21, .21, .21, .21);
}

@mixin mat-spinner-rotate {
  animation: material-spinner-rotate 1568.63ms linear infinite;
}

@mixin fb-spinner-rotate {
  animation: fabric-spinner-rotate 1.3s infinite cubic-bezier(.53, .21, .29, .67);
}

@mixin boot4-spinner-rotate {
  animation: material-spinner-rotate .75s linear infinite;
}

/* stylelint-disable property-no-vendor-prefix */
@keyframes material-spinner-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes fabric-spinner-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@include export-module('spinner-layout') {
  .e-spinner-pane {
    align-items: center;
    display: inline-flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    user-select: none;
    vertical-align: middle;
    width: 100%;
    z-index: 1000;

    &::after {
      content: $theme;
      display: none;
    }

    &.e-spin-left {
      /* stylelint-disable property-no-vendor-prefix */
      .e-spinner-inner {
        -webkit-transform: translateX(0%) translateY(-50%);
        left: 0;
        padding-left: $spin-padding;
        transform: translateX(0%) translateY(-50%);
      }
    }

    &.e-spin-right {
      .e-spinner-inner {
        -webkit-transform: translateX(-100%) translateY(-50%);
        left: 100%;
        padding-right: $spin-padding;
        transform: translateX(-100%) translateY(-50%);
      }
    }

    &.e-spin-center {
      .e-spinner-inner {
        -webkit-transform: translateX(-50%) translateY(-50%);
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }

    &.e-spin-hide {
      display: none;
    }

    &.e-spin-show {
      display: inline-flex;
    }

    .e-spinner-inner {
      -webkit-transform: translateX(-50%) translateY(-50%);
      left: 50%;
      margin: 0;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: 1000;

      .e-spin-label {
        font-family: $spin-label-font-family;
        font-size: $spin-label-font-size;
        margin-top: $spin-label-margin-top;
        text-align: center;
      }

      .e-spin-material,
      .e-spin-material3,
      .e-spin-tailwind3 {
        @include mat-spinner-rotate;
        display: block;
        margin: 0 auto;

        .e-path-circle {
          fill: none;
          stroke-linecap: square;
        }
      }

      .e-spin-bootstrap4 {
        @include boot4-spinner-rotate;
        border-radius: 50%;
        display: block;
        margin: 0 auto;

        .e-path-circle {
          fill: none;
          stroke-linecap: square;
          stroke-width: $spin-boot4-stroke-width;
        }
      }

      .e-spin-fluent,
      .e-spin-fluent2,
      .e-spin-fabric {
        @include fb-spinner-rotate;
        display: block;
        margin: 0 auto;
        overflow: visible;

        .e-path-arc,
        .e-path-circle {
          fill: none;
          stroke-width: $spin-fabric-stroke-width;
        }
      }

      .e-spin-tailwind {
        @include tw-spinner-rotate;
        display: block;
        margin: 0 auto;
        overflow: visible;

        .e-path-arc,
        .e-path-circle {
          fill: none;
          stroke-width: $spin-tailwind-stroke-width;
        }
      }

      .e-spin-bootstrap5,
      .e-spin-bootstrap5v3 {
        @include boot5-spinner-rotate;
        border-radius: 50%;
        display: block;
        margin: 0 auto;

        .e-path-circle {
          fill: none;
          stroke-linecap: square;
          stroke-width: $spin-boot5-stroke-width;
        }
      }

      .e-spin-bootstrap {
        display: block;
        margin: 0 auto;
      }

      .e-spin-high-contrast {
        @include fb-spinner-rotate;
        display: block;
        margin: 0 auto;
        overflow: visible;

        .e-path-arc,
        .e-path-circle {
          fill: none;
          stroke-width: $spin-fabric-stroke-width;
        }
      }
    }
  }
}


@include export-module('spinner-theme') {
  .e-spinner-pane {
    background-color: transparent;

    &.e-spin-overlay {
      background-color: $spin-overlay-background;

      .e-spinner-inner {

        .e-spin-label {
          color: $spin-overlay-font-color;
        }

        .e-spin-bootstrap {
          fill: $spin-overlay-stroke-color;
          stroke: $spin-overlay-stroke-color;
        }
      }
    }

    .e-spinner-inner {
      .e-spin-label {
        color: $spin-label-color;
      }

      .e-spin-fluent,
      .e-spin-fluent2,
      .e-spin-fabric {

        .e-path-circle {
          stroke: $spin-fabric-stroke-color;
        }

        .e-path-arc {
          stroke: $spin-fabric-arc-color;
        }
      }

      .e-spin-tailwind {
        .e-path-circle {
          stroke: $spin-tailwind-stroke-color;
        }

        .e-path-arc {
          stroke: $spin-tailwind-arc-color;
        }
      }

      .e-spin-bootstrap5,
      .e-spin-bootstrap5v3 {
        stroke: $spin-boot5-stroke-color;
      }

      .e-spin-high-contrast {
        .e-path-circle {
          stroke: $spin-fabric-stroke-color;
        }

        .e-path-arc {
          stroke: $spin-fabric-arc-color;
        }
      }

      .e-spin-material,
      .e-spin-material3,
      .e-spin-tailwind3 {
        stroke: $spin-material-stroke-color;
      }

      .e-spin-bootstrap4 {
        stroke: $spin-boot4-stroke-color;
      }

      .e-spin-bootstrap {
        fill: $spin-bootstrap-stroke-color;
        stroke: $spin-bootstrap-stroke-color;
      }
    }
  }
}


/* Syncfusion controls */
@import '../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-angular-inputs/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-notifications/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-angular-grids/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-angular-calendars/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-grids/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-treegrid/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-angular-dropdowns/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-angular-popups/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-icons/styles/bootstrap5.css';

/* Syncfusion custom style */
@import 'styles/gd_sf_bootstrap5/bootstrap5.min.css';

/* Bootstrap custom style */
@import 'styles/gd_bootstrap5/main.css';

body {
  overflow-x: hidden;
  background-color: white;
  margin: 0;
}

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -300px;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

.sidebar-title {
  font-size: 1.5rem;
  font-weight: 500;
}

.sidebar-title-text {
  line-height: 50px;
  height: 50px;
  text-align: center;
}

.sidebar-menu {
  width: 300px;
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

#topbar {
  display: block;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -300px;
  }

  #topbar {
    display: none;
  }
}

html,
body {
  height: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active input:-webkit-autofill::selection {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}
@import "../base/base.gd.scss";
@import "../inputs/input.gd.scss";
@import "../popups/popup.gd.scss";
@import "../popups/spinner.gd.scss";
@import "../dropdowns/drop-down-base.gd.scss";

$ddl-input-font-size: 14px !default;
$ddl-zero-value: 0 !default;
$ddl-default-border-color: $grey-300 !default;
$ddl-input-border: $border-size $border-type !default;
$ddl-input-font-family: inherit !default;
$ddl-input-margin-bottom: 4px !default;
$ddl-input-padding: 8px $ddl-zero-value 4px !default;
$ddl-input-group-border-width: $ddl-zero-value !default;
$ddl-active-font-color: $accent !default;
$ddl-list-search-icon-padding: 12px 8px 8px !default;
$ddl-list-filter-text-indent: 8px 16px 8px !default;
$ddl-bigger-list-font-size: 14px !default;
$ddl-list-box-shadow-color: rgba(0, 0, 0, .21) !default;
$ddl-filter-box-shadow-color: rgba(0, 0, 0, .3) !default;
$ddl-popup-background-color: $grey-white !default;
$ddl-filter-border: 0 !default;
$ddl-filter-top-border: 0 !default;
$ddl-filter-padding: 0 !default;
$ddl-filter-box-shadow: 0 1.5px 5px -2px $ddl-filter-box-shadow-color !default;
$ddl-filter-background-color: $grey-100 !default;
$ddl-clear-icon-margin-right: 66px !default;
$ddl-back-icon-margin: 0 10px 0 -52px !default;
$ddl-back-icon-padding: 0 8px !default;
$ddl-popup-shadow: 0 5px 5px -3px rgba($grey-light-font, .2), 0 8px 10px 1px rgba($grey-light-font, .14), 0 3px 14px 2px rgba($grey-light-font, .12) !default;
$ddl-filter-margin: 0 !default;

// Small Size
$ddl-small-list-font-size: 12px !default;

// touch small
$ddl-bigger-small-list-font-size: 13px !default;

@include export-module('dropdownlist-material') {
  .e-ddl.e-control-wrapper .e-ddl-icon::before {
    transform: rotate(0deg);
    transition: transform 300ms ease;
  }

  .e-ddl.e-control-wrapper.e-icon-anim .e-ddl-icon::before {
    transform: rotate(180deg);
    transition: transform 300ms ease;
  }

  .e-dropdownbase .e-list-item.e-active.e-hover {
    color: $ddl-active-font-color;
  }

  .e-input-group:not(.e-disabled) .e-control#{&}.e-dropdownlist ~ .e-ddl-icon:active,
  .e-input-group:not(.e-disabled) .e-control#{&}.e-dropdownlist ~ .e-ddl-icon:hover,
  .e-input-group:not(.e-disabled) .e-back-icon:active,
  .e-input-group:not(.e-disabled) .e-back-icon:hover,
  #{&}.e-popup.e-ddl .e-input-group:not(.e-disabled) .e-clear-icon:active,
  #{&}.e-popup.e-ddl .e-input-group:not(.e-disabled) .e-clear-icon:hover {
    background: transparent;
  }

  .e-input-group .e-ddl-icon:not(:active)::after {
    animation: none;
  }

  .e-ddl#{&}.e-popup {
    border: 0;
    box-shadow: $ddl-popup-shadow;
    margin-top: 2px;
  }

  #{&}.e-popup.e-ddl .e-dropdownbase {
    min-height: 26px;
  }

  #{&}.e-popup.e-ddl:not(.e-ddl-device) .e-input-group .e-clear-icon {
    margin: 6px 6px 5px;
    min-height: 12px;
    min-width: 12px;
    padding: 6px;
  }

  .e-input-group.e-ddl,
  .e-input-group.e-ddl .e-input,
  .e-input-group.e-ddl .e-ddl-icon {
    background: transparent;
  }

  .e-ddl.e-ddl-device.e-ddl-device-filter .e-input-group:hover:not(.e-disabled):not(.e-float-icon-left),
  .e-ddl.e-ddl-device.e-ddl-device-filter .e-input-group.e-control-wrapper:hover:not(.e-disabled):not(.e-float-icon-left) {
    border-bottom-width: 0;
  }

  // small size
  #{&}.e-popup.e-ddl:not(.e-ddl-device) .e-input-group.e-small .e-clear-icon,
  .e-small #{&}.e-popup.e-ddl:not(.e-ddl-device) .e-input-group .e-clear-icon,
  .e-small#{&}.e-popup.e-ddl:not(.e-ddl-device) .e-input-group .e-clear-icon,
  #{&}.e-popup.e-ddl:not(.e-ddl-device) .e-input-group.e-input-focus.e-small .e-clear-icon,
  .e-small #{&}.e-popup.e-ddl:not(.e-ddl-device) .e-input-group.e-input-focus .e-clear-icon,
  .e-small#{&}.e-popup.e-ddl:not(.e-ddl-device) .e-input-group.e-input-focus .e-clear-icon {
    margin: 0;
  }

  .e-small #{&}.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group .e-input-filter,
  .e-small#{&}.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group .e-input-filter,
  #{&}.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group.e-small .e-input-filter,
  .e-small #{&}.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group.e-input-focus .e-input-filter,
  .e-small#{&}.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group.e-input-focus .e-input-filter,
  #{&}.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group.e-small.e-input-focus .e-input-filter {
    padding: 5px 5px 5px 12px;
  }

  // outline class style for filter input
  .e-ddl.e-popup.e-outline .e-filter-parent {
    padding: 4px 8px;
  }
}

@include export-module('dropdownlist-layout') {

  .e-ddl#{&}.e-popup {
    #{if(&, '&', '*')} .e-input-group {
      @if ($skin-name == 'Material3') {
        margin-top: 4px;
      }
    }
  }

  .e-popup.e-wide-popup.e-ddl-device.e-popup-close {
    display: block;
    visibility: hidden;
  }

  .e-popup-full-page {
    bottom: 0;
    left: 0;
    margin: 0;
    overflow: hidden;
    padding: 0;
    right: 0;
    top: 0;

    #{&}.e-ddl.e-popup.e-ddl-device-filter {
      margin: $ddl-filter-margin;
    }
  }

  .e-ddl.e-control-wrapper .e-ddl-disable-icon {
    position: relative;
  }

  .e-ddl.e-control-wrapper .e-ddl-disable-icon::before {
    content: '';
  }

  .e-ddl-device-filter .e-filter-parent {
    background-color: $ddl-filter-background-color;
  }

  /* stylelint-disable property-no-vendor-prefix */
  .e-ddl input.e-input::-webkit-contacts-auto-fill-button {
    display: none;
    pointer-events: none;
    position: absolute;
    right: 0;
    visibility: hidden;
  }
  /* stylelint-enable property-no-vendor-prefix */

  .e-filter-parent {
    border: $ddl-filter-border;
    border-top-width: $ddl-filter-top-border;
    box-shadow: $ddl-filter-box-shadow;
    display: block;
    padding: $ddl-filter-padding;
    @if ($skin-name == 'FluentUI') {
      border-bottom: $ddl-filter-parent-border;
    }
  }

  .e-ddl.e-input-group:not(.e-disabled) {
    cursor: pointer;
  }

  .e-ddl#{&}.e-popup.e-ddl-device-filter {
    @at-root {
      #{if(&, '&', '*')} .e-input-group.e-input-focus::before,
      #{if(&, '&', '*')} .e-input-group.e-input-focus::after {
        width: 0;
      }
    }
  }

  .e-ddl#{&}.e-popup {
    background: $ddl-popup-background-color;
    @if $skin-name != 'material' and $skin-name != 'Material3' {
      border: 1px solid $ddl-default-border-color;
    }
    @if $skin-name == 'Material3' {
      border-radius: 4px;
    }
    position: absolute;
    @at-root {
      #{if(&, '&', '*')} .e-search-icon {
        margin: 0;
        opacity: .57;
        padding: $ddl-list-search-icon-padding;
      }

      #{if(&, '&', '*')} .e-filter-parent .e-back-icon {
        padding: $ddl-back-icon-padding;
      }

      #{if(&, '&', '*')}.e-rtl .e-filter-parent .e-input-group.e-control-wrapper .e-input-filter,
      #{if(&, '&', '*')} .e-filter-parent .e-input-filter,
      #{if(&, '&', '*')} .e-filter-parent .e-input-filter:focus,
      #{if(&, '&', '*')} .e-filter-parent .e-input-group.e-input-focus .e-input-filter,
      #{if(&, '&', '*')} .e-filter-parent .e-input-group.e-control-wrapper.e-input-focus .e-input-filter {
        @if ($skin-name != 'bootstrap4' and $skin-name != 'bootstrap4-dark' and $skin-name != 'FluentUI' and $skin-name != 'bootstrap5' and $skin-name != 'bootstrap5.3' and $skin-name != 'tailwind' and $skin-name != 'tailwind3') {
          padding: $ddl-list-filter-text-indent;
        }
      }

      #{if(&, '&', '*')} .e-input-group {
        margin-bottom: 0;
      }

      #{if(&, '&', '*')} .e-ddl-footer,
      #{if(&, '&', '*')} .e-ddl-header {
        cursor: default;
      }
    }
  }

  /* stylelint-disable property-no-vendor-prefix */
  .e-ddl.e-input-group .e-ddl-hidden,
  .e-ddl.e-float-input .e-ddl-hidden {
    -webkit-appearance: initial;
    border: 0;
    height: 0;
    padding: 0;
    visibility: hidden;
    width: 0;
  }

  .e-ddl.e-input-group,
  .e-ddl.e-input-group.e-input-focus:focus {
    outline: none;
  }

  .e-dropdownbase .e-list-item .e-highlight {
    display: inline;
    font-weight: bold;
    vertical-align: baseline;
  }

  .e-ddl.e-input-group input[readonly] ~ .e-clear-icon:not(.e-clear-icon-hide),
  .e-float-input input[readonly] ~ .e-clear-icon:not(.e-clear-icon-hide),
  .e-float-input.e-input-group input[readonly] ~ .e-clear-icon:not(.e-clear-icon-hide) {
    opacity: 1;
  }

  .e-dropdownlist.e-input:not(:valid):first-child ~ .e-clear-icon,
  .e-input-group input.e-dropdownlist.e-input:not(:valid):first-child ~ .e-clear-icon,
  .e-input-group.e-control-wrapper input.e-dropdownlist.e-input:not(:valid):first-child ~ .e-clear-icon,
  .e-float-input input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon,
  .e-float-input.e-control-wrapper input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon,
  .e-float-input.e-input-group input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon,
  .e-float-input.e-input-group.e-control-wrapper input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon {
    display: flex;
  }

  .e-dropdownlist.e-input:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide,
  .e-input-group input.e-dropdownlist.e-input:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide,
  .e-input-group.e-control-wrapper input.e-dropdownlist.e-input:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide,
  .e-float-input input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide,
  .e-float-input.e-control-wrapper input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide,
  .e-float-input.e-input-group input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide,
  .e-float-input.e-input-group.e-control-wrapper input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide {
    display: none;
  }

  .e-input-group.e-static-clear input.e-dropdownlist.e-input:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide,
  .e-input-group.e-static-clear.e-control-wrapper input.e-dropdownlist.e-input:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide,
  .e-float-input.e-static-clear input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide,
  .e-float-input.e-static-clear.e-control-wrapper input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide,
  .e-float-input.e-static-clear.e-input-group input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide,
  .e-float-input.e-static-clear.e-input-group.e-control-wrapper input.e-dropdownlist:not(:valid):first-child ~ .e-clear-icon.e-clear-icon-hide {
    cursor: pointer;
    display: flex;
  }

  .e-ddl.e-input-group {
    .e-input-value,
    .e-input-value:focus {
      font-family: $ddl-input-font-family;
      font-size: $ddl-input-font-size;
      height: auto;
      margin: $ddl-zero-value;
      outline: none;
      width: 100%;
      overflow: hidden;
    }

    input[readonly].e-input,
    input[readonly],
    .e-dropdownlist {
      pointer-events: none;
    }
  }

  .e-ddl.e-popup.e-popup-open .e-list-item.e-disabled {
    opacity: .7;
    pointer-events: none;
    @if $skin-name == 'bootstrap5.3' or $skin-name == 'fluent2' or $skin-name == 'tailwind3' {
      color: $ddl-list-disable-item-color;
    }
  }

  ejs-autocomplete,
  ejs-combobox,
  ejs-dropdownlist {
    display: block;
  }

  .e-small .e-ddl#{&}.e-popup,
  .e-input-group.e-ddl.e-small {
    #{if(&, '&', '*')} .e-list-item {
      font-size: $ddl-small-list-font-size;
    }

    #{if(&, '&', '*')} .e-list-group-item {
      @if $skin-name != 'tailwind' {
        font-size: $ddl-small-list-font-size;
      }
    }
  }

  .e-small.e-ddl#{&}.e-popup,
  .e-input-group.e-ddl.e-small {
    #{if(&, '&', '*')} .e-list-item {
      font-size: $ddl-small-list-font-size;
    }

    #{if(&, '&', '*')} .e-list-group-item {
      @if $skin-name != 'tailwind' {
        font-size: $ddl-small-list-font-size;
      }
    }
  }

  .e-content-placeholder.e-ddl.e-placeholder-ddl,
  .e-content-placeholder.e-autocomplete.e-placeholder-autocomplete,
  .e-content-placeholder.e-combobox.e-placeholder-combobox {
    background-size: 300px 33px;
    min-height: 33px;
  }

  .e-ddl.e-popup.e-resize .e-resizer-right {
    bottom: 0;
    right: 0;
    cursor: nwse-resize;
    height: 15px;
    position: absolute;
    width: 15px;
  }

  .e-ddl.e-popup.e-resize .e-resizer-right {
    @if $skin-name == 'bootstrap-dark' or $skin-name == 'material-dark' or $skin-name == 'tailwind-dark' or $skin-name == 'material3-dark' or $skin-name == 'bootstrap5-dark' or $skin-name == 'fluent-dark'  or $skin-name == 'fabric-dark' or $skin-name == 'highcontrast'  {
      background: transparent;
      color: rgb(221, 218, 218);
    }
  }
}


@include export-module('dropdownlist-theme') {
  #{&}.e-popup {
    border-color: $ddl-default-border-color;
  }

  .e-float-input.e-input-group.e-ddl.e-control.e-icon-anim > .e-float-text,
  .e-float-input.e-input-focus.e-input-group.e-ddl.e-control.e-keyboard > .e-float-text {
    color: $ddl-active-font-color;
  }

  .e-ddl.e-popup .e-filter-parent .e-input-group input.e-input-filter {
    border-color: transparent;
    @if $skin-name == 'bootstrap4' or $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'highcontrast' {
      background: none;
    }
  }
}


@include export-module('dropdownlist-material-icons') {
  .e-ddl .e-search-icon::before {
    content: '\e993';
  }

  .e-ddl .e-back-icon::before {
    content: '\e977';
  }

  .e-ddl.e-input-group.e-control-wrapper .e-ddl-icon::before {
    content: '\e969';
    font-family: 'e-icons';
  }

  .e-ddl.e-popup.e-resize .e-icons.e-resizer-right::before {
    content: '\eb05';
  }
}

@include export-module('dropdownlist-bigger') {

  .e-bigger .e-input-group.e-ddl {
    #{if(&, '&', '*')} .e-input-filter,
    #{if(&, '&', '*')} .e-input-filter:focus {
      margin-left: -20px;
    }
  }

  .e-bigger .e-ddl#{&}.e-popup {
    #{if(&, '&', '*')} .e-list-item {
      font-size: $ddl-bigger-list-font-size;
    }

    #{if(&, '&', '*')} .e-list-group-item {
      @if $skin-name != 'tailwind' {
        font-size: $ddl-bigger-list-font-size;
      }
    }

    #{if(&, '&', '*')} .e-input-group {
      @if ($skin-name != 'bootstrap5' and $skin-name != 'bootstrap5.3' and $skin-name != 'tailwind' and $skin-name != 'FluentUI' and $skin-name != 'bootstrap4' and $skin-name != 'bootstrap4-dark' and $skin-name != 'Material3') {
        padding: 4px 0;
      }
      @if ($skin-name == 'Material3') {
        margin-top: 8px;
      }
    }
  }

  .e-bigger.e-ddl#{&}.e-popup {
    #{if(&, '&', '*')} .e-list-item {
      font-size: $ddl-bigger-list-font-size;
    }

    #{if(&, '&', '*')} .e-list-group-item {
      @if $skin-name != 'tailwind' {
        font-size: $ddl-bigger-list-font-size;
      }
    }

    #{if(&, '&', '*')} .e-input-group {
      @if ($skin-name != 'bootstrap5' and $skin-name != 'bootstrap5.3' and $skin-name != 'tailwind' and $skin-name != 'FluentUI' and $skin-name != 'bootstrap4' and $skin-name != 'bootstrap4-dark') {
        padding: 4px 0;
      }
    }
  }

  .e-bigger.e-small .e-ddl#{&}.e-popup,
  .e-bigger .e-input-group.e-ddl.e-small {
    #{if(&, '&', '*')} .e-list-item {
      font-size: $ddl-bigger-small-list-font-size;
    }

    #{if(&, '&', '*')} .e-list-group-item {
      @if $skin-name != 'tailwind' {
        font-size: $ddl-bigger-small-list-font-size;
      }
    }
  }

  .e-bigger.e-small.e-ddl#{&}.e-popup,
  .e-bigger .e-input-group.e-ddl.e-small {
    #{if(&, '&', '*')} .e-list-item {
      font-size: $ddl-bigger-small-list-font-size;
    }

    #{if(&, '&', '*')} .e-list-group-item {
      @if $skin-name != 'tailwind' {
        font-size: $ddl-bigger-small-list-font-size;
      }
    }
  }

  .e-bigger .e-content-placeholder.e-ddl.e-placeholder-ddl,
  .e-bigger.e-content-placeholder.e-ddl.e-placeholder-ddl,
  .e-bigger .e-content-placeholder.e-autocomplete.e-placeholder-autocomplete,
  .e-bigger.e-content-placeholder.e-autocomplete.e-placeholder-autocomplete,
  .e-bigger .e-content-placeholder.e-combobox.e-placeholder-combobox,
  .e-bigger.e-content-placeholder.e-combobox.e-placeholder-combobox {
    background-size: 300px 40px;
    min-height: 40px;
  }

  .e-bigger .e-ddl#{&}.e-popup,
  .e-bigger.e-ddl#{&}.e-popup {
    @if $skin-name == 'tailwind' {
      border-radius: 8px;
      margin-top: 8px;
    }
  }

  .e-bigger.e-small .e-ddl#{&}.e-popup,
  .e-small.e-bigger.e-ddl#{&}.e-popup {
    @if $skin-name == 'tailwind' {
      border-radius: 8px;
      margin-top: 6px;
    }
  }

  .e-bigger #{&}.e-popup.e-ddl-device-filter .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) {
    @if $skin-name == 'tailwind' {
      border-color: $ddl-default-border-color;
    }
  }

  .e-bigger #{&}.e-popup.e-ddl-device-filter {
    @if $skin-name == 'tailwind' {
      margin-top: 0;
    }
  }

  .e-bigger .e-ddl-device .e-input-group,
  .e-bigger .e-ddl-device .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) {
    @if $skin-name == 'tailwind' {
      background: $flyout-bg-color;
      border-width: 0;
      box-shadow: none;
      margin-bottom: 0;
    }
  }

  .e-bigger .e-ddl-device .e-input-group .e-back-icon,
  .e-bigger .e-ddl-device .e-input-group input.e-input,
  .e-bigger .e-ddl-device .e-input-group .e-clear-icon {
    @if $skin-name == 'tailwind' {
      background-color: $flyout-bg-color;
    }
  }

  @if $skin-name == 'bootstrap-dark' or $skin-name == 'bootstrap' {
    .e-bigger #{&}.e-popup.e-ddl-device-filter {
      margin-top: 0;
    }

    .e-bigger #{&}.e-popup.e-ddl {
      @at-root {
        #{if(&, '&', '*')} .e-list-item,
        #{if(&, '&', '*')} .e-list-group-item,
        #{if(&, '&', '*')} .e-fixed-head {
          font-size: 15px;
          line-height: 40px;
          padding-left: 0;
          text-indent: 24px;
        }

        #{if(&, '&', '*')} .e-dd-group .e-list-item {
          padding-left: 4px;
        }

        #{if(&, '&', '*')} .e-input-group {
          padding: 4px 0;
        }

        #{if(&, '&', '*')} .e-input-group input,
        #{if(&, '&', '*')} .e-input-group input.e-input {
          height: 38px;
        }
      }
    }
  }

  @if $skin-name == 'bootstrap-dark' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' {
    .e-bigger #{&}.e-popup.e-ddl .e-dropdownbase {
      min-height: 40px;
    }

    .e-bigger.e-small .e-ddl#{&}.e-popup {
      @at-root {
        #{if(&, '&', '*')} .e-list-item,
        #{if(&, '&', '*')} .e-list-group-item,
        #{if(&, '&', '*')} .e-fixed-head {
          font-size: 14px;
          line-height: 34px;
          padding-left: 0;
          text-indent: 16px;
        }

        #{if(&, '&', '*')} .e-dd-group .e-list-item {
          padding-left: 4px;
        }

        #{if(&, '&', '*')} .e-input-group {
          padding: 4px 0;
        }

        #{if(&, '&', '*')} .e-input-group input,
        #{if(&, '&', '*')} .e-input-group input.e-input {
          height: 30px;
        }
      }
    }

    .e-bigger.e-small #{&}.e-popup.e-ddl .e-dropdownbase {
      min-height: 34px;
    }
  }

  .e-small.e-bigger .e-input-group .e-control#{&}.e-dropdownlist~.e-ddl-icon,
  .e-small.e-bigger.e-input-group .e-control#{&}.e-dropdownlist~.e-ddl-icon {
    @if $skin-name == 'bootstrap4' {
      font-size: 12px;
    }
  }

  .e-bigger .e-input-group .e-control#{&}.e-dropdownlist~.e-ddl-icon,
  .e-bigger.e-input-group .e-control#{&}.e-dropdownlist~.e-ddl-icon {
    @if $skin-name == 'bootstrap4' {
      font-size: 14px;
    }
  }

  @if $skin-name == 'bootstrap4' {
    .e-bigger #{&}.e-popup.e-ddl-device-filter {
      margin-top: 0;
    }

    .e-bigger #{&}.e-popup.e-ddl {
      @at-root {
        #{if(&, '&', '*')} .e-list-item {
          font-size: 16px;
          line-height: 32px;
          padding-left: 0;
          text-indent: 24px;
        }

        #{if(&, '&', '*')} .e-list-group-item,
        #{if(&, '&', '*')} .e-fixed-head {
          font-size: 14px;
          line-height: 32px;
          padding-left: 0;
          text-indent: 24px;
        }

        #{if(&, '&', '*')} .e-dd-group .e-list-item {
          padding-left: 4px;
        }

        #{if(&, '&', '*')} .e-input-group {
          padding: 4px 0;
        }

        #{if(&, '&', '*')} .e-input-group input,
        #{if(&, '&', '*')} .e-input-group input.e-input {
          font-size: 16px;
          height: 36px;
        }
      }
    }

    .e-bigger #{&}.e-popup.e-ddl .e-dropdownbase {
      min-height: 40px;
    }
  }

  @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
    .e-bigger .e-input-group .e-control#{&}.e-dropdownlist~.e-ddl-icon {
      font-size: $text-base;
    }

    .e-bigger.e-small .e-ddl#{&}.e-popup {
      @at-root {
        #{if(&, '&', '*')} .e-list-item,
        #{if(&, '&', '*')} .e-list-group-item,
        #{if(&, '&', '*')} .e-fixed-head {
          font-size: $text-sm;
          line-height: 34px;
          padding-left: 0;
          text-indent: 16px;
        }

        #{if(&, '&', '*')} .e-dd-group .e-list-item {
          padding-left: 4px;
        }

        #{if(&, '&', '*')} .e-input-group input,
        #{if(&, '&', '*')} .e-input-group input.e-input {
          height: 30px;
        }
      }
    }

    .e-bigger.e-small #{&}.e-popup.e-ddl .e-dropdownbase {
      min-height: 34px;
    }

    .e-bigger #{&}.e-popup.e-ddl-device-filter {
      margin-top: 0;
    }

    .e-bigger #{&}.e-popup.e-ddl {
      @at-root {
        #{if(&, '&', '*')} .e-list-item {
          font-size: $text-base;
          line-height: 32px;
          padding-left: 0;
          text-indent: 16px;
        }

        #{if(&, '&', '*')} .e-list-group-item,
        #{if(&, '&', '*')} .e-fixed-head {
          font-size: $text-sm;
          line-height: 32px;
          padding-left: 0;
          text-indent: 16px;
        }

        #{if(&, '&', '*')} .e-dd-group .e-list-item {
          padding-left: 0;
        }

        #{if(&, '&', '*')} .e-input-group input,
        #{if(&, '&', '*')} .e-input-group input.e-input {
          font-size: $text-base;
          height: 36px;
        }
      }
    }

    .e-bigger #{&}.e-popup.e-ddl .e-dropdownbase {
      min-height: 40px;
    }
  }

  @if $skin-name == 'fabric-dark' or $skin-name == 'fabric' {
    .e-bigger #{&}.e-popup.e-ddl-device-filter {
      margin-top: 0;
    }
    .e-bigger .e-ddl#{&}.e-popup {
      @at-root {
        #{if(&, '&', '*')} .e-list-item,
        #{if(&, '&', '*')} .e-list-group-item,
        #{if(&, '&', '*')} .e-fixed-head {
          font-size: 15px;
          line-height: 45px;
          padding-left: 0;
          text-indent: 16px;
        }

        #{if(&, '&', '*')} .e-dd-group .e-list-item {
          padding-left: 4px;
        }

        #{if(&, '&', '*')} .e-input-group {
          padding: 4px 0;
        }

        #{if(&, '&', '*')} .e-input-group input,
        #{if(&, '&', '*')} .e-input-group input.e-input {
          height: 38px;
        }
      }
    }
    .e-bigger.e-small .e-ddl#{&}.e-popup,
    .e-ddl#{&}.e-popup .e-bigger.e-small {
      @at-root {
        #{if(&, '&', '*')} .e-list-item,
        #{if(&, '&', '*')} .e-list-group-item,
        #{if(&, '&', '*')} .e-fixed-head {
          font-size: 14px;
          line-height: 40px;
          padding-left: 0;
          text-indent: 16px;
        }

        #{if(&, '&', '*')} .e-dd-group .e-list-item {
          padding-left: 4px;
        }

        #{if(&, '&', '*')} .e-input-group {
          padding: 0;
        }

        #{if(&, '&', '*')} .e-input-group input,
        #{if(&, '&', '*')} .e-input-group input.e-input {
          height: 34px;
        }
      }
    }
  }
  @if $skin-name == 'FluentUI' {
    .e-bigger #{&}.e-popup.e-ddl-device-filter {
      margin-top: 0;
    }

    .e-bigger #{&}.e-popup.e-ddl {
      @at-root {
        #{if(&, '&', '*')} .e-list-item {
          font-size: $text-base;
          line-height: 32px;
          padding-left: 0;
          text-indent: 16px;
        }

        #{if(&, '&', '*')} .e-list-group-item,
        #{if(&, '&', '*')} .e-fixed-head {
          font-size: $text-sm;
          line-height: 32px;
          padding-left: 0;
          text-indent: 16px;
        }
      }
    }

    .e-bigger #{&}.e-popup.e-ddl .e-dropdownbase {
      min-height: 40px;
    }
    .e-bigger .e-input-group .e-control#{&}.e-dropdownlist~.e-ddl-icon {
      font-size: $text-base;
    }

    .e-bigger.e-small .e-ddl#{&}.e-popup {
      @at-root {
        #{if(&, '&', '*')} .e-list-item,
        #{if(&, '&', '*')} .e-list-group-item,
        #{if(&, '&', '*')} .e-fixed-head {
          font-size: $text-sm;
          line-height: 34px;
          padding-left: 0;
          text-indent: 16px;
        }

        // #{if(&, '&', '*')} .e-dd-group .e-list-item {
        //   padding-left: 4px;
        // }

        #{if(&, '&', '*')} .e-input-group input,
        #{if(&, '&', '*')} .e-input-group input.e-input {
          height: 30px;
        }
      }
    }

    .e-bigger.e-small #{&}.e-popup.e-ddl .e-dropdownbase {
      min-height: 34px;
    }
  }
  @if $skin-name == 'fluent2' {
    .e-bigger .e-ddl#{&}.e-popup,
    .e-bigger.e-ddl#{&}.e-popup {
      border-radius: 8px;
      margin-top: 8px;
    }

    .e-bigger.e-small .e-ddl#{&}.e-popup,
    .e-small.e-bigger.e-ddl#{&}.e-popup {
      border-radius: 8px;
      margin-top: 6px;
    }
    .e-bigger #{&}.e-popup.e-ddl-device-filter .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) {
      border-color: $ddl-default-border-color;
    }

    .e-bigger #{&}.e-popup.e-ddl-device-filter {
      margin-top: 0;
    }

    .e-bigger .e-ddl-device .e-input-group,
    .e-bigger .e-ddl-device .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) {
      background: $flyout-bg-color;
      border-width: 0;
      box-shadow: none;
      margin-bottom: 0;
    }

    .e-bigger .e-ddl-device .e-input-group .e-back-icon,
    .e-bigger .e-ddl-device .e-input-group input.e-input,
    .e-bigger .e-ddl-device .e-input-group .e-clear-icon {
      background-color: $flyout-bg-color;
    }
  }
  @if $skin-name == 'highcontrast' or $skin-name == 'highcontrast-light' {
    .e-bigger .e-ddl.e-popup .e-input-group .e-clear-icon {
      height: 36px;
    }
    .e-ddl#{&} {
      #{if(&, '&', '*')}.e-popup {
        .e-bigger #{if(&, '&', '*')} {
          #{if(&, '&', '*')}.e-ddl-device.e-ddl-device-filter {
            margin-top: 0;
          }
          #{if(&, '&', '*')} .e-list-item,
          #{if(&, '&', '*')} .e-list-group-item,
          #{if(&, '&', '*')} .e-fixed-head {
            font-size: 15px;
            line-height: 45px;
            padding-left: 0;
            text-indent: 16px;
          }

          #{if(&, '&', '*')} .e-dd-group {
            #{if(&, '&', '*')} .e-list-item {
              padding-left: 4px;
            }
          }

          #{if(&, '&', '*')} .e-input-group {
            padding: 4px 0;
          }

          #{if(&, '&', '*')} .e-input-group {
            #{if(&, '&', '*')} input {
              height: 46px;
            }
          }
        }

        .e-bigger.e-small #{if(&, '&', '*')} {
          #{if(&, '&', '*')} .e-list-item,
          #{if(&, '&', '*')} .e-list-group-item,
          #{if(&, '&', '*')} .e-fixed-head {
            font-size: 14px;
            line-height: 40px;
            padding-left: 0;
            text-indent: 16px;
          }

          #{if(&, '&', '*')} .e-dd-group {
            #{if(&, '&', '*')} .e-list-item {
              padding-left: 4px;
            }
          }

          #{if(&, '&', '*')} .e-input-group {
            padding: 0;
          }

          #{if(&, '&', '*')} .e-input-group {
            #{if(&, '&', '*')} input {
              height: 34px;
            }
          }
        }
      }
    }
  }
  @if $skin-name == 'material-dark' {
    .e-bigger #{&}.e-popup.e-ddl-device-filter .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) {
      border-color: $ddl-default-border-color;
    }

    .e-bigger #{&}.e-popup.e-ddl-device-filter {
      margin-top: 0;
    }

    .e-bigger .e-ddl-device .e-input-group,
    .e-bigger .e-ddl-device .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) {
      background: $grey-100;
      border-width: 0;
      box-shadow: none;
      margin-bottom: 0;
    }

    .e-bigger .e-ddl-device .e-input-group .e-back-icon,
    .e-bigger .e-ddl-device .e-input-group input.e-input,
    .e-bigger .e-ddl-device .e-input-group .e-clear-icon {
      background-color: $grey-100;
    }
    .e-bigger #{&}.e-popup.e-ddl:not(.e-ddl-device) .e-input-group .e-clear-icon,
    .e-bigger#{&}.e-popup.e-ddl:not(.e-ddl-device) .e-input-group .e-clear-icon {
      min-height: 16px;
      min-width: 16px;
    }

    .e-bigger #{&}.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-filter,
    .e-bigger#{&}.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-filter {
      padding: 8px 16px 8px 0;
    }
  }
  @if $skin-name == 'material' {
    .e-bigger #{&}.e-popup.e-ddl-device-filter .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) {
      border-color: $ddl-default-border-color;
    }

    .e-bigger #{&}.e-popup.e-ddl-device-filter {
      margin-top: 0;
    }

    .e-bigger .e-ddl-device .e-input-group,
    .e-bigger .e-ddl-device .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) {
      background: $grey-100;
      border-width: 0;
      box-shadow: none;
      margin-bottom: 0;
    }

    .e-bigger .e-ddl-device .e-input-group .e-back-icon,
    .e-bigger .e-ddl-device .e-input-group input.e-input,
    .e-bigger .e-ddl-device .e-input-group .e-clear-icon {
      background-color: $grey-100;
    }
    .e-bigger #{&}.e-popup.e-ddl:not(.e-ddl-device) .e-input-group .e-clear-icon,
    .e-bigger#{&}.e-popup.e-ddl:not(.e-ddl-device) .e-input-group .e-clear-icon {
      min-height: 16px;
      min-width: 16px;
    }

    .e-bigger #{&}.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-filter,
    .e-bigger#{&}.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-filter {
      padding: 8px 16px 8px 0;
    }
    .e-bigger.e-small #{&}.e-popup.e-ddl:not(.e-ddl-device) .e-input-group .e-clear-icon,
    .e-bigger.e-small#{&}.e-popup.e-ddl:not(.e-ddl-device) .e-input-group .e-clear-icon,
    .e-bigger #{&}.e-popup.e-ddl:not(.e-ddl-device) .e-input-group.e-small .e-clear-icon,
    .e-bigger#{&}.e-popup.e-ddl:not(.e-ddl-device) .e-input-group.e-small .e-clear-icon,
    .e-small #{&}.e-popup.e-ddl:not(.e-ddl-device) .e-input-group.e-bigger .e-clear-icon,
    .e-small#{&}.e-popup.e-ddl:not(.e-ddl-device) .e-input-group.e-bigger .e-clear-icon,
    #{&}.e-popup.e-ddl:not(.e-ddl-device) .e-input-group.e-bigger.e-small .e-clear-icon {
      min-height: 18px;
      min-width: 18px;
    }

    .e-bigger.e-small #{&}.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group .e-input-filter,
    .e-bigger.e-small#{&}.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group .e-input-filter,
    .e-bigger #{&}.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group.e-small .e-input-filter,
    .e-bigger#{&}.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group.e-small .e-input-filter,
    .e-small #{&}.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group.e-bigger .e-input-filter,
    .e-small#{&}.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group.e-bigger .e-input-filter,
    #{&}.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group.e-bigger.e-small .e-input-filter {
      padding: 8px 16px;
    }
  }
  @if $skin-name == 'Material3' {
    .e-bigger #{&}.e-popup.e-ddl-device-filter .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) {
      border-color: $ddl-default-border-color;
    }

    .e-bigger #{&}.e-popup.e-ddl-device-filter {
      margin-top: 0;
    }

    .e-bigger .e-ddl-device .e-input-group,
    .e-bigger .e-ddl-device .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) {
      background: rgba($transparent, .0001);
      border-width: 0;
      box-shadow: none;
      margin-bottom: 0;
    }

    .e-bigger .e-ddl-device .e-input-group .e-back-icon,
    .e-bigger .e-ddl-device .e-input-group input.e-input,
    .e-bigger .e-ddl-device .e-input-group .e-clear-icon {
      background: rgba($transparent, .0001);
    }
    .e-bigger #{&}.e-popup.e-ddl:not(.e-ddl-device) .e-input-group .e-clear-icon,
    .e-bigger#{&}.e-popup.e-ddl:not(.e-ddl-device) .e-input-group .e-clear-icon {
      min-height: 38px;
      min-width: 38px;
    }
    .e-bigger #{&}.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-filter,
    .e-bigger#{&}.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-filter {
      padding: 4px 16px 4px 0;
    }
    .e-bigger.e-small #{&}.e-popup.e-ddl:not(.e-ddl-device) .e-input-group .e-clear-icon,
    .e-bigger.e-small#{&}.e-popup.e-ddl:not(.e-ddl-device) .e-input-group .e-clear-icon,
    .e-bigger #{&}.e-popup.e-ddl:not(.e-ddl-device) .e-input-group.e-small .e-clear-icon,
    .e-bigger#{&}.e-popup.e-ddl:not(.e-ddl-device) .e-input-group.e-small .e-clear-icon,
    .e-small #{&}.e-popup.e-ddl:not(.e-ddl-device) .e-input-group.e-bigger .e-clear-icon,
    .e-small#{&}.e-popup.e-ddl:not(.e-ddl-device) .e-input-group.e-bigger .e-clear-icon,
    #{&}.e-popup.e-ddl:not(.e-ddl-device) .e-input-group.e-bigger.e-small .e-clear-icon {
      min-height: 34px;
      min-width: 34px;
    }

    .e-bigger.e-small #{&}.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group .e-input-filter,
    .e-bigger.e-small#{&}.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group .e-input-filter,
    .e-bigger #{&}.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group.e-small .e-input-filter,
    .e-bigger#{&}.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group.e-small .e-input-filter,
    .e-small #{&}.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group.e-bigger .e-input-filter,
    .e-small#{&}.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group.e-bigger .e-input-filter,
    #{&}.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group.e-bigger.e-small .e-input-filter {
      padding: 8px 16px;
    }

    .e-bigger.e-small .e-popup.e-ddl:not(.e-ddl-device) .e-input-group.e-input-focus .e-clear-icon {
      margin: 0 4px;
    }
  }
  @if $skin-name == 'tailwind' {
    .e-bigger .e-ddl#{&}.e-popup,
    .e-bigger.e-ddl#{&}.e-popup {
      border-radius: 6px;
      margin-top: 8px;
    }

    .e-bigger.e-small .e-ddl#{&}.e-popup,
    .e-small.e-bigger.e-ddl#{&}.e-popup {
      border-radius: 4px;
      margin-top: 6px;
    }
    .e-bigger #{&}.e-popup.e-ddl-device-filter .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) {
      border-color: $ddl-default-border-color;
    }

    .e-bigger #{&}.e-popup.e-ddl-device-filter {
      margin-top: 0;
    }

    .e-bigger .e-ddl-device .e-input-group,
    .e-bigger .e-ddl-device .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) {
      background: $flyout-bg-color;
      border-width: 0;
      box-shadow: none;
      margin-bottom: 0;
    }

    .e-bigger .e-ddl-device .e-input-group .e-back-icon,
    .e-bigger .e-ddl-device .e-input-group input.e-input,
    .e-bigger .e-ddl-device .e-input-group .e-clear-icon {
      background-color: $flyout-bg-color;
    }
  }
}

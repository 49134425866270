@import "../base/base.gd.scss";
@import "../inputs/input.gd.scss";
@import "../popups/popup.gd.scss";
@import "../buttons/button.gd.scss";
@import "../calendars/calendar.gd.scss";
@import "../buttons/check-box.gd.scss";
@import "../buttons/chips.gd.scss";
@import "../buttons/radio-button.gd.scss";
@import "../buttons/switch.gd.scss";
@import "../buttons/floating-action-button.gd.scss";
@import "../buttons/speed-dial.gd.scss";
@import "../lists/list-view.gd.scss";
@import "../lists/sortable.gd.scss";

//default
$range-bg-color: $grey-white !default;
$range-primary-color: $primary !default;
$range-primary-font-color: $primary-font !default;
$range-presets-bg: $grey-white !default;
$range-preset-normal-font-color: $grey-light-font !default;
$range-font-color: $accent !default;
$range-background: $grey-white !default;
$range-footer-background: $range-background !default;
$range-header-bg-color: $range-background !default;
$range-active-icon-color: $accent !default;
$range-active-state-icon-color: $accent !default;
$range-hover-color: $grey-200 !default;
$range-hover-content-color: darken($range-hover-color, 15%) !default;
$range-today-color: $grey-200 !default;
$range-selection-bg: $accent !default;
$range-other-hover-color: $grey-white !default;
$range-other-month-date: rgba($grey-light-font, .38) !default;
$range-box-shadow: 0 5px 5px -3px rgba($grey-light-font, .2), 0 8px 10px 1px rgba($grey-light-font, .14), 0 3px 14px 2px rgba($grey-light-font, .12) !default;
$range-box-shadow-none: none !default;
$range-btn-font-weight: 500 !default;
$range-btn-width: 50% !default;
$range-btn-normal-height: 27px !default;
$range-btn-bigger-height: 36px !default;
$range-btn-normal-line-height: 27px !default;
$range-btn-bigger-line-height: 36px !default;
$range-max-width: 730px !default;
$range-device-max-width: 298px !default;
$range-bigger-max-width: 770px !default;
$range-max-height: 500px !default;
$range-normal-nav-icon-width: 36px !default;
$range-normal-nav-icon-height: 36px !default;
$range-calendar-dark-color: rgba($grey-light-font, .87) !default;
$range-calendar-header-text-color: rgba($grey-light-font, .87) !default;
$range-calendar-header-dark-color: rgba($grey-light-font, .87) !default;
$range-calendar-medium-color: rgba($grey-light-font, .54) !default;
$range-calendar-light-color: rgba($grey-light-font, .38) !default;
$range-calendar-medium-font-size: 14px !default;
$range-calendar-btn-font-size: 14px !default;
$range-presets-normal-list-font-size: 13px !default;
$range-presets-bigger-list-font-size: 14px !default;
$range-range-btn-font-weight: 500 !default;
$range-header-label-size: 18px !default;
$range-calendar-small-font-size: 13px !default;
$range-calendar-bigger-font-size: 20px !default;
$range-calendar-header-padding: 10px 5px 0 5px !default;
$range-e-bigger-header-padding: 10px 5px 0 5px !default;
$range-device-control-header-margin: 20px 16px 0 16px !default;
$range-device-control-header-padding: 0 !default;
$range-control-header-margin: 16px 16px 0 16px !default;
$range-indicator-margin: 0 0 15px 0 !default;
$range-btn-border-transparent: 1px solid transparent !default;
$range-control-header-width: auto !default;
$range-font-weight-normal: normal !default;
$range-flex-style: flex !default;
$range-flex-justify-content: flex-end !default;
$range-inline-block-style: inline-block !default;
$range-block-style: block !default;
$range-table-style: table !default;
$range-lg-day-header-format-width: 100% !default;
$range-display-none:  none !default;
$range-align-center: center !default;
$range-float-right: right !default;
$range-float-left: left !default;
$range-float-none: none !default;
$range-float-clear: both !default;
$range-start-end-container-height: 35px !default;
$range-calendar-border: none !default;
$range-calendar-selection-border: none !default;
$range-calendar-hover-border: none !default;
$range-calendar-hover-border-color: none !default;
$range-calendar-other-month-border: none !default;
$range-calendar-margin: 0 !default;
$range-cursor-default-style: default !default;
$range-cursor-pointer-style: pointer !default;
$range-start-end-label-width: 48% !default;
$range-browser-select-none:  none !default;
$range-change-icon-width: 4% !default;
$range-font-weight-bold: 500 !default;
$range-font-size: 15px !default;
$range-change-icon-color: rgba($grey-light-font, .74) !default;
$range-indicator-label-width: 100% !default;
$range-device-indicator-margin: 10px 0 !default;
$range-separator-height: 1px !default;
$range-border-value: 1px solid rgba($grey-light-font, .12) !default;
$range-separator-color: rgba($grey-light-font, .12) !default;
$range-separator-margin: 0 !default;
$range-footer-height: 48px !default;
$range-bigger-footer-height: 58px !default;
$range-flex-direction-row-reverse: row-reverse !default;
$range-flex-direction-row: row !default;
$range-btn-height: 36px !default;
$range-btn-padding: 0 16px !default;
$range-normal-footer-margin: 0 8px 0 8px !default;
$range-bigger-footer-margin: 0 8px 0 8px !default;
$range-normal-rtl-footer-margin: 0 8px 0 8px !default;
$range-bigger-rtl-footer-margin: 0 8px 0 8px !default;
$range-presets-height: 192px !default;
$range-bigger-presets-height: 240px !default;
$range-calendar-container-height: 100% !default;
$range-list-item-height: 48px !default;
$range-list-item-padding: 0 24px !default;
$range-listview-padding: 4px 0 !default;
$range-width-auto: auto !default;
$range-list-item-line-height: 47px !default;
$range-device-medium-font-size: 13px !default;
$range-device-medium-header-font-size: 13px !default;
$range-device-small-font-size: 11px !default;
$range-week-header-font-size: 13px !default;
$range-device-header-container-height: 36px !default;
$range-device-header-container-width: 100% !default;
$range-device-list-item-padding: 0 16px !default;
$range-value-zero: 0 !default;
$range-value-none: none !default;
$range-btn-border-value: 1px solid $primary !default;
$range-btn-left-radius: 2px 0 0 2px !default;
$range-btn-right-radius: 0 2px 2px 0 !default;
$range-visibility-hidden: hidden !default;
$range-indicator-size: 1px !default;
$range-text-nowrap: nowrap !default;
$range-day-span-height: 14px !default;
$range-direction-left: ltr !default;
$range-preset-min-width: 625px !default;
$range-header-btn-max-width: 116px !default;
$range-header-btn-bigger-max-width: 141px !default;
$range-presets-width: 160px !default;
$range-hover-start-radius: 50% 0 0 50% !default;
$range-hover-end-radius: 0 50% 50% 0 !default;
$range-text-overflow: ellipsis !default;
$range-btn-height: 36px !default;
$range-device-btn-line-height: 34px !default;
$range-icon-hover-color: $hover-bg-color !default;
$range-icon-border-radius: 50% !default;
$range-icon-container-min-height: 24px !default;
$range-icon-container-min-width: 24px !default;
$range-icon-bottom-right-radius: 0 !default;
$range-icon-top-right-radius: 0 !default;
$range-bigger-icon-container-min-height: 24px !default;
$range-bigger-icon-container-min-width: 24px !default;
$range-icon-margin: 0 0 4px 0 !default;
$range-icon-bigger-margin: 0 0 2px 0 !default;
$range-sart-end-btn-padding: 1px 6px !default;
$range-calendar-landscape-height: 130px !default;

// new variable
$range-icon: '\e245' !default;
$range-icon-font-size: 16px !default;
$range-bigger-icon-font-size: 18px !default;
$range-icon-next: '\e913' !default;
$range-icon-prev: '\e921' !default;
$range-change-icon: '\e85f' !default;
$range-rtl-icon-next: '\e921' !default;
$range-rtl-icon-prev: '\e913' !default;
$range-rtl-icon-change: '\e85b' !default;
$range-popup-border: none !default;
$range-overlay: rgba($grey-black, .6) !default;
$range-popup-padding: 0 !default;
$range-presets-bg-color: $grey-white !default;
$range-presets-font-color: $accent !default;
$range-calendar-normal-table-padding: 0 10px 10px !default;
$range-calendar-bigger-table-padding: 0 15px 15px !default;
$range-popup-border-radius: 0 !default;
$range-list-border-radius: 0 !default;
$range-presets-hover-bg: $grey-200 !default;
$range-bigger-nav-icon-width: 48px !default;
$range-bigger-nav-icon-height: 48px !default;
$range-calendar-popup-padding: 0 !default;
$range-bigger-calendar-popup-padding: 0 !default;
$range-icon-bigger-padding: 15px !default;
$range-icon-normal-padding: 10px !default;
$range-preset-normal-list-height: 36px !default;
$range-preset-bigger-list-height: 48px !default;
$range-list-hover-color: $range-calendar-header-dark-color !default;
$range-calendar-active-border: 1px solid $accent !default;
$range-today-border-color: $range-calendar-active-border !default;
$range-calendar-today-color: $accent !default;
$range-calendar-today-start-color: $range-calendar-active-border !default;
$range-calendar-other-normal-border: none !default;
$range-calendar-bigger-max: 296px !default;
$range-active-font-color: $accent-font !default;

// Mouse small size
$range-small-header-label-size: 16px !default;
$range-small-font-size: 12px !default;
$range-start-end-container-small-height: 32px !default;
$range-calendar-mouse-small-font-size: 12px !default;
$range-indicator-small-margin: 0 0 10px 0 !default;
$range-control-small-header-margin: 10px 10px 0 10px !default;
$range-separator-small-margin: 0 10px !default;
$range-small-footer-margin: 10px 10px 10px 8px !default;
$range-preset-small-list-height: 26px !default;
$range-presets-small-list-font-size: 12px !default;
$calendar-bigger-small-max-width: 246px !default;

// mouse small icon
$range-small-icon-font-size: 14px !default;

// touch small icon
$range-bigger-small-icon-font-size: 18px !default;

// modal full-screen styles
$modal-range-portrait-calendar-min-height: 100% !default;
$modal-range-portrait-calendar-min-width: 100% !default;
$modal-range-portrait-calendar-height: 100% !default;
$modal-range-portrait-header-height: 20vh !default;
$modal-range-portrait-header-padding: 2vh 2vw !default;
$modal-range-portrait-month-header-padding: 2vh 2vw !default;
$modal-range-portrait-icon-float: right !default;
$modal-range-portrait-header-month-height: 10vh !default;
$modal-range-month-header-line-height: 5vh !default;
$modal-range-month-landscape-title-line-height: 8vh !default;
$modal-range-month-header-title-line-height: inherit !default;
$modal-range-portrait-calendar-content-height: 69vh !default;
$modal-range-portrait-calendar-tabel-height: 69vh !default;
$modal-range-landscape-header-big-height: 25vh !default;
$modal-range-portrait-calendar-width: 100% !default;
$modal-range-start-end-margin: 3vh 0 0 0 !default;
$modal-range-start-end-tablet-margin: 5vh 0 0 0 !default;
$modal-range-portrait-calendar-height: 70vh !default;
$modal-range-landscape-calendar-height: 80vh !default;
$modal-range-landscape-container-height: 75vh !default;
$modal-range-header-height: 60vh !default;
$modal-range-tablet-header-height: 65vh !default;
$modal-range-calendar-padding: 0 !default;
$modal-range-calendar-overflow: unset !default;
$modal-range-calendar-header-border-style: solid !default;
$modal-range-calendar-header-border-width: 0 0 1px 0 !default;
$modal-range-prev-next-icon-size: 36px !default;
$modal-range-prev-next-icon-padding: 10px !default;
$modal-range-prev-next-icon-line-height: 1 !default;
$modal-range-calendar-th-size: 48px !default;
$modal-range-tablet-content-size: 64px !default;
$modal-range-range-hover-radius: 0 !default;
$modal-range-table-padding: 0 2vw !default;
$modal-range-footer-display: none !default;
$modal-range-landscape-header-height: 27vh !default;
$modal-range-landscape-conetent-overflow: auto !default;
$modal-range-table-display: table !default;
$modal-range-start-end-size: 28px !default;

// header styles for presets
$modal-range-presets-header-height: 10% !default;
$modal-range-presets-portrait-height: 90% !default;
$modal-range-presets-landscape-height: 85% !default;
$modal-range-header-padding: 2.5vh 2.5vw 2.5vh 0 !default;
$modal-range-header-display-style: flex !default;
$modal-range-header-content-align: center !default;
$modal-range-header-portrait-font-size: 3vh !default;
$modal-close-icon-float: left !default;
$modal-portrait-content-padding: 1vh 2vw !default;
$modal-range-header-title-transform: uppercase !default;
$modal-range-header-border-bottom: none !default;
$modal-range-header-landscape-height: 15% !default;
$modal-range-header-landscape-font-size: 18px !default;
$modal-landscape-padding: 1vh 1vw !default;

// modal dialog colors
$modal-range-header-bg-color: $primary !default;
$modal-range-header-text-color: $grey-white !default;

// tablet device style changes for modal popup
$modal-range-tablet-font-size: 18px !default;

$range-header-font-size: 16px !default;

//enddefault

@include export-module('daterangepicker-layout') {

  .e-input-group.e-control-wrapper.e-date-range-wrapper.e-non-edit.e-input-focus .e-input:focus ~ .e-clear-icon,
  .e-float-input.e-control-wrapper.e-input-group.e-date-range-wrapper.e-non-edit.e-input-focus input:focus ~ .e-clear-icon {
    display: flex;
  }

  .e-float-input.e-input-group.e-control-wrapper.e-date-range-wrapper .e-daterange-hidden,
  .e-input-group.e-control-wrapper.e-date-range-wrapper .e-daterange-hidden,
  .e-float-input.e-control-wrapper.e-date-range-wrapper .e-daterange-hidden,
  .e-float-input.e-input-group.e-control-wrapper.e-date-range-wrapper.e-input-focus .e-daterange-hidden,
  .e-input-group.e-control-wrapper.e-date-range-wrapper.e-input-focus .e-daterange-hidden,
  .e-float-input.e-control-wrapper.e-date-range-wrapper.e-input-focus .e-daterange-hidden {
    border: 0;
    height: 0;
    margin: 0;
    padding: 0;
    text-indent: 0;
    visibility: hidden;
    width: 0;
  }

  #{&}.e-daterangepicker {
    #{if(&, '&', '*')}.e-popup {
      border: $range-popup-border;
      border-radius: $range-popup-border-radius;
      box-shadow: $range-box-shadow;
      max-height: $range-max-height;
      max-width: $range-max-width;

      #{if(&, '&', '*')}.e-daterange-day-header-lg {
        max-width: $range-lg-day-header-format-width;
      }
      #{if(&, '&', '*')}.e-preset-wrapper {
        min-width: $range-preset-min-width;

        #{if(&, '&', '*')} .e-presets {
          max-height: $range-value-none;
        }
      }
      #{if(&, '&', '*')} .e-range-header {
        background: $range-header-bg-color;
        padding: $range-control-header-margin;
        width: $range-control-header-width;
        @if $skin-name == 'tailwind' {
          border-radius: $range-popup-header-border-radius;
        }
        @if $skin-name == 'Material3' {
          border-top-left-radius: $range-popup-border-radius;
        }
        @if $skin-name == 'fluent2' {
          border-top-left-radius: inherit;
          border-top-right-radius: inherit;
        }

        #{if(&, '&', '*')} .e-start-label,
        #{if(&, '&', '*')} .e-end-label {
          cursor: $range-cursor-default-style;
          display: $range-inline-block-style;
          font-size: $range-header-label-size;
          overflow: $range-visibility-hidden;
          text-align: $range-align-center;
          text-decoration: $range-value-none;
          text-overflow: $range-text-overflow;
          user-select: $range-browser-select-none;
          white-space: $range-text-nowrap;
          width: $range-start-end-label-width;
          @if $skin-name == 'Material3' {
            font-weight: $font-weight-medium;
          }
        }
        #{if(&, '&', '*')} .e-change-icon {
          font-size: $range-font-size;
          font-weight: $range-font-weight-bold;
          text-align: $range-align-center;
          width: $range-change-icon-width;
        }
        #{if(&, '&', '*')} .e-day-span {
          direction: $range-direction-left;
          font-size: $range-calendar-small-font-size;
          height: $range-day-span-height;
          margin: $range-indicator-margin;
          text-align: $range-align-center;
          user-select: $range-browser-select-none;
          width: $range-indicator-label-width;
        }
        #{if(&, '&', '*')} .e-start-end {
          align-items: $range-align-center;
          display: $range-flex-style;
          height: $range-start-end-container-height;
        }
      }
      #{if(&, '&', '*')} .e-separator {
        @if ($skin-name != 'FluentUI') {
          height: $range-separator-height;
          margin: $range-separator-margin;
        }
      }
      #{if(&, '&', '*')} .e-calendar {
        border: $range-calendar-border;
        margin: $range-calendar-margin;
        @if ($skin-name != 'FluentUI') {
          padding: $range-calendar-popup-padding;
        }
        @if $skin-name == 'fluent2' {
          box-shadow: none;
        }

        #{if(&, '&', '*')} .e-content table {
          @if ($skin-name != 'FluentUI') {
            padding: $range-calendar-normal-table-padding;
          }
        }
        #{if(&, '&', '*')} .e-header {
          #{if(&, '&', '*')} .e-title {
            cursor: $range-cursor-pointer-style;
            @if $skin-name == 'bootstrap5.3' {
              line-height: $range-normal-nav-icon-line-height;
              padding: $range-header-title-padding;
            }
            @else {
              line-height: $range-normal-nav-icon-height;
            }
            @if ($skin-name != 'FluentUI') {
              width: $range-control-header-width;
              float: $range-float-none;
              font-weight: $range-font-weight-normal;
              margin-left: $range-value-zero;
            }
          }
          #{if(&, '&', '*')}.e-month,
          #{if(&, '&', '*')}.e-year,
          #{if(&, '&', '*')}.e-decade {
            @if ($skin-name != 'FluentUI') {
              padding: $range-calendar-header-padding;
            }
          }
          #{if(&, '&', '*')} .e-next {
            @if ($skin-name != 'FluentUI' and $skin-name != 'fluent2') {
              float: $range-float-right;
            }
            @if $skin-name == 'tailwind3' {
              margin-right: 0;
              right: -10px;
            }
          }
          #{if(&, '&', '*')} .e-prev {
            @if ($skin-name != 'FluentUI' and $skin-name != 'fluent2') {
              float: $range-float-left;
            }
            @if $skin-name == 'tailwind3' {
              left: -10px;
            }
          }
          #{if(&, '&', '*')} .e-next,
          #{if(&, '&', '*')} .e-prev {
            height: $range-normal-nav-icon-height;
            width: $range-normal-nav-icon-width;
            @if $skin-name == 'tailwind3' {
              position: inherit;
              top: 4px;
            }
          }
          #{if(&, '&', '*')} .e-next span,
          #{if(&, '&', '*')} .e-prev span {
            padding: $range-icon-normal-padding;
          }
        }
        #{if(&, '&', '*')} .e-start-date.e-selected.e-range-hover {
          border-radius: $range-hover-start-radius;
        }
        #{if(&, '&', '*')} .e-end-date.e-selected.e-range-hover {
          border-radius: $range-hover-end-radius;
        }
        #{if(&, '&', '*')} .e-start-date.e-selected {
          @if ($skin-name == 'FluentUI') {
            border-radius: $range-hover-start-radius;
          }
        }
        #{if(&, '&', '*')} .e-end-date.e-selected {
          @if ($skin-name == 'FluentUI') {
            border-radius: $range-hover-end-radius;
          }
        }
        #{if(&, '&', '*')} .e-end-date.e-selected.e-range-hover span.e-day,
        #{if(&, '&', '*')} .e-start-date.e-selected.e-range-hover span.e-day {
          border: $range-calendar-hover-border;
        }
      }
      #{if(&, '&', '*')} .e-footer {
        align-items: $range-align-center;
        @if ($skin-name != 'FluentUI' and $skin-name != 'fluent2' and $skin-name != 'tailwind3') {
          border-top: $range-border-value;
        }
        clear: $range-float-clear;
        display: $range-flex-style;
        flex-direction: $range-flex-direction-row-reverse;
        height: $range-footer-height;
        @if $skin-name == 'tailwind' {
          border-radius: $range-popup-footer-border-radius;
        }
      }
      #{if(&, '&', '*')} .e-footer .e-btn {
        font-weight: $range-btn-font-weight;
        height: $range-btn-normal-height;
        line-height: $range-btn-normal-line-height;
        overflow: $range-visibility-hidden;
        padding: $range-btn-padding;
        text-overflow: $range-text-overflow;
        @if $skin-name == 'fluent2' {
          border: $footer-btn-border;
        }
      }
      #{if(&, '&', '*')} .e-footer .e-btn.e-apply {
        margin: $range-normal-footer-margin;
      }
      #{if(&, '&', '*')} .e-date-range-container {
        float: $range-float-left;
        @if $skin-name == 'fluent2' {
          border-top-left-radius: inherit;
          border-top-right-radius: inherit;
        }

        #{if(&, '&', '*')}.e-range-border {
          @if $skin-name != 'tailwind3' {
            border-right: $range-border-value;
          }
        }
      }
      #{if(&, '&', '*')} .e-calendar-container {
        display: $range-flex-style;

        #{if(&, '&', '*')} .e-left-container,
        #{if(&, '&', '*')} .e-right-container {
          float: $range-float-left;
        }
        #{if(&, '&', '*')} .e-left-container {
          @if $skin-name != 'FluentUI' and $skin-name != 'fluent2' and $skin-name != 'tailwind3' {
            border-right: $range-border-value;
          }
          @if $skin-name == 'tailwind' {
            border-right: $range-container-border-value;
          }
        }
      }
      #{if(&, '&', '*')} .e-presets {
        max-height: $range-presets-height;
        overflow: auto;
        width: $range-width-auto;

        #{if(&, '&', '*')} .e-list-item {
          border-radius: $range-list-border-radius;
          cursor: $range-cursor-pointer-style;
          line-height: $range-list-item-height;
          overflow: $range-visibility-hidden;
          padding: $range-list-item-padding;
          white-space: $range-text-nowrap;
          text-overflow: $range-text-overflow;
        }
        #{if(&, '&', '*')} .e-list-parent {
          margin: $range-value-zero;
          max-width: $range-presets-width;
          padding: $range-value-zero;
        }
        #{if(&, '&', '*')} .e-text-content {
          line-height: $range-list-item-line-height;
        }
        #{if(&, '&', '*')} .e-ul {

          #{if(&, '&', '*')} li.e-list-item {
            font-size: $range-presets-normal-list-font-size;
            height: $range-preset-normal-list-height;
            line-height: $range-preset-normal-list-height;
            @if $skin-name == 'tailwind3' {
              font-weight: 500;
            }
            &.e-active:first-child {
              @if $skin-name == 'Material3' {
                border-top-right-radius: $range-popup-border-radius;
                border-top-left-radius: $range-popup-border-radius;
              }
            }
          }
        }
      }
      #{if(&, '&', '*')} .e-hide-range {
        display: $range-display-none;
      }
    }
    #{if(&, '&', '*')}.e-rtl {
      #{if(&, '&', '*')} .e-date-range-container {
        float: $range-float-right;

        #{if(&, '&', '*')}.e-range-border {
          border-left: $range-border-value;
          border-right: $range-value-zero;
        }
        #{if(&, '&', '*')} .e-left-container {
          @if $skin-name != 'FluentUI' {
            border-left: $range-border-value;
            border-right: $range-value-zero;
          }
          @if $skin-name == 'tailwind' {
            border-left: $range-container-border-value;
          }
        }
        #{if(&, '&', '*')} .e-calendar {
          #{if(&, '&', '*')} .e-next {
            float: $range-float-left;
          }
          #{if(&, '&', '*')} .e-prev {
            @if ($skin-name != 'FluentUI') {
              float: $range-float-right;
            }
          }
          #{if(&, '&', '*')} .e-start-date.e-selected.e-range-hover {
            border-radius: $range-hover-end-radius;
            @if ($skin-name == 'FluentUI') {
              box-shadow: $selected-range-box-shadow;
            }
          }
          #{if(&, '&', '*')} .e-end-date.e-selected.e-range-hover {
            border-radius: $range-hover-start-radius;
            @if ($skin-name == 'FluentUI') {
              box-shadow: $selected-range-box-shadow;
            }
          }
        }
      }
      #{if(&, '&', '*')} .e-footer {
        flex-direction: $range-flex-direction-row;
        justify-content: $range-flex-justify-content;

        #{if(&, '&', '*')} .e-btn.e-cancel {
          margin: $range-normal-rtl-footer-margin;
        }
        #{if(&, '&', '*')} .e-btn.e-apply {
          margin-left: $range-value-zero;
        }
      }
    }
  }

  .e-small #{&}.e-daterangepicker,
  #{if(&, '&', '*')}.e-small#{&}.e-daterangepicker {
    #{if(&, '&', '*')}.e-popup {
      #{if(&, '&', '*')} .e-range-header {
        @if $skin-name != 'tailwind' {
          margin: $range-control-small-header-margin;
        }

        @if $skin-name == 'tailwind' {
          padding: $range-control-bigger-header-margin;
        }

        #{if(&, '&', '*')} .e-start-label,
        #{if(&, '&', '*')} .e-end-label {
          font-size: $range-small-header-label-size;
        }

        #{if(&, '&', '*')} .e-change-icon {
          font-size: $range-small-font-size;
        }

        #{if(&, '&', '*')} .e-start-end {
          height: $range-start-end-container-small-height;
        }

        #{if(&, '&', '*')} .e-day-span {
          font-size: $range-calendar-mouse-small-font-size;
          margin: $range-indicator-small-margin;
        }

        #{if(&, '&', '*')} .e-separator {
          @if ($skin-name != 'FluentUI') {
            margin: $range-separator-small-margin;
          }
        }
      }

      #{if(&, '&', '*')} .e-footer .e-btn.e-apply {
        margin: $range-small-footer-margin;
      }

      #{if(&, '&', '*')}.e-preset-wrapper .e-presets .e-list-parent.e-ul {
        #{if(&, '&', '*')} .e-list-item {
          font-size: $range-presets-small-list-font-size;
          height: $range-preset-small-list-height;
          line-height: $range-preset-small-list-height;
        }
      }
    }
  }
  .e-daterangepicker .e-calendar .e-month .e-selected span.e-day {
    @if $skin-name == 'FluentUI' {
      height: 25px;
      width: 25px;
      line-height: 25px;
    }
  }

  .e-daterangepicker.e-small .e-calendar .e-month .e-selected span.e-day {
    @if $skin-name == 'FluentUI' {
      height: 21px;
      width: 21px;
      line-height: 21px;
    }
  }
}

/* stylelint-disable */
.e-range-overflow {
  overflow: hidden;
}

.e-daterangepick-mob-popup-wrap {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  max-height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1002;

  .e-daterangepicker.e-popup.e-control.e-lib.e-device.e-popup-open {
    position: relative;
    top:0 !important;
    left: 0 !important;
  }

  .e-daterangepicker.e-popup.e-popup-expand.e-control.e-lib.e-device.e-popup-open {
    min-height:100%;
    min-width: 100%;
    height:100%;
    width:100%;
}
}

.e-content-placeholder.e-daterangepicker.e-placeholder-daterangepicker {
  background-size: 250px 33px;
  min-height: 33px;
}

.e-daterangepick-mob-popup-wrap {
    #{if( &, '&', '*')} .e-daterangepicker.e-popup.e-popup-expand {
      #{if(&, '&', '*')} .e-date-range-container {
            min-height: $modal-range-portrait-calendar-min-height;
            min-width: $modal-range-portrait-calendar-min-width;
            height: $modal-range-portrait-calendar-height;
            width: $modal-range-portrait-calendar-width;

            #{if(&, '&', '*')} .e-range-header {
              height: $modal-range-portrait-header-height;
              padding: $modal-range-portrait-header-padding;
              margin: 0;
              color: $range-calendar-header-dark-color;

              #{if(&, '&', '*')} .e-model-header-wrapper {
                @media (max-device-width: 768px) {
                  font-size: $range-header-font-size;
                }

                @media (min-device-width: 768px) {
                  font-size: $modal-range-tablet-font-size;
                }

                #{if(&, '&', '*')} .e-apply {
                  float: $modal-range-portrait-icon-float;
                }
              }

              #{if(&, '&', '*')} .e-start-end {
                margin: $modal-range-start-end-margin;
              }
            }
            #{if(&, '&', '*')} .e-calendar {
              @media (max-height: 600px) {
                min-height: $modal-range-portrait-calendar-height;
                height: $modal-range-portrait-calendar-height;
              }
              @media (min-height: 600px) {
                min-height: $modal-range-landscape-calendar-height;
                height: $modal-range-landscape-calendar-height;
              }
              min-width: $modal-range-portrait-calendar-min-width;
              width: $modal-range-portrait-calendar-width;
              padding: $modal-range-calendar-padding;
              overflow: $modal-range-calendar-overflow;

              #{if(&, '&', '*')} .e-header.e-month,
              #{if(&, '&', '*')} .e-header.e-year,
              #{if(&, '&', '*')} .e-header.e-decade {
                height: $modal-range-portrait-header-month-height;
                border-style: $modal-range-calendar-header-border-style;
                border-width: $modal-range-calendar-header-border-width;
                border-color: $range-separator-color;
                padding: $modal-range-portrait-month-header-padding;
                line-height: $modal-range-month-header-line-height;

                #{if(&, '&', '*')} .e-prev {
                  height: $modal-range-prev-next-icon-size;
                  width: $modal-range-prev-next-icon-size;
                  @if ($skin-name == 'Material3') {
                    vertical-align: inherit;
                    font-size: $modal-range-tablet-font-size
                  }
                  @media screen and (orientation: landscape) {
                    @if ($skin-name == 'Material3') {
                      vertical-align: inherit;
                    }
                  }

                  #{if(&, '&', '*')} span {
                    @media (min-device-width: 768px) {
                      font-size: 18px;
                      padding: 11px;
                    }
                  }
                }
                #{if(&, '&', '*')} .e-next {
                  height: $modal-range-prev-next-icon-size;
                  width: $modal-range-prev-next-icon-size;

                  #{if(&, '&', '*')} span {
                    padding: $modal-range-prev-next-icon-padding;
                    line-height: $modal-range-prev-next-icon-line-height;
                    @media (min-device-width: 768px) {
                      font-size: $modal-range-tablet-font-size;
                    }
                  }
                }

                #{if(&, '&', '*')} .e-title {
                  @media (max-height: 600px) {
                      @if ($skin-name =='Material3') {
                        margin-left: $modal-header-month-name-left-landscape-width;
                        position: absolute;
                        text-align: center;
                        vertical-align: middle;
                        width: $modal-header-month-name-width;
                        line-height: $modal-month-name-line-height;
                      }

                      line-height: $modal-range-month-landscape-title-line-height;
                    }

                  @media (min-height: 600px) {
                    @if ($skin-name == 'Material3') {
                      margin-left: $modal-header-month-name-left-width;
                      position: absolute;
                      text-align: center;
                      vertical-align: middle;
                      width: $modal-header-month-name-width;
                      line-height: $modal-month-name-line-height;
                  }
                    line-height: $modal-range-month-header-title-line-height;
                  }

                  @media (min-device-width: 768px) {
                    font-size: $modal-range-tablet-font-size;
                  }
                }
              }

              #{if(&, '&', '*')} th {
                @media (min-device-width: 768px) {
                  font-size: $modal-range-tablet-font-size;
                  height: $modal-range-calendar-th-size;
                }
              }

              #{if(&, '&', '*')} .e-content span.e-day {
                @media (min-device-width: 768px) {
                  font-size: $modal-range-tablet-font-size;
                  height: $modal-range-tablet-content-size;
                  width: $modal-range-tablet-content-size;
                  line-height: $modal-range-tablet-content-size;
                }
              }
              #{if(&, '&', '*')} .e-start-date.e-selected.e-range-hover,
              #{if(&, '&', '*')} .e-end-date.e-selected.e-range-hover {
                border-radius: $modal-range-range-hover-radius;
              }
            }
        }

        #{if( &, '&', '*')} .e-calendar-holder {
            height: 100%;

            #{if( &, '&', '*')} .e-calendar-container {
              @media (max-height: 600px) {
                min-height: 78vh;
                height: 78vh;
              }

              #{if( &, '&', '*')} .e-calendar {
                @media (max-height: 600px) {
                  min-height: 78vh;
                  height: 78vh;
                }
              }
            }
        }

        #{if(&, '&', '*')} .e-calendar-container {
          @media (max-height: 600px) {
            min-height: $modal-range-portrait-calendar-height;
            height: $modal-range-portrait-calendar-height;
          }
          @media (min-height: 600px) {
            min-height: $modal-range-landscape-calendar-height;
            height: $modal-range-landscape-calendar-height;
          }
        }

        #{if(&, '&', '*')} .e-separator {
            margin: 0;
        }

        #{if( &, '&', '*')} .e-content.e-month {
            height: $modal-range-portrait-calendar-content-height;

            table {
              padding: $modal-range-table-padding;
              height: $modal-range-portrait-calendar-tabel-height;
              border-spacing: $modal-range-calendar-overflow;
            }
        }
        #{if( &, '&', '*')} .e-footer{
          display: $modal-range-footer-display;
        }

        #{if(&, '&', '*')} .e-presets {
          max-height: $modal-range-presets-portrait-height;
          height: $modal-range-presets-portrait-height;
          #{if(&, '&', '*')} ul {
            height: $modal-range-portrait-calendar-height;

            #{if(&, '&', '*')} li.e-list-item {
              font-size: $range-header-font-size;

              @media (min-device-width: 768px) {
                font-size: 18px;
              }
            }
           }
        }

        #{if(&, '&', '*')} .e-range-mob-popup-wrap {
          position: relative;
          height:100%;

          #{if(&, '&', '*')} .e-model-header {
            height: $modal-range-presets-header-height;
            padding: $modal-range-header-padding;
            display: $modal-range-header-display-style;
            align-items: $modal-range-header-content-align;
            font-size: $modal-range-header-portrait-font-size;
            border-bottom: none;
            @media (min-device-width: 768px) {
              font-size: $modal-range-header-landscape-font-size;
            }

            #{if(&, '&', '*')} .e-popup-close {
              float: $modal-close-icon-float;
              padding: $modal-portrait-content-padding;
            }

            #{if(&, '&', '*')} .e-model-title {
              padding: $modal-portrait-content-padding;
              text-transform: $modal-range-header-title-transform;
            }
          }
        }
    }
}

@media screen and (orientation: landscape) {
  .e-daterangepick-mob-popup-wrap {
    #{if( &, '&', '*')} .e-daterangepicker.e-popup.e-popup-expand {
      #{if( &, '&', '*')} .e-date-range-container .e-range-header .e-model-header-wrapper .e-btn {
        @if $skin-name != 'fluent2' {
          padding: 0;
        }
        @media (min-device-width: 768px) {
          font-size: $modal-range-header-landscape-font-size;
        }
      }

      #{if( &, '&', '*')} .e-calendar-holder {
        #{if(&, '&', '*')} .e-calendar-container {
          @media (min-height: 600px) {
            min-height: 70vh;
            height: 70vh;
          }

          @media (max-height: 600px) {
            min-height: 65%;
            height: 65%;
          }

          #{if(&, '&', '*')} .e-calendar {
            @media (min-height: 600px) {
              min-height: 70vh;
              height: 70vh;
            }
          }

          #{if(&, '&', '*')} .e-content.e-month,
          #{if(&, '&', '*')} .e-content.e-year,
          #{if(&, '&', '*')} .e-content.e-decade {
            @media (max-height: 600px) {
              height: 50vh;
            }
          }
        }
      }

      #{if(&, '&', '*')} .e-presets {
        max-height: $modal-range-presets-landscape-height;
        height: $modal-range-presets-landscape-height;
      }

      #{if(&, '&', '*')} .e-range-mob-popup-wrap {
        #{if(&, '&', '*')} .e-model-header {
          height: $modal-range-header-landscape-height;
          font-size: $modal-range-header-landscape-font-size;

          #{if(&, '&', '*')} .e-popup-close {
            padding: $modal-landscape-padding;
          }

          #{if(&, '&', '*')} .e-model-title {
            padding: $modal-landscape-padding;
          }
        }
      }

      #{if( &, '&', '*')} .e-date-range-container .e-range-header {
          @media (max-height: 600px) {
            height: $modal-range-landscape-header-height;
          }

          @media (min-height: 600px) {
            height: $modal-range-landscape-header-big-height;
          }
          width: $modal-range-portrait-calendar-width;

          #{if(&, '&', '*')} .e-start-end {
            margin: $modal-range-calendar-padding;
            height: $modal-range-start-end-size;

            @media (min-height: 600px) {
              margin: $modal-range-start-end-tablet-margin;
            }
          }

          #{if(&, '&', '*')} .e-day-span {
            margin: 8px 0;
            font-size: 16px;
          }

        }

        #{if(&, '&', '*')} .e-calendar-container {
          @media (min-height: 600px) {
            min-height: $modal-range-landscape-container-height;
            height: $modal-range-landscape-container-height;
          }
        }

        #{if(&, '&', '*')} .e-content.e-month,
        #{if(&, '&', '*')} .e-content.e-year,
        #{if(&, '&', '*')} .e-content.e-decade {
          @media (max-height: 600px) {
            height: $modal-range-header-height;
          }

          @media (min-height: 600px) {
            height: $modal-range-tablet-header-height;
          }
          overflow-y: $modal-range-landscape-conetent-overflow;

          table {
            @media (min-height: 600px) {
              height: $modal-range-tablet-header-height;
            }
            display: $modal-range-table-display;
            border-spacing: $modal-range-calendar-overflow;
          }
        }
    }
  }
}

.e-outline.e-float-input.e-control-wrapper label.e-float-text.e-label-bottom span.e-float-text-content.e-float-text-overflow.e-date-time-icon {
  width: calc(100% - 80px);
}

.e-outline.e-float-input.e-static-clear.e-control-wrapper label.e-float-text.e-label-bottom span.e-float-text-content.e-float-text-overflow.e-date-time-icon {
  width: calc(100% - 110px);
}


@include export-module('daterangepicker-theme') {
  .e-date-range-wrapper {
    #{if(&, '&', '*')} .e-input-group-icon.e-icons.e-active {
      @if $skin-name == 'bootstrap4' {
        background: $range-icon-active-bg-color;
        border-color: $range-active-border-color;
      }
      color: $range-active-icon-color;
    }

    #{if(&, '&', '*')}.e-input-group:not(.e-disabled) .e-input-group-icon.e-active:active {
      color: $range-active-state-icon-color;
    }
  }

  #{&}.e-daterangepicker {
    #{if(&, '&', '*')}.e-popup {
      background: $range-background;

      #{if(&, '&', '*')} .e-calendar {
        @if $skin-name != 'Material3' {
          background-color: $range-background;
        }
        @if $skin-name == 'Material3' {
          background: $range-background;
        }

        #{if(&, '&', '*')} .e-header .e-title,
        #{if(&, '&', '*')} .e-header .e-title:hover {
          color: $range-calendar-header-dark-color;
          text-decoration: none;
          @if $skin-name == 'fluent2' {
            width: 72%;
          }
        }
        #{if(&, '&', '*')} .e-content .e-range-hover {
          @if $skin-name != 'Material3' {
            background-color: $range-hover-color;
          }
          @if $skin-name == 'Material3' {
            background: $range-hover-color;
          }
          color: $range-calendar-dark-color;
        }
        #{if(&, '&', '*')} .e-content .e-start-date.e-selected,
        #{if(&, '&', '*')} .e-content .e-end-date.e-selected {
          @if $skin-name == 'FluentUI' {
            background-color: $range-hover-color;
            color: $range-calendar-dark-color;
          }
        }
        #{if(&, '&', '*')} .e-content.e-month .e-today.e-range-hover span {
          @if $skin-name != 'tailwind' and $skin-name != 'Material3' and $skin-name != 'tailwind3' {
            background-color: $range-today-color;
            border: $range-today-border-color;
            color: $range-calendar-today-color;
          }
          @if $skin-name == 'Material3' {
            background: $range-today-color;
            border: $range-today-border-color;
            color: $range-calendar-today-color;
          }
          @if $skin-name == 'FluentUI' {
            box-shadow: $range-today-hover-box-shadow;
          }
        }
        #{if(&, '&', '*')} .e-content .e-range-hover span {
          @if $skin-name != 'tailwind' and $skin-name != 'Material3' and $skin-name != 'tailwind3' {
            background: $range-hover-color;
            border: $range-calendar-selection-border;
            color: $range-calendar-dark-color;
          }
          @if $skin-name == 'fluent2' {
            background: $range-hover-color;
            border: $range-calendar-selection-border;
            color: $range-calendar-hover-color;
          }
          @if $skin-name == 'Material3' {
            background: $range-hover-bg-color;
            border: $range-calendar-selection-border;
            color: $range-calendar-dark-color;
          }
        }

        #{if(&, '&', '*')} .e-content .e-range-hover:not(.e-other-month) span {
          @if $skin-name == 'tailwind' or $skin-name == 'tailwind3' {
            background: $range-hover-color;
            border: $range-calendar-selection-border;
            color: $range-calendar-dark-color;
          }
        }
        #{if(&, '&', '*')} .e-range-hover:not(.e-selected):hover span.e-day,
        #{if(&, '&', '*')} .e-range-hover.e-focused-date:not(.e-selected) span.e-day,
        #{if(&, '&', '*')} .e-range-hover.e-focused-date.e-today span.e-day {
          @if $skin-name != 'tailwind' and $skin-name != 'Material3' and $skin-name != 'tailwind3' {
            background-color: $range-hover-content-color;
            border: $range-calendar-hover-border-color;
            color: $range-calendar-dark-color;
          }
          @if $skin-name == 'Material3' {
            background: $range-hover-content-color;
            border: $range-calendar-hover-border-color;
            color: $range-calendar-dark-color;
          }
        }
        #{if(&, '&', '*')} .e-range-hover.e-today:hover span.e-day,
        #{if(&, '&', '*')} .e-range-hover.e-focused-date.e-today span.e-day,
        #{if(&, '&', '*')} .e-range-hover.e-start-date.e-selected.e-today span.e-day,
        #{if(&, '&', '*')} .e-range-hover.e-end-date.e-selected.e-today span.e-day {
          @if $skin-name != 'tailwind' and $skin-name != 'tailwind3' {
            border: $range-calendar-active-border;
          }
        }
        #{if(&, '&', '*')} .e-range-hover.e-selected.e-today:hover span.e-day {
          @if $skin-name != 'tailwind' and $skin-name != 'tailwind3' {
            border: $range-calendar-today-start-color;
          }
        }
        #{if(&, '&', '*')} .e-content .e-other-month.e-range-hover,
        #{if(&, '&', '*')} .e-content .e-other-month.e-today.e-range-hover span,
        #{if(&, '&', '*')} .e-content .e-other-month.e-range-hover span,
        #{if(&, '&', '*')} .e-content .e-other-month.e-range-hover:hover span.e-day,
        #{if(&, '&', '*')} .e-content .e-other-month.e-selected,
        #{if(&, '&', '*')} .e-content .e-other-month.e-selected span {
          @if $skin-name != 'tailwind' and $skin-name != 'Material3' and $skin-name != 'tailwind3' {
            background-color: $range-other-hover-color;
            border: $range-calendar-other-month-border;
            color: $range-other-month-date;
          }
          @if $skin-name == 'Material3' {
            background: $range-other-hover-color;
            border: $range-calendar-other-month-border;
            color: $range-other-month-date;
          }
        }
        #{if(&, '&', '*')} .e-content .e-other-month.e-range-hover,
        #{if(&, '&', '*')} .e-content .e-other-month.e-today.e-range-hover span,
        #{if(&, '&', '*')} .e-content .e-other-month.e-range-hover span,
        #{if(&, '&', '*')} .e-content .e-other-month.e-range-hover:hover span.e-day {
          @if $skin-name == 'tailwind' or $skin-name == 'tailwind3' {
            background-color: $range-other-hover-color;
            border: $range-calendar-other-month-border;
            color: $range-other-month-date;
          }
        }
        #{if(&, '&', '*')} .e-content .e-other-month.e-range-hover,
        #{if(&, '&', '*')} .e-content .e-other-month.e-selected {
          @if $skin-name != 'tailwind' and $skin-name != 'Material3' and $skin-name != 'tailwind3' {
            background-color: $range-other-hover-color;
            border: $range-calendar-other-normal-border;
            color: $range-other-month-date;
          }
          @if $skin-name == 'Material3' {
            background: $range-other-hover-color;
            border: $range-calendar-other-normal-border;
            color: $range-other-month-date;
          }
        }
        #{if(&, '&', '*')} .e-content .e-other-month.e-range-hover {
          @if $skin-name == 'tailwind' or $skin-name == 'tailwind3' {
            background-color: $range-other-hover-color;
            border: $range-calendar-other-normal-border;
            color: $range-other-month-date;
          }
        }
        #{if(&, '&', '*')} .e-end-date.e-selected.e-range-hover span.e-day,
        #{if(&, '&', '*')} .e-start-date.e-selected.e-range-hover span.e-day {
          @if $skin-name != 'Material3' and $skin-name != 'fluent2' {
            background-color: $range-selection-bg;
          }
          @if $skin-name == 'Material3' {
            background: $range-selection-bg;
          }
          @if $skin-name == 'fluent2' {
            background: $range-selection-bg;
            border-radius: $range-selected-hover-radius;
          }
          color: $range-active-font-color;
          @if ($skin-name == 'FluentUI') {
            border-radius: $range-selected-hover-radius;
            box-shadow: $range-value-none;
          }
          @if $skin-name == 'tailwind3' or $skin-name == 'tailwind3' {
            border-radius: 0;
          }
        }
        #{if(&, '&', '*')} .e-start-date.e-selected span.e-day,
        #{if(&, '&', '*')} .e-end-date.e-selected span.e-day {
          @if ($skin-name == 'FluentUI') {
            background-color: $range-selection-bg;
            border-radius: $range-selected-hover-radius;
            box-shadow: $range-value-none;
            color: $range-active-font-color;
          }
          @if ($skin-name == 'fluent2') {
            background: $range-selection-bg;
            border-radius: $range-selected-hover-radius;
            color: $range-active-font-color;
          }
        }
        #{if(&, '&', '*')} .e-end-date.e-selected.e-range-hover.e-other-month span.e-day,
        #{if(&, '&', '*')} .e-start-date.e-selected.e-range-hover.e-other-month span.e-day {
          @if ($skin-name == 'tailwind' or $skin-name == 'tailwind3') {
            background-color: $range-selection-bg;
            color: $range-active-font-color;
          }
        }
        #{if(&, '&', '*')} .e-start-date.e-selected.e-range-hover.e-today span.e-day {
          @if $skin-name != 'Material3' {
            background-color: $range-selection-bg;
          }
          @if $skin-name == 'Material3' {
            background: $range-selection-bg;
          }
          color: $range-active-font-color;
        }
        #{if(&, '&', '*')} .e-other-month.e-selected span {
          color: $range-primary-font-color;
        }
      }
      #{if(&, '&', '*')} .e-presets {
        @if $skin-name != 'Material3' {
          background-color: $range-presets-bg;
        }
        @if $skin-name == 'Material3' {
          background: $range-presets-bg;
        }
        color: $range-preset-normal-font-color;

        #{if(&, '&', '*')} .e-list-item.e-active {
          @if $skin-name != 'Material3' {
            background-color: $range-presets-bg-color;
          }
          @if $skin-name == 'Material3' {
            background: $range-presets-bg-active-color;
          }
          @if $skin-name != 'tailwind3' {
            color: $range-presets-font-color;
          }
        }
        #{if(&, '&', '*')} .e-list-item.e-hover {
          @if $skin-name != 'Material3' {
            background-color: $range-presets-hover-bg;
          }
          @if $skin-name == 'Material3' {
            background: $range-presets-hover-bg;
          }
          color: $range-list-hover-color;
        }
      }
      #{if(&, '&', '*')} .e-start-label,
      #{if(&, '&', '*')} .e-end-label {
        color: $range-calendar-header-text-color;
      }
      #{if(&, '&', '*')} .e-change-icon {
        color: $range-change-icon-color;
      }
      #{if(&, '&', '*')} .e-day-span {
        color: $range-calendar-medium-color;
      }
      #{if(&, '&', '*')} .e-separator {
        @if $skin-name != 'Material3' {
          background-color: $range-separator-color;
        }
        @if $skin-name == 'Material3' {
          background: $range-separator-color;
        }
        @if $skin-name == 'fluent2' {
          display: none;
        }
      }
      #{if(&, '&', '*')} .e-footer {
        @if $skin-name != 'Material3' {
          background-color: $range-footer-background;
        }
        @if $skin-name == 'fluent2' {
          border-bottom-left-radius: inherit;
          border-bottom-right-radius: inherit;
        }
        @if $skin-name == 'Material3' {
          background: $range-footer-background;
          border-bottom-left-radius: $range-popup-border-radius;
          border-bottom-right-radius: $range-popup-border-radius;
        }

        #{if(&, '&', '*')} .e-cancel.e-flat:not(:hover) {
          @if $skin-name == 'tailwind' or $skin-name == 'tailwind3' {
            /* stylelint-disable property-no-vendor-prefix */
            -webkit-tap-highlight-color: transparent;
            background-color: $range-cancel-flat-btn-bgcolor;
            border-color: $range-cancel-flat-btn-border-color;
            box-shadow: $range-cancel-flat-btn-box-shadow;
            color: $range-cancel-flat-btn-color;
          }
        }

        #{if(&, '&', '*')} .e-apply.e-flat.e-primary:not(:hover):not(:disabled) {
          @if $skin-name == 'tailwind' or $skin-name == 'tailwind3' {
            -webkit-tap-highlight-color: transparent;
            background-color: $range-apply-btn-primary-bgcolor;
            border-color: $range-apply-btn-primary-border-color;
            color: $range-apply-btn-primary-color;
          }
        }
      }
    }
  }

  .e-daterangepicker.e-popup .e-calendar .e-content .e-end-date.e-selected span.e-day,
  .e-daterangepicker.e-popup .e-calendar .e-content .e-start-date.e-selected span.e-day {
    @if $skin-name == 'tailwind3' {
      background: $range-primary-color;
      border-radius: 0;
    }
  }

  .e-daterangepicker.e-popup .e-footer .e-btn.e-apply.e-flat.e-primary,
  .e-daterangepicker.e-popup .e-footer .e-css.e-btn.e-apply.e-flat.e-primary {
    @if ($skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'FluentUI') {
      background-color: $apply-buuton-bg;
      border-color: $apply-buuton-bg-border-color;
      color: $apply-buuton-text-color;
    }
  }

  .e-daterangepicker.e-popup .e-footer .e-btn.e-apply.e-flat.e-primary:hover,
  .e-daterangepicker.e-popup .e-footer .e-css.e-btn.e-apply.e-flat.e-primary:hover {
    @if ($skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'FluentUI') {
      background-color: $apply-buuton-hover-bg;
      border-color: $apply-buuton-bg-border-hover-color;
      color: $apply-buuton-text-hover-color;
    }
  }

  .e-daterangepicker.e-popup .e-footer .e-btn.e-apply.e-flat.e-primary:active,
  .e-daterangepicker.e-popup .e-footer .e-btn.e-apply.e-flat.e-primary.e-active,
  .e-daterangepicker.e-popup .e-footer .e-css.e-btn.e-apply.e-flat.e-primary:active,
  .e-daterangepicker.e-popup .e-footer .e-css.e-btn.e-apply.e-flat.e-primary.e-active {
    @if ($skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'FluentUI') {
      background-color: $apply-buuton-active-bg;
      border-color: $apply-buuton-bg-border-active-color;
      color: $apply-buuton-text-active-color;
    }
  }

  .e-daterangepicker.e-popup .e-footer .e-btn.e-apply.e-flat.e-primary:disabled,
  .e-daterangepicker.e-popup .e-footer .e-btn.e-apply.e-flat.e-primary:disabled,
  .e-daterangepicker.e-popup .e-footer .e-css.e-btn.e-apply.e-flat.e-primary:disabled,
  .e-daterangepicker.e-popup .e-footer .e-css.e-btn.e-apply.e-flat.e-primary:disabled {
    @if ($skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'FluentUI') {
      background-color: $range-apply-disabled-background-style;
      border-color: $range-apply-disabled-border-style;
      color: $range-apply-disabled-color;
    }
  }

  .e-daterangepicker.e-popup .e-footer .e-btn.e-flat,
  .e-daterangepicker.e-popup .e-footer .e-css.e-btn.e-flat {
    @if ($skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'FluentUI') {
      background-color: $cancel-buuton-bg;
      border-color: $cancel-buuton-bg-border-color;
      color: $cancel-buuton-text-color;
    }
  }

  .e-daterangepicker.e-popup .e-footer .e-btn.e-flat:hover,
  .e-daterangepicker.e-popup .e-footer .e-css.e-btn.e-flat:hover {
    @if ($skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'FluentUI') {
      background-color: $cancel-buuton-hover-bg;
      border-color: $cancel-buuton-bg-border-hover-color;
      color: $cancel-buuton-text-hover-color;
    }
  }

  .e-daterangepicker.e-popup .e-footer .e-btn.e-flat:active,
  .e-daterangepicker.e-popup .e-footer .e-btn.e-flat.e-active,
  .e-daterangepicker.e-popup .e-footer .e-css.e-btn.e-flat:active,
  .e-daterangepicker.e-popup .e-footer .e-css.e-btn.e-flat.e-active {
    @if ($skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'FluentUI') {
      background-color: $cancel-buuton-active-bg;
      border-color: $cancel-buuton-bg-border-active-color;
      color: $cancel-buuton-text-active-color;
    }
  }

  .e-daterangepicker.e-popup .e-footer .e-btn.e-flat:disabled,
  .e-daterangepicker.e-popup .e-footer .e-btn.e-flat:disabled,
  .e-daterangepicker.e-popup .e-footer .e-css.e-btn.e-flat:disabled,
  .e-daterangepicker.e-popup .e-footer .e-css.e-btn.e-flat:disabled {
    @if ($skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'FluentUI') {
      background-color: $range-cancel-disabled-background-style;
      border-color: $range-cancel-disabled-border-style;
      color: $range-cancel-disabled-color;
    }
  }
}

.e-daterangepicker.e-popup .e-calendar .e-content.e-month .e-today.e-range-hover span {
  @if $skin-name == 'FluentUI' {
    line-height: $range-today-hover-line-height;
  }
}

.e-daterangepicker.e-popup .e-calendar .e-content .e-other-month.e-today.e-range-hover span {
  @if $skin-name == 'FluentUI' {
    line-height: $range-other-hover-line-height;
  }
}

.e-small .e-calendar .e-content.e-month td.e-today.e-range-hover span.e-day,
.e-small .e-calendar .e-content.e-month td.e-other-month.e-today.e-range-hover span.e-day {
  @if $skin-name == 'FluentUI' {
    line-height: $range-small-today-hover-line-height;
  }
}

.e-daterangepick-mob-popup-wrap {
  #{if( &, '&', '*')} .e-daterangepicker.e-popup.e-popup-expand {
    #{if(&, '&', '*')} .e-model-header {
      background-color: $modal-range-header-bg-color;
      color: $modal-range-header-text-color;

      #{if(&, '&', '*')} .e-popup-close {
        color: $modal-range-header-text-color;
      }
    }
  }
}


@include export-module('daterangepicker-material-icons') {

  /*! component icons */
  .e-input-group-icon.e-range-icon,
  #{if(&, '&', '*')}.e-control-wrapper .e-input-group-icon.e-range-icon {
    font-size: $range-icon-font-size;
    margin: $range-icon-margin;
    min-height: $range-icon-container-min-height;
    min-width: $range-icon-container-min-width;
    outline: none;

    #{if(&, '&', '*')}::before {
      content: '\e245';
      font-family: 'e-icons';
    }

    #{if(&, '&', '*')}:focus {
      background: $range-icon-hover-color;
      border-radius: $range-icon-border-radius;
    }
  }

  .e-daterangepicker {
    #{if(&, '&', '*')} .e-calendar .e-header .e-date-icon-next::before {
      content: '\e913';
    }
    #{if(&, '&', '*')} .e-calendar .e-header .e-date-icon-prev::before {
      content: '\e921';
    }
    #{if(&, '&', '*')} .e-change-icon::before {
      content: '\e85f';
    }
    #{if(&, '&', '*')} .e-calendar.e-rtl .e-header .e-date-icon-next::before {
      content: '\e921';
    }
    #{if(&, '&', '*')} .e-calendar.e-rtl .e-header .e-date-icon-prev::before {
      content: '\e913';
    }
    #{if(&, '&', '*')}.e-rtl .e-start-end .e-change-icon::before {
      content: '\e85b';
    }
  }

  // mouse small icon
  .e-small .e-input-group-icon.e-range-icon,
  #{if(&, '&', '*')}.e-control-wrapper.e-small .e-input-group-icon.e-range-icon,
  #{if(&, '&', '*')}.e-small .e-control-wrapper .e-input-group-icon.e-range-icon {
    font-size: $range-small-icon-font-size;
  }

  // Outline textbox configuration

  .e-date-range-wrapper.e-outline .e-input-group-icon.e-range-icon,
  .e-date-range-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-range-icon {
    min-height: $zero-value;
    min-width: 20px;
  }

  .e-small .e-date-range-wrapper.e-outline .e-input-group-icon.e-range-icon,
  .e-date-range-wrapper.e-control-wrapper.e-small.e-outline .e-input-group-icon.e-range-icon,
  .e-small .e-date-range-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-range-icon {
    min-height: $zero-value;
    min-width: 18px;
  }

  .e-daterangepick-mob-popup-wrap {

    #{if(&, '&', '*')} .e-daterangepicker.e-popup-expand {

      #{if(&, '&', '*')} .e-range-header,
      #{if(&, '&', '*')} .e-model-header {

        #{if(&, '&', '*')} .e-popup-close::before {
          content: '\e932';
          font-family: 'e-icons';
        }

        #{if(&, '&', '*')} .e-apply::before {
          content: '\e7ff';
          font-family: 'e-icons';
        }
      }
    }
  }
}

@include export-module('daterangepicker-bigger') {

  .e-bigger #{&}.e-daterangepicker.e-range-modal,
  #{if(&, '&', '*')}.e-device#{&}.e-daterangepicker.e-range-modal {
    @if $skin-name != 'Material3' {
      background-color: $range-overlay;
    }
    @if $skin-name == 'Material3' {
      background: $range-overlay;
    }
    height: 100%;
    left: 0;
    opacity: .5;
    pointer-events: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
  }

  .e-bigger #{&}.e-daterangepicker,
  #{if(&, '&', '*')}.e-bigger#{&}.e-daterangepicker,
  #{if(&, '&', '*')}.e-device#{&}.e-daterangepicker {
    #{if(&, '&', '*')}.e-popup {
      @if $skin-name == 'material' or $skin-name == 'bootstrap'{
        background-color: $range-background;
      }
      @if $skin-name == 'Material3' {
        background: $range-background;
      }

      #{if(&, '&', '*')} .e-start-label,
      #{if(&, '&', '*')} .e-end-label {
        @if $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
          font-size: $range-bigger-header-label-size;
        }
      }

      #{if(&, '&', '*')} .e-day-span {
        @if $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
          font-size: $range-bigger-indicator-label-size;
        }
      }

      #{if(&, '&', '*')}.e-preset-wrapper {
        max-width: $range-bigger-max-width;
        min-width: $range-width-auto;

        #{if(&, '&', '*')} .e-presets {
          max-height: $range-value-none;
        }
      }
      #{if(&, '&', '*')} .e-range-header {
        @if $skin-name == 'bootstrap4' or $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
          margin: $range-control-bigger-header-margin;
        }

        @if $skin-name == 'tailwind' {
          padding: $range-control-bigger-header-margin;
        }
        width: $range-control-header-width;

        #{if(&, '&', '*')} .e-change-icon {
          @if $skin-name == 'bootstrap4' or $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'tailwind' {
            font-size: $range-bigger-change-icon-size;
          }
        }

        #{if(&, '&', '*')} .e-start-end {
          align-items: $range-align-center;
          cursor: $range-cursor-pointer-style;
          display: $range-flex-style;
          height: $range-device-header-container-height;
          justify-content: $range-align-center;
          user-select: $range-browser-select-none;
          width: $range-device-header-container-width;

          #{if(&, '&', '*')} .e-start-btn,
          #{if(&, '&', '*')} .e-end-btn {
            border: $range-btn-border-value;
            box-shadow: $range-box-shadow-none;
            font-size: $range-calendar-btn-font-size;
            font-weight: $range-range-btn-font-weight;
            height: $range-btn-height;
            line-height: $range-device-btn-line-height;
            max-width: $range-header-btn-max-width;
            overflow: $range-visibility-hidden;
            padding: $range-sart-end-btn-padding;
            text-overflow: $range-text-overflow;
            width: $range-btn-width;
          }
          #{if(&, '&', '*')} .e-end-btn {
            border-left: $range-value-zero;
            border-radius: $range-btn-right-radius;
          }
          #{if(&, '&', '*')} .e-start-btn {
            border-radius: $range-btn-left-radius;
          }
          #{if(&, '&', '*')} .e-start-btn:hover,
          #{if(&, '&', '*')} .e-end-btn:hover:not([disabled]) {
            box-shadow: $range-box-shadow-none;
          }
          #{if(&, '&', '*')} .e-start-btn.e-active,
          #{if(&, '&', '*')} .e-start-btn.e-active:active,
          #{if(&, '&', '*')} .e-end-btn.e-active,
          #{if(&, '&', '*')} .e-end-btn.e-active:active:not([disabled]),
          #{if(&, '&', '*')} .e-start-btn.e-active:hover,
          #{if(&, '&', '*')} .e-end-btn.e-active:hover {
            box-shadow: $range-box-shadow-none;
          }
          #{if(&, '&', '*')} .e-start-btn,
          #{if(&, '&', '*')} .e-end-btn {
            max-width: $range-header-btn-bigger-max-width;
          }
        }
      }
      #{if(&, '&', '*')} .e-presets {
        max-height: $range-bigger-presets-height;

        #{if(&, '&', '*')}.e-preset-wrapper {
          max-height: $range-value-none;
        }

        #{if(&, '&', '*')} ul {
          max-width: $range-value-none;

          #{if(&, '&', '*')} li.e-list-item {
            font-size: $range-presets-bigger-list-font-size;
            height: $range-preset-bigger-list-height;
            line-height: $range-preset-bigger-list-height;
            padding: $range-device-list-item-padding;
          }
        }
      }
    }
    #{if(&, '&', '*')} .e-calendar {
      max-width: $range-calendar-bigger-max;
      @if ($skin-name != 'FluentUI') {
        padding: $range-bigger-calendar-popup-padding;
      }

      #{if(&, '&', '*')} .e-content table {
        @if ($skin-name != 'FluentUI') {
          padding: $range-calendar-bigger-table-padding;
        }
      }
      #{if(&, '&', '*')} .e-header {
        @if ($skin-name == 'FluentUI') {
          padding: 8px 16px;
        }

        #{if(&, '&', '*')} .e-next,
        #{if(&, '&', '*')} .e-prev {
          height: $range-bigger-nav-icon-height;
          width: $range-bigger-nav-icon-width;

          #{if(&, '&', '*')} span {
            padding: $range-icon-bigger-padding;
          }
        }

        #{if(&, '&', '*')} .e-title {
          cursor: $range-cursor-pointer-style;
          line-height: $range-bigger-nav-icon-height;
        }
        #{if(&, '&', '*')}.e-month,
        #{if(&, '&', '*')}.e-year,
        #{if(&, '&', '*')}.e-decade {
          @if ($skin-name != 'FluentUI') {
            padding: $range-e-bigger-header-padding;
          }
        }
      }
    }
    #{if(&, '&', '*')} .e-footer {
      height: $range-bigger-footer-height;

      #{if(&, '&', '*')} .e-btn {
        height: $range-btn-bigger-height;
        line-height: $range-btn-bigger-line-height;
        overflow: $range-visibility-hidden;
      }
      #{if(&, '&', '*')} .e-btn.e-apply {
        margin: $range-bigger-footer-margin;
      }
    }
    #{if(&, '&', '*')}.e-rtl.e-popup {
      #{if(&, '&', '*')} .e-range-header .e-start-end {
        #{if(&, '&', '*')} .e-end-btn {
          border: $range-btn-border-value;
          border-radius: $range-btn-left-radius;
          border-right: $range-value-zero;
        }
        #{if(&, '&', '*')} .e-start-btn {
          border-radius: $range-btn-right-radius;
        }
      }
      #{if(&, '&', '*')} .e-footer {
        #{if(&, '&', '*')}.e-btn.e-cancel {
          margin: $range-bigger-rtl-footer-margin;
        }
        #{if(&, '&', '*')} .e-btn.e-apply {
          margin-left: $range-value-zero;
        }
      }
    }
    #{if(&, '&', '*')}.e-device {
      #{if(&, '&', '*')}.e-popup {
        max-width: $range-device-max-width;

        #{if(&, '&', '*')} .e-range-header {
          margin: $range-device-control-header-margin;
          padding: $range-device-control-header-padding;

          #{if(&, '&', '*')} .e-day-span {
            @if $skin-name == 'tailwind' {
              height: $range-device-indicator-height;
              line-height: $range-device-indicator-height;
            }
            margin: $range-device-indicator-margin;

            @if $skin-name == 'fluentUI' or $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'bootstrap4' {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .e-daterangepicker.e-bigger .e-calendar .e-month .e-selected span.e-day {
    @if $skin-name == 'FluentUI' {
      height: 29px;
      width: 29px;
      line-height: 29px;
    }
  }


  .e-bigger .e-content-placeholder.e-daterangepicker.e-placeholder-daterangepicker,
  .e-bigger.e-content-placeholder.e-daterangepicker.e-placeholder-daterangepicker {
    background-size: 250px 40px;
    min-height: 40px;
  }

  .e-bigger .e-calendar .e-content.e-month td.e-today.e-range-hover span.e-day {
    @if $skin-name == 'FluentUI' {
      line-height: $range-bigger-today-hover-line-height;
    }
  }

  .e-bigger .e-calendar .e-content.e-month td.e-other-month.e-today.e-range-hover span.e-day {
    @if $skin-name == 'FluentUI' {
      line-height: $range-bigger-other-hover-line-height;
    }
  }

  .e-bigger #{&}.e-daterangepicker,
  #{if(&, '&', '*')}.e-bigger#{&}.e-daterangepicker,
  #{if(&, '&', '*')}.e-device#{&}.e-daterangepicker {
    @if $skin-name == 'material' {
      background-color: initial;
    }
    @else if $skin-name == 'Material3' {
      background: initial;
    }
    @else if $skin-name == 'bootstrap-dark' {
      background-color: $range-background;
    }
    @else {
      background-color: $range-bg-color;
    }
    padding: $range-popup-padding;

    #{if(&, '&', '*')} .e-calendar th {
      color: $range-calendar-light-color;
    }
    #{if(&, '&', '*')} .e-start-btn,
    #{if(&, '&', '*')} .e-end-btn {
      background: $range-bg-color;
      @if $skin-name == 'bootstrap4' {
        color: $range-button-font-color;
      }
      @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3'{
        color: $range-button-font-color;
      }
    }
    #{if(&, '&', '*')} .e-start-btn.e-active,
    #{if(&, '&', '*')} .e-start-btn.e-active:active,
    #{if(&, '&', '*')} .e-end-btn.e-active,
    #{if(&, '&', '*')} .e-end-btn.e-active:active:not([disabled]),
    #{if(&, '&', '*')} .e-start-btn.e-active:hover,
    #{if(&, '&', '*')} .e-end-btn.e-active:hover {
      background: $range-primary-color;
      color: $range-primary-font-color;
    }
  }

  .e-daterangepicker.e-bigger.e-small .e-calendar .e-month .e-selected span.e-day {
    @if $skin-name == 'FluentUI' {
      height: 25px;
      width: 25px;
      line-height: 25px;
    }
  }

  .e-bigger.e-small #{&}.e-daterangepicker {
    #{if(&, '&', '*')}.e-popup {
      border: $range-popup-border;
      border-radius: $range-popup-border-radius;
      box-shadow: $range-box-shadow;
      max-height: $range-max-height;
      max-width: $range-max-width;

      #{if(&, '&', '*')}.e-daterange-day-header-lg {
        max-width: $range-lg-day-header-format-width;
      }
      #{if(&, '&', '*')}.e-preset-wrapper {
        min-width: $range-preset-min-width;

        #{if(&, '&', '*')} .e-presets {
          max-height: $range-value-none;
        }
      }
      #{if(&, '&', '*')} .e-range-header {
        background: $range-header-bg-color;
        padding: $range-control-header-margin;
        width: $range-control-header-width;
        @if $skin-name == 'tailwind' {
          border-radius: $range-popup-header-border-radius;
        }
        @if $skin-name == 'Material3' {
          border-top-left-radius: $range-popup-border-radius;
        }

        #{if(&, '&', '*')} .e-start-label,
        #{if(&, '&', '*')} .e-end-label {
          cursor: $range-cursor-default-style;
          display: $range-inline-block-style;
          font-size: $range-header-label-size;
          overflow: $range-visibility-hidden;
          text-align: $range-align-center;
          text-decoration: $range-value-none;
          text-overflow: $range-text-overflow;
          user-select: $range-browser-select-none;
          white-space: $range-text-nowrap;
          width: $range-start-end-label-width;
          @if $skin-name == 'Material3' {
            font-weight: $font-weight-medium;
          }
        }
        #{if(&, '&', '*')} .e-change-icon {
          font-size: $range-font-size;
          font-weight: $range-font-weight-bold;
          text-align: $range-align-center;
          width: $range-change-icon-width;
        }
        #{if(&, '&', '*')} .e-day-span {
          direction: $range-direction-left;
          font-size: $range-calendar-small-font-size;
          height: $range-day-span-height;
          margin: $range-indicator-margin;
          text-align: $range-align-center;
          user-select: $range-browser-select-none;
          width: $range-indicator-label-width;
        }
        #{if(&, '&', '*')} .e-start-end {
          align-items: $range-align-center;
          display: $range-flex-style;
          height: $range-start-end-container-height;
        }
      }
      #{if(&, '&', '*')} .e-separator {
        @if ($skin-name != 'FluentUI') {
          height: $range-separator-height;
          margin: $range-separator-margin;
        }
      }
      #{if(&, '&', '*')} .e-calendar {
        border: $range-calendar-border;
        margin: $range-calendar-margin;
        @if ($skin-name != 'FluentUI') {
          padding: $range-calendar-popup-padding;
        }
        @if $skin-name == 'fluent2' {
          box-shadow: none;
        }

        #{if(&, '&', '*')} .e-content table {
          @if ($skin-name != 'FluentUI') {
            padding: $range-calendar-normal-table-padding;
          }
        }
        #{if(&, '&', '*')} .e-header {
          #{if(&, '&', '*')} .e-title {
            cursor: $range-cursor-pointer-style;
            line-height: $range-normal-nav-icon-height;
            @if ($skin-name != 'FluentUI') {
              width: $range-control-header-width;
              float: $range-float-none;
              font-weight: $range-font-weight-normal;
              margin-left: $range-value-zero;
            }
          }
          #{if(&, '&', '*')}.e-month,
          #{if(&, '&', '*')}.e-year,
          #{if(&, '&', '*')}.e-decade {
            @if ($skin-name != 'FluentUI') {
              padding: $range-calendar-header-padding;
            }
          }
          #{if(&, '&', '*')} .e-next {
            @if ($skin-name != 'FluentUI' and $skin-name != 'fluent2') {
              float: $range-float-right;
            }
          }
          #{if(&, '&', '*')} .e-prev {
            @if ($skin-name != 'FluentUI' and $skin-name != 'fluent2') {
              float: $range-float-left;
            }
          }
          #{if(&, '&', '*')} .e-next,
          #{if(&, '&', '*')} .e-prev {
            height: $range-normal-nav-icon-height;
            width: $range-normal-nav-icon-width;
          }
          #{if(&, '&', '*')} .e-next span,
          #{if(&, '&', '*')} .e-prev span {
            padding: $range-icon-normal-padding;
          }
        }
        #{if(&, '&', '*')} .e-start-date.e-selected.e-range-hover {
          border-radius: $range-hover-start-radius;
        }
        #{if(&, '&', '*')} .e-end-date.e-selected.e-range-hover {
          border-radius: $range-hover-end-radius;
        }
        #{if(&, '&', '*')} .e-start-date.e-selected {
          @if ($skin-name == 'FluentUI') {
            border-radius: $range-hover-start-radius;
          }
        }
        #{if(&, '&', '*')} .e-end-date.e-selected {
          @if ($skin-name == 'FluentUI') {
            border-radius: $range-hover-end-radius;
          }
        }
        #{if(&, '&', '*')} .e-end-date.e-selected.e-range-hover span.e-day,
        #{if(&, '&', '*')} .e-start-date.e-selected.e-range-hover span.e-day {
          border: $range-calendar-hover-border;
        }
      }
      #{if(&, '&', '*')} .e-footer {
        align-items: $range-align-center;
        @if ($skin-name != 'FluentUI') {
          border-top: $range-border-value;
        }
        clear: $range-float-clear;
        display: $range-flex-style;
        flex-direction: $range-flex-direction-row-reverse;
        height: $range-footer-height;
        @if $skin-name == 'tailwind' {
          border-radius: $range-popup-footer-border-radius;
        }
      }
      #{if(&, '&', '*')} .e-footer .e-btn {
        font-weight: $range-btn-font-weight;
        height: $range-btn-normal-height;
        line-height: $range-btn-normal-line-height;
        overflow: $range-visibility-hidden;
        padding: $range-btn-padding;
        text-overflow: $range-text-overflow;
        @if $skin-name == 'fluent2' {
          border: $footer-btn-border;
        }
      }
      #{if(&, '&', '*')} .e-footer .e-btn.e-apply {
        margin: $range-normal-footer-margin;
      }
      #{if(&, '&', '*')} .e-date-range-container {
        float: $range-float-left;

        #{if(&, '&', '*')}.e-range-border {
          border-right: $range-border-value;
        }
      }
      #{if(&, '&', '*')} .e-calendar-container {
        display: $range-flex-style;

        #{if(&, '&', '*')} .e-left-container,
        #{if(&, '&', '*')} .e-right-container {
          float: $range-float-left;
        }
        #{if(&, '&', '*')} .e-left-container {
          @if $skin-name != 'FluentUI' {
            border-right: $range-border-value;
          }
          @if $skin-name == 'tailwind' {
            border-right: $range-container-border-value;
          }
        }
      }
      #{if(&, '&', '*')} .e-presets {
        max-height: $range-presets-height;
        overflow: auto;
        width: $range-width-auto;

        #{if(&, '&', '*')} .e-list-item {
          border-radius: $range-list-border-radius;
          cursor: $range-cursor-pointer-style;
          line-height: $range-list-item-height;
          overflow: $range-visibility-hidden;
          padding: $range-list-item-padding;
          white-space: $range-text-nowrap;
          text-overflow: $range-text-overflow;
        }
        #{if(&, '&', '*')} .e-list-parent {
          margin: $range-value-zero;
          max-width: $range-presets-width;
          padding: $range-value-zero;
        }
        #{if(&, '&', '*')} .e-text-content {
          line-height: $range-list-item-line-height;
        }
        #{if(&, '&', '*')} .e-ul {

          #{if(&, '&', '*')} li.e-list-item {
            font-size: $range-presets-normal-list-font-size;
            height: $range-preset-normal-list-height;
            line-height: $range-preset-normal-list-height;
            &.e-active:first-child {
              @if $skin-name == 'Material3' {
                border-top-right-radius: $range-popup-border-radius;
                border-top-left-radius: $range-popup-border-radius;
              }
            }
          }
        }
      }
      #{if(&, '&', '*')} .e-hide-range {
        display: $range-display-none;
      }
    }
    #{if(&, '&', '*')}.e-rtl {
      #{if(&, '&', '*')} .e-date-range-container {
        float: $range-float-right;

        #{if(&, '&', '*')}.e-range-border {
          border-left: $range-border-value;
          border-right: $range-value-zero;
        }
        #{if(&, '&', '*')} .e-left-container {
          @if $skin-name != 'FluentUI' {
            border-left: $range-border-value;
            border-right: $range-value-zero;
          }
          @if $skin-name == 'tailwind' {
            border-left: $range-container-border-value;
          }
        }
        #{if(&, '&', '*')} .e-calendar {
          #{if(&, '&', '*')} .e-next {
            float: $range-float-left;
          }
          #{if(&, '&', '*')} .e-prev {
            @if ($skin-name != 'FluentUI') {
              float: $range-float-right;
            }
          }
          #{if(&, '&', '*')} .e-start-date.e-selected.e-range-hover {
            border-radius: $range-hover-end-radius;
            @if ($skin-name == 'FluentUI') {
              box-shadow: $selected-range-box-shadow;
            }
          }
          #{if(&, '&', '*')} .e-end-date.e-selected.e-range-hover {
            border-radius: $range-hover-start-radius;
            @if ($skin-name == 'FluentUI') {
              box-shadow: $selected-range-box-shadow;
            }
          }
        }
      }
      #{if(&, '&', '*')} .e-footer {
        flex-direction: $range-flex-direction-row;
        justify-content: $range-flex-justify-content;

        #{if(&, '&', '*')} .e-btn.e-cancel {
          margin: $range-normal-rtl-footer-margin;
        }
        #{if(&, '&', '*')} .e-btn.e-apply {
          margin-left: $range-value-zero;
        }
      }
    }
  }

  .e-bigger.e-small #{&}.e-daterangepicker #{&}.e-calendar {
    max-width: $calendar-bigger-small-max-width;
  }

  .e-bigger.e-small #{&}.e-daterangepicker,
  .e-bigger.e-small#{&}.e-daterangepicker {
    #{if(&, '&', '*')}.e-popup {
      background: $range-background;

      #{if(&, '&', '*')} .e-calendar {
        @if $skin-name != 'Material3' {
          background-color: $range-background;
        }
        @if $skin-name == 'Material3' {
          background: $range-background;
        }

        #{if(&, '&', '*')} .e-header .e-title,
        #{if(&, '&', '*')} .e-header .e-title:hover {
          color: $range-calendar-header-dark-color;
          text-decoration: none;
          @if $skin-name == 'fluent2' {
            width: 72%;
          }
        }
        #{if(&, '&', '*')} .e-content .e-range-hover {
          @if $skin-name != 'Material3' {
            background-color: $range-hover-color;
          }
          @if $skin-name == 'Material3' {
            background: $range-hover-color;
          }
          color: $range-calendar-dark-color;
        }
        #{if(&, '&', '*')} .e-content .e-start-date.e-selected,
        #{if(&, '&', '*')} .e-content .e-end-date.e-selected {
          @if $skin-name == 'FluentUI' {
            background-color: $range-hover-color;
            color: $range-calendar-dark-color;
          }
        }
        #{if(&, '&', '*')} .e-content.e-month .e-today.e-range-hover span {
          @if $skin-name != 'tailwind' and $skin-name != 'Material3' {
            background-color: $range-today-color;
            border: $range-today-border-color;
            color: $range-calendar-today-color;
          }
          @if $skin-name == 'Material3' {
            background: $range-today-color;
            border: $range-today-border-color;
            color: $range-calendar-today-color;
          }
          @if $skin-name == 'FluentUI' {
            box-shadow: $range-today-hover-box-shadow;
          }
        }
        #{if(&, '&', '*')} .e-content .e-range-hover span {
          @if $skin-name != 'tailwind' and $skin-name != 'Material3' {
            background: $range-hover-color;
            border: $range-calendar-selection-border;
            color: $range-calendar-dark-color;
          }
          @if $skin-name == 'Material3' {
            background: $range-hover-bg-color;
            border: $range-calendar-selection-border;
            color: $range-calendar-dark-color;
          }
        }

        #{if(&, '&', '*')} .e-content .e-range-hover:not(.e-other-month) span {
          @if $skin-name == 'tailwind' {
            background: $range-hover-color;
            border: $range-calendar-selection-border;
            color: $range-calendar-dark-color;
          }
        }
        #{if(&, '&', '*')} .e-range-hover:not(.e-selected):hover span.e-day,
        #{if(&, '&', '*')} .e-range-hover.e-focused-date:not(.e-selected) span.e-day,
        #{if(&, '&', '*')} .e-range-hover.e-focused-date.e-today span.e-day {
          @if $skin-name != 'tailwind' and $skin-name != 'Material3' {
            background-color: $range-hover-content-color;
            border: $range-calendar-hover-border-color;
            color: $range-calendar-dark-color;
          }
          @if $skin-name == 'Material3' {
            background: $range-hover-content-color;
            border: $range-calendar-hover-border-color;
            color: $range-calendar-dark-color;
          }
        }
        #{if(&, '&', '*')} .e-range-hover.e-today:hover span.e-day,
        #{if(&, '&', '*')} .e-range-hover.e-focused-date.e-today span.e-day,
        #{if(&, '&', '*')} .e-range-hover.e-start-date.e-selected.e-today span.e-day,
        #{if(&, '&', '*')} .e-range-hover.e-end-date.e-selected.e-today span.e-day {
          @if $skin-name != 'tailwind' {
            border: $range-calendar-active-border;
          }
        }
        #{if(&, '&', '*')} .e-range-hover.e-selected.e-today:hover span.e-day {
          @if $skin-name != 'tailwind' {
            border: $range-calendar-today-start-color;
          }
        }
        #{if(&, '&', '*')} .e-content .e-other-month.e-range-hover,
        #{if(&, '&', '*')} .e-content .e-other-month.e-today.e-range-hover span,
        #{if(&, '&', '*')} .e-content .e-other-month.e-range-hover span,
        #{if(&, '&', '*')} .e-content .e-other-month.e-range-hover:hover span.e-day,
        #{if(&, '&', '*')} .e-content .e-other-month.e-selected,
        #{if(&, '&', '*')} .e-content .e-other-month.e-selected span {
          @if $skin-name != 'tailwind' and $skin-name != 'Material3' {
            background-color: $range-other-hover-color;
            border: $range-calendar-other-month-border;
            color: $range-other-month-date;
          }
          @if $skin-name == 'Material3' {
            background: $range-other-hover-color;
            border: $range-calendar-other-month-border;
            color: $range-other-month-date;
          }
        }
        #{if(&, '&', '*')} .e-content .e-other-month.e-range-hover,
        #{if(&, '&', '*')} .e-content .e-other-month.e-today.e-range-hover span,
        #{if(&, '&', '*')} .e-content .e-other-month.e-range-hover span,
        #{if(&, '&', '*')} .e-content .e-other-month.e-range-hover:hover span.e-day {
          @if $skin-name == 'tailwind' {
            background-color: $range-other-hover-color;
            border: $range-calendar-other-month-border;
            color: $range-other-month-date;
          }
        }
        #{if(&, '&', '*')} .e-content .e-other-month.e-range-hover,
        #{if(&, '&', '*')} .e-content .e-other-month.e-selected {
          @if $skin-name != 'tailwind' and $skin-name != 'Material3' {
            background-color: $range-other-hover-color;
            border: $range-calendar-other-normal-border;
            color: $range-other-month-date;
          }
          @if $skin-name == 'Material3' {
            background: $range-other-hover-color;
            border: $range-calendar-other-normal-border;
            color: $range-other-month-date;
          }
        }
        #{if(&, '&', '*')} .e-content .e-other-month.e-range-hover {
          @if $skin-name == 'tailwind' {
            background-color: $range-other-hover-color;
            border: $range-calendar-other-normal-border;
            color: $range-other-month-date;
          }
        }
        #{if(&, '&', '*')} .e-end-date.e-selected.e-range-hover span.e-day,
        #{if(&, '&', '*')} .e-start-date.e-selected.e-range-hover span.e-day {
          @if $skin-name != 'Material3' and $skin-name != 'fluent2' {
            background-color: $range-selection-bg;
          }
          @if $skin-name == 'Material3' {
            background: $range-selection-bg;
          }
          @if $skin-name == 'fluent2' {
            background: $range-selection-bg;
            border-radius: $range-selected-hover-radius;
          }
          color: $range-active-font-color;
          @if ($skin-name == 'FluentUI') {
            border-radius: $range-selected-hover-radius;
            box-shadow: $range-value-none;
          }
        }
        #{if(&, '&', '*')} .e-start-date.e-selected span.e-day,
        #{if(&, '&', '*')} .e-end-date.e-selected span.e-day {
          @if ($skin-name == 'FluentUI') {
            background-color: $range-selection-bg;
            border-radius: $range-selected-hover-radius;
            box-shadow: $range-value-none;
            color: $range-active-font-color;
          }
          @if ($skin-name == 'fluent2') {
            background: $range-selection-bg;
            border-radius: $range-selected-hover-radius;
            color: $range-active-font-color;
          }
        }
        #{if(&, '&', '*')} .e-end-date.e-selected.e-range-hover.e-other-month span.e-day,
        #{if(&, '&', '*')} .e-start-date.e-selected.e-range-hover.e-other-month span.e-day {
          @if ($skin-name == 'tailwind') {
            background-color: $range-selection-bg;
            color: $range-active-font-color;
          }
        }
        #{if(&, '&', '*')} .e-start-date.e-selected.e-range-hover.e-today span.e-day {
          @if $skin-name != 'Material3' {
            background-color: $range-selection-bg;
          }
          @if $skin-name == 'Material3' {
            background: $range-selection-bg;
          }
          color: $range-active-font-color;
        }
        #{if(&, '&', '*')} .e-other-month.e-selected span {
          color: $range-primary-font-color;
        }
      }
      #{if(&, '&', '*')} .e-presets {
        @if $skin-name != 'Material3' {
          background-color: $range-presets-bg;
        }
        @if $skin-name == 'Material3' {
          background: $range-presets-bg;
        }
        color: $range-preset-normal-font-color;

        #{if(&, '&', '*')} .e-list-item.e-active {
          @if $skin-name != 'Material3' {
            background-color: $range-presets-bg-color;
          }
          @if $skin-name == 'Material3' {
            background: $range-presets-bg-active-color;
          }
          color: $range-presets-font-color;
        }
        #{if(&, '&', '*')} .e-list-item.e-hover {
          @if $skin-name != 'Material3' {
            background-color: $range-presets-hover-bg;
          }
          @if $skin-name == 'Material3' {
            background: $range-presets-hover-bg;
          }
          color: $range-list-hover-color;
        }
      }
      #{if(&, '&', '*')} .e-start-label,
      #{if(&, '&', '*')} .e-end-label {
        color: $range-calendar-header-text-color;
      }
      #{if(&, '&', '*')} .e-change-icon {
        color: $range-change-icon-color;
      }
      #{if(&, '&', '*')} .e-day-span {
        color: $range-calendar-medium-color;
      }
      #{if(&, '&', '*')} .e-separator {
        @if $skin-name != 'Material3' {
          background-color: $range-separator-color;
        }
        @if $skin-name == 'Material3' {
          background: $range-separator-color;
        }
        @if $skin-name == 'fluent2' {
          display: none;
        }
      }
      #{if(&, '&', '*')} .e-footer {
        @if $skin-name != 'Material3' {
          background-color: $range-footer-background;
        }
        @if $skin-name == 'Material3' {
          background: $range-footer-background;
          border-bottom-left-radius: $range-popup-border-radius;
          border-bottom-right-radius: $range-popup-border-radius;
        }

        #{if(&, '&', '*')} .e-cancel.e-flat:not(:hover) {
          @if $skin-name == 'tailwind' {
            /* stylelint-disable property-no-vendor-prefix */
            -webkit-tap-highlight-color: transparent;
            background-color: $range-cancel-flat-btn-bgcolor;
            border-color: $range-cancel-flat-btn-border-color;
            box-shadow: $range-cancel-flat-btn-box-shadow;
            color: $range-cancel-flat-btn-color;
          }
        }

        #{if(&, '&', '*')} .e-apply.e-flat.e-primary:not(:hover):not(:disabled) {
          @if $skin-name == 'tailwind' {
            -webkit-tap-highlight-color: transparent;
            background-color: $range-apply-btn-primary-bgcolor;
            border-color: $range-apply-btn-primary-border-color;
            color: $range-apply-btn-primary-color;
          }
        }
      }
    }
  }

  .e-bigger.e-small .e-calendar .e-content.e-month td.e-today.e-range-hover span.e-day {
    @if $skin-name == 'FluentUI' {
      line-height: $range-today-hover-line-height;
    }
  }


  .e-bigger.e-small .e-calendar .e-content.e-month td.e-today.e-other-month.e-range-hover span.e-day {
    @if $skin-name == 'FluentUI' {
      line-height: $range-other-hover-line-height;
    }
  }

  .e-bigger {
    #{if(&, '&', '*')} .e-input-group-icon.e-range-icon,
    #{if(&, '&', '*')}.e-control-wrapper .e-input-group-icon.e-range-icon,
    #{if(&, '&', '*')} .e-control-wrapper .e-input-group-icon.e-range-icon {
      @if $skin-name == 'tailwind' or $skin-name == 'bootstrap4' or $skin-name == 'bootstrap5.3' or $skin-name == 'bootstrap5' or $skin-name == 'FluentUI' or $skin-name == 'material-dark' or $skin-name == 'material' or $skin-name == 'tailwind3' {
        font-size: $range-bigger-icon-font-size;
        margin: $range-icon-bigger-margin;
        min-height: $range-bigger-icon-container-min-height;
        min-width: $range-bigger-icon-container-min-width;
        outline: none;
      }
      @if $skin-name == 'fluent2' or $skin-name == 'Material3' {
        font-size: $range-bigger-icon-font-size;
        margin: $range-icon-bigger-margin;
        min-height: $range-icon-bigger-container-min-height;
        min-width: $range-icon-bigger-container-min-width;
        outline: none;
        border-radius: $range-icon-bigger-border-radius;
      }
      @if $skin-name == 'fluent2' {
        padding: 6px 12px 6px 6px;
      }
      @if $skin-name == 'bootstrap-dark' or $skin-name == 'bootstrap' or $skin-name == 'fabric-dark' or $skin-name == 'fabric'  or $skin-name == 'highcontrast-light' or $skin-name == 'highcontrast' {
        font-size: $range-bigger-icon-font-size;
        margin: $range-icon-bigger-margin;
        outline: none;
      }
    }
  }

  .e-small.e-bigger .e-input-group-icon.e-range-icon,
  #{if(&, '&', '*')}.e-control-wrapper.e-small.e-bigger .e-input-group-icon.e-range-icon,
  #{if(&, '&', '*')}.e-small.e-bigger .e-control-wrapper .e-input-group-icon.e-range-icon {
    font-size: $range-bigger-small-icon-font-size;
    @if $skin-name == 'fluent2' or $skin-name == 'Material3' {
      min-width: $range-bigger-small-icon-container-min-width;
      min-height: $range-bigger-small-icon-container-min-height;
      border-radius: $range-bigger-small-icon-border-radius;
      margin: $zero-value;
    }
    @if $skin-name == 'Material3' {
      padding: $zero-value;
    }
  }

  .e-bigger .e-date-range-wrapper.e-outline .e-input-group-icon.e-range-icon,
  .e-date-range-wrapper.e-control-wrapper.e-bigger.e-outline .e-input-group-icon.e-range-icon,
  .e-bigger .e-date-range-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-range-icon {
    @if $skin-name == 'fluent2' or $skin-name == 'material-dark'  or $skin-name == 'material' or $skin-name == 'Material3' {
      min-height: $zero-value;
      min-width: 24px;
    }
  }

  .e-bigger.e-small .e-outline.e-date-range-wrapper .e-input-group-icon.e-range-icon,
  .e-bigger.e-small.e-outline.e-date-range-wrapper .e-input-group-icon.e-range-icon,
  .e-date-range-wrapper.e-control-wrapper.e-bigger.e-small.e-outline .e-input-group-icon.e-range-icon,
  .e-bigger.e-small .e-date-range-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-range-icon {
    @if $skin-name == 'fluent2' or $skin-name == 'material-dark' or $skin-name == 'material' or $skin-name == 'Material3' {
      min-height: $zero-value;
      min-width: 20px;
    }
  }

  .e-daterangepicker.e-popup.e-bigger .e-calendar .e-header .e-title,
  .e-daterangepicker.e-popup.e-device .e-calendar .e-header .e-title {
    @if $skin-name == 'FluentUI' {
      width: 72%;
    }
  }

  .e-daterangepicker.e-popup.e-bigger.e-small .e-calendar .e-header .e-title {
    @if $skin-name == 'FluentUI' {
      width: 70%;
    }
  }
}

@import "../base/base.gd.scss";
@import "../popups/popup.gd.scss";
@import "../buttons/button.gd.scss";

$drop-down-btn-arrow-content: '\e969' !default;
$drop-down-btn-vertical-btn-padding: 12px 12px !default;
$drop-down-btn-vertical-bigger-padding: 16px 16px !default;
$drop-down-btn-bigger-font-size: 15px !default;
$drop-down-btn-bigger-li-height: 48px !default;
$drop-down-btn-bigger-max-width: 280px !default;
$drop-down-btn-bigger-min-width: 112px !default;
$drop-down-btn-color: rgba($grey-light-font, .87) !default;
$drop-down-btn-disable-text: rgba($grey-light-font, .38) !default;
$drop-down-btn-font-size: 14px !default;
$drop-down-btn-font-weight: normal !default;
$drop-down-btn-icon-font-size: 14px !default;
$drop-down-btn-icon-margin-right: 10px !default;
$drop-down-btn-bigger-icon-margin-right: 10px !default;
$drop-down-btn-li-bgcolor: $grey-300 !default;
$drop-down-btn-li-box-shadow: none !default;
$drop-down-btn-li-border-color: rgba($grey-light-font, .12) !default;
$drop-down-btn-li-border-style: solid !default;
$drop-down-btn-li-border-width: 1px !default;
$drop-down-btn-li-focus-bgcolor: $grey-300 !default;
$drop-down-btn-li-height: 36px !default;
$drop-down-btn-li-padding: 0 16px !default;
$drop-down-btn-bigger-li-padding: 0 16px !default;
$drop-down-btn-small-li-padding: 0 16px !default;
$drop-down-btn-li-selection-bgcolor: $grey-400 !default;
$drop-down-btn-li-selection-font-color: rgba($grey-light-font, .87) !default;
$drop-down-btn-max-width: 240px !default;
$drop-down-btn-menu-icon-bigger-font-size: 16px !default;
$drop-down-btn-menu-icon-color: rgba($grey-black, .87) !default;
$drop-down-btn-menu-icon-disabled-color: rgba($grey-light-font, .38) !default;
$drop-down-btn-menu-icon-font-size: 14px !default;
$drop-down-btn-min-width: 120px !default;
$drop-down-btn-parent-ul-box-shadow: 0 5px 5px -3px rgba($grey-black, .2), 0 8px 10px 1px rgba($grey-black, .14), 0 3px 14px 2px rgba($grey-black, .12) !default;
$drop-down-btn-seperator-padding: 8px 0 !default;
$drop-down-btn-selected-color: rgba($grey-light-font, .87) !default;
$drop-down-btn-sub-ul-box-shadow: 0 5px 5px -3px rgba($grey-black, .2), 0 8px 10px 1px rgba($grey-black, .14), 0 3px 14px 2px rgba($grey-black, .12) !default;
$drop-down-btn-ul-bgcolor: $grey-white !default;
$drop-down-btn-ul-border-radius: 0 !default;
$drop-down-btn-ul-border: none !default;
$drop-down-btn-ul-padding: 8px 0 !default;
$drop-down-btn-ul-bigger-padding: 8px 0 !default;
$drop-down-btn-ul-small-padding: 8px 0 !default;
$drop-down-btn-caret-icon-font-size: 8px !default;
$drop-down-btn-icon-font-size-bigger: 16px !default;
$drop-down-btn-caret-icon-font-size-bigger: 10px !default;
$drop-down-btn-box-shadow: none !default;
$drop-down-btn-popup-margin-top: 0 !default;
$drop-down-btn-bigger-popup-margin-top: 0 !default;

@include export-module('drop-down-button-layout') {
  .e-dropdown-btn,
  .e-dropdown-btn.e-btn {
    @if $skin-name != 'bootstrap4' and $skin-name != 'bootstrap5' and $skin-name != 'bootstrap5.3' and $skin-name != 'fluent2' and $skin-name != 'tailwind3' {
      box-shadow: none;
    }
    text-transform: none;
    white-space: normal;
    @if $skin-name == 'Material3' {
      border-radius: $btn-border-radius;
      box-shadow: $drop-down-btn-box-shadow;
      padding: 7px 16px;
    }

    &:hover {
      @if $skin-name != 'bootstrap4' and $skin-name != 'bootstrap5' and $skin-name != 'bootstrap5.3' and $skin-name != 'Material3' {
        box-shadow: none;
      }
    }

    &:focus {
      @if $skin-name == 'tailwind' or $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'Material3' {
        box-shadow: $drop-down-btn-box-shadow;
      }
      @else {
        @if $skin-name != 'tailwind3' {
          box-shadow: none;
        }
      }
    }

    &:focus-visible { // only for keybord
      @if $skin-name == 'Material3' or $skin-name == 'fluent2' {
        box-shadow: $btn-keyboard-focus-box-shadow;
      }
    }

    &:active {
      box-shadow: $btn-active-box-shadow;
    }

    .e-btn-icon {
      font-size: $drop-down-btn-icon-font-size;
    }

    .e-caret {
      font-size: $drop-down-btn-caret-icon-font-size;
      @if $skin-name == 'FluentUI' or $skin-name == 'highcontrast' {
        margin-top: 0;
      }
    }

    &.e-vertical {
      line-height: 1;
      padding: $drop-down-btn-vertical-btn-padding;
    }

    &.e-caret-hide {
      & .e-caret {
        display: none;
      }
    }

    &.e-small {
      .e-dropdown-popup ul {
        padding: $drop-down-btn-ul-small-padding;

        & .e-item  {
          padding: $drop-down-btn-small-li-padding;
          @if $skin-name == 'Material3' {
            height: $drop-down-btn-small-li-height;
          }
        }
      }

      &.e-vertical {
        line-height: 1;
        padding: $drop-down-btn-vertical-btn-padding;
      }

      & .e-btn-icon {
        @if $skin-name == 'tailwind3' {
          font-size: $drop-down-btn-small-icon-font-size;
        }
      }

      & .e-btn-icon.e-caret {
        @if $skin-name == 'fluent2' {
          font-size: 12px;
        }
      }
    }
  }

  .e-small.e-dropdown-popup ul {
    & .e-item {
      @if $skin-name == 'tailwind3' {
        height: $drop-down-btn-small-li-height;
        font-size: $drop-down-btn-small-font-size;
        line-height: $drop-down-btn-small-li-height;
        .e-menu-icon {
          font-size: $drop-down-btn-small-icon-font-size;
          line-height: $drop-down-btn-small-li-height;
        }
      }
    }
  }

  .e-dropdown-popup {
    position: absolute;
    @if $skin-name == 'tailwind' or $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $theme-name == 'tailwind-dark' or $theme-name == 'bootstrap5-dark' {
      border-radius: $drop-down-btn-ul-border-radius;
      margin-top: $drop-down-btn-popup-margin-top;
    }
    @if $skin-name == 'fluent2' {
      border-radius: $drop-down-btn-ul-border-radius;
    }

    & ul {
      border: $drop-down-btn-ul-border;
      border-radius: $drop-down-btn-ul-border-radius;
      box-shadow: $drop-down-btn-parent-ul-box-shadow;
      box-sizing: border-box;
      font-size: $drop-down-btn-font-size;
      font-weight: $drop-down-btn-font-weight;
      list-style-image: none;
      list-style-position: outside;
      list-style-type: none;
      margin: 0;
      min-width: $drop-down-btn-min-width;
      overflow: hidden;
      padding: $drop-down-btn-ul-padding;
      user-select: none;
      white-space: nowrap;

      & .e-item  {
        cursor: pointer;
        display: flex;
        height: $drop-down-btn-li-height;
        line-height: $drop-down-btn-li-height;
        padding: $drop-down-btn-li-padding;
        @if $skin-name == 'fluent2' {
          line-height: 19px;
          margin: 4px;
          border-radius: 4px;
        }

        &.e-separator {
          padding: 0 !important; /* stylelint-disable-line declaration-no-important */
        }

        &.e-url {
          padding: 0;
        }

        & .e-menu-url {
          display: block;
          padding: $drop-down-btn-li-padding;
          text-decoration: none;
          width: 100%;
        }

        & .e-menu-icon {
          float: left;
          font-size: $drop-down-btn-menu-icon-font-size;
          @if $skin-name == 'fluent2' {
            line-height: 18px;
            padding: 2px;
          }
          @else {
            line-height: $drop-down-btn-li-height;
          }
          margin-right: $drop-down-btn-icon-margin-right;
          vertical-align: middle;
          width: 1em;
        }

        &.e-disabled {
          cursor: auto;
          pointer-events: none;
          @if $skin-name == 'Material3' {
            box-shadow: $drop-down-btn-sub-ul-box-shadow;
          }
          @if $skin-name == 'fluent2' or $skin-name == 'tailwind3' {
            color: $flyout-text-color-disabled;
            opacity: 1;

            & .e-menu-icon {
              color: $flyout-text-color-disabled;
            }
          }
        }

        &.e-separator {
          border-bottom-style: $drop-down-btn-li-border-style;
          border-bottom-width: $drop-down-btn-li-border-width;
          cursor: auto;
          height: auto;
          line-height: normal;
          margin: $drop-down-btn-seperator-padding;
          pointer-events: none;
        }
      }
    }

    &.e-transparent {
      background: transparent;
      box-shadow: none;

      & .e-dropdown-menu {
        border: 0;
        height: 1px;
        min-width: 0;
        padding: 0;
        width: 1px;
      }
    }

    &.e-hidden {
      display: none !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  .e-rtl.e-dropdown-popup .e-item {
    & .e-menu-icon {
      float: right;
      margin-left: $drop-down-btn-icon-margin-right;
      margin-right: 0;
    }
  }

  .e-dropdown-popup-width ul {
    min-width: 0 !important; /* stylelint-disable-line declaration-no-important */
  }

  .e-dropdown-popup-width ul li {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block !important; /* stylelint-disable-line declaration-no-important */
  }
}


@include export-module('drop-down-button-theme') {
  .e-dropdown-popup {
    background: $drop-down-btn-ul-bgcolor;
    color: $drop-down-btn-color;

    & ul {
      & .e-item  {
        & .e-menu-url {
          color: $drop-down-btn-color;
        }

        & .e-menu-icon {
          color: $drop-down-btn-menu-icon-color;
        }

        &.e-focused {
          @if $skin-name != 'FluentUI' {
            background: $drop-down-btn-li-focus-bgcolor;
          }
          box-shadow: $drop-down-btn-li-box-shadow;
        }

        &:hover {
          @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
            background: $drop-down-btn-li-hover-bgcolor;
          }
          @else {
            background: $drop-down-btn-li-bgcolor;
          }
          color: $drop-down-btn-li-selection-font-color;
          & .e-menu-icon,
          & .e-menu-url {
            @if $skin-name == 'fluent2' {
              color: $drop-down-btn-li-selection-font-color;
            }
          }
        }

        &:active,
        &.e-selected {
          background: $drop-down-btn-li-selection-bgcolor;
          color: $drop-down-btn-selected-color;

          & .e-menu-url {
            color: $drop-down-btn-selected-color;
          }

          & .e-menu-icon {
            @if $skin-name == 'FluentUI' or $theme-name == 'FluentUI' {
              color: $drop-down-btn-menu-icon-color;
            }
            @else {
              color: $drop-down-btn-selected-color;
            }
          }
        }

        &:focus {
          @if $skin-name == 'tailwind3' {
            background: $drop-down-btn-li-bgcolor;
            color: $drop-down-btn-li-selection-font-color;
          }
        }

        &:focus-visible {
          @if $theme-name == 'fluent2' {
            background-color: $flyout-bg-color-focus;
            box-shadow: $btn-keyboard-focus-box-shadow;
            color: $flyout-text-color-focus;

            & .e-menu-icon {
              color: $flyout-text-color-focus;
            }
          }
        }
      }

      & .e-separator {
        border-bottom-color: $drop-down-btn-li-border-color;
      }
    }
  }

  .e-dropdown-btn,
  .e-dropdown-btn.e-btn {
    &.e-inherit {
      background: inherit;
      border-color: transparent;
      box-shadow: none;
      color: inherit;

      &:hover,
      &:focus,
      &:active,
      &.e-active {
        background: rgba(transparent, .056);
        border-color: transparent;
        box-shadow: none;
        color: inherit;
      }
    }
  }
}


@include export-module('drop-down-button-material-icons') {
  .e-dropdown-btn,
  .e-dropdown-btn.e-btn {
    .e-caret {
      &::before {
        content: '\e969';
      }
    }
  }
}

@include export-module('drop-down-button-bigger') {
  .e-bigger .e-dropdown-btn,
  .e-bigger.e-dropdown-btn {
    .e-btn-icon {
      font-size: $drop-down-btn-icon-font-size-bigger;
    }

    .e-caret {
      font-size: $drop-down-btn-caret-icon-font-size-bigger;
    }

    &.e-vertical {
      line-height: 1;
      padding: $drop-down-btn-vertical-bigger-padding;
    }

    &.e-small {
      &.e-vertical {
        line-height: 1;
        padding: $drop-down-btn-vertical-bigger-padding;
      }
      .e-btn-icon {
        @if $skin-name == 'tailwind3' {
          font-size: $drop-down-btn-icon-font-size-bigger;
        }
      }
    }
  }

  .e-bigger .e-small.e-dropdown-popup ul,
  .e-bigger.e-small.e-dropdown-popup ul {
    & .e-item {
      @if $skin-name == 'tailwind3' {
        height: $drop-down-btn-bigger-small-li-height;
        font-size: $drop-down-btn-bigger-font-size;
        line-height: $drop-down-btn-bigger-small-li-height;
        .e-menu-icon {
          font-size: $drop-down-btn-icon-font-size-bigger;
          line-height: $drop-down-btn-bigger-small-li-height;
        }
      }
    }
  }

  #{if(&, '&', '*')}.e-bigger .e-dropdown-popup {
    @if $skin-name == 'tailwind' or $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $theme-name == 'tailwind-dark' or $theme-name == 'bootstrap5-dark' {
      background-color: transparent;
      border-radius: $drop-down-btn-ul-border-radius;
    }
  }

  #{if(&, '&', '*')}.e-bigger .e-dropdown-popup ul,
  #{if(&, '&', '*')}.e-bigger.e-dropdown-popup ul {
    box-shadow: $drop-down-btn-parent-ul-box-shadow;
    font-size: $drop-down-btn-bigger-font-size;
    max-width: $drop-down-btn-bigger-max-width;
    min-width: $drop-down-btn-bigger-min-width;
    padding: $drop-down-btn-ul-bigger-padding;
    @if $skin-name == 'tailwind' or $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
      background-color: $drop-down-btn-ul-bgcolor;
      margin-top: $drop-down-btn-bigger-popup-margin-top;
    }

    & .e-item  {
      height: $drop-down-btn-bigger-li-height;
      @if $skin-name != 'fluent2' {
        line-height: $drop-down-btn-bigger-li-height;
      }
      @if $skin-name == 'fluent2' {
        height: 40px;
        line-height: 22px;
      }
      padding: $drop-down-btn-bigger-li-padding;

      &.e-url {
        padding: 0;
      }

      & .e-menu-url {
        @if $skin-name == 'fluent2' {
          padding: 8px 8px 10px;
        }
      }

      & .e-menu-icon {
        font-size: $drop-down-btn-menu-icon-bigger-font-size;
        line-height: $drop-down-btn-bigger-li-height;
        @if $skin-name == 'fluent2' {
          line-height: 22px;
        }
      }

      &.e-separator {
        height: auto;
        line-height: normal;
      }
    }
  }
}

@use 'sass:math';
@import "../base/base.gd.scss";



$pager-font-size: 13px !default;
$pager-font-line-height: 1 !default;
$pager-numeric-font-line-height: $pager-font-line-height !default;
$pager-font-family: sans-serif !default;
$pager-border-type: $border-type !default;
$pager-mobile-icon-size: 11px !default;
$pager-icon-color: $default-icon-color !default;
$pager-hover-color: $hover-font-color !default;
$pager-mhover-color: $default-font-color !default;
$page-content-color: $default-font-color !default;
$pager-pagermessage-weight: normal !default;
$pager-last-border-size: 0 !default;
$pager-bigger-icons-align: '' !default;
$pager-numeric-font-weight: normal !default;
$pager-messagebar-padding-bottom: 9px !default;
$pager-bigger-messagebar-padding-bottom: 8px !default;
$pager-letter-spacing: -.1em !default;
$pager-numeric-hover-bg-color: unset !default;
$pager-numeric-txt-bg-color: unset !default;
$pager-bigger-new-container-rtl-margin: 5px 0 !default;
$pager-pagecontainer-icon-padding: 11px 9px 8px !default;
$pager-bigger-pagecontainer-icon-padding: 11px 9px 8px !default;
$pager-last-page-hover-border-radius: 0 4px 4px 0 !default;
$pager-first-page-hover-border-radius: 4px 0 0 4px !default;
$pager-item-hover-borders-width: 0 !default;
$pager-rtl-parentmsgbar-margin-left: 6px !default;
$pager-rtl-parentmsgbar-padding-top: 18px !default;
$pager-bigger-padding: 4px 0 2px !default;
$pager-bigger-icon-font-size: 13px !default;
$pager-bigger-font-size: 13px !default;
$pager-bigger-pagercontainer-margin: 5px 8px 5px 24px !default;
$pager-bigger-numeric-item-margin: 0 8px !default;
$pager-bigger-current-item-padding: 10px 13px !default;
$pager-bigger-pagercontainer-icons-marign-right: 16px !default;
$pager-bigger-pagercontainer-icons-padding: 6px !default;
$pager-bigger-rtl-nexticon-marign-left: 0 !default;
$pager-device-padding: 19px 0 !default;
$pager-device-messabar-padding: 0 !default;
$pager-bigger-rtl-parentmsgbar-margin-left: 6px !default;
$pager-bigger-letter-spacing: 10px 13px !default;
$pager-numericitem-min-width: 26px !default;
$pager-bg-color: $grey-white !default;
$pager-active-bg-color: $accent !default;
$pager-hover-bg-color: $grey-white !default;
$pager-number-hover-bg-color: $grey-200 !default;
$pager-border-size: 0 !default;
$pager-icon-width: 18px !default;
$pager-icon-height: 18px !default;
$pager-container-margin: 5px 6px 5px 18px !default;
$pager-navigationicon-padding: 5px !default;
$pager-navigationicon-right: 12px !default;
$pager-navigationicon-top: 5px !default;
$pager-numericitem-padding: 5px 8px !default;
$pager-numericitem-margin-right: 4px !default;
$pager-numericitem-border-radius: 12px !default;
$pager-icon-size: 9px !default;
$pager-messagebar-padding-top: 14px !default;
$pager-messagebar-padding-right: 18px !default;
$pager-padding: 0 !default;
$pager-border-color: $grey-300 !default;
$pager-color: $grey-light-font !default;
$pager-active-color: $accent-font !default;
$pager-content-opacity: .87 !default;
$pager-numeric-icon-padding: 6px !default;
$pager-container-rtl-margin: 5px 6px 5px 18px !default;
$pager-rtl-navigation-icon-padding: 9px 8px !default;
$pager-activeitem-padding: 6px !default;
$pager-bigger-activeitem-padding: $pager-activeitem-padding !default;
$pager-bigger-rtl-activeitem-padding: 10px 13px !default;
$pager-rtl-numeric-margin: 11px 4px 0 0 !default;
$pager-next-icon-margin-left: 9px !default;
$pager-tripledot-font-size: 22px !default;
$pager-tripledot-padding: 0 6px !default;
$pager-first-content-icon: 'e896' !default;
$pager-prev-content-icon: 'e904' !default;
$pager-next-content-icon: 'e913' !default;
$pager-last-content-icon: 'e897' !default;
$pager-dropdown-margin-left: 12px !default;
$pager-dropdown-margin-top: -26px !default;
$pager-dropdown-margin-right: 0 !default;
$pager-dropdown-overflow: hidden !default;
$pager-bigger-dropdown-constant-margin: 15px !default;
$pager-bigger-dropdown-margin: -40px 0 0 16px !default;
$pager-dropdown-height: 32px !default;
$pager-dropdown-width: 90px !default;
$pager-bigger-dropdown-width: 90px !default;
$pager-constant-margin: 0 0 11px 12px !default;
$pager-tripledot-rtl-margin-top: -2px !default;
$pager-tripledot-rtl-padding: 3px 11px 9px !default;
$pager-rtl-pagerconstant-margin-top: 14px !default;
$pager-rtl-pagerdropdown-margin: -33px 18px 0 10px !default;
$pager-tripledot-rtl-bigger-margin-top: 0 !default;
$pager-bigger-rtl-numeric-margin: 0 8px !default;
$pager-bigger-container-rtl-margin: 5 0 -4px 0 !default;
$pager-rtl-pagerdropdown-bigger-margin: -35px 18px 0 0 !default;
$pager-rtl-pagerconstant-bigger-margin-top: 8px 16px 16px 0 !default;
$pager-bigger-rtl-parentmsgbar-margin-top: 0 !default;
$pager-bigger-rtl-pagercontainer-margin-top: 5px !default;
$pager-bigger-device-rtl-parentmsgbar-margin-top: 5px !default;
$pager-rtl-pagermsgbar-device-margin-top: 7px !default;
$pager-bigger-constant-margin-left: 16px !default;
$pager-rtl-last-content-icon: 'e896' !default;
$pager-rtl-first-content-icon: 'e897' !default;
$pager-rtl-prev-content-icon: 'e913' !default;
$pager-rtl-next-content-icon: 'e904' !default;
$pager-rtl-first-last-icon-hover-border-radius: 0 4px 4px 0 !default;
$pager-bigger-messagebar-padding-right: math.div($pager-messagebar-padding-right, .75) !default;
$pager-bigger-messagebar-padding-top: 20px !default;
$pager-bigger-nexticon-marign-left: math.div($pager-next-icon-margin-left, .75) !default;
$pager-bigger-rtl-nexticon-marign-right: math.div($pager-next-icon-margin-left, .75) !default;
$pager-bigger-current-item-border-radius: math.div($pager-numericitem-border-radius, .75) !default;
$pager-item-hover-border-width: 0 !default;
$pager-item-hover-border-color: $pager-border-color !default;
$pager-bigger-current-item-hover-padding: $pager-bigger-current-item-padding !default;
$pager-numeric-icon-hover-padding: $pager-numeric-icon-padding !default;
$pager-bigger-rtl-activeitem-hover-padding: $pager-bigger-rtl-activeitem-padding !default;
$pager-activeitem-hover-padding: $pager-activeitem-padding !default;
$pager-bigger-dropdown-margin-top: -36px !default;
$pager-rtl-nxtpage-margin: 6px !default;
$pager-disabled-icons-opacity: .3 !default;
$pager-bigger-dropdown-heigh: 40px !default;
$pager-external-msg-padding: 6px !default;
$pager-rtl-bigger-tripledot-font-size: $pager-tripledot-font-size !default;
$pager-current-item-font-weight: normal !default;
$pager-skin: 'material' !default;
$pager-container-border-radius: 4px !default;
$pager-bigger-tripledot-font-size: $pager-tripledot-font-size !default;
$pager-focus-bg-color: $grey-200 !default;
$pager-focus-tripledot-padding: 2.5px 0 9px !default;
$pager-focus-tripledot-line-height: 14px !default;
$pager-focus-active-box-shadow: none !default;
$pager-focus-shadow: 0 0 0 1px $grey-500 inset !default;
$pager-bigger-adaptive-pagermsgbar-margin-top: 0 !default;
$pager-bigger-adaptive-dropdown-margin: 2px 2px 0 4px !default;
$pager-bigger-adaptive-dropdown-min-width: 77px !default;
$pager-bigger-adaptive-constant-top: 9px !default;
$pager-rtl-adaptive-constant-top: 0 10px 0 8px !default;
$pager-rtl-e-bigger-adaptive-dropdown-margin: 2px 4px 0 2px !default;
$pager-rtl-e-bigger-adaptive-pagermsgbar-margin-top: 0 !default;
$pager-bigger-adaptive-pager-padding: 6px !default;
$pager-adaptive-pagermsgbar-margin-top: -4px !default;
$pager-adaptive-dropdown-margin: 6px 2px 0 4px !default;
$pager-rtl-adaptive-pagermsgbar-margin-top: -4px !default;
$pager-rtl-adaptive-dropdown-margin: 8px 4px 0 2px !default;
$pager-adaptive-constant-pagesizeall-top: 14px !default;
$pager-bigger-adaptive-constant-pagesizeall-top: 14px !default;
$pager-rtl-adaptive-constant-pagesizeall-top: 15px !default;
$pager-rtl-e-bigger-adaptive-constant-pagesizeall-top: 14px !default;

@mixin padding-bottom-right-top-styles($bottom, $right, $top) {
  padding-bottom: $bottom;
  padding-right: $right;
  padding-top: $top;
}

@mixin fontsize-padding-styles($fontsize, $padding) {
  font-size: $fontsize;
  padding: $padding;
}

@mixin textalign-width-styles($align, $width) {
  text-align: $align;
  width: $width;
}

@mixin margin-left-right($left, $right) {
  margin-left: $left;
  margin-right: $right;
}

@mixin border-left-width-styles($style, $width) {
  border-left-style: $style;
  border-left-width: $width;
}

@mixin border-right-width-styles($style, $width) {
  border-right-style: $style;
  border-right-width: $width;
}

@include export-module('pager-layout') {

  /*! Pager layout */
  #{&}.e-pager {
    border-style: $pager-border-type;
    border-width: 1px;
    display: inline-block;
    @include fontsize-padding-styles($pager-font-size, $pager-padding);
    white-space: normal;
    width: 100%;

    [class ^= 'e-'] {
      box-sizing: border-box;
    }

    .e-pager-default.e-hide,
    .e-pagesizes.e-hide,
    .e-hide {
      display: none;
    }

    div.e-parentmsgbar {
      float: right;
      @include padding-bottom-right-top-styles($pager-messagebar-padding-bottom, $pager-messagebar-padding-right, $pager-messagebar-padding-top);
      @if $pager-skin == 'tailwind' {
        line-height: 18px;
      }
      @if $pager-skin == 'fluent2' {
        line-height: 20px;
      }
    }

    .e-pagesizes {
      display: inline;
    }

    .e-pagerdropdown {
      display: inline-block;
      height: $pager-dropdown-height;
      @include margin-left-right($pager-dropdown-margin-left, $pager-dropdown-margin-right);
      margin-top: $pager-dropdown-margin-top;
      overflow: $pager-dropdown-overflow;
      vertical-align: middle;
      width: $pager-dropdown-width;
    }

    .e-pagerconstant {
      @if $pager-skin == 'fluent' {
        font-weight: 600;
      }
      display: inline-block;
      @if $pager-skin == 'tailwind' {
        line-height: 18px;
      }
      @if $pager-skin == 'fluent2' {
        line-height: 20px;
      }
      margin: $pager-constant-margin;
      overflow: hidden;
      width: auto;
    }

    .e-icons {
      font-size: $pager-icon-size;
    }

    .e-numericitem {
      @if $pager-skin == 'fluent2' {
        @include border-right-width-styles($pager-border-type, 0);
        margin-left: 1px;
        vertical-align: bottom;
      }
      @else {
        @include border-right-width-styles($pager-border-type, $pager-border-size);
      }
      display: inline-block;
      line-height: $pager-numeric-font-line-height;
      margin-right: $pager-numericitem-margin-right;
      min-width: $pager-numericitem-min-width;
      padding: $pager-numericitem-padding;
      text-align: center;
      @if $pager-skin == 'material3' {
        vertical-align: middle;
      }

      &.e-currentitem {
        @if $pager-skin == 'tailwind' {
          border: 1px solid $primary;
        }
      }

      &.e-currentitem,
      &.e-currentitem:hover {
        @if $pager-skin == 'tailwind' {
          padding: 3.5px 11px 6.2px 10px;
        }
        @else if $pager-skin == 'bootstrap5' {
          padding: 7.4px 10.5px 8.4px 9.5px;
        }
      }
    }

    div.e-pagermsgdiv {
      padding-right: 6px;
      padding-top: 4px;
      text-align: right;
    }

    .e-firstpagedisabled,
    .e-prevpagedisabled,
    .e-nextpagedisabled,
    .e-lastpagedisabled {
      opacity: $pager-disabled-icons-opacity;
    }

    .e-spacing,
    .e-numericitem:hover,
    .e-currentitem {
      border-radius: $pager-numericitem-border-radius;
      cursor: pointer;
      padding: $pager-numeric-icon-padding;
      text-decoration: none;
    }

    .e-currentitem {
      @if $pager-skin == 'fluent' {
        border-bottom: $pager-active-item-border-bottom;
      }
      @if $pager-skin == 'bootstrap5' {
        border: 3px solid $primary-light;
      }
      @if $pager-skin == 'material3' {
        border-radius: 4px;
      }
      font-style: normal;
      font-variant: normal;
      font-weight: $pager-current-item-font-weight;
    }

    div,
    a {
      display: inline;
      user-select: none;
    }

    .e-icons::before {
      display: inline-block;
    }

    .e-pagercontainer {
      .e-firstpage,
      .e-prevpage,
      .e-firstpagedisabled,
      .e-prevpagedisabled,
      .e-nextpage,
      .e-lastpage,
      .e-nextpagedisabled,
      .e-lastpagedisabled {
        @if $pager-skin == 'fluent2' {
          border-radius: 5px;
          @include border-right-width-styles($pager-border-type, 0);
          margin-left: 1px;
          &.e-focused {
            border-radius: 5px;
          }
        }
        @else {
          @include border-right-width-styles($pager-border-type, $pager-border-size);
        }
        display: inline-block;
        margin-right: $pager-navigationicon-right;
        margin-top: $pager-navigationicon-top;
        min-width: 26px;
        padding: $pager-pagecontainer-icon-padding;
        @if $pager-skin == 'tailwind' {
          position: relative;
          top: 3px;

          &.e-focused {
            border: 0;
            border-right-style: none;
            padding: 8.5px 7px 9.5px;
          }
        }
        @else if $pager-skin == 'material3' {
          vertical-align: middle;
          &.e-focused {
            border-radius: 4px;
          }
        }
        @else if $pager-skin == 'tailwind3' {
          vertical-align: sub;
        }
      }

      .e-nextpage.e-focused,
      .e-firstpage.e-focused  {
        @if $pager-skin == 'tailwind' {
          padding-right: 8px;
        }
      }

      .e-prevpage.e-focused {
        @if $pager-skin == 'tailwind' {
          padding-left: 7px;
          padding-right: 8px;
        }
      }

      .e-lastpage,
      .e-lastpagedisabled {
        border-right: $pager-last-border-size;
      }
    }

    .e-firstpage:hover,
    .e-prevpage:hover,
    .e-lastpage:hover,
    .e-nextpage:hover {
      cursor: pointer;
      text-decoration: none;
    }

    a.e-nextprevitemdisabled {
      display: none;
      @include margin-left-right($pager-border-size, $pager-border-size);
      padding-left: 5px;
      padding-right: 5px;
      text-decoration: none;
    }

    .e-next.e-icons.e-icon-next.e-nextpagedisabled.e-disable,
    .e-next.e-icons.e-icon-next.e-nextpage.e-pager-default {
      margin-left: $pager-next-icon-margin-left;
    }

    .e-pagercontainer {
      border-radius: $pager-container-border-radius;
      border-style: $pager-border-type;
      @if $pager-skin == 'fluent2' {
        border-width: 0;
      }
      @else {
        border-width: $pager-border-size;
      }
      display: inline-block;
      margin: $pager-container-margin;
      overflow: hidden;
    }

    .e-lastpage:hover {
      border-radius: $pager-last-page-hover-border-radius;
    }

    .e-firstpage:hover {
      border-radius: $pager-first-page-hover-border-radius;
    }

    .e-pagermessage,
    .e-pagerexternalmsg {
      display: block;
      margin: $pager-external-msg-padding;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .e-mfirst,
    .e-mprev,
    .e-mnext,
    .e-mlast {
      display: none;
    }

    .e-mprev {
      text-indent: -3px;
    }

    .e-mnext {
      text-indent: -2px;
    }

    /* stylelint-disable */
    .e-mfirst,
    .e-mprev,
    .e-mnext,
    .e-mlast {
      -webkit-tap-highlight-color: $pager-bg-color;
    }

    /* stylelint-enable */
    .e-pp,
    .e-np,
    .e-pp:hover,
    .e-np:hover {
      @include fontsize-padding-styles($pager-tripledot-font-size, $pager-tripledot-padding);
      font-weight: $pager-numeric-font-weight;
      letter-spacing: $pager-letter-spacing;
    }

    .e-np.e-focused,
    .e-pp.e-focused {
      line-height: $pager-focus-tripledot-line-height;
      padding: $pager-focus-tripledot-padding;
    }
    @if $pager-skin == 'material3' {
      &.e-rtl .e-np.e-focused,
      &.e-rtl .e-pp.e-focused {
        padding: $pager-focus-tripledot-padding;
      }
    }

    &.e-adaptive {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $pager-bigger-adaptive-pager-padding;

      div.e-parentmsgbar {
        margin: 0 5px;
        margin-top: $pager-adaptive-pagermsgbar-margin-top;
        flex-grow: 1;
        min-width: 10px;
        white-space: nowrap;
      }
      .e-pagesizes {
        display: inline-flex;
        width: auto;
      }
      .e-pagerdropdown {
        margin: $pager-adaptive-dropdown-margin;
        width: $pager-bigger-adaptive-dropdown-min-width;
        min-width: $pager-bigger-adaptive-dropdown-min-width;
        order: 2;
        height: 40px;
        padding-right: 5px;
      }
      .e-pagerconstant {
        font-size: 12px;
        position: relative;
        top: $pager-bigger-adaptive-constant-top;
        order: 1;
        min-width: 52px;
        @if $pager-skin == 'tailwind' {
          max-height: 42px;
        }
        @else if $pager-skin == 'fluent2' {
          max-height: 32px;
        }
        @else {
          max-height: 34px;
        }
        @if $pager-skin == 'fluent2' {
          line-height: 16px;
        }
        margin: 0 8px 0 10px;
      }
      .e-pagerconstant.e-page-all {
        top: $pager-adaptive-constant-pagesizeall-top;
        text-align: center;
      }

      .e-mfirst,
      .e-mprev,
      .e-mnext,
      .e-mlast {
        padding: 1% 2%;
        width: 5%;
      }

      .e-mfirst,
      .e-mlast {
        width: calc(5% + 11px);
      }
    }

    @media (max-width: 769px) {
      @if $pager-skin == 'fluent2' {
        padding: 11px 0;
      }
      @else {
        padding: 13px 0;
      }

      div.e-parentmsgbar {
        @include padding-bottom-right-top-styles(0, 0, 0);
        box-sizing: border-box;
        display: inline-block;
        float: initial;
        @include textalign-width-styles(center, calc(60% - 48px));
      }

      .e-pagesizes,
      .e-pagecountmsg,
      .e-pagercontainer {
        display: none;
      }

      .e-icons {
        font-size: $pager-mobile-icon-size;
      }

      .e-mfirst,
      .e-mprev,
      .e-mnext,
      .e-mlast {
        border: 0;
        box-sizing: border-box;
        display: inline-block;
        padding: 1% 5%;
      }

      .e-mfirst {
        margin-right: 4px;
        @include textalign-width-styles(right, calc(10% + 11px));
      }

      .e-mprev {
        margin: 0 4px;
        @include textalign-width-styles(right, 10%);
      }

      .e-mnext {
        margin: 0 4px;
        @include textalign-width-styles(left, 10%);
      }

      .e-mlast {
        margin-left: 4px;
        @include textalign-width-styles(left, calc(10% + 11px));
      }
    }

    &.e-rtl {
      .e-numericitem {
        &.e-currentitem,
        &.e-currentitem:hover {
          @if $pager-skin == 'tailwind' {
            padding: 3.5px 11px 4px 10px;
          }
          @else if $pager-skin == 'bootstrap5' {
            padding: 7px 10.5px 8px 9.5px;
          }
        }
        &.e-currentitem {
          @if $pager-skin == 'tailwind' {
            border-left-color: $primary;
            @include border-left-width-styles(solid, 1px);
          }
          @else if $pager-skin == 'bootstrap5' {
            border-left-color: $primary-light;
            @include border-left-width-styles(solid, 3px);
          }
        }
      }
      direction: rtl;
      .e-next.e-icons.e-icon-next.e-nextpagedisabled.e-disable,
      .e-next.e-icons.e-icon-next.e-nextpage.e-pager-default {
        @include margin-left-right(0, $pager-rtl-nxtpage-margin);
        @if $pager-skin == 'tailwind' or $pager-skin == 'bootstrap5' {
          @include border-left-width-styles(solid, 1px);
          @include border-right-width-styles(solid, 1px);
        }
      }

      & .e-pagercontainer,
      & div,
      & a {
        float: initial;
      }

      & .e-parentmsgbar {
        float: left;
        margin-left: $pager-rtl-parentmsgbar-margin-left;
        padding-top: $pager-rtl-parentmsgbar-padding-top;
      }

      & .e-pagerdropdown {
        margin: $pager-rtl-pagerdropdown-margin;
      }

      & .e-pagerconstant {
        margin: $pager-rtl-pagerconstant-margin-top;
      }

      & .e-pagerexternalmsg {
        float: none;
      }

      & .e-pagercontainer {
        float: none;
        margin: $pager-container-rtl-margin;
        padding: 0;
      }

      & .e-firstpage,
      & .e-prevpage,
      & .e-firstpagedisabled,
      & .e-prevpagedisabled,
      & .e-nextpage,
      & .e-lastpage,
      & .e-nextpagedisabled,
      & .e-lastpagedisabled {
        padding: $pager-rtl-navigation-icon-padding;
      }

      & .e-next.e-icons.e-icon-next.e-nextpage.e-focused {
        @if $pager-skin == 'tailwind' {
          border: 0;
          padding: 6.5px 8px 6px;
        }
      }

      & .e-prevpage.e-focused {
        @if $pager-skin == 'tailwind' {
          padding: 6.5px 8px 6px 7px;
        }
      }

      & .e-lastpage.e-focused,
      & .e-firstpage.e-focused {
        @if $pager-skin == 'tailwind' {
          padding: 6.5px 7px 6px;
        }
      }

      & .e-firstpage::before,
      & .e-firstpage:hover,
      & .e-firstpagedisabled::before {
        content: '\e701';
      }

      & .e-prevpage::before,
      & .e-prevpage:hover,
      & .e-prevpagedisabled::before {
        content: '\e848';
      }

      & .e-nextpage::before,
      & .e-nextpage:hover,
      & .e-nextpagedisabled::before {
        content: '\e84b';
      }

      & .e-lastpage::before,
      & .e-lastpage:hover,
      & .e-lastpagedisabled::before {
        content: '\e716';
      }

      & .e-nextpage,
      & .e-nextpagedisabled,
      & .e-prevpage,
      & .e-prevpagedisabled,
      & .e-firstpage,
      & .e-firstpagedisabled {
        @if $pager-skin == 'tailwind' or $pager-skin == 'bootstrap5' or $pager-skin == 'fluent2' {
          @include border-left-width-styles(none, 0);
        }
        @else {
          @include border-left-width-styles($pager-border-type, $pager-border-size);
        }
      }

      & .e-nextpage,
      & .e-nextpagedisabled,
      & .e-prevpage,
      & .e-prevpagedisabled {
        @if $pager-skin == 'tailwind' or $pager-skin == 'bootstrap5' {
          @include border-left-width-styles(none, 0);
        }
        @else {
          @include border-right-width-styles(none, 0);
        }
      }

      & .e-firstpage,
      & .e-firstpagedisabled {
        border-right: medium none;
      }

      & .e-firstpage:hover {
        border-radius: $pager-rtl-first-last-icon-hover-border-radius;
      }

      & .e-lastpage:hover {
        border-radius: $pager-rtl-first-last-icon-hover-border-radius;
      }

      & .e-numericcontainer {
        float: none;
      }

      & .e-numericitem {
        @if $pager-skin == 'tailwind' or $pager-skin == 'bootstrap5' {
          @if $pager-skin == 'bootstrap5' {
            border-right-style: solid;
          }
          @else {
            @include border-left-width-styles(none, 0);
            @include border-right-width-styles(solid, 1px);
          }
        }
        @else {
          @if $pager-skin == 'fluent2' {
            @include border-left-width-styles(none, 0);
          }
          @else {
            @include border-left-width-styles($pager-border-type, $pager-border-size);
            @include border-right-width-styles(none, 0);
          }
        }
        min-width: 26px;
      }

      & .e-next.e-icons.e-icon-next.e-nextpagedisabled.e-disable,
      & .e-next.e-icons.e-icon-next.e-nextpage.e-pager-default {
        @include margin-left-right(0, $pager-next-icon-margin-left);
      }

      & .e-spacing,
      & .e-numericitem:hover,
      & .e-currentitem {
        margin: $pager-rtl-numeric-margin;
        padding: $pager-activeitem-padding;
      }

      & .e-pp,
      & .e-np,
      & .e-pp:hover,
      & .e-np:hover {
        margin-top: $pager-tripledot-rtl-margin-top;
        padding: $pager-tripledot-rtl-padding;
      }

      & .e-np.e-focused,
      & .e-pp.e-focused {
        @if $pager-skin == 'tailwind' {
          padding: $pager-focus-tripledot-padding;
        }
      }

      &.e-adaptive.e-rtl {
        div.e-parentmsgbar {
          margin: 0 5px;
          margin-top: $pager-rtl-adaptive-pagermsgbar-margin-top;
          padding-top: 0;
        }
        div.e-pagerconstant {
          margin: $pager-rtl-adaptive-constant-top;
        }
        div.e-pagerconstant.e-page-all {
          top: $pager-rtl-adaptive-constant-pagesizeall-top;
        }
        div.e-pagerdropdown {
          min-width: 92px;
          margin: $pager-rtl-adaptive-dropdown-margin;
          padding: 0 0 0 5px;
        }
        .e-mfirst,
        .e-mprev,
        .e-mnext,
        .e-mlast {
          padding: 1% 2%;
          width: 5%;
        }

        .e-mfirst,
        .e-mlast {
          width: calc(5% + 11px);
        }
      }

      @media (max-width: 769px) {
        .e-mfirst,
        .e-mprev,
        .e-mnext,
        .e-mlast {
          border: 0;
        }

        &.e-rtl div.e-parentmsgbar {
          float: initial;
          margin-left: 0;
          margin-top: $pager-rtl-pagermsgbar-device-margin-top;
        }

        .e-parentmsgbar {
          float: right;
        }
      }
    }

    &.sf-pager {
      .e-pagercontainer {
        font-size: 0;
        .e-first,
        .e-prev,
        .e-next,
        .e-last {
          font-size: $pager-icon-size;
        }
        .e-firstpage,
        .e-prevpage,
        .e-firstpagedisabled,
        .e-prevpagedisabled,
        .e-nextpage,
        .e-lastpage,
        .e-nextpagedisabled,
        .e-lastpagedisabled {
          @if $pager-skin == 'tailwind3' {
            padding: 7.1px 8.6px 8.5px;
          }
        }
      }
      .e-spacing {
        @if $pager-skin == 'tailwind3' {
          padding: 7.6px 11.85px 7.8px 11.8px;
        }
      }

      .e-numericcontainer a {
        font-size: $pager-font-size;
      }

      .e-link,
      .e-next.e-icons.e-icon-next.e-nextpage.e-pager-default {
        margin-left: 0;
      }
    }
  }
}


@mixin color-opacity-styles($color, $opacity) {
  color: $color;
  opacity: $opacity;
}

@mixin background-color-opacity-styles($background, $color, $opacity) {
  background: $background;
  @include color-opacity-styles($color, $opacity);
}

@include export-module('pager-theme') {

  /*! Pager theme */
  #{&}.e-pager {
    @if $pager-skin == 'material3' {
      border-color: rgba($border-light);
    }
    @else if $pager-skin == 'fluent2' {
      border-color: $border-light;
    }
    @else {
      border-color: $pager-border-color;
    }

    @include background-color-opacity-styles($pager-bg-color, $pager-color, $pager-content-opacity);

    div.e-icons.e-pager-default {
      color: $pager-icon-color;
    }

    div.e-icons.e-pager-default.e-focused {
      @if $pager-skin == 'fluent2' {
        color: $pager-hover-color;
      }
    }

    div.e-icons.e-pager-default:not(.e-numericitem):hover {
      @if $pager-skin == 'fluent2' {
        color: $pager-hover-color;
        background-color: $pager-hover-bg-color;
      }
    }

    div.e-icons.e-disable {
      color: $pager-numeric-txt-bg-color;
    }

    .e-pager-default:hover {
      @include background-color-opacity-styles($pager-hover-bg-color, $pager-hover-color, $pager-content-opacity);
      @if $pager-skin == 'material3' {
        border-radius: 4px;
      }
    }

    .e-parentmsgbar {
      font-weight: $pager-pagermessage-weight;
    }

    .e-spacing {
      @include color-opacity-styles($page-content-color, $pager-content-opacity);
    }

    .e-spacing.e-numericitem:not(.e-active):not(.e-np):not(.e-pp):hover {
      background: $pager-numeric-hover-bg-color;
    }

    .e-numericitem:hover {
      @include color-opacity-styles($pager-hover-color, $pager-content-opacity);
    }

    .e-numericitem:not(.e-active):not(.e-np):not(.e-pp):hover {
      background-color: $pager-number-hover-bg-color;
      opacity: 1;
    }

    .e-numericitem {
      background: $pager-bg-color;
      color: $page-content-color;
    }

    .e-prevpagedisabled,
    .e-prevpage,
    .e-nextpage,
    .e-nextpagedisabled,
    .e-lastpagedisabled,
    .e-lastpage,
    .e-firstpage,
    .e-firstpagedisabled {
      background-color: $pager-bg-color;
      color: $pager-icon-color;
    }

    .e-prevpagedisabled,
    .e-nextpagedisabled,
    .e-lastpagedisabled,
    .e-firstpagedisabled {
      @if $pager-skin == 'fluent2' {
        background-color: $content-bg-color-disabled;
      }
    }

    @media (max-width: 769px) {
      .e-prevpagedisabled,
      .e-nextpagedisabled,
      .e-lastpagedisabled,
      .e-firstpagedisabled {
        @if $pager-skin == 'fluent2' {
          background-color: $pager-bg-color;
        }
      }
    }

    .e-lastpage,
    .e-firstpage {
      background-color: transparent;
    }

    .e-pagercontainer {
      background-color: $pager-bg-color;
      border-color: $pager-border-color;
    }

    .e-numericitem,
    .e-firstpage,
    .e-prevpage,
    .e-firstpagedisabled,
    .e-prevpagedisabled,
    .e-nextpage,
    .e-lastpage,
    .e-nextpagedisabled,
    .e-lastpagedisabled {
      border-right-color: $pager-border-color;
    }

    .e-currentitem,
    .e-currentitem:hover {
      @if $pager-skin == 'bootstrap5' {
        border-right-color: $primary-light;
      }
      @if $pager-skin == 'material3' {
        border-radius: 4px;
      }
      @include background-color-opacity-styles($pager-active-bg-color, $pager-active-color, 1);
    }

    .e-numericitem.e-focused,
    .e-first.e-focused,
    .e-prev.e-focused,
    .e-next.e-focused,
    .e-last.e-focused,
    .e-mfirst.e-focused,
    .e-mprev.e-focused,
    .e-mnext.e-focused,
    .e-mlast.e-focused {
      background-color: $pager-focus-bg-color;
    }

    .e-currentitem.e-numericitem.e-focused {
      background: $pager-active-bg-color;
      box-shadow: $pager-focus-active-box-shadow;
    }

    .e-focused {
      box-shadow: $pager-focus-shadow;
    }

    @media (max-width: 590px) {
      .e-pager-default:hover {
        @include background-color-opacity-styles($pager-bg-color, $pager-mhover-color, $pager-content-opacity);
      }
    }

    &.e-rtl {
      & .e-numericitem,
      & .e-firstpage,
      & .e-prevpage,
      & .e-firstpagedisabled,
      & .e-prevpagedisabled,
      & .e-nextpage,
      & .e-lastpage,
      & .e-nextpagedisabled,
      & .e-lastpagedisabled,
      & .e-nextpage,
      & .e-nextpagedisabled {
        border-left-color: $pager-border-color;
      }
    }
  }
}


@include export-module('pager-material-icons') {

  /*! component icons */

  #{&}.e-pager,
  .e-grid-menu {

    .e-icon-last::before {
      content: '\e897';
    }

    .e-icon-first::before {
      content: '\e896';
    }

    .e-icon-prev::before {
      content: '\e904';
    }

    .e-icon-next::before {
      content: '\e913';
    }
    &.e-rtl {
      & .e-icon-last.e-lastpage::before,
      & .e-icon-last.e-lastpage:hover,
      & .e-icon-last.e-lastpagedisabled::before {
        content: '\e896';
      }
      & .e-icon-first.e-firstpage::before,
      & .e-icon-first.e-firstpage:hover,
      & .e-icon-first.e-firstpagedisabled::before {
        content: '\e897';
      }
      & .e-icon-prev.e-prevpage::before,
      & .e-icon-prev.e-prevpage:hover,
      & .e-icon-prev.e-prevpagedisabled::before {
        content: '\e913';
      }
      & .e-icon-next.e-nextpage::before,
      & .e-icon-next.e-nextpage:hover,
      & .e-icon-next.e-nextpagedisabled::before {
        content: '\e904';
      }
    }
  }
}

@include export-module('pager-bigger') {

  /*! Pager bigger */
  .e-bigger  #{&}.e-pager,
  #{&}.e-pager.e-bigger {
    font-size: $pager-bigger-font-size;
    padding: $pager-bigger-padding;
    div.e-icons:not(.e-check) {
      font-size: $pager-bigger-icon-font-size;
    }

    div.e-parentmsgbar {
      padding-bottom: $pager-bigger-messagebar-padding-bottom;
      padding-right: $pager-bigger-messagebar-padding-right;
      padding-top: $pager-bigger-messagebar-padding-top;
      @if $pager-skin == 'tailwind' or $pager-skin == 'fluent2' {
        line-height: 22px;
      }
    }

    &:not(.e-adaptive) {
      .e-pagerdropdown {
        height: $pager-bigger-dropdown-heigh;
        margin: $pager-bigger-dropdown-margin;
        margin-top: $pager-bigger-dropdown-margin-top;
        width: $pager-bigger-dropdown-width;
      }
    }

    .e-pagercontainer {
      margin: $pager-bigger-pagercontainer-margin;
      .e-firstpage,
      .e-prevpage,
      .e-firstpagedisabled,
      .e-prevpagedisabled,
      .e-nextpage,
      .e-lastpage,
      .e-nextpagedisabled,
      .e-lastpagedisabled {
        padding: $pager-bigger-pagecontainer-icon-padding;
      }
    }

    .e-pagerconstant {
      margin-bottom: $pager-bigger-dropdown-constant-margin;
      margin-left: $pager-bigger-constant-margin-left;
      @if $pager-skin == 'tailwind' {
        line-height: 22px;
      }
    }

    @media (max-width: 769px) {
      padding: $pager-device-padding;
      &.e-adaptive .e-icons {
        @if $pager-skin == 'fluent2' {
          font-size: 20px;
        }
      }
      &.e-rtl div.e-parentmsgbar {
        margin-right: 0;
      }

      div.e-parentmsgbar {
        padding: $pager-device-messabar-padding;
        @if $pager-skin == 'material3' {
          vertical-align: top;
          margin-top: 5px;
        }
      }
    }

    .e-next.e-icons.e-icon-next.e-nextpagedisabled.e-disable,
    .e-next.e-icons.e-icon-next.e-nextpage.e-pager-default {
      margin-left: $pager-bigger-nexticon-marign-left;
    }

    .e-numericitem {
      margin: $pager-bigger-numeric-item-margin;
      @if $pager-skin == 'tailwind' or $pager-skin == 'fluent2' {
        line-height: 22px;
      }
      &.e-currentitem,
      &.e-currentitem:hover {
        @if $pager-skin == 'tailwind' {
          padding: 6px 14px 5.5px 13px;
        }
        @else if $pager-skin == 'bootstrap5' {
          padding: 9px 13px 10px 12px;
        }
      }
    }

    .e-pp.e-spacing,
    .e-np.e-spacing,
    .e-pp.e-spacing:hover,
    .e-np.e-spacing:hover {
      padding: $pager-bigger-letter-spacing;
    }

    .e-np.e-spacing.e-focused,
    .e-pp.e-spacing.e-focused {
      @if $pager-skin == 'tailwind' {
        padding: 7px 11.8px 6.5px;
      }
    }

    .e-spacing,
    .e-numericitem:hover,
    .e-currentitem {
      border-radius: $pager-bigger-current-item-border-radius;
      padding: $pager-bigger-current-item-padding;
    }

    &.e-rtl {
      .e-spacing,
      .e-numericitem:hover,
      .e-currentitem {
        margin: $pager-bigger-numeric-item-margin;
        @if $pager-skin == 'material3' {
          padding: $pager-bigger-current-item-padding;
        }
        @if $pager-skin == 'tailwind3' {
          padding: 10.7px 15.48px 11.5px 15.1px;
        }
        @else {
          padding: $pager-bigger-letter-spacing;
        }
      }

      .e-pp.e-spacing,
      .e-np.e-spacing,
      .e-pp.e-spacing:hover,
      .e-np.e-spacing:hover {
        @if $pager-skin == 'tailwind3' {
          padding-top: 12.5px;
        }
      }

      & .e-pagerdropdown {
        margin: $pager-rtl-pagerdropdown-bigger-margin;
      }

      & .e-pagerconstant {
        margin: $pager-rtl-pagerconstant-bigger-margin-top;
      }

      .e-pagercontainer {
        margin: $pager-bigger-new-container-rtl-margin;

        .e-prevpage.e-focused {
          @if $pager-skin == 'tailwind' {
            padding: 10px 11px 9px 10px;
          }
        }

        .e-firstpage.e-focused,
        .e-lastpage.e-focused {
          @if $pager-skin == 'tailwind' {
            padding: 10px 10px 9.5px;
          }
        }

        .e-next.e-icons.e-icon-next.e-nextpage.e-pager-default.e-focused {
          @if $pager-skin == 'tailwind' {
            padding: 10px 11px 9.5px;
          }
        }
      }

      .e-next.e-icons.e-icon-next.e-nextpagedisabled.e-disable,
      .e-next.e-icons.e-icon-next.e-nextpage.e-pager-default {
        margin-left: 0;
        margin-right: $pager-bigger-rtl-nexticon-marign-right;
      }

      div.e-parentmsgbar {
        margin-left: $pager-bigger-rtl-parentmsgbar-margin-left;
        margin-top: $pager-bigger-rtl-parentmsgbar-margin-top;
      }

      @media (max-width: 769px) {
        div.e-parentmsgbar {
          margin-left: 0;
          margin-top: $pager-rtl-pagermsgbar-device-margin-top;
        }
      }

      @media (max-width: 769px) {
        div.e-parentmsgbar {
          margin-left: 0;
          margin-top: $pager-rtl-pagermsgbar-device-margin-top;
        }
      }

      .e-numericitem.e-currentitem,
      .e-numericitem.e-currentitem:hover {
        @if $pager-skin == 'tailwind' {
          padding: 6px 14px 6px 13px;
        }
        @else if $pager-skin == 'bootstrap5' {
          padding: 9px 13px 10px 12px;
        }
        @else if $pager-skin == 'material3' {
          padding: 10px 15px;
        }
      }

      .e-np.e-spacing.e-focused,
      .e-pp.e-spacing.e-focused {
        @if $pager-skin == 'tailwind' {
          padding: 13px 13px 11px 12px;
        }
      }

      & .e-pp.e-spacing.e-numericitem,
      & .e-np.e-spacing.e-numericitem,
      & .e-pp.e-spacing.e-numericitem:hover,
      & .e-np.e-spacing.e-numericitem:hover {
        font-size: $pager-rtl-bigger-tripledot-font-size;
        margin-top: $pager-tripledot-rtl-bigger-margin-top;
        @if $pager-skin == 'tailwind' {
          padding: 7px 12.6px;
        }
        @else if $pager-skin == 'bootstrap5' {
          padding: 14px 14px 13px;
        }
        @else if $pager-skin == 'material3' {
          &.e-focused {
            padding: 10px 12px;
          }
          padding: 10px 12px;
        }
      }

      &.e-adaptive {
        div.e-parentmsgbar {
          margin-top: $pager-rtl-e-bigger-adaptive-pagermsgbar-margin-top;
        }
        div.e-pagerdropdown {
          margin: $pager-rtl-e-bigger-adaptive-dropdown-margin;
        }
        div.e-pagerconstant.e-page-all {
          top: $pager-rtl-e-bigger-adaptive-constant-pagesizeall-top;
        }
      }
    }

    .e-pagercontainer {
      .e-firstpage,
      .e-prevpage,
      .e-firstpagedisabled,
      .e-prevpagedisabled,
      .e-nextpage,
      .e-lastpage,
      .e-nextpagedisabled,
      .e-lastpagedisabled {
        margin-right: $pager-bigger-pagercontainer-icons-marign-right;
        @if $pager-skin == 'fluent2' {
          margin-left: 2px;
        }
        @if $pager-skin == 'tailwind' {
          margin-top: -4px;
        }
        padding: $pager-bigger-pagercontainer-icons-padding;
      }

      .e-next.e-icons.e-icon-next.e-nextpage.e-pager-default.e-focused,
      .e-prevpage.e-focused,
      .e-firstpage.e-focused {
        @if $pager-skin == 'tailwind' {
          padding: 10px 11px 9.5px 10px;
        }
      }

      .e-lastpage.e-focused {
        @if $pager-skin == 'tailwind' {
          padding: 10px 10px 9.5px;
        }
      }
    }

    &.e-rtl .e-pagercontainer {
      .e-firstpage,
      .e-prevpage,
      .e-firstpagedisabled,
      .e-prevpagedisabled,
      .e-nextpage,
      .e-lastpage,
      .e-nextpagedisabled,
      .e-lastpagedisabled {
        @if $pager-skin == 'tailwind3' {
          padding: 11.7px 11.6px 8.7px;
        }
      }
    }
    .e-pp,
    .e-np,
    .e-pp:hover,
    .e-np:hover {
      font-size: $pager-bigger-tripledot-font-size;
    }
    &.e-adaptive {
      div.e-parentmsgbar {
        margin-top: $pager-bigger-adaptive-pagermsgbar-margin-top;
      }
      .e-pagerdropdown {
        margin: $pager-bigger-adaptive-dropdown-margin;
        height: 40px;
      }
      .e-pagerconstant.e-page-all {
        top: $pager-bigger-adaptive-constant-pagesizeall-top;
      }
      .e-pagerconstant {
        margin: 0 8px 0 10px;
      }
    }
  }

  .e-bigger .e-responsive  #{&}.e-pager,
  .e-responsive #{&}.e-pager.e-bigger {
    div.e-icons {
      @if $pager-skin == 'material3' {
        border-radius: 4px;
      }
    }
    &.e-adaptive {
      .e-firstpagedisabled,
      .e-prevpagedisabled,
      .e-nextpagedisabled,
      .e-lastpagedisabled {
        @if $pager-skin == 'fluent2' {
          background-color: transparent;
        }
      }
    }
  }

  .e-bigger  #{&}.sf-pager,
  #{&}.sf-pager.e-bigger {
    .e-pagercontainer {
      .e-firstpage,
      .e-prevpage,
      .e-firstpagedisabled,
      .e-prevpagedisabled,
      .e-nextpage,
      .e-lastpage,
      .e-nextpagedisabled,
      .e-lastpagedisabled {
        @if $pager-skin =='tailwind3' {
          padding: 10.3px 11.6px 12.5px;
        }
      }
    }

    .e-spacing,
    .e-currentitem,
    .e-numericitem:hover {
      @if $pager-skin =='tailwind3' {
        padding: 14.5px 15.48px 11.8px 15.1px;
      }
    }
  }
}

@import "../base/base.gd.scss";
@import "../inputs/input.gd.scss";
@import "../popups/popup.gd.scss";
@import "../buttons/button.gd.scss";
@import "../calendars/calendar.gd.scss";
@import "../buttons/check-box.gd.scss";
@import "../calendars/datepicker.gd.scss";
@import "../lists/list-view.gd.scss";
@import "../lists/sortable.gd.scss";

$datetime-default-text-indent: 16px !default;
$datetime-list-bigger-line-height: 48px !default;
$datetime-list-normal-line-height: 36px !default;
$datetime-list-normal-font-size: 13px !default;
$datetime-list-bigger-font-size: 14px !default;
$datetime-list-normal-text-indent: $datetime-default-text-indent !default;
$datetime-list-bigger-text-indent: 16px !default;
$datetime-popup-border-radius: 2px !default;
$datetime-list-font-weight: normal !default;
$datetime-popup-shadow: 0 5px 5px -3px rgba($grey-light-font, .2), 0 8px 10px 1px rgba($grey-light-font, .14), 0 3px 14px 2px rgba($grey-light-font, .12) !default;
$datetime-list-default-font-color: rgba($grey-light-font, .87) !default;
$datetime-list-border-color: none !default;
$datetime-list-bg-color: $grey-white !default;
$datetime-list-active-bg-color: $grey-200 !default;
$datetime-list-active-font-color: $accent !default;
$datetime-list-active-icon-color: $accent !default;
$datetime-list-hover-bg-color: $grey-200 !default;
$datetime-list-hover-font-color: $grey-light-font !default;
$datetime-list-popup-icon-active-color: $accent !default;
$datetime-list-active-hover-bg-color: $grey-200 !default;
$datetime-list-active-hover-font-color: $accent !default;
$datetime-list-normal-padding: 8px !default;
$datetime-list-bigger-padding: 8px !default;
$datetime-time-font-icon: '\e20c' !default;
$datetime-icon-normal-font-size: 16px !default;
$datetime-icon-bigger-font-size: 18px !default;
$datetime-date-icon-margin: 0 0 4px !default;
$datetime-time-icon-margin: 0 0 4px 6px !default;
$datetime-time-bigger-icon-margin: 5px 0 6px 8px !default;
$datetime-time-rtl-icon-margin: 0 6px 4px 0 !default;
$datetime-time-rtl-bigger-icon-margin: 5px 8px 6px 0 !default;
$datetime-normal-min-height: 24px !default;
$datetime-normal-min-width: 24px !default;
$datetime-bigger-min-height: 26px !default;
$datetime-bigger-min-width: 26px !default;
$datetime-time-icon-border: 0 !default;
$datetime-time-icon-border-style: none !default;
$datetime-list-hover-border-color: none !default;
$datetime-list-default-border-style: none !default;
$datetime-default-overlay: rgba($grey-black, .6) !default;

//mouse small
$datetime-list-small-line-height: 26px !default;
$datetime-list-small-text-indent: 12px !default;
$datetime-list-small-font-size: 12px !default;

// mouse small icon
$datetime-icon-small-font-size: 14px !default;
$datetime-small-min-width: 16px !default;

//touch small
$datetime-list-bigger-small-line-height: 40px !default;
$datetime-list-bigger-small-text-indent: 16px !default;
$datetime-list-bigger-small-font-size: 13px !default;

// touch small icon
$datetime-icon-bigger-small-font-size: 18px !default;

// modal full-screen popup
$modal-datetime-position: fixed !default;
$modal-datetime-wrapper-position: relative !default;
$modal-datetime-wrapper-width: 100% !default;

@include export-module('datetimepicker-layout') {
  // datetimepicker layout
  .e-input-group.e-control-wrapper.e-datetime-wrapper.e-non-edit.e-input-focus .e-input:focus ~ .e-clear-icon,
  .e-float-input.e-control-wrapper.e-input-group.e-datetime-wrapper.e-non-edit.e-input-focus input:focus ~ .e-clear-icon {
    display: flex;
  }

  .e-datetime-wrapper {
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-tap-highlight-color: transparent;

    #{if(&, '&', '*')} .e-time-icon.e-icons::before {
      font-size: $datetime-icon-normal-font-size;
    }

    #{if(&, '&', '*')}.e-control-wrapper {
      box-sizing: border-box;
    }

    #{if(&, '&', '*')} .e-time-icon.e-icons.e-disabled,
    #{if(&, '&', '*')} .e-date-icon.e-icons.e-disabled {
      pointer-events: none;
    }

    #{if(&, '&', '*')} .e-clear-icon {
      box-sizing: content-box;
      @if $skin-name == 'fluent2' or $skin-name == 'fluent2-dark' {
        box-sizing: border-box;
      }
    }

    #{if(&, '&', '*')} span {
      cursor: pointer;
    }

    #{if(&, '&', '*')} .e-input-group-icon.e-date-icon,
    #{if(&, '&', '*')} .e-input-group-icon.e-time-icon {
      font-size: $datetime-icon-normal-font-size;
      margin: $datetime-date-icon-margin;
      @if $skin-name == 'material' or $skin-name == 'material-dark' or $skin-name == 'Material3' {
        min-height: $datetime-normal-min-height;
        min-width: $datetime-normal-min-width;
        @if $skin-name == 'Material3' {
          border-radius: $datetime-normal-border-radius;
        }
      }
      outline: none;
    }

    #{if(&, '&', '*')} .e-input-group-icon.e-time-icon {
      border: $datetime-time-icon-border;
      border-style: $datetime-time-icon-border-style;
      margin: $datetime-time-icon-margin;
    }
  }

  .e-datetime-wrapper:not(.e-outline) {
    #{if(&, '&', '*')}.e-rtl .e-input-group-icon.e-time-icon {
      margin: $datetime-time-rtl-icon-margin;
    }
  }

  .e-control.e-datetimepicker.e-popup-wrapper.e-popup-container.e-popup-expand.e-popup.e-popup-open .e-datetime-mob-popup-wrap {
    align-items: normal;
  }

  .e-datetime-mob-popup-wrap {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    max-height: 100%;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1002;

    .e-datetimepicker.e-popup.e-lib.e-control.e-popup-open {
      left: 0 !important; /* stylelint-disable-line declaration-no-important */
      position: relative;
      top: 0 !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  #{&}.e-datetimepicker.e-time-modal {
    @if $skin-name != 'Material3' {
      background-color: $datetime-default-overlay;
    }
    @if $skin-name == 'Material3' {
      background: $datetime-default-overlay;
    }
    height: 100%;
    left: 0;
    opacity: .5;
    pointer-events: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
  }

  #{&}.e-datetimepicker.e-popup {
    border-style: solid;
    border-width: 1px;
    overflow: auto;

    #{if(&, '&', '*')} .e-content {
      position: relative;
    }

    #{if(&, '&', '*')} .e-list-parent.e-ul {
      margin: 0;
      @if $skin-name != 'Material3' {
        padding: $datetime-list-normal-padding 0;
      }
      @else {
        padding: 0;
      }

      #{if(&, '&', '*')} .e-list-item {
        color: $datetime-list-default-font-color;
        cursor: default;
        font-size: $datetime-list-normal-font-size;
        overflow: hidden;
        position: relative;
        text-overflow: ellipsis;
        vertical-align: middle;
        white-space: nowrap;
        width: 100%;
      }

      #{if(&, '&', '*')} .e-list-item.e-hover {
        cursor: pointer;
      }
    }
  }

  #{&}.e-datetimepicker.e-popup {
    #{if(&, '&', '*')} .e-list-parent.e-ul .e-list-item {
      line-height: $datetime-list-normal-line-height;
      text-indent: $datetime-list-normal-text-indent;
    }
  }

  .e-small #{&}.e-datetimepicker.e-popup,
  #{if(&, '&', '*')}.e-small#{&}.e-datetimepicker.e-popup {
    #{if(&, '&', '*')} .e-list-parent.e-ul .e-list-item {
      font-size: $datetime-list-small-font-size;
      line-height: $datetime-list-small-line-height;
      text-indent: $datetime-list-small-text-indent;
    }
  }

  .e-small.e-datetime-wrapper,
  #{if(&, '&', '*')}.e-small .e-datetime-wrapper {

    #{if(&, '&', '*')} .e-time-icon.e-icons::before {
      font-size: $datetime-icon-small-font-size;
    }

    #{if(&, '&', '*')} .e-input-group-icon.e-time-icon {
      @if $skin-name == 'Material3' {
        min-height: $datetime-small-min-height;
        min-width: $datetime-small-min-width;
        border-radius: $datetime-small-icon-border-radius;
        margin: $datetime-time-small-icon-margin;
      }
    }
  }

  .e-content-placeholder.e-datetimepicker.e-placeholder-datetimepicker {
    background-size: 250px 33px;
    min-height: 33px;
  }

  .e-datetimepicker.e-popup-expand.e-popup {
    position: $modal-datetime-position;

    #{if(&, '&', '*')} .e-datetime-mob-popup-wrap {
      position: $modal-datetime-wrapper-position;
      width: $modal-datetime-wrapper-width;
    }
  }
}


@include export-module('datetimepicker-theme') {

  /*! datetimepicker theme */
  .e-datetime-wrapper {
    #{if(&, '&', '*')} .e-input-group-icon.e-icons.e-active {
      @if $skin-name == 'bootstrap4' {
        background: $datetime-icon-active-bg-color;
        border-color: $datetime-active-border-color;
      }
      color: $datetime-list-active-icon-color;
    }

    #{if(&, '&', '*')}.e-input-group:not(.e-disabled) .e-input-group-icon.e-active:active {
      color: $datetime-list-popup-icon-active-color;
    }
  }

  .e-datetimepicker.e-popup {
    border: $datetime-list-border-color;
    border-radius: $datetime-popup-border-radius;
    box-shadow: $datetime-popup-shadow;

    #{if(&, '&', '*')} .e-list-parent.e-ul {
      @if $skin-name != 'Material3' {
        background-color: $datetime-list-bg-color;
      }
      @if $skin-name == 'Material3' {
        background: $datetime-list-bg-color;
      }

      #{if(&, '&', '*')} li.e-list-item {
        border: $datetime-list-default-border-style;
        color: $datetime-list-default-font-color;
      }

      #{if(&, '&', '*')} .e-list-item.e-hover {
        @if $skin-name == 'tailwind3' {
          background: $datetime-list-hover-bg-color;
          color: $datetime-list-hover-font-color;
        }
      }

      #{if(&, '&', '*')} .e-list-item.e-navigation {
        @if $skin-name == 'tailwind3' {
          background: $datetime-list-navigation-bg-color;
          color: $datetime-list-navigation-font-color;
          box-shadow: $datetime-list-navigation-box-shadow;
        }
      }

      #{if(&, '&', '*')} .e-list-item:focus {
        @if $skin-name == 'tailwind3' {
          background: $datetime-list-focus-bg-color;
          color: $datetime-list-focus-font-color;
        }
      }

      #{if(&, '&', '*')} .e-list-item.e-hover,
      #{if(&, '&', '*')} .e-list-item.e-navigation,
      #{if(&, '&', '*')} .e-list-item:focus {
        @if $skin-name != 'Material3' and $skin-name != 'fluent2' {
          background-color: $datetime-list-hover-bg-color;
        }
        @if $skin-name == 'Material3' or $skin-name == 'fluent2' {
          background: $datetime-list-hover-bg-color;
        }
        border: $datetime-list-hover-border-color;
        color: $datetime-list-hover-font-color;
      }

      #{if(&, '&', '*')} .e-list-item.e-active {
        @if $skin-name != 'Material3' and $skin-name != 'tailwind3' {
          background-color: $datetime-list-active-bg-color;
        }
        @if $skin-name == 'Material3' or $skin-name == 'tailwind3' {
          background: $datetime-list-active-bg-color;
        }
        color: $datetime-list-active-font-color;
        @if $skin-name == 'tailwind3' {
          font-weight: 500;
        }
      }

      #{if(&, '&', '*')} .e-list-item.e-active.e-hover {
        @if $skin-name != 'Material3' {
          background-color: $datetime-list-active-hover-bg-color;
        }
        @if $skin-name == 'Material3' {
          background: $datetime-list-active-hover-bg-color;
        }
        color: $datetime-list-active-hover-font-color;
      }
    }
  }
}


@include export-module('datetimepicker-material-icons') {

  /*! component icons */
  .e-datetime-wrapper {

    #{if(&, '&', '*')} .e-time-icon.e-icons::before {
      content: '\e20c';
      font-family: 'e-icons';
    }
  }

  // Outline textbox icon configuration

  .e-datetime-wrapper.e-outline .e-input-group-icon.e-date-icon,
  .e-datetime-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-date-icon,
  .e-datetime-wrapper.e-outline .e-input-group-icon.e-time-icon,
  .e-datetime-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-time-icon {
    min-height: $zero-value;
    min-width: 20px;
  }

  .e-small .e-datetime-wrapper.e-outline .e-input-group-icon.e-date-icon,
  .e-datetime-wrapper.e-control-wrapper.e-small.e-outline .e-input-group-icon.e-date-icon,
  .e-small .e-datetime-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-date-icon,
  .e-small .e-datetime-wrapper.e-outline .e-input-group-icon.e-time-icon,
  .e-datetime-wrapper.e-control-wrapper.e-small.e-outline .e-input-group-icon.e-time-icon,
  .e-small .e-datetime-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-time-icon {
    min-height: $zero-value;
    min-width: 18px;
  }

  .e-datetime-wrapper.e-filled .e-input-group-icon.e-time-icon,
  .e-datetime-wrapper.e-control-wrapper.e-filled .e-input-group-icon.e-time-icon,
  .e-rtl.e-datetime-wrapper.e-filled .e-input-group-icon.e-time-icon,
  .e-rtl.e-datetime-wrapper.e-control-wrapper.e-filled .e-input-group-icon.e-time-icon,
  .e-rtl .e-datetime-wrapper.e-filled .e-input-group-icon.e-time-icon,
  .e-rtl .e-datetime-wrapper.e-control-wrapper.e-filled .e-input-group-icon.e-time-icon {
    margin: $zero-value;
  }
}

@include export-module('datetimepicker-bigger') {

  .e-bigger.e-datetime-wrapper,
  #{if(&, '&', '*')}.e-bigger .e-datetime-wrapper {

    #{if(&, '&', '*')} .e-time-icon.e-icons::before {
      font-size: $datetime-icon-bigger-font-size;
    }

    #{if(&, '&', '*')} .e-input-group-icon.e-time-icon {
      margin: $datetime-time-bigger-icon-margin;
      @if $skin-name == 'material' or $skin-name == 'material-dark' or $skin-name == 'Material3' {
        min-height: $datetime-bigger-min-height;
        min-width: $datetime-bigger-min-width;
        @if $skin-name == 'Material3' {
          border-radius: $datetime-bigger-icon-border-radius;
        }
      }
    }
  }

  .e-bigger.e-datetime-wrapper:not(.e-outline),
  #{if(&, '&', '*')}.e-bigger .e-datetime-wrapper:not(.e-outline) {
    #{if(&, '&', '*')}.e-rtl .e-input-group-icon.e-time-icon {
      margin: $datetime-time-rtl-bigger-icon-margin;
    }
  }

  .e-bigger #{&}.e-datetimepicker.e-popup,
  #{if(&, '&', '*')}.e-bigger#{&}.e-datetimepicker.e-popup {
    #{if(&, '&', '*')} .e-list-parent.e-ul {
      @if $skin-name != 'Material3' {
        padding: $datetime-list-bigger-padding 0;
      }
      @else {
        padding: 0;
      }

      #{if(&, '&', '*')} .e-list-item {
        font-size: $datetime-list-bigger-font-size;
        line-height: $datetime-list-bigger-line-height;
        text-indent: $datetime-list-bigger-text-indent;
      }
    }
  }


  .e-bigger .e-content-placeholder.e-datetimepicker.e-placeholder-datetimepicker,
  .e-bigger.e-content-placeholder.e-datetimepicker.e-placeholder-datetimepicker {
    background-size: 250px 40px;
    min-height: 40px;
  }

  .e-small.e-bigger.e-datetime-wrapper,
  #{if(&, '&', '*')}.e-small.e-bigger .e-datetime-wrapper {

    #{if(&, '&', '*')} .e-time-icon.e-icons::before {
      font-size: $datetime-icon-bigger-small-font-size;
    }

    #{if(&, '&', '*')} .e-input-group-icon.e-time-icon {
      @if $skin-name == 'Material3' {
        min-height: $datetime-bigger-small-min-height;
        min-width: $datetime-bigger-small-min-width;
        border-radius: $datetime-bigger-small-icon-border-radius;
        margin: $datetime-time-bigger-small-icon-margin;
      }
    }
  }

  .e-small.e-bigger #{&}.e-datetimepicker.e-popup,
  #{if(&, '&', '*')}.e-small.e-bigger#{&}.e-datetimepicker.e-popup {
    #{if(&, '&', '*')} .e-list-parent.e-ul .e-list-item {
      font-size: $datetime-list-bigger-small-font-size;
      line-height: $datetime-list-bigger-small-line-height;
      text-indent: $datetime-list-bigger-small-text-indent;
    }
  }

  .e-bigger .e-datetime-wrapper.e-outline .e-input-group-icon.e-date-icon,
  .e-datetime-wrapper.e-control-wrapper.e-bigger.e-outline .e-input-group-icon.e-date-icon,
  .e-bigger .e-datetime-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-date-icon,
  .e-bigger .e-datetime-wrapper.e-outline .e-input-group-icon.e-time-icon,
  .e-datetime-wrapper.e-control-wrapper.e-bigger.e-outline .e-input-group-icon.e-time-icon,
  .e-bigger .e-datetime-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-time-icon {
    @if $skin-name == 'material-dark' or $skin-name == 'material' {
      min-height: $zero-value;
      min-width: 24px;
    }
  }

  .e-bigger.e-small .e-outline.e-datetime-wrapper .e-input-group-icon.e-date-icon,
  .e-bigger.e-small.e-outline.e-datetime-wrapper .e-input-group-icon.e-date-icon,
  .e-datetime-wrapper.e-control-wrapper.e-bigger.e-small.e-outline .e-input-group-icon.e-date-icon,
  .e-bigger.e-small .e-datetime-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-date-icon,
  .e-bigger.e-small .e-outline.e-datetime-wrapper .e-input-group-icon.e-time-icon,
  .e-bigger.e-small.e-outline.e-datetime-wrapper .e-input-group-icon.e-time-icon,
  .e-datetime-wrapper.e-control-wrapper.e-bigger.e-small.e-outline .e-input-group-icon.e-time-icon,
  .e-bigger.e-small .e-datetime-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-time-icon {
    @if $skin-name == 'material-dark' or $skin-name == 'material' {
      min-height: $zero-value;
      min-width: 20px;
    }
  }
}

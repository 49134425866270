@import "../base/base.gd.scss";
@import "../popups/spinner.gd.scss";
@import "../buttons/button.gd.scss";

$progress-btn-color: rgba($grey-black, .87) !default;
$progress-btn-bgcolor: rgba($grey-black, .2) !default;
$progress-btn-bgcolor-normal: rgba($grey-black, .2) !default;
$progress-btn-warning-progress-color: $progress-btn-bgcolor !default;
$progress-btn-circle-opacity: 0 !default;

$progress-btn-transition: all .3s linear !default;

$progress-btn-spinner-padding: 12px !default;
$progress-btn-spinner-padding-small: 10px !default;
$progress-btn-spinner-padding-bigger: 14px !default;
$progress-btn-spinner-padding-bigger-small: 12px !default;

$progress-btn-spin-btn-padding: 16px !default;
$progress-btn-small-spin-btn-padding: 14px !default;
$progress-btn-bigger-spin-btn-padding: 20px !default;
$progress-btn-bigger-small-spin-btn-padding: 16px !default;

$progress-btn-flat-primary-progress-color: $accent !default;
$progress-btn-flat-success-progress-color: #4d841d !default;
$progress-btn-flat-info-progress-color: #0378d5 !default;
$progress-btn-flat-warning-progress-color: #c15700 !default;
$progress-btn-flat-danger-progress-color: #d64113 !default;
$btn-flat-primary-path-arc: none;

@include export-module('progress-button-layout') {
  .e-progress-btn {
    position: relative;

    .e-progress {
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: opacity .3s linear;
    }

    .e-spinner {
      transition: $progress-btn-transition;
    }

    .e-btn-content {
      display: inline-block;
      position: relative;
      z-index: 1;
    }

    &.e-vertical {
      .e-progress {
        bottom: 0;
        height: 0%;
        top: auto;
        width: 100%;
      }
    }

    &.e-progress-top {
      .e-progress {
        height: 10%;
      }
    }

    &.e-progress-bottom {
      .e-progress {
        bottom: -1px;
        height: 10%;
        top: auto;
      }
    }

    &.e-progress-active {
      overflow: hidden;
      pointer-events: none;

      .e-progress {
        opacity: 1;
      }

      .e-animate-end {
        opacity: 0;
      }
    }

    &.e-spin-top,
    &.e-spin-bottom {
      transition: padding .3s linear;
    }

    &.e-progress-active:not(.e-hide-spinner) {
      &.e-spin-top,
      &.e-spin-bottom {
        .e-btn-content,
        .e-spinner {
          display: block;
          line-height: 1;
        }
      }

      &.e-spin-center {
        .e-spinner {
          display: flex;
          justify-content: center;
        }
      }

      &.e-spin-left:not(.e-round) {
        .e-spinner {
          padding-right: $progress-btn-spinner-padding;

          .e-spinner-inner {
            top: -1px;
          }
        }

        &.e-rtl {
          .e-spinner {
            padding-left: $progress-btn-spinner-padding;
            padding-right: 0;
          }
        }
      }

      &.e-spin-right:not(.e-round) {
        .e-spinner {
          padding-left: $progress-btn-spinner-padding;

          .e-spinner-inner {
            top: -1px;
          }
        }

        &.e-rtl {
          .e-spinner {
            padding-left: 0;
            padding-right: $progress-btn-spinner-padding;
          }
        }
      }

      &.e-spin-top {
        padding-bottom: $progress-btn-spin-btn-padding;
        padding-top: $progress-btn-spin-btn-padding;

        .e-spinner {
          padding-bottom: $progress-btn-spinner-padding;
        }
      }

      &.e-spin-bottom {
        padding-bottom: $progress-btn-spin-btn-padding;
        padding-top: $progress-btn-spin-btn-padding;

        .e-spinner {
          padding-top: $progress-btn-spinner-padding;
        }
      }

      &.e-small {
        &.e-spin-left:not(.e-round) {
          .e-spinner {
            padding-right: $progress-btn-spinner-padding-small;
          }

          &.e-rtl {
            .e-spinner {
              padding-left: $progress-btn-spinner-padding-small;
              padding-right: 0;
            }
          }
        }

        &.e-spin-right:not(.e-round) {
          .e-spinner {
            padding-left: $progress-btn-spinner-padding-small;
          }

          &.e-rtl {
            .e-spinner {
              padding-left: 0;
              padding-right: $progress-btn-spinner-padding-small;
            }
          }
        }

        &.e-spin-top {
          padding-bottom: $progress-btn-small-spin-btn-padding;
          padding-top: $progress-btn-small-spin-btn-padding;

          .e-spinner {
            padding-bottom: $progress-btn-spinner-padding-small;
          }
        }

        &.e-spin-bottom {
          padding-bottom: $progress-btn-small-spin-btn-padding;
          padding-top: $progress-btn-small-spin-btn-padding;

          .e-spinner {
            padding-top: $progress-btn-spinner-padding-small;
          }
        }
      }
    }

    &.e-round-corner {
      border-radius: 5em;
      outline: none;
      overflow: hidden;
    }

    &.e-rtl {
      .e-progress {
        left: auto;
        right: 0;
      }

      &.e-vertical {
        .e-progress {
          bottom: auto;
          top: 0;
        }
      }
    }

    .e-spinner-pane {
      height: auto;
      position: relative;
      width: auto;

      .e-spinner-inner {
        left: auto;
        position: relative;
        top: auto;
        transform: none;
      }
    }

    .e-cont-animate {
      align-items: center;
      display: inline-flex;
      height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    @keyframes ProgressSlideLeft {
      from {
        left: 0%;
        opacity: 1;
      }

      to {
        left: -100%;
        opacity: 0;
      }
    }

    @keyframes ProgressSlideRight {
      from {
        left: 0%;
        opacity: 1;
      }

      to {
        left: 100%;
        opacity: 0;
      }
    }

    @keyframes ProgressSlideUp {
      from {
        height: auto;
        opacity: 1;
        top: 0;
      }

      to {
        height: auto;
        opacity: 0;
        top: -100px;
      }
    }

    @keyframes ProgressSlideDown {
      from {
        height: auto;
        opacity: 1;
        top: 0;
      }

      to {
        height: auto;
        opacity: 0;
        top: 100px;
      }
    }

    @keyframes ProgressZoomIn {
      from {
        opacity: 1;
        transform: translate(0, 0) scale(1);
      }

      to {
        opacity: 0;
        transform: translate(0, 0) scale(3);
      }
    }

    @keyframes ProgressZoomOut {
      from {
        transform: translate(0, 0) scale(1);
      }

      to {
        transform: translate(0, 0) scale(0);
      }
    }
  }
}


@include export-module('progress-button-theme') {

  /*! Progress Button Theme */
  .e-progress-btn {
    .e-spinner-pane .e-spinner-inner svg {
      fill: $progress-btn-color;
      stroke: $progress-btn-color;

      .e-path-circle {
        @if $skin-name == 'bootstrap4' {
          stroke-width: 1px;
        }
        @else if $skin-name == 'tailwind' or $skin-name == 'tailwind-dark' {
          stroke-width: 3px;
        }
      }

      .e-path-arc {
        stroke: $btn-color;
        @if $skin-name == 'tailwind' or $skin-name == 'tailwind-dark' {
          stroke-width: 3px;
        }
      }
    }

    #{if(&, '&', '*')}.e-primary,
    #{if(&, '&', '*')}.e-primary.e-outline:hover,
    #{if(&, '&', '*')}.e-primary.e-outline:focus,
    #{if(&, '&', '*')}.e-primary.e-flat:hover,
    #{if(&, '&', '*')}.e-primary.e-flat:focus {
      .e-spinner-pane .e-spinner-inner svg {
        fill: $btn-primary-color;
        stroke: $btn-primary-color;

        .e-path-circle {
          @if $skin-name == 'material' or $skin-name == 'fabric' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
            stroke: fade-out($btn-primary-color, $progress-btn-circle-opacity);
          }
        }

        .e-path-arc {
          stroke: $btn-primary-color;
        }
      }

      &.e-flat {
        .e-spinner-pane .e-spinner-inner svg {
          fill: $btn-flat-primary-color;
          stroke: $btn-flat-primary-color;

          .e-path-circle {
            @if $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
              stroke: fade-out($btn-flat-primary-path-arc, $progress-btn-circle-opacity);
            }
            @else if $skin-name == 'material' or $skin-name == 'fabric' or $skin-name == 'bootstrap4' or $skin-name == 'bootstrap'{
              stroke: fade-out($btn-flat-primary-color, $progress-btn-circle-opacity);
            }
          }

          .e-path-arc {
            @if $skin-name == 'tailwind' or $skin-name == 'tailwind-dark' or $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'Material3' {
              stroke: $btn-flat-primary-path-arc;
            }
            @else {
              stroke: $btn-flat-primary-color;
            }
          }
        }
      }
    }

    #{if(&, '&', '*')}.e-primary.e-outline:hover,
    #{if(&, '&', '*')}.e-primary.e-outline:focus {
      @if $skin-name == 'Material3' {
        .e-spinner-pane .e-spinner-inner svg {
          fill: $btn-primary-bgcolor;
          stroke: $btn-primary-bgcolor;

          .e-path-circle {
            @if $skin-name == 'material' or $skin-name == 'fabric' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
              stroke: fade-out($btn-primary-color, $progress-btn-circle-opacity);
            }
          }

          .e-path-arc {
            stroke: $btn-primary-color;
          }
        }
      }
    }

    #{if(&, '&', '*')}.e-success,
    #{if(&, '&', '*')}.e-success.e-outline:hover,
    #{if(&, '&', '*')}.e-success.e-outline:focus,
    #{if(&, '&', '*')}.e-success.e-flat:hover,
    #{if(&, '&', '*')}.e-success.e-flat:focus {
      .e-spinner-pane .e-spinner-inner svg {
        fill: $btn-success-color;
        stroke: $btn-success-color;

        .e-path-circle {
          @if $skin-name == 'material' or $skin-name == 'fabric' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
            stroke: fade-out($btn-success-color, $progress-btn-circle-opacity);
          }
        }

        .e-path-arc {
          stroke: $btn-success-color;
        }
      }

      &.e-flat {
        .e-spinner-pane .e-spinner-inner svg {
          fill: $btn-flat-success-color;
          stroke: $btn-flat-success-color;

          .e-path-circle {
            @if $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
              stroke: fade-out($btn-flat-primary-path-arc, $progress-btn-circle-opacity);
            }
            @else {
              @if $skin-name == 'material' or $skin-name == 'fabric' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
                stroke: fade-out($btn-flat-success-color, $progress-btn-circle-opacity);
              }
            }
          }

          .e-path-arc {
            @if $skin-name == 'tailwind' or $skin-name == 'tailwind-dark' {
              stroke: $btn-flat-primary-path-arc;
            }
            @else {
              stroke: $btn-flat-success-color;
            }
          }
        }
      }
    }

    #{if(&, '&', '*')}.e-success.e-outline:hover,
    #{if(&, '&', '*')}.e-success.e-outline:focus {
      @if $skin-name == 'Material3' {
        .e-spinner-pane .e-spinner-inner svg {
          fill: $btn-success-bgcolor;
          stroke: $btn-success-bgcolor;

          .e-path-circle {
            @if $skin-name == 'material' or $skin-name == 'fabric' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
              stroke: fade-out($btn-success-color, $progress-btn-circle-opacity);
            }
          }

          .e-path-arc {
            stroke: $btn-success-color;
          }
        }
      }
    }

    &.e-info,
    &.e-info.e-outline:hover,
    &.e-info.e-outline:focus,
    &.e-info.e-flat:hover,
    &.e-info.e-flat:focus {
      .e-spinner-pane .e-spinner-inner svg {
        fill: $btn-info-color;
        stroke: $btn-info-color;

        .e-path-circle {
          @if $skin-name == 'material' or $skin-name == 'fabric' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
            stroke: fade-out($btn-info-color, $progress-btn-circle-opacity);
          }
          @if $skin-name == 'fluent2' {
            stroke: fade-out(#242424, $progress-btn-circle-opacity);
          }
        }

        .e-path-arc {
          stroke: $btn-info-color;
        }
      }

      &.e-flat {
        .e-spinner-pane .e-spinner-inner svg {
          fill: $btn-flat-info-color;
          stroke: $btn-flat-info-color;

          .e-path-circle {
            @if $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
              stroke: fade-out($btn-flat-primary-path-arc, $progress-btn-circle-opacity);
            }
            @else if $skin-name == 'material' or $skin-name == 'fabric' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
              stroke: fade-out($btn-flat-info-color, $progress-btn-circle-opacity);
            }
          }

          .e-path-arc {
            @if $skin-name == 'tailwind' or $skin-name == 'tailwind-dark' {
              stroke: $btn-flat-primary-path-arc;
            }
            @else {
              stroke: $btn-flat-info-color;
            }
          }
        }
      }
    }

    #{if(&, '&', '*')}.e-info.e-outline:hover,
    #{if(&, '&', '*')}.e-info.e-outline:focus {
      @if $skin-name == 'Material3' {
        .e-spinner-pane .e-spinner-inner svg {
          fill: $btn-info-bgcolor;
          stroke: $btn-info-bgcolor;

          .e-path-circle {
            @if $skin-name == 'material' or $skin-name == 'fabric' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
              stroke: fade-out($btn-info-color, $progress-btn-circle-opacity);
            }
          }

          .e-path-arc {
            stroke: $btn-info-color;
          }
        }
      }
    }

    &.e-warning,
    &.e-warning.e-outline:hover,
    &.e-warning.e-outline:focus,
    &.e-warning.e-flat:hover,
    &.e-warning.e-flat:focus {
      .e-spinner-pane .e-spinner-inner svg {
        fill: $btn-warning-color;
        stroke: $btn-warning-color;

        .e-path-circle {
          @if $skin-name == 'material' or $skin-name == 'fabric' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
            stroke: fade-out($btn-warning-color, $progress-btn-circle-opacity);
          }
        }

        .e-path-arc {
          stroke: $btn-warning-color;
        }
      }

      &.e-flat {
        .e-spinner-pane .e-spinner-inner svg {
          fill: $btn-flat-warning-color;
          stroke: $btn-flat-warning-color;

          .e-path-circle {
            @if $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
              stroke: fade-out($btn-flat-primary-path-arc, $progress-btn-circle-opacity);
            }
            @else {
              stroke: $btn-flat-warning-color;
            }
          }

          .e-path-arc {
            @if $skin-name == 'tailwind' or $skin-name == 'tailwind-dark' {
              stroke: $btn-flat-primary-path-arc;
            }
            @else {
              stroke: $btn-flat-warning-color;
            }
          }
        }
      }
    }

    #{if(&, '&', '*')}.e-warning.e-outline:hover,
    #{if(&, '&', '*')}.e-warning.e-outline:focus {
      @if $skin-name == 'Material3' {
        .e-spinner-pane .e-spinner-inner svg {
          fill: $btn-warning-bgcolor;
          stroke: $btn-warning-bgcolor;

          .e-path-circle {
            @if $skin-name == 'material' or $skin-name == 'fabric' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
              stroke: fade-out($btn-warning-color, $progress-btn-circle-opacity);
            }
          }

          .e-path-arc {
            stroke: $btn-warning-color;
          }
        }
      }
    }

    &.e-danger,
    &.e-danger.e-outline:hover,
    &.e-danger.e-outline:focus,
    &.e-danger.e-flat:hover,
    &.e-danger.e-flat:focus {
      .e-spinner-pane .e-spinner-inner svg {
        fill: $btn-danger-color;
        stroke: $btn-danger-color;

        .e-path-circle {
          @if $skin-name == 'material' or $skin-name == 'fabric' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
            stroke: fade-out($btn-danger-color, $progress-btn-circle-opacity);
          }
        }

        .e-path-arc {
          stroke: $btn-danger-color;
        }
      }

      &.e-flat {
        .e-spinner-pane .e-spinner-inner svg {
          fill: $btn-flat-danger-color;
          stroke: $btn-flat-danger-color;

          .e-path-circle {
            @if $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
              stroke: fade-out($btn-flat-primary-path-arc, $progress-btn-circle-opacity);
            }
            @else if $skin-name == 'material' or $skin-name == 'fabric' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
              stroke: fade-out($btn-flat-danger-color, $progress-btn-circle-opacity);
            }
          }

          .e-path-arc {
            @if $skin-name == 'tailwind' or $skin-name == 'tailwind-dark' {
              stroke: $btn-flat-primary-path-arc;
            }
            @else {
              stroke: $btn-flat-danger-color;
            }
          }
        }
      }
    }

    #{if(&, '&', '*')}.e-danger.e-outline:hover,
    #{if(&, '&', '*')}.e-danger.e-outline:focus {
      @if $skin-name == 'Material3' {
        .e-spinner-pane .e-spinner-inner svg {
          fill: $btn-danger-bgcolor;
          stroke: $btn-danger-bgcolor;

          .e-path-circle {
            @if $skin-name == 'material' or $skin-name == 'fabric' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
              stroke: fade-out($btn-danger-color, $progress-btn-circle-opacity);
            }
          }

          .e-path-arc {
            stroke: $btn-danger-color;
          }
        }
      }
    }

    &.e-outline,
    &.e-flat {
      &.e-primary {
        .e-spinner-pane .e-spinner-inner svg {
          fill: $btn-primary-bgcolor;
          stroke: $btn-primary-bgcolor;

          .e-path-circle {
            @if $skin-name == 'material' or $skin-name == 'fabric' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
              stroke: fade-out($btn-primary-bgcolor, $progress-btn-circle-opacity);
            }
          }

          .e-path-arc {
            stroke: $btn-primary-bgcolor;
          }
        }
      }

      &.e-success {
        .e-spinner-pane .e-spinner-inner svg {
          fill: $btn-success-bgcolor;
          stroke: $btn-success-bgcolor;

          .e-path-circle {
            @if $skin-name == 'material' or $skin-name == 'fabric' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
              stroke: fade-out($btn-success-bgcolor, $progress-btn-circle-opacity);
            }
          }
          .e-path-arc {
            stroke: $btn-success-bgcolor;
          }
        }
      }

      &.e-info {
        .e-spinner-pane .e-spinner-inner svg {
          fill: $btn-info-bgcolor;
          stroke: $btn-info-bgcolor;

          .e-path-circle {
            @if $skin-name == 'material' or $skin-name == 'fabric' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
              stroke: fade-out($btn-info-bgcolor, $progress-btn-circle-opacity);
            }
          }

          .e-path-arc {
            stroke: $btn-info-bgcolor;
          }
        }
      }

      &.e-warning {
        .e-spinner-pane .e-spinner-inner svg {
          fill: $btn-warning-bgcolor;
          stroke: $btn-warning-bgcolor;

          .e-path-circle {
            @if $skin-name == 'material' or $skin-name == 'fabric' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
              stroke: fade-out($btn-warning-bgcolor, $progress-btn-circle-opacity);
            }
          }

          .e-path-arc {
            stroke: $btn-warning-bgcolor;
          }
        }
      }

      &.e-danger {
        .e-spinner-pane .e-spinner-inner svg {
          fill: $btn-danger-bgcolor;
          stroke: $btn-danger-bgcolor;

          .e-path-circle {
            @if $skin-name == 'material' or $skin-name == 'fabric' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
              stroke: fade-out($btn-danger-bgcolor, $progress-btn-circle-opacity);
            }
          }

          .e-path-arc {
            stroke: $btn-danger-bgcolor;
          }
        }
      }
    }

    &.e-link {
      .e-spinner-pane .e-spinner-inner svg {
        fill: $btn-link-color;
        stroke: $btn-link-color;

        .e-path-circle {
          @if $skin-name == 'material' or $skin-name == 'fabric' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
            stroke: fade-out($btn-link-color, $progress-btn-circle-opacity);
          }
        }

        .e-path-arc {
          stroke: $btn-link-color;
        }
      }
    }
  }

  .e-progress {
    background: $progress-btn-bgcolor-normal;
  }

  #{if(&, '&', '*')}.e-primary,
  #{if(&, '&', '*')}.e-success,
  #{if(&, '&', '*')}.e-danger {
    .e-progress {
      background: $progress-btn-bgcolor;
    }
  }

  #{if(&, '&', '*')}.e-info {
    .e-progress {
      @if $skin-name != 'fluent2' {
        background: $progress-btn-bgcolor;
      }
    }
  }

  #{if(&, '&', '*')}.e-flat {
    &.e-primary {
      .e-progress {
        @if $skin-name == 'material' or $skin-name == 'fabric' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
          background: fade-out($progress-btn-flat-primary-progress-color, .8);
        }
      }
    }

    &.e-success {
      .e-progress {
        @if $skin-name == 'material' or $skin-name == 'fabric' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
          background: fade-out($progress-btn-flat-success-progress-color, .8);
        }
      }
    }

    &.e-info {
      .e-progress {
        @if $skin-name == 'material' or $skin-name == 'fabric' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
          background: fade-out($progress-btn-flat-info-progress-color, .8);
        }
      }
    }

    &.e-warning {
      .e-progress {
        @if $skin-name == 'material' or $skin-name == 'fabric' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
          background: fade-out($progress-btn-flat-warning-progress-color, .8);
        }
      }
    }

    &.e-danger {
      .e-progress {
        @if $skin-name == 'material' or $skin-name == 'fabric' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
          background: fade-out($progress-btn-flat-danger-progress-color, .8);
        }
      }
    }
  }

  #{if(&, '&', '*')}.e-outline:not(:focus) {
    &.e-primary {
      .e-progress {
        @if $skin-name == 'material' or $skin-name == 'fabric' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
          background: fade-out($btn-primary-bgcolor, .8);
        }
      }
    }

    &.e-success {
      .e-progress {
        @if $skin-name == 'material' or $skin-name == 'fabric' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
          background: fade-out($btn-success-bgcolor, .8);
        }
      }
    }

    &.e-info {
      .e-progress {
        @if $skin-name == 'material' or $skin-name == 'fabric' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
          background: fade-out($btn-info-bgcolor, .8);
        }
      }
    }

    &.e-warning {
      .e-progress {
        @if $skin-name == 'material' or $skin-name == 'fabric' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
          background: fade-out($btn-warning-bgcolor, .8);
        }
      }
    }

    &.e-danger {
      .e-progress {
        @if $skin-name == 'material' or $skin-name == 'fabric' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
          background: fade-out($btn-danger-bgcolor, .8);
        }
      }
    }
  }

  @if $skin-name == 'Material3' {
    #{if(&, '&', '*')}.e-flat,
    #{if(&, '&', '*')}.e-flat:not(:focus) {
      &.e-primary {
        .e-progress {
          background: $progress-btn-flat-primary-progress-color;
        }
      }

      &.e-success {
        .e-progress {
          background: $progress-btn-flat-success-progress-color;
        }
      }

      &.e-info {
        .e-progress {
          background: $progress-btn-flat-info-progress-color;
        }
      }

      &.e-warning {
        .e-progress {
          background: $progress-btn-flat-warning-progress-color;
        }
      }

      &.e-danger {
        .e-progress {
          background: $progress-btn-flat-danger-progress-color;
        }
      }
    }
  }

  #{if(&, '&', '*')}.e-flat:not(:focus) {
    @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
      &.e-primary {
        .e-progress {
          @if $skin-name == 'material' or $skin-name == 'fabric' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
            background: fade-out($btn-primary-bgcolor, .8);
          }
        }
      }

      &.e-success {
        .e-progress {
          @if $skin-name == 'material' or $skin-name == 'fabric' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
            background: fade-out($btn-success-bgcolor, .8);
          }
        }
      }

      &.e-info {
        .e-progress {
          @if $skin-name == 'material' or $skin-name == 'fabric' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
            background: fade-out($btn-info-bgcolor, .8);
          }
        }
      }

      &.e-warning {
        .e-progress {
          @if $skin-name == 'material' or $skin-name == 'fabric' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
            background: fade-out($btn-warning-bgcolor, .8);
          }
        }
      }

      &.e-danger {
        .e-progress {
          @if $skin-name == 'material' or $skin-name == 'fabric' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' {
            background: fade-out($btn-danger-bgcolor, .8);
          }
        }
      }
    }
  }
}

@if $skin-name != 'fluent2' {
  .e-progress-btn.e-outline .e-spinner-pane .e-spinner-inner svg {
    fill: $btn-outline-color;
    stroke: $btn-outline-color;
  }

  .e-progress-btn.e-outline:focus .e-spinner-pane .e-spinner-inner svg {
    fill: $btn-color;
    stroke: $btn-color;
  }

  .e-progress-btn.e-outline.e-success .e-spinner-pane .e-spinner-inner svg .e-path-arc {
    stroke: $btn-outline-color;
  }
}

@if $skin-name == 'fluent2' {
  .e-primary.e-outline .e-progress,
  .e-primary.e-flat .e-progress {
    background: rgba(15, 108, 189, .5) !important; /* stylelint-disable-line declaration-no-important */
  }

  .e-success.e-outline .e-progress,
  .e-success.e-flat .e-progress {
    background: rgba(16, 124, 16, .5) !important; /* stylelint-disable-line declaration-no-important */
  }

  .e-danger.e-outline .e-progress,
  .e-danger.e-flat .e-progress {
    background: rgba(197, 15, 31, .5) !important; /* stylelint-disable-line declaration-no-important */
  }

  .e-warning.e-outline .e-progress,
  .e-warning.e-flat .e-progress {
    background: rgba(247, 99, 12, .5) !important; /* stylelint-disable-line declaration-no-important */
  }

  .e-info.e-outline .e-progress,
  .e-info.e-flat .e-progress {
    background: rgba(49, 175, 204, .5) !important; /* stylelint-disable-line declaration-no-important */
  }

  .e-progress-btn.e-warning .e-spinner-pane .e-spinner-inner .e-spin-fluent2 .e-path-circle {
    stroke: fade-out(#242424, $progress-btn-circle-opacity) !important; /* stylelint-disable-line declaration-no-important */
  }

  .e-progress-btn.e-warning.e-flat:focus .e-spinner-pane .e-spinner-inner .e-spin-fluent2 .e-path-circle {
    stroke: $brand-160 !important; /* stylelint-disable-line declaration-no-important */
  }

  .e-progress-btn.e-outline {
    &.e-success:focus .e-spinner-pane .e-spinner-inner svg .e-path-arc {
      stroke: $success-bg-color;
    }
    &.e-primary:focus .e-spinner-pane .e-spinner-inner svg .e-path-arc {
      stroke: $primary-bg-color;
    }
    &.e-danger:focus .e-spinner-pane .e-spinner-inner svg .e-path-arc {
      stroke: $danger-bg-color;
    }
    &.e-warning:focus .e-spinner-pane .e-spinner-inner svg .e-path-arc {
      stroke: $warning-bg-color;
    }
    &.e-info:focus .e-spinner-pane .e-spinner-inner svg .e-path-arc {
      stroke: $info-bg-color;
    }
  }

  .e-progress-btn .e-spinner-pane .e-spinner-inner .e-spin-fluent2 .e-path-circle {
    stroke: $progress-btn-spinner-color !important; /* stylelint-disable-line declaration-no-important */
  }
}

@if $skin-name == 'tailwind3' {
  .e-progress,
  .e-primary .e-progress,
  .e-success .e-progress,
  .e-warning .e-progress,
  .e-info .e-progress,
  .e-danger .e-progress,
  .e-primary.e-outline .e-progress,
  .e-primary.e-flat .e-progress,
  .e-success.e-outline .e-progress,
  .e-success.e-flat .e-progress,
  .e-danger.e-outline .e-progress,
  .e-danger.e-flat .e-progress,
  .e-warning.e-outline .e-progress,
  .e-warning.e-flat .e-progress,
  .e-info.e-outline .e-progress,
  .e-info.e-flat .e-progress {
    opacity: .2 !important; /* stylelint-disable-line declaration-no-important */
  }

  .e-progress {
    background: $secondary-text-color-focus;
  }

  .e-primary .e-progress {
    background: $primary-text;
  }

  .e-success .e-progress {
    background: $success-text;
  }

  .e-warning .e-progress {
    background: $warning-text;
  }

  .e-info .e-progress {
    background: $info-text;
  }

  .e-danger .e-progress {
    background: $danger-text;
  }

  .e-primary.e-outline .e-progress,
  .e-primary.e-flat .e-progress {
    background: $primary-bg-color;
  }

  .e-success.e-outline .e-progress,
  .e-success.e-flat .e-progress {
    background: $success-bg-color;
  }

  .e-danger.e-outline .e-progress,
  .e-danger.e-flat .e-progress {
    background: $danger-bg-color;
  }

  .e-warning.e-outline .e-progress,
  .e-warning.e-flat .e-progress {
    background: $warning-bg-color;
  }

  .e-info.e-outline .e-progress,
  .e-info.e-flat .e-progress {
    background: $info-bg-color;
  }
}

@include export-module('progress-button-bigger') {
  #{if(&, '&', '*')}.e-bigger .e-progress-btn,
  #{if(&, '&', '*')}.e-bigger.e-progress-btn {
    &.e-progress-active:not(.e-hide-spinner) {
      &.e-spin-left:not(.e-round) {
        .e-spinner {
          padding-right: $progress-btn-spinner-padding-bigger;
        }

        &.e-rtl {
          .e-spinner {
            padding-left: $progress-btn-spinner-padding-bigger;
            padding-right: 0;
          }
        }
      }

      &.e-spin-right:not(.e-round) {
        .e-spinner {
          padding-left: $progress-btn-spinner-padding-bigger;
        }

        &.e-rtl {
          .e-spinner {
            padding-left: 0;
            padding-right: $progress-btn-spinner-padding-bigger;
          }
        }
      }

      &.e-spin-top {
        padding-bottom: $progress-btn-bigger-spin-btn-padding;
        padding-top: $progress-btn-bigger-spin-btn-padding;

        .e-spinner {
          padding-bottom: $progress-btn-spinner-padding-bigger;
        }
      }

      &.e-spin-bottom {
        padding-bottom: $progress-btn-bigger-spin-btn-padding;
        padding-top: $progress-btn-bigger-spin-btn-padding;

        .e-spinner {
          padding-top: $progress-btn-spinner-padding-bigger;
        }
      }

      &.e-small {
        &.e-spin-left:not(.e-round) {
          .e-spinner {
            padding-right: $progress-btn-spinner-padding-bigger-small;
          }

          &.e-rtl {
            .e-spinner {
              padding-left: $progress-btn-spinner-padding-bigger-small;
              padding-right: 0;
            }
          }
        }

        &.e-spin-right:not(.e-round) {
          .e-spinner {
            padding-left: $progress-btn-spinner-padding-bigger-small;
          }

          &.e-rtl {
            .e-spinner {
              padding-left: 0;
              padding-right: $progress-btn-spinner-padding-bigger-small;
            }
          }
        }

        &.e-spin-top {
          padding-bottom: $progress-btn-bigger-small-spin-btn-padding;
          padding-top: $progress-btn-bigger-small-spin-btn-padding;

          .e-spinner {
            padding-bottom: $progress-btn-spinner-padding-bigger-small;
          }
        }

        &.e-spin-bottom {
          padding-bottom: $progress-btn-bigger-small-spin-btn-padding;
          padding-top: $progress-btn-bigger-small-spin-btn-padding;

          .e-spinner {
            padding-top: $progress-btn-spinner-padding-bigger-small;
          }
        }
      }
    }
  }
}

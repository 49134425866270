@import "../base/base.gd.scss";
@import "../inputs/input.gd.scss";

$numeric-skin-name: 'material' !default;
$numeric-input-icon-size: 12px !default;
$numeric-input-bigger-icon-size: 12px !default;

.e-numeric-container {
  width: 100%;
}

.e-content-placeholder.e-numeric.e-placeholder-numeric {
  background-size: 300px 33px;
  min-height: 33px;
}

// Hidden element styles
.e-float-input.e-input-group.e-control-wrapper.e-numeric .e-numeric-hidden,
.e-input-group.e-control-wrapper.e-numeric .e-numeric-hidden,
.e-float-input.e-control-wrapper.e-numeric .e-numeric-hidden,
.e-float-input.e-input-group.e-control-wrapper.e-numeric.e-input-focus .e-numeric-hidden,
.e-input-group.e-control-wrapper.e-numeric.e-input-focus .e-numeric-hidden,
.e-float-input.e-control-wrapper.e-numeric.e-input-focus .e-numeric-hidden {
  border: 0;
  height: 0;
  margin: 0;
  padding: 0;
  text-indent: 0;
  visibility: hidden;
  width: 0;
}


@include export-module('numerictextbox-theme') {
  .e-numeric.e-control-wrapper {
    #{if(&, '&', '*')}.e-input-group:not(.e-disabled):active:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-focus) {
      @if $numeric-skin-name == 'bootstrap' {
        border-color: $input-group-full-border-color;
        box-shadow: none;
      }
    }

    #{if(&, '&', '*')}.e-input-group .e-input-group-icon {
      font-size: $numeric-input-icon-size;
    }
  }

  .e-small {
    #{if(&, '&', '*')} .e-control-wrapper.e-numeric.e-input-group .e-input-group-icon,
    #{if(&, '&', '*')}.e-control-wrapper.e-numeric.e-input-group .e-input-group-icon {
      @if ($input-skin-name == 'FluentUI') {
        font-size: $numeric-input-small-icon-size;
      }
    }
  }
}


@include export-module('numerictextbox-material-icons') {
  .e-input-group-icon.e-spin-up::before {
    content: '\e834';
    font-family: 'e-icons';
  }

  .e-input-group-icon.e-spin-down::before {
    content: '\e83d';
    font-family: 'e-icons';
  }
}

@include export-module('numerictextbox-bigger') {

  .e-bigger.e-content-placeholder.e-numeric.e-placeholder-numeric,
  .e-bigger .e-content-placeholder.e-numeric.e-placeholder-numeric {
    background-size: 300px 40px;
    min-height: 40px;
  }

  .e-bigger {
    #{if(&, '&', '*')} .e-control-wrapper.e-numeric.e-input-group .e-input-group-icon,
    #{if(&, '&', '*')}.e-control-wrapper.e-numeric.e-input-group .e-input-group-icon {
      font-size: $numeric-input-bigger-icon-size;
    }
  }

  .e-small.e-bigger .e-control-wrapper.e-numeric.e-input-group .e-input-group-icon,
  .e-small.e-bigger.e-control-wrapper.e-numeric.e-input-group .e-input-group-icon,
  .e-small .e-bigger.e-control-wrapper.e-numeric.e-input-group .e-input-group-icon,
  .e-bigger .e-small.e-control-wrapper.e-numeric.e-input-group .e-input-group-icon {
    @if ($input-skin-name == 'FluentUI') {
      font-size: $numeric-input-bigger-small-icon-size;
    }
  }
}

@import "../base/base.gd.scss";
@import "../popups/popup.gd.scss";
@import "../buttons/button.gd.scss";
@import "../navigations/h-scroll.gd.scss";
@import "../navigations/v-scroll.gd.scss";

$tbar-skin: 'material' !default;
$tbar-pop-icon-bgr-padding: 0;
$tbar-pop-btn-txt-bgr-pad: 0;
$tbar-btn-bgr-minheight: 0 !default;
$tbar-btn-bgr-line-height: 35px !default;
$tbar-btn-icon-bgr-line-height: 34px !default;
$tbar-icons-bgr-font-size: 14px;
$tbar-btn-nrml-minheight: 0 !default;
$tbar-btn-nrml-line-height: 25px !default;
$tbar-btn-icon-nrml-line-height: 25px !default;
$tbar-pop-icon-nrml-padding: 0;
$tbar-pop-btn-txt-nrml-pad: 0;
$tbar-btn-icn-nrml-padding: 0;
$tbar-btn-icn-bgr-padding: 0;
$tbar-rtl-btn-icn-nrml-padding: 0 !default;
$tbar-rtl-btn-icn-bgr-padding: 0 !default;
$tbar-btn-icn-right-bgr-padding: 0 !default;
$tbar-btn-icn-right-nrml-padding: 0;
$tbar-rtl-btn-icn-right-nrml-padding: 0 !default;
$tbar-rtl-btn-icn-right-bgr-padding: 0 !default;
$tbar-radius: 0;
$tbar-pop-radius: 0;
$tbar-separator-border-type: $border-type;
$tbar-pop-box-shadow: 0 2px 2px 1px rgba(0, 0, 0, .21);
$tbar-default-bg: $grey-50 !default;
$tbar-items-default-bg: $tbar-default-bg !default;
$tbar-pop-bg: $tbar-default-bg !default;
$tbar-press-bg: $grey-400 !default;
$tbar-press-border-color: $tbar-press-bg !default;
$tbar-btn-press-bg: $tbar-press-bg !default;
$tbar-zero-value: 0 !default;
$tbar-btn-bgr-minwidth: 0 !default;
$tbar-btn-nrml-minwidth: 0 !default;
$tbar-btn-weight: 400 !default;
$tbar-border-radius: $border-radius !default;
$tbar-tab-highlight-color:  rgba(0, 0, 0, 0) !default;
$tbar-border-nav-type: solid !default;
$tbar-btn-box-shadow: none !default;
$tbar-active-bg: $grey-400 !default;
$tbar-nrml-size: 42px !default;
$tbar-bgr-size: 56px !default;
$tbar-nrml-items-size: 42px !default;
$tbar-bgr-items-size: 56px !default;
$tbar-nrml-item-size: 42px !default;
$tbar-item-height: 36px !default;
$tbar-bgr-item-size: 48px !default;
$tbar-item-nrml-minwidth: 34px !default;
$tbar-btn-pop-nrml-minheight: 25px !default;
$tbar-btn-pop-bgr-minheight: 30px !default;
$tbar-item-nrml-mrgn: 3px !default;
$tbar-item-bgr-mrgn: 3px !default;
$tbar-nav-nrml-width: 32px !default;
$tbar-nav-bgr-width: 40px !default;
$tbar-popup-padding: 0 !default;
$tbar-btn-border-radius: 2px !default;
$tbar-btn-pressed-box-shadow: none !default;
$tbar-item-bgr-padding: 5px !default;
$tbar-item-nrml-padding: 3.5px !default;
$btn-txt-nrml-padding: 0 2px 0 2px !default;
$btn-txt-bgr-padding: 0 2.5px 0 2.5px !default;
$btn-rtl-txt-nrml-padding: 0 2px 0 2px !default;
$btn-rtl-txt-bgr-padding: 0 2.5px 0 2.5px !default;
$tbar-item-pop-nrml-padding: 0 !default;
$tbar-item-pop-bgr-padding: 0 !default;
$tbar-multirow-items-mrgn-bigger: 24px !default;
$tbar-multirow-items-mrgn-small: 18px !default;
$tbar-multirow-item-top-btm-mrgn-bigger: 0 !default;
$tbar-multirow-item-top-btm-mrgn-small: 0 !default;
$tbar-btn-nrml-mrgn: 4px 0 !default;
$tbar-separator-nrml-mrgn: 7.5px 3px !default;
$tbar-separator-bgr-mrgn: 10px 5px !default;
$tbar-separator-vertical-bgr-mrgn: 5px 10px !default;
$tbar-separator-vertical-nrml-mrgn: 3px 7.5px !default;
$tbar-separator-nrml-height: calc(100% - 15px) !default;
$tbar-separator-bgr-height: calc(100% - 20px) !default;
$tbar-separator-nrml-minheight: 27px !default;
$tbar-separator-bgr-minheight: 36px !default;
$tbar-btn-icon-bgr-width: 27px !default;
$tbar-btn-icon-nrml-width: 24px !default;
$tbar-btn-icon-nrml-height: 25px !default;
$tbar-right-item-line-height: 24px !default;
$tbar-btn-bgr-padding: $tbar-zero-value 6px !default;
$tbar-btn-bgr-focus-padding: 0 6px !default;
$tbar-btn-nrml-padding: $tbar-zero-value 1.5px !default;
$tbar-pop-btn-bgr-padding: $tbar-zero-value 16px !default;
$tbar-pop-btn-nrml-padding: $tbar-zero-value 16px !default;
$tbar-btn-border: none !default;
$tbar-nav-press-border: 0 !default;
$tbar-nav-hover-border: 1px solid rgba($grey-black, .12) !default;
$tbar-nav-focus-border: 0 !default;
$tbar-nav-pressed-box-shadow: none !default;
$tbar-border-nav-active-type: none !default;
$tbar-border-size: 1px !default;
$tbar-border-type: none !default;
$tbar-box-shadow: none !default;
$tbar-separator-size: .5px !default;
$tba-horizontal-separator: 0 $tbar-separator-size 0 0 !default;
$tba-vertical-separator: 0 0 $tbar-separator-size 0 !default;
$tbar-popup-border-width: 0 0 0 $border-size !default;
$tbar-popup-rtl-border-width: 0 1px 0 0 !default;
$tbar-popup-vertical-border-width: $border-size 0 0 0 !default;
$tbar-popup-vertical-rtl-border-width: 0 0 $border-size 0 !default;
$tbar-item-pop-bg-color: transparent !default;
$tbar-btn-font-size: 14px !default;
$tbar-btn-txt-font-size: $tbar-btn-font-size !default;
$tbar-hover-bg: rgba($grey-black, .12) !default;
$tbar-hover-border-color: rgba($grey-black, .12) !default;
$tbar-focus-border-color: $tbar-hover-border-color !default;
$tbar-focus-bg: $tbar-hover-bg !default;
$tbar-default-icon-color: rgba($grey-light-font, .54) !default;
$tbar-default-font: rgba($grey-light-font, .87) !default;
$tbar-hover-font: $tbar-default-font !default;
$tbar-default-font-overlay: rgba($grey-light-font, .38) !default;
$tbar-active-font-color: $tbar-default-font !default;
$tbar-press-font: $tbar-default-font !default;
$tbar-pressed-bg: $tbar-hover-bg !default;
$tbar-pressed-border: $tbar-hover-border-color !default;
$tbar-pressed-font: $tbar-hover-font !default;
$tbar-select-font: $tbar-hover-font !default;
$tbar-default-icon-overlay: rgba($grey-light-font, .26) !default;
$tbar-separator-border: rgba($grey-black, .12) !default;
$tbar-default-border: $tbar-separator-border !default;
$tbar-bgr-btn-text-font-size: 14px !default;
$tbar-bgr-btn-icon-font-size: 14px !default;
$tbar-bgr-btn-focus-padding: 0 6px !default;
$tbar-nrml-btn-border-radius: 2px !default;
$tbar-nrml-btn-focus-padding: 0 1.5px !default;
$tbar-nrml-btn-focus-outline: 0 !default;
$tbar-btn-icons-focus-color: $tbar-default-icon-color !default;
$tbar-btn-text-focus-color: $tbar-default-font !default;
$tbar-btn-focus-border-color: $tbar-focus-border-color !default;
$tbar-btn-hover-border-size: $tbar-zero-value !default;
$tbar-btn-hover-active-icons-color: $tbar-default-icon-color !default;
$tbar-btn-hover-active-text-color: $tbar-default-font !default;
$tbar-btn-overlay-opacity: .5 !default;
$tbar-btn-active-bg: $tbar-press-bg !default;
$tbar-btn-active-icons-color: $tbar-default-icon-color !default;
$tbar-btn-active-text-color: $tbar-hover-font !default;
$tbar-btn-text-color: $tbar-default-font !default;
$tbar-btn-pressed-text-color: rgba($grey-black, .87) !default;
$tbar-btn-pressed-focus-box-shadow: none !default;
$tbar-btn-pressed-bg: mix($tbar-btn-pressed-text-color, transparent, 24%) !default;
$tbar-flat-btn-active-box-shadow: none !default;
$tbar-ext-btn-focus-padding: 0 1.5px !default;
$tbar-ext-btn-icon-padding: 0 !default;
$tbar-ext-btn-icon-font-size: 14px !default;
$tbar-ext-btn-focus-box-shadow: none !default;
$tbar-ext-btn-hover-border-color: $tbar-hover-border-color !default;
$tbar-ext-btn-border: none !default;
$tbar-popup-icon-font-size: 12px !default;
$tbar-popup-text-btn-icon-padding: 0 !default;
$tbar-popup-bgr-text-btn-icon-padding: 0 !default;
$tbar-popup-btn-border: none !default;
$tbar-popup-btn-border-radius: 2px !default;
$tbar-popup-bgr-height: 48px !default;
$tbar-popup-bgr-btn-icon-font-size: 14px !default;
$tbar-popup-bgr-btn-text-font-size: 14px !default;
$tbar-popup-nav-active-border-bottom-right-radius: 0 !default;
$tbar-popup-nav-active-bg: $tbar-default-bg !default;
$tbar-popup-nav-active-icons-color: $tbar-default-icon-color !default;
$tbar-popup-nav-hover-bg: $tbar-hover-bg !default;
$tbar-popup-nav-hover-color: $tbar-hover-font !default;
$tbar-popup-nav-hover-icons-color: $tbar-default-icon-color !default;
$tbar-popup-nav-hover-border-color: $tbar-default-border !default;
$tbar-popup-nav-hover-border-size: 0 0 0 1px !default;
$tbar-popup-nav-hover-active-bg: $tbar-hover-bg !default;
$tbar-popup-nav-hover-active-border-color: $tbar-default-border !default;
$tbar-popup-nav-hover-active-border-size: 0 !default;
$tbar-popup-nav-focus-bg: $tbar-hover-bg !default;
$tbar-popup-nav-focus-color: $tbar-hover-font !default;
$tbar-popup-nav-focus-border-color: $tbar-hover-bg !default;
$tbar-popup-nav-focus-border-size: 0 !default;
$tbar-popup-btn-bg: $tbar-default-bg !default;
$tbar-popup-btn-hover-bg: $tbar-hover-bg !default;
$tbar-popup-btn-hover-box-shadow: none !default;
$tbar-popup-btn-active-bg: $tbar-press-bg !default;
$tbar-popup-btn-active-box-shadow: none !default;
$tbar-popup-btn-focus-bg: $tbar-hover-bg !default;
$tbar-popup-btn-focus-box-shadow: none !default;
$tbar-popup-nav-pressed-icons-active-color: $tbar-active-font-color !default;
$tbar-popup-btn-focus-outline: 0 !default;
$tbar-popup-nav-pressed-border-color: $tbar-pressed-bg !default;
$tbar-popup-nav-pressed-border-size: 0 !default;
$tbar-popup-nav-pressed-focus-border-color: $tbar-pressed-bg !default;
$tbar-popup-nav-pressed-focus-border-size: 0 !default;
$tbar-popup-btn-hover-border-size: $tbar-zero-value !default;
$tbar-ribble-animation-border-frame: rgba(255, 255, 255, .5) !default;
$tbar-ribble-animation-shadow-frame: 0 0 0 0 $tbar-ribble-animation-border-frame !default;
$tbar-ribble-animation-shadow-frame-end: 0 0 0 200px rgba(255, 255, 255, .12) !default;

@mixin tbar-btn-animation {
  background-color: transparent;
  border-radius: 50%;
  border-width: 1px;
  box-sizing: border-box;
  content: '';
  height: 1px;
  left: 50%;
  position: absolute;
  top: 50%;
  visibility: hidden;
  width: 1px;
}

@mixin tbar-btn-animation-after {
  animation: tbar-popup-shadow .6s ease-out 0ms;
  visibility: visible;
}

@keyframes tbar-popup-shadow {
  0% {
    border-color: $tbar-ribble-animation-border-frame;
    box-shadow: $tbar-ribble-animation-shadow-frame;
  }

  100% {
    box-shadow: $tbar-ribble-animation-shadow-frame-end;
  }
}

@include export-module('toolbar-layout') {
  .e-toolbar {
    border-radius: $tbar-radius;
    display: block;
    height: $tbar-nrml-size;
    min-height: $tbar-nrml-size;
    position: relative;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;

    &.e-spacer-toolbar {

      .e-toolbar-items {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;

        &:not(.e-toolbar-multirow) {
          position: absolute;
        }
      }

      &.e-pop-mode {

        .e-toolbar-items {
          width: calc(100% - $tbar-nav-nrml-width);
        }
      }
    }


    .e-blazor-toolbar-items {
      position: absolute;
      top: -9999px;
      visibility: hidden;
    }

    &.e-control[class *= 'e-toolbar'] {
      box-sizing: content-box;
    }

    &.e-corner {
      border-radius: $tbar-border-radius;
    }

    &.e-hidden {
      display: none;
    }

    .e-toolbar-items {
      border-radius: $tbar-radius 0 0 $tbar-radius;
      display: inline-flex;
      height: 100%;
      vertical-align: middle;
      align-items: center;

      &.e-toolbar-multirow {
        margin-bottom: 1px;
        margin-left: $tbar-multirow-items-mrgn-small;
        margin-right: $tbar-multirow-items-mrgn-small;
        white-space: normal;
        flex-wrap: wrap;

        .e-toolbar-item {

          &:not(.e-separator) {
            margin: $tbar-multirow-item-top-btm-mrgn-small;
          }

          &.e-separator {

            &.e-multirow-separator,
            &.e-hidden {
              display: none;
            }
          }
        }
      }

      &.e-multirow-pos {

        .e-toolbar-left,
        .e-toolbar-center,
        .e-toolbar-right {
          display: inline;
        }
      }

      &.e-tbar-pos {
        display: block;

        .e-toolbar-left,
        .e-toolbar-center,
        .e-toolbar-right {
          display: table;
          height: 100%;
          top: 0;
        }

        .e-toolbar-right,
        .e-toolbar-left {
          position: absolute;
        }

        .e-toolbar-right {
          right: 0;
        }

        .e-toolbar-left {
          left: 0;
        }

        .e-toolbar-center {
          margin: 0 auto;
        }
      }

      .e-toolbar-left,
      .e-toolbar-center,
      .e-toolbar-right {
        display: inline-block;
      }

      .e-toolbar-left,
      &:not(.e-tbar-pos):not(.e-toolbar-multirow) {

        .e-toolbar-item:first-child {
          margin-left: $tbar-item-nrml-mrgn;
        }
      }

      &:first-child:not(.e-toolbar-multirow) {

        > .e-toolbar-item:last-child,
        > .e-toolbar-right .e-toolbar-item:last-child {
          margin-right: $tbar-item-nrml-mrgn;
        }
      }

      .e-hscroll-bar .e-hscroll-content {
        touch-action: pan-y pinch-zoom;

        > .e-toolbar-item:last-child {
          margin-right: $tbar-item-nrml-mrgn;
        }

        .e-toolbar-center .e-toolbar-item,
        .e-toolbar-right .e-toolbar-item {
          margin: 0;
        }

        .e-toolbar-right .e-toolbar-item:last-child {
          margin-right: $tbar-item-nrml-mrgn;
        }
      }
    }

    .e-toolbar-item {
      align-content: center;
      align-items: center;
      cursor: pointer;
      display: inline-flex;
      min-height: $tbar-nrml-item-size;
      vertical-align: middle;
      width: auto;
      flex: 0 0 auto;

      &.e-spacer {
        flex-grow: 1;
      }

      .e-tbar-btn {
        display: flex;
        align-items: center;
        vertical-align: middle;
        justify-content: center;
        margin: $tbar-btn-nrml-mrgn;
        min-height: $tbar-btn-nrml-minheight;
        min-width: $tbar-btn-nrml-minwidth;
        padding: $tbar-btn-nrml-padding;
        border-radius: $tbar-nrml-btn-border-radius;
        line-height: $tbar-btn-nrml-line-height;
        border: $tbar-btn-border;
        cursor: pointer;
        font-size: $tbar-btn-font-size;
        font-weight: $tbar-btn-weight;
        overflow: hidden;
        text-align: center;
        text-decoration: none;
        text-transform: none;

        &.e-tbtn-txt {

          .e-icons.e-btn-icon {
            padding: $tbar-btn-icn-nrml-padding;

            &.e-icon-right {
              padding: $tbar-btn-icn-right-nrml-padding;
            }
          }
        }

        .e-icons.e-btn-icon {
          margin: $tbar-zero-value;
          min-width: $tbar-btn-icon-nrml-width;
          width: auto;
          font-size: $tbar-btn-font-size;
          line-height: $tbar-btn-icon-nrml-line-height;
        }

        &:hover,
        &:focus,
        &:active {
          padding: $tbar-nrml-btn-focus-padding;
        }

        &:focus {
          outline: $tbar-nrml-btn-focus-outline;
        }

        div {
          vertical-align: middle;
        }

        .e-tbar-btn-text {
          font-size: $tbar-btn-txt-font-size;
          padding: $btn-txt-nrml-padding;
        }
      }

      &:not(.e-separator):not(.e-spacer) {
        height: inherit;
        min-width: $tbar-item-nrml-minwidth;
        padding: $tbar-item-nrml-padding;
      }

      &.e-separator {
        margin: $tbar-separator-nrml-mrgn;
        min-height: $tbar-separator-nrml-minheight;
        min-width: 1px;
        height: $tbar-separator-nrml-height;

        + .e-separator,
        &:last-of-type,
        &:first-of-type {
          display: none;
        }
      }

      &.e-popup-text {

        .e-tbar-btn.e-btn.e-tbtn-txt {

          .e-icons.e-btn-icon {
            padding: $tbar-popup-text-btn-icon-padding;
          }
        }
      }

      > * {
        text-overflow: ellipsis;
      }

      &.e-hidden {
        display: none;
      }

      input[type = 'checkbox'] {
        height: auto;
      }
    }

    &.e-vertical {
      display: flex;
      flex-direction: column;

      .e-toolbar-items {

        .e-vscroll-bar .e-vscroll-content {
          touch-action: pan-x pinch-zoom;
        }

        &.e-tbar-pos {

          .e-toolbar-left,
          .e-toolbar-center,
          .e-toolbar-right {
            height: auto;
          }

          .e-toolbar-left {
            left: auto;
            right: auto;
            top: 0;
          }

          .e-toolbar-right {
            bottom: 0;
            left: auto;
            right: auto;
          }
        }

        &:not(.e-tbar-pos) .e-toolbar-item {

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }

        .e-toolbar-item {
          display: flex;
          height: auto;

          &:not(.e-separator) {
            min-width: 33px;
          }

          &.e-separator {
            height: auto;
            margin: $tbar-separator-vertical-nrml-mrgn;
            min-height: auto;
          }
        }
      }

      .e-hor-nav {
        bottom: 0;
        height: auto;
        left: 0;
        min-height: 40px;
        min-width: 50px;
        right: auto;
        top: auto;
        width: auto;
      }

      &.e-rtl.e-tbar-pos {

        .e-toolbar-left {
          bottom: 0;
          top: auto;
        }

        .e-toolbar-right {
          bottom: auto;
          top: 0;
        }
      }
    }

    .e-hor-nav {
      align-items: center;
      border-radius: 0 $tbar-radius $tbar-radius 0;
      cursor: pointer;
      display: flex;
      height: 100%;
      min-height: $tbar-nrml-items-size;
      overflow: hidden;
      position: absolute;
      right: $tbar-zero-value;
      top: $tbar-zero-value;
      width: $tbar-nav-nrml-width;

      &.e-ie-align {
        display: table;
      }
    }

    .e-popup-down-icon.e-icons,
    .e-popup-up-icon.e-icons {
      display: flex;
      text-align: center;
      vertical-align: middle;
      align-items: center;
      justify-content: center;
      width: 100%;
      font-size: $tbar-popup-icon-font-size;

      @if ($skin-name == 'tailwind3') {
        color: $tbar-active-font-color;
      }
      @else {
        color: $tbar-default-icon-color;
      }
    }

    &.e-toolpop {
      overflow: visible;

      .e-toolbar-items {

        .e-toolbar-item {

          &.e-popup-text .e-tbar-btn-text {
            display: none;
          }
        }
      }
    }

    .e-toolbar-pop {
      border-radius: $tbar-pop-radius;
      overflow: hidden;
      padding: $tbar-popup-padding;
      position: absolute;

      .e-toolbar-item  {
        display: flex;
        height: $tbar-item-height;
        justify-content: center;
        min-height: $tbar-btn-pop-nrml-minheight;

        &.e-toolbar-popup.e-hidden {
          display: none;
        }

        .e-tbar-btn.e-btn {
          min-height: $tbar-btn-pop-nrml-minheight;
          min-width: 100%;
          padding: $tbar-pop-btn-nrml-padding;
          border: $tbar-popup-btn-border;
          border-radius: $tbar-popup-btn-border-radius;
          justify-content: flex-start;

          .e-icons.e-btn-icon {
            margin: $tbar-zero-value;
            padding: $tbar-pop-icon-nrml-padding;
            width: auto;
          }

          .e-tbar-btn-text {
            padding: $tbar-pop-btn-txt-nrml-pad;
          }
        }

        &:not(.e-separator) {
          height: $tbar-item-height;
          min-width: 34px;
          padding: $tbar-item-pop-nrml-padding;
        }

        > * {
          height: 100%;
          min-width: 100%;
          text-overflow: ellipsis;
        }

        &.e-tbtn-align .e-btn.e-control .e-icons.e-btn-icon {
          min-width: 100%;
        }
      }

      .e-toolbar-text .e-tbar-btn-text {
        display: none;
      }

      .e-toolbar-popup,
      .e-toolpopup {
        text-align: center;
      }
    }

    &.e-extended-toolbar {
      overflow: visible;

      &.e-tbar-extended {
        border-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      .e-toolbar-extended {
        border-top: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        min-height: $tbar-nrml-size;
        padding-bottom: 0;
        padding-left: $tbar-item-nrml-mrgn;
        padding-right: $tbar-item-nrml-mrgn;
        padding-top: 0;
        margin-left: -1px;
        box-shadow: $tbar-box-shadow;
        display: inline;
        white-space: normal;

        .e-toolbar-item {
          display: inline-flex;
          min-height: $tbar-nrml-item-size;

          .e-tbar-btn.e-btn,
          .e-tbar-btn.e-btn.e-control {
            min-height: $tbar-btn-nrml-minheight;
            min-width: $tbar-btn-nrml-minwidth;
            padding: $tbar-btn-nrml-padding;

            .e-tbar-btn-text {
              padding: $btn-txt-nrml-padding;
              font-size: $tbar-btn-txt-font-size;
            }

            .e-icons.e-btn-icon:not(.e-toolbar-pop) {
              display: flex;
              align-items: center;
              vertical-align: middle;
              justify-content: center;
              padding: $tbar-ext-btn-icon-padding;
              font-size: $tbar-ext-btn-icon-font-size;
            }

            &.e-tbtn-txt {

              .e-icons.e-btn-icon:not(.e-toolbar-pop) {
                padding: $tbar-btn-icn-nrml-padding;
              }

              .e-icons.e-icon-right.e-btn-icon:not(.e-toolbar-pop) {
                padding: $tbar-btn-icn-right-nrml-padding;
              }
            }

            &:hover,
            &:focus,
            &:active {
              padding: $tbar-ext-btn-focus-padding;
            }
          }

          &:not(.e-separator) {
            min-width: $tbar-item-nrml-minwidth;
            padding: $tbar-item-nrml-padding;
          }

          &.e-separator {
            min-height: $tbar-btn-icon-nrml-height;

            &.e-extended-separator {
              display: none;
            }
          }

          &.e-toolbar-text .e-tbar-btn-text {
            display: none;
          }
        }

        &.e-popup-close {
          display: none;
        }

        &.e-popup-open {
          display: inline;
        }

        &.e-toolbar-pop {
          width: inherit;

          .e-toolbar-item {

            .e-tbar-btn {
              cursor: pointer;
              font-size: $tbar-btn-font-size;
              overflow: hidden;
              padding: $tbar-btn-nrml-padding;

              .e-icons.e-btn-icon {
                font-size: 14px;
              }
            }

            &:not(.e-separator) {
              height: auto;
            }
          }

          .e-toolbar-item > * {
            align-self: center;
            text-overflow: ellipsis;
          }
        }
      }

      .e-toolbar-items {

        .e-toolbar-item {

          &.e-popup-text .e-tbar-btn-text {
            display: none;
          }

          &.e-separator:last-of-type {
            display: inline-flex;
          }
        }
      }

      .e-hor-nav.e-ie-align {
        display: table;
      }
    }

    &.e-rtl {

      &:not(.e-spacer-toolbar) {

        .e-toolbar-items:not(.e-tbar-pos) {

          .e-toolbar-item:first-child {
            margin-right: $tbar-item-nrml-mrgn;
          }
        }
      }

      .e-toolbar-item {

        .e-tbar-btn {

          &.e-tbtn-txt {

            .e-icons.e-btn-icon {
              padding: $tbar-rtl-btn-icn-nrml-padding;

              &.e-icon-right {
                padding: $tbar-rtl-btn-icn-right-nrml-padding;
              }
            }
          }

          .e-tbar-btn-text {
            padding: $btn-rtl-txt-nrml-padding;
          }
        }
      }

      .e-hscroll-bar .e-hscroll-content {

        > .e-toolbar-item:last-child {
          margin-left: $tbar-item-nrml-mrgn;
          margin-right: initial;
        }

        .e-toolbar-center .e-toolbar-item,
        .e-toolbar-right .e-toolbar-item {
          margin: 0;
        }

        .e-toolbar-right .e-toolbar-item:last-child {
          margin-right: $tbar-item-nrml-mrgn;
        }
      }

      .e-toolbar-items {

        &.e-tbar-pos {

          .e-toolbar-left {
            left: auto;
            right: 0;
          }

          .e-toolbar-right {
            left: 0;
            right: auto;
          }
        }

        .e-toolbar-left {

          .e-toolbar-item {

            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              margin-left: $tbar-item-nrml-mrgn;
            }
          }
        }

        &:not(.e-tbar-pos) {
          .e-toolbar-item:first-child {
            margin-left: 0;
          }
        }

        &:first-child {

          > .e-toolbar-item:last-child,
          > .e-toolbar-right .e-toolbar-item {

            &:last-child {
              margin-right: 0;
            }

            &:first-child {
              margin-right: $tbar-item-nrml-mrgn;
            }
          }
        }
      }

      .e-hor-nav {
        left: $tbar-zero-value;
        right: auto;
        border-radius: $tbar-radius 0 0 $tbar-radius;
      }
    }

    &.e-toolpop.e-rtl {

      .e-toolbar-items:not(.e-tbar-pos) {

        .e-toolbar-center .e-toolbar-item,
        .e-toolbar-right .e-toolbar-item {
          margin: 0;
        }

        .e-toolbar-right .e-toolbar-item:last-child {
          margin: 0;
          margin-left: $tbar-item-nrml-mrgn;
        }
      }
    }

    &.e-extended-toolbar.e-rtl {

      .e-hor-nav {
        left: 0;
        right: auto;
      }

      .e-toolbar-extended {
        padding-right: $tbar-item-nrml-mrgn;
        margin-left: 0;

        .e-toolbar-item .e-tbar-btn.e-btn .e-icon-left {
          padding-left: 0;
        }
      }
    }
  }
}


@include export-module('toolbar-theme') {
  /* stylelint-disable property-no-vendor-prefix */
  .e-toolbar {
    -webkit-tap-highlight-color: $tbar-tab-highlight-color;
    background: $tbar-default-bg;
    border: $tbar-border-size $tbar-border-type $tbar-default-border;
    box-shadow: $tbar-box-shadow;

    .e-toolbar-items {
      background: $tbar-default-bg;
    }

    .e-toolbar-item {

      .e-tbar-btn {
        background: $tbar-items-default-bg;
        box-shadow: $tbar-btn-box-shadow;
        color: $tbar-default-font;
        border: $tbar-btn-border;

        .e-icons {
          color: $tbar-default-icon-color;
        }

        .e-tbar-btn-text {
          color: $tbar-btn-text-color;
        }

        &.e-flat.e-active {
          background: $tbar-btn-pressed-bg;
          box-shadow: $tbar-flat-btn-active-box-shadow;
          .e-tbar-btn-text {
            color: $tbar-btn-pressed-text-color;
          }

          &:focus {
            box-shadow: $tbar-btn-pressed-focus-box-shadow;
          }
        }

        &:focus {
          background: $tbar-focus-bg;
          border-radius: $tbar-btn-border-radius;
          color: $tbar-hover-font;
          border-color: $tbar-btn-focus-border-color;
          border-style: $tbar-border-nav-type;
          border-width: $tbar-btn-hover-border-size;

          @if ($skin-name == 'fluent2' or $skin-name == 'tailwind3') {
            box-shadow: none;
          }
          @else {
            box-shadow: $tbar-ext-btn-focus-box-shadow;
          }

          .e-icons {
            color: $tbar-btn-icons-focus-color;
          }

          .e-tbar-btn-text {
            color: $tbar-btn-text-focus-color;

            @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
              color: $tbar-btn-icons-focus-color;
            }
          }
        }

        @if ($skin-name == 'fluent2' or $skin-name == 'tailwind3') {
          &:focus-visible {
            background: $tbar-default-bg;
            box-shadow: $tbar-ext-btn-focus-box-shadow;

            .e-icons {
              color: $tbar-default-icon-color;
            }

            .e-tbar-btn-text {
              color: $tbar-default-font;
            }
          }
        }

        &:hover {
          background: $tbar-hover-bg;
          border-color: $tbar-hover-border-color;
          border-style: $tbar-border-nav-type;
          border-width: $tbar-btn-hover-border-size;
          border-radius: $tbar-btn-border-radius;
          color: $tbar-hover-font;

          .e-icons {
            color: $tbar-btn-icons-focus-color;

            @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
              color: $tbar-btn-text-focus-color;
            }
          }

          .e-tbar-btn-text {
            color: $tbar-btn-text-focus-color;
          }

          &:active {
            .e-icons {
              color: $tbar-btn-hover-active-icons-color;
            }

            .e-tbar-btn-text {
              color: $tbar-btn-hover-active-text-color;
            }
          }
        }

        &:active {
          background: $tbar-btn-active-bg;
          border-color: $tbar-press-border-color;
          border-style: $tbar-border-nav-type;
          border-width: $tbar-btn-hover-border-size;
          border-radius: $tbar-btn-border-radius;
          box-shadow: $tbar-btn-pressed-box-shadow;
          color: $tbar-press-font;

          .e-icons {
            color: $tbar-btn-active-icons-color;
          }

          .e-tbar-btn-text {
            color: $tbar-btn-active-text-color;
          }
        }
      }

      &.e-separator {
        border: $tbar-separator-border-type $tbar-separator-border;
        border-width: $tba-horizontal-separator;
      }

      &.e-overlay {
        background: $tbar-default-bg;
        opacity: $tbar-btn-overlay-opacity;
        color: $tbar-default-icon-overlay;

        .e-tbar-btn-text {
          color: $tbar-default-font-overlay;
        }

        .e-icons {
          color: $tbar-default-icon-overlay;
        }
      }
    }

    &.e-vertical {

      .e-hor-nav {
        border: $tbar-border-nav-type $tbar-default-border;
        border-width: $tbar-popup-vertical-border-width;
      }

      &.e-rtl .e-hor-nav {
        border: $tbar-border-nav-type $tbar-default-border;
        border-width: $tbar-popup-vertical-rtl-border-width;
      }

      .e-toolbar-items .e-toolbar-item {

        &.e-separator {
          border-width: $tba-vertical-separator;
        }
      }
    }

    .e-hor-nav {
      background: $tbar-default-bg;
      border: $tbar-border-nav-type $tbar-default-border;
      border-width: $tbar-popup-border-width;

      &::after {
        @include tbar-btn-animation;
      }

      &.e-nav-active {
        &:active,
        &:focus,
        &:hover {
          border-bottom-right-radius: $tbar-popup-nav-active-border-bottom-right-radius;
        }
      }

      &:active {
        border: $tbar-nav-press-border;
        box-shadow: $tbar-nav-pressed-box-shadow;
        color: $tbar-select-font;
        background: $tbar-popup-nav-active-bg;

        .e-icons {
          color: $tbar-popup-nav-active-icons-color;
        }

        &::after {
          @include tbar-btn-animation-after;
        }
      }

      &:hover {
        background: $tbar-popup-nav-hover-bg;
        border-left: $tbar-nav-hover-border;
        color: $tbar-popup-nav-hover-color;

        .e-icons {
          color: $tbar-popup-nav-hover-icons-color;
        }

        &:not(.e-nav-active) {
          border-color: $tbar-popup-nav-hover-border-color;
          border-style: $tbar-border-nav-type;
          border-width: $tbar-popup-nav-hover-border-size;
        }

        &:active {
          background: $tbar-popup-nav-hover-active-bg;
          border-color: $tbar-popup-nav-hover-active-border-color;
          border-style: $tbar-border-nav-type;
          border-width: $tbar-popup-nav-hover-active-border-size;
        }
      }

      &:focus {
        background: $tbar-popup-nav-focus-bg;
        color: $tbar-popup-nav-focus-color;
        border-color: $tbar-popup-nav-focus-border-color;
        border-style: $tbar-border-nav-type;
        border-width: $tbar-popup-nav-focus-border-size;

        .e-icons {
          color: $tbar-popup-nav-hover-icons-color;
        }
      }
    }

    &.e-toolpop {

      .e-hor-nav.e-nav-active,
      .e-hor-nav.e-nav-active:not(.e-expended-nav) {
        background: $tbar-pressed-bg;
        box-shadow: $tbar-nav-pressed-box-shadow;
        border-color: $tbar-popup-nav-pressed-border-color;
        border-style: $tbar-border-nav-type;
        border-width: $tbar-popup-nav-pressed-border-size;

        &:focus {
          border-color: $tbar-popup-nav-pressed-focus-border-color;
          border-style: $tbar-border-nav-type;
          border-width: $tbar-popup-nav-pressed-focus-border-size;
        }

        .e-icons {
          color: $tbar-active-font-color;

          &:active {
            color: $tbar-popup-nav-pressed-icons-active-color;
          }
        }
      }
    }

    .e-toolbar-pop {
      background: $tbar-pop-bg;
      border: $tbar-border-size $tbar-border-type $tbar-default-border;
      box-shadow: $tbar-pop-box-shadow;

      .e-toolbar-item {

        .e-tbar-btn {
          background: $tbar-popup-btn-bg;

          &:hover {
            background: $tbar-popup-btn-hover-bg;
            box-shadow: $tbar-popup-btn-hover-box-shadow;
            border-color: $tbar-hover-border-color;
            border-style: $tbar-border-nav-type;
            border-width: $tbar-popup-btn-hover-border-size;

            @if $skin-name == 'bootstrap5.3' {
              .e-tbar-btn-text,
              .e-icons.e-btn-icon {
                color: $tbar-default-font;
              }
            }
          }

          &:active {
            background: $tbar-popup-btn-active-bg;
            box-shadow: $tbar-popup-btn-active-box-shadow;
            border-color: $tbar-press-border-color;
            border-style: $tbar-border-nav-type;
            border-width: $tbar-popup-btn-hover-border-size;
          }

          &:focus {
            background: $tbar-popup-btn-focus-bg;
            border-color: $tbar-btn-focus-border-color;
            border-style: $tbar-border-nav-type;
            border-width: $tbar-popup-btn-hover-border-size;

            @if ($skin-name == 'fluent2' or $skin-name == 'bootstrap5.3' or $skin-name == 'tailwind3') {
              box-shadow: none;
            }
            @else {
              box-shadow: $tbar-popup-btn-focus-box-shadow;
            }

            @if $skin-name == 'bootstrap5.3' {
              .e-tbar-btn-text,
              .e-icons.e-btn-icon {
                color: $tbar-btn-active-icons-color;
              }
            }
          }

          @if ($skin-name == 'fluent2' or $skin-name == 'tailwind3') {
            &:focus-visible {
              background: $tbar-popup-btn-focus-bg;
              box-shadow: $tbar-popup-btn-focus-box-shadow;

              .e-icons {
                color: $tbar-popup-nav-hover-icons-color;
              }

              .e-tbar-btn-text {
                color: $tbar-popup-btn-text-focus-color;
              }
            }
          }
        }

        .e-btn:focus {
          outline: $tbar-popup-btn-focus-outline;
        }

        &:not(.e-separator) {
          background: $tbar-item-pop-bg-color;
        }
      }
    }

    &.e-extended-toolbar {

      .e-toolbar-extended {

        .e-toolbar-item {

          .e-tbar-btn {
            background: $tbar-default-bg;
            box-shadow: $tbar-btn-box-shadow;
            border: $tbar-ext-btn-border;

            &:focus {
              background: $tbar-focus-bg;
              border-radius: $tbar-btn-border-radius;
              border-color: $tbar-btn-focus-border-color;
              border-style: $tbar-border-nav-type;
              border-width: $tbar-btn-hover-border-size;

              @if ($skin-name == 'fluent2' or $skin-name == 'tailwind3') {
                box-shadow: none;
              }
              @else {
                box-shadow: $tbar-ext-btn-focus-box-shadow;
              }
            }

            @if ($skin-name == 'fluent2' or $skin-name == 'tailwind3') {
              &:focus-visible {
                background: $tbar-default-bg;
                box-shadow: $tbar-ext-btn-focus-box-shadow;

                .e-icons {
                  color: $tbar-default-icon-color;
                }

                .e-tbar-btn-text {
                  color: $tbar-default-font;
                }
              }
            }

            &:hover {
              background: $tbar-hover-bg;
              border-radius: $tbar-btn-border-radius;
              border-color: $tbar-ext-btn-hover-border-color;
              border-style: $tbar-border-nav-type;
              border-width: $tbar-btn-hover-border-size;
            }

            &:active {
              background: $tbar-btn-press-bg;
              border-radius: $tbar-btn-border-radius;
              box-shadow: $tbar-btn-pressed-box-shadow;
              border-color: $tbar-press-border-color;
              border-style: $tbar-border-nav-type;
              border-width: $tbar-btn-hover-border-size;
            }
          }

          &.e-separator {
            border: $tbar-separator-border-type $tbar-separator-border;
            border-width: $tba-horizontal-separator;
          }

          &.e-overlay {
            background: $tbar-default-bg;
          }
        }
      }

      .e-toolbar-pop {
        background: $tbar-default-bg;
      }
    }

    &.e-rtl .e-hor-nav {
      background: $tbar-default-bg;
      border: $tbar-border-nav-type $tbar-default-border;
      border-width: $tbar-popup-rtl-border-width;

      &:not(.e-nav-active):hover {
        background: $tbar-hover-bg;
        color: $tbar-hover-font;
        border: $tbar-border-nav-type $tbar-default-border;
        border-width: $tbar-popup-rtl-border-width;
      }
    }
  }
}


@include export-module('toolbar-material-icons') {
  #{&}.e-toolbar {

    .e-popup-down-icon::before {
      content: '\e916';
      line-height: normal;
    }

    .e-popup-up-icon::before {
      content: '\e910';
      line-height: normal;
    }
  }
}

@include export-module('toolbar-bigger') {
  .e-bigger .e-toolbar,
  .e-toolbar.e-bigger {
    height: $tbar-bgr-size;
    min-height: $tbar-bgr-size;

    .e-toolbar-items {

      &:not(.e-tbar-pos):not(.e-toolbar-multirow),
      .e-toolbar-left {

        .e-toolbar-item:first-child {
          margin-left: $tbar-item-bgr-mrgn;
        }
      }

      &:first-child:not(.e-toolbar-multirow) {

        > .e-toolbar-item:last-child,
        > .e-toolbar-right .e-toolbar-item:last-child {
          margin-right: $tbar-item-bgr-mrgn;
        }
      }

      .e-hscroll-bar .e-hscroll-content {

        > .e-toolbar-item:last-child {
          margin-right: $tbar-item-bgr-mrgn;
        }

        .e-toolbar-right .e-toolbar-item {

          &:last-child {
            margin-right: $tbar-item-bgr-mrgn;
          }
        }
      }

      &.e-toolbar-multirow {
        margin-left: $tbar-multirow-items-mrgn-bigger;
        margin-right: $tbar-multirow-items-mrgn-bigger;

        .e-toolbar-item:not(.e-separator) {
          margin: $tbar-multirow-item-top-btm-mrgn-bigger;
        }
      }

      .e-toolbar-item {

        .e-tbar-btn.e-btn,
        .e-tbar-btn.e-btn.e-control {

          &:focus,
          &:hover,
          &:active {
            padding: $tbar-btn-bgr-focus-padding;
          }
        }
      }
    }

    .e-toolbar-item {
      min-height: $tbar-bgr-items-size;

      .e-tbar-btn.e-btn,
      .e-tbar-btn.e-btn.e-control {
        min-height: $tbar-btn-bgr-minheight;
        min-width: $tbar-btn-bgr-minwidth;
        padding: $tbar-btn-bgr-padding;
        line-height: $tbar-btn-bgr-line-height;

        .e-tbar-btn-text {
          padding: $btn-txt-bgr-padding;
          font-size: $tbar-bgr-btn-text-font-size;
        }

        .e-icons {
          font-size: $tbar-icons-bgr-font-size;

          &.e-btn-icon {
            font-size: $tbar-bgr-btn-icon-font-size;
            line-height: $tbar-btn-icon-bgr-line-height;
          }
        }

        &.e-tbtn-txt {

          .e-icons.e-btn-icon {
            padding: $tbar-btn-icn-bgr-padding;
          }

          .e-icons.e-icon-right.e-btn-icon {
            padding: $tbar-btn-icn-right-bgr-padding;
          }
        }
      }

      &:not(.e-separator):not(.e-spacer) {
        min-width: $tbar-bgr-item-size;
        padding: $tbar-item-bgr-padding;
      }

      &.e-separator {
        height: $tbar-separator-bgr-height;
        margin: $tbar-separator-bgr-mrgn;
        min-height: $tbar-separator-bgr-minheight;
      }

      &.e-popup-text {

        .e-tbar-btn.e-btn,
        .e-tbar-btn.e-btn.e-control {

          &.e-tbtn-txt {

            .e-icons.e-btn-icon {
              padding: $tbar-popup-bgr-text-btn-icon-padding;
            }
          }
        }
      }
    }

    .e-hor-nav {
      min-height: $tbar-bgr-items-size;
      min-width: $tbar-nav-bgr-width;
    }

    &.e-vertical {

      .e-toolbar-items {

        &:not(.e-tbar-pos) .e-toolbar-item {

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .e-toolbar-item {

        &:not(.e-separator) {
          min-height: 38px;
        }

        &.e-separator {
          height: auto;
          margin: $tbar-separator-vertical-bgr-mrgn;
          min-height: auto;
        }
      }

      .e-hor-nav {
        min-height: 40px;
        min-width: 50px;
      }
    }

    .e-toolbar-pop {

      .e-toolbar-item {
        height: $tbar-popup-bgr-height;

        &:not(.e-separator) {
          min-width: 48px;
          padding: $tbar-item-pop-bgr-padding;
          min-height: $tbar-btn-pop-bgr-minheight;
        }

        .e-tbar-btn.e-btn {
          min-height: $tbar-btn-pop-bgr-minheight;
          padding: $tbar-pop-btn-bgr-padding;
          min-width: 100%;

          .e-icons.e-btn-icon {
            margin-left: $tbar-zero-value;
            padding: $tbar-pop-icon-bgr-padding;
            font-size: $tbar-popup-bgr-btn-icon-font-size;
          }

          .e-tbar-btn-text {
            padding: $tbar-pop-btn-txt-bgr-pad;
            font-size: $tbar-popup-bgr-btn-text-font-size;
          }
        }
      }
    }

    &.e-extended-toolbar {

      .e-toolbar-extended {
        min-height: $tbar-bgr-size;
        padding-bottom: 0;
        padding-left: $tbar-item-bgr-mrgn;
        padding-right: $tbar-item-bgr-mrgn;
        padding-top: 0;

        .e-toolbar-item {
          min-height: $tbar-bgr-items-size;

          .e-tbar-btn.e-btn,
          .e-tbar-btn.e-btn.e-control {
            min-height: $tbar-btn-bgr-minheight;
            min-width: $tbar-btn-bgr-minwidth;
            padding: $tbar-btn-bgr-padding;

            @if ($skin-name == 'tailwind3') {
              .e-icons.e-btn-icon:not(.e-toolbar-pop) {
                font-size: $tbar-bgr-btn-icon-font-size;
              }

              &.e-tbtn-txt .e-icons.e-btn-icon:not(.e-toolbar-pop),
              .e-control.e-tbtn-txt .e-icons.e-btn-icon:not(.e-toolbar-pop) {
                padding: $tbar-btn-icn-bgr-padding;
              }
            }

            .e-tbar-btn-text {
              padding: $btn-txt-bgr-padding;
              @if ($skin-name == 'tailwind3') {
                font-size: $tbar-bgr-btn-text-font-size;
              }
            }

            &:hover,
            &:focus,
            &:active {
              padding: $tbar-bgr-btn-focus-padding;
            }
          }

          &:not(.e-separator) {
            min-width: $tbar-bgr-item-size;
            padding: $tbar-item-bgr-padding;
          }

          &.e-separator {
            height: $tbar-separator-bgr-height;
            margin: $tbar-separator-bgr-mrgn;
            min-height: $tbar-separator-bgr-minheight;
          }
        }
      }

      &.e-tbar-extended {
        border-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        .e-toolbar-extended {
          border-top: 0;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
      }
    }

    &.e-rtl {

      .e-toolbar-item {

        .e-tbar-btn.e-btn {

          &.e-tbtn-txt {

            .e-icons.e-btn-icon {
              padding: $tbar-rtl-btn-icn-bgr-padding;

              &.e-icon-right {
                padding: $tbar-rtl-btn-icn-right-bgr-padding;
              }
            }
          }

          .e-tbar-btn-text {
            padding: $btn-rtl-txt-bgr-padding;
          }
        }
      }

      .e-toolbar-items {

        .e-hscroll-bar .e-hscroll-content {

          > .e-toolbar-item:last-child {
            margin-left: $tbar-item-bgr-mrgn;
            margin-right: initial;
          }

          .e-toolbar-center .e-toolbar-item,
          .e-toolbar-right .e-toolbar-item {
            margin: 0;
          }

          .e-toolbar-right .e-toolbar-item:last-child {
            margin-left: $tbar-item-bgr-mrgn;
            margin-right: 0;
          }
        }

        &:not(.e-tbar-pos) {

          .e-toolbar-item:first-child {
            margin-left: 0;
            margin-right: $tbar-item-bgr-mrgn;
          }
        }
      }
    }

    &.e-extended-toolbar.e-rtl {

      .e-toolbar-extended {
        padding-right: $tbar-item-bgr-mrgn;
      }
    }
  }
}

@import "../base/base.gd.scss";
@import "../inputs/input.gd.scss";
@import "../popups/popup.gd.scss";
@import "../buttons/button.gd.scss";
@import "../calendars/calendar.gd.scss";

$datepicker-icon-color: $accent !default;
$datepicker-active-icon-color: $datepicker-icon-color !default;
$datepicker-popup-box-shadow: 0 5px 5px -3px rgba($grey-light-font, .2), 0 8px 10px 1px rgba($grey-light-font, .14), 0 3px 14px 2px rgba($grey-light-font, .12) !default;
$datepicker-input-border-style: none !default;
$datepicker-calendar-border-style: none !default;
$datepicker-icon: '\e901' !default;
$datepicker-popup-bg-color: $grey-white !default;
$datepicker-popup-border: none !default;
$datepicker-icon-font-size: 16px !default;
$datepicker-icon-container-min-height: 24px !default;
$datepicker-icon-container-min-width: 24px !default;
$datepicker-bigger-icon-container-min-height: 26px !default;
$datepicker-bigger-icon-container-min-width: 26px !default;
$datepicker-bigger-icon-font-size: 18px !default;
$datepicker-overlay: rgba($grey-black, .6) !default;
$datepicker-icon-normal-margin: 0 0 4px 0 !default;
$datepicker-icon-bigger-margin: 5px 0 6px 0 !default;
$datepicker-icon-hover-color: $hover-bg-color !default;
$datepicker-icon-border-radius: 50% !default;
$datepicker-popup-border-radius: 0 !default;
$datepicker-box-sizing: border-box !default;
$datepicker-othermonth-row: none !default;
$datepicker-modal-header-bg: $accent !default;
$datepicker-modal-header-color: $grey-white !default;
$datepicker-modal-header-display: block !default;
$datepicker-calendar-tbody-landscape-height: 130px !default;

// mouse small icon
$datepicker-small-icon-font-size: 14px !default;

// Touch small icon
$datepicker-bigger-small-icon-font-size: 18px !default;
$zero-value: 0 !default;
$modal-year-font-size: 14px !default;
$modal-year-font-weight: 500 !default;
$modal-year-line-height: 32px !default;
$modal-month-font-size: 20px !default;
$modal-month-font-weight: 500 !default;
$modal-month-line-height: 32px !default;

// modal dialog dimensions and styles
$modal-portrait-year-font-size: 4vw !default;
$modal-portrait-header-year-margin: 12vh 0 0 0 !default;
$modal-portrait-month-font-size: 5vw !default;
$modal-portrait-header-padding: 2vh 6vw !default;
$modal-portrait-month-header-padding: 2vh 2vw !default;
$modal-portrait-month-header-vertical-align: middle !default;
$modal-month-header-line-height: 5vh !default;
$modal-month-header-title-line-height: inherit !default;

$modal-header-border-style: none !default;
$modal-header-border-width: 0 !default;

// Touch modal popup cancel icon style
$modal-portrait-cancel-icon-float-style: left !default;
$modal-portrait-cancel-icon-padding: 2px !default;
$modal-portrait-prev-icon-margin: 0 !default;
$modal-portrait-next-icon-margin: 0 !default;
$modal-portrait-header-title-margin: 5vw !default;
$modal-portrait-header-title-position: inherit !default;
$modal-portrait-header-title-text: left !default;
$modal-portrait-header-title-width: 60vw !default;
$modal-portrait-cancel-icon-color: inherit !default;
$modal-header-day-line-height: 6vw !default;

//Touch modal popup today styles
$modal-portrait-today-float-style: right !default;
$modal-today-text-color: inherit !default;
$modal-portrait-footer-display-style: none !default;
$modal-header-border-color: rgba($grey-black, .12) !default;
$modal-portrait-icon-float: right !default;

// Touch modal popup styles
$modal-portrait-header-height: 20vh !default;
$modal-portrait-header-month-height: 10vh !default;
$modal-portrait-calendar-container-height: 79vh !default;
$modal-portrait-calendar-min-height: 100% !default;
$modal-portrait-calendar-min-width: 100% !default;
$modal-portrait-calendar-height: 100% !default;
$modal-portrait-calendar-content-height: 69vh !default;
$modal-portrait-calendar-tabel-height: 69vh !default;

// Touch modal popup landscape styles
$datepicker-modal-popup-landscape-max-width: 100% !default;
$modal-landscape-header-height: 30vh !default;
$modal-landscape-header-big-height: 25vh !default;
$modal-landscape-calendar-content-height: 60vh !default;
$modal-landscape-calendar-content-table-height: 65vh !default;
$modal-landscape-calendar-content-big-height: 65vh !default;
$modal-landscape-header-year-margin: 12vh 0 0 0 !default;
$modal-landscape-prev-icon-margin: $modal-portrait-prev-icon-margin !default;
$modal-year-landscape-font-size: 3vw !default;
$modal-month-landscape-font-size: 4vw !default;
$datepicker-modal-landscape-overflow: visible !default;
$modal-month-landscape-title-line-height: 12vh !default;

// tablet device style changes for modal popup
$modal-tablet-font-size: 18px !default;
$modal-tablet-content-dimension: 64px !default;
$modal-tablet-table-header-height: 48px !default;

@include export-module('datepicker-layout') {

  .e-input-group.e-control-wrapper.e-date-wrapper.e-non-edit.e-input-focus .e-input:focus ~ .e-clear-icon,
  .e-float-input.e-control-wrapper.e-input-group.e-date-wrapper.e-non-edit.e-input-focus input:focus ~ .e-clear-icon {
    display: flex;
  }

  .e-date-wrapper:not(.e-filled) {
    #{if(&, '&', '*')} .e-date-icon.e-icons {
      @if $skin-name == 'material' or $skin-name == 'material-dark' or $skin-name == 'Material3' {
        box-sizing: $datepicker-box-sizing;
      }
    }
  }

  #{&}.e-datepicker {
    #{if(&, '&', '*')} .e-calendar {

      #{if(&, '&', '*')} .e-content table tbody tr.e-month-hide:last-child {
        display: $datepicker-othermonth-row;
      }
    }

    #{if(&, '&', '*')}.e-popup-wrapper {
      border-radius: $datepicker-popup-border-radius;
      overflow-y: hidden;
      pointer-events: auto;
    }

    #{if(&, '&', '*')}.e-date-modal {
      @if $skin-name != 'Material3' {
        background-color: $datepicker-overlay;
      }
      @if $skin-name == 'Material3' {
        background: $datepicker-overlay;
      }
      height: 100%;
      left: 0;
      opacity: .5;
      pointer-events: auto;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 999;
    }

    #{if(&, '&', '*')} .e-model-header {
      @if $skin-name != 'Material3' and $skin-name != 'fluent2' {
        background-color: $datepicker-modal-header-bg;
      }
      @if $skin-name == 'Material3' or $skin-name == 'fluent2' {
        background: $datepicker-modal-header-bg;
      }
      color: $datepicker-modal-header-color;
      cursor: default;
      display: $datepicker-modal-header-display;
      padding: 10px 10px 10px 15px;

      #{if(&, '&', '*')} .e-model-year {
        font-size: $modal-year-font-size;
        font-weight: $modal-year-font-weight;
        line-height: $modal-year-line-height;
        margin: 0;
        @if $skin-name == 'bootstrap5.3' {
          color: $modal-year-font-color;
        }
      }
    }

    #{if(&, '&', '*')} .e-model-month,
    #{if(&, '&', '*')} .e-model-day {
      font-size: $modal-month-font-size;
      font-weight: $modal-month-font-weight;
      line-height: $modal-month-line-height;
      margin: 0;
      @if $skin-name == 'bootstrap5.3' {
        color: $modal-year-font-color;
      }
    }
  }
}

/* stylelint-disable */
.e-date-overflow {
  overflow: hidden !important;
}

.e-datepick-mob-popup-wrap {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  max-height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1002;

  .e-datepicker.e-popup-wrapper.e-lib.e-popup.e-control.e-popup-open {
    position: relative;
    top: 0 !important;
    left: 0 !important;
  }

  .e-datepicker.e-popup-wrapper.e-popup-expand.e-lib.e-popup.e-control.e-popup-open {
    min-width: 100%;
    min-height: 100%;
  }
}

.e-content-placeholder.e-datepicker.e-placeholder-datepicker {
  background-size: 250px 33px;
  min-height: 33px;
}

@media screen and (orientation: landscape) {
  .e-datepick-mob-popup-wrap .e-datepicker.e-popup-expand .e-calendar .e-content.e-month td.e-today span.e-day {
    line-height: $modal-tablet-content-dimension;
  }
}

.e-datepick-mob-popup-wrap {
  #{if(&, '&', '*')} .e-datepicker.e-popup-expand {
    border-radius: 0;

    #{if(&, '&', '*')} .e-model-header.e-blazor-device {
      height: 15vh;
      .e-popup-close {
        float: right;
      }
    }

    #{if(&, '&', '*')} .e-model-header.e-blazor-device {
      height: 15vh;
      .e-popup-close {
        float: right;
      }
    }

    #{if(&, '&', '*')} .e-model-header {
      height: $modal-portrait-header-height;
      padding: $modal-portrait-header-padding;

      #{if(&, '&', '*')} .e-day-wrapper {
        margin: $modal-portrait-header-year-margin;
      }

      #{if(&, '&', '*')} .e-popup-close {
        color: $modal-portrait-cancel-icon-color;
        float: $modal-portrait-cancel-icon-float-style;
        font-size: $datepicker-bigger-icon-font-size;
        @media (min-device-width: 768px) {
          font-size: $modal-tablet-font-size;
        }
      }

      #{if(&, '&', '*')} .e-btn.e-flat.e-popup-close {
        background: transparent;
        border-color: transparent;
        box-shadow: none;
        font-weight: 400;
        padding: 0;
      }

      #{if(&, '&', '*')} .e-today.e-flat.e-primary {
        color: $modal-today-text-color;
        float: $modal-portrait-today-float-style;
        @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
          background-color: transparent;
        }
        @if $skin-name == 'fluent2' {
          border-color: transparent;
        }
        @media (min-device-width: 768px) {
          font-size: $modal-tablet-font-size;
        }
      }
    }

    #{if(&, '&', '*')} .e-model-month,
    #{if(&, '&', '*')} .e-model-day {
      font-size: $modal-portrait-month-font-size;
      line-height: $modal-header-day-line-height;
    }

    #{if(&, '&', '*')} .e-calendar {
      min-width: $modal-portrait-calendar-min-width;
      min-height: $modal-portrait-calendar-min-height;
      height: $modal-portrait-calendar-height;
      @if ($skin-name == 'bootstrap' or $skin-name == 'bootstrap-dark') {
        padding: $modal-portrait-calendar-padding;
      }

      #{if(&, '&', '*')} .e-header.e-month,
      #{if(&, '&', '*')} .e-header.e-year,
      #{if(&, '&', '*')} .e-header.e-decade {
        height: $modal-portrait-header-month-height;
        border-style: $modal-header-border-style;
        border-width: $modal-header-border-width;
        border-color: $modal-header-border-color;
        padding: $modal-portrait-month-header-padding;
        line-height: $modal-month-header-line-height;

        #{if(&, '&', '*')} .e-title {
          @if $skin-name != 'bootstrap5.3' {
            position: $modal-portrait-header-title-position;
            line-height: $modal-month-header-title-line-height;
          }
          margin-left: $modal-portrait-header-title-margin;
          text-align: $modal-portrait-header-title-text;
          vertical-align: $modal-portrait-month-header-vertical-align;
          width: $modal-portrait-header-title-width;
          @media (min-device-width: 768px) {
            font-size: $modal-tablet-font-size;
          }
          @if $skin-name == 'fluent2' {
            left: $modal-header-left;
          }
        }

        #{if(&, '&', '*')} .e-prev {
          margin-right: $modal-portrait-prev-icon-margin;
          vertical-align: inherit;
          @if $skin-name == 'bootstrap5.3' {
            height: 46px;
            width: 46px;
          }
          @else {
            height: 35px;
            width: 35px;
          }
          @media (min-device-width: 768px) {
            @if $skin-name == 'bootstrap5.3' {
              height: 70px;
              width: 70px;
            }
            @else {
              height: 55px;
              width: 55px;
            }
          }

          #{if(&, '&', '*')} .e-icons {
            vertical-align: inherit;
           @media (min-device-width: 768px) {
             font-size: $modal-tablet-font-size;
           }
          }
        }

        #{if(&, '&', '*')} .e-next {
          margin-right: $modal-portrait-next-icon-margin;
          vertical-align: inherit;
          @if $skin-name == 'bootstrap5.3' {
            height: 46px;
            width: 46px;
          }
          @else {
            height: 35px;
            width: 35px;
          }
          @media (min-device-width: 768px) {
            @if $skin-name == 'bootstrap5.3' {
              height: 70px;
              width: 70px;
            }
            @else {
              height: 55px;
              width: 55px;
            }
          }

          #{if(&, '&', '*')} .e-icons {
            vertical-align: inherit;
            @media (min-device-width: 768px) {
              font-size: $modal-tablet-font-size;
            }
           }
        }

        #{if(&, '&', '*')} .e-icon-container {
          float: $modal-portrait-icon-float;
          @if $skin-name == 'fluent2' {
            padding-right: 2vw;
          }
        }
      }

      #{if(&, '&', '*')} th {
        @media (min-device-width: 768px) {
          font-size: $modal-tablet-font-size;
          height: $modal-tablet-table-header-height;
        }
      }

      #{if(&, '&', '*')} .e-content span.e-day {
        @media (min-device-width: 768px) {
          font-size: $modal-tablet-font-size;
          height: $modal-tablet-content-dimension;
          width: $modal-tablet-content-dimension;
          line-height: $modal-tablet-content-dimension;
        }
      }

    }

  #{if(&, '&', '*')} .e-calendar-cell-container {
    height: $modal-portrait-calendar-container-height;
  }

  .e-footer-container
  {
    display: $modal-portrait-footer-display-style;
  }

  #{if(&, '&', '*')} .e-content.e-month
  {
    height: $modal-portrait-calendar-content-height;
    table
    {
    height: $modal-portrait-calendar-tabel-height;
    }

  }

  }
}

@media screen and (orientation: landscape) {
  .e-datepick-mob-popup-wrap {
    #{if(&, '&', '*')} .e-datepicker.e-popup-expand {

      #{if(&, '&', '*')} .e-model-header.e-blazor-device {
        height: 25vh;
        .e-popup-close {
          float: right;
        }
      }

      #{if(&, '&', '*')} .e-calendar-cell-container {

        #{if(&, '&', '*')} .e-content.e-month,
        #{if(&, '&', '*')} .e-content.e-year,
        #{if(&, '&', '*')} .e-content.e-decade {
          @media (max-height: 600px) {
            height: 50vh;
          }

          @media (min-height: 600px) {
            height: 60vh;
          }
          overflow-y: auto;

          #{if(&, '&', '*')} table {
            @media (min-height: 600px) {
              height: 60vh;
            }
          }
        }
        #{if(&, '&', '*')} .e-footer-container {
          @media (min-height: 600px) {
            padding: 10px 0;
            height: 10%;
            font-size: 24px;
          }
        }
      }

      #{if(&, '&', '*')} .e-model-header {

        @media (max-height: 600px) {
          height: $modal-landscape-header-height;
        }

        @media (min-height: 600px) {
          height: $modal-landscape-header-big-height;
        }
        width: $datepicker-modal-popup-landscape-max-width;

        .e-popup-close {
          float: $modal-portrait-cancel-icon-float-style;
        }

        .e-day-wrapper {
          margin: $modal-landscape-header-year-margin;
        }

        #{if(&, '&', '*')} .e-model-month,
        #{if(&, '&', '*')} .e-model-day {
          font-size: $modal-month-landscape-font-size;
        }

      }

      #{if(&, '&', '*')} .e-calendar .e-header.e-month,
      #{if(&, '&', '*')} .e-calendar .e-header.e-year,
      #{if(&, '&', '*')} .e-calendar .e-header.e-decade {

        #{if(&, '&', '*')} .e-prev {
          @if $skin-name != 'fluent2' {
            margin-right: $modal-landscape-prev-icon-margin;
          }
        }

        #{if(&, '&', '*')} .e-title {
          @media (max-height: 600px) {
            line-height: $modal-month-landscape-title-line-height;
          }

          @media (min-height: 600px) {
            line-height: $modal-month-header-title-line-height;
          }
        }
      }

      #{if(&, '&', '*')} .e-content.e-month,
      #{if(&, '&', '*')} .e-content.e-year,
      #{if(&, '&', '*')} .e-content.e-decade {
        @media (max-height: 600px) {
          height: $modal-landscape-calendar-content-height;
        }

        @media (min-height: 600px) {
          height: $modal-landscape-calendar-content-big-height;
        }
        overflow-y: auto;
      }

      .e-calendar {
        display: $datepicker-modal-header-display;
        max-width: $datepicker-modal-popup-landscape-max-width;
        overflow: $datepicker-modal-landscape-overflow;
      }

      .e-calendar-cell-container {
        height: 70%;
        width: 100%;
      }

      .e-calendar.e-device .e-month table tbody {
        display: table-row-group;
      }

      #{if(&, '&', '*')} .e-content.e-month table ,
      #{if(&, '&', '*')} .e-content.e-decade table ,
      #{if(&, '&', '*')} .e-content.e-year table {
        @media (max-height: 600px) {
          height: $modal-portrait-calendar-content-height;
        }

        @media (min-height: 600px) {
          height: $modal-landscape-calendar-content-table-height;
        }
      }
    }
}
}
/* stylelint-enable */

@include export-module('datepicker-theme') {
  #{&}.e-datepicker,
  .e-small#{&}.e-datepicker {
    #{if(&, '&', '*')}.e-popup-wrapper {
      border: $datepicker-popup-border;
      box-shadow: $datepicker-popup-box-shadow;
    }

    #{if(&, '&', '*')} .e-calendar {
      @if $skin-name != 'Material3' {
        background-color: $datepicker-popup-bg-color;
      }
      @if $skin-name == 'Material3' {
        background: $datepicker-popup-bg-color;
      }
      border: $datepicker-calendar-border-style;
    }
  }

  #{if(&, '&', '*')}.e-input-group {
    #{if(&, '&', '*')}.e-date-wrapper.e-dateinput-active:active:not(.e-success):not(.e-warning):not(.e-error) {
      border: $datepicker-input-border-style;
    }
  }

  .e-date-wrapper {
    #{if(&, '&', '*')} span.e-input-group-icon {
      #{if(&, '&', '*')}.e-date-icon.e-icons.e-active {
        @if ($skin-name != 'bootstrap' and $skin-name != 'bootstrap5' and $skin-name != 'bootstrap5.3' and $skin-name != 'bootstrap-dark' and $skin-name != 'fabric' and $skin-name != 'fabric-dark') {
          color: $datepicker-icon-color;
        }
      }
      #{if(&, '&', '*')}.e-date-icon.e-icons.e-active {
        @if $skin-name == 'bootstrap4' or $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
          background: $datepicker-icon-active-bg-color;
          border-color: $datepicker-active-border-color;
        }
        @if ($skin-name != 'bootstrap' and $skin-name != 'bootstrap-dark' and $skin-name != 'fabric' and $skin-name != 'fabric-dark') {
          color: $datepicker-active-icon-color;
        }
        @if $skin-name == 'Material3' {
          border: $datepicker-icon-active-border;
          background: $datepicker-icon-active-bg-color;
        }
      }
    }
  }
}


@include export-module('datepicker-material-icons') {

  /*! component icons */

  .e-input-group-icon.e-date-icon,
  #{if(&, '&', '*')}.e-control-wrapper .e-input-group-icon.e-date-icon {
    font-size: $datepicker-icon-font-size;
    margin: $datepicker-icon-normal-margin;
    min-height: $datepicker-icon-container-min-height;
    min-width: $datepicker-icon-container-min-width;
    outline: none;

    #{if(&, '&', '*')}::before {
      content: '\e901';
      font-family: 'e-icons';
    }

    #{if(&, '&', '*')}:focus {
      background: $datepicker-icon-hover-color;
      border-radius: $datepicker-icon-border-radius;
    }
  }

  // mouse small icon
  .e-small .e-input-group-icon.e-date-icon,
  #{if(&, '&', '*')}.e-control-wrapper.e-small .e-input-group-icon.e-date-icon,
  #{if(&, '&', '*')}.e-small .e-control-wrapper .e-input-group-icon.e-date-icon {
    font-size: $datepicker-small-icon-font-size;
  }

  // Outline textbox icon configuration

  .e-date-wrapper.e-outline .e-input-group-icon.e-date-icon,
  .e-date-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-date-icon {
    min-height: $zero-value;
    min-width: 20px;
  }

  .e-small .e-date-wrapper.e-outline .e-input-group-icon.e-date-icon,
  .e-date-wrapper.e-control-wrapper.e-small.e-outline .e-input-group-icon.e-date-icon,
  .e-small .e-date-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-date-icon {
    min-height: $zero-value;
    min-width: 18px;
  }

  .e-datepick-mob-popup-wrap {

    #{if(&, '&', '*')} .e-datepicker.e-popup-expand {

      #{if(&, '&', '*')} .e-model-header {

        #{if(&, '&', '*')} .e-popup-close::before {
          content: '\e932';
          font-family: 'e-icons';
        }
      }
    }
  }
}

@include export-module('datepicker-bigger') {
  #{&}.e-datepicker.e-bigger,
  .e-bigger #{&}.e-datepicker {
    #{if(&, '&', '*')}.e-popup-wrapper {
      @if $skin-name == 'tailwind' {
        margin-top: $datepicker-popup-bigger-margin-top;
      }
    }
  }

  .e-bigger .e-content-placeholder.e-datepicker.e-placeholder-datepicker,
  .e-bigger.e-content-placeholder.e-datepicker.e-placeholder-datepicker {
    background-size: 250px 40px;
    min-height: 40px;
  }

  .e-bigger#{&}.e-datepicker,
  .e-bigger.e-small#{&}.e-datepicker {
    #{if(&, '&', '*')}.e-popup-wrapper {
      border: $datepicker-popup-border;
      box-shadow: $datepicker-popup-box-shadow;
    }

    #{if(&, '&', '*')} .e-calendar {
      @if $skin-name != 'Material3' {
        background-color: $datepicker-popup-bg-color;
      }
      @if $skin-name == 'Material3' {
        background: $datepicker-popup-bg-color;
      }
      border: $datepicker-calendar-border-style;
    }
  }

  .e-bigger .e-input-group-icon.e-date-icon,
  #{if(&, '&', '*')}.e-control-wrapper.e-bigger .e-input-group-icon.e-date-icon,
  #{if(&, '&', '*')}.e-bigger .e-control-wrapper .e-input-group-icon.e-date-icon {
    @if $skin-name == 'tailwind' or $skin-name == 'bootstrap4' or $skin-name == 'bootstrap5.3' or $skin-name == 'bootstrap5' or $skin-name == 'FluentUI' or $skin-name == 'fluent2' or $skin-name == 'material-dark' or $skin-name == 'material' or $skin-name == 'Material3' {
      font-size: $datepicker-bigger-icon-font-size;
      margin: $datepicker-icon-bigger-margin;
      min-height: $datepicker-bigger-icon-container-min-height;
      min-width: $datepicker-bigger-icon-container-min-width;
    }
    @if $skin-name == 'fluent2' or $skin-name == 'Material3' {
      border-radius: $datepicker-bigger-icon-border-radius;
    }
    @if $skin-name == 'bootstrap-dark' or $skin-name == 'bootstrap' or $skin-name == 'fabric-dark' or $skin-name == 'fabric' or $skin-name == 'highcontrast-light' or $skin-name == 'highcontrast' {
      font-size: $datepicker-bigger-icon-font-size;
      margin: $datepicker-icon-bigger-margin;
    }
  }

  .e-small.e-bigger .e-input-group-icon.e-date-icon,
  #{if(&, '&', '*')}.e-control-wrapper.e-small.e-bigger .e-input-group-icon.e-date-icon,
  #{if(&, '&', '*')}.e-small.e-bigger .e-control-wrapper .e-input-group-icon.e-date-icon {
    font-size: $datepicker-bigger-small-icon-font-size;
    @if $skin-name == 'fluent2' or $skin-name == 'Material3' {
      min-width: $datepicker-bigger-small-icon-container-min-width;
      min-height: $datepicker-bigger-small-icon-container-min-height;
      border-radius: $datepicker-bigger-small-icon-border-radius;
      margin: $datepicker-bigger-small-icon-margin;
    }
  }

  .e-bigger .e-date-wrapper.e-outline .e-input-group-icon.e-date-icon,
  .e-date-wrapper.e-control-wrapper.e-bigger.e-outline .e-input-group-icon.e-date-icon,
  .e-bigger .e-date-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-date-icon {
    @if $skin-name == 'material-dark' or $skin-name == 'material' {
      min-height: $zero-value;
      min-width: 24px;
    }
  }
  .e-bigger.e-small .e-outline.e-date-wrapper .e-input-group-icon.e-date-icon,
  .e-bigger.e-small.e-outline.e-date-wrapper .e-input-group-icon.e-date-icon,
  .e-date-wrapper.e-control-wrapper.e-bigger.e-small.e-outline .e-input-group-icon.e-date-icon,
  .e-bigger.e-small .e-date-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-date-icon {
    @if $skin-name == 'material-dark' or $skin-name == 'material' {
      min-height: $zero-value;
      min-width: 20px;
    }
  }
}

@import "../base/base.gd.scss";

$btn-color: rgba($grey-black, .87) !default;
$btn-font-weight: 500 !default;
$btn-border: 1px solid !default;
$btn-border-color: transparent !default;
$btn-padding: 6px 12px 4px !default;
$btn-small-padding: 2px 12px 1px !default;
$btn-bigger-padding: 4px 16px 2px !default;
$btn-bigger-small-padding: 3px 16px 1px !default;
$btn-icon-padding: 6px 7px 4px !default;
$btn-small-font-size: 12px !default;
$btn-small-icon-padding: 2px 5px 1px !default;
$btn-bigger-icon-padding: 4px 11px 2px !default;
$btn-bigger-small-icon-padding: 3px 10px 1px !default;
$btn-top-icon-padding: 12px 12px !default;
$btn-top-icon-bigger-padding: 16px 16px !default;
$btn-icon-top-bottom-padding: 6px !default;
$btn-small-icon-top-bottom-padding: 6px !default;
$btn-bigger-icon-top-bottom-padding: 8px !default;
$btn-bgcolor: $grey-50 !default;
$btn-border-radius: 2px !default;
$btn-hover-border-color: $btn-border-color !default;
$btn-active-border-color: $btn-border-color !default;
$btn-hover-color: $btn-color !default;
$btn-focus-color: $btn-color !default;
$btn-active-color: $btn-color !default;
$btn-focus-border-color: $btn-border-color !default;
$btn-text-transform: uppercase !default;
$btn-box-shadow: 0 3px 1px -2px rgba($grey-black, .2), 0 2px 2px 0 rgba($grey-black, .14), 0 1px 5px 0 rgba($grey-black, .12) !default;
$btn-hover-focus-box-shadow: 0 2px 4px -1px rgba($grey-black, .2), 0 4px 5px 0 rgba($grey-black, .14), 0 1px 10px 0 rgba($grey-black, .12) !default;
$btn-hover-bgcolor: mix($btn-color, $btn-bgcolor, 12%) !default;
$btn-focus-bgcolor: rgba($grey-light-font, .18) !default;
$btn-active-bgcolor: mix($btn-color, $btn-bgcolor, 32%) !default;
$btn-active-box-shadow: 0 5px 5px -3px rgba($grey-black, .2), 0 8px 10px 1px rgba($grey-black, .14), 0 3px 14px 2px rgba($grey-black, .12) !default;
$btn-disabled-color: rgba($grey-black, .26) !default;
$btn-disabled-bgcolor: rgba($grey-black, .12) !default;
$btn-disabled-border-color: $btn-border-color !default;
$btn-primary-color: $accent-font !default;
$btn-primary-hover-color: $btn-primary-color !default;
$btn-primary-focus-color: $btn-primary-color !default;
$btn-primary-active-color: $btn-primary-color !default;
$btn-primary-bgcolor: $accent !default;
$btn-primary-hover-bgcolor: mix($btn-primary-color, $btn-primary-bgcolor, 12%) !default;
$btn-primary-focus-bgcolor: mix($btn-primary-color, $btn-primary-bgcolor, 24%) !default;
$btn-primary-active-bgcolor: mix($btn-primary-color, $btn-primary-bgcolor, 32%) !default;
$btn-primary-border-color: $btn-border-color !default;
$btn-primary-hover-border-color: $btn-border-color !default;
$btn-primary-focus-border-color: $btn-border-color !default;
$btn-primary-active-border-color: $btn-border-color !default;
$btn-success-color: #fff !default;
$btn-success-bgcolor: #4d841d !default;
$btn-success-border-color: $btn-border-color !default;
$btn-success-hover-border-color: $btn-border-color !default;
$btn-success-focus-border-color: $btn-border-color !default;
$btn-success-active-border-color: $btn-border-color !default;
$btn-success-active-color: $btn-success-color !default;
$btn-info-border-color: $btn-border-color !default;
$btn-info-hover-border-color: $btn-border-color !default;
$btn-info-focus-border-color: $btn-border-color !default;
$btn-info-active-border-color: $btn-border-color !default;
$btn-info-bgcolor: #0378d5 !default;
$btn-warning-bgcolor: #c15700 !default;
$btn-warning-hover-color: #fff !default;
$btn-warning-border-color: $btn-border-color !default;
$btn-warning-hover-border-color: $btn-border-color !default;
$btn-warning-focus-border-color: $btn-border-color !default;
$btn-warning-active-border-color: $btn-border-color !default;
$btn-danger-border-color: $btn-border-color !default;
$btn-danger-hover-border-color: $btn-border-color !default;
$btn-danger-focus-border-color: $btn-border-color !default;
$btn-danger-active-border-color: $btn-border-color !default;
$btn-danger-color: #fff !default;
$btn-danger-bgcolor: #d64113 !default;
$btn-danger-active-color: $btn-danger-color !default;
$btn-flat-color: $btn-color !default;
$btn-flat-border: $btn-border !default;
$btn-flat-border-color: transparent !default;
$btn-flat-hover-color: $btn-flat-color !default;
$btn-flat-focus-color: $btn-flat-color !default;
$btn-flat-active-color: $btn-flat-color !default;
$btn-flat-bgcolor: transparent !default;
$btn-flat-disabled-bgcolor: $btn-flat-bgcolor !default;
$btn-flat-primary-color: $btn-primary-bgcolor !default;
$btn-flat-hover-bgcolor: mix($btn-flat-color, $btn-flat-bgcolor, 4%) !default;
$btn-flat-focus-bgcolor: mix($btn-flat-color, $btn-flat-bgcolor, 12%) !default;
$btn-flat-active-bgcolor: mix($btn-flat-color, $btn-flat-bgcolor, 24%) !default;
$btn-flat-primary-hover-bgcolor: mix($btn-flat-primary-color, $btn-flat-bgcolor, 4%) !default;
$btn-flat-primary-focus-bgcolor: mix($btn-flat-primary-color, $btn-flat-bgcolor, 12%) !default;
$btn-flat-primary-active-bgcolor: mix($btn-flat-primary-color, $btn-flat-bgcolor, 24%) !default;
$btn-flat-success-hover-bgcolor: mix($btn-success-bgcolor, $btn-flat-bgcolor, 4%) !default;
$btn-flat-success-focus-bgcolor: mix($btn-success-bgcolor, $btn-flat-bgcolor, 12%) !default;
$btn-flat-success-active-bgcolor: mix($btn-success-bgcolor, $btn-flat-bgcolor, 24%) !default;
$btn-flat-info-hover-bgcolor: mix($btn-info-bgcolor, $btn-flat-bgcolor, 4%) !default;
$btn-flat-info-focus-bgcolor: mix($btn-info-bgcolor, $btn-flat-bgcolor, 12%) !default;
$btn-flat-info-active-bgcolor: mix($btn-info-bgcolor, $btn-flat-bgcolor, 24%) !default;
$btn-flat-warning-hover-bgcolor: mix($btn-warning-bgcolor, $btn-flat-bgcolor, 4%) !default;
$btn-flat-warning-focus-bgcolor: mix($btn-warning-bgcolor, $btn-flat-bgcolor, 12%) !default;
$btn-flat-warning-active-bgcolor: mix($btn-warning-bgcolor, $btn-flat-bgcolor, 24%) !default;
$btn-flat-danger-hover-bgcolor: mix($btn-danger-bgcolor, $btn-flat-bgcolor, 4%) !default;
$btn-flat-danger-focus-bgcolor: mix($btn-danger-bgcolor, $btn-flat-bgcolor, 12%) !default;
$btn-flat-danger-active-bgcolor: mix($btn-danger-bgcolor, $btn-flat-bgcolor, 24%) !default;
$btn-flat-box-shadow: none !default;
$btn-outline-color: $btn-color !default;
$btn-outline-bgcolor: transparent !default;
$btn-outline-hover-color: $btn-color !default;
$btn-outline-focus-color: $btn-color !default;
$btn-outline-active-color: $btn-color !default;
$btn-outline-border-color: #adadad !default;
$btn-outline-hover-bgcolor: rgba($grey-black, .12) !default;
$btn-outline-focus-bgcolor: rgba($grey-black, .24) !default;
$btn-outline-active-bgcolor: rgba($grey-black, .32) !default;
$btn-outline-default-focus-border-color: $btn-hover-border-color !default;
$btn-font-size: 14px !default;
$btn-bigger-font-size: $btn-font-size !default;
$btn-bigger-small-font-size: $btn-font-size !default;
$btn-icon-font-size: 12px !default;
$btn-small-icon-font-size: 11px !default;
$btn-bigger-icon-font-size: 12px !default;
$btn-bigger-small-icon-font-size: 12px !default;
$btn-round-bgcolor: $btn-bgcolor !default;
$btn-round-color: $btn-color !default;
$btn-round-border-color: $btn-border-color !default;
$btn-round-hover-bgcolor: rgba($grey-black, .12) !default;
$btn-round-hover-color: $btn-color !default;
$btn-round-height: 3em !default;
$btn-round-width: 3em !default;
$btn-round-bigger-height: 3.7143em !default;
$btn-round-bigger-width: 3.7143em !default;
$btn-round-small-height: 2.5em !default;
$btn-round-small-width: 2.5em !default;
$btn-round-bigger-small-height: 2.8572em !default;
$btn-round-bigger-small-width: 2.8572em !default;
$btn-round-font-size: 14px !default;
$btn-small-round-font-size: 12px !default;
$btn-bigger-round-font-size: 16px !default;
$btn-bigger-small-round-font-size: 14px !default;
$btn-success-hover-bgcolor: mix($btn-success-color, $btn-success-bgcolor, 12%) !default;
$btn-success-focus-bgcolor: mix($btn-success-color, $btn-success-bgcolor, 24%) !default;
$btn-success-active-bgcolor: mix($btn-success-color, $btn-success-bgcolor, 32%) !default;
$btn-info-color: #fff !default;
$btn-info-hover-bgcolor: mix($btn-info-color, $btn-info-bgcolor, 12%) !default;
$btn-info-focus-bgcolor: mix($btn-info-color, $btn-info-bgcolor, 24%) !default;
$btn-info-active-bgcolor: mix($btn-info-color, $btn-info-bgcolor, 32%) !default;
$btn-warning-color: #fff !default;
$btn-warning-hover-bgcolor: mix($btn-warning-color, $btn-warning-bgcolor, 12%) !default;
$btn-warning-focus-bgcolor: mix($btn-warning-color, $btn-warning-bgcolor, 24%) !default;
$btn-warning-active-bgcolor: mix($btn-warning-color, $btn-warning-bgcolor, 32%) !default;
$btn-danger-hover-bgcolor: mix($btn-danger-color, $btn-danger-bgcolor, 12%) !default;
$btn-danger-focus-bgcolor: mix($btn-danger-color, $btn-danger-bgcolor, 24%) !default;
$btn-danger-active-bgcolor: mix($btn-danger-color, $btn-danger-bgcolor, 32%) !default;
$btn-flat-success-color:  $btn-success-bgcolor !default;
$btn-flat-info-color:  $btn-info-bgcolor !default;
$btn-flat-warning-color:  $btn-warning-bgcolor !default;
$btn-flat-danger-color:   $btn-danger-bgcolor !default;
$btn-flat-primary-hover-color: $btn-primary-bgcolor !default;
$btn-flat-success-hover-color: $btn-success-bgcolor !default;
$btn-flat-info-hover-color: $btn-info-bgcolor !default;
$btn-flat-warning-hover-color: $btn-warning-bgcolor !default;
$btn-flat-danger-hover-color: $btn-danger-bgcolor !default;
$btn-flat-primary-focus-color: $btn-primary-bgcolor !default;
$btn-flat-success-focus-color: $btn-success-bgcolor !default;
$btn-flat-info-focus-color: $btn-info-bgcolor !default;
$btn-flat-warning-focus-color: $btn-warning-bgcolor !default;
$btn-flat-danger-focus-color: $btn-danger-bgcolor !default;
$btn-flat-primary-active-color: $btn-primary-bgcolor !default;
$btn-flat-success-active-color: $btn-success-bgcolor !default;
$btn-flat-info-active-color: $btn-info-bgcolor !default;
$btn-flat-warning-active-color: $btn-warning-bgcolor !default;
$btn-flat-danger-active-color: $btn-danger-bgcolor !default;
$btn-icon-margin: -.6667em !default;
$btn-small-icon-margin: -.7273em !default;
$btn-bigger-icon-margin: -1em !default;
$btn-bigger-small-icon-margin: -1em !default;
$btn-icon-btn-width: 1em !default;
$btn-icon-small-width: 1.091em !default;
$btn-icon-bigger-width: 1em !default;
$btn-icon-bigger-small-width: 1em !default;
$btn-icon-width: 2.25em !default;
$btn-icon-margin-top: -2px !default;
$btn-small-icon-width: 2.182em !default;
$btn-bigger-icon-width: 3em !default;
$btn-bigger-small-icon-width: 2.6667em !default;
$btn-round-icon-line-height: 2.8572em !default;
$btn-small-round-icon-line-height: 2.3334em !default;
$btn-bigger-round-icon-line-height: 3.125em !default;
$btn-bigger-small-round-icon-line-height: 2.7143em !default;
$btn-text-line-height: 1.143em !default;
$btn-bigger-text-line-height: 2em !default;
$btn-small-text-line-height: 1.5834em !default;
$btn-bigger-small-text-line-height: 2em !default;
$btn-primary-disabled-bgcolor: $btn-disabled-bgcolor !default;
$btn-success-disabled-bgcolor: $btn-disabled-bgcolor !default;
$btn-info-disabled-bgcolor: $btn-disabled-bgcolor !default;
$btn-warning-disabled-bgcolor: $btn-disabled-bgcolor !default;
$btn-danger-disabled-bgcolor: $btn-disabled-bgcolor !default;
$btn-primary-disabled-color: $btn-disabled-color !default;
$btn-success-disabled-color: $btn-disabled-color !default;
$btn-info-disabled-color: $btn-disabled-color !default;
$btn-warning-disabled-color: $btn-disabled-color !default;
$btn-danger-disabled-color: $btn-disabled-color !default;
$btn-primary-disabled-border-color: $btn-disabled-border-color !default;
$btn-success-disabled-border-color: $btn-disabled-border-color !default;
$btn-info-disabled-border-color: $btn-disabled-border-color !default;
$btn-warning-disabled-border-color: $btn-disabled-border-color !default;
$btn-danger-disabled-border-color: $btn-disabled-border-color !default;
$btn-flat-disabled-color: $btn-disabled-color !default;
$btn-flat-primary-disabled-color: $btn-disabled-color !default;
$btn-flat-success-disabled-color: $btn-disabled-color !default;
$btn-flat-info-disabled-color: $btn-disabled-color !default;
$btn-flat-warning-disabled-color: $btn-disabled-color !default;
$btn-flat-danger-disabled-color: $btn-disabled-color !default;
$btn-outline-primary-disabled-color: $btn-disabled-color !default;
$btn-outline-success-disabled-color: $btn-disabled-color !default;
$btn-outline-info-disabled-color: $btn-disabled-color !default;
$btn-outline-warning-disabled-color: $btn-disabled-color !default;
$btn-outline-danger-disabled-color: $btn-disabled-color !default;
$btn-outline-primary-disabled-border-color: rgba($grey-black, .26) !default;
$btn-outline-success-disabled-border-color: rgba($grey-black, .26) !default;
$btn-outline-info-disabled-border-color: rgba($grey-black, .26) !default;
$btn-outline-warning-disabled-border-color: rgba($grey-black, .26) !default;
$btn-outline-danger-disabled-border-color: rgba($grey-black, .26) !default;
$btn-outline-active-box-shadow: $btn-flat-box-shadow !default;
$btn-flat-primary-bgcolor: $btn-flat-bgcolor !default;
$btn-flat-success-bgcolor: $btn-flat-bgcolor !default;
$btn-flat-info-bgcolor: $btn-flat-bgcolor !default;
$btn-flat-warning-bgcolor: $btn-flat-bgcolor !default;
$btn-flat-danger-bgcolor: $btn-flat-bgcolor !default;
$btn-flat-primary-disabled-bgcolor: $btn-disabled-bgcolor !default;
$btn-flat-success-disabled-bgcolor: $btn-disabled-bgcolor !default;
$btn-flat-info-disabled-bgcolor: $btn-disabled-bgcolor !default;
$btn-flat-warning-disabled-bgcolor: $btn-disabled-bgcolor !default;
$btn-flat-danger-disabled-bgcolor: $btn-disabled-bgcolor !default;
$btn-flat-hover-border-color: $btn-flat-border-color !default;
$btn-flat-active-border-color: $btn-flat-border-color !default;
$btn-flat-focus-border-color: $btn-flat-border-color !default;
$btn-flat-disabled-border-color: $btn-flat-border-color !default;
$btn-flat-primary-border-color: $btn-flat-border-color !default;
$btn-flat-primary-hover-border-color: $btn-flat-border-color !default;
$btn-flat-primary-active-border-color: $btn-flat-border-color !default;
$btn-flat-primary-focus-border-color: $btn-flat-border-color !default;
$btn-flat-primary-disabled-border-color: $btn-flat-border-color !default;
$btn-flat-success-border-color: $btn-flat-border-color !default;
$btn-flat-success-hover-border-color: $btn-flat-border-color !default;
$btn-flat-success-active-border-color: $btn-flat-border-color !default;
$btn-flat-success-focus-border-color: $btn-flat-border-color !default;
$btn-flat-success-disabled-border-color: $btn-flat-border-color !default;
$btn-flat-info-border-color: $btn-flat-border-color !default;
$btn-flat-info-hover-border-color: $btn-flat-border-color !default;
$btn-flat-info-active-border-color: $btn-flat-border-color !default;
$btn-flat-info-focus-border-color: $btn-flat-border-color !default;
$btn-flat-info-disabled-border-color: $btn-flat-border-color !default;
$btn-flat-warning-border-color: $btn-flat-border-color !default;
$btn-flat-warning-hover-border-color: $btn-flat-border-color !default;
$btn-flat-warning-active-border-color: $btn-flat-border-color !default;
$btn-flat-warning-focus-border-color: $btn-flat-border-color !default;
$btn-flat-warning-disabled-border-color: $btn-flat-border-color !default;
$btn-flat-danger-border-color: $btn-flat-border-color !default;
$btn-flat-danger-hover-border-color: $btn-flat-border-color !default;
$btn-flat-danger-active-border-color: $btn-flat-border-color !default;
$btn-flat-danger-focus-border-color: $btn-flat-border-color !default;
$btn-flat-danger-disabled-border-color: $btn-flat-border-color !default;
$btn-flat-active-box-shadow: $btn-flat-box-shadow !default;
$btn-outline-hover-border-color: $btn-outline-border-color !default;
$btn-outline-focus-border-color: $btn-outline-border-color !default;
$btn-outline-active-border-color: $btn-outline-border-color !default;
$btn-outline-primary-hover-border-color: $btn-primary-hover-border-color !default;
$btn-outline-primary-focus-border-color: $btn-primary-focus-border-color !default;
$btn-outline-primary-active-border-color: $btn-primary-active-border-color !default;
$btn-round-hover-border-color: $btn-round-hover-bgcolor !default;
$btn-round-focus-color: $btn-focus-color !default;
$btn-round-active-color: $btn-active-color !default;
$btn-warning-active-color: $btn-warning-color !default;
$btn-success-hover-color: #fff !default;
$btn-success-focus-color: $btn-success-hover-color !default;
$btn-info-active-color: $btn-info-color !default;
$btn-ripple-bgcolor: rgba($btn-color, .24) !default;
$btn-ripple-primary-bgcolor: rgba($btn-primary-color, .24) !default;
$btn-ripple-success-bgcolor: rgba($btn-success-color, .24) !default;
$btn-ripple-info-bgcolor: rgba($btn-info-color, .24) !default;
$btn-ripple-warning-bgcolor: rgba($btn-warning-color, .24) !default;
$btn-ripple-danger-bgcolor: rgba($btn-danger-color, .24) !default;
$btn-ripple-flat-bgcolor: rgba($btn-color, .12) !default;
$btn-ripple-flat-primary-bgcolor: rgba($btn-primary-bgcolor, .12) !default;
$btn-ripple-flat-success-bgcolor: rgba($btn-success-bgcolor, .12) !default;
$btn-ripple-flat-info-bgcolor: rgba($btn-info-bgcolor, .12) !default;
$btn-ripple-flat-warning-bgcolor: rgba($btn-warning-bgcolor, .12) !default;
$btn-ripple-flat-danger-bgcolor: rgba($btn-danger-bgcolor, .12) !default;
$btn-outline-primary-color: $btn-primary-bgcolor !default;
$btn-outline-primary-focus-bgcolor: $btn-primary-focus-bgcolor !default;
$btn-outline-primary-focus-color: $btn-primary-focus-color !default;
$btn-focus-outline: $btn-bgcolor 0 solid !default;
$btn-focus-outline-offset: 0 !default;
$btn-active-outline: $btn-bgcolor 0 solid !default;
$btn-active-outline-offset: 0 !default;
$btn-focus-outline-round: $btn-bgcolor 0 solid !default;
$btn-primary-outline: $btn-bgcolor 0 solid !default;
$btn-ripple-primary-bgcolor: transparent !default;
$btn-ripple-success-bgcolor: transparent !default;
$btn-info-hover-color: #fff !default;
$btn-danger-hover-color: #fff !default;
$btn-link-bgcolor: transparent !default;
$btn-link-border-color: transparent !default;
$btn-link-color: #0d47a1 !default;
$btn-link-hover-color: #0a3576 !default;
$btn-link-disabled-bgcolor: transparent !default;
$btn-focus-box-shadow: none !default;

@mixin icon-left-right {
  @if $skin-name == 'bootstrap4' {
    padding: 0 $btn-bigger-icon-top-bottom-padding;
  }
  @if $skin-name == 'tailwind' or $skin-name == 'tailwind-dark' {
    line-height: 0;
  }
}

@mixin icon-top-bottom {
  display: block;
  margin-top: 0;
  width: auto;
}

@mixin top-bottom-icon-btn {
  @if $skin-name == 'tailwind' or $skin-name == 'tailwind-dark' {
    line-height: 0;
  }
  @else {
    line-height: 1;
  }
}

@mixin mat3-border-radius {
  @if $skin-name == 'Material3' {
    border-radius: 4px;
  }
}

@mixin fluent-focus-visible {
  @if $skin-name == 'FluentUI' {
    outline: none !important; /* stylelint-disable-line declaration-no-important */
  }
}

@include export-module('button-layout') {
  .e-btn,
  .e-css.e-btn {
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-font-smoothing: antialiased;
    border: $btn-border;
    border-radius: $btn-border-radius;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    font-family: $font-family;
    font-size: $btn-font-size;
    font-weight: $btn-font-weight;
    justify-content: center;
    line-height: $btn-text-line-height;
    outline: none;
    padding: $btn-padding;
    text-align: center;
    text-decoration: none;
    text-transform: $btn-text-transform;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    @if $skin-name == 'Material3' {
      letter-spacing: .15px;
    }
    @at-root {
      &:disabled {
        cursor: default;
      }

      &:hover,
      &:focus {
        text-decoration: none;
      }

      &::-moz-focus-inner {
        border: 0;
        padding: 0;
      }

      & .e-btn-icon {
        display: inline-block;
        font-size: $btn-icon-font-size;
        margin-top: $btn-icon-margin-top;
        vertical-align: middle;
        width: $btn-icon-btn-width;

        &.e-icon-left {
          margin-left: $btn-icon-margin;
          width: $btn-icon-width;
          @include icon-left-right;
        }

        &.e-icon-right {
          width: $btn-icon-width;
          margin-right: $btn-icon-margin;
          @include icon-left-right;
        }

        &.e-icon-top {
          padding-bottom: $btn-icon-top-bottom-padding;
          @include icon-top-bottom;
        }

        &.e-icon-bottom {
          padding-top: $btn-icon-top-bottom-padding;
          @include icon-top-bottom;
        }
      }

      &.e-icon-btn {
        @if $skin-name != 'tailwind3' {
          padding: $btn-icon-padding;
        }
        @if $skin-name == 'fluent2' {
          line-height: 14px;
        }
        @include mat3-border-radius;
      }

      &.e-top-icon-btn,
      &.e-bottom-icon-btn {
        @include top-bottom-icon-btn;
        padding: $btn-top-icon-padding;
      }

      &.e-round {
        border-radius: 50%;
        height: $btn-round-height;
        line-height: 1;
        padding: 0;
        width: $btn-round-width;

        & .e-btn-icon {
          font-size: $btn-round-font-size;
          line-height: $btn-round-icon-line-height;
          margin-top: 0;
          width: auto;
        }
      }

      &.e-round-corner {
        @if $skin-name == 'Material3' {
          border-radius: 25px;
          padding: $btn-padding;
        }
      }

      &.e-rtl {
        & .e-icon-right {
          margin-left: $btn-icon-margin;
          margin-right: 0;
        }

        & .e-icon-left {
          margin-left: 0;
          margin-right: $btn-icon-margin;
        }
      }

      &.e-flat {
        border: $btn-flat-border;
      }

      &.e-small {
        font-size: $btn-small-font-size;
        line-height: $btn-small-text-line-height;
        padding: $btn-small-padding;
        @if $skin-name == 'bootstrap5' or $skin-name == 'FluentUI' {
          border-radius: 2px;
        }

        &.e-round-corner {
          @if $skin-name == 'Material3' {
            border-radius: 25px;
            padding: $btn-small-padding;
          }
        }

        & .e-btn-icon {
          font-size: $btn-small-icon-font-size;
          width: $btn-icon-small-width;

          &.e-icon-left {
            margin-left: $btn-small-icon-margin;
            width: $btn-small-icon-width;
            @include icon-left-right;
          }

          &.e-icon-right {
            margin-right: $btn-small-icon-margin;
            width: $btn-small-icon-width;
            @include icon-left-right;
          }

          &.e-icon-top {
            padding-bottom: $btn-small-icon-top-bottom-padding;
            width: auto;
          }

          &.e-icon-bottom {
            padding-top: $btn-small-icon-top-bottom-padding;
            width: auto;
          }
        }

        &.e-icon-btn {
          padding: $btn-small-icon-padding;
          @include mat3-border-radius;
        }

        &.e-top-icon-btn,
        &.e-bottom-icon-btn {
          @include top-bottom-icon-btn;
          padding: $btn-top-icon-padding;
        }

        &.e-round {
          height: $btn-round-small-height;
          line-height: 1;
          padding: 0;
          width: $btn-round-small-width;
          @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'FluentUI' or $skin-name == 'Material3' {
            border-radius: 50%;
          }

          & .e-btn-icon {
            font-size: $btn-small-round-font-size;
            line-height: $btn-small-round-icon-line-height;
            width: auto;
          }
        }

        &.e-rtl {
          & .e-icon-right {
            margin-left: $btn-small-icon-margin;
            margin-right: 0;
          }

          & .e-icon-left {
            margin-left: 0;
            margin-right: $btn-small-icon-margin;
          }
        }
      }

      &.e-block {
        display: block;
        width: 100%;
      }
    }
  }

  .e-small .e-btn,
  .e-small.e-btn,
  .e-small .e-css.e-btn,
  .e-small.e-css.e-btn {
    font-size: $btn-small-font-size;
    line-height: $btn-small-text-line-height;
    padding: $btn-small-padding;
    @if $skin-name == 'bootstrap5' or $skin-name == 'FluentUI' {
      border-radius: 2px;
    }

    &.e-round-corner {
      @if $skin-name == 'Material3' {
        border-radius: 25px;
        padding: $btn-small-padding;
      }
    }

    & .e-btn-icon {
      font-size: $btn-small-icon-font-size;
      width: $btn-icon-small-width;

      &.e-icon-left {
        margin-left: $btn-small-icon-margin;
        width: $btn-small-icon-width;
        @include icon-left-right;
      }

      &.e-icon-right {
        margin-right: $btn-small-icon-margin;
        width: $btn-small-icon-width;
        @include icon-left-right;
      }

      &.e-icon-top {
        padding-bottom: $btn-small-icon-top-bottom-padding;
        width: auto;
      }

      &.e-icon-bottom {
        padding-top: $btn-small-icon-top-bottom-padding;
        width: auto;
      }
    }

    &.e-icon-btn {
      padding: $btn-small-icon-padding;
      @if $skin-name == 'fluent2' {
        line-height: 14px;
      }
      @include mat3-border-radius;
    }

    &.e-top-icon-btn,
    &.e-bottom-icon-btn {
      @include top-bottom-icon-btn;
      padding: $btn-top-icon-padding;
    }

    &.e-round {
      height: $btn-round-small-height;
      line-height: 1;
      padding: 0;
      width: $btn-round-small-width;
      @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'FluentUI' or or $skin-name == 'Material3' {
        border-radius: 50%;
      }

      & .e-btn-icon {
        font-size: $btn-small-round-font-size;
        line-height: $btn-small-round-icon-line-height;
        width: auto;
      }
    }

    &.e-rtl {
      & .e-icon-right {
        margin-left: $btn-small-icon-margin;
        margin-right: 0;
      }

      & .e-icon-left {
        margin-left: 0;
        margin-right: $btn-small-icon-margin;
      }
    }
  }

  @if $skin-name == 'fluent2' {
    .e-btn.e-icon-btn .e-btn-icon,
    .e-small .e-btn.e-icon-btn .e-btn-icon {
      font-size: 14px;
      line-height: 14px;
    }
  }
}


@mixin button-focus {
  background: $btn-focus-bgcolor;
  @if $skin-name == 'Material3' {
    border-image: $btn-focus-border-color;
  }
  @else {
    border-color: $btn-focus-border-color;
  }
  color: $btn-focus-color;
  outline: $btn-focus-outline;
  outline-offset: $btn-focus-outline-offset;
  @if $skin-name == 'bootstrap4' {
    $color-rgba: rgba(mix(lighten($btn-focus-bgcolor, 50%), $btn-focus-border-color, 15%), .5);
    box-shadow: 0 0 0 .25em $color-rgba;
  }
  @else if $skin-name == 'tailwind' or $skin-name == 'bootstrap5' or $skin-name == 'FluentUI' {
    box-shadow: $btn-focus-box-shadow;
  }
  @else if $skin-name != 'bootstrap5.3' and $skin-name != 'tailwind3' {
    box-shadow: $btn-hover-focus-box-shadow;
  }
}

@mixin button-active {
  background: $btn-active-bgcolor;
  @if $skin-name == 'Material3' {
    border-image: $btn-active-border-color;
  }
  @else {
    border-color: $btn-active-border-color;
  }
  color: $btn-active-color;
  @if $skin-name != 'FluentUI' {
    outline: $btn-active-outline;
    outline-offset: $btn-active-outline-offset;
  }
  @else if $skin-name != 'bootstrap5' and $skin-name != 'bootstrap5.3' and $skin-name != 'bootstrap4' {
    box-shadow: $btn-active-box-shadow;
  }
}

@mixin primary-focus {
  background: $btn-primary-focus-bgcolor;
  border-color: $btn-primary-focus-border-color;
  color: $btn-primary-focus-color;
  outline: $btn-primary-outline;
  @if $skin-name == 'bootstrap4' {
    $color-rgba: rgba(mix(lighten($btn-primary-focus-bgcolor, 50%), $btn-primary-focus-border-color, 15%), .5);
    box-shadow: 0 0 0 .25em $color-rgba;
  }
  @else if $skin-name == 'tailwind' or $skin-name == 'tailwind-dark' {
    box-shadow: $btn-focus-box-shadow;
  }
  @else if $skin-name == 'bootstrap5' or $skin-name == 'Material3' {
    box-shadow: $btn-primary-focus-box-shadow;
  }
  @else {
    @if $skin-name != 'tailwind3' {
      box-shadow: $btn-hover-focus-box-shadow;
    }
  }
}

@mixin primary-active {
  background: $btn-primary-active-bgcolor;
  border-color: $btn-primary-active-border-color;
  color: $btn-primary-active-color;
  @if $skin-name != 'FluentUI' {
    outline: $btn-active-outline;
  }
  @if $skin-name != 'bootstrap5' and $skin-name != 'bootstrap5.3' and $skin-name != 'bootstrap4' {
    box-shadow: $btn-active-box-shadow;
  }
}

@mixin primary-disabled {
  background: $btn-primary-disabled-bgcolor;
  border-color: $btn-primary-disabled-border-color;
  box-shadow: $btn-flat-box-shadow;
  color: $btn-primary-disabled-color;
  @if $skin-name == 'FluentUI' {
    outline: none;
  }
}

@mixin outline-focus {
  @if $skin-name == 'bootstrap4' {
    $color-rgba: rgba(mix(lighten($btn-outline-focus-bgcolor, 50%), $btn-outline-default-focus-border-color, 15%), .5);
    box-shadow: 0 0 0 .25em $color-rgba;
  }
  @else if ($skin-name == 'tailwind' or $skin-name == 'tailwind-dark' or $skin-name == 'bootstrap5') {
    box-shadow: $btn-focus-box-shadow;
  }
  @else if $skin-name == 'Material3' {
    background: $btn-outline-active-bgcolor;
    border: 1px solid $btn-outline-border-color;
    color: $btn-outline-color;
  }
  @else if $skin-name == 'fluent2' {
    color: $secondary-outline-button-text-color-hover;
  }
  @else if $skin-name == 'bootstrap5.3' {
    box-shadow: $btn-hover-focus-box-shadow;
  }
  @else if $skin-name == 'tailwind3' {
    background: $secondary-bg-color-focus;
    border: 1px solid $secondary-border-color-focus;
    color: $secondary-text-color-focus;
  }
}

@mixin outline-active {
  @if $skin-name == 'bootstrap4' {
    background: $btn-bgcolor;
    border-color: transparent;
  }
  @else {
    background: $btn-outline-active-bgcolor;
    border-color: $btn-outline-active-border-color;
    @if $skin-name != 'bootstrap5' and $skin-name != 'bootstrap5.3' and $skin-name != 'bootstrap4' {
      box-shadow: $btn-outline-active-box-shadow;
    }
  }
  color: $btn-outline-active-color;
}

@mixin outline-primary-focus {
  @if $skin-name == 'bootstrap4' {
    $color-rgba: rgba(mix(lighten($btn-outline-primary-focus-bgcolor, 50%), $btn-outline-primary-focus-border-color, 15%), .5);
    box-shadow: 0 0 0 .25em $color-rgba;
  }
  @else if ($skin-name == 'tailwind' or $skin-name == 'tailwind-dark') {
    box-shadow: $btn-focus-box-shadow;
  }
  @else if ($skin-name == 'bootstrap5') {
    box-shadow: $btn-primary-focus-box-shadow;
  }
  @else if $skin-name == 'Material3' {
    background: $btn-outline-primary-active-bgcolor;
    border: 1px solid $btn-outline-border-color;
    color: rgba($btn-outline-primary-color);
  }
  @else if $skin-name == 'tailwind3' {
    background: $primary-bg-color-focus;
    border: 1px solid $primary-border-color-focus;
    color: $primary-text-focus;
  }
}

@mixin outline-primary-active {
  @if $skin-name == 'bootstrap4' {
    background: $btn-primary-bgcolor;
    border-color: transparent;
  }
  @else if ($skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3') {
    background: $btn-primary-bgcolor;
  }
  @else {
    background: $btn-primary-active-bgcolor;
    border-color: $btn-outline-primary-active-border-color;
    @if $skin-name != 'bootstrap5' and $skin-name != 'bootstrap5.3' and $skin-name != 'bootstrap4' {
      box-shadow: $btn-outline-active-box-shadow;
    }
  }
  color: $btn-primary-active-color;
  @if $skin-name == 'Material3' {
    background: $btn-outline-primary-active-bgcolor;
    border: 1px solid $btn-outline-border-color;
    color: rgba($btn-outline-primary-color);
  }
}

@mixin outline-success-focus {
  @if $skin-name == 'bootstrap4' {
    $color-rgba: rgba(mix(lighten($btn-success-focus-bgcolor, 50%), $btn-success-focus-border-color, 15%), .5);
    box-shadow: 0 0 0 .25em $color-rgba;
  }
  @else if ($skin-name == 'tailwind' or $skin-name == 'tailwind-dark') {
    box-shadow: $btn-focus-box-shadow;
  }
  @else if ($skin-name == 'bootstrap5') {
    box-shadow: $btn-success-focus-box-shadow;
  }
  @else if $skin-name == 'Material3' {
    background: $btn-outline-success-active-bgcolor;
    border: 1px solid $btn-outline-border-color;
    color: rgba($btn-outline-success-color);
  }
  @else if $skin-name == 'tailwind3' {
    background: $success-bg-color-focus;
    border: 1px solid $success-border-color-focus;
    color: $success-text-focus;
  }
}

@mixin outline-success-active {
  @if $skin-name == 'bootstrap4' {
    background: $btn-success-bgcolor;
    border-color: transparent;
  }
  @else {
    background: $btn-success-active-bgcolor;
    border-color: $btn-success-active-border-color;
    @if $skin-name != 'bootstrap5' and $skin-name != 'bootstrap5.3' and $skin-name != 'bootstrap4' {
      box-shadow: $btn-outline-active-box-shadow;
    }
  }
  color: $btn-success-active-color;
  @if $skin-name == 'Material3' {
    background: $btn-outline-success-active-bgcolor;
    border: 1px solid $btn-outline-border-color;
    color: rgba($btn-outline-success-color);
  }
}

@mixin outline-info-focus {
  @if $skin-name == 'bootstrap4' {
    $color-rgba: rgba(mix(lighten($btn-info-hover-bgcolor, 50%), $btn-info-focus-border-color, 15%), .5);
    box-shadow: 0 0 0 .25em $color-rgba;
  }
  @else if ($skin-name == 'tailwind' or $skin-name == 'tailwind-dark') {
    box-shadow: $btn-focus-box-shadow;
  }
  @else if ($skin-name == 'bootstrap5') {
    box-shadow: $btn-info-focus-box-shadow;
  }
  @else if $skin-name == 'Material3' {
    background: $btn-outline-info-active-bgcolor;
    border: 1px solid $btn-outline-border-color;
    color: rgba($btn-outline-info-color);
  }
  @else if $skin-name == 'tailwind3' {
    background: $info-bg-color-focus;
    border: 1px solid $info-border-color-focus;
    color: $info-text-focus;
  }
}

@mixin outline-info-active {
  @if $skin-name == 'bootstrap4' {
    background: $btn-info-bgcolor;
    border-color: transparent;
  }
  @else if ($skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3') {
    background: $btn-info-bgcolor;
  }
  @else {
    background: $btn-info-active-bgcolor;
    border-color: $btn-info-active-border-color;
    @if $skin-name != 'bootstrap5' and $skin-name != 'bootstrap5.3' and $skin-name != 'bootstrap4' {
      box-shadow: $btn-outline-active-box-shadow;
    }
  }
  color: $btn-info-active-color;
  @if $skin-name == 'Material3' {
    background: $btn-outline-info-active-bgcolor;
    border: 1px solid $btn-outline-border-color;
    color: rgba($btn-outline-info-color);
  }
}

@mixin outline-warning-focus {
  @if $skin-name == 'bootstrap4' {
    $color-rgba: rgba(mix(lighten($btn-warning-hover-bgcolor, 50%), $btn-warning-focus-border-color, 15%), .5);
    box-shadow: 0 0 0 .25em $color-rgba;
  }
  @else if ($skin-name == 'tailwind' or $skin-name == 'tailwind-dark') {
    box-shadow: $btn-focus-box-shadow;
  }
  @else if ($skin-name == 'bootstrap5') {
    box-shadow: $btn-warning-focus-box-shadow;
  }
  @else if $skin-name == 'Material3' {
    background: $btn-outline-warning-active-bgcolor;
    border: 1px solid $btn-outline-border-color;
    color: rgba($btn-outline-warning-color);
  }
  @else if $skin-name == 'tailwind3' {
    background: $warning-bg-color-focus;
    border: 1px solid $warning-border-color-focus;
    color: $warning-text-focus;
  }
}

@mixin outline-warning-active {
  @if $skin-name == 'bootstrap4' {
    background: $btn-warning-bgcolor;
    border-color: transparent;
  }
  @else {
    background: $btn-warning-active-bgcolor;
    border-color: $btn-warning-active-border-color;
    @if $skin-name != 'bootstrap5' and $skin-name != 'bootstrap5.3' and $skin-name != 'bootstrap4' {
      box-shadow: $btn-outline-active-box-shadow;
    }
  }
  color: $btn-warning-active-color;
  @if $skin-name == 'Material3' {
    background: $btn-outline-warning-active-bgcolor;
    border: 1px solid $btn-outline-border-color;
    color: rgba($btn-outline-warning-color);
  }
}

@mixin outline-danger-focus {
  @if $skin-name == 'bootstrap4' {
    $color-rgba: rgba(mix(lighten($btn-danger-hover-bgcolor, 50%), $btn-danger-focus-border-color, 15%), .5);
    box-shadow: 0 0 0 .25em $color-rgba;
  }
  @else if ($skin-name == 'tailwind' or $skin-name == 'tailwind-dark') {
    box-shadow: $btn-focus-box-shadow;
  }
  @else if ($skin-name == 'bootstrap5') {
    box-shadow: $btn-danger-focus-box-shadow;
  }
  @else if $skin-name == 'Material3' {
    background: $btn-outline-danger-active-bgcolor;
    border: 1px solid $btn-outline-border-color;
    color: rgba($btn-outline-danger-color);
  }
  @else if $skin-name == 'tailwind3' {
    background: $danger-bg-color-focus;
    border: 1px solid $danger-border-color-focus;
    color: $danger-text-focus;
  }
}

@mixin outline-danger-active {
  @if $skin-name == 'bootstrap4' {
    background: $btn-danger-bgcolor;
    border-color: transparent;
  }
  @else {
    background: $btn-danger-active-bgcolor;
    border-color: $btn-danger-active-border-color;
    @if $skin-name != 'bootstrap5' and $skin-name != 'bootstrap5.3' and $skin-name != 'bootstrap4' {
      box-shadow: $btn-outline-active-box-shadow;
    }
  }
  color: $btn-danger-active-color;
  @if $skin-name == 'Material3' {
    background: $btn-outline-danger-active-bgcolor;
    border: 1px solid $btn-outline-border-color;
    color: rgba($btn-outline-danger-color);
  }
}

@mixin outline-disabled {
  background: $btn-outline-bgcolor;
  @if $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'tailwind-dark' {
    border-color: $btn-outline-disabled-border-color;
    color: $btn-outline-disabled-color;
  }
  @else if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
    border-color: $btn-outline-disabled-border-color;
    color: $btn-outline-disabled-color;
  }
  @else if $skin-name == 'fluent2' {
    background: $btn-outline-disabled-bgcolor !important; /* stylelint-disable-line declaration-no-important */
    border-color: $btn-outline-disabled-border-color !important; /* stylelint-disable-line declaration-no-important */
    color: $btn-outline-disabled-color !important; /* stylelint-disable-line declaration-no-important */
  }
  @else if $skin-name == 'tailwind3' {
    border: 1px solid $secondary-border-color-disabled;
    color: $secondary-text-color-disabled;
  }
  @else {
    border-color: $btn-outline-primary-disabled-border-color;
    box-shadow: $btn-flat-box-shadow;
    color: $btn-outline-primary-disabled-color;
  }
}

@mixin outline-primary-disabled {
  @if $skin-name == 'bootstrap5'  or $skin-name == 'bootstrap5.3' {
    background: $btn-outline-primary-disabled-border-color;
  }
  @else {
    background: $btn-outline-bgcolor;
  }
  border-color: $btn-outline-primary-disabled-border-color;
  box-shadow: $btn-flat-box-shadow;
  color: $btn-outline-primary-disabled-color;
}

@mixin outline-success-disabled {
  @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
    background: $btn-outline-success-disabled-border-color;
  }
  @else {
    background: $btn-outline-bgcolor;
  }
  border-color: $btn-outline-success-disabled-border-color;
  box-shadow: $btn-flat-box-shadow;
  color: $btn-outline-success-disabled-color;
}

@mixin outline-info-disabled {
  @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
    background: $btn-info-disabled-border-color;
  }
  @else {
    background: $btn-outline-bgcolor;
  }
  border-color: $btn-outline-info-disabled-border-color;
  box-shadow: $btn-flat-box-shadow;
  color: $btn-outline-info-disabled-color;
}

@mixin outline-warning-disabled {
  @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
    background: $btn-outline-warning-disabled-border-color;
  }
  @else {
    background: $btn-outline-bgcolor;
  }
  border-color: $btn-outline-warning-disabled-border-color;
  box-shadow: $btn-flat-box-shadow;
  color: $btn-outline-warning-disabled-color;
}

@mixin outline-danger-disabled {
  @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
    background: $btn-outline-danger-disabled-border-color;
  }
  @else {
    background: $btn-outline-bgcolor;
  }
  border-color: $btn-outline-danger-disabled-border-color;
  box-shadow: $btn-flat-box-shadow;
  color: $btn-outline-danger-disabled-color;
}

@mixin success-focus {
  background: $btn-success-focus-bgcolor;
  border-color: $btn-success-focus-border-color;
  color: $btn-success-hover-color;
  @if $skin-name == 'bootstrap4' {
    $color-rgba: rgba(mix(lighten($btn-success-focus-bgcolor, 50%), $btn-success-focus-border-color, 15%), .5);
    box-shadow: 0 0 0 .25em $color-rgba;
  }
  @else if ($skin-name == 'tailwind' or $skin-name == 'tailwind-dark') {
    box-shadow: $btn-focus-box-shadow;
  }
  @else if $skin-name == 'bootstrap5' or $skin-name == 'Material3' {
    box-shadow: $btn-success-focus-box-shadow;
  }
  @else {
    @if $skin-name != 'tailwind3' {
      box-shadow: $btn-hover-focus-box-shadow;
    }
  }
}

@mixin success-active {
  background: $btn-success-active-bgcolor;
  border-color: $btn-success-active-border-color;
  color: $btn-success-active-color;
  @if $skin-name != 'bootstrap5' and $skin-name != 'bootstrap5.3' and $skin-name != 'bootstrap4' {
    box-shadow: $btn-active-box-shadow;
  }
}

@mixin info-focus {
  background: $btn-info-focus-bgcolor;
  border-color: $btn-info-focus-border-color;
  color: $btn-info-hover-color;
  @if $skin-name == 'bootstrap4' {
    $color-rgba: rgba(mix(lighten($btn-info-focus-bgcolor, 50%), $btn-info-focus-border-color, 15%), .5);
    box-shadow: 0 0 0 .25em $color-rgba;
  }
  @else if ($skin-name == 'tailwind' or $skin-name == 'tailwind-dark') {
    box-shadow: $btn-focus-box-shadow;
  }
  @else if ($skin-name == 'bootstrap5') {
    box-shadow: $btn-info-focus-box-shadow;
  }
  @else {
    @if $skin-name != 'tailwind3' {
      box-shadow: $btn-hover-focus-box-shadow;
    }
  }
}

@mixin info-active {
  background: $btn-info-active-bgcolor;
  color: $btn-info-active-color;
  @if ($skin-name == 'tailwind' or $skin-name == 'tailwind-dark') {
    border-color: $info-border-color-pressed;
  }
  @else {
    border-color: $btn-info-active-border-color;
  }
  @if $skin-name != 'bootstrap5' and $skin-name != 'bootstrap5.3' and $skin-name != 'bootstrap4' {
    box-shadow: $btn-active-box-shadow;
  }
}

@mixin warning-focus {
  background: $btn-warning-focus-bgcolor;
  border-color: $btn-warning-focus-border-color;
  color: $btn-warning-hover-color;
  @if $skin-name == 'bootstrap4' {
    $color-rgba: rgba(mix(lighten($btn-warning-focus-bgcolor, 50%), $btn-warning-focus-border-color, 15%), .5);
    box-shadow: 0 0 0 .25em $color-rgba;
  }
  @else if ($skin-name == 'tailwind' or $skin-name == 'tailwind-dark') {
    box-shadow: $btn-focus-box-shadow;
  }
  @else if $skin-name == 'bootstrap5' or $skin-name == 'Material3' {
    box-shadow: $btn-warning-focus-box-shadow;
  }
  @else {
    @if $skin-name != 'tailwind3' {
      box-shadow: $btn-hover-focus-box-shadow;
    }
  }
}

@mixin warning-active {
  background: $btn-warning-active-bgcolor;
  @if ($skin-name == 'tailwind' or $skin-name == 'tailwind-dark') {
    border-color: $warning-border-color-pressed;
  }
  @else {
    border-color: $btn-warning-active-border-color;
  }
  color: $btn-warning-active-color;
  @if $skin-name != 'bootstrap5' and $skin-name != 'bootstrap5.3' and $skin-name != 'bootstrap4' {
    box-shadow: $btn-active-box-shadow;
  }
}

@mixin danger-focus {
  background: $btn-danger-focus-bgcolor;
  border-color: $btn-danger-focus-border-color;
  color: $btn-danger-hover-color;
  @if $skin-name == 'bootstrap4' {
    $color-rgba: rgba(mix(lighten($btn-danger-focus-bgcolor, 50%), $btn-danger-focus-border-color, 15%), .5);
    box-shadow: 0 0 0 .25em $color-rgba;
  }
  @else if ($skin-name == 'tailwind' or $skin-name == 'tailwind-dark') {
    box-shadow: $btn-focus-box-shadow;
  }
  @else if $skin-name == 'bootstrap5' or $skin-name == 'Material3' {
    box-shadow: $btn-danger-focus-box-shadow;
  }
  @else {
    @if $skin-name != 'tailwind3' {
      box-shadow: $btn-hover-focus-box-shadow;
    }
  }
}

@mixin danger-active {
  background: $btn-danger-active-bgcolor;
  @if ($skin-name == 'tailwind' or $skin-name == 'tailwind-dark') {
    border-color: $danger-border-color-pressed;
  }
  @else {
    border-color: $btn-danger-active-border-color;
  }
  color: $btn-danger-active-color;
  @if $skin-name != 'bootstrap5' and $skin-name != 'bootstrap5.3' and $skin-name != 'bootstrap4' {
    box-shadow: $btn-active-box-shadow;
  }
}

@mixin link-focus {
  border-radius: 0;
  text-decoration: underline;
  @if $skin-name == 'bootstrap4' {
    background: $btn-link-bgcolor;
    border-color: $btn-link-border-color;
    box-shadow: none;
    color: $btn-link-hover-color;
  }
  @else {
    color: $btn-link-hover-color;
  }
}

@mixin link-hover {
  border-radius: 0;
  color: $btn-link-hover-color;
  text-decoration: underline;
  @if $skin-name == 'bootstrap4' {
    background: transparent;
    border-color: transparent;
  }
}

@mixin success-disabled {
  background: $btn-success-disabled-bgcolor;
  @if $skin-name == 'tailwind' or $skin-name == 'tailwind-dark' or $skin-name == 'tailwind3' or $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
    border-color: $success-border-color-disabled;
  }
  @else {
    border-color: $btn-disabled-border-color;
  }
  box-shadow: $btn-flat-box-shadow;
  color: $btn-success-disabled-color;
  @if $skin-name == 'FluentUI' {
    outline: none;
  }
}

@mixin info-disabled {
  background: $btn-info-disabled-bgcolor;
  @if ($skin-name == 'tailwind' or $skin-name == 'tailwind-dark' or $skin-name == 'tailwind3' or $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3') {
    border-color: $btn-info-disabled-border-color;
  }
  @else {
    border-color: $btn-disabled-border-color;
  }
  box-shadow: $btn-flat-box-shadow;
  color: $btn-info-disabled-color;
  @if $skin-name == 'FluentUI' {
    outline: none;
  }
}

@mixin warning-disabled {
  background: $btn-warning-disabled-bgcolor;
  @if ($skin-name == 'tailwind' or $skin-name == 'tailwind-dark' or $skin-name == 'tailwind3' or $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3') {
    border-color: $btn-warning-disabled-border-color;
  }
  @else {
    border-color: $btn-disabled-border-color;
  }
  box-shadow: $btn-flat-box-shadow;
  color: $btn-warning-disabled-color;
  @if $skin-name == 'FluentUI' {
    outline: none;
  }
}

@mixin danger-disabled {
  background: $btn-danger-disabled-bgcolor;
  @if ($skin-name == 'tailwind' or $skin-name == 'tailwind-dark' or $skin-name == 'tailwind3' or $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3') {
    border-color: $btn-danger-disabled-border-color;
  }
  @else {
    border-color: $btn-disabled-border-color;
  }
  box-shadow: $btn-flat-box-shadow;
  color: $btn-danger-disabled-color;
  @if $skin-name == 'FluentUI' {
    outline: none;
  }
}

@mixin link-disabled {
  @if $skin-name == 'bootstrap4' {
    color: $btn-bgcolor;
  }
  @else if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
    color: $secondary-border-color-disabled;
    text-decoration: underline;
  }
  @else if $skin-name == 'tailwind3' {
    color: $btn-link-disabled-color;
  }
  @else {
    color: $btn-disabled-color;
  }
  background: $btn-link-disabled-bgcolor;
  box-shadow: $btn-flat-box-shadow;
  text-decoration: none;
  @if $skin-name == 'FluentUI' {
    outline: none;
  }
}



@include export-module('button-theme') {

  /* stylelint-disable property-no-vendor-prefix */
  .e-btn,
  .e-css.e-btn {
    -webkit-tap-highlight-color: transparent;
    background: $btn-bgcolor;
    @if $skin-name == 'Material3' {
      border-image: $btn-border-color;
    }
    @else {
      border-color: $btn-border-color;
    }
    @if $skin-name != 'bootstrap5' and $skin-name != 'bootstrap5.3' {
      box-shadow: $btn-box-shadow;
    }
    color: $btn-color;
    transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
    @at-root {
      &:hover {
        background: $btn-hover-bgcolor;
        @if $skin-name == 'Material3' {
          border-image: $btn-hover-border-color;
        }
        @else {
          border-color: $btn-hover-border-color;
        }
        box-shadow: $btn-hover-focus-box-shadow;
        color: $btn-hover-color;
      }

      &:focus { // both keyboard and focus
        @include button-focus;
      }

      &:focus:not(:focus-visible) { //mouse click
        @include fluent-focus-visible;
      }

      &:focus-visible { // only for keybord
        @if $skin-name == 'FluentUI' {
          background: $btn-focus-bgcolor;
        }
        @if $skin-name == 'Material3' {
          background: $btn-active-bgcolor;
          box-shadow: $btn-keyboard-focus-box-shadow;
        }
        @if $skin-name == 'fluent2' {
          border-color: $btn-keyboard-focus-border-color;
          box-shadow: $btn-keyboard-focus-box-shadow;
        }
        @if $skin-name == 'bootstrap5.3' {
          box-shadow: $btn-focus-box-shadow;
        }
        @if $skin-name == 'tailwind3' {
          background: $btn-bgcolor;
          color: $btn-color;
          box-shadow: $btn-focus-box-shadow;
        }
      }

      &:active {
        @include button-active;
      }

      &.e-active {
        background: $btn-active-bgcolor;
        @if $skin-name == 'Material3' {
          border-image: $btn-active-border-color;
        }
        @else {
          border-color: $btn-active-border-color;
        }
        @if $skin-name != 'bootstrap5' and $skin-name != 'bootstrap5.3' {
          box-shadow: $btn-active-box-shadow;
        }
        color: $btn-active-color;
        @if $skin-name == 'fluent2' {
          background: $secondary-bg-color-selected !important; /* stylelint-disable-line declaration-no-important */
          border-color: $secondary-border-color-selected !important; /* stylelint-disable-line declaration-no-important */
          color: $secondary-text-color-selected !important; /* stylelint-disable-line declaration-no-important */
        }
      }

      &:disabled,
      &.e-disabled {
        background: $btn-disabled-bgcolor;
        border-color: $btn-disabled-border-color;
        box-shadow: $btn-flat-box-shadow;
        color: $btn-disabled-color;
        @if $skin-name == 'FluentUI' {
          outline: none;
        }
      }

      &.e-disabled {
        @if $skin-name == 'fluent2' or $skin-name == 'bootstrap5.3' {
          opacity: 1;
        }
      }

      & .e-ripple-element {
        background: $btn-ripple-bgcolor;
      }

      &.e-round,
      &.e-round-edge {
        background: $btn-round-bgcolor;
        border-color: $btn-round-border-color;
        color: $btn-round-color;

        &:hover {
          background: $btn-round-hover-bgcolor;
          border-color: $btn-round-hover-border-color;
          color: $btn-round-hover-color;
        }

        &:focus {
          background: $btn-focus-bgcolor;
          @if $skin-name == 'Material3' {
            border-image: $btn-focus-border-color;
          }
          @else {
            border-color: $btn-focus-border-color;
          }
          @if ($skin-name == 'tailwind') {
            box-shadow: $btn-focus-box-shadow;
          }
          @else {
            box-shadow: $btn-box-shadow;
          }
          color: $btn-round-focus-color;
          outline: $btn-focus-outline-round;
          outline-offset: $btn-focus-outline-offset;
        }

        &:focus:not(:focus-visible) { //mouse click
          @include fluent-focus-visible;
        }

        &:focus-visible { // only for keybord
          @if $skin-name == 'FluentUI' {
            background: $btn-focus-bgcolor;
          }
          @if $skin-name == 'Material3' {
            background: $btn-active-bgcolor;
            box-shadow: $btn-keyboard-focus-box-shadow;
          }
          @if $skin-name == 'fluent2' {
            border-color: $btn-keyboard-focus-border-color;
            box-shadow: $btn-keyboard-focus-box-shadow;
          }
          @if $skin-name == 'tailwind3' {
            background: $btn-bgcolor;
            color: $btn-color;
            box-shadow: $btn-focus-box-shadow;
          }
        }

        &:active {
          background: $btn-active-bgcolor;
          @if $skin-name == 'Material3' {
            border-image: $btn-active-border-color;
          }
          @else {
            border-color: $btn-active-border-color;
          }
          @if ($skin-name == 'tailwind') {
            box-shadow: $btn-focus-box-shadow;
          }
          @else {
            box-shadow: $btn-active-box-shadow;
          }
          color: $btn-round-active-color;
          outline: $btn-active-outline;
          outline-offset: $btn-active-outline-offset;
        }

        &:disabled,
        &.e-disabled {
          background: $btn-disabled-bgcolor;
          border-color: $btn-disabled-border-color;
          box-shadow: $btn-flat-box-shadow;
          color: $btn-disabled-color;
        }

        &.e-primary {
          &:hover {
            @if $skin-name != 'fluent2' {
              border-color: $btn-primary-bgcolor;
            }
          }

          &:focus {
            outline: $btn-focus-outline-round;
          }

          &:focus:not(:focus-visible) { //mouse click
            @include fluent-focus-visible;
          }

          &:focus-visible { // only for keybord
            @if $skin-name == 'FluentUI' {
              background: $btn-primary-focus-bgcolor;
            }
            @if $skin-name == 'Material3' {
              background: $btn-primary-active-bgcolor;
              box-shadow: $btn-keyboard-focus-box-shadow;
            }
            @if $skin-name == 'fluent2' {
              border-color: $btn-keyboard-focus-border-color;
              box-shadow: $btn-primary-focus-box-shadow;
            }
            @if $skin-name == 'tailwind3' {
              background: $btn-primary-bgcolor;
              color: $btn-primary-color;
              box-shadow: $btn-focus-box-shadow;
            }
          }
        }

        &.e-success {
          &:hover {
            @if $skin-name != 'fluent2' {
              border-color: $btn-success-bgcolor;
            }
          }

          &:focus {
            outline: $btn-focus-outline-round;
          }

          &:focus:not(:focus-visible) { //mouse click
            @include fluent-focus-visible;
          }

          &:focus-visible { // only for keybord
            @if $skin-name == 'FluentUI' {
              background: $btn-success-focus-bgcolor;
            }
            @if $skin-name == 'Material3' {
              background: $btn-success-active-bgcolor;
              box-shadow: $btn-keyboard-focus-box-shadow;
            }
            @if $skin-name == 'fluent2' {
              border-color: $btn-keyboard-focus-border-color;
              box-shadow: $btn-success-focus-box-shadow;
            }
            @if $skin-name == 'tailwind3' {
              background: $btn-success-bgcolor;
              color: $btn-success-color;
              box-shadow: $btn-focus-box-shadow;
            }
          }
        }

        &.e-info {
          &:hover {
            @if $skin-name != 'fluent2' {
              border-color: $btn-info-bgcolor;
            }
          }

          &:focus {
            outline: $btn-focus-outline-round;
          }

          &:focus:not(:focus-visible) { //mouse click
            @include fluent-focus-visible;
          }

          &:focus-visible { // only for keybord
            @if $skin-name == 'FluentUI' {
              background: $btn-info-focus-bgcolor;
            }
            @if $skin-name == 'Material3' {
              background: $btn-info-active-bgcolor;
              box-shadow: $btn-keyboard-focus-box-shadow;
            }
            @if $skin-name == 'fluent2' {
              border-color: $btn-keyboard-focus-border-color;
              box-shadow: $btn-info-focus-box-shadow;
            }
            @if $skin-name == 'tailwind3' {
              background: $btn-info-bgcolor;
              color: $btn-info-color;
              box-shadow: $btn-focus-box-shadow;
            }
          }
        }

        &.e-warning {
          &:hover {
            @if $skin-name != 'fluent2' {
              border-color: $btn-warning-bgcolor;
            }
          }

          &:focus {
            outline: $btn-focus-outline-round;
          }

          &:focus:not(:focus-visible) { //mouse click
            @include fluent-focus-visible;
          }

          &:focus-visible { // only for keybord
            @if $skin-name == 'FluentUI' {
              background: $btn-warning-focus-bgcolor;
            }
            @if $skin-name == 'Material3' {
              background: $btn-warning-active-bgcolor;
              box-shadow: $btn-keyboard-focus-box-shadow;
            }
            @if $skin-name == 'fluent2' {
              border-color: $btn-keyboard-focus-border-color;
              box-shadow: $btn-warning-focus-box-shadow;
            }
            @if $skin-name == 'tailwind3' {
              background: $btn-warning-bgcolor;
              color: $btn-warning-color;
              box-shadow: $btn-focus-box-shadow;
            }
          }
        }

        &.e-danger {
          &:hover {
            @if $skin-name != 'fluent2' {
              border-color: $btn-danger-bgcolor;
            }
          }

          &:focus {
            outline: $btn-focus-outline-round;
          }

          &:focus:not(:focus-visible) { //mouse click
            @include fluent-focus-visible;
          }

          &:focus-visible { // only for keybord
            @if $skin-name == 'FluentUI' {
              background: $btn-danger-focus-bgcolor;
            }
            @if $skin-name == 'Material3' {
              background: $btn-danger-active-bgcolor;
              box-shadow: $btn-keyboard-focus-box-shadow;
            }
            @if $skin-name == 'fluent2' {
              border-color: $btn-keyboard-focus-border-color;
              box-shadow: $btn-danger-focus-box-shadow;
            }
            @if $skin-name == 'tailwind3' {
              background: $btn-danger-bgcolor;
              color: $btn-danger-color;
              box-shadow: $btn-focus-box-shadow;
            }
          }
        }
      }

      &.e-primary {
        background: $btn-primary-bgcolor;
        border-color: $btn-primary-border-color;
        color: $btn-primary-color;
        @if $skin-name == 'Material3' {
          box-shadow: $btn-focus-box-shadow;
        }

        &:hover {
          background: $btn-primary-hover-bgcolor;
          @if $skin-name != 'Material3' {
            border-color: $btn-primary-hover-border-color;
          }
          @if $skin-name == 'Material3' {
            box-shadow: $btn-primary-focus-box-shadow;
          }
          @else {
            box-shadow: $btn-hover-focus-box-shadow;
          }
          color: $btn-primary-hover-color;
        }

        &:focus {
          @include primary-focus;
        }

        &:focus:not(:focus-visible) { //mouse click
          @include fluent-focus-visible;
        }

        &:focus-visible { // only for keybord
          @if $skin-name == 'FluentUI' {
            background: $btn-primary-focus-bgcolor;
          }
          @if $skin-name == 'Material3' {
            background: $btn-primary-active-bgcolor;
            box-shadow: $btn-keyboard-focus-box-shadow;
          }
          @if $skin-name == 'fluent2' {
            box-shadow: $btn-primary-focus-box-shadow;
            border-color: $btn-keyboard-focus-border-color;
            background-color: $primary-bg-color-focus;
          }
          @if $skin-name == 'bootstrap5.3' {
            box-shadow: $btn-primary-focus-box-shadow;
          }
          @if $skin-name == 'tailwind3' {
            background: $btn-primary-bgcolor;
            color: $btn-primary-color;
            box-shadow: $btn-focus-box-shadow;
          }
        }

        &:active {
          @include primary-active;
        }

        &.e-active {
          background: $btn-primary-active-bgcolor;
          border-color: $btn-primary-active-border-color;
          @if $skin-name == 'bootstrap4' {
            $color-rgba: rgba(mix(lighten($btn-primary-focus-bgcolor, 50%), $btn-primary-focus-border-color, 15%), .5);
            box-shadow: 0 0 0 .25em $color-rgba;
          }
          @else if $skin-name != 'bootstrap5' and $skin-name != 'bootstrap5.3' {
            box-shadow: $btn-active-box-shadow;
          }
          color: $btn-primary-active-color;
          @if $skin-name == 'fluent2' {
            background: $primary-bg-color-selected !important; /* stylelint-disable-line declaration-no-important */
            border-color: $primary-border-color-selected !important; /* stylelint-disable-line declaration-no-important */
            color: $primary-text-selected !important; /* stylelint-disable-line declaration-no-important */
          }
        }

        &:disabled,
        &.e-disabled {
          @include primary-disabled;
        }

        & .e-ripple-element {
          background: $btn-ripple-primary-bgcolor;
        }
      }

      &.e-success {
        background: $btn-success-bgcolor;
        border-color: $btn-success-border-color;
        color: $btn-success-color;
        @if $skin-name == 'Material3' {
          box-shadow: $btn-focus-box-shadow;
        }

        &:hover {
          background: $btn-success-hover-bgcolor;
          @if $skin-name != 'Material3' {
            border-color: $btn-success-hover-border-color;
          }
          @if $skin-name == 'Material3' {
            box-shadow: $btn-primary-focus-box-shadow;
          }
          @else {
            box-shadow: $btn-hover-focus-box-shadow;
          }
          color: $btn-success-hover-color;
        }

        &:focus {
          @include success-focus;
        }

        &:focus:not(:focus-visible) { //mouse click
          @include fluent-focus-visible;
        }

        &:focus-visible { // only for keybord
          @if $skin-name == 'FluentUI' {
            background: $btn-success-focus-bgcolor;
          }
          @if $skin-name == 'Material3' {
            background: $btn-success-active-bgcolor;
            box-shadow: $btn-keyboard-focus-box-shadow;
          }
          @if $skin-name == 'fluent2' {
            border-color: $btn-keyboard-focus-border-color;
            box-shadow: $btn-success-focus-box-shadow;
            background-color: $success-bg-color-focus;
            color: $success-text-focus;
          }
          @if $skin-name == 'bootstrap5.3' {
            box-shadow: $btn-success-focus-box-shadow;
          }
          @if $skin-name == 'tailwind3' {
            background: $btn-success-bgcolor;
            color: $btn-success-color;
            box-shadow: $btn-focus-box-shadow;
          }
        }

        &:active,
        &.e-active {
          @include success-active;
        }

        &.e-active {
          @if $skin-name == 'fluent2' {
            background: $success-bg-color-selected !important; /* stylelint-disable-line declaration-no-important */
            border-color: $success-border-color-selected !important; /* stylelint-disable-line declaration-no-important */
            color: $success-text-pressed !important; /* stylelint-disable-line declaration-no-important */
          }
        }

        &:disabled,
        &.e-disabled {
          @include success-disabled;
        }

        & .e-ripple-element {
          background: $btn-ripple-success-bgcolor;
        }
      }

      &.e-info {
        background: $btn-info-bgcolor;
        border-color: $btn-info-border-color;
        color: $btn-info-color;
        @if $skin-name == 'Material3' {
          box-shadow: $btn-focus-box-shadow;
        }

        &:hover {
          background: $btn-info-hover-bgcolor;
          @if $skin-name != 'Material3' {
            border-color: $btn-info-hover-border-color;
          }
          @if $skin-name == 'Material3' {
            box-shadow: $btn-primary-focus-box-shadow;
          }
          @else {
            box-shadow: $btn-hover-focus-box-shadow;
          }
          color: $btn-info-hover-color;
        }

        &:focus {
          @include info-focus;
        }

        &:focus:not(:focus-visible) { //mouse click
          @include fluent-focus-visible;
        }

        &:focus-visible { // only for keybord
          @if $skin-name == 'FluentUI' {
            background: $btn-info-focus-bgcolor;
          }
          @if $skin-name == 'Material3' {
            background: $btn-info-active-bgcolor;
            box-shadow: $btn-keyboard-focus-box-shadow;
          }
          @if $skin-name == 'fluent2' {
            border-color: $btn-keyboard-focus-border-color;
            box-shadow: $btn-info-focus-box-shadow;
            background-color: $info-bg-color-focus;
          }
          @if $skin-name == 'bootstrap5.3' {
            box-shadow: $btn-info-focus-box-shadow;
          }
          @if $skin-name == 'tailwind3' {
            background: $btn-info-bgcolor;
            color: $btn-info-color;
            box-shadow: $btn-focus-box-shadow;
          }
        }

        &:active,
        &.e-active {
          @include info-active;
        }

        &.e-active {
          @if $skin-name == 'fluent2' {
            background: $info-bg-color-selected !important; /* stylelint-disable-line declaration-no-important */
            border-color: $info-border-color-selected !important; /* stylelint-disable-line declaration-no-important */
            color: $btn-info-active-color !important; /* stylelint-disable-line declaration-no-important */
          }
        }

        &:disabled,
        &.e-disabled {
          @include info-disabled;
        }

        & .e-ripple-element {
          background: $btn-ripple-info-bgcolor;
        }
      }

      &.e-warning {
        background: $btn-warning-bgcolor;
        border-color: $btn-warning-border-color;
        color: $btn-warning-color;
        @if $skin-name == 'Material3' {
          box-shadow: $btn-focus-box-shadow;
        }

        &:hover {
          background: $btn-warning-hover-bgcolor;
          @if $skin-name != 'Material3' {
            border-color: $btn-warning-hover-border-color;
          }
          @if $skin-name == 'Material3' {
            box-shadow: $btn-primary-focus-box-shadow;
          }
          @else {
            box-shadow: $btn-hover-focus-box-shadow;
          }
          color: $btn-warning-hover-color;
        }

        &:focus {
          @include warning-focus;
        }

        &:focus:not(:focus-visible) { //mouse click
          @include fluent-focus-visible;
        }

        &:focus-visible { // only for keybord
          @if $skin-name == 'FluentUI' {
            background: $btn-warning-focus-bgcolor;
          }
          @if $skin-name == 'Material3' {
            background: $btn-warning-active-bgcolor;
            box-shadow: $btn-keyboard-focus-box-shadow;
          }
          @if $skin-name == 'fluent2' {
            border-color: $btn-keyboard-focus-border-color;
            box-shadow: $btn-warning-focus-box-shadow;
            background-color: $warning-bg-color-focus;
          }
          @if $skin-name == 'bootstrap5.3' {
            box-shadow: $btn-warning-focus-box-shadow;
          }
          @if $skin-name == 'tailwind3' {
            background: $btn-warning-bgcolor;
            color: $btn-warning-color;
            box-shadow: $btn-focus-box-shadow;
          }
        }

        &:active,
        &.e-active {
          @include warning-active;
        }

        &.e-active {
          @if $skin-name == 'fluent2' {
            background: $warning-bg-color-selected !important; /* stylelint-disable-line declaration-no-important */
            border-color: $warning-border-color-selected !important; /* stylelint-disable-line declaration-no-important */
            color: $btn-warning-active-color !important; /* stylelint-disable-line declaration-no-important */
          }
        }

        &:disabled,
        &.e-disabled {
          @include warning-disabled;
        }

        & .e-ripple-element {
          background: $btn-ripple-warning-bgcolor;
        }
      }

      &.e-danger {
        background: $btn-danger-bgcolor;
        border-color: $btn-danger-border-color;
        color: $btn-danger-color;
        @if $skin-name == 'Material3' {
          box-shadow: $btn-focus-box-shadow;
        }

        &:hover {
          background: $btn-danger-hover-bgcolor;
          @if $skin-name != 'Material3' {
            border-color: $btn-danger-hover-border-color;
          }
          @if $skin-name == 'Material3' {
            box-shadow: $btn-primary-focus-box-shadow;
          }
          @else {
            box-shadow: $btn-hover-focus-box-shadow;
          }
          color: $btn-danger-hover-color;
        }

        &:focus {
          @include danger-focus;
        }

        &:focus:not(:focus-visible) { //mouse click
          @include fluent-focus-visible;
        }

        &:focus-visible { // only for keybord
          @if $skin-name == 'FluentUI' {
            background: $btn-danger-focus-bgcolor;
          }
          @if $skin-name == 'Material3' {
            background: $btn-danger-active-bgcolor;
            box-shadow: $btn-keyboard-focus-box-shadow;
          }
          @if $skin-name == 'fluent2' {
            border-color: $btn-keyboard-focus-border-color;
            box-shadow: $btn-danger-focus-box-shadow;
            background-color: $danger-bg-color-focus;
            color: $danger-text-focus;
          }
          @if $skin-name == 'bootstrap5.3' {
            box-shadow: $btn-danger-focus-box-shadow;
          }
          @if $skin-name == 'tailwind3' {
            background: $btn-danger-bgcolor;
            color: $btn-danger-color;
            box-shadow: $btn-focus-box-shadow;
          }
        }

        &:active {
          @include danger-active;
        }

        &.e-active {
          background: $btn-danger-active-bgcolor;
          border-color: $btn-danger-active-border-color;
          @if $skin-name == 'bootstrap4' {
            $color-rgba: rgba(mix(lighten($btn-primary-focus-bgcolor, 50%), $btn-primary-focus-border-color, 15%), .5);
            box-shadow: 0 0 0 .25em $color-rgba;
          }
          @else if $skin-name != 'bootstrap5' and $skin-name != 'bootstrap5.3' {
            box-shadow: $btn-active-box-shadow;
          }
          color: $btn-danger-active-color;
          @if $skin-name == 'fluent2' {
            background: $danger-bg-color-selected !important; /* stylelint-disable-line declaration-no-important */
            border-color: $danger-border-color-selected !important; /* stylelint-disable-line declaration-no-important */
            color: $danger-text-pressed !important; /* stylelint-disable-line declaration-no-important */
          }
        }

        &:disabled,
        &.e-disabled {
          @include danger-disabled;
        }

        & .e-ripple-element {
          background: $btn-ripple-danger-bgcolor;
        }
      }

      &.e-flat {
        background: $btn-flat-bgcolor;
        border-color: $btn-flat-border-color;
        box-shadow: $btn-flat-box-shadow;
        color: $btn-flat-color;

        &:hover {
          background: $btn-flat-hover-bgcolor;
          border-color: $btn-flat-hover-border-color;
          box-shadow: $btn-flat-box-shadow;
          color: $btn-flat-hover-color;
        }

        &:focus {
          background: $btn-flat-focus-bgcolor;
          border-color: $btn-flat-focus-border-color;
          color: $btn-flat-focus-color;
          @if $skin-name == 'bootstrap4' {
            $color-rgba: rgba(mix(lighten($btn-flat-focus-bgcolor, 50%), $btn-flat-focus-border-color, 15%), .5);
            box-shadow: 0 0 0 .25em $color-rgba;
          }
          @else if ($skin-name == 'tailwind' or $skin-name == 'bootstrap5') {
            box-shadow: $btn-focus-box-shadow;
          }
          @else if $skin-name != 'bootstrap5.3' {
            box-shadow: $btn-flat-box-shadow;
          }
        }

        &:focus:not(:focus-visible) { //mouse click
          @include fluent-focus-visible;
        }

        &:focus-visible { // only for keybord
          @if $skin-name == 'Material3' {
            background: $btn-active-bgcolor;
            box-shadow: $btn-keyboard-focus-box-shadow;
          }
          @if $skin-name == 'fluent2' {
            border-color: $btn-keyboard-focus-border-color;
            box-shadow: $btn-keyboard-focus-box-shadow;
          }
          @if $skin-name == 'bootstrap5.3' {
            box-shadow: $btn-focus-box-shadow;
          }
          @if $skin-name == 'tailwind3' {
            background: $btn-bgcolor;
            color: $btn-color;
            box-shadow: $btn-focus-box-shadow;
          }
        }

        &:active,
        &.e-active {
          background: $btn-flat-active-bgcolor;
          border-color: $btn-flat-active-border-color;
          color: $btn-flat-active-color;
          @if $skin-name == 'bootstrap4' {
            $color-rgba: rgba(mix(lighten($btn-flat-active-bgcolor, 50%), $btn-flat-active-border-color, 15%), .5);
            box-shadow: 0 0 0 .25em $color-rgba;
          }
          @else if $skin-name != 'bootstrap5' and $skin-name != 'bootstrap5.3' {
            box-shadow: $btn-flat-active-box-shadow;
          }
        }

        &:disabled,
        &.e-disabled {
          background: $btn-flat-disabled-bgcolor;
          border-color: $btn-flat-disabled-border-color;
          box-shadow: $btn-flat-box-shadow;
          color: $btn-flat-disabled-color;
        }

        & .e-ripple-element {
          background: $btn-ripple-flat-bgcolor;
        }

        &.e-primary {
          background: $btn-flat-primary-bgcolor;
          border-color: $btn-flat-primary-border-color;
          color: $btn-flat-primary-color;

          &:hover {
            background: $btn-flat-primary-hover-bgcolor;
            border-color: $btn-flat-primary-hover-border-color;
            color: $btn-flat-primary-hover-color;
          }

          &:focus {
            background: $btn-flat-primary-focus-bgcolor;
            border-color: $btn-flat-primary-focus-border-color;
            color: $btn-flat-primary-focus-color;
            @if $skin-name == 'bootstrap4' {
              $color-rgba: rgba(mix(lighten($btn-flat-primary-focus-bgcolor, 50%), $btn-flat-primary-focus-border-color, 15%), .5);
              box-shadow: 0 0 0 .25em $color-rgba;
            }
            @else if ($skin-name == 'tailwind') {
              box-shadow: $btn-focus-box-shadow;
            }
            @else if ($skin-name == 'bootstrap5') {
              box-shadow: $btn-primary-focus-box-shadow;
            }
          }

          &:focus:not(:focus-visible) { //mouse click
            @include fluent-focus-visible;
          }

          &:focus-visible { // only for keybord
            @if $skin-name == 'Material3' {
              background: $btn-primary-active-bgcolor;
              box-shadow: $btn-keyboard-focus-box-shadow;
              color: $btn-primary-color;
            }
            @if $skin-name == 'fluent2' {
              border-color: $btn-keyboard-focus-border-color;
              box-shadow: $btn-primary-focus-box-shadow;
              background-color: $primary-bg-color-focus;
            }
            @if $skin-name == 'bootstrap5.3' {
              box-shadow: $btn-primary-focus-box-shadow;
            }
            @if $skin-name == 'tailwind3' {
              background: $btn-primary-bgcolor;
              color: $btn-primary-color;
              box-shadow: $btn-focus-box-shadow;
            }
          }

          &:active,
          &.e-active {
            background: $btn-flat-primary-active-bgcolor;
            border-color: $btn-flat-primary-active-border-color;
            color: $btn-flat-primary-active-color;
            @if $skin-name == 'bootstrap4' {
              $color-rgba: rgba(mix(lighten($btn-flat-primary-active-bgcolor, 50%), $btn-flat-primary-active-border-color, 15%), .5);
              box-shadow: 0 0 0 .25em $color-rgba;
            }
          }

          &:disabled,
          &.e-disabled {
            background: $btn-flat-primary-disabled-bgcolor;
            border-color: $btn-flat-primary-disabled-border-color;
            box-shadow: $btn-flat-box-shadow;
            color: $btn-flat-primary-disabled-color;
          }

          & .e-ripple-element {
            background: $btn-ripple-flat-primary-bgcolor;
          }
        }

        &.e-success {
          background: $btn-flat-success-bgcolor;
          border-color: $btn-flat-success-border-color;
          color: $btn-flat-success-color;

          &:hover {
            background: $btn-flat-success-hover-bgcolor;
            border-color: $btn-flat-success-hover-border-color;
            box-shadow: $btn-flat-box-shadow;
            color: $btn-flat-success-hover-color;
          }

          &:focus {
            background: $btn-flat-success-focus-bgcolor;
            border-color: $btn-flat-success-focus-border-color;
            color: $btn-flat-success-focus-color;
            @if $skin-name == 'bootstrap4' {
              $color-rgba: rgba(mix(lighten($btn-flat-success-focus-bgcolor, 50%), $btn-flat-success-focus-border-color, 15%), .5);
              box-shadow: 0 0 0 .25em $color-rgba;
            }
            @else if ($skin-name == 'tailwind') {
              box-shadow: $btn-focus-box-shadow;
            }
            @else if ($skin-name == 'bootstrap5') {
              box-shadow: $btn-success-focus-box-shadow;
            }
            @else if $skin-name != 'bootstrap5.3' {
              box-shadow: $btn-flat-box-shadow;
            }
          }

          &:focus:not(:focus-visible) { //mouse click
            @include fluent-focus-visible;
          }

          &:focus-visible { // only for keybord
            @if $skin-name == 'Material3' {
              background: $btn-success-active-bgcolor;
              box-shadow: $btn-keyboard-focus-box-shadow;
              color: $btn-success-color;
            }
            @if $skin-name == 'fluent2' {
              border-color: $btn-keyboard-focus-border-color;
              box-shadow: $btn-success-focus-box-shadow;
              background-color: $success-bg-color-focus;
              color: $success-text-focus;
            }
            @if $skin-name == 'bootstrap5.3' {
              box-shadow: $btn-success-focus-box-shadow;
            }
            @if $skin-name == 'tailwind3' {
              background: $btn-success-bgcolor;
              color: $btn-success-color;
              box-shadow: $btn-focus-box-shadow;
            }
          }

          &:active,
          &.e-active {
            background: $btn-flat-success-active-bgcolor;
            border-color: $btn-flat-success-active-border-color;
            color: $btn-flat-success-active-color;
            @if $skin-name == 'bootstrap4' {
              $color-rgba: rgba(mix(lighten($btn-flat-success-active-bgcolor, 50%), $btn-flat-success-active-border-color, 15%), .5);
              box-shadow: 0 0 0 .25em $color-rgba;
            }
            @else if $skin-name != 'bootstrap5' and $skin-name != 'bootstrap5.3' {
              box-shadow: $btn-flat-active-box-shadow;
            }
          }

          &:disabled,
          &.e-disabled {
            background: $btn-flat-success-disabled-bgcolor;
            border-color: $btn-flat-success-disabled-border-color;
            color: $btn-flat-success-disabled-color;
          }

          & .e-ripple-element {
            background: $btn-ripple-flat-success-bgcolor;
          }
        }

        &.e-info {
          background: $btn-flat-info-bgcolor;
          border-color: $btn-flat-info-border-color;
          color: $btn-flat-info-color;

          &:hover {
            background: $btn-flat-info-hover-bgcolor;
            border-color: $btn-flat-info-hover-border-color;
            box-shadow: $btn-flat-box-shadow;
            color: $btn-flat-info-hover-color;
          }

          &:focus {
            background: $btn-flat-info-focus-bgcolor;
            border-color: $btn-flat-info-focus-border-color;
            color: $btn-flat-info-focus-color;
            @if $skin-name == 'bootstrap4' {
              $color-rgba: rgba(mix(lighten($btn-flat-info-focus-bgcolor, 50%), $btn-flat-info-focus-border-color, 15%), .5);
              box-shadow: 0 0 0 .25em $color-rgba;
            }
            @else if ($skin-name == 'tailwind') {
              box-shadow: $btn-focus-box-shadow;
            }
            @else if ($skin-name == 'bootstrap5') {
              box-shadow: $btn-info-focus-box-shadow;
            }
            @else if $skin-name != 'bootstrap5.3' {
              box-shadow: $btn-flat-box-shadow;
            }
          }

          &:focus:not(:focus-visible) { //mouse click
            @include fluent-focus-visible;
          }

          &:focus-visible { // only for keybord
            @if $skin-name == 'Material3' {
              background: $btn-info-active-bgcolor;
              box-shadow: $btn-keyboard-focus-box-shadow;
              color: $btn-info-color;
            }
            @if $skin-name == 'fluent2' {
              border-color: $btn-keyboard-focus-border-color;
              box-shadow: $btn-info-focus-box-shadow;
              background-color: $info-bg-color-focus;
            }
            @if $skin-name == 'bootstrap5.3' {
              box-shadow: $btn-info-focus-box-shadow;
            }
            @if $skin-name == 'tailwind3' {
              background: $btn-info-bgcolor;
              color: $btn-info-color;
              box-shadow: $btn-focus-box-shadow;
            }
          }

          &:active,
          &.e-active {
            background: $btn-flat-info-active-bgcolor;
            border-color: $btn-flat-info-active-border-color;
            color: $btn-flat-info-active-color;
            @if $skin-name == 'bootstrap4' {
              $color-rgba: rgba(mix(lighten($btn-flat-info-active-bgcolor, 50%), $btn-flat-info-active-border-color, 15%), .5);
              box-shadow: 0 0 0 .25em $color-rgba;
            }
            @else if $skin-name != 'bootstrap5' and $skin-name != 'bootstrap5.3' {
              box-shadow: $btn-flat-active-box-shadow;
            }
          }

          &:disabled,
          &.e-disabled {
            background: $btn-flat-info-disabled-bgcolor;
            border-color: $btn-flat-info-disabled-border-color;
            color: $btn-flat-info-disabled-color;
          }

          & .e-ripple-element {
            background: $btn-ripple-flat-info-bgcolor;
          }
        }

        &.e-warning {
          background: $btn-flat-warning-bgcolor;
          border-color: $btn-flat-warning-border-color;
          color: $btn-flat-warning-color;

          &:hover {
            background: $btn-flat-warning-hover-bgcolor;
            border-color: $btn-flat-warning-hover-border-color;
            box-shadow: $btn-flat-box-shadow;
            color: $btn-flat-warning-hover-color;
          }

          &:focus {
            background: $btn-flat-warning-focus-bgcolor;
            border-color: $btn-flat-warning-focus-border-color;
            color: $btn-flat-warning-focus-color;
            @if $skin-name == 'bootstrap4' {
              $color-rgba: rgba(mix(lighten($btn-flat-warning-focus-bgcolor, 50%), $btn-flat-warning-focus-border-color, 15%), .5);
              box-shadow: 0 0 0 .25em $color-rgba;
            }
            @else if ($skin-name == 'tailwind') {
              box-shadow: $btn-focus-box-shadow;
            }
            @else if ($skin-name == 'bootstrap5') {
              box-shadow: $btn-warning-focus-box-shadow;
            }
            @else if $skin-name != 'bootstrap5.3' {
              box-shadow: $btn-flat-box-shadow;
            }
          }

          &:focus:not(:focus-visible) { //mouse click
            @include fluent-focus-visible;
          }

          &:focus-visible { // only for keybord
            @if $skin-name == 'Material3' {
              background: $btn-warning-active-bgcolor;
              box-shadow: $btn-keyboard-focus-box-shadow;
              color: $btn-warning-color;
            }
            @if $skin-name == 'fluent2' {
              border-color: $btn-keyboard-focus-border-color;
              box-shadow: $btn-warning-focus-box-shadow;
              background-color: $warning-bg-color-focus;
            }
            @if $skin-name == 'bootstrap5.3' {
              box-shadow: $btn-warning-focus-box-shadow;
            }
            @if $skin-name == 'tailwind3' {
              background: $btn-warning-bgcolor;
              color: $btn-warning-color;
              box-shadow: $btn-focus-box-shadow;
            }
          }

          &:active,
          &.e-active {
            background: $btn-flat-warning-active-bgcolor;
            border-color: $btn-flat-warning-active-border-color;
            color: $btn-flat-warning-active-color;
            @if $skin-name == 'bootstrap4' {
              $color-rgba: rgba(mix(lighten($btn-flat-warning-active-bgcolor, 50%), $btn-flat-warning-active-border-color, 15%), .5);
              box-shadow: 0 0 0 .25em $color-rgba;
            }
            @else if $skin-name != 'bootstrap5' and $skin-name != 'bootstrap5.3' {
              box-shadow: $btn-flat-active-box-shadow;
            }
          }

          &:disabled,
          &.e-disabled {
            background: $btn-flat-warning-disabled-bgcolor;
            border-color: $btn-flat-warning-disabled-border-color;
            color: $btn-flat-warning-disabled-color;
          }

          & .e-ripple-element {
            background: $btn-ripple-flat-warning-bgcolor;
          }
        }

        &.e-danger {
          background: $btn-flat-danger-bgcolor;
          border-color: $btn-flat-danger-border-color;
          color: $btn-flat-danger-color;

          &:hover {
            background: $btn-flat-danger-hover-bgcolor;
            border-color: $btn-flat-danger-hover-border-color;
            box-shadow: $btn-flat-box-shadow;
            color: $btn-flat-danger-hover-color;
          }

          &:focus {
            background: $btn-flat-danger-focus-bgcolor;
            border-color: $btn-flat-danger-focus-border-color;
            color: $btn-flat-danger-focus-color;
            @if $skin-name == 'bootstrap4' {
              $color-rgba: rgba(mix(lighten($btn-flat-danger-focus-bgcolor, 50%), $btn-flat-danger-focus-border-color, 15%), .5);
              box-shadow: 0 0 0 .25em $color-rgba;
            }
            @else if ($skin-name == 'tailwind') {
              box-shadow: $btn-focus-box-shadow;
            }
            @else if ($skin-name == 'bootstrap5') {
              box-shadow: $btn-danger-focus-box-shadow;
            }
            @else if $skin-name != 'bootstrap5.3' {
              box-shadow: $btn-flat-box-shadow;
            }
          }

          &:focus:not(:focus-visible) { //mouse click
            @include fluent-focus-visible;
          }

          &:focus-visible { // only for keybord
            @if $skin-name == 'Material3' {
              background: $btn-danger-active-bgcolor;
              box-shadow: $btn-keyboard-focus-box-shadow;
              color: $btn-danger-color;
            }
            @if $skin-name == 'fluent2' {
              border-color: $btn-keyboard-focus-border-color;
              box-shadow: $btn-danger-focus-box-shadow;
              background-color: $danger-bg-color-focus;
              color: $danger-text-focus;
            }
            @if $skin-name == 'bootstrap5.3' {
              box-shadow: $btn-danger-focus-box-shadow;
            }
            @if $skin-name == 'tailwind3' {
              background: $btn-danger-bgcolor;
              color: $btn-danger-color;
              box-shadow: $btn-focus-box-shadow;
            }
          }

          &:active,
          &.e-active {
            background: $btn-flat-danger-active-bgcolor;
            border-color: $btn-flat-danger-active-border-color;
            color: $btn-flat-danger-active-color;
            @if $skin-name == 'bootstrap4' {
              $color-rgba: rgba(mix(lighten($btn-flat-danger-active-bgcolor, 50%), $btn-flat-danger-active-border-color, 15%), .5);
              box-shadow: 0 0 0 .25em $color-rgba;
            }
            @else if $skin-name != 'bootstrap5' and $skin-name != 'bootstrap5.3' {
              box-shadow: $btn-flat-active-box-shadow;
            }
          }

          &:disabled,
          &.e-disabled {
            background: $btn-flat-danger-disabled-bgcolor;
            border-color: $btn-flat-danger-disabled-border-color;
            color: $btn-flat-danger-disabled-color;
          }

          & .e-ripple-element {
            background: $btn-ripple-flat-danger-bgcolor;
          }
        }
      }

      &.e-outline {
        background: $btn-outline-bgcolor;
        border-color: $btn-outline-border-color;
        box-shadow: $btn-flat-box-shadow;
        color: $btn-outline-color;
        @if $skin-name == 'Material3' {
          border: 1px solid;
        }

        &:hover {
          @if $skin-name == 'bootstrap4' {
            background: $btn-bgcolor;
          }
          @else {
            background: $btn-outline-hover-bgcolor;
          }
          border-color: $btn-outline-hover-border-color;
          box-shadow: $btn-flat-box-shadow;
          color: $btn-outline-hover-color;
        }

        &:focus {
          @include outline-focus;
        }

        &:focus:not(:focus-visible) { //mouse click
          @include fluent-focus-visible;
          @if $skin-name == 'fluent2' {
            background: transparent;
            border-color: $secondary-border-color-hover;
            color: $secondary-outline-button-text-color-pressed;
          }

          @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
            background: $btn-outline-active-bgcolor;
            color: $btn-outline-active-color;
          }
        }

        &:focus-visible { // only for keybord
          @if $skin-name == 'FluentUI' {
            background: $btn-outline-bgcolor;
          }

          @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
            box-shadow: $btn-focus-box-shadow;
          }
          @if $skin-name == 'bootstrap5.3' {
            background-color: $secondary-bg-color-focus;
            color: $secondary-text-color-focus;
          }
          @if $skin-name == 'Material3' {
            background: $btn-active-bgcolor;
            box-shadow: $btn-keyboard-focus-box-shadow;
          }
          @if $skin-name == 'fluent2' {
            border-color: $btn-keyboard-focus-border-color;
            box-shadow: $btn-keyboard-focus-box-shadow;
          }
          @if $skin-name == 'tailwind3' {
            background: $transparent;
            color: $btn-color;
            box-shadow: $btn-focus-box-shadow;
          }
        }

        &:active,
        &.e-active {
          @include outline-active;
          @if $skin-name == 'fluent2' {
            background: transparent;
            color: $secondary-outline-button-text-color-pressed !important; /* stylelint-disable-line declaration-no-important */
            border-color: $btn-outline-active-border-color !important; /* stylelint-disable-line declaration-no-important */
            outline: 2px solid $secondary-outline-button-text-color-pressed;
          }
        }

        &.e-active {
          @if $skin-name == 'fluent2' {
            background: transparent !important; /* stylelint-disable-line declaration-no-important */
            border-color: $btn-outline-active-border-color !important; /* stylelint-disable-line declaration-no-important */
            outline: 2px solid $secondary-outline-button-text-color-pressed;
          }
        }

        &:disabled,
        &.e-disabled {
          @include outline-disabled;
        }

        &.e-primary {
          background: $btn-outline-bgcolor;
          @if $skin-name == 'Material3' {
            border: 1px solid $btn-outline-border-color;
            color: rgba($btn-outline-primary-color);
          }
          @else {
            border-color: $btn-outline-primary-color;
            color: $btn-outline-primary-color;
          }

          &:hover {
            @if $skin-name == 'bootstrap4' {
              background: $btn-primary-bgcolor;
            }
            @if $skin-name == 'Material3' {
              background: $btn-outline-primary-hover-bgcolor;
              border: 1px solid $btn-outline-border-color;
              color: rgba($btn-outline-primary-color);
            }
            @else if $skin-name == 'fluent2' {
              background: $btn-outline-success-hover-bgcolor;
              border-color: $btn-outline-primary-hover-border-color;
              color: $btn-primary-hover-bgcolor;
            }
            @else {
              background: $btn-primary-hover-bgcolor;
              border-color: $btn-outline-primary-hover-border-color;
              color: $btn-primary-hover-color;
            }
          }

          &:focus {
            @include outline-primary-focus;
          }

          &:focus:not(:focus-visible) { //mouse click
            @include fluent-focus-visible;
            @if $skin-name == 'fluent2' {
              background: transparent;
              border-color: $primary-border-color-hover;
              color: $primary-border-color-hover;
            }

            @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
              background: $btn-outline-primary-focus-bgcolor;
              color: $btn-outline-active-color;
            }
          }

          &:focus-visible { // only for keybord
            @if $skin-name == 'FluentUI' {
              background: $btn-outline-bgcolor;
            }

            @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
              box-shadow: $btn-primary-focus-box-shadow;
            }
            @if $skin-name == 'bootstrap5.3' {
              background-color: $primary-bg-color-focus;
              color: $primary-text-color;
            }
            @if $skin-name == 'Material3' {
              background: $btn-primary-active-bgcolor;
              box-shadow: $btn-keyboard-focus-box-shadow;
              color: $btn-primary-color;
            }
            @if $skin-name == 'fluent2' {
              border-color: $btn-keyboard-focus-border-color;
              box-shadow: $btn-primary-focus-box-shadow;
            }
            @if $skin-name == 'tailwind3' {
              background: $transparent;
              color: $btn-primary-bgcolor;
              box-shadow: $btn-focus-box-shadow;
            }
          }

          &:active,
          &.e-active {
            @include outline-primary-active;
            @if $skin-name == 'fluent2' {
              background: transparent;
              color: $primary-bg-color-pressed !important; /* stylelint-disable-line declaration-no-important */
              border-color: $btn-outline-primary-active-border-color !important; /* stylelint-disable-line declaration-no-important */
              outline: 2px solid $primary-bg-color-pressed;
            }
          }

          &.e-active {
            @if $skin-name == 'fluent2' {
              background: transparent !important; /* stylelint-disable-line declaration-no-important */
              color: $primary-bg-color-pressed !important; /* stylelint-disable-line declaration-no-important */
              border-color: $btn-outline-primary-active-border-color !important; /* stylelint-disable-line declaration-no-important */
            }
          }

          &:disabled,
          &.e-disabled {
            @include outline-primary-disabled;
          }
        }

        &.e-success {
          background: $btn-outline-bgcolor;
          @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'bootstrap5-dark' {
            border-color: $success-outline;
            color: $success-outline;
          }
          @if $skin-name == 'Material3' {
            border: 1px solid $btn-outline-border-color;
            color: rgba($btn-outline-success-color);
          }
          @else {
            border-color: $btn-success-bgcolor;
            color: $btn-success-bgcolor;
          }

          &:hover {
            @if $skin-name == 'bootstrap4' {
              background: $btn-success-bgcolor;
            }
            @if $skin-name == 'Material3' {
              background: $btn-outline-success-hover-bgcolor;
              border: 1px solid $btn-outline-border-color;
              color: rgba($btn-outline-success-color);
            }
            @else if $skin-name == 'fluent2' {
              background: $btn-outline-success-hover-bgcolor;
              border-color: $btn-success-hover-border-color;
              color: $btn-success-hover-bgcolor;
            }
            @else {
              background: $btn-success-hover-bgcolor;
              border-color: $btn-success-hover-border-color;
              color: $btn-success-color;
            }
          }

          &:focus {
            @include outline-success-focus;
          }

          &:focus:not(:focus-visible) { //mouse click
            @include fluent-focus-visible;
            @if $skin-name == 'fluent2' {
              background: transparent;
              border-color: $success-border-color-hover;
              color: $success-border-color-hover;
            }

            @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
              background: $btn-success-bgcolor;
              color: $btn-success-color;
            }
          }

          &:focus-visible { // only for keybord
            @if $skin-name == 'FluentUI' {
              background: $btn-outline-bgcolor;
            }
            @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
              box-shadow: $btn-success-focus-box-shadow;
            }
            @if $skin-name == 'bootstrap5.3' {
              background-color: $success-bg-color-focus;
              color: $success-text-focus;
            }
            @if $skin-name == 'Material3' {
              background: $btn-success-active-bgcolor;
              box-shadow: $btn-keyboard-focus-box-shadow;
              color: $btn-success-color;
            }
            @if $skin-name == 'fluent2' {
              border-color: $btn-keyboard-focus-border-color;
              box-shadow: $btn-success-focus-box-shadow;
            }
            @if $skin-name == 'tailwind3' {
              background: $transparent;
              color: $btn-success-bgcolor;
              box-shadow: $btn-focus-box-shadow;
            }
          }

          &:active,
          &.e-active {
            @include outline-success-active;
            @if $skin-name == 'fluent2' {
              background: transparent;
              color: $success-bg-color-pressed !important; /* stylelint-disable-line declaration-no-important */
              border-color: $btn-success-active-border-color !important; /* stylelint-disable-line declaration-no-important */
              outline: 2px solid $success-bg-color-pressed;
            }
          }

          &.e-active {
            @if $skin-name == 'fluent2' {
              background: transparent !important; /* stylelint-disable-line declaration-no-important */
              border-color: $success-border-color-selected !important; /* stylelint-disable-line declaration-no-important */
            }
          }

          &:disabled,
          &.e-disabled {
            @include outline-success-disabled;
          }
        }

        &.e-info {
          background: $btn-outline-bgcolor;
          @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'bootstrap5-dark' {
            border-color: $info-outline;
            color: $info-outline;
          }
          @if $skin-name == 'Material3' {
            border: 1px solid $btn-outline-border-color;
            color: rgba($btn-outline-info-color);
          }
          @else {
            border-color: $btn-info-bgcolor;
            color: $btn-info-bgcolor;
          }

          &:hover {
            @if $skin-name == 'bootstrap4' {
              background: $btn-info-bgcolor;
            }
            @if $skin-name == 'Material3' {
              background: $btn-outline-info-hover-bgcolor;
              border: 1px solid $btn-outline-border-color;
              color: rgba($btn-outline-info-color);
            }
            @else if $skin-name == 'fluent2' {
              background: $btn-outline-info-hover-bgcolor;
              border-color: $btn-info-hover-border-color;
              color: $btn-info-hover-bgcolor;
            }
            @else {
              background: $btn-info-hover-bgcolor;
              border-color: $btn-info-hover-border-color;
              color: $btn-info-color;
            }
          }

          &:focus {
            @include outline-info-focus;
          }

          &:focus:not(:focus-visible) { //mouse click
            @include fluent-focus-visible;
            @if $skin-name == 'fluent2' {
              background: transparent;
              border-color: $info-bg-color-hover;
              color: $info-bg-color-hover;
            }

            @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
              background: $btn-info-bgcolor;
              color: $btn-info-color;
            }
          }

          &:focus-visible { // only for keybord
            @if $skin-name == 'FluentUI' {
              background: $btn-outline-bgcolor;
            }
            @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
              box-shadow: $btn-info-focus-box-shadow;
            }
            @if $skin-name == 'bootstrap5.3' {
              background-color: $info-bg-color-focus;
              color: $info-text-focus;
            }
            @if $skin-name == 'Material3' {
              background: $btn-info-active-bgcolor;
              box-shadow: $btn-keyboard-focus-box-shadow;
              color: $btn-info-color;
            }
            @if $skin-name == 'fluent2' {
              border-color: $btn-keyboard-focus-border-color;
              box-shadow: $btn-info-focus-box-shadow;
            }
            @if $skin-name == 'tailwind3' {
              background: $transparent;
              color: $btn-info-bgcolor;
              box-shadow: $btn-focus-box-shadow;
            }
          }

          &:active,
          &.e-active {
            @include outline-info-active;
            @if $skin-name == 'fluent2' {
              background: transparent;
              color: $info-bg-color-pressed !important; /* stylelint-disable-line declaration-no-important */
              border-color: $btn-info-active-border-color !important; /* stylelint-disable-line declaration-no-important */
              outline: 2px solid $info-bg-color-pressed;
            }
          }

          &.e-active {
            @if $skin-name == 'fluent2' {
              background: transparent !important; /* stylelint-disable-line declaration-no-important */
              border-color: $info-border-color-selected !important; /* stylelint-disable-line declaration-no-important */
            }
          }

          &:disabled,
          &.e-disabled {
            @include outline-info-disabled;
          }
        }

        &.e-warning {
          background: $btn-outline-bgcolor;
          @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'bootstrap5-dark' {
            border-color: $warning-outline;
            color: $warning-outline;
          }
          @if $skin-name == 'Material3' {
            border: 1px solid $btn-outline-border-color;
            color: rgba($btn-outline-warning-color);
          }
          @else {
            border-color: $btn-warning-bgcolor;
            color: $btn-warning-bgcolor;
          }

          &:hover {
            @if $skin-name == 'bootstrap4' {
              background: $btn-warning-bgcolor;
            }
            @if $skin-name == 'Material3' {
              background: $btn-outline-warning-hover-bgcolor;
              border: 1px solid $btn-outline-border-color;
              color: rgba($btn-outline-warning-color);
            }
            @else if $skin-name == 'fluent2' {
              background: $btn-outline-warning-hover-bgcolor;
              border-color: $btn-warning-hover-border-color;
              color: $btn-warning-hover-bgcolor;
            }
            @else {
              background: $btn-warning-hover-bgcolor;
              border-color: $btn-warning-hover-border-color;
              color: $btn-warning-color;
            }
          }

          &:focus {
            @include outline-warning-focus;
          }

          &:focus:not(:focus-visible) { //mouse click
            @include fluent-focus-visible;
            @if $skin-name == 'fluent2' {
              background: transparent;
              border-color: $warning-border-color-hover;
              color: $warning-border-color-hover;
            }

            @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
              background: $btn-warning-bgcolor;
              color: $btn-warning-color;
            }
          }

          &:focus-visible { // only for keybord
            @if $skin-name == 'FluentUI' {
              background: $btn-outline-bgcolor;
            }
            @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
              box-shadow: $btn-warning-focus-box-shadow;
            }
            @if $skin-name == 'bootstrap5.3' {
              background-color: $warning-bg-color-focus;
              color: $warning-text-focus;
            }
            @if $skin-name == 'Material3' {
              background: $btn-warning-active-bgcolor;
              box-shadow: $btn-keyboard-focus-box-shadow;
              color: $btn-warning-color;
            }
            @if $skin-name == 'fluent2' {
              border-color: $btn-keyboard-focus-border-color;
              box-shadow: $btn-warning-focus-box-shadow;
            }
            @if $skin-name == 'tailwind3' {
              background: $transparent;
              color: $btn-warning-bgcolor;
              box-shadow: $btn-focus-box-shadow;
            }
          }

          &:active,
          &.e-active {
            @include outline-warning-active;
            @if $skin-name == 'fluent2' {
              background: transparent;
              color: $warning-bg-color-pressed !important; /* stylelint-disable-line declaration-no-important */
              border-color: $btn-warning-active-border-color !important; /* stylelint-disable-line declaration-no-important */
              outline: 2px solid $warning-bg-color-pressed;
            }
          }

          &.e-active {
            @if $skin-name == 'fluent2' {
              background: transparent !important; /* stylelint-disable-line declaration-no-important */
              border-color: $warning-border-color-selected !important; /* stylelint-disable-line declaration-no-important */
            }
          }

          &:disabled,
          &.e-disabled {
            @include outline-warning-disabled;
          }
        }

        &.e-danger {
          background: $btn-outline-bgcolor;
          @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'bootstrap5-dark' {
            border-color: $danger-outline;
            color: $danger-outline;
          }
          @if $skin-name == 'Material3' {
            border: 1px solid $btn-outline-border-color;
            color: rgba($btn-outline-danger-color);
          }
          @else {
            border-color: $btn-danger-bgcolor;
            color: $btn-danger-bgcolor;
          }

          &:hover {
            @if $skin-name == 'bootstrap4' {
              background: $btn-danger-bgcolor;
            }
            @if $skin-name == 'Material3' {
              background: $btn-outline-danger-hover-bgcolor;
              border: 1px solid $btn-outline-border-color;
              color: rgba($btn-outline-danger-color);
            }
            @if $skin-name == 'fluent2' {
              background: $btn-outline-danger-hover-bgcolor;
              border-color: $btn-danger-hover-border-color;
              color: $btn-danger-hover-bgcolor;
            }
            @else {
              background: $btn-danger-hover-bgcolor;
              border-color: $btn-danger-hover-border-color;
              color: $btn-danger-color;
            }
          }

          &:focus {
            @include outline-danger-focus;
          }

          &:focus:not(:focus-visible) { //mouse click
            @include fluent-focus-visible;
            @if $skin-name == 'fluent2' {
              background: transparent;
              border-color: $danger-border-color-hover;
              color: $danger-border-color-hover;
            }

            @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
              background: $btn-danger-bgcolor;
              color: $btn-danger-color;
            }
          }

          &:focus-visible { // only for keybord
            @if $skin-name == 'FluentUI' {
              background: $btn-outline-bgcolor;
            }
            @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
              box-shadow: $btn-danger-focus-box-shadow;
            }
            @if $skin-name == 'bootstrap5.3' {
              background-color: $danger-bg-color-focus;
              color: $danger-text-focus;
            }
            @if $skin-name == 'Material3' {
              background: $btn-danger-active-bgcolor;
              box-shadow: $btn-keyboard-focus-box-shadow;
              color: $btn-danger-color;
            }
            @if $skin-name == 'fluent2' {
              border-color: $btn-keyboard-focus-border-color;
              box-shadow: $btn-danger-focus-box-shadow;
            }
            @if $skin-name == 'tailwind3' {
              background: $transparent;
              color: $btn-danger-bgcolor;
              box-shadow: $btn-focus-box-shadow;
            }
          }

          &:active,
          &.e-active {
            @include outline-danger-active;
            @if $skin-name == 'fluent2' {
              background: transparent;
              color: $danger-bg-color-pressed !important; /* stylelint-disable-line declaration-no-important */
              border-color: $btn-danger-active-border-color !important; /* stylelint-disable-line declaration-no-important */
              outline: 2px solid $danger-bg-color-pressed;
            }
          }

          &.e-active {
            @if $skin-name == 'fluent2' {
              background: transparent !important; /* stylelint-disable-line declaration-no-important */
              border-color: $danger-border-color-selected !important; /* stylelint-disable-line declaration-no-important */
            }
          }

          &:disabled,
          &.e-disabled {
            @include outline-danger-disabled;
          }
        }
      }

      &.e-link {
        background: $btn-link-bgcolor;
        border-color: $btn-link-border-color;
        border-radius: 0;
        box-shadow: none;
        color: $btn-link-color;

        &:hover {
          @include link-hover;
        }

        &:focus {
          @include link-focus;
        }

        &:focus:not(:focus-visible) {
          outline: none !important; /* stylelint-disable-line declaration-no-important */
        }

        &:focus-visible { // only for keybord
          @if $skin-name == 'Material3' {
            box-shadow: $btn-keyboard-focus-box-shadow;
          }
          @if $skin-name == 'fluent2' {
            text-decoration-color: $btn-link-focus-underline-color !important; /* stylelint-disable-line declaration-no-important */
            text-decoration: underline;
            text-decoration-style: double;
          }
        }

        &:active,
        &.e-active {
          @if $skin-name == 'tailwind3' {
            color: $btn-link-active-color;
            text-decoration: underline;
          }
        }

        &:disabled {
          @include link-disabled;
        }
      }

      &.e-inherit {
        color: inherit;
        background: inherit;
        border-color: transparent;
        box-shadow: none;

        &:hover,
        &:focus,
        &:active,
        &.e-active {
          background: rgba(transparent, .056);
          border-color: transparent;
          box-shadow: none;
          color: inherit;
          outline: none;
        }

        &:disabled {
          background: inherit;
          color: inherit;
          border-color: transparent;
          box-shadow: none;
          opacity: .5;
        }
      }
    }
  }
}

@if $skin-name == 'bootstrap5.3' {
  .e-btn:not(.e-outline) {
    &:disabled,
    &.e-disabled {
      border-color: transparent !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}

@mixin icon-left-right {
  @if $skin-name == 'bootstrap4' {
    padding: 0 $btn-bigger-icon-top-bottom-padding;
  }
  @if $skin-name == 'tailwind' or $skin-name == 'tailwind-dark' {
    line-height: 0;
  }
}

@mixin mat3-border-radius {
  @if $skin-name == 'Material3' {
    border-radius: 4px;
  }
}

@mixin top-bottom-icon-btn {
  @if $skin-name == 'tailwind' or $skin-name == 'tailwind-dark' {
    line-height: 0;
  }
  @else {
    line-height: 1;
  }
}

@include export-module('button-bigger') {
  .e-bigger.e-small .e-btn,
  .e-bigger .e-small.e-btn,
  .e-bigger.e-small .e-css.e-btn,
  .e-bigger .e-small.e-css.e-btn {
    font-size: $btn-bigger-small-font-size;
    line-height: $btn-bigger-small-text-line-height;
    padding: $btn-bigger-small-padding;
    @if $skin-name == 'tailwind' or $skin-name == 'tailwind-dark' {
      border-radius: 4px;
    }

    &.e-round-corner {
      @if $skin-name == 'Material3' {
        border-radius: 25px;
        padding: $btn-bigger-small-padding;
      }
    }

    & .e-btn-icon {
      font-size: $btn-bigger-small-icon-font-size;
      width: $btn-icon-bigger-small-width;

      &.e-icon-left {
        margin-left: $btn-bigger-small-icon-margin;
        width: $btn-bigger-small-icon-width;
        @include icon-left-right;
      }

      &.e-icon-right {
        margin-right: $btn-bigger-small-icon-margin;
        width: $btn-bigger-small-icon-width;
        @include icon-left-right;
      }

      &.e-icon-top {
        padding-bottom: $btn-small-icon-top-bottom-padding;
        width: auto;
      }

      &.e-icon-bottom {
        padding-top: $btn-small-icon-top-bottom-padding;
        width: auto;
      }
    }

    &.e-icon-btn {
      padding: $btn-bigger-small-icon-padding;
      @if $skin-name == 'fluent2' {
        line-height: 16px;
      }
      @include mat3-border-radius;
    }

    &.e-top-icon-btn,
    &.e-bottom-icon-btn {
      @include top-bottom-icon-btn;
      padding: $btn-top-icon-bigger-padding;
    }

    &.e-round {
      height: $btn-round-bigger-small-height;
      line-height: 1;
      padding: 0;
      width: $btn-round-bigger-small-width;

      & .e-btn-icon {
        font-size: $btn-bigger-small-round-font-size;
        line-height: $btn-bigger-small-round-icon-line-height;
        width: auto;
      }
    }

    &.e-rtl {
      & .e-icon-right {
        margin-left: $btn-bigger-small-icon-margin;
        margin-right: 0;
      }

      & .e-icon-left {
        margin-left: 0;
        margin-right: $btn-bigger-small-icon-margin;
      }
    }
  }

  .e-bigger .e-btn,
  .e-bigger.e-btn,
  .e-bigger .e-css.e-btn,
  .e-bigger.e-css.e-btn {
    font-size: $btn-bigger-font-size;
    line-height: $btn-bigger-text-line-height;
    padding: $btn-bigger-padding;
    @if $skin-name == 'tailwind' or $skin-name == 'tailwind-dark' {
      border-radius: 4px;
    }
    @else if $skin-name == 'bootstrap5.3' {
      border-radius: 6px;
    }

    &.e-round-corner {
      @if $skin-name == 'Material3' {
        border-radius: 25px;
        padding: $btn-bigger-padding;
      }
    }

    & .e-btn-icon {
      font-size: $btn-bigger-icon-font-size;
      width: $btn-icon-bigger-width;

      &.e-icon-left {
        margin-left: $btn-bigger-icon-margin;
        width: $btn-bigger-icon-width;
        @include icon-left-right;
      }

      &.e-icon-right {
        margin-right: $btn-bigger-icon-margin;
        width: $btn-bigger-icon-width;
        @include icon-left-right;
      }

      &.e-icon-top {
        padding-bottom: $btn-bigger-icon-top-bottom-padding;
        width: auto;
      }

      &.e-icon-bottom {
        padding-top: $btn-bigger-icon-top-bottom-padding;
        width: auto;
      }
    }

    &.e-icon-btn {
      @if $skin-name != 'tailwind3' {
        padding: $btn-bigger-icon-padding;
      }
      @if $skin-name == 'fluent2' {
        line-height: 16px;
      }
      @include mat3-border-radius;
    }

    &.e-top-icon-btn,
    &.e-bottom-icon-btn {
      @include top-bottom-icon-btn;
      padding: $btn-top-icon-bigger-padding;
    }

    &.e-round {
      height: $btn-round-bigger-height;
      line-height: 1;
      padding: 0;
      width: $btn-round-bigger-width;
      @if $skin-name == 'tailwind' or $skin-name == 'Material3' or $skin-name == 'bootstrap5.3' {
        border-radius: 50%;
      }

      & .e-btn-icon {
        font-size: $btn-bigger-round-font-size;
        line-height: $btn-bigger-round-icon-line-height;
        width: auto;
      }
    }

    &.e-rtl {
      & .e-icon-right {
        margin-left: $btn-bigger-icon-margin;
        margin-right: 0;
      }

      & .e-icon-left {
        margin-left: 0;
        margin-right: $btn-bigger-icon-margin;
      }
    }

    &.e-small {
      font-size: $btn-bigger-small-font-size;
      line-height: $btn-bigger-small-text-line-height;
      padding: $btn-bigger-small-padding;
      @if $skin-name == 'bootstrap5' or $skin-name == 'FluentUI' {
        border-radius: 2px;
      }
      @else if $skin-name == 'bootstrap5.3' {
        border-radius: 6px;
      }

      &.e-round-corner {
        @if $skin-name == 'Material3' {
          border-radius: 25px;
          padding: $btn-bigger-small-padding;
        }
      }

      & .e-btn-icon {
        font-size: $btn-bigger-small-icon-font-size;
        width: $btn-icon-bigger-small-width;

        &.e-icon-left {
          margin-left: $btn-bigger-small-icon-margin;
          width: $btn-bigger-small-icon-width;
          @include icon-left-right;
        }

        &.e-icon-right {
          margin-right: $btn-bigger-small-icon-margin;
          width: $btn-bigger-small-icon-width;
          @include icon-left-right;
        }

        &.e-icon-top {
          padding-bottom: $btn-small-icon-top-bottom-padding;
          width: auto;
        }

        &.e-icon-bottom {
          padding-top: $btn-small-icon-top-bottom-padding;
          width: auto;
        }
      }

      &.e-icon-btn {
        padding: $btn-bigger-small-icon-padding;
        @include mat3-border-radius;
      }

      &.e-top-icon-btn,
      &.e-bottom-icon-btn {
        @include top-bottom-icon-btn;
        padding: $btn-top-icon-bigger-padding;
      }

      &.e-round {
        height: $btn-round-bigger-small-height;
        line-height: 1;
        padding: 0;
        width: $btn-round-bigger-small-width;
        @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'FluentUI' or $skin-name == 'Material3' {
          border-radius: 50%;
        }

        & .e-btn-icon {
          font-size: $btn-bigger-small-round-font-size;
          line-height: $btn-bigger-small-round-icon-line-height;
          width: auto;
        }
      }

      &.e-rtl {
        & .e-icon-right {
          margin-left: $btn-bigger-small-icon-margin;
          margin-right: 0;
        }

        & .e-icon-left {
          margin-left: 0;
          margin-right: $btn-bigger-small-icon-margin;
        }
      }
    }
  }

  @if $skin-name == 'fluent2' {
    .e-bigger.e-small .e-btn.e-icon-btn .e-btn-icon,
    .e-bigger .e-btn.e-icon-btn .e-btn-icon {
      font-size: 16px;
      line-height: 16px;
    }
  }
}

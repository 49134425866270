@import "../base/base.gd.scss";

$cbox-bgcolor: #fff !default;
$cbox-bigger-check-fontsize: 16px !default;
$cbox-bigger-font-size: 14px !default;
$cbox-bigger-height: 22px !default;
$cbox-bigger-indeterminate-fontsize: 12px !default;
$cbox-bigger-indeterminate-lineheight: 14px !default;
$cbox-bigger-lineheight: 14px !default;
$cbox-bigger-margin: 12px !default;
$cbox-bigger-small-check-fontsize: 12px !default;
$cbox-bigger-small-font-size: 14px !default;
$cbox-bigger-small-height: 20px !default;
$cbox-bigger-small-indeterminate-fontsize: 10px !default;
$cbox-bigger-small-indeterminate-lineheight: 12px !default;
$cbox-bigger-small-lineheight: 12px !default;
$cbox-bigger-small-width: 20px !default;
$cbox-bigger-width: 22px !default;
$cbox-border: 2px solid !default;
$cbox-border-color: $grey-600 !default;
$cbox-border-radius: 2px !default;
$cbox-check-fontsize: 12px !default;
$cbox-checked-ripple-bgcolo: rgba($accent, .26) !default;
$cbox-checkmark-bgcolor: $accent !default;
$cbox-checkmark-border-color: transparent !default;
$cbox-checkmark-color: $accent-font !default;
$cbox-checkmark-disabled-bgcolor: $grey-400 !default;
$cbox-checkmark-disabled-border-color: $grey-400 !default;
$cbox-checkmark-disabled-color: $grey-dark-font !default;
$cbox-checkmark-hover-bgcolor: $accent !default;
$cbox-checkmark-hover-border-color: transparent !default;
$cbox-checkmark-hover-color: $accent-font !default;
$cbox-color: rgba($grey-light-font, .87) !default;
$cbox-disabled-bgcolor: #fff !default;
$cbox-disabled-border-color: $grey-400 !default;
$cbox-disabled-color: $grey-400 !default;
$cbox-focus-outline: $cbox-bgcolor 0 solid !default;
$cbox-focus-outline-offset: 0 !default;
$cbox-focussed-bgcolor: #fff !default;
$cbox-focussed-border-color: $grey-600 !default;
$cbox-focussed-box-shadow: none !default;
$cbox-focussed-checkmark-bgcolor: $accent !default;
$cbox-focussed-checkmark-border-color: transparent !default;
$cbox-focussed-checkmark-color:  $accent-font !default;
$cbox-focussed-color: rgba($grey-light-font, .87) !default;
$cbox-focussed-indeterminate-color: $grey-600 !default;
$cbox-font-size: 13px !default;
$cbox-height: 18px !default;
$cbox-hover-bgcolor: #fff !default;
$cbox-hover-border-color: $grey-600 !default;
$cbox-hover-color: rgba($grey-light-font, .87) !default;
$cbox-indeterminate-bgcolor: #fff !default;
$cbox-indeterminate-border-color: $grey-600 !default;
$cbox-indeterminate-color: $grey-600 !default;
$cbox-indeterminate-content: '\e934' !default;
$cbox-indeterminate-disabled-bgcolor: #fff !default;
$cbox-indeterminate-disabled-border-color: $grey-400 !default;
$cbox-indeterminate-disabled-color: $grey-400 !default;
$cbox-indeterminate-fontsize: 10px !default;
$cbox-indeterminate-hover-color: $grey-600 !default;
$cbox-indeterminate-lineheight: 10px !default;
$cbox-key-focussed-bgcolor: rgba($grey-black, .12) !default;
$cbox-lineheight: 10px !default;
$cbox-margin: 10px !default;
$cbox-padding: 2px 0 !default;
$cbox-ripple-bgcolor: rgba($grey-black, .26) !default;
$cbox-ripple-bigger-size: -9px !default;
$cbox-ripple-bigger-small-size: -9px !default;
$cbox-ripple-size: -9px !default;
$cbox-ripple-small-size: -9px !default;
$cbox-ripple-height: 36px !default;
$cbox-ripple-width: 36px !default;
$cbox-ripple-small-height: 32px !default;
$cbox-ripple-small-width: 32px !default;
$cbox-ripple-bigger-small-height: 38px !default;
$cbox-ripple-bigger-small-width: 38px !default;
$cbox-ripple-bigger-height: 40px !default;
$cbox-ripple-bigger-width: 40px !default;
$cbox-small-check-fontsize: 10px !default;
$cbox-small-font-size: 13px !default;
$cbox-small-height: 14px !default;
$cbox-small-indeterminate-fontsize: 8px !default;
$cbox-small-indeterminate-lineheight: 6px !default;
$cbox-small-lineheight: 6px !default;
$cbox-small-width: 14px !default;
$cbox-width: 18px !default;
$cbox-border-style: solid !default;

@mixin ripple-container {
  bottom: $cbox-ripple-small-size;
  height: $cbox-ripple-small-height;
  left: $cbox-ripple-small-size;
  right: $cbox-ripple-small-size;
  top: $cbox-ripple-small-size;
  width: $cbox-ripple-small-width;
}

@include export-module('checkbox-layout') {
  .e-checkbox-wrapper,
  .e-css.e-checkbox-wrapper {
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    outline: none;
    user-select: none;
    @if $skin-name == 'FluentUI' {
      border: 1px solid transparent;
      padding: 3px;
    }
    @if $skin-name == 'fluent2' {
      border-radius: 4px;
      border: 1px solid transparent;
      line-height: 0;
    }
    @at-root {
      & label {
        cursor: pointer;
        display: inline-block;
        line-height: 0;
        margin: 0;
        position: relative;
        white-space: nowrap;
      }

      &:focus,
      &.e-focus {
        & .e-frame {
          box-shadow: $cbox-focussed-box-shadow;
          @if $skin-name == 'bootstrap5.3' {
            border-color: $border-focus;
          }
        }
        @if $skin-name == 'FluentUI' {
          border: 1px solid $cbox-border-color;
          padding: 3px;
        }
        @if $skin-name == 'fluent2' {
          border-radius: 4px;
          box-shadow: $shadow-focus-ring1;
        }
      }

      &:active {
        & .e-frame {
          @if $skin-name == 'bootstrap5.3' {
            box-shadow: $cbox-focussed-box-shadow;
            border-color: $border-focus !important; /* stylelint-disable-line declaration-no-important */
            background-color: $content-bg-color-pressed;
          }
          @if $skin-name == 'tailwind3' {
            box-shadow: $cbox-focussed-box-shadow;
          }
        }
      }

      & .e-ripple-container {
        border-radius: 50%;
        bottom: $cbox-ripple-size;
        height: $cbox-ripple-height;
        left: $cbox-ripple-size;
        pointer-events: none;
        position: absolute;
        right: $cbox-ripple-size;
        top: $cbox-ripple-size;
        width: $cbox-ripple-width;
        z-index: 1;

        & .e-ripple-element {
          @if $skin-name == 'Material3' {
            border-radius: 50%;
          }
        }
      }

      & .e-label {
        cursor: pointer;
        display: inline-block;
        font-family: $font-family;
        font-size: $cbox-font-size;
        font-weight: normal;
        line-height: $cbox-height;
        user-select: none;
        vertical-align: middle;
        white-space: normal;
        @if $skin-name == 'tailwind3' {
          font-weight: $font-weight-medium;
        }
      }

      & .e-checkbox {
        height: 1px;
        opacity: 0;
        position: absolute;
        width: 1px;

        +.e-label {
          @if $skin-name == 'fluent2' {
            margin: 6px 4px 6px 8px;
          }
          @else {
            margin-right: $cbox-margin;
          }
        }
      }

      & .e-frame {
        border: $cbox-border;
        border-radius: $cbox-border-radius;
        box-sizing: border-box;
        cursor: pointer;
        display: inline-block;
        font-family: 'e-icons';
        height: $cbox-height;
        line-height: $cbox-lineheight;
        padding: $cbox-padding;
        text-align: center;
        vertical-align: middle;
        width: $cbox-width;
        @if $skin-name == 'fluent2' {
          margin: 7.2px 8px;
        }

        +.e-label {
          @if $skin-name == 'fluent2' {
            margin: 6px 8px 6px 4px;
          }
          @else {
            margin-left: $cbox-margin;
          }
        }

        +.e-ripple-container {
          left: auto;
        }
      }

      & .e-check {
        font-size: $cbox-check-fontsize;
      }

      & .e-stop {
        font-size: $cbox-indeterminate-fontsize;
        line-height: $cbox-indeterminate-lineheight;
      }

      &.e-checkbox-disabled {
        cursor: default;
        pointer-events: none;
        @if $skin-name == 'bootstrap5.3' {
          opacity: .5;
        }

        & .e-frame {
          cursor: default;
        }

        & .e-label {
          cursor: default;
        }
      }

      &.e-rtl {
        .e-ripple-container {
          right: $cbox-ripple-size;
        }

        & .e-frame {
          @if $skin-name != 'fluent2' {
            margin: 0;
          }

          &:hover {
            background-color: $cbox-hover-bgcolor;
            border-color: $cbox-hover-border-color;
            @if $skin-name == 'FluentUI' {
              &:not(.e-check),
              &:not(.e-stop)::before {
                content: $cbox-check-content;
                font-size: $cbox-check-fontsize;
              }
            }
          }

          + .e-ripple-container {
            left: $cbox-ripple-size;
            right: auto;
          }
        }

        & .e-label {
          @if $skin-name == 'fluent2' {
            margin: 6px 4px 6px 8px;
          }
          @else {
            margin-left: 0;
            margin-right: $cbox-margin;
          }

          +.e-frame {
            @if $skin-name != 'fluent2' {
              margin: 0;
            }
          }
        }

        & .e-checkbox {
          +.e-label {
            @if $skin-name == 'fluent2' {
              margin: 6px 4px 6px 8px;
            }
            @else {
              margin-left: $cbox-margin;
              margin-right: 0;
            }
          }
        }
      }

      &.e-small {
        & .e-frame {
          height: $cbox-small-height;
          line-height: $cbox-small-lineheight;
          width: $cbox-small-width;
        }

        & .e-check {
          font-size: $cbox-small-check-fontsize;
        }

        & .e-stop {
          font-size: $cbox-small-indeterminate-fontsize;
          line-height: $cbox-small-indeterminate-lineheight;
        }

        & .e-label {
          font-size: $cbox-small-font-size;
          line-height: $cbox-small-height;
        }

        & .e-ripple-container {
          @include ripple-container;
        }
      }
    }
  }

  .e-checkbox-wrapper[readonly] {
    pointer-events: none;
  }

  .e-small .e-checkbox-wrapper,
  .e-small.e-checkbox-wrapper,
  .e-small .e-css.e-checkbox-wrapper,
  .e-small.e-css.e-checkbox-wrapper {
    & .e-frame {
      height: $cbox-small-height;
      line-height: $cbox-small-lineheight;
      width: $cbox-small-width;
      @if $skin-name == 'fluent2' {
        margin: 6.2px 8px;
      }

      &:hover {
        background-color: $cbox-hover-bgcolor;
        border-color: $cbox-hover-border-color;
        @if $skin-name == 'FluentUI' {
          &:not(.e-check),
          &:not(.e-stop)::before {
            content: $cbox-check-content;
            font-size: $cbox-small-check-fontsize;
          }
        }
      }
    }

    & .e-check {
      font-size: $cbox-small-check-fontsize;
    }

    & .e-stop {
      font-size: $cbox-small-indeterminate-fontsize;
      line-height: $cbox-small-indeterminate-lineheight;
    }

    & .e-label {
      font-size: $cbox-small-font-size;
      line-height: $cbox-small-height;
    }

    & .e-ripple-container {
      @include ripple-container;
    }
  }
}


@include export-module('checkbox-theme') {
  .e-checkbox-wrapper,
  .e-css.e-checkbox-wrapper {
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-tap-highlight-color: transparent;
    @at-root {
      & .e-frame {
        background-color: $cbox-bgcolor;
        border-color: $cbox-border-color;

        &.e-check {
          background-color: $cbox-checkmark-bgcolor;
          border-color: $cbox-checkmark-border-color;
          color: $cbox-checkmark-color;
        }

        &.e-stop {
          background-color: $cbox-indeterminate-bgcolor;
          border-color: $cbox-indeterminate-border-color;
          color: $cbox-indeterminate-color;
        }
      }

      & .e-ripple-element {
        background: $cbox-checked-ripple-bgcolo;
      }

      & .e-ripple-check {
        & .e-ripple-element {
          background: $cbox-ripple-bgcolor;
        }
      }

      &:active {
        & .e-ripple-element {
          background: $cbox-ripple-bgcolor;
        }

        & .e-ripple-check {
          & .e-ripple-element {
            background: $cbox-checked-ripple-bgcolo;
          }
        }
      }

      & .e-label {
        color: $cbox-color;
      }

      & .e-checkbox {
        &:focus,
        &:active {
          +.e-frame {
            background-color: $cbox-focussed-bgcolor;
            border-color: $cbox-focussed-border-color;
            box-shadow: $cbox-focussed-box-shadow;

            &.e-check {
              background-color: $cbox-focussed-checkmark-bgcolor;
              border-color: $cbox-focussed-checkmark-border-color;
              box-shadow: $cbox-focussed-box-shadow;
              color: $cbox-focussed-checkmark-color;
            }

            &.e-stop {
              background-color: $cbox-focussed-checkmark-bgcolor;
              border-color: $cbox-focussed-checkmark-border-color;
              box-shadow: $cbox-focussed-box-shadow;
              color: $cbox-indeterminate-hover-color;
            }
          }
        }
      }

      &:hover {
        & .e-ripple-container {
          @if $skin-name == 'Material3' {
            background: $cbox-ripple-bgcolor;
          }
          &.e-ripple-check {
            @if $skin-name == 'Material3' {
              background: $cbox-checked-ripple-bgcolo;
            }
          }
        }

        & .e-frame {
          background-color: $cbox-hover-bgcolor;
          border-color: $cbox-hover-border-color;
          @if $skin-name == 'FluentUI' {
            font-size: $cbox-check-fontsize;

            &:not(.e-check):not(.e-stop) {
              color: $cbox-icon-color;
              @media (max-width: 768px)  {
                color: $cbox-bgcolor;
              }
            }

            &:not(.e-check),
            &:not(.e-stop)::before {
              content: $cbox-check-content;
            }
          }

          &.e-check {
            background-color: $cbox-checkmark-hover-bgcolor;
            border-color: $cbox-checkmark-hover-border-color;
            color: $cbox-checkmark-hover-color;
          }

          &.e-stop {
            @if $skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'Material3' {
              background-color: $cbox-checkmark-hover-bgcolor;
              border-color: $cbox-checkmark-hover-border-color;
            }
            @else if $skin-name == 'FluentUI' or $skin-name == 'fluent2' {
              border-color: $cbox-checkmark-hover-border-color;
            }
            @else {
              color: $cbox-indeterminate-hover-color;
            }
            @if $skin-name == 'fluent2' {
              color: $primary-bg-color-hover;
            }
            @if $skin-name == 'tailwind3' {
              background-color: $cbox-focussed-checkmark-bgcolor;
              border-color: $cbox-indeterminate-border-color;
            }
          }
        }

        & .e-label {
          color: $cbox-hover-color;
        }
      }

      &.e-checkbox-disabled {
        & .e-frame {
          background-color: $cbox-disabled-bgcolor;
          @if $skin-name == 'Material3' {
            border: $cbox-border $cbox-disabled-border-color;
          }
          @else {
            border-color: $cbox-disabled-border-color;
          }
          color: $cbox-disabled-color;

          &.e-check {
            background-color: $cbox-checkmark-disabled-bgcolor;
            @if $skin-name == 'bootstrap4' {
              border-color: transparent;
            }
            @if $skin-name == 'Material3' {
              border: none;
              border-color: $cbox-checkmark-disabled-border-color;
              line-height: 14px;
            }
            @else {
              border-color: $cbox-checkmark-disabled-border-color;
            }
            color: $cbox-checkmark-disabled-color;
          }

          &.e-stop {
            background-color: $cbox-indeterminate-disabled-bgcolor;
            @if $skin-name == 'bootstrap4' {
              border-color: transparent;
            }
            @if $skin-name == 'Material3' {
              border: none;
              border: $cbox-indeterminate-disabled-border-color;
              line-height: 14px;
            }
            @else {
              border-color: $cbox-indeterminate-disabled-border-color;
            }
            color: $cbox-indeterminate-disabled-color;
          }
        }

        & .e-label {
          color: $cbox-disabled-color;
        }
      }

      &.e-focus {
        & .e-ripple-container {
          background-color: $cbox-key-focussed-bgcolor;

          &.e-ripple-check {
            background-color: $cbox-checked-ripple-bgcolo;
          }
        }

        & .e-frame,
        & .e-frame.e-check {
          outline: $cbox-focus-outline;
          outline-offset: $cbox-focus-outline-offset;
        }
      }

      .e-small .e-checkbox-wrapper,
      .e-small.e-checkbox-wrapper,
      .e-small .e-css.e-checkbox-wrapper,
      .e-small.e-css.e-checkbox-wrapper {
        &:hover {
          & .e-frame {
            @if $skin-name == 'FluentUI' {
              font-size: $cbox-small-check-fontsize;

              &:not(.e-check):not(.e-stop) {
                color: $cbox-icon-color;
              }
            }
          }
        }
      }

      &:hover .e-checkbox:focus + .e-frame.e-check {
        @if $theme-name == 'fluent2-highcontrast' {
          background-color: $primary-border-color-hover !important; /* stylelint-disable-line declaration-no-important */
          border-color: $primary-border-color-hover !important; /* stylelint-disable-line declaration-no-important */
        }
      }
    }
  }
}


@include export-module('check-box-material-icons') {
  .e-checkbox-wrapper,
  .e-css.e-checkbox-wrapper {
    @at-root {
      & .e-check {
        &::before {
          content: '\e933';
        }
      }

      & .e-stop {
        &::before {
          content: '\e934';
        }
      }
    }
  }
}

@include export-module('checkbox-bigger') {
  .e-bigger.e-small .e-checkbox-wrapper,
  .e-bigger.e-small.e-checkbox-wrapper,
  .e-bigger.e-small .e-css.e-checkbox-wrapper,
  .e-bigger.e-small.e-css.e-checkbox-wrapper {
    & .e-frame {
      height: $cbox-bigger-small-height;
      line-height: $cbox-bigger-small-lineheight;
      width: $cbox-bigger-small-width;
      @if $skin-name == 'fluent2' {
        margin: 8.2px 8px;
        +label {
          margin: 7.2px 8px 7.2px 4px;
        }
      }

      &:hover {
        @if $skin-name != 'FluentUI' {
          &.e-check {
            background-color: $cbox-checkmark-hover-bgcolor;
            border-color: $cbox-checkmark-hover-border-color;
          }
        }
        @if $skin-name == 'FluentUI' {
          &:not(.e-check),
          &:not(.e-stop)::before {
            content: $cbox-check-content;
            font-size: $cbox-bigger-small-check-fontsize;
          }
        }
      }
    }

    & .e-check {
      font-size: $cbox-bigger-small-check-fontsize;
    }

    & .e-stop {
      font-size: $cbox-bigger-small-indeterminate-fontsize;
      line-height: $cbox-bigger-small-indeterminate-lineheight;
    }

    & .e-label {
      font-size: $cbox-bigger-small-font-size;
      line-height: $cbox-bigger-small-height;
    }

    & .e-ripple-container {
      bottom: $cbox-ripple-bigger-small-size;
      height: $cbox-ripple-bigger-small-height;
      left: $cbox-ripple-bigger-small-size;
      right: $cbox-ripple-bigger-small-size;
      top: $cbox-ripple-bigger-small-size;
      width: $cbox-ripple-bigger-small-width;
    }
  }

  .e-bigger .e-checkbox-wrapper,
  .e-bigger.e-checkbox-wrapper,
  .e-bigger .e-css.e-checkbox-wrapper,
  .e-bigger.e-css.e-checkbox-wrapper {
    & .e-checkbox {
      +.label {
        @if $skin-name == 'fluent2' {
          margin: 9px 6px 9px 8px;
        }
      }
    }
    & .e-frame {
      height: $cbox-bigger-height;
      line-height: $cbox-bigger-lineheight;
      width: $cbox-bigger-width;
      @if $skin-name == 'fluent2' {
        margin: 9.2px 8px;
      }

      &:hover {
        @if $skin-name == 'FluentUI' {
          &:not(.e-check),
          &:not(.e-stop)::before {
            content: $cbox-check-content;
            font-size: $cbox-bigger-check-fontsize;
          }
        }
      }

      +.e-label {
        font-size: $cbox-bigger-font-size;
        line-height: $cbox-bigger-height;
        @if $skin-name == 'fluent2' {
          margin: 9px 8px 9px 6px;
        }
        @else {
          margin-left: $cbox-bigger-margin;
        }
      }

      +.e-ripple-container {
        left: auto;
      }
    }

    & .e-check {
      font-size: $cbox-bigger-check-fontsize;
    }

    & .e-stop {
      font-size: $cbox-bigger-indeterminate-fontsize;
      line-height: $cbox-bigger-indeterminate-lineheight;
    }

    & .e-label {
      font-size: $cbox-bigger-font-size;
    }

    & .e-ripple-container {
      bottom: $cbox-ripple-bigger-size;
      height: $cbox-ripple-bigger-height;
      left: $cbox-ripple-bigger-size;
      right: $cbox-ripple-bigger-size;
      top: $cbox-ripple-bigger-size;
      width: $cbox-ripple-bigger-width;
    }

    &.e-rtl {
      & .e-frame {
        @if $skin-name != 'fluent2' {
          margin: 0;
        }

        &:hover {
          @if $skin-name != 'FluentUI' {
            &.e-check {
              background-color: $cbox-checkmark-hover-bgcolor;
              border-color: $cbox-checkmark-hover-border-color;
            }
          }
          @if $skin-name == 'FluentUI' {
            &:not(.e-check),
            &:not(.e-stop)::before {
              content: $cbox-check-content;
              font-size: $cbox-bigger-check-fontsize;
            }
          }
        }

        +.e-label {
          @if $skin-name == 'fluent2' {
            margin: 9px 6px 9px 8px;
          }
          @else {
            margin-left: 0;
            margin-right: $cbox-bigger-margin;
          }
        }

        +.e-ripple-container {
          left: auto;
        }

        +.e-ripple-container {
          right: auto;
        }
      }
    }

    &.e-small {
      & .e-frame {
        height: $cbox-bigger-small-height;
        line-height: $cbox-bigger-small-lineheight;
        width: $cbox-bigger-small-width;
      }

      & .e-check {
        font-size: $cbox-bigger-small-check-fontsize;
      }

      & .e-stop {
        font-size: $cbox-bigger-small-indeterminate-fontsize;
        line-height: $cbox-bigger-small-indeterminate-lineheight;
      }

      & .e-label {
        font-size: $cbox-bigger-small-font-size;
        line-height: $cbox-bigger-small-height;
      }

      & .e-ripple-container {
        bottom: $cbox-ripple-bigger-small-size;
        height: $cbox-ripple-bigger-small-height;
        left: $cbox-ripple-bigger-small-size;
        right: $cbox-ripple-bigger-small-size;
        top: $cbox-ripple-bigger-small-size;
        width: $cbox-ripple-bigger-small-width;
      }
    }
  }

  .e-bigger .e-checkbox-wrapper,
  .e-bigger.e-checkbox-wrapper,
  .e-bigger .e-css.e-checkbox-wrapper,
  .e-bigger.e-css.e-checkbox-wrapper {
    &:hover {
      & .e-frame {
        @if $skin-name == 'FluentUI' {
          font-size: $cbox-bigger-check-fontsize;

          &:not(.e-check):not(.e-stop) {
            color: $cbox-icon-color;
            @media (max-width: 768px)  {
              color: $cbox-bgcolor;
            }
          }
        }
      }
    }

    &.e-checkbox-disabled {
      & .e-frame {
        &.e-check,
        &.e-stop {
          @if $skin-name == 'Material3' {
            line-height: 18px;
          }
        }
      }
    }
  }

  .e-bigger.e-small .e-checkbox-wrapper,
  .e-bigger.e-small.e-checkbox-wrapper,
  .e-bigger.e-small .e-css.e-checkbox-wrapper,
  .e-bigger.e-small.e-css.e-checkbox-wrapper {
    &:hover {
      & .e-frame {
        @if $skin-name == 'FluentUI' {
          font-size: $cbox-bigger-small-check-fontsize;

          &:not(.e-check):not(.e-stop) {
            color: $cbox-icon-color;
          }
        }
        @if $skin-name == 'bootstrap4' or $skin-name == 'tailwind' {
          background-color: $cbox-checkmark-hover-bgcolor;
          border-color: $cbox-checkmark-hover-border-color;
        }
      }
    }
  }
}

@import "../base/base.gd.scss";

$tooltip-drop-shadow: none !default;
$tooltip-content-bg-color: $grey-700 !default;
$tooltip-content-border-color: $grey-700 !default;
$tooltip-content-font-color: $grey-dark-font !default;
$tooltip-default-icon-color: $grey-700 !default;
$tooltip-icon-bg-normal: $primary-font !default;
$tooltip-hover-icon-color: $grey-700 !default;
$tooltip-icon-bg-hover: $primary-font !default;
$tooltip-font-size: 11px !default;
$tooltip-mobile-font-size: 14px !default;
$tooltip-border-radius: 2px !default;
$tooltip-opacity: .9 !default;
$tooltip-arrow-outer-border: 8px !default;
$tooltip-arrow-inner-border: 7px !default;
$tooltip-horizontal-padding: 3px !default;
$tooltip-vertical-padding: 6px !default;
$tooltip-mobile-horizontal-padding: 5px !default;
$tooltip-mobile-vertical-padding: 8px !default;
$tooltip-line-height: 16px !default;
$tooltip-arrow-icon-font-size: 16px !default;
$tooltip-icon-border-color: transparent !default;

$tooltip-tip-bottom-height: 8px !default;
$tooltip-tip-bottom-left: 50% !default;
$tooltip-tip-bottom-top: 100% !default;
$tooltip-tip-bottom-width: 16px !default;

$tooltip-tip-top-height: 8px !default;
$tooltip-tip-top-left: 50% !default;
$tooltip-tip-top-top: -9px !default;
$tooltip-tip-top-width: 16px !default;

$tooltip-tip-left-height: 16px !default;
$tooltip-tip-left-left: -9px !default;
$tooltip-tip-left-top: 48% !default;
$tooltip-tip-left-width: 8px !default;

$tooltip-tip-right-height: 16px !default;
$tooltip-tip-right-left: 100% !default;
$tooltip-tip-right-top: 50% !default;
$tooltip-tip-right-width: 8px !default;

@mixin tip-size($height, $left, $top, $width) {
  height: $height;
  left: $left;
  top: $top;
  width: $width;
}

@include export-module('tooltip-layout') {

  .e-tooltip-popup-container {
    position: relative;
  }

  .e-tooltip-wrap {
    max-width: 350px;
    min-width: 30px;
    padding: 0;
    position: absolute;
    visibility: visible;

    .e-arrow-tip {
      overflow: hidden;
      position: absolute;

      &.e-tip-bottom {
        @include tip-size($tooltip-tip-bottom-height, $tooltip-tip-bottom-left, $tooltip-tip-bottom-top, $tooltip-tip-bottom-width);
      }

      &.e-tip-top {
        @include tip-size($tooltip-tip-top-height, $tooltip-tip-top-left, $tooltip-tip-top-top, $tooltip-tip-top-width);
      }

      &.e-tip-left {
        @include tip-size($tooltip-tip-left-height, $tooltip-tip-left-left, $tooltip-tip-left-top, $tooltip-tip-left-width);
      }

      &.e-tip-right {
        @include tip-size($tooltip-tip-right-height, $tooltip-tip-right-left, $tooltip-tip-right-top, $tooltip-tip-right-width);
      }
    }

    .e-tooltip-close {
      cursor: pointer;
      float: right;
      position: absolute;
      right: -9px;
      top: -9px;
      z-index: inherit;
      @if ($skin-name == 'tailwind' or $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'FluentUI' or $skin-name == 'Material3' or $skin-name == 'fluent2') {
        border-radius: 9px;
        border-width: 1px;
        box-shadow: $shadow-sm;
        box-sizing: border-box;
        height: 16px;
        width: 16px;
      }
      @if ($skin-name == 'tailwind3') {
        height: 24px;
        width: 24px;
        right: -15px;
        top: -15px;
      }
    }

    .e-tip-content {
      background-color: inherit;
      height: 100%;
      line-height: $tooltip-line-height;
      overflow-wrap: break-word;
      overflow-x: hidden;
      padding: $tooltip-horizontal-padding $tooltip-vertical-padding;
      position: relative;
      white-space: normal;
      width: 100%;
      word-break: break-word;
      z-index: 1;
      @if $skin-name == 'bootstrap4' {
        border-radius: inherit;
      }
      @if ($skin-name == 'tailwind' or $skin-name == 'bootstrap5') {
        font-weight: 500;
      }
    }

    &.e-rtl {
      .e-tooltip-close::before {
        @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
          font-size: 9px;
          margin-right: 2px;
        }
        @if $skin-name == 'tailwind' {
          font-size: 8px;
          margin-right: 3px;
        }
      }
    }
  }
}


@include export-module('tooltip-theme') {

  .e-tooltip-wrap {
    border-radius: $tooltip-border-radius;

    @if ($skin-name != 'tailwind' and $skin-name != 'tailwind3' and $skin-name != 'bootstrap5' and $skin-name != 'bootstrap5.3' and $skin-name != 'FluentUI') {
      filter: $tooltip-drop-shadow;
      opacity: $tooltip-opacity;
    }

    &.e-popup {
      background-color: $tooltip-content-bg-color;
      border: $border-size $border-type $tooltip-content-border-color;
      @if ($skin-name == 'FluentUI' or $skin-name == 'fluent2') {
        box-shadow: $tooltip-drop-shadow;
        filter: $tooltip-drop-shadow-filter;
      }
    }

    .e-arrow-tip-outer {
      height: 0;
      left: 0;
      position: absolute;
      top: 0;
      width: 0;

      &.e-tip-bottom {
        border-left: $tooltip-arrow-outer-border $border-type transparent;
        border-right: $tooltip-arrow-outer-border $border-type transparent;
        border-top: $tooltip-arrow-outer-border $border-type $tooltip-content-border-color;
      }

      &.e-tip-top {
        border-bottom: $tooltip-arrow-outer-border $border-type $tooltip-content-border-color;
        border-left: $tooltip-arrow-outer-border $border-type transparent;
        border-right: $tooltip-arrow-outer-border $border-type transparent;
      }

      &.e-tip-left {
        border-bottom: $tooltip-arrow-outer-border $border-type transparent;
        border-right: $tooltip-arrow-outer-border $border-type $tooltip-content-border-color;
        border-top: $tooltip-arrow-outer-border $border-type transparent;
      }

      &.e-tip-right {
        border-bottom: $tooltip-arrow-outer-border $border-type transparent;
        border-left: $tooltip-arrow-outer-border $border-type $tooltip-content-border-color;
        border-top: $tooltip-arrow-outer-border $border-type transparent;
      }
    }

    .e-arrow-tip-inner {
      height: 0;
      position: absolute;
      width: 0;
      z-index: 10;

      &.e-tip-right,
      &.e-tip-left,
      &.e-tip-bottom,
      &.e-tip-top {
        color: $tooltip-content-bg-color;
        font-family: 'e-icons';
        font-size: $tooltip-arrow-icon-font-size;
        font-style: normal;
        font-variant: normal;
        font-weight: normal;
        line-height: 1;
        text-transform: none;
      }

      &.e-tip-bottom,
      &.e-tip-top {
        right: $tooltip-arrow-icon-font-size;
      }

      &.e-tip-right,
      &.e-tip-left {
        bottom: $tooltip-arrow-icon-font-size;
      }
    }

    .e-tooltip-close {
      background-color: $tooltip-icon-bg-normal;
      border-color: $tooltip-icon-border-color;
      @if ($skin-name == 'tailwind' or $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'FluentUI') {
        border-radius: 9px;
      }
      @else if ($skin-name == 'tailwind3') {
        border-radius: 50%;
      }
      @else {
        border-radius: 8px;
      }
      color: $tooltip-default-icon-color;
      @if ($skin-name == 'highcontrast' or $skin-name == 'highcontrast-light') {
        border-style: solid;
        border-width: 1px;
        height: 16px;
        width: 16px;
      }
      @if ($skin-name == 'bootstrap4' or $skin-name == 'tailwind' or $skin-name == 'bootstrap5' or $skin-name == 'FluentUI' or $skin-name == 'Material3') {
        border: $tooltip-close-icon-border;
      }

      &:hover {
        @if ($skin-name == 'tailwind') {
          color: $tooltip-hover-icon-color;
        }
        @else {
          background-color: $tooltip-icon-bg-hover;
          color: $tooltip-hover-icon-color;
        }
        @if ($skin-name == 'bootstrap5') {
          border-color: $tooltip-close-icon-border-hover;
        }
      }
    }

    .e-tip-content {
      border-radius: inherit;
      color: $tooltip-content-font-color;
      font-family: $font-family;
      font-size: $tooltip-font-size;
      @if $skin-name == 'bootstrap4' {
        line-height: 1.5;
      }
    }
  }
}


.e-tooltip-close::before {
  content: '\e7e9';
  font-size: 16px;
}

.e-arrow-tip-inner.e-tip-right::before {
  content: '\e848';
}

.e-arrow-tip-inner.e-tip-top::before {
  content: '\e918';
}

.e-arrow-tip-inner.e-tip-bottom::before {
  content: '\e919';
}

.e-arrow-tip-inner.e-tip-left::before {
  content: '\e84b';
}

@include export-module('tooltip-bigger') {
  .e-bigger .e-tooltip-wrap,
  .e-tooltip-wrap.e-bigger {

    .e-tooltip-close {
      @if ($skin-name == 'tailwind' or $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3') {
        border-radius: 10px;
        border-width: 1px;
        height: 20px;
        width: 20px;
      }

      &::before {
        @if ($skin-name == 'tailwind') {
          font-size: 10px;
          vertical-align: middle;
        }

        @if ($skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3') {
          @if ($skin-name == 'bootstrap5.3') {
            font-size: 8px;
            left: calc(50% - 4px);
          }

          @else {
            font-size: 11px;
            left: calc(50% - 6px);
          }
        }
      }
    }

    .e-tip-content {
      @if ($skin-name == 'tailwind' or $skin-name == 'bootstrap5' or $skin-name == 'FluentUI') {
        line-height: 22px;
      }
      @else if ($skin-name == 'Material3') {
        line-height: 24px;
      }
      @else {
        line-height: 20px;
      }
      padding: $tooltip-mobile-horizontal-padding $tooltip-mobile-vertical-padding;
      @if $skin-name == 'bootstrap4' {
        line-height: 1.5;
      }
    }

    &.e-rtl {
      .e-tooltip-close::before {
        @if ($skin-name == 'tailwind') {
          font-size: 10px;
          margin-right: 4px;
        }
      }
    }
  }

  .e-bigger .e-tooltip-wrap .e-tip-content,
  .e-tooltip-wrap.e-bigger .e-tip-content {
    font-size: $tooltip-mobile-font-size;
  }
}

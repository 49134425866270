@import "../base/base.gd.scss";

$cmenu-back-icon: '\e977' !default;
$cmenu-back-icon-margin: 16px !default;
$cmenu-bigger-caret-font-size: 12px !default;
$cmenu-bigger-font-size: 15px !default;
$cmenu-bigger-li-height: 48px !default;
$cmenu-bigger-max-width: 280px !default;
$cmenu-bigger-min-width: 112px !default;
$cmenu-caret-font-size: 11px !default;
$cmenu-caret-color: rgba($grey-light-font, .54) !default;
$cmenu-caret-icon: '\e956' !default;
$cmenu-caret-icon-rtl: '\e937' !default;
$cmenu-caret-li-padding: 36px !default;
$cmenu-caret-right: 8px !default;
$cmenu-caret-blank-icon: 16px !default;
$cmenu-color: rgba($grey-light-font, .87) !default;
$cmenu-disable-text: rgba($grey-light-font, .38) !default;
$cmenu-font-size: 14px !default;
$cmenu-font-weight: normal !default;
$cmenu-icon-margin-right: 10px !default;
$cmenu-li-bgcolor: $grey-200 !default;
$cmenu-li-border-color: rgba($grey-light-font, .12) !default;
$cmenu-li-border-style: solid !default;
$cmenu-li-border-width: 1px !default;
$cmenu-li-height: 36px !default;
$cmenu-li-padding: 0 16px !default;
$cmenu-li-right-padding: 16px !default;
$cmenu-li-selection-bgcolor:  $grey-200 !default;
$cmenu-li-selection-font-color: rgba($grey-light-font, .87) !default;
$cmenu-li-hover-outline-offset: 0 !default;
$cmenu-li-selected-outline: 0 solid $cmenu-li-selection-bgcolor !default;
$cmenu-li-selected-outline-offset: 0 !default;
$cmenu-max-width: 240px !default;
$cmenu-icon-color: rgba($grey-light-font, .54) !default;
$cmenu-icon-disabled-color: rgba($grey-light-font, .38) !default;
$cmenu-icon-font-size: 14px !default;
$cmenu-icon-bigger-font-size: 16px !default;
$cmenu-min-width: 120px !default;
$cmenu-parent-ul-box-shadow: 0 5px 5px -3px rgba($grey-black, .2), 0 8px 10px 1px rgba($grey-black, .14), 0 3px 14px 2px rgba($grey-black, .12) !default;
$cmenu-seperator-padding: 8px 0 !default;
$cmenu-seperator-bigger-padding: 8px 0 !default;
$cmenu-selected-color: rgba($grey-light-font, .87) !default;
$cmenu-sub-ul-box-shadow: 0 5px 5px -3px rgba($grey-black, .2), 0 8px 10px 1px rgba($grey-black, .14), 0 3px 14px 2px rgba($grey-black, .12) !default;
$cmenu-ul-bgcolor: $grey-white !default;
$cmenu-ul-border-radius: 0 !default;
$cmenu-ul-border: none !default;
$cmenu-ul-padding: 0 !default;
$cmenu-ul-bigger-padding: 8px 0 !default;
$cmenu-li-hover-outline: 0 solid $cmenu-li-border-color !default;
$cmenu-caret-hover-color: $cmenu-caret-color !default;
$cmenu-hover-icon-color: $cmenu-icon-color !default;

@mixin ul-layout {
  font-weight: $cmenu-font-weight;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  user-select: none;

  &.e-ul,
  &.e-ul * {
    box-sizing: border-box;

    &:focus {
      outline: none;
    }
  }
}

@mixin ul-size {
  font-size: $cmenu-font-size;
  padding: $cmenu-ul-padding;
}

@mixin li-layout {
  cursor: pointer;
  position: relative;

  &.e-menu-hide {
    display: none;
  }

  &.e-menu-header {
    border-bottom-style: $cmenu-li-border-style;
    border-bottom-width: $cmenu-li-border-width;
  }

  & .e-menu-url {
    text-decoration: none;
  }

  & .e-menu-icon {
    display: inline-block;
    vertical-align: middle;
  }

  &.e-separator {
    cursor: auto;
    line-height: normal;
    pointer-events: none;
  }
}

@mixin li-size {
  height: $cmenu-li-height;
  line-height: $cmenu-li-height;
  @if $skin-name == 'fluent2' {
    line-height: 19px;
    height: 32px;
  }
  padding: $cmenu-li-padding;

  & .e-menu-url {
    display: inline-block;
    min-width: 120px;
  }

  & .e-menu-icon {
    font-size: $cmenu-icon-font-size;
    line-height: $cmenu-li-height;
    margin-right: $cmenu-icon-margin-right;
    width: 1em;
  }

  & .e-caret {
    line-height: $cmenu-li-height;
    margin-left: 16px;
    margin-right: 0;
    position: absolute;
    right: $cmenu-caret-right;
    @if $skin-name == 'fluent2' {
      padding: 4px 0;
    }
  }

  &.e-menu-caret-icon {
    padding-right: $cmenu-caret-li-padding;
  }

  &.e-separator {
    @include separator-size;
    margin: $cmenu-seperator-padding;
  }
}

@mixin separator-size {
  border-bottom-style: $cmenu-li-border-style;
  border-bottom-width: $cmenu-li-border-width;
  height: auto;
}

@mixin rtl-li-layout {
  & .e-menu-icon {
    margin-right: 0;
  }

  & .e-caret {
    margin-left: 0;
    margin-right: 16px;
    right: auto;
  }
}

@mixin rtl-li-size {
  & .e-menu-icon {
    margin-left: $cmenu-icon-margin-right;
    @if $skin-name == 'fluent2' {
      margin: 3px 3px 3px 5px;
    }
  }

  & .e-caret {
    left: $cmenu-caret-right;
  }

  &.e-menu-caret-icon {
    @if $skin-name != 'FluentUI' {
      padding-left: $cmenu-caret-li-padding;
    }
    padding-right: $cmenu-li-right-padding;
  }

  &.e-blankicon {
    padding-left: $cmenu-caret-blank-icon;

    &.e-menu-caret-icon {
      padding-left: $cmenu-caret-li-padding;
    }
  }
}



@include export-module('contextmenu-layout') {
  .e-contextmenu-wrapper,
  .e-contextmenu-container {
    @at-root {
      & ul {
        @include ul-layout;
        @include ul-size;
        border: $cmenu-ul-border;
        border-radius: $cmenu-ul-border-radius;
        box-shadow: $cmenu-sub-ul-box-shadow;
        display: none;
        min-width: $cmenu-min-width;
        position: absolute;

        &.e-contextmenu {
          box-shadow: $cmenu-parent-ul-box-shadow;
          @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'tailwind' or $skin-name == 'Material3' {
            padding: 4px 0;
          }
        }

        &.e-ul {
          font-family: $font-family;
        }

        & .e-menu-item  {
          @include li-layout;
          @include li-size;

          & .e-menu-icon {
            @if $skin-name == 'fluent2' {
              margin-bottom: 1px;
            }
          }

          & .e-caret {
            font-size: $cmenu-caret-font-size;
          }

          & .e-previous {
            margin-right: $cmenu-back-icon-margin;
          }

          &.e-disabled {
            cursor: auto;
            pointer-events: none;
            @if $skin-name == 'tailwind3' {
              color: $flyout-text-color-disabled;
            }
          }

          &.e-separator {
            @if $skin-name == 'fluent2' {
              padding: 0 !important;  /* stylelint-disable-line declaration-no-important */
            }
          }

          &:focus-visible {
            @if $skin-name == 'fluent2' {
              border-radius: 4px;
              background-color: $content-bg-color;
              box-shadow: $shadow-focus-ring1;
            }
            @if $skin-name == 'tailwind3' {
              box-shadow: $shadow-focus-ring2;
              background: $flyout-bg-color;
            }
          }
        }

        .e-menu-item .e-checkbox-wrapper {
          width: max-content;
        }
      }
    }

    &.e-sfcontextmenu {
      position: absolute;

      & .e-menu-vscroll {
        position: absolute;
      }

      & ul {
        white-space: nowrap;

        &.e-transparent {
          background: transparent;
          border: 0;
          box-shadow: none;
          height: 1px;
          left: 0;
          min-width: 0;
          padding: 0;
          top: 0;
          width: 0;
        }

        &.e-transparent .e-menu-item {
          height: 0;
          padding: 0;
        }

        &.e-transparent .e-menu-item.e-separator {
          border: 0;
          margin: 0;
        }
      }
    }

    &:not(.e-menu-container) {
      & .e-menu-vscroll.e-vscroll {
        box-shadow: $cmenu-parent-ul-box-shadow;

        & .e-vscroll-bar {
          height: 100%;
          width: inherit;
        }

        & .e-scroll-nav {
          height: 16px;
        }

        &:not(.e-scroll-device) {
          padding: 16px 0;
        }

        & ul {
          box-shadow: none;
          margin-top: -12px;
          width: inherit;
        }
      }
    }
  }

  .e-rtl.e-contextmenu-wrapper .e-menu-item,
  .e-rtl.e-contextmenu-container .e-menu-item {
    @include rtl-li-layout;
    @include rtl-li-size;
  }

  .e-contextmenu-wrapper .e-menu-item,
  .e-contextmenu-container .e-menu-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .e-small .e-contextmenu-wrapper ul .e-menu-item .e-caret,
  .e-small .e-contextmenu-wrapper ul .e-menu-icon {
    @if $skin-name == 'fluent2' {
      font-size: 12px;
    }
  }

  .e-small .e-contextmenu-wrapper ul .e-menu-item,
  .e-small.e-contextmenu-wrapper ul .e-menu-item {
    @if $skin-name == 'tailwind3' {
      font-size: $cmenu-small-font-size;
      height: $cmenu-small-li-height;
      line-height: $cmenu-small-li-height;
      .e-caret,
      .e-menu-icon {
        font-size: $cmenu-small-font-size;
        line-height: $cmenu-small-li-height;
      }
      &.e-separator {
        height: auto;
      }
    }
  }
}


@mixin focused-icon-color {
  @if $skin-name != 'bootstrap5' {
    & .e-caret {
      color: $cmenu-caret-color;
      @if $skin-name == 'fluent2' {
        color: $cmenu-hover-icon-color;
      }
    }

    & .e-menu-icon {
      @if $skin-name == 'fluent2' {
        color: $cmenu-hover-icon-color;
      }
      @else if $skin-name == 'bootstrap5.3' {
        color: $cmenu-hover-icon-color;
      }
      @else {
        color: $cmenu-icon-color;
      }
    }
  }
}

@mixin selected-icon-color {
  @if $skin-name != 'bootstrap5' {
    & .e-caret {
      color: $cmenu-caret-hover-color;
    }

    & .e-menu-icon {
      @if $skin-name == 'bootstrap5.3' {
        color: $flyout-text-color-pressed;
      }
      @else {
        color: $cmenu-hover-icon-color;
      }
    }
  }
}

@mixin icon-color {
  &.e-menu-header {
    border-bottom-color: $cmenu-li-border-color;
  }
  @include focused-icon-color;
}

@mixin focused-color {
  background-color: $cmenu-li-bgcolor;
  color: $cmenu-li-selection-font-color;
  outline: $cmenu-li-hover-outline;
  outline-offset: $cmenu-li-hover-outline-offset;
  @if $skin-name == 'fluent2' {
    border-radius: 4px;
  }
}

@mixin selected-color {
  background-color: $cmenu-li-selection-bgcolor;
  color: $cmenu-selected-color;
  outline: $cmenu-li-selected-outline;
  outline-offset: $cmenu-li-selected-outline-offset;
  @if $skin-name == 'fluent2' {
    border-radius: 4px;
    box-shadow: none;
  }
}

@mixin disabled-color {
  & .e-disabled {
    color: $cmenu-disable-text;
    @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
      opacity: $cmenu-disabled-item-opacity;
    }
    @else{
      opacity: 1;
    }

    & .e-menu-icon {
      color: $cmenu-icon-disabled-color;
    }

    & .e-caret {
      color: $cmenu-icon-disabled-color;
    }

    & .e-menu-url {
      color: $cmenu-disable-text;
    }
  }
}



@include export-module('contextmenu-theme') {
  .e-contextmenu-wrapper,
  .e-contextmenu-container {
    @at-root {
      & ul {
        @if $skin-name == 'Material3' {
          background: $cmenu-ul-bgcolor;
        }
        @else {
          background-color: $cmenu-ul-bgcolor;
        }
        color: $cmenu-color;

        & .e-menu-item  {
          @include icon-color;
          @if $skin-name == 'fluent2' {
            & .e-menu-icon,
            & .e-caret {
              color: $icon-color;
            }
          }

          & .e-menu-url {
            color: $cmenu-color;
          }

          &.e-focused {
            @include focused-color;
            @include focused-icon-color;
          }

          &.e-selected {
            @include selected-color;
            @include selected-icon-color;
          }

          &:active {
            @if $skin-name == 'bootstrap5.3' {
              background-color: $cmenu-li-selection-bgcolor;
              color: $cmenu-selected-color;

              & .e-menu-icon {
                color: $cmenu-selected-color;
              }
            }
          }
        }

        @include disabled-color;

        & .e-separator {
          border-bottom-color: $cmenu-li-border-color;
        }
      }
    }

    &:not(.e-menu-container) {
      & .e-menu-vscroll.e-vscroll {
        background-color: inherit;
        border: $cmenu-ul-border;

        & .e-scroll-nav {
          border-color: $cmenu-li-border-color;
        }
      }
    }
  }
}


@include export-module('context-menu-material-icons') {
  .e-contextmenu-wrapper,
  .e-contextmenu-container {
    @at-root {
      & ul {
        & .e-menu-item  {
          & .e-previous {
            &::before {
              content: '\e977';
            }
          }

          & .e-caret {
            &::before {
              content: '\e956';
            }
          }
        }
      }
    }
  }

  .e-rtl.e-contextmenu-wrapper .e-menu-item,
  .e-rtl.e-contextmenu-container .e-menu-item {
    & .e-caret {
      &::before {
        content: '\e937';
      }
    }
  }
}

@mixin bigger-rtl-blank-icon {
  padding-left: $cmenu-caret-blank-icon;

  &.e-menu-caret-icon {
    padding-left: $cmenu-caret-li-padding;
  }
}

@mixin bigger-ul-size {
  font-size: $cmenu-bigger-font-size;
  padding: $cmenu-ul-bigger-padding;
  white-space: nowrap;
}

@mixin bigger-li-size {
  height: $cmenu-bigger-li-height;
  line-height: $cmenu-bigger-li-height;
  @if $skin-name == 'fluent2' {
    height: 40px;
    line-height: 21px;
    padding: 9px;
  }

  &.e-menu-caret-icon {
    @if $skin-name == 'fluent2' {
      padding: 9px 36px 9px 9px;
    }
  }

  & .e-menu-icon {
    font-size: $cmenu-icon-bigger-font-size;
    line-height: $cmenu-bigger-li-height;
    @if $skin-name == 'tailwind' {
      margin-right: $cmenu-icon-bigger-margin-right;
    }
  }

  & .e-caret {
    line-height: $cmenu-bigger-li-height;
    @if $skin-name == 'fluent2' {
      margin: 3px 0;
    }
  }

  &.e-separator {
    height: auto;
    line-height: normal;
  }
}

@include export-module('contextmenu-bigger') {
  .e-bigger .e-contextmenu-wrapper ul,
  .e-bigger.e-contextmenu-wrapper ul,
  .e-bigger .e-contextmenu-container ul,
  .e-bigger.e-contextmenu-container ul {
    @include bigger-ul-size;
    box-shadow: $cmenu-parent-ul-box-shadow;
    max-width: $cmenu-bigger-max-width;
    min-width: $cmenu-bigger-min-width;
    @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or  $skin-name == 'Material3' {
      padding: 8px 0;
    }

    & .e-menu-item  {
      @include bigger-li-size;

      & .e-menu-icon {
        @if $skin-name == 'fluent2' {
          margin-bottom: 2px;
        }
      }

      & .e-caret {
        font-size: $cmenu-bigger-caret-font-size;
        @if $skin-name == 'fluent2' {
          padding: 0;
        }
      }
    }
  }

  .e-bigger .e-contextmenu-wrapper.e-rtl ul .e-blankicon,
  .e-bigger.e-contextmenu-wrapper.e-rtl ul .e-blankicon,
  .e-bigger .e-contextmenu-container.e-rtl ul .e-blankicon,
  .e-bigger.e-contextmenu-container.e-rtl ul .e-blankicon {
    @if $skin-name != 'FluentUI' {
      @include bigger-rtl-blank-icon;
    }
  }

  .e-bigger .e-contextmenu-wrapper.e-rtl ul .e-menu-item.e-menu-caret-icon {
    @if $skin-name == 'fluent2' {
      padding: 9px 9px 9px 36px;
    }
  }

  .e-bigger .e-small .e-contextmenu-wrapper ul .e-menu-item,
  .e-bigger .e-small.e-contextmenu-wrapper ul .e-menu-item {
    @if $skin-name == 'tailwind3' {
      height: $cmenu-bigger-small-li-height;
      font-size: $cmenu-bigger-font-size;
      line-height: $cmenu-bigger-small-li-height;
      &.e-separator {
        height: auto;
      }
      .e-menu-icon {
        line-height: $cmenu-bigger-small-li-height;
      }
    }
  }
}

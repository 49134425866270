@import "../base/base.gd.scss";
@import "../popups/popup.gd.scss";
@import "../buttons/button.gd.scss";

$dialog-btn-bg-bgcolor: transparent !default;
$dialog-btn-box-shadow: none !default;
$dialog-bg-color: $grey-white !default;
$dialog-icon-color: $grey-light-font !default;
$dialog-active-icon-color: $grey-light-font !default;
$dialog-close-button-left: 0 !default;
$dialog-close-button-right: 8px !default;
$dialog-header-border: none !default;
$dialog-border: none !default;
$dialog-primary-button-color:  #e3165b !default;
$dialog-secondary-button-color: #333333 !default;
$dialog-footer-content-border-top: none !default;
$dialog-alert-footer-content-border-top: none !default;
$dialog-close-icon-normal-opacity: initial !default;
$dialog-close-icon-active-opacity: initial !default;
$dialog-close-icon-font-size: 12px !default;
$header-content-border-radius: 1px 1px 0 0 !default;
$dialog-header-line-height: 30px !default;
$dialog-content-padding-top: 0 !default;
$dialog-bigger-content-padding-top: 0 !default;
$footer-content-border-radius: 0 !default;
$dialog-close-icon-button-padding: 0 12px !default;
$dialog-bigger-close-icon-button-padding: 0 12px !default;
$dialog-bigger-close-icon-font-size: 12px !default;
$dialog-hover-icon-color: $grey-light-font !default;
$dialog-icon-bg-normal: $grey-white !default;
$dialog-header-bg-color: $grey-white !default;
$dialog-content-bg-color: $grey-white !default;
$dialog-footer-bg-color: $grey-white !default;
$dialog-shadow-color: 0 11px 15px -7px rgba($grey-light-font, .2), 0 24px 38px 3px rgba($grey-light-font, .14), 0 9px 46px 8px rgba($grey-light-font, .12) !default;
$dialog-header-font-size: 18px !default;
$dialog-header-font-weight: normal !default;
$dialog-overlay-bg-color: #383838 !default;
$dialog-overlay-opacity: .5 !default;
$dialog-header-font-color: rgba($grey-light-font, .87) !default;
$dialog-content-font-size: 13px !default;
$dialog-content-line-height: normal !default;
$dialog-content-font-weight: normal !default;
$dialog-device-content-font-size: 14px !default;
$dialog-content-font-color: rgba($grey-light-font, .87) !default;
$dialog-border-radius: 2px !default;
$dialog-header-icon-hover-border-radius: 50% !default;
$dialog-closeicon-btn-hover-color: transparent !default;
$dialog-closeicon-btn-active-color: transparent !default;
$dialog-close-button-height: 30px !default;
$dialog-close-button-width: 30px !default;
$dialog-header-content-padding: 18px !default;
$dialog-content-padding: 18px !default;
$dialog-footer-content-padding: 8px !default;
$dialog-bigger-header-content-padding: 24px 24px 20px !default;
$dialog-bigger-content-padding: 24px !default;
$dialog-bigger-footer-content-padding: 8px !default;
$dialog-footer-button-margin-left: 6px !default;
$dialog-bigger-footer-button-margin-left: 8px !default;
$dialog-footer-button-margin-right: 6px !default;
$dialog-bigger-footer-button-margin-right: 8px !default;
$dialog-close-icon-margin-left: -4px !default;
$dialog-close-icon-height: 24px !default;
$dialog-close-icon-width: auto !default;
$dialog-bigger-close-icon-height: 34px !default;
$dialog-bigger-close-icon-width: auto !default;
$dialog-close-icon-top: 0 !default;
$dialog-bigger-header-content-font-size: 18px !default;
$dialog-bigger-content-font-size: 13px !default;
$dialog-bigger-close-icon-top: -1px !default;
$dialog-bigger-close-icon-margin-left: -5px !default;
$dialog-bigger-close-icon-margin-right: -5px !default;
$dialog-bigger-close-button-bottom: 3px !default;
$dialog-bigger-close-button-height: 36px !default;
$dialog-bigger-close-button-left: 3px !default;
$dialog-bigger-close-button-width: 36px !default;
$dialog-btn-border-color: transparent !default;
$dialog-btn-bg-color: transparent !default;
$dialog-active-btn-border-color: transparent !default;
$dialog-hover-focus-btn-bg-color: $grey-300 !default;
$dialog-active-btn-bg-color: $grey-300 !default;
$dialog-headericon-bottom: 0 !default;
$dialog-resize-handler-width: 15px;
$dialog-resize-handler-height: 15px;
$dialog-resize-handler-position: 0;
$resize-hanlder-bottom-rtl: 2px;
$dialog-enable-resize-padding-bottom: 15px !default;
$dialog-footer-primary-hover-btn-bg-color: rgba($accent, .1) !default;
$dialog-footer-flat-hover-btn-bg-color: $grey-300 !default;
$dialog-footer-primary-focus-btn-bg-color: rgba($accent, .2) !default;
$dialog-footer-primary-active-btn-bg-color: rgba($accent, .3) !default;
$dialog-footer-flat-active-btn-bg-color: $grey-400 !default;

@include export-module('dialog-layout') {
  /* stylelint-disable property-no-vendor-prefix */
  .e-dialog {
    -webkit-flex-direction: column;
    @if $skin-name == 'fluent2' {
      border: $dialog-outer-border;
    }
    @else {
      border: $dialog-border;
    }
    border-radius: $dialog-border-radius;
    flex-direction: column;
    width: 100%;

    &.e-popup {
      width: 100%;
    }

    &.e-dlg-resizable {
      padding-bottom: $dialog-enable-resize-padding-bottom;
      touch-action: none;
    }

    .e-dlg-header-content {
      border-radius: $header-content-border-radius;
      @if $skin-name != 'bootstrap5' {
        line-height: $dialog-header-line-height;
      }
    }

    .e-dlg-header-content + .e-dlg-content {
      padding-top: $dialog-content-padding-top;
    }

    .e-btn .e-btn-icon.e-icon-dlg-close {
      font-size: $dialog-close-icon-font-size;
      width: $dialog-close-icon-width;
      @if $skin-name == 'bootstrap5.3' or $skin-name == 'bootstrap5' {
        top: $dialog-header-close-icon-top;
        left: $dialog-header-close-icon-left;
        position: inherit;
      }
      @if $skin-name == 'tailwind3' {
        margin-left: -4px;
        margin-top: -22px;
      }
    }

    .e-dlg-header {
      display: block;
      font-size: $dialog-header-font-size;
      font-weight: $dialog-header-font-weight;
      overflow: hidden;
      text-overflow: ellipsis;
      user-select: none;
      vertical-align: top;
      white-space: nowrap;
      width: 80%;
      @if $skin-name == 'Material3' {
        line-height: 30px;
      }
      @if $skin-name == 'fluent2' {
        line-height: 32px;
      }

      &.e-hide {
        display: none;
      }
    }

    .e-dlg-modal {
      position: fixed;
    }

    /* stylelint-disable declaration-no-important */
    .e-scroll-disabled {
      overflow: hidden !important;
    }
    /* stylelint-enable declaration-no-important */

    .e-dlg-content {
      display: block;
      flex: 1 1 auto;
      font-size: $dialog-content-font-size;
      font-weight: $dialog-content-font-weight;
      line-height: $dialog-content-line-height;
      overflow: auto;
      overflow-x: hidden;

      &.e-hide {
        display: none;
      }
    }

    .e-footer-content {
      border-bottom-left-radius: $footer-content-border-radius;
      border-bottom-right-radius: $footer-content-border-radius;
      @if $skin-name != 'tailwind3' {
        border-top: $dialog-footer-content-border-top;
      }
      bottom: 0;
      display: block;
      right: 0;
      width: 100%;

      &.e-hide {
        display: none;
      }
    }

    .e-footer-content {
      text-align: right;
    }

    .e-resize-handle {
      height: $dialog-resize-handler-height;
      position: absolute;
      width: $dialog-resize-handler-width;

      &.e-south-east {
        bottom: $dialog-resize-handler-position;
        cursor: nwse-resize;
        right: $dialog-resize-handler-position;
      }

      &.e-south-west {
        bottom: $dialog-resize-handler-position;
        cursor: nesw-resize;
        left: $dialog-resize-handler-position;
        transform: rotate(90deg);
      }

      &.e-north-east {
        cursor: nesw-resize;
        right: $dialog-resize-handler-position;
        top: $dialog-resize-handler-position;
        transform: rotate(-90deg);
      }

      &.e-north-west {
        cursor: nwse-resize;
        left: $dialog-resize-handler-position;
        top: $dialog-resize-handler-position;
        transform: rotate(180deg);
      }
    }

    .e-south,
    .e-north,
    .e-east,
    .e-west {
      background-color: transparent;
      background-repeat: repeat;
      overflow: visible;
      position: absolute;
    }

    .e-east,
    .e-west {
      cursor: ew-resize;
    }

    .e-south,
    .e-north {
      cursor: ns-resize;
    }

    &.e-blazor-hidden {
      left: 0;
      position: absolute;
    }
  }

  .e-dialog {
    & .e-dlg-header-content {
      @if $skin-name != 'tailwind3' {
        border-bottom: $dialog-header-border;
      }
      padding: $dialog-header-content-padding;
    }

    & .e-dlg-content {
      padding: $dialog-content-padding;
      @if $skin-name == 'Material3' or $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'tailwind3' {
        border-radius: $dialog-border-radius;
      }
    }

    & .e-footer-content {
      padding: $dialog-footer-content-padding;
    }

    @if $skin-name == 'FluentUI' {
      &.e-dlg-resizable {
        & .e-footer-content {
          padding-bottom: $dialog-footer-content-padding-bottom;
        }
      }
    }

    & .e-footer-content .e-btn {
      margin-left: $dialog-footer-button-margin-left;
    }
  }

  .e-alert-dialog .e-footer-content{
    border-top: $dialog-alert-footer-content-border-top;
  }

  .e-rtl .e-footer-content .e-btn {
    margin-right: $dialog-footer-button-margin-right;
  }

  .e-dialog.e-draggable > .e-dlg-header-content {
    cursor: move;
  }

  .e-dialog.e-device.e-draggable.e-popup-open {
    touch-action: none;
  }

  .e-dialog {
    max-height: 98%;
    max-width: 100%;
    min-width: 240px;
    position: absolute;
  }

  .e-rtl .e-footer-content .e-btn {
    margin-left: 0;
  }

  .e-rtl .e-footer-content {
    text-align: left;
  }

  .e-rtl .e-footer-content {
    text-align: left;
  }

  .e-dialog.e-rtl .e-dlg-header-content .e-btn.e-dlg-closeicon-btn {
    float: left;
    left: 0;
    right: $dialog-close-button-left;
  }

  .e-dialog .e-dlg-header-content .e-btn.e-dlg-closeicon-btn {
    background-color: $dialog-btn-bg-color;
    border-color: $dialog-btn-border-color;
    border-radius: $dialog-header-icon-hover-border-radius;
    bottom: $dialog-headericon-bottom;
    float: right;
    height: $dialog-close-button-height;
    left: $dialog-close-button-left;
    position: relative;
    width: $dialog-close-button-width;
    @if $skin-name == 'FluentUI' or $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'fluent2' or $skin-name == 'tailwind3' {
      padding: $dialog-header-btn-padding;
    }
    @if $skin-name == 'Material3' {
      padding: $zero-value;
    }
    @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
      line-height: 1;
    }
  }

  .e-rtl {
    &.e-dialog {
      .e-resize-handle {
        &.e-south-west {
          bottom: $dialog-resize-handler-position;
          cursor: sw-resize;
          direction: ltr;
          left: $dialog-resize-handler-position;
          text-align: initial;
          transform: rotate(90deg);
        }
      }
    }
  }

  /* stylelint-disable declaration-no-important */
  .e-dlg-target.e-scroll-disabled {
    overflow: hidden !important;
  }
  /* stylelint-enable declaration-no-important */
  .e-dlg-overlay {
    height: 100%;
    left: 0;
    opacity: $dialog-overlay-opacity;
    position: fixed;
    top: 0;
    transition: opacity .15s linear;
    width: 100%;

    &.e-fade {
      opacity: 0;
    }
  }

  .e-dlg-overflow-hidden {
    overflow: auto;
  }

  /* stylelint-disable declaration-no-important */
  .e-dlg-fullscreen {
    height: 100% !important;
    left: 0 !important;
    width: 100% !important;
  }
  /* stylelint-enable declaration-no-important */

  .e-popup.e-popup-open.e-dialog {
    display: inline-flex;
  }

  .e-dlg-container {
    align-items: flex-start;
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }

  .e-dlg-center-center {
    -webkit-align-items: center;
    -webkit-justify-content: center;
    align-items: center;
    justify-content: center;
  }

  .e-dlg-left-center {
    -webkit-align-items: center;
    -webkit-justify-content: flex-start;
    align-items: center;
    justify-content: flex-start;
  }

  .e-dlg-right-center {
    -webkit-align-items: center;
    -webkit-justify-content: flex-end;
    align-items: center;
    justify-content: flex-end;
  }

  .e-dlg-left-top {
    -webkit-align-items: flex-start;
    -webkit-justify-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .e-dlg-right-top {
    -webkit-align-items: flex-start;
    -webkit-justify-content: flex-end;
    align-items: flex-start;
    justify-content: flex-end;
  }

  .e-dlg-center-top {
    -webkit-align-items: center;
    -webkit-flex-direction: column;
    align-items: center;
    flex-direction: column;
  }

  .e-dlg-left-bottom {
    -webkit-align-items: flex-end;
    -webkit-justify-content: flex-start;
    align-items: flex-end;
    justify-content: flex-start;
  }

  .e-dlg-right-bottom {
    -webkit-align-items: flex-end;
    -webkit-justify-content: flex-end;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .e-dlg-center-bottom {
    -webkit-align-items: center;
    -webkit-flex-direction: column;
    -webkit-justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
  }

  .e-dialog .e-btn.e-dlg-closeicon-btn:hover,
  .e-dialog .e-btn.e-dlg-closeicon-btn:focus,
  .e-dialog .e-btn.e-dlg-closeicon-btn:active {
    @if $skin-name != 'fluent2' {
      background-color: $dialog-hover-focus-btn-bg-color;
      border-color: $dialog-active-btn-border-color;
      box-shadow: 0 0 0 transparent;
    }
    @if $skin-name == 'bootstrap5.3' {
      background-color: $content-bg-color;
      border-color: $dialog-active-btn-border-color;
    }
  }

  .e-dialog .e-btn.e-dlg-closeicon-btn:active {
    @if $skin-name != 'fluent2' {
      background-color: $dialog-active-btn-bg-color;
    }
  }

  .e-dialog .e-btn.e-dlg-closeicon-btn:focus,
  .e-dialog .e-btn.e-dlg-closeicon-btn:active {
    @if $skin-name == 'bootstrap5.3' {
      background-color: $content-bg-color;
      border-color: $dialog-active-btn-border-color;
      box-shadow: $primary-shadow-focus;
    }
  }
}

.e-content-placeholder.e-dialog.e-placeholder-dialog {
  background-size: 400px 210px;
  min-height: 210px;
}

@media (min-width: 768px) {
  .e-alert-dialog.e-dialog.e-popup,
  .e-confirm-dialog.e-dialog.e-popup {
    margin: 30px;
    width: 600px;
  }
}

@media (max-width: 768px) {
  .e-alert-dialog.e-dialog.e-popup,
  .e-confirm-dialog.e-dialog.e-popup {
    margin: 30px;
    width: auto;
  }
}

@media (max-width: 768px) {
  .e-prompt-dialog.e-dialog.e-popup,
  .e-prompt-dialog.e-dialog.e-popup {
    margin: 30px;
    width: auto;
  }
}

@media (min-width: 768px) {
  .e-prompt-dialog.e-dialog.e-popup,
  .e-prompt-dialog.e-dialog.e-popup {
    margin: 30px;
    width: 600px;
  }
}

.e-dlg-ref-element {
  display: none;
}

.e-dialog .e-footer-content {
  box-sizing: border-box;
}

@if $skin-name == 'bootstrap' or $skin-name == 'bootstrap4' or $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'tailwind' {
  .e-dialog .e-dlg-content .e-richtexteditor .e-rte-container .e-rte-tb-float {
    top: -$dialog-content-padding-top !important; /* stylelint-disable-line declaration-no-important */
  }
}


@include export-module('dialog-theme') {
  .e-dialog {
    background-color: $dialog-bg-color;
    box-shadow: $dialog-shadow-color;
  }

  .e-dlg-overlay {
    background-color: $dialog-overlay-bg-color;
  }

  .e-footer-content {
    background-color: $dialog-footer-bg-color;

    @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or  $skin-name == 'Material3' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' or $skin-name == 'fluent2' or $skin-name == 'tailwind3' {
      .e-btn.e-primary.e-flat:not([DISABLED]):not(.e-success):not(.e-danger):not(.e-warning):not(.e-info) {
        background: $dialog-footer-btn-bg-color;
        border-color: $dialog-footer-btn-border-color;
        color: $dialog-footer-btn-color;
      }

      .e-btn:hover.e-primary.e-flat:not([DISABLED]):not(.e-success):not(.e-danger):not(.e-warning):not(.e-info) {
        @if $skin-name == 'Material3' {
          background: $dialog-footer-flat-hover-btn-bg-color;
          color: rgba($primary);
        }
        @else {
          background: $dialog-footer-hover-btn-color;
          @if $skin-name == 'fluent2' {
            color: $dialog-footer-hover-btn;
          }
        }
      }
    }

    @if $skin-name == 'Material3' {
      .e-btn.e-primary.e-flat:not([DISABLED]):not(.e-success):not(.e-danger):not(.e-warning):not(.e-info):focus-visible,
      .e-btn.e-primary.e-flat:not([DISABLED]):not(.e-success):not(.e-danger):not(.e-warning):not(.e-info):focus,
      .e-btn.e-primary.e-flat:not([DISABLED]):not(.e-success):not(.e-danger):not(.e-warning):not(.e-info):focus,
      .e-btn.e-flat:not([DISABLED]):not(.e-success):not(.e-danger):not(.e-warning):not(.e-info):focus-visible {
        background: $dialog-footer-flat-hover-btn-bg-color;
        box-shadow: $shadow-focus-ring1;
      }
    }

    @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'tailwind' or $skin-name == 'FluentUI' or $skin-name == 'Material3' or $skin-name == 'fluent2' or $skin-name == 'tailwind3' {
      .e-btn.e-flat:not([DISABLED]):not(.e-success):not(.e-danger):not(.e-warning):not(.e-info) {
        background: $dialog-footer-flat-btn-bg-color;
        border-color: $dialog-footer-flat-btn-border-color;
        color: $dialog-footer-flat-btn-content-color;
      }
      .e-btn:hover.e-flat:not([DISABLED]):not(.e-success):not(.e-danger):not(.e-warning):not(.e-info) {
        background: $dialog-footer-flat-hover-btn-bg-color;
        @if $skin-name == 'fluent2' {
          color: $dialog-footer-flat-btn-hover-color;
        }
      }
    }
  }

  .e-dlg-header,
  .e-dlg-header * {
    color: $dialog-header-font-color;
    font-size: $dialog-header-font-size;
    font-weight: $dialog-header-font-weight;
  }

  .e-dlg-content {
    color: $dialog-content-font-color;
  }

  .e-device .e-dlg-content {
    font-size: $dialog-device-content-font-size;
  }

  .e-dlg-header-content {
    background-color: $dialog-header-bg-color;
  }

  .e-dlg-content {
    background-color: $dialog-content-bg-color;
  }

  .e-icon-dlg-close {
    color: $dialog-icon-color;
  }

  .e-dialog .e-btn.e-dlg-closeicon-btn:hover span {
    color: $dialog-active-icon-color;
  }

  .e-dialog .e-btn.e-dlg-closeicon-btn:active span,
  .e-dialog .e-btn.e-dlg-closeicon-btn:focus span {
    border-radius: $dialog-header-icon-hover-border-radius;
    color: $dialog-active-icon-color;
    opacity: 1;
  }

  .e-icon-dlg-close:active {
    border-radius: $dialog-header-icon-hover-border-radius;
    color: $dialog-active-icon-color;
    opacity: 1;
  }

  .e-icon-dlg-close:hover {
    color: $dialog-hover-icon-color;
  }

  .e-dlg-header-content .e-dlg-closeicon-btn:not(:hover) {
    @if $skin-name == 'fluent2' {
      color: $dialog-icon-color;
    }
  }

  .e-dlg-header-content .e-dlg-closeicon-btn:hover {
    @if $skin-name != 'fluent2' {
      background-color: $dialog-closeicon-btn-hover-color;
    }
  }

  .e-dlg-header-content .e-dlg-closeicon-btn:active {
    background-color: $dialog-closeicon-btn-active-color;
    @if $skin-name == 'FluentUI' {
      outline: none;
    }
    @if $skin-name == 'fluent2' {
      color: $dialog-active-icon-color;
    }
  }

  .e-south-east {
    color: $dialog-icon-color;
  }

  .e-rtl .e-south-east {
    color: transparent;
  }

  .e-rtl .e-south-west {
    color: $dialog-icon-color;
  }

  .e-south-west,
  .e-north-east,
  .e-north-west {
    color: transparent;
  }

  @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' or $skin-name == 'tailwind' or $skin-name == 'bootstrap4'  {
    .e-dialog .e-dlg-header-content .e-btn.e-dlg-closeicon-btn.e-ripple {
      overflow: visible;
    }
  }
}


@include export-module('dialog-bootstrap-icons') {
  .e-dialog {
    .e-icon-dlg-close::before {
      content: '\e7fc';
      position: relative;
    }

    .e-icon-dlg-close {
      opacity: $dialog-close-icon-normal-opacity;
    }

    .e-icon-dlg-close:active {
      opacity: $dialog-close-icon-active-opacity;
    }

    .e-icon-dlg-close:hover {
      opacity: $dialog-close-icon-active-opacity;
    }

    .e-south-east::before,
    .e-south-west::before,
    .e-north-east::before,
    .e-north-west::before {
      content: '\eb05';
    }
  }
}

@include export-module('dialog-bigger') {

  .e-bigger.e-dialog,
  #{if(&, '&', '*')}.e-bigger .e-dialog {
    #{if(&, '&', '*')} .e-dlg-header-content {
      padding: $dialog-bigger-header-content-padding;
      @if $skin-name == 'bootstrap5' {
        line-height: inherit;
      }
    }

    & .e-dlg-header {
      font-size: $dialog-bigger-header-content-font-size;
      @if $skin-name == 'Material3' {
        line-height: 40px;
      }
      @if $skin-name == 'bootstrap5.3' or $skin-name == 'tailwind3' {
        height: 28px;
      }
    }

    & .e-dlg-content {
      font-size: $dialog-bigger-content-font-size;
      padding: $dialog-bigger-content-padding;
    }

    & .e-footer-content {
      padding: $dialog-bigger-footer-content-padding;
    }

    & .e-footer-content .e-btn {
      @if $skin-name != 'fluent2' {
        margin-left: $dialog-bigger-footer-button-margin-left;
      }
    }

    & .e-dlg-header-content .e-btn.e-dlg-closeicon-btn {
      @if $skin-name != 'bootstrap5' and $skin-name != 'bootstrap5.3' and $skin-name != 'tailwind3' {
        bottom: $dialog-bigger-close-button-bottom;
        left: $dialog-bigger-close-button-left;
      }
      height: $dialog-bigger-close-button-height;
      width: $dialog-bigger-close-button-width;
      @if $skin-name == 'FluentUI' or $skin-name == 'fluent2' {
        padding: $dialog-bigger-header-btn-padding;
      }
      @if $skin-name == 'Material3' {
        padding: $zero-value;
      }
    }

    .e-btn .e-btn-icon.e-icon-dlg-close {
      font-size: $dialog-bigger-close-icon-font-size;
      width: $dialog-bigger-close-icon-width;
      @if $skin-name == 'bootstrap5' {
        top: auto;
        left: auto;
      }
      @if $skin-name == 'tailwind3' {
        margin-top: -25px;
      }
    }
  }

  .e-bigger.e-rtl .e-footer-content .e-btn,
  .e-bigger .e-rtl .e-footer-content .e-btn {
    margin-left: 0;
    margin-right: $dialog-bigger-footer-button-margin-right;
  }

  .e-bigger .e-dlg-header-content + .e-dlg-content {
    padding-top: $dialog-bigger-content-padding-top;
  }

  .e-bigger .e-content-placeholder.e-dialog.e-placeholder-dialog,
  .e-bigger.e-content-placeholder.e-dialog.e-placeholder-dialog {
    background-size: 400px 220px;
    min-height: 220px;
  }
}

@import "../base/base.gd.scss";
@import "../inputs/input.gd.scss";

$mask-error-color: $error-font !default;
$mask-font-size: 12.5px !default;
$mask-bigger-font-size: 13px !default;
$mask-font-family: Arial, $font-family !default;

@include export-module('maskedtextbox-layout') {

  /*! maskedtextbox layout */
  .e-control-wrapper.e-mask #{&}.e-maskedtextbox {
    @if $skin-name != 'bootstrap' {
      font-family: $mask-font-family;
      font-size: $mask-font-size;
    }
  }

  .e-content-placeholder.e-mask.e-placeholder-mask {
    background-size: 300px 33px;
    min-height: 33px;
  }
}


@include export-module('maskedit-theme') {

  /*! maskedtextbox theme */
  .e-control-wrapper.e-mask.e-error #{&}.e-maskedtextbox,
  .e-utility-mask.e-error {
    @if $skin-name != 'bootstrap' and $skin-name != 'FluentUI' {
      color: $mask-error-color;
    }
  }
}

@include export-module('maskedtextbox-bigger') {

  .e-bigger .e-control-wrapper.e-mask #{&}.e-maskedtextbox {
    @if $skin-name != 'bootstrap' {
      font-size: $mask-bigger-font-size;
    }
  }

  .e-control-wrapper.e-mask.e-bigger #{&}.e-maskedtextbox {
    @if $skin-name != 'bootstrap' {
      font-size: $mask-bigger-font-size;
    }
  }

  .e-bigger .e-content-placeholder.e-mask.e-placeholder-mask,
  .e-bigger.e-content-placeholder.e-mask.e-placeholder-mask {
    background-size: 300px 40px;
    min-height: 40px;
  }
}
